import folder1 from "./folder1.png";
import folder2 from "./folder2.png";
import folder3 from "./folder3.png";
import folder4 from "./folder4.png";
import folder5 from "./folder5.png";
import folder6 from "./folder6.png";
import folder7 from "./folder7.png";
import folder8 from "./folder8.png";
import folder9 from "./folder9.png";

export default [folder1, folder2, folder3, folder4, folder5, folder6, folder7, folder8, folder9];
