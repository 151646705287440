import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { GET_USER_RESULT } from "utils/api/Hexagram";
import FirstPageMobile from "./ResultMobilePages/FirstPageMobile";
import SecondPageMobile from "./ResultMobilePages/SecondPageMobile";
import ThirdPageMobile from "./ResultMobilePages/ThirdPageMobile";
import FourthPageMobile from "./ResultMobilePages/FourthPageMobile";
import FifthPageMobile from "./ResultMobilePages/FifthPageMobile";
import SixthPageMobile from "./ResultMobilePages/SixthPageMobile";
import SeventhPageMobile from "./ResultMobilePages/SeventhPageMobile";
import EighthPageMobile from "./ResultMobilePages/EighthPageMobile";
import S from "./style.module.css";
import {
  ActorTypeMobile,
  PersisterTypeMobile,
  DreamerTypeMobile,
  EmoterTypeMobile,
  AnalystTypeMobile,
  PlayerTypeMobile,
} from "assets/text/HexagramResultTextMobile";

const ResultMobile = () => {
  const { gameId, results, pageNumber } = useParams();
  const navigate = useNavigate();
  const [cookies] = useCookies(["uid"]);
  
  const pageComponents = {
    1: FirstPageMobile,
    2: SecondPageMobile,
    3: ThirdPageMobile,
    4: FourthPageMobile,
    5: FifthPageMobile,
    6: SixthPageMobile,
    7: SeventhPageMobile,
    8: EighthPageMobile,
  };

  const PageComponent = pageComponents[pageNumber] || FirstPageMobile;

  const handleNextClick = () => {
    const nextPage = parseInt(pageNumber, 10) + 1;
    navigate(`/hexagram/user/${gameId}/result/${results}/${nextPage}`);
  };

  const handlePrevClick = () => {
    const nextPage = parseInt(pageNumber, 10) - 1;
    navigate(`/hexagram/user/${gameId}/result/${results}/${nextPage}`);
  };

  const [resultObjectArray, setResultObjectArray] = useState([]);
  const [firstTypeArray, setFirstTypeArray] = useState([]);
  const [secondTypeArray, setSecondTypeArray] = useState([]);

  // 서버에서 받아오는 사용자 데이터 배열 초기화
  const [userData, setUserData] = useState({
    name: "",
    affiliation: "",
    result: "",
    date: "",
  });

  // 서버에서 데이터를 받아오고, 이를 정렬하는 함수
  useEffect(() => {
    const userId = cookies.uid;

    // 서버에서 사용자 데이터 받아오기
    GET_USER_RESULT(gameId, userId)
      .then((response) => {
        const { name, affiliation, result, date } = response.data;
        setUserData({ name, affiliation, result, date });

        // result(유저 응답)가 배열인지 확인 후, 아니라면 JSON.parse로 변환
        const resultArray = Array.isArray(result) ? result : JSON.parse(result);

        // 저장된 result(유저 응답)을 naming 할 수 있는 배열로 옮기기, 그리고 1순위부터 정렬
        const newResultObjectArray = [
          { name: "유희형(PL)", num: resultArray[0], type: "PlayerTypeMobile" },
          { name: "신념형(PE)", num: resultArray[1], type: "PersisterTypeMobile" },
          { name: "상상형(DR)", num: resultArray[2], type: "DreamerTypeMobile" },
          { name: "분석형(AN)", num: resultArray[3], type: "AnalystTypeMobile" },
          { name: "행동형(AC)", num: resultArray[4], type: "ActorTypeMobile" },
          { name: "감성형(EM)", num: resultArray[5], type: "EmoterTypeMobile" },
        ].sort((a, b) => a.num - b.num); 

        setResultObjectArray(newResultObjectArray);

        // 가장 높은 값과 두 번째로 높은 값을 기준으로 형 타입 배열 설정
        if (newResultObjectArray.length > 1) {
          const firstType = newResultObjectArray[5].type; // 첫 번째로 높은 값
          const secondType = newResultObjectArray[4].type; // 두 번째로 높은 값

          // 형 타입 배열 매핑
          const typeMapping = {
            ActorTypeMobile,
            PersisterTypeMobile,
            DreamerTypeMobile,
            EmoterTypeMobile,
            AnalystTypeMobile,
            PlayerTypeMobile,
          };

          setFirstTypeArray(typeMapping[firstType]);
          setSecondTypeArray(typeMapping[secondType]);
        }
      })
      .catch((error) => {
        console.error("에러입니다", error);
      });
  }, [gameId, cookies]);

  return (
    <div className={S.Container}>
      <PageComponent onNextClick={handleNextClick} onPrevClick={handlePrevClick} resultObjectArray={resultObjectArray} userData={userData} firstTypeArray={firstTypeArray} secondTypeArray={secondTypeArray}/>
    </div>
  );
};

export default ResultMobile;