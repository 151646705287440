const isDev = process.env.NODE_ENV === "development";

const GOOGLE = {
  CLIENT_ID: "489537342104-qm036ksb0rpdc1ds5upqc6gan452lta1.apps.googleusercontent.com",
  REDIRECT_URI: isDev
    ? "http://localhost:3000/oauth/google"
    : "https://www.withplus.live/oauth/google",
};

export default GOOGLE;
