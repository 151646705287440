import Character1 from "./Character1.png";
import Character2 from "./Character2.png";
import Character3 from "./Character3.png";
import Character4 from "./Character4.png";
import Character5 from "./Character5.png";
import Character6 from "./Character6.png";
import Character7 from "./Character7.png";
import Character8 from "./Character8.png";
import Character9 from "./Character9.png";
import Character10 from "./Character10.png";
import Character11 from "./Character11.png";
import Character12 from "./Character12.png";
import Character13 from "./Character13.png";
import Character14 from "./Character14.png";
import Character15 from "./Character15.png";
import Character16 from "./Character16.png";
import Character17 from "./Character17.png";
import Character18 from "./Character18.png";
import Character19 from "./Character19.png";
import Character20 from "./Character20.png";
import Character21 from "./Character21.png";
import Character22 from "./Character22.png";
import Character23 from "./Character23.png";
import Character24 from "./Character24.png";
import Character25 from "./Character25.png";
import Character26 from "./Character26.png";
import Character27 from "./Character27.png";
import Character28 from "./Character28.png";
import Character29 from "./Character29.png";
import Character30 from "./Character30.png";
import Character31 from "./Character31.png";
import Character32 from "./Character32.png";
import Character33 from "./Character33.png";
import Character34 from "./Character34.png";
import Character35 from "./Character35.png";
import Character36 from "./Character36.png";
import Character37 from "./Character37.png";
import Character38 from "./Character38.png";
import Character39 from "./Character39.png";
import Character40 from "./Character40.png";
import Character41 from "./Character41.png";
import Character42 from "./Character42.png";
import Character43 from "./Character43.png";
import Character44 from "./Character44.png";
import Character45 from "./Character45.png";
import Character46 from "./Character46.png";
import Character47 from "./Character47.png";
import Character48 from "./Character48.png";
import Character49 from "./Character49.png";
import Character50 from "./Character50.png";
import Character51 from "./Character51.png";
import Character52 from "./Character52.png";
import Character53 from "./Character53.png";
import Character54 from "./Character54.png";
import Character55 from "./Character55.png";
import Character56 from "./Character56.png";
import Character57 from "./Character57.png";
import Character58 from "./Character58.png";
import Character59 from "./Character59.png";
import Character60 from "./Character60.png";
import Character61 from "./Character61.png";
import Character62 from "./Character62.png";
import Character63 from "./Character63.png";
import Character64 from "./Character64.png";
import Character65 from "./Character65.png";
import Character66 from "./Character66.png";
import Character67 from "./Character67.png";

export default [
  Character1,
  Character2,
  Character3,
  Character4,
  Character5,
  Character6,
  Character7,
  Character8,
  Character9,
  Character10,
  Character11,
  Character12,
  Character13,
  Character14,
  Character15,
  Character16,
  Character17,
  Character18,
  Character19,
  Character20,
  Character21,
  Character22,
  Character23,
  Character24,
  Character25,
  Character26,
  Character27,
  Character28,
  Character29,
  Character30,
  Character31,
  Character32,
  Character33,
  Character34,
  Character35,
  Character36,
  Character37,
  Character38,
  Character39,
  Character40,
  Character41,
  Character42,
  Character43,
  Character44,
  Character45,
  Character46,
  Character47,
  Character48,
  Character49,
  Character50,
  Character51,
  Character52,
  Character53,
  Character54,
  Character55,
  Character56,
  Character57,
  Character58,
  Character59,
  Character60,
  Character61,
  Character62,
  Character63,
  Character64,
  Character65,
  Character66,
  Character67,
];
