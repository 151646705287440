import React, { createContext, useState } from "react";
import { QuizTemplate } from "template/Quiz";
import { isHangulLetter } from "utils/chosung";

const QuizContext = createContext({
  title: "",
  slideList: [],
  currentSlide: {},

  handleTitle: () => {},
  setSlideList: () => {},
  setCurrentSlide: () => {},
  setTitle: () => {},
  updateCurrentSlide: () => {},

  hasBlankTitle: () => {},
});

const QuizProvider = ({ children }) => {
  const [title, setTitle] = useState("");
  const [slideList, setSlideList] = useState([QuizTemplate()]);
  const [currentSlide, setCurrentSlide] = useState(slideList[0] || {});

  const handleTitle = (e) => {
    setTitle(e.target.value);
  };

  const updateCurrentSlide = (object) => {
    let newCurrentSlide = { ...currentSlide, ...object };

    checkComplete(newCurrentSlide);
    setCurrentSlide(newCurrentSlide);

    setSlideList(
      slideList.map((item) => {
        return item.id === currentSlide.id ? newCurrentSlide : item;
      })
    );
  };

  const checkComplete = (currentSlide) => {
    switch (currentSlide.type) {
      case "객관식":
        currentSlide.isCompleted =
          currentSlide.question !== "" &&
          !hasBlankAnswer(currentSlide) &&
          currentSlide.correctAnswerIDs.length !== 0;
        break;

      case "초성 퀴즈":
        const regex = /^[가-힣 ]*$/;

        currentSlide.isCompleted =
          currentSlide.question !== "" &&
          !hasBlankAnswer(currentSlide) &&
          regex.test(currentSlide.answer);
        break;

      default:
        currentSlide.isCompleted = currentSlide.question !== "" && !hasBlankAnswer(currentSlide);
        break;
    }
  };

  const hasBlankAnswer = (currentSlide) => {
    switch (currentSlide.type) {
      case "객관식":
        let answers = currentSlide.answers;
        let blankAnswer = answers.filter((answer) => answer.text === "");

        return blankAnswer.length !== 0;

      default:
        let answer = currentSlide.answer;

        return answer === "";
    }
  };

  const hasBlankHint = (currentSlide) => {
    const hints = currentSlide.hints;
    let blankHint = hints.filter((hint) => hint.text === "");

    return blankHint.length !== 0;
  };

  const hasBlankTitle = () => {
    // console.log(title);
    return title;
  };

  const value = {
    title,
    slideList,
    currentSlide,

    handleTitle,
    setSlideList,
    setCurrentSlide,
    updateCurrentSlide,

    setTitle,
    hasBlankTitle,
  };

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
};

export { QuizContext, QuizProvider };
