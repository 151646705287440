import axios from "axios";
import React, { useEffect } from "react";
import { Route, Routes, useParams, useSearchParams } from "react-router-dom";

const CallbackPage = (props) => {
  return (
    <Routes>
      <Route path="/tosspayments/*" element={<TossPayments />} />
    </Routes>
  );
};

const TossPayments = () => {
  return (
    <Routes>
      <Route path="cardChange/:result" element={<CardChange />} />
    </Routes>
  );
};

const CardChange = () => {
  const { result } = useParams();
  const searchParams = useSearchParams()[0];

  useEffect(() => {
    if (result === "success") {
      const params = {
        customerKey: searchParams.get("customerKey"),
        authKey: searchParams.get("authKey"),
      };
      axios
        .post("/api/payment/cardChange", null, { params })
        .then((res) => {
          console.log(res.data);
          const { success, error } = res.data;

          if (success) {
            alert("카드 변경이 완료되었습니다.");
            if (searchParams.get("from") === "payment-immediately") {
              window.location.replace("/dashboard/subscribe/payment-immediately");
              return;
            }
            window.location.replace("/dashboard");
          } else {
            alert(`카드 변경에 실패하였습니다. \n 에러내용 : ${error.message}`);
            window.location.replace("/dashboard");
          }
        })
        .catch((err) => {
          alert("카드 변경에 실패하였습니다. 잠시후에 시도해주세요");
          window.location.replace("/dashboard");
          console.log(err);
        });
    } else {
    }
  }, []);

  return <></>;
};

export default CallbackPage;
