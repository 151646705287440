import React, { useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  Typography,
} from "@mui/material";

import EqualizerIcon from "@mui/icons-material/Equalizer";
import CloseIcon from "@mui/icons-material/Close";
import PieChartIcon from "@mui/icons-material/PieChart";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ToolTip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { EditContext } from "../contexts/EditContext";
import * as S from "./styles";
import { Textfield } from "components/Textfield";
import { SnackBarContext } from "components/SnackBar/ContextAPI";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ToolTip,
  Legend,
  ArcElement,
  ChartDataLabels
);

const backgroundColor = [
  "rgba(255, 99, 132, 0.7)",
  "rgba(54, 162, 235, 0.7)",
  "rgba(255, 206, 86, 0.7)",
  "rgba(75, 192, 192, 0.7)",
  "rgba(153, 102, 255, 0.7)",
  "rgba(255, 159, 64, 0.7)",

  "rgba(75, 159, 206, 0.7)",
  "rgba(255, 162, 64, 0.7)",
  "rgba(75, 159, 64, 0.7)",
  "rgba(255, 162, 132, 0.7)",
];

const randomData = [13, 43, 6, 26, 19, 33, 26, 44, 5, 15, 2, 21, 9, 16, 39, 43, 4, 17, 41, 24];

export const getChoiceData = () => {
  return {
    type: "Choice",
    question: "",
    options: [
      { id: new Date().getTime() + "1", value: "" },
      { id: new Date().getTime() + "2", value: "" },
    ],
    duplicateSubmission: false,
    resultLayout: "bar",
    percentage: false,
    etcContained: false,
    completed: false,
  };
};

export const ChoicePreview = (props) => {
  const { currentSlide } = useContext(EditContext);

  const [data, setData] = useState({
    labels: ["옵션1", "옵션2", "기타"],
    datasets: [{ backgroundColor, data: [31.25, 37.5, 31.25] }],
  });

  useEffect(() => {
    if (currentSlide.etcContained) {
      setData({
        labels: [
          ...currentSlide.options.map((option) =>
            option.value === "" ? `옵션${currentSlide.options.indexOf(option) + 1}` : option.value
          ),
          "기타",
        ],
        datasets: [
          {
            backgroundColor,
            data: randomData.slice(0, currentSlide.options.length + 1),
          },
        ],
      });
    } else {
      setData({
        labels: currentSlide.options.map((option) =>
          option.value === "" ? `옵션${currentSlide.options.indexOf(option) + 1}` : option.value
        ),
        datasets: [
          {
            backgroundColor,
            data: randomData.slice(0, currentSlide.options.length),
          },
        ],
      });
    }
  }, [currentSlide]);

  const options = {
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (value) {
            return " 투표 수 : " + value.raw;
          },
        },
      },
      datalabels: {
        display: true,
        color: "black",
        font: { size: "18px", weight: "bold", family: "Pretendard" },
        align: "end",
        anchor: "end",
        formatter: (value, ctx) => {
          const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
          const percentage = sum === 0 ? "0 %" : ((value * 100) / sum).toFixed(1) + "%";
          return currentSlide.percentage ? percentage : value;
        },
      },
    },
    scales: {
      y: { min: 0, max: 47.5 },
    },
  };

  const circleOptions = {
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return currentSlide.percentage
              ? " 비율 : " + context.raw.toPrecision(3) + " %"
              : " 투표 수 : " + context.raw;
          },
        },
      },
      datalabels: {
        display: true,
        color: "white",
        formatter: (value, ctx) => {
          const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
          const percentage = sum === 0 ? "0 %" : ((value * 100) / sum).toFixed(2) + "%";
          return currentSlide.percentage ? percentage : value + " 표";
        },
        font: {
          size: "20px",
          weight: "bold",
          family: "Pretendard",
          align: "center",
        },
      },
    },
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        p: "5px",
        boxSizing: "border-box",
      }}
    >
      <Typography
        sx={{
          font: "700 32px Pretendard",
          borderBottom: "1px solid #eee",
          color: "#252b36",
          wordBreak: "break-all",
        }}
      >
        {currentSlide.question === "" ? "질문을 입력해주세요." : currentSlide.question}
      </Typography>

      <Box
        sx={{
          flex: 1,
          position: "relative",
          p: "10px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            position: "absolute",
            top: "10px",
            left: "10px",
            font: "500 16px Pretendard",
            color: "#777",
          }}
        >
          투표 수: 100
          <br />
          <br />
          {"(예시 화면입니다.)"}
        </Typography>

        <Box
          sx={{
            height: "80%",
            aspectRatio: "16 / 9",
            minHeight: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {(() => {
            switch (currentSlide.resultLayout) {
              case "bar":
                return <Bar options={options} data={data} />;
              case "circle":
                return <Pie options={circleOptions} data={data} />;
              case "doughnut":
                return <Doughnut options={circleOptions} data={data} />;

              default:
                return <></>;
            }
          })()}
        </Box>
      </Box>
    </Box>
  );
};

export const ChoiceEditor = (props) => {
  const { currentSlide, updateCurrentSlide } = useContext(EditContext);
  const { setText, setOpen, setSeverity } = useContext(SnackBarContext);

  const handleQuestion = (e) => {
    if (e.target.value.length > 75) {
      updateCurrentSlide({ question: e.target.value.slice(0, 75) });
    } else {
      updateCurrentSlide({ question: e.target.value });
    }
  };

  const addOption = () => {
    if (currentSlide.options.length >= 20) {
      setText("옵션은 최대 20개까지 추가할 수 있습니다.");
      setSeverity("warning");
      setOpen(true);
      return;
    }

    let newOptions = [...currentSlide.options, { id: new Date().getTime() + "", value: "" }];

    updateCurrentSlide({ options: newOptions });
  };

  const deleteOption = (e) => {
    if (currentSlide.options.length === 1) {
      setText("옵션은 최소 1개 이상이어야 합니다.");
      setSeverity("warning");
      setOpen(true);
      return;
    }

    let newOptions = currentSlide.options.filter((option) => option.id !== e.currentTarget.id);
    updateCurrentSlide({ options: newOptions });
  };

  const handleOption = (e) => {
    let newOptions = [...currentSlide.options];

    newOptions.forEach((option) => {
      if (option.id === e.currentTarget.id) {
        if (e.target.value.length > 20) {
          option.value = e.target.value.slice(0, 20);
        } else {
          option.value = e.target.value;
        }
      }
    });

    updateCurrentSlide({ options: newOptions });
  };

  const handlePercentage = (e) => {
    updateCurrentSlide({ percentage: e.target.checked });
  };

  const handleDuplicateSubmission = (e) => {
    updateCurrentSlide({ duplicateSubmission: e.target.checked });
  };

  const handleEtcContained = (e) => {
    updateCurrentSlide({ etcContained: e.target.checked });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography sx={{ fontWeight: "600", color: "#c33c3c" }}>{"(* 필수 입력)"}</Typography>

      <Box>
        <Typography sx={{ fontWeight: "600", mb: "10px" }}>
          질문 <span style={{ color: "#c33c3c" }}>*</span>
        </Typography>

        <Textfield
          size="small"
          placeholder="질문을 입력해주세요. (75자 이내)"
          fullWidth
          value={currentSlide.question}
          onChange={handleQuestion}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography sx={{ color: "#252b36" }}>
                  {75 - currentSlide.question.length}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box>
        <Typography sx={{ fontWeight: "600", mb: "10px" }}>
          옵션 <span style={{ color: "#c33c3c" }}>*</span>
        </Typography>

        {currentSlide.options.map((option, index) => (
          <Box key={option.id} sx={{ display: "flex", gap: "10px", mb: "10px" }}>
            <Textfield
              fullWidth
              size="small"
              value={option.value}
              onChange={handleOption}
              InputProps={{
                id: option.id + "",
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography sx={{ color: "#252b36" }}>{20 - option.value.length}</Typography>
                  </InputAdornment>
                ),
              }}
              placeholder={`옵션 ${index + 1}`}
            />

            <IconButton onClick={deleteOption} id={option.id}>
              <CloseIcon />
            </IconButton>
          </Box>
        ))}

        <Button
          size="large"
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: "rgb(219, 220, 225)",
            color: "black",
            "&:hover": { backgroundColor: "rgb(203, 204, 209)" },
            font: "600 16px Pretendard",
          }}
          onClick={addOption}
        >
          옵션 추가
        </Button>
      </Box>

      <Box>
        <Typography sx={{ fontWeight: "600", mb: "10px" }}>결과 레이아웃</Typography>

        <Grid container>
          <Grid item xs={4}>
            <Box
              id="bar"
              sx={{
                m: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ababab",
                borderRadius: "10px",
                py: "10px",
                cursor: "pointer",
                bgcolor: currentSlide.resultLayout === "bar" ? "#b43f3f" : "#fff",
                "&:hover": { bgcolor: currentSlide.resultLayout === "bar" ? null : "#efefef" },
              }}
              onClick={() => updateCurrentSlide({ resultLayout: "bar" })}
            >
              <EqualizerIcon
                sx={{
                  fontSize: "40px",
                  color: currentSlide.resultLayout === "bar" ? "#fff" : "#000",
                }}
              />
              <Typography
                sx={{
                  fontWeight: "600",
                  color: currentSlide.resultLayout === "bar" ? "#fff" : "#000",
                  fontSize: "clamp(12px, 0.83vw, 16px)",
                }}
              >
                막대 그래프
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box
              id="circle"
              sx={{
                m: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ababab",
                borderRadius: "10px",
                py: "10px",
                cursor: "pointer",
                bgcolor: currentSlide.resultLayout === "circle" ? "#b43f3f" : "#fff",
                "&:hover": { bgcolor: currentSlide.resultLayout === "circle" ? null : "#efefef" },
              }}
              onClick={() => updateCurrentSlide({ resultLayout: "circle" })}
            >
              <PieChartIcon
                sx={{
                  fontSize: "40px",
                  color: currentSlide.resultLayout === "circle" ? "#fff" : "#000",
                }}
              />
              <Typography
                sx={{
                  fontWeight: "600",
                  color: currentSlide.resultLayout === "circle" ? "#fff" : "#000",
                  fontSize: "clamp(12px, 0.83vw, 16px)",
                }}
              >
                원형 그래프
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box
              id="doughnut"
              sx={{
                m: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #ababab",
                borderRadius: "10px",
                py: "10px",
                cursor: "pointer",
                bgcolor: currentSlide.resultLayout === "doughnut" ? "#b43f3f" : "#fff",
                "&:hover": { bgcolor: currentSlide.resultLayout === "doughnut" ? null : "#efefef" },
              }}
              onClick={() => updateCurrentSlide({ resultLayout: "doughnut" })}
            >
              <DonutLargeIcon
                sx={{
                  fontSize: "40px",
                  color: currentSlide.resultLayout === "doughnut" ? "#fff" : "#000",
                }}
              />
              <Typography
                sx={{
                  fontWeight: "600",
                  color: currentSlide.resultLayout === "doughnut" ? "#fff" : "#000",
                  fontSize: "clamp(12px, 0.83vw, 16px)",
                }}
              >
                도넛 그래프
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Typography sx={{ fontWeight: "700", mb: "10px", fontSize: "18px" }}>설정</Typography>

        <Box sx={{ pl: "10px", display: "flex", flexDirection: "column", gap: "10px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography fontWeight={600}>투표 결과를 퍼센트로 보기</Typography>

            <Checkbox checked={currentSlide.percentage} onChange={handlePercentage} />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography fontWeight={600}>중복 투표 가능</Typography>

            <Checkbox
              checked={currentSlide.duplicateSubmission}
              onChange={handleDuplicateSubmission}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography fontWeight={600}>기타 옵션 추가</Typography>

            <Checkbox checked={currentSlide.etcContained} onChange={handleEtcContained} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
