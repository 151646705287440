import { Button, CircularProgress, IconButton, Modal as MuiModal, TextField } from "@mui/material";
import { styled } from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";

import CloseIcon from "@mui/icons-material/Close";

export const Modal = MuiStyled((props) => <MuiModal {...props} />)({});

export const Paper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  width: 420px;
  height: 300px;
  border: 0;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
  padding: 10px;
  overflow: auto;

  &:focus {
    outline: none;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #cdcdcd;
  padding-bottom: 10px;

  & > div:first-child {
    width: 10%;
  }
`;

export const Title = styled.div`
  width: 80%;
  text-align: center;
  font: bold 24px NotoSansKR-Regular;
`;

export const CloseButton = MuiStyled((props) => (
  <IconButton children={<CloseIcon />} size="small" {...props} />
))({
  width: "10%",
});

export const CodeInputContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  font-family: NotoSansKR-Medium;

  > div {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    box-sizing: border-box;
    margin-top: 5px;
  }
`;

export const CodeInput = MuiStyled((props) => (
  <TextField
    size="small"
    InputProps={{
      style: {
        fontSize: "15px",
        fontFamily: "NotoSansKR-Medium",
        backgroundColor: "#F5F5F5",
        borderRadius: "8px",
      },
    }}
    placeholder="쿠폰 코드를 입력해주세요."
    {...props}
  />
))({
  width: "75%",
});

export const EnrollButton = MuiStyled((props) => <Button variant="contained" {...props} />)({
  width: "25%",
  fontFamily: "NotoSansKR-Medium",
});

export const CouponList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
  font-family: NotoSansKR-Medium;
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingBar = MuiStyled((props) => <CircularProgress {...props} />)({
  color: "white",
});

export const ResultModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background-color: white;
  border-radius: 10px;

  padding: 30px;

  transition: all 0.5s ease-out;

  &.show {
    transform: translate(-50%, -50%) scale(1);
  }
`;
