import { useEffect, useRef, useState } from "react";
import {
  // PaymentWidgetInstance,
  loadPaymentWidget,
  ANONYMOUS,
} from "@tosspayments/payment-widget-sdk";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Box, Button, Container, Typography, Grid } from "@mui/material";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import logo from "assets/images/wLiveLogo(Red).png";
import axios from "axios";
import config from "./config";

const clientKey = config.TOSS_PAYMENT_WIDGET_CLIENT_KEY;
const selector = "#payment-widget";

export default () => {
  const { data: paymentWidget } = usePaymentWidget(clientKey, ANONYMOUS);
  const paymentMethodsWidgetRef = useRef(null);
  const { state } = useLocation();
  const [price, setPrice] = useState(50_000);
  const [paymentMethodsWidgetReady, isPaymentMethodsWidgetReady] = useState(false);

  const requestPayment = () => {
    // TODO: 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
    // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
    // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보

    // console.log(state);
    // let params;

    // if (state.auth) {
    //   params = {
    //     orderName: state.orderName,
    //     // name : state.name,
    //     phone: state.phone,
    //     company: state.company,
    //     auth: state.auth,
    //     totalAmount: state.finalPrice,
    //   };
    // } else {
    //   params = {
    //     orderName: state.orderName,
    //     // name : state.name,
    //     phone: state.phone,
    //     company: state.company,
    //     totalAmount: state.finalPrice,
    //   };
    // }

    // return;

    const params = {
      orderName: state.orderName,
      // name : state.name,
      phone: state.phone,
      company: state.company,
      auth: state.auth,
      id: state.id,
      totalAmount: state.finalPrice,
    };

    axios.post("/api/payment/request-order", params).then((res) => {
      const { status, data: response } = res;
      const { order } = response;

      paymentWidget
        .requestPayment({
          orderId: order._id,
          orderName: state.orderName,
          customerName: state.name,
          successUrl: `${window.location.href}/pending?period=${state.period}`,
          failUrl: `${window.location.href}/fail?order-id=${order._id}`,
        })
        .catch((error) => {
          // console.log(error);
          axios.post("/api/payment/cancel-order", { orderId: order._id });
        });
    });
  };

  useEffect(() => {
    if (paymentWidget == null) {
      return;
    }

    // ------  결제 UI 렌더링 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
      selector,
      { value: price },
      { variantKey: "DEFAULT" }
    );

    // ------  이용약관 UI 렌더링 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
    paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });

    //  ------  결제 UI 렌더링 완료 이벤트 ------
    paymentMethodsWidget.on("ready", () => {
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
      isPaymentMethodsWidgetReady(true);
    });
  }, [paymentWidget]);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }

    // ------ 금액 업데이트 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#updateamount결제-금액
    paymentMethodsWidget.updateAmount(price);
  }, [price]);

  useEffect(() => {
    if (!state) return;

    setPrice(state.finalPrice);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        padding: "10px",
        height: "100vh",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          boxSizing: "border-box",
          borderRadius: "10px",
          boxShadow: "0px 3px 6px #00000038",
          height: "100%",
          overflow: "auto",
          margin: "0 auto",
          maxWidth: "500px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            "> img": {
              width: "40px",
            },
            opacity: "0.8",
            pt: "10px",
          }}
        >
          <img src={logo} />

          <Typography sx={{ font: "700 28px Pretendard" }}>Withplus Live - 상품 결제</Typography>
        </Box>
        <div id="payment-widget" />
        <div id="agreement" />

        <Button
          sx={{
            color: "#f9fafb",
            bgcolor: "#3182f6",
            fontSize: "18px",
            fontWeight: 600,
            fontFamily: "Pretendard",
            lineHeight: "18px",
            whiteSpace: "nowrap",
            textAlign: "center",
            cursor: "pointer",
            border: "0 solid transparent",
            userSelect: "none",
            transition: "background 0.2s ease, color 0.1s ease",
            textDecoration: "none",
            borderRadius: "7px",
            padding: "11px 16px",
            width: "100%",
            boxSizing: "border-box",
            "&:hover": {
              color: "#fff",
              bgcolor: "#1b64da",
            },
            "&:disabled": {
              opacity: "0.5",
              cursor: "not-allowed",
              color: "#fff",
              bgcolor: "#3182f6",
            },
          }}
          disabled={!paymentMethodsWidgetReady}
          onClick={requestPayment}
        >
          {price.toLocaleString("ko-KR")}원 결제하기
        </Button>
      </div>
    </div>
  );
};

export const PendingPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    const requestData = {
      orderId: searchParams.get("orderId"),
      amount: searchParams.get("amount"),
      paymentKey: searchParams.get("paymentKey"),
      period: searchParams.get("period"),
    };

    // TODO: 개발자센터에 로그인해서 내 결제위젯 연동 키 > 시크릿 키를 입력하세요. 시크릿 키는 외부에 공개되면 안돼요.
    // @docs https://docs.tosspayments.com/reference/using-api/api-keys
    const secretKey = config.TOSS_PAYMENT_WIDGET_SECRET_KEY;

    // 토스페이먼츠 API는 시크릿 키를 사용자 ID로 사용하고, 비밀번호는 사용하지 않습니다.
    // 비밀번호가 없다는 것을 알리기 위해 시크릿 키 뒤에 콜론을 추가합니다.
    // @docs https://docs.tosspayments.com/reference/using-api/authorization#%EC%9D%B8%EC%A6%9D
    const encryptedSecretKey = `Basic ${window.btoa(secretKey + ":")}`;

    async function confirm() {
      const response = await fetch("https://api.tosspayments.com/v1/payments/confirm", {
        method: "POST",
        headers: {
          Authorization: encryptedSecretKey,
          "Content-Type": "application/json",
          // "TossPayments-Test-Code": "INVALID_STOPPED_CARD",
        },
        body: JSON.stringify(requestData),
      });

      const json = await response.json();

      if (!response.ok) {
        // TODO: 구매 실패 비즈니스 로직 구현
        navigate(
          `/payment/checkout/fail?code=${json.code}&message=${json.message}&order-id=${requestData.orderId}`,
          {
            replace: true,
          }
        );
        return;
      }

      const params = {
        orderId: json.orderId,
        orderName: json.orderName,
        approvedAt: json.approvedAt,
        paymentKey: json.paymentKey,
        method: json.method,
        receipt: json.receipt.url,
        period: requestData.period,
        type: "period",
      };

      // TODO: 결제 성공 비즈니스 로직을 구현하세요.
      axios.post("/api/payment/enroll", params).then((res) => {
        const state = {
          ...json,
          StartedAt: res.data.user?.StartedAt,
          ExpiredAt: res.data.user?.ExpiredAt,
        };
        // setResponseData({
        //   ...json,
        //   StartedAt: res.data.user.StartedAt,
        //   ExpiredAt: res.data.user.ExpiredAt,
        // });
        navigate("/payment/checkout/success", { replace: true, state });
      });

      // console.log(json);
      return json;
    }

    confirm();
    // confirm().then((data) => {
    //   setResponseData(data);
    // });
  }, [searchParams]);

  return <></>;
};

export const SuccessPage = () => {
  const navigate = useNavigate();
  const { state: responseData } = useLocation();

  return (
    <Box
      sx={{
        bgcolor: "#f5f5f5",
        height: "100vh",
        position: "relative",
        p: "10px",
        boxSizing: "border-box",
      }}
    >
      <Container
        disableGutters
        sx={{
          position: "relative",
          maxWidth: "500px !important",
          height: "100%",
          bgcolor: "#fff",
          borderRadius: "10px",
          boxShadow: "0px 3px 6px #00000038",
          p: "10px",
          boxSizing: "border-box",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            "> img": {
              width: "40px",
            },
            opacity: "0.8",
            pt: "10px",
          }}
        >
          <img src={logo} />

          <Typography sx={{ font: "700 28px Pretendard" }}>Withplus Live - 상품 결제</Typography>
        </Box>

        <Box sx={{ mt: "50px", textAlign: "center" }}>
          {/* <CheckCircleOutlineIcon sx={{ fontSize: "90px", color: "#c33c3c" }} /> */}
          <img
            src="https://static.toss.im/illusts/check-blue-spot-ending-frame.png"
            style={{ width: "100px" }}
          />
          <Typography
            sx={{ mt: "20px", font: "700 24px Pretendard", color: "#3182f6", lineHeight: "1.5" }}
          >
            이용권 결제가 정상적으로 <br />
            완료되었습니다.
          </Typography>
        </Box>

        <Box
          sx={{
            mt: "50px",
            bgcolor: "#f5f5f5",
            borderRadius: "10px",
            p: "20px",
          }}
        >
          <Grid container rowGap="20px">
            <Grid item xs={5}>
              <Typography sx={{ font: "700 18px Pretendard", color: "#aaa" }}>이용권</Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography sx={{ font: "700 18px Pretendard", textAlign: "right" }}>
                {responseData.orderName}
              </Typography>
            </Grid>

            {responseData.StartedAt && (
              <Grid item xs={5}>
                <Typography sx={{ font: "700 18px Pretendard", color: "#aaa" }}>
                  이용 기간
                </Typography>
              </Grid>
            )}

            {responseData.StartedAt && (
              <Grid item xs={7}>
                <Typography sx={{ font: "700 18px Pretendard", textAlign: "right" }}>
                  {responseData.StartedAt} ~ {responseData.ExpiredAt}
                </Typography>
              </Grid>
            )}

            <Grid item xs={5}>
              <Typography sx={{ font: "700 18px Pretendard", color: "#aaa" }}>결제 금액</Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography sx={{ font: "700 18px Pretendard", textAlign: "right" }}>
                {responseData.totalAmount?.toLocaleString("ko-KR")}원
              </Typography>
            </Grid>

            {!responseData.receipt && (
              <>
                <Grid item xs={5}>
                  <Typography sx={{ font: "700 18px Pretendard", color: "#aaa" }}>
                    다음 결제일
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography sx={{ font: "700 18px Pretendard", textAlign: "right" }}>
                    {responseData.ExpiredAt}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Box>

        {responseData.receipt && (
          <Box
            sx={{
              mt: "20px",
              border: "2px solid #ddd",
              p: "20px",
              borderRadius: "8px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "&:hover": { bgcolor: "#f5f5f5" },
            }}
            onClick={() => {
              if (!responseData.receipt.url) {
                alert("영수증이 없습니다.");
                return;
              }
              window.open(responseData.receipt.url, "_blank");
            }}
          >
            <Typography sx={{ font: "700 18px Pretendard", color: "#555" }}>영수증 보기</Typography>

            <KeyboardArrowRightIcon sx={{ color: "#555" }} />
          </Box>
        )}

        <Box sx={{ position: "absolute", bottom: 0, width: "100%", left: 0 }}>
          <Button
            sx={{
              width: "100%",
              borderRadius: 0,
              bgcolor: "#3182f6",
              color: "#fff",
              font: "600 24px Pretendard",
              "&:hover": {
                bgcolor: "#2172e6",
              },
              p: "15px",
            }}
            onClick={() => {
              if (!responseData.StartedAt) {
                navigate("/");
                return;
              }
              navigate("/dashboard/subscribe");
            }}
          >
            확인했어요
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export const FailPage = () => {
  const [searchParams] = useSearchParams();

  const params = {
    orderId: searchParams.get("order-id"),
  };

  useEffect(() => {
    axios.post("/api/payment/cancel-order", params);
  }, []);

  return (
    <Box
      sx={{
        bgcolor: "#f5f5f5",
        height: "100vh",
        position: "relative",
        p: "10px",
        boxSizing: "border-box",
      }}
    >
      <Container
        disableGutters
        sx={{
          position: "relative",
          maxWidth: "500px !important",
          height: "100%",
          bgcolor: "#fff",
          borderRadius: "10px",
          boxShadow: "0px 3px 6px #00000038",
          p: "10px",
          boxSizing: "border-box",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            "> img": {
              width: "40px",
            },
            opacity: "0.8",
            pt: "10px",
          }}
        >
          <img src={logo} />

          <Typography sx={{ font: "700 28px Pretendard" }}>Withplus Live - 상품 결제</Typography>
        </Box>

        <Box sx={{ mt: "50px", textAlign: "center" }}>
          {/* <CheckCircleOutlineIcon sx={{ fontSize: "90px", color: "#c33c3c" }} /> */}
          <img
            src="https://static.toss.im/lotties/error-spot-no-loop-space-apng.png"
            style={{ width: "100px" }}
          />
          <Typography
            sx={{ mt: "20px", font: "700 24px Pretendard", color: "#c33c3c", lineHeight: "1.5" }}
          >
            결제를 실패했어요.
          </Typography>
        </Box>

        <Box
          sx={{
            mt: "50px",
            bgcolor: "#f5f5f5",
            borderRadius: "10px",
            p: "20px",
          }}
        >
          <Grid container rowGap="20px">
            <Grid item xs={4}>
              <Typography sx={{ font: "700 18px Pretendard", color: "#aaa" }}>
                에러 메시지
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography sx={{ font: "700 18px Pretendard", textAlign: "right" }}>
                {`${searchParams.get("message")}`}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography sx={{ font: "700 18px Pretendard", color: "#aaa" }}>에러 코드</Typography>
            </Grid>

            <Grid item xs={8}>
              <Typography sx={{ font: "700 18px Pretendard", textAlign: "right" }}>
                {`${searchParams.get("code")}`}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ position: "absolute", bottom: 0, width: "100%", left: 0 }}>
          <Button
            sx={{
              width: "100%",
              borderRadius: 0,
              bgcolor: "#c33c3c",
              color: "#fff",
              font: "600 24px Pretendard",
              "&:hover": {
                bgcolor: "#b32c2c",
              },
              p: "15px",
            }}
            onClick={() => {
              window.location.href = "/plan";
            }}
          >
            다시 시도하기
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

function usePaymentWidget(clientKey, customerKey) {
  return useQuery({
    queryKey: ["payment-widget", clientKey, customerKey],
    queryFn: () => {
      // ------  결제위젯 초기화 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
      return loadPaymentWidget(clientKey, customerKey);
    },
  });
}
