import { styled } from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";

import { Typography, Button as MuiButton, Box, Grid, Paper } from "@mui/material";

// export const Container = MuiStyled((props) => (
//   <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px" {...props} />
// ))({
//   boxSizing: "border-box",
//   width: "100%",
//   // minWidth: "800px ",
// });

export const Container = MuiStyled((props) => <Grid container {...props} />)({
  justifyContent: "space-between",
});

export const GridItem = MuiStyled((props) => <Grid item xs={5} {...props} />)({
  backgroundColor: "#fff",
  borderRadius: "10px",
  overflow: "hidden",
  boxShadow: "0px 3px 6px #0000004d",
});

export const GridBox = MuiStyled((props) => (
  <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" {...props} />
))({
  boxSizing: "border-box",
  width: "100%",
});

// export const Header = MuiStyled((props) => <Box gridColumn="span 4" {...props} />)({
//   height: "100%",
//   width: "100%",
//   backgroundColor: "#fff",
//   borderRadius: "8px",
//   borderBottomRightRadius: "0px",
//   borderBottomLeftRadius: "0px",
//   boxShadow: "0px 2px 2px #ababab",
//   padding: "20px",
//   paddingBottom: "10px",
//   boxSizing: "border-box",
// });

export const Header = MuiStyled((props) => <Box {...props} />)({
  backgroundColor: "#f1f1f1",
  padding: "10px 25px",
  boxShadow: "0px 3px 6px #00000029",
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

export const HeaderTitle = MuiStyled((props) => <Typography {...props} />)({
  font: "500 24px Pretendard",
  color: "#808080",
});

export const PlanBox = MuiStyled((props) => <Box {...props} />)({
  height: "260px",
  paddingTop: "35px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const PlanName = MuiStyled((props) => <Typography {...props} />)({
  font: "700 30px Pretendard",
  textAlign: "center",
  color: "#343434",
});

export const PriceBox = MuiStyled((props) => <Box {...props} />)({
  width: "max-content",
  textAlign: "center",
  marginTop: "45px",
  color: "#343434",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  // "> div": {
  //   backgroundColor: "red",
  //   textAlign: "left",
  // },
});

export const Price = MuiStyled((props) => <Typography {...props} />)({
  font: "700 38px Pretendard",
  color: "#343434",
  "> span": {
    font: "500 16px Pretendard",
    color: "#808080",
  },
});

export const BeforeDisCountPrice = MuiStyled((props) => <Typography {...props} />)({
  font: "500 16px Pretendard",
  color: "#7CCC73",
  textDecoration: "line-through",
  textAlign: "left",
});

export const DiscountPrice = MuiStyled((props) => <Typography {...props} />)({
  font: "600 30px Pretendard",
  color: "#c33c3c",
  textAlign: "center",
});

export const ExplainBox = MuiStyled((props) => <Box {...props} />)({
  height: "134px",
  backgroundColor: "#f1f1f1",
  padding: "20px 66px",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
});

export const ExplainContent = MuiStyled((props) => <Box {...props} />)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const ExplainContentBox = MuiStyled((props) => <Box {...props} />)({
  display: "flex",
  justifyContent: "space-between",
});

export const UserLimitBox = MuiStyled((props) => <Box {...props} />)({
  display: "flex",
  gap: "15px",
  alignItems: "center",
});

export const TeamLimitBox = MuiStyled((props) => <Box {...props} />)({
  display: "flex",
  gap: "15px",
});

export const ExplainTitle = MuiStyled((props) => <Typography {...props} />)({
  font: "500 18px Pretendard",
  color: "#808080",
  lineHeight: "0.8",
  "> span": { font: "500 10px Pretendard" },
});

export const ExplainContentText = MuiStyled((props) => <Typography {...props} />)({
  font: "500 18px Pretendard",
  color: "#343434",
});

export const ButtonBox = MuiStyled((props) => <Box {...props} />)({
  height: "185px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  padding: "0 40px",
});

export const ButtonBoxExplain = MuiStyled((props) => <Typography {...props} />)({
  font: "500 14px Pretendard",
  fontSize: "13px",
  fontSize: "clamp(13px, 4vw, 14px)",
  color: "#808080",
  textAlign: "center",
});

export const PlanButton = MuiStyled((props) => <Button fullWidth variant="contained" {...props} />)(
  {
    font: "700 20px Pretendard",
    padding: "10px 0",
    borderRadius: "12px",
    border: "2px solid #c33c3c",
    color: "#000",
    backgroundColor: "#fff",
    "&:disabled": { color: "white", backgroundColor: "#bebebe", border: "2px solid #bebebe" },
  }
);

export const Body = MuiStyled((props) => <Box gridColumn="span 4" {...props} />)({
  // height: "100%",
  width: "100%",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 2px #ababab",
  padding: "20px",
  paddingBottom: "10px",
  boxSizing: "border-box",
});

export const Footer = MuiStyled((props) => <Box gridColumn="span 4" {...props} />)({
  height: "100%",
  width: "100%",
  backgroundColor: "#fff",
  boxShadow: "0px 2px 2px #ababab",
  borderBottomRightRadius: "8px",
  borderBottomLeftRadius: "8px",
  padding: "20px",
  paddingBottom: "10px",
  boxSizing: "border-box",
});

export const CardTitle = MuiStyled((props) => <Typography {...props} />)({
  font: "bold 24px NotoSansKR-Regular",
  color: "#707070",
});

export const Explain = styled.div`
  margin-top: 10px;
  font: 14px NotoSansKR-Regular;
  color: #707070;
  word-break: keep-all;
`;

export const RedText = styled.span`
  color: #ba1f21;
  ${(props) => props.$emphasis && "font-weight: bold; font-size: 18px;"}
`;

export const VAT = styled.div`
  display: flex;
  justify-content: flex-end;
  font: 14px NotoSansKR-Regular;
  color: #707070;
`;

export const Features = styled.div`
  margin-top: 20px;

  > div:last-child {
    margin-top: 10px;
  }
`;

export const Button = MuiStyled((props) => <MuiButton fullWidth {...props} />)({
  marginTop: "10px",
  border: "3px solid #ba1f21",
  color: "#606060",
  borderRadius: "6px",
  font: "16px NotoSansKR-Medium",
  boxShadow: "2px 2px 4px #ababab",
  "&:hover": {
    backgroundColor: "#ba1f21",
    color: "#fff",
    marginTop: "7px",
  },
  "&:disabled": {
    backgroundColor: "#eaeaea",
    color: "#60606080",
    border: "3px solid #ba1f2180",
    boxShadow: "none",
  },
  "&.test": {
    backgroundColor: "#ba1f21",
  },
});
