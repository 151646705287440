import React, { createContext, useContext, useEffect, useRef } from "react";
import io from "socket.io-client";

// Create a context to hold the socket instance
const SocketContext = createContext();

// Custom hook to access the socket instance
export const useSocket = (url) => {
  const socketRef = useRef();

  // Initialize socket if it doesn't exist
  if (!socketRef.current) {
    socketRef.current = io(url);
  }

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  return socketRef.current;
};

// Component to provide the socket instance
export const SocketProvider = ({ children }) => {
  return <SocketContext.Provider value={useSocket}>{children}</SocketContext.Provider>;
};
