import React, { useEffect, useRef, useState } from "react";
import { QueryClientProvider, useQuery, QueryClient } from "@tanstack/react-query";
import {
  PaymentWidgetInstance,
  loadPaymentWidget,
  ANONYMOUS,
} from "@tosspayments/payment-widget-sdk";
import { useLocation } from "react-router-dom";
import axios from "axios";

import { Button } from "@mui/material";

import styles from "../styles/payment.module.css";
import config from "views/PaymentPage/config";

import logo from "assets/images/wLiveLogo(Red).png";
import { useCookies } from "react-cookie";

const queryClient = new QueryClient();

const clientKey = config.TOSS_PAYMENT_WIDGET_CLIENT_KEY;
const selector = "#payment-widget";

export default () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Payment />
    </QueryClientProvider>
  );
};

const Payment = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const baseURL = window.location.origin;

  const { data: paymentWidget } = usePaymentWidget(clientKey, ANONYMOUS);
  const paymentMethodsWidgetRef = useRef(null);
  const { state } = useLocation();
  const [cookies] = useCookies(["auth"]);

  const [paymentMethodsWidgetReady, isPaymentMethodsWidgetReady] = useState(false);
  const [productName, setProductName] = useState("");
  const [count, setCount] = useState(0);
  const [price, setPrice] = useState(50_000);

  const requestPayment = () => {
    const params = {
      orderName: `라이브 핵사그램 결제 - ${count}토큰`,
      phone: searchParams.get("phone"),
      company: searchParams.get("company"),
      auth: cookies.auth,
      totalAmount: price,
    };

    axios.post("/api/payment/request-order", params).then((res) => {
      const { status, data: response } = res;
      const { order } = response;
      const product = searchParams.get("product");

      paymentWidget
        .requestPayment({
          orderId: order._id,
          orderName: params.orderName,
          customerName: searchParams.get("name"),
          successUrl: `${baseURL}/diagnosis-plan/payment/pending?product=${product}&count=${count}`,
          failUrl: `${baseURL}/diagnosis-plan/payment/fail?order-id=${order._id}`,
        })
        .catch((error) => {
          axios.post("/api/payment/cancel-order", { orderId: order._id });
        });
    });
  };

  useEffect(() => {
    if (paymentWidget == null) {
      return;
    }

    // ------  결제 UI 렌더링 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
    const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
      selector,
      { value: price },
      { variantKey: "DEFAULT" }
    );

    // ------  이용약관 UI 렌더링 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
    paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });

    //  ------  결제 UI 렌더링 완료 이벤트 ------
    paymentMethodsWidget.on("ready", () => {
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
      isPaymentMethodsWidgetReady(true);
    });
  }, [paymentWidget]);

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current;

    if (paymentMethodsWidget == null) {
      return;
    }

    // ------ 금액 업데이트 ------
    // @docs https://docs.tosspayments.com/reference/widget-sdk#updateamount결제-금액
    paymentMethodsWidget.updateAmount(price);
  });

  useEffect(() => {
    // if (!state) return;

    // setPrice(state.finalPrice);

    switch (searchParams.get("product")) {
      case "hexagram":
        // setPrice(10_000);
        setProductName("라이브 헥사그램");
        break;
      default:
        break;
    }

    setCount(Number(searchParams.get("count")));
    setPrice(Number(searchParams.get("totalPrice")));
  }, []);

  return (
    <div className={styles.payment}>
      <div className={styles.body}>
        <div className={styles.logo}>
          <img src={logo} alt="logo" />

          <p>Withplus Live - 상품 결제</p>
        </div>

        <div className={styles.info}>
          <p className={styles.title}>상품정보</p>

          <div>
            <p>상품명: </p>

            <p>{productName}</p>
          </div>

          <div>
            <p>수량: </p>

            <p>{count}개</p>
          </div>

          <div>
            <p>가격: </p>

            <p>{price.toLocaleString()}원</p>
          </div>
        </div>

        <div id="payment-widget" />

        <div id="agreement" />

        <Button variant="contained" className={styles.payment_btn} onClick={requestPayment}>
          {price.toLocaleString()}원 결제하기
        </Button>
      </div>
    </div>
  );
};

const usePaymentWidget = (clientKey, customerKey) => {
  return useQuery({
    queryKey: ["payment-widget", clientKey, customerKey],
    queryFn: () => {
      // ------  결제위젯 초기화 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
      return loadPaymentWidget(clientKey, customerKey);
    },
  });
};
