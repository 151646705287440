import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { useCookies } from "react-cookie";

import { Box, Typography, TextField, CircularProgress } from "@mui/material";

import LogoSVG from "../assets/images/wliveLogo.png";
import { checkCode } from "../utils/api";
import { CustomLoadingButton as Button } from "./../components/Button";
import { QRCodeCanvas } from "qrcode.react";

const CodePage = () => {
  const navigate = useNavigate();
  const depth = window.location.href.split("/");
  const baseURL = `${depth[0]}//${depth[2]}`;
  // const [, setCookie] = useCookies();

  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCode = (e) => {
    setCode(e.target.value);
  };

  const submitCode = (e) => {
    e.preventDefault();

    if (code === "") return;

    if (code.length !== 6) {
      alert("6자리 코드를 입력해주세요");
      return;
    }

    setLoading(true);

    checkCode(code, (res) => {
      let data = res.data;
      let project = data.project;
      // 이걸 바로 QR로 만들어서 도메인에 박아버리면 되지 않을까..
      // console.log(data.project._id);

      setTimeout(() => {
        console.log(data);
        if (data.success) {
          switch (data.type) {
            case "Live Polling":
              // navigate(`/app/poll/${project.type}/${data.project._id}`);
              // window.location.href = `${depth[0]}//${depth[2]}/app/poll/${project.type}/${data.project._id}`;
              // window.location.href = baseURL + `/app/poll/wordcloud/${data.project._id}`;
              // window.location.href = baseURL + `/poll/user/${data.project._id}`;
              navigate(`/polling/user/${project._id}`);
              break;

            case "Live Quiz":
              // setCookie("uid", data.uid);
              // navigate(`/app/quiz/${data.project._id}`);
              // window.location.href = `${depth[0]}//${depth[2]}/app/quiz/${data.project._id}`;
              window.location.href = baseURL + `/app/quiz/${data.project._id}`;
              break;

            // case "Great Thinking":
            //   // navigate(`/pick/user/${data.project._id}`);
            //   window.location.href = baseURL + `/pick/user/${data.project._id}`;
            //   break;

            // case "Great Deal":
            //   // navigate(`/deal/user/${data.project._id}`);
            //   window.location.href = baseURL + `/deal/user/${data.project._id}`;
            //   break;

            // case "Great Pick":
            //   // navigate(`/pick/user/${data.project._id}`);
            //   window.location.href = baseURL + `/pick/user/${data.project._id}`;
            //   break;

            case "Live Pick":
              window.location.href = baseURL + `/pick/user/${data.project._id}`;
              break;

            case "Live Deal":
              window.location.href = baseURL + `/deal/user/${data.project._id}`;
              break;

            case "Egogram":
              window.location.href = baseURL + `/egogram/user/${data.project._id}`;
              break;

            case "Live Board":
              navigate(`/board/user/${data.project._id}`);
              // window.location.href = baseURL + `/board/user/${data.project._id}`;
              break;

            case "Hexagram":
              navigate(`/hexagram/user/${data.project._id}`);
              break;

            case "Live Tap":
              navigate(`/tap/user/${data.project._id}`);
              break;

            default:
              break;
          }
        } else {
          alert("코드를 정확히 입력해주세요.");
          setLoading(false);
        }
      }, 1000);
    });
  };
  return (
    <Box sx={{ backgroundColor: "#f5f5f5", minHeight: "calc(var(--vh, 1vh) * 100)" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: { xs: "50%", lg: "20%" }, p: 1, boxSizing: "border-box" }}>
          <img src={LogoSVG} style={{ width: "100%" }} alt="logo" />
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
          width: { xs: "90%", lg: "50%" },
        }}
      >
        <Typography
          sx={{
            mb: "20px",
            font: { xs: "bold 28px NotoSansKR-Medium", lg: "bold 30px NotoSansKR-Medium" },
            px: "10px",
          }}
        >
          접속 코드를 입력해주세요
        </Typography>
        <Box
          type="submit"
          component="form"
          sx={{
            mx: { xs: "10px", lg: "" },
          }}
          onSubmit={submitCode}
        >
          <TextField
            fullWidth
            sx={{
              mb: "20px",
              input: {
                backgroundColor: "#9f9f9f",
                borderRadius: "10px",
                textAlign: "center",
                "&:focus::placeholder": { color: "transparent" },
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
                font: "18px NotoSansKR-Medium",
              },
            }}
            type="tel"
            placeholder="접속코드 입력"
            autoComplete="off"
            onChange={handleCode}
            value={code}
          />
        </Box>

        <Button
          size="large"
          sx={{
            font: "24px NotoSansKR-Medium",
            width: { xs: "70%", lg: "50%" },
            borderRadius: "10px",
            color: "white",
          }}
          onClick={submitCode}
          loading={loading}
          loadingIndicator={<CircularProgress size={24} sx={{ color: "#fff" }} />}
        >
          제출
        </Button>
      </Box>
    </Box>
  );
};

export default CodePage;
