import { IconButton } from "@mui/material";
import { styled } from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";

import coupon from "assets/images/coupon.svg";

import DownloadIcon from "@mui/icons-material/Download";

export const Coupon = styled.div`
  position: relative;

  width: 390px;
  height: 100px;

  background-image: url(${coupon});
  filter: drop-shadow(1px 1px 2px #ababab);

  margin: 10px 5px;
  padding: 10px;
  box-sizing: border-box;

  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  transition: all 0.2s ease-in-out;
`;

export const CouponContent = styled.div`
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CouponTitle = styled.div`
  font: bold 18px NotoSansKR-Regular;
`;

export const CouponPrice = styled.div`
  font: 800 24px NotoSansKR-Regular;
  color: #c33c3c;
`;

export const DownloadButton = MuiStyled((props) => (
  <IconButton
    size="small"
    children={<DownloadIcon sx={{ color: "white", fontSize: "16px !important" }} />}
    {...props}
  />
))({
  position: "absolute",
  top: "-10px",
  right: "-10px",
  backgroundColor: "#c33c3c",

  "&:hover": {
    backgroundColor: "#c33c3c",
  },
});
