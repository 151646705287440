import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, {useEffect, useState} from "react";
import { CookiesProvider, useCookies } from 'react-cookie';
import { v4 as uuidv4 } from "uuid";

import Layout from "./components/Layout";
import LoginPage from "./views/LoginPage/LoginPage";
import RegisterPage from "./views/RegisterPage/RegisterPage";
import DashBoardPage from "./views/DashBoardPage";

import Auth from "./auth/auth";


import LandingPage from "./views/LandingPage";

import PasswordPage from "./views/PasswordPage";
import CertificationPage from "./views/CertificationPage";
import PlanPage from "views/PlanPage";
import PayPage from "./views/PayPage";
import GameCodePage from './views/CodePage'
import KakaoOauth, { KakaoPopup } from './views/oAuth/Kakao';

import KAKAO from './config/kakao'

import "./App.css";
import "assets/fonts/pretendard.css";
import "assets/fonts/pretendard-subset.css";
import 'views/LivePolling/style.css';

import { createTheme, ThemeProvider } from "@mui/material/styles";
import QuizListPage from "views/LiveQuiz/QuizListPage";
import QuizEditPage from "views/LiveQuiz/QuizEditPage";
import QuizSlidePage from "views/LiveQuiz/QuizSlidePage";
import QuizUserPage from "views/LiveQuiz/QuizUserPage";
import { SnackBarProvider } from "components/SnackBar/ContextAPI";
import NaverOauth from "./views/oAuth/Naver";
import { GoogleOauth } from "views/oAuth/Google";
import LogoutPage from "views/Logout";
import PaymentPage from "views/PaymentPage";
import CallbackPage from "views/CallbackPage";
import GhostLeg from "views/SimpleGame/GhostLeg/GhostLeg";
import Roulette from "views/SimpleGame/Roulette/Roulette";
import ArrowSpinning from "views/SimpleGame/ArrowSpinning/ArrowSpinning";
import { SimpleGameRoultte } from "views/SimpleGame/Roulette/SimpleGameRoulette";
import { SimpleGameArrowSpinning } from "views/SimpleGame/ArrowSpinning/SimpleGameArrowSpining";
import { SimpleGameGhostLeg } from "views/SimpleGame/GhostLeg/SimpleGameGhostLeg";

import { SocketProvider } from "contexts/Socket";

import LivePollingContentPage from "views/ContentsPage/LivePolling";
import LiveQuizContentPage from "views/ContentsPage/LiveQuiz";
import LiveBoardContentPage from "views/ContentsPage/LiveBoard";
import EgogramContentPage from "views/ContentsPage/Egogram/EgogramPage";
import LivePickContentPage from "views/ContentsPage/LivePick";
import LiveDealContentPage from "views/ContentsPage/LiveDeal";
import LivePollingPage from "views/LivePolling";
import LiveHexagram from "views/LiveHexagram";
import LiveBoard from "views/LiveBoard"; // * 라이브 보드 페이지
import ChangePasswordPage from "views/ChangePasswordPage";
import DiagnosisPlanPage from "views/DiagnosisPlanPage";
import LiveTap from "views/LiveTap";
import Redirect from "middleware/redirect";

/** 테마 설정 */
const theme = createTheme({
  typography: {
    fontFamily: ["Pretendard"],
    button: {
      textTransform: "none",
    },
  },
  input:{
    fontFamily:"NotoSansKR-Medium"
  },
  palette: {
    primary: {
      light: "#ff7961",
      main: "#c33c3c",
      dark: "#ba000d",
      contrastText: "#fff",
    },
    secondary: {
      light: "#757ce8",
      main: "#3f50b5",
      dark: "#002884",
      contrastText: "#fff",
    },
  },
});

function App(props) {
  const [cookies, setCookie] = useCookies()
  /** 스크린 사이즈 맞춤 설정 */
  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  window.addEventListener('resize', setScreenSize);

  useEffect(() => {
    setScreenSize();

    if(!cookies.uid) {
      const maxAge = 60 * 60 * 24 * 365;
      setCookie("uid", uuidv4(), { path: "/", maxAge });
    }
    try{
      window.Kakao.init(KAKAO.JAVASCRIPT_KEY);
    }catch(e){
      // console.log(e);
    }

    console.log(window.Kakao.isInitialized());
  }, []);

  

  // window.Kakao.Auth.logout()
  // .then(function(response) {
  //   console.log(window.Kakao.Auth.getAccessToken()); // null
  // })
  // .catch(function(error) {
  //   console.log('Not logged in.');
  // });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <SocketProvider>
          <CookiesProvider>
            <SnackBarProvider>
              <Layout>
                <Routes>
                  {/* 모두 접근 가능한 Page */}
                  <Route exact path='/' element={<LandingPage/>} /> {/* 랜딩페이지 */} 
                  <Route exact path='/password' element={<PasswordPage/>} /> {/* 비밀번호 찾기 페이지 */}
                  <Route exact path='/password/change' element={<ChangePasswordPage/>} /> {/* 비밀번호 변경 페이지 */}
                  <Route exact path='/plan' element={<PlanPage/>}/> {/* 구독 플랜 페이지 */}
                  <Route exact path='/diagnosis-plan/*' element={<DiagnosisPlanPage/>}/> {/* 구독 플랜 페이지 */}
                  <Route exact path='/content/poll' element={<LivePollingContentPage/>}/>{/* 라이브 폴 설명 페이지 */}
                  <Route exact path='/content/quiz' element={<LiveQuizContentPage />}/>{/* 라이브 퀴즈 설명 페이지 */}
                  <Route exact path='/content/board' element={<LiveBoardContentPage />}/>{/* 라이브 퀴즈 설명 페이지 */}
                  <Route exact path='/content/deal' element={<LiveDealContentPage/>}/>{/* 라이브 딜 설명 페이지 */}    
                  <Route exact path='/content/pick' element={<LivePickContentPage/>}/>{/* 라이브 딜 설명 페이지 */}            
                  <Route exact path='/content/egogram' element={<EgogramContentPage/>}/>

                  {/* 로그인한 유저는 접근 불가능한 Page */}
                  <Route exact path='/login' element={<Auth Component={LoginPage} isAuthRequired={false}/>} /> {/* 로그인 페이지 */}
                  <Route axact path="/logout/*" element={<LogoutPage/>}/>
                  <Route exact path='/signup' element={<Auth Component={RegisterPage} isAuthRequired={false}/>} /> {/* 회원가입 페이지 */}
                  <Route exact path='/certification' element={<Auth Component={CertificationPage} isAuthRequired={false}/>} /> {/* 인증코드 입력 페이지 */}
                  
                  {/* 로그인 못한 유저는 접근 불가능한 Page */}
                  <Route exact path='/dashboard/*' element={<Auth Component={DashBoardPage} isAuthRequired={true}/> } /> {/* 대시보드 페이지 */}
                  <Route exact path='/pay' element={<Auth Component={PayPage} isAuthRequired={true}/>}/> {/* 구독 결제 페이지 */}
                  {/* <Route exact path='/payment/*' element={<Auth Component={PaymentPage} isAuthRequired={true}/>}/> 구독 결제 페이지 */}
                  <Route exact path='/payment/*' element={<PaymentPage/>}/> {/* 구독 결제 페이지 */}

                  {/* Live Polling */}
                  <Route exact path='/polling/*' element={<LivePollingPage/>} />


                  {/* Live Quiz */}
                  <Route exact path='/features/quiz' element={<Auth Component={QuizListPage} isAuthRequired={true}/>}/> {/* 라이브 퀴즈 리스트 페이지 */}
                  <Route exact path='/features/quiz/edit/:id' element={<Auth Component={QuizEditPage} isAuthRequired={true}/>}/> {/* 라이브 퀴즈 편집 페이지 */}
                  <Route exact path='/features/quiz/app/:id' element={<Auth Component={QuizSlidePage} isAuthRequired={true}/>}/> {/* 폴링 진행 페이지 */}

                  {/* Live Board */}
                  <Route exact path="/board/*" element={<LiveBoard />} /> {/* 라이브 보드 페이지 */}

                  {/* Live Hexagram */}
                  <Route exact path="/hexagram/*" element={<LiveHexagram />} /> {/* 라이브 핵사그램 페이지 */}


                  {/* Live Tap */}
                  <Route exact path="/tap/*" element={<LiveTap />} /> {/* 라이브 런 페이지 */}

                  
                  {/* Game Code 입력 Page */}
                  <Route exact path='/code' element={<GameCodePage/>}/> {/* 폴링 편집 페이지 */}

                  {/* User 진행 페이지 */}
                  <Route exact path='/app/quiz/:id' element={<QuizUserPage/>}/> {/* 퀴즈 페이지 */}

                  {/* OAuth Redirect 페이지 */}
                  <Route exact path="/oauth/kakao" element={<KakaoOauth/>}/>
                  <Route exact path="/oauth/naver" element={<NaverOauth/>}/>
                  <Route exact path="/oauth/google" element={<GoogleOauth/>}/>

                  {/* 팝업 페이지 */}
                  <Route exact path="/oauth/popup/kakao" element={<KakaoPopup/>}/>

                  {/* tosspayments callback 페이지 */}
                  <Route exact path="/callback/*" element={<CallbackPage/>} />

                  {/*간단게임 페이지 */}
                  <Route exact path="/simpleGame/ghostLeg" element={<GhostLeg/>}/>
                  <Route exact path="/simpleGame/Roulette" element={<Roulette/>}/>
                  <Route exact path="/simpleGame/ArrowSpinning" element={<ArrowSpinning/>}/>
                  <Route exact path="/simpleGame/SimpleGameRoultte" element = {<SimpleGameRoultte/>}/>
                  <Route exact path="/simpleGame/SimpleGameArrowSpinning" element={<SimpleGameArrowSpinning/>}/>
                  <Route exact path="/simpleGame/SimpleGameGhostLeg" element={<SimpleGameGhostLeg/>}/>

                  <Route exact path="/redirect" element={<Redirect/>}/>
                </Routes>
              </Layout>
            </SnackBarProvider>
          </CookiesProvider>
        </SocketProvider>
        
      </Router>
      
    </ThemeProvider>
  );
}

export default App;
