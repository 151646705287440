// const kakao = {
//     JAVASCRIPT_KEY: "effabfca853c30c852053e4b44efd5c2",
//     REST_API_KEY: "e79fc5994ea6cf220c38cfb994da9b9c",
//   };

const kakao = {
  JAVASCRIPT_KEY:
    process.env.NODE_ENV === "development"
      ? "effabfca853c30c852053e4b44efd5c2"
      : "52eda90c3420a6ba095bdec5b98354fe",
  REST_API_KEY:
    process.env.NODE_ENV === "development"
      ? "e79fc5994ea6cf220c38cfb994da9b9c"
      : "0bb882356c90a476d4a1c0777922f6cd",
  REDIRECT_PAGE:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/oauth/kakao"
      : "https://www.withplus.live/oauth/kakao",
  LOGOUT_REDIRECT_URI:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/logout/kakao"
      : "https://www.withplus.live/logout/kakao",
};

export default kakao;
