// * npm modules
import React, { useContext } from "react";

// * material UI components
import { Box, Typography } from "@mui/material";

// * 프로젝트 컴포넌트
import { UserContext } from "../contexts/UserContext";
import logo from "assets/images/ContentPage/LivePolling/characterImg.png";

// * styles
import * as S from "./styles";

// * 인트로 컴포넌트
export default () => {
  const { playingData, currentSlideIndex } = useContext(UserContext); // * 플레이 데이터, 현재 슬라이드 인덱스

  return (
    <S.Intro>
      {/* 인트로 타이틀 */}
      <Typography sx={{ font: "700 32px Pretendard", mb: "30px" }}>
        {playingData[currentSlideIndex]?.title}
      </Typography>

      {/* 라이브폴링 로고*/}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <S.Logo src={logo} alt="logo" width={"60%"} $filter />
      </Box>

      {/* 라이브폴링 */}
      <Typography
        sx={{ font: "700 24px Pretendard", mt: "10px", textAlign: "center", color: "#c33c3c" }}
      >
        라이브 폴링
      </Typography>

      {/* 대기 텍스트 */}
      <Typography
        sx={{
          font: "700 16px Pretendard",
          mb: "30px",
          textAlign: "center",
          wordBreak: "keep-all",
          color: "#c33c3c",
          mt: "10px",
        }}
      >
        진행자가 다음 슬라이드로 넘어가기 전까지 대기해주세요!
      </Typography>
    </S.Intro>
  );
};
