import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Typography, IconButton, Menu } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PeopleIcon from "@mui/icons-material/People";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CheckIcon from "@mui/icons-material/Check";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import Logo from "assets/images/wLiveLogo(Red).png";
import { QuizSlideContext } from "../contexts/QuizSlide";
import { AudioContext } from "contexts/Audio";
import { FullScreenContext } from "contexts/FullScreen";
import Avartar from "assets/images/Avartar";

export default ({ title, isStatic }) => {
  const navigate = useNavigate();

  const { playingData, users, setAccessCodeOpened, isAccessCodeOpened } =
    useContext(QuizSlideContext);
  const { bgmVolumn, setBgmVolumn } = useContext(AudioContext);
  const { handle, handleFullScreen } = useContext(FullScreenContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMuteSound = () => {
    if (bgmVolumn === 0) {
      setBgmVolumn(0.25);
    } else {
      setBgmVolumn(0);
    }
  };

  const handleBGMVolume = (e) => {
    setBgmVolumn((e.target.value * 1) / 200);
  };

  const openParticipants = (e) => {
    // console.log("openParticipants");
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseTab = () => {
    if (title === "접속 방법") {
      // alert("창을 닫을 수 없습니다.");
      setAccessCodeOpened(!isAccessCodeOpened);
      return;
    }

    window.confirm("퀴즈 화면에서 리스트 화면으로 돌아가시겠습니까?") && navigate("/features/quiz");
  };

  return (
    <Box
      sx={{
        position: isStatic ? "static" : "absolute",
        top: 0,
        width: "100%",
        height: "clamp(34.375px, 2.864vw, 55px)",
        boxSizing: "border-box",
        bgcolor: "rgb(214, 226, 251)",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "10px 10px 0 0",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", gap: "10px", p: "15px" }}>
          <Box
            sx={{
              width: "clamp(11.25px, 0.9375vw, 18px)",
              height: "clamp(11.25px, 0.9375vw, 18px)",
              borderRadius: "50%",
              bgcolor: "rgb(237, 106, 94)",
              position: "relative",
              cursor: "pointer",
              "& > svg": { display: "none" },

              "&:hover": {
                "& > svg": { display: "block" },
              },
            }}
            onClick={handleCloseTab}
          >
            <CloseIcon
              sx={{
                fontSize: "clamp(10px, 0.83vw, 16px)",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </Box>
          <Box
            sx={{
              width: "clamp(11.25px, 0.9375vw, 18px)",
              height: "clamp(11.25px, 0.9375vw, 18px)",
              borderRadius: "50%",
              bgcolor: "rgb(245, 191, 79)",
            }}
          />
          <Box
            onClick={handleFullScreen}
            sx={{
              width: "clamp(11.25px, 0.9375vw, 18px)",
              height: "clamp(11.25px, 0.9375vw, 18px)",
              borderRadius: "50%",
              bgcolor: "rgb(97, 195, 83)",
              position: "relative",
              cursor: "pointer",
              "& > svg": { display: "none" },

              "&:hover": {
                "& > svg": { display: "block" },
              },
            }}
          >
            {handle.active ? (
              <FullscreenExitIcon
                sx={{
                  fontSize: "clamp(10px, 0.83vw, 16px)",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            ) : (
              <FullscreenIcon
                sx={{
                  fontSize: "clamp(10px, 0.83vw, 16px)",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            )}
          </Box>
        </Box>

        <Box sx={{ height: "100%", display: "flex" }}>
          <Box sx={{ width: "10px", bgcolor: "#fff" }}>
            <Box
              sx={{
                bgcolor: "rgb(214, 226, 251)",
                height: "100%",
                width: "12px",
                borderRadius: "0 0 12px 0",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              height: "100%",
              bgcolor: "#fff",
              // bgcolor: "red",
              minWidth: "200px",
              mt: "5px",
              borderRadius: "12px 12px 0 0",
              px: "10px",
              pb: "5px",
              boxSizing: "border-box",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <img src={Logo} style={{ width: "clamp(15.625px, 1.302vw, 25px)" }} />

              <Typography
                sx={{ font: "700 20px Pretendard", fontSize: "clamp(12.5px, 1.0416vw, 20px)" }}
              >
                {title}
              </Typography>
            </Box>

            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                borderRadius: "50%",
                "&:hover": {
                  bgcolor: "#f0f0f0",
                },
              }}
              onClick={handleCloseTab}
            >
              <CloseIcon sx={{ fontSize: "clamp(12.5px, 1.0416vw, 20px)" }} />
            </Box>
          </Box>

          <Box sx={{ width: "10px", bgcolor: "#fff" }}>
            <Box
              sx={{
                bgcolor: "rgb(214, 226, 251)",
                height: "100%",
                width: "12px",
                borderRadius: "0 0 0 12px",
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: title !== "접속 방법" ? "flex" : "none",
          alignItems: "center",
          gap: "10px",
          pr: "10px",
        }}
      >
        {title !== "참여 대기방" && title !== "인트로" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: "#fff",
              borderRadius: "20px",
              p: "5px 10px",
              cursor: "pointer",
              "&:hover": {
                bgcolor: "#eee",
                transform: "scale(1.05)",
              },

              "&:active": {
                transform: "scale(1) translateY(2px)",
              },
            }}
            onClick={openParticipants}
          >
            <Typography sx={{ font: "600 clamp(11.25px, 0.9375vw, 18px) Pretendard" }}>
              {playingData.submittedAnswers?.length} / {users.length}
            </Typography>
            <PeopleIcon sx={{ fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)", ml: 1 }} />
          </Box>
        )}

        {playingData.isStarted && (
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose} disablePortal>
            <Box
              sx={{
                width: "300px",
                height: "300px",
                p: "10px",
                boxSizing: "border-box",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Typography sx={{ font: "700 24px Pretendard" }}>제출 현황</Typography>

              {users.map((user, index) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    bgcolor:
                      playingData.submittedAnswers.filter((answer) => answer.uid === user.uid)
                        .length === 0
                        ? "#f5f5f5"
                        : "#0d0",
                    borderRadius: "8px",
                    p: "10px",
                  }}
                  key={user.uid}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <img src={Avartar[user.imgIdx]} style={{ width: "35px" }} />
                    <Typography sx={{ font: "700 21px Pretendard" }}>{user.name}</Typography>
                  </Box>

                  {/* {console.log(
                  playingData.submittedAnswers.filter((answer) => answer.uid === user.uid)[0]
                )} */}

                  {playingData.submittedAnswers.filter((answer) => answer.uid === user.uid)
                    .length === 0 ? (
                    <MoreHorizIcon sx={{ fontSize: "27px", color: "#aaa" }} />
                  ) : (
                    <CheckIcon sx={{ fontSize: "27px", color: "#070" }} />
                  )}
                </Box>
              ))}
            </Box>
          </Menu>
        )}

        <Box
          sx={{
            display: "flex",
            borderRadius: "50px",
            border: "1px solid #eee",
            bgcolor: "#fff",
            px: "5px",
          }}
        >
          <input
            type="range"
            min="0"
            max="100"
            value={bgmVolumn * 200}
            onChange={handleBGMVolume}
            style={{
              accentColor: "#c26974",
            }}
          />
          <IconButton size="small" onClick={handleMuteSound}>
            {bgmVolumn === 0 ? (
              <VolumeOffIcon sx={{ fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)" }} />
            ) : (
              <VolumeUpIcon sx={{ fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)" }} />
            )}
          </IconButton>
        </Box>

        <IconButton
          onClick={handleFullScreen}
          sx={{
            boxShadow: "0px 0px 13px 2px rgba(0,0,0,0.2)",
            bgcolor: "#fff",
            ":hover": {
              bgcolor: "#eee",
            },
          }}
        >
          {handle.active ? (
            <FullscreenExitIcon sx={{ fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)" }} />
          ) : (
            <FullscreenIcon sx={{ fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)" }} />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};
