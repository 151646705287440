export const ActorTypeMobile = [
  {
    type: "행동형",
    typeDescription: `수완이 좋고 매력적이며 적응력이 뛰어납니다. 추진력과 승부근성이 행동형의 강점입니다. 이들은 매우 매력적입니다. 이들은 위협을 하거나 유혹을 하여 자신이 원하는 것을 취할 수 있습니다. 그리고 외부의 자극을 추구합니다. 이들은 판매업에서 주로 성공하며, 기금모금이나 새로운 프로젝트를 시작하는 일을 잘합니다. 카리스마가 있어서 정치에 진출 하기도 하고 다른 공공 영역에서 영향력을 발휘하기도 하며, 훌륭한 기업가가 되기도 합니다.`,
  },
  {
    typeWord: `“밀어 붙여서 안되는게 어딨어!”, “어떤 상황도 해결해야 해!”`,
    keyWord: `실행, 도전, 변화, 설득력, 성과중심(단기성과)`,
    typeDescription: `추진력과 강한 승부근성이 있습니다. 기회에 민감하고 강한 도전정신을 보입니다. 변화분위기를 창출하고 변화를 창출하기 위한 비범한 방법을 구사합니다. 나아가야 할 방향에 대해 구성원들을 이해시키는 의사소통능력이 뛰어나 구성원들이 전달 메시지를 이해하고 일체화 할 수 있게 만듭니다. 다른 사람들이 함께 행동하게 만듭니다.`,
  },
  {
    pros: `매사에 직접적입니다
. 추진력이 뛰어납니다 
. 설득력이 높습니다
. 좋은(빠른) 결과를 만들어내려고 애씁니다
. 자신감이 넘칩니다 
. 활력이 넘칩니다`,
    cons: `경쟁심이 강합니다
. 주도권을 쥐려고 합니다
. 남에게 이것 저것 요구합니다
. 다른 사람의 감정을 이해하려고 하지 않습니다
. 참을성이 없습니다 
. 진전이 느리면 짜증을 냅니다 
. 소극적인 태도를 보이기도 합니다`,
  },
  {
    desire: `빠른 결과(즉각적인 보상)을 만들어내고자 하는 욕구. 상황을 통제하고 싶은 욕구. 명확하고 분명한 상황을 알고 싶은 욕구. 도전적인 일을 하고 싶은 욕구. 존재감을 드러내고 싶은 욕구.`,
    stress: `상대를 부추겨서 자신이 생각한 대로 움직입니다
. 도움이 필요한 사람에게 자신의 일은 자신이 해야 한다는 태도를 취합니다
. 자신에게 유리하게 이야기와 상황을 만들고, 유리하게 해석 하려합니다.
. 규칙을 깨고 자신은 특별하다는 태도를 취합니다
. 일부러 갈등을 만듭니다
. 자신에게 필요 없다고 생각되는 사람과의 관계를 끊습니다`,
    stressManage: `도전적인 환경이나 일을 만듭니다
. 즉각적이고 빠르게 성취할 수 있는 일을 합니다 
. 스스로에게 관심과 보상을 자주 줍니다
. 몸으로 할 수 있는 다양한 취미 활동을 합니다
. 모임에서 리더의 역할을 맡습니다`,
  },
  {
    relationship: `자신이 주도권을 쥡니다
. 사람들을 자신의 통제 하에 두고 싶어합니다`,
    communication: `듣는 것 보다 말하는 것을 선호합니다
. 직선적인 표현을 주로 사용합니다
. 거침없이 말합니다
. 결론을 중심으로 말합니다
. 설득력이 있어 다른 이들을 행동하게 만듭니다`,
    motivation: `높은 수준의 업무나 목표를 제시해야 합니다
. 변화와 도전할 수 있는 환경을 제공해야 합니다
. 활동적인 업무에 배치해야 합니다
. 실행력과 추진력에 대한 인정과 칭찬을 해줘야합니다
. 눈에 보이는 결과나 성과를 알려줘야 합니다
. 성취에 대해 즉각적으로 보상을 줘야 합니다`,
  },
];

export const PersisterTypeMobile = [
  {
    type: "신념형",
    typeDescription: `성실하고 헌신적이며, 매사에 원칙을 준수합니다. 신중함과 인재육성이 이들의 강점입니다. 스스로 먼저 활동하는 것과 타인이 먼저 움직이는 것을 기다리는 빈도가 거의 비슷합니다. 신념형은 매번 세세하게 따지는 경향이 있습니다. 이들은 회계, 경영, 법률, 그리고 세심한 사고능력이 요구되는 영역에서 그 능력을 발휘합니다. 또한 이들은 거의 실수를 하지 않기 때문에 훌륭한 관리자이기도 합니다. 이들은 조정과 통제에 관심이 많습니다.`,
  },
  {
    typeWord: `“최선을 다하는 것은 당연하고 잘하는 것도 중요하다!”, “최고가 되어야 한다”, “이왕 하는 거면 제대로 해야지“`,
    keyWord: `가치, 의미, 신념, 본질, 책임감, 성장과 육성, 중장기 목표와 비전`,
    typeDescription: `탁월한 책임감과 성실함으로 성과를 내기 위해 팀원들을 이끈다. 자신의 핵심 가치에 근거하여 꾸밈없이 자유롭게 리더십 행동을 한다. 매우 높은 도덕적, 윤리적 행동기준을 가지고 있고 항상 올바른 일을 하려고 한다. 공통 비전과 미션을 바탕으로 구성원들을 동기부여 함으로써 하나로 집결시키고 구성원들이 개별 관심사를 뛰어넘도록 한다. 개인의 가치관, 신념, 개별적 업무에 대한 의미 부여 등 질적인 측면에 관심을 갖는다. 성장을 위한 진정성 있는 사실 기반의 피드백을 제공한다. 강력한 역할 모델로서 행동한다.`,
  },
  {
    pros: `성실합니다
. 빈틈이 없습니다
. 헌신적입니다
. 원칙을 준수합니다
. 후배육성 능력이 높습니다 
. 매사에 끈기가 있습니다
. 사명감이 투철합니다
. 평가능력이 좋습니다
. 예의를 중시합니다`,
    cons: `예측하지 못한 상황은 피하려 합니다
. 나의 의견을 타인에게 강요하는 경향이 있습니다
. 자기 주장이 강합니다
. 자신의 의견과 다를 때, 객관적 사실을 신뢰하지 않는 경향이 있습니다
. 과도한 자기 규제를 보입니다
. 긴장상태가 오래갑니다
. 일이 잘 못될 것에 대한 불안감이 높은 편입니다`,
  },
  {
    desire: `신념을 인정받고 싶은 욕구. 헌신(과정)에 대한 인정을 받고 싶은 욕구. 영향력을 주고 싶은 욕구. 타인에게 존경받고 싶은 욕구. 같은 가치를 지닌 동료를 얻고 싶은 욕구.`,
    stress: `트집 잡고 지적하는 모습을 보입니다
. 완곡하지만 상대를 불쾌하게 만드는 말투를 사용합니다
. 본인의 기준으로 부정적 판단을 내리며 지적합니다
. 스스로를 탓하며 우울감과 자괴감에 빠집니다`,
    stressManage: `여행이나 취미를 통한 여유 시간을 가져야 합니다
. 문제의 해결책을 세울 때 안도감을 느낄 수 있습니다
. 해결할 수 있는 것과 없는 것 구분해야 합니다
. 스스로에게 주는 다양한 보상체계를 만들어야 합니다
. 다양한 업무 툴(Tool) 사용으로 업무속도를 향상시켜야 합니다`,
  },
  {
    relationship: `감정이 아닌 사고를 통해 접근 선호합니다
. 사적보다는 업무 중심의 관계 시 편안함입니다
. 서로에게 의미 있는 존재가 되길 원합니다
. 본인도 타인도 완벽을 추구하기를 기대합니다
. 함께 성장하는 관계를 선호합니다`,
    communication: `의미와 가치가 중요합니다
. 의견을 주고 받는 소통방식 선호합니다
. 한 번 신뢰한 사람은 배신하지 않습니다
. 다수보다 소수와의 소통 선호합니다`,
    motivation: `세심한 사고능력이 요구되는 업무에 배치해야 합니다
. 업무를 맡길 때 일의 의미와 가치를 설명해줘야 합니다
. 명확하게 목표를 제시하고 함께 방향성을 합의하는 과정을 거쳐야 합니다
. 중요한 업무의 마지막 점검을 맡겨야 합니다
. 후배 직원의 멘토 역할을 맡겨야 합니다
. 피드백 시 직접 답을 주기보다 성찰을 통해 스스로 수정할 수 있도록 도와줘야 합니다
. 완벽하고 꼼꼼한 업무 결과뿐 아니라 성실한 노력에 대해서도 인정해 줘야 합니다.`,
  },
];

export const DreamerTypeMobile = [
  {
    type: "상상형",
    typeDescription: `사려 깊고 침착하며 상상력이 풍부합니다. 통찰력과 미래지향성이 그들의 강점입니다. 이들은 혼자 있을 때 행복합니다. 이들은 매우 창의적인 사고력을 지니고 있으며 많은 예술적 영역에 종사합니다. 많은 인내를 요구하는 일과 자신이 상상한 것을 구현하는 데에서 탁월한 일꾼입니다. 이들은 친절하고, 함께 지내기 편한 지지적인 사람으로 타인의 생활공간을 존중합니다.`,
  },
  {
    typeWord: `“생각할 시간이 필요해!”, “정리되면 말해줄게!”, “순리대로 하자!” `,
    keyWord: `창의, 방향제시, 다양하고 깊은 사고, 신중함, 인내심, 위임, 인정과 배려`,
    typeDescription: `실행에 옮기기 전까지 독자적으로 충분한 시간을 들여 다각도로 사고하고 결정에 신중합니다.미래지향적이며 구성원들에게 큰 그림과 나아가야 할 방향을 제시합니다.구성원을 믿고 맡기는 경우가 많습니다(권한 위임과 자율성).구성원에게 충분한 지원과 일할 환경을 만들어줍니다.기존에 했던 방식과 차별화된 업무 방식을 선호합니다.`,
  },
  {
    pros: `영감, 직관력이 뛰어납니다 
.상상력이 뛰어납니다
.생각이 깊고 침착합니다
.인내심이 강합니다
.통찰력이 있습니다
.인정이 많습니다
.대인관계를 오래 유지합니다`,
    cons: `갈등을 회피합니다
.깊이 관여하지 않습니다
.지나치게 자기에게 몰입하는 경향이 있습니다
.자주 미루는 버릇이 있습니다
.우유부단합니다
.활기차지 못합니다`,
  },
  {
    desire: `자기성찰을 통해 상황을 이해하고 싶은 욕구. 방해받고 싶지 않고 심리적 여유와 평화롭고 싶은 욕구. 배려와 존중의 욕구. 독립적으로 책임감을 갖고 일하고 싶은 욕구. 나만의 시,공간을 갖고 깊은 욕구.`,
    stress: `점점 말 수가 적어져 말을 하지 않게 됩니다
.지시를 받지 않으면 무엇을 해야할지 혼란스러워합니다
.여러가지 과제를 동시에 해야만 할 때 크게 당황하며 우선순위를 정하는데 어려움을 겪습니다
.사람들과 이야기하지 않아도 되는 상황을 만듭니다
.시작한 일을 중도 포기합니다
.틀어 박혀서 사람들과 교류를 끊습니다
`,
    stressManage: `혼자만의 시간을 충분히 갖어야 합니다 
.혼자 있을 수 있는 공간을 만들어야 합니다
.혼자 여행을 갑니다
.좋아하는 장르의 책을 읽고 공상해야 합니다
.자연과 함께 하는 시간을 늘려야 합니다
.휴대전화, SNS를 잠시 꺼둔니다`,
  },
  {
    relationship: `혼자 있을 때 편안하고 행복합니다
.타인이 먼저 다가와 주기를 원합니다
.관계가 형성되면 타인을 존중하고 친절합니다`,
    communication: `.스스로 생각할 시간을 보장해 주길 원합니다
.혼자만의 공간을 침범하는 느낌을 조심해야 합니다
.메시지는 한번에 하나씩 전달해야 합니다`,
    motivation: `독립적으로 일 할 수 있는 시간과 공간을 제공해야 합니다
.과도한 개입을 지양하고, 지시 후 아웃풋을 재촉하지 않아야 합니다
.동시에 여러 업무를 주지 않아야 합니다
.업무 수행과 관련하여 명확하고 구체적인 지시를 해줘야 합니다
.큰 그림과 새로운 변화가 필요한 미래와 관련한 프로젝트나 업무에 배치해야 합니다
.창의성과 상상력을 펼칠 수 있게 격려해야 합니다`,
  },
];

export const EmoterTypeMobile = [
  {
    type: "감성형",
    typeDescription: `동정심이 풍부하고 섬세하며 따뜻합니다. 사교성과 공감능력이 강점입니다. 감성형은 타인의 시선을 끌려고합니다. 이들은 사람과 사람 사이의 관계를 중시하며, 인생을 파티의 연속으로 만들려 합니다. 그리고 문제해결을 위해서는 먼저 해결하려 하는 자세를 취하며, 타인에게도 먼저 다가가는 경향이 있습니다.`,
  },
  {
    typeWord: `“세심하고 친절해!”, “동정심 있고 따뜻해!”`,
    keyWord: `관계, 공감, 감정, 신뢰`,
    typeDescription: `조직에서 가장 중요한 자원은 사람이라고 생각합니다.구성원과 인간적으로 교감하며 신뢰관계를 빠르게 형성합니다.갈등을 원만히 해결할 수 있으며 팀을 조직하고 팀워크를 이끌어내는 능력이 탁월합니다.경청, 설득과 대화를 통해 업무를 추진하며 팀을 형성합니다.권위적인 모습보다는 공감하고 지원하며 심리적 여유를 만듭니다.조직구성원들에게 진심으로 관심을 갖고 건설적인 피드백과 조언자 역할을 합니다`,
  },
  {
    pros: `동정심이 많습니다
.섬세합니다
.따뜻합니다
.관계를 중시합니다
.감수성이 풍부합니다
.사교성이 좋습니다
.공감능력이 뛰어납니다`,
    cons: `민감하게 반응할 수 있습니다
.감정적입니다
.과민 반응을 할 수 있습니다 
.자기 감정에 매몰됩니다
.타인의 탓으로 돌립니다`,
  },
  {
    desire: `관계속에서 안정을 찾으려는 욕구.개인적인 관심과 인정을 받으려는 욕구.오감만족을 추구하고자 하는 욕구.업무나 관계속에서 즐거운 환경을 만들고자 하는 욕구.`,
    stress: `상대에게 너무 맞추려는 나머지 우유부단하게 됩니다
.상대를 기쁘게 해주려고 무엇이든 받아들여 부담이 과하게 됩니다
.주의력이 떨어지고 무의식적으로 실수를 연발합니다
.기가 죽고, 자기불신, 지기비하, 낮은 자존감을 느낍니다
.자극물(마약, 음주, 극단적 매운 것 등)을 원하게 됩니다
.＂나는 누구에게도 받아들여지지 않는다" 와 같이 거절된 느낌을 갖습니다`,
    stressManage: `개인적인 관심과 인정, 표현을 많이 해줘야 합니다
.친한 사람과 여유있는 시간을 보내야 합니다
.추억의 사진이나 물건을 보이는 곳에 장식해야 합니다
.마사지를 받거나 아로마 향을 피워서 릴렉스해야 합니다
.자신에게 잘 어울리는 치장을 해야 합니다`,
  },
  {
    relationship: `개인적인 관심과 인정을 많이 합니다
.타인이 나를 어떻게 생각하는지가 중요합니다
.타인의 기분이나 분위기를 맞추려 노력합니다
.남을 배려하고 편안하게 합니다
.상대방의 의견을 따르려고 합니다`,
    communication: `친근하고 다정하게 이야기합니다
.감정적인 표현을 많이 합니다
.이야기에 경청하고, 상황에 맞는 리액션을 잘합니다
.개인적인 이야기에도 관심이 많으며, 감정을 읽으려고 애를 씁니다
.편안한 분위기에서 이야기 하는 것을 선호합니다`,
    motivation: `개인적 관심을 통해 신뢰를 형성해야 합니다
.어떤 부분에 어려움을 가지고 있는지 진정성을 같고 경청해야 합니다
.과제를 마칠 때까지 끊임없는 관심과 조언을 해줘야 합니다
.노력하는 부분에 대해 긍정적 피드백을 해줘야 합니다
.개선이 필요한 부분은 구체적으로 피드백하고 체크해줘야 합니다
.적절한 시기에 의사결정을 도와줘야 합니다
.다른 구성원과 협업할 수 있는 기회를 마련해줘야 합니다`,
  },
];

export const AnalystTypeMobile = [
  {
    type: "분석형",
    typeDescription: `논리적이고 조직적이며 책임감이 강합니다. 분석력과 계획성이 강점입니다. 이들은 혼자 있을 때 행복해합니다. 또한 사회적인 관계를 가지려고 할 때 일대일 또는 두세 명 정도의 타인과 관계를 맺는 것을 선호합니다. 이들은 문제해결을 할 때에는 능동적입니다. 분석형은 자신의 말에 대해 책임지는 사람입니다. 이들은 자신이 할 수 있다고 말한 것은 무슨 일이든 해내는 멋진 사람입니다. 또한 사회를 유지하는 기둥 같은 존재라고 말할 수 있습니다.`,
  },
  {
    typeWord: `“일 먼저 하자!”, “근거가 뭔데?”, “사실이야?”`,
    keyWord: `목표, 효율성, 계획, 문제해결, 문제 중심, 합리적`,
    typeDescription: `현재 조직이 추구하는 목표를 달성하거나 문제를 해결하는데 중점을 둔니다.목표 지향적이며, 계획을 세워 완성도 높게 일을 완료합니다.기한내에 마무리하는 것을 중요하게 생각합니다.사실과 근거를 기반으로 업무를 처리합니다.조직의 구조화 방안, 시스템의 체계화, 효과적 보상체계 구축 등에 신경을 씁니다.구성원의 발달 단계를 파악하여 리더십 스타일을 달리하는 것이 가장 효율적이라고 생각하기 때문에 상황대응리더십을 가장 잘 수용하고, 적용합니다`,
  },
  {
    pros: `논리적입니다
.조직적입니다
.책임감이 투철합니다
.분석력이 뛰어납니다
.계획성이 높습니다`,
    cons: `일 중독 현상을 보일 수 있습니다
.과잉 억제를 보입니다
.과도한 긴장감으로 현재를 즐기지 못합니다
.비판적입니다`,
  },
  {
    desire: `업무 성취 및 전문성에 대해 인정받고 싶어하는 욕구.효율적인 것을 추구하는 욕구.계획에 따라 움직이는 욕구.`,
    stress: `생각이 지나치게 많아져 과도하게 변명하거나 장황하게 설명합니다
.대화 도중 인내심이 없어지며 큰 소리로 먼저 대답해 버립니다
.다른 사람에게 업무를 맡기지 못하고 본인이 먼저 일을 해버립니다
.지시나 명령을 통해 다른 사람을 통제하려고 합니다
.과정이 틀어지는 것에 화를 내고 규칙, 책임 등에 꼼꼼하고 까다로워집니다
.자신이 하려는 일을 타인이 먼저 지적하면 화를 냅니다
.업무 성과가 없거나 계획적이지 못한 타인을 거절하게 된니다
.협업 하느니 혼자 한다고 마음먹고 타인과의 관계를 정리합니다`,
    stressManage: `통제 가능한 상황을 만들어야 합니다
.스케줄을 정리해야 합니다
.자격증이나 학위 등 성취를 인정해 줄 수 있는 과정을 등록해야 합니다
.계획된 업무 완수 시 스스로에게 보상을 줘야 합니다
.일 거리를 다 놓아버리고 계획된 휴가를 떠나야 합니다`,
  },
  {
    relationship: `혼자 일하는 것을 편안해 합니다
.다수의 사람들보다는 소수의 사람과 함께 하는 것을 선호합니다
.비 업무적인 관계를 힘들어합니다`,
    communication: `질문을 던지는 요청형 의사소통을 선호합니다
.지시보다 질문을 좋아합니다
.핵심만 이야기하는 간결한 방식을 선호합니다
.근거를 제시하는 방식을 신뢰합니다
.성과나 업무 능력에 대한 인정과 칭찬이 효과적입니다`,
    motivation: `업무관리 및 계획을 수립할 수 있도록 중장기 계획을 공유하고 사전에 스케쥴을 공지해야 합니다
.마감시한을 명시한 과제를 제시해야 합니다
.업무계획을 수립할 수 있는 시간 제공을 제공해야 합니다
.성과달성에 도움이 되는 구체적인 자료를 제공해줘야 합니다
.달성한 업무 성과나 노력에 대해 인정하고 칭찬해줘야 합니다
.전문성 확보를 위한 학습이나 경험의 기회를 제공해야 합니다
.지시보다 요청형 의사소통방식을 사용해야 합니다`,
  },
];

export const PlayerTypeMobile = [
  {
    type: "유희형",
    typeDescription: `창의성이 뛰어나고 자발적이며 쾌활합니다. 창의성과 주도성이 이들의 강점입니다. 유희형은 타인과의 관계에서는 매우 활동적입니다. 이들은 집단의 구성원인 것을 즐깁니다. 이들은 유희를 할 수 있으나, 타인의 통제에 대하여 강하게 저항합니다. 이들은 훌륭한 조사관, 평론가, 탐정이 될 수 있습니다. 어떤 상황에서 무엇인가 잘못되는 것이 나타나면 이들이 제일 먼저 그 사실을 지적합니다.`,
  },
  {
    typeWord: `“재미가 중요해!“, ”즐거운 인생!“, ”남다르고 싶어!＂`,
    keyWord: `열정, 즐거움, 창의, 자발`,
    typeDescription: `에너지가 넘치고, 즐거움을 추구하며 구성원과 함께 합니다.창의적이고 다양성을 중요시하는 환경을 구축합니다.구성원들이 자발적이고 열정적으로 움직여 주길 기대하며 참여를 촉진합니다.기존의 방식과 다른 접근을 하거나 신선하고 혁신적인 아이디어가 많습니다.변화하는 환경을 예측하고 준비하는 능력이 탁월합니다`,
  },
  {
    pros: `쾌활하며 삶을 즐길 줄 압니다
.호기심이 많고 창조적입니다
.유머 감각이 뛰어나고 긍정적입니다 
.새로운 아이디어를 잘 내놓으며 재치있습니다
.활기가 있고 에너지가 넘칩니다`,
    cons: `산만하고 집중을 잘 못하는 경향이 있습니다
.감정 기복이 심합니다
.불평이나 불만을 토로합니다
.자기 입장을 고집하고, 협조하기를 거부합니다
.이유 없는 투쟁, 반발을 합니다
.혼자만의 성찰의 시간을 갖지 못합니다`,
  },
  {
    desire: `끊임없는 재미와 흥미를 추구하는 욕구.새롭고 남다른 것을 추구하는 욕구.구속 받지 않고 자유롭고 싶은 욕구.과정 속에서 즐거움을 추구하는 욕구.즐거운 회합을 갖고 싶다는 욕구.`,
    stress: `이것 저것 일을 벌려서 수습하기 힘들어집니다
.하고 싶지 않은 일을 할 때는 노력하려고 해도 금방 싫증이 납니다
.자신이 싫은 일은 남에게 떠맡깁니다
.관심이 없는 말은 안 들리는 척 합니다
.제대로 되지 않는 일을 타인 탓으로 돌립니다
.상대를 일부러 화나게 하려고 불평을 합니다
.주위로부터 비난 받으면 복수심을 품습니다
.자포자기 하게 됩니다`,
    stressManage: `농담을 하며 동료와 웃고 떠들어야 합니다
.좋아하는 동물과 장난치며 놀아야 합니다
.노래방에서 춤추고 놀아야 합니다
.자연에서 피크닉이나 하이킹을 해야 합니다
.TV, 게임, 만화를 보는 시간이 있어야 합니다`,
  },
  {
    relationship: `하고싶을 때 하고싶은 일을 합니다
.조금 일하고 실컷 놉니다
.유머와 웃음이 넘치는 인생을 추구합니다
.여러 사람들과 만나고, 여러 환경을 접합니다`,
    communication: `농담을 주고 받을 수 있는 재미있는 대화를 선호합니다
.감정을 솔직하게 표현할 수 있는 대화를 선호합니다
.호기심을 자극할 수 있는 주제를 선호합니다`,
    motivation: `.즐거운 분위기에서 일할 수 있는 환경을 제공해야 합니다
.팀으로 활동할 수 있는 업무에 배정해야 합니다
.과제를 부여할 때 곧바로 지시하기보다 재미있고 편안한 관계를 맺은 후 스스로 수용할 수 있도록 유도해야 합니다
.창의적인 아이디어가 필요한 프로젝트에 주도적인 역할을 부여해야 합니다
.조직에 사기를 불어넣는 역할을 맡겨야 합니다
.너무 세세한 가이드라인 제시와 타이트한 마감시간으로 압박감을 주지 않아야 합니다`,
  },
];
