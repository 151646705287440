import { styled } from "styled-components";

export const ArrowAnimation = styled.img`
  /* border: 1vw solid blue; */
  height: 100%;
  width: 45%;
  /* border-radius: 50%; */
  position: relative;
  transform-origin: center;
  animation: ${(props) =>
    props.$isClicked
      ? "rotate 5s cubic-bezier(.8,.5,.22,1.05) forwards"
      : "rotate2 5s cubic-bezier(.8,.5,.22,1.05) forwards"};

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(${(props) => props.$rotationAngle + 810}deg);
    }
  }

  @keyframes rotate2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(${(props) => props.$rotationAngle + 810}deg);
    }
  }
`;
