import React, { createContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { useNavigate, useParams } from "react-router-dom";

import { LoadingModal } from "components/Modal";

const ManagerContext = createContext({
  socket: null,
  managerPlan: "free",
  playingData: [],
  currentSlideIndex: 0,
  code: 123456,
  isQrGridOpened: false,
  qrCodeURL: "",
  likes: [],
  isQnACreated: false,
  QnA: [],
  isQnaModalOpened: false,

  handleCurrentSlideIndex: () => {},
  handleQRCode: () => {},
  setQnACreated: () => {},
  setQnA: () => {},
  setIsQnaModalOpened: () => {},
});

const ManagerProvider = ({ children }) => {
  const { gameId } = useParams(); // * 게임 ID
  const navigate = useNavigate(); // * 라우터 네비게이션

  const [socket, setSocket] = useState(null); // * 소켓
  const [isSocketDisconnected, setSocketDisconnected] = useState(false); // * 소켓 연결 해제 여부

  const [managerPlan, setManagerPlan] = useState("free"); // * 매니저 플랜
  const [playingData, setPlayingData] = useState([]); // * 플레이 중인 데이터
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // * 현재 슬라이드 인덱스
  const [code, setCode] = useState("123456"); // * 접속 코드
  const [isQrGridOpened, setQrGridOpened] = useState(false); // * QR코드 그리드 오픈 여부
  const [qrCodeURL, setQrCodeURL] = useState(""); // * QR코드 URL
  const [likes, setLikes] = useState([]); // * 좋아요 리스트
  const [isQnACreated, setQnACreated] = useState(false); // * QnA 생성 여부
  const [QnA, setQnA] = useState([]); // * QnA 리스트
  const [isQnaModalOpened, setIsQnaModalOpened] = useState(false); // * QnA 모달 오픈 여부

  // * 슬라이드 이동 핸들러
  const handleCurrentSlideIndex = (e) => {
    const type = e.currentTarget.id; // * 이전, 다음 슬라이드 여부

    if (type === "prev") {
      setCurrentSlideIndex(currentSlideIndex - 1); // * 현재 슬라이드 인덱스 - 1
      navigate(`${playingData[currentSlideIndex - 1].id}`); // * 이전 슬라이드로 라우터 네비게이션

      socket.emit("setCurrentSlideIndex", { gameId, currentSlideIndex: currentSlideIndex - 1 }); // * 현재 슬라이드 인덱스 서버로 전송
    } else {
      setCurrentSlideIndex(currentSlideIndex + 1); // * 현재 슬라이드 인덱스 + 1
      navigate(`${playingData[currentSlideIndex + 1].id}`); // * 다음 슬라이드로 라우터 네비게이션

      socket.emit("setCurrentSlideIndex", { gameId, currentSlideIndex: currentSlideIndex + 1 }); // * 현재 슬라이드 인덱스 서버로 전송
    }
  };

  // * QR코드 핸들러
  const handleQRCode = () => {
    setQrGridOpened(!isQrGridOpened); // * QR코드 그리드 오픈 여부 토글
  };

  // * 소켓 연결
  useEffect(() => {
    // * 소켓이 없으면 연결
    if (!socket) {
      setSocket(io.connect("https://poll.withplus.live"));
    }
  }, [socket]);

  // * 페이지 로드 시 데이터 요청
  useEffect(() => {
    if (!socket) return; // * 소켓이 없으면 리턴

    // * 폴링 데이터 요청
    socket.emit("getPolling", { gameId }, (poll) => {
      setPlayingData(poll.playingData); // * 플레이 중인 데이터 설정
      setCurrentSlideIndex(poll.currentSlideIndex); // * 현재 슬라이드 인덱스 설정
      setLikes(poll.likes); // * 좋아요 리스트 설정
      setQnA(poll.QnA); // * QnA 리스트 설정
    });

    // * 접속 코드 요청
    socket.emit("getCode", { id: gameId });
  }, [socket]);

  // * QR코드 URL 생성 (개발용)
  useEffect(() => {
    setQrCodeURL(`https://www.withplus.live/polling/user/${gameId}`);
  }, []);

  // * 소켓 이벤트 리스너
  useEffect(() => {
    if (!socket) return;

    // * 소켓 연결 이벤트 + 소켓 룸 입장
    socket.on("connect", () => {
      console.log("connected");
      setSocketDisconnected(false); // * 소켓 연결 해제 여부 false
      socket.emit("joinRoom", { gameId }); // * 소켓 룸 입장
    });

    // * 소켓 연결 해제 이벤트
    socket.on("disconnect", () => {
      console.log("disconnected");
      setSocketDisconnected(true); // * 소켓 연결 해제 여부 true
    });

    // * 폴링 수정 이벤트
    socket.on("leavePolling", () => {
      alert("폴링이 수정되었습니다. 페이지를 새로고침합니다.");
      window.location.reload(); // * 페이지 새로고침
    });

    // * 접속 코드 수신
    socket.on("getCode", ({ code }) => {
      setCode(code.code + ""); // * 접속 코드 설정
    });

    // * 좋아요 수신 이벤트
    socket.on("submitLike", (data) => {
      let poll = data.poll;

      setLikes(poll.likes); // * 좋아요 리스트 설정
    });

    // * QnA 수신 이벤트
    socket.on("submitQuestion", (data) => {
      let poll = data.poll;

      setQnA(poll.QnA); // * QnA 리스트 설정
      setQnACreated(true); // * QnA 생성 여부 true
    });

    // * 워드클라우드 수신 이벤트
    socket.on("submitWordCloud", (data) => {
      const { poll } = data;
      setPlayingData(poll.playingData); // * 플레이 중인 데이터 설정
    });

    // * 현재 슬라이드 투표 제한 이벤트
    socket.on("restrictPolling", (data) => {
      setPlayingData(data.poll.playingData); // * 플레이 중인 데이터 설정
    });

    // * 현재 슬라이드 투표 제한 해제 이벤트
    socket.on("unrestrictPolling", (data) => {
      setPlayingData(data.poll.playingData); // * 플레이 중인 데이터 설정
    });

    // * 폴링 투표 결과 초기화 이벤트
    socket.on("resetPoll", (data) => {
      const { poll } = data;

      setPlayingData(poll.playingData); // * 플레이 중인 데이터 설정
      setCurrentSlideIndex(poll.currentSlideIndex); // * 현재 슬라이드 인덱스 설정
      setLikes(poll.likes); // * 좋아요 리스트 설정
      setQnA(poll.QnA); // * QnA 리스트 설정
    });

    socket.on("submitChoice", ({ poll }) => {
      setPlayingData(poll.playingData); // * 유저 투표 결과 설정
    });

    socket.on("submitOpenEnded", ({ poll }) => {
      setPlayingData(poll.playingData); // * 유저 투표 결과 설정
    });

    socket.on("setPlayingData", ({ poll }) => {
      setPlayingData(poll.playingData); // * 플레이 중인 데이터 설정
    });
    socket.on("getPlan", ({ plan }) => {
      setManagerPlan(plan); // * 매니저 플랜 설정
    });

    // * 매 렌더링 시 소켓 연결 해제 이벤트 리스너 제거
    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("getCode");
      socket.off("submitLike");
      socket.off("submitWordCloud");
      socket.off("restrictPolling");
      socket.off("unrestrictPolling");
      socket.off("resetPoll");
      socket.off("submitChoice");
      socket.off("leavePolling");
      socket.off("submitOpenEnded");
      socket.off("setPlayingData");
      socket.off("getPlan");
    };
  });

  // * 컨텍스트 값
  const value = {
    managerPlan,
    socket,
    playingData,
    currentSlideIndex,
    code,
    isQrGridOpened,
    qrCodeURL,
    likes,
    isQnACreated,
    QnA,
    isQnaModalOpened,

    handleCurrentSlideIndex,
    handleQRCode,
    setQnACreated,
    setQnA,
    setIsQnaModalOpened,
  };

  return (
    <ManagerContext.Provider value={value}>
      {children}

      {/* 소켓 연결 해제 시 로딩 모달 */}
      <LoadingModal open={isSocketDisconnected} />
    </ManagerContext.Provider>
  );
};

export { ManagerContext, ManagerProvider };
