const styles = {
  // * Slide List Styles
  slideList: {
    // * Grid Style
    grid: {
      px: "5px",
      borderRight: 1,
      borderColor: "#cdcdcd",
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column",
      height: "100%",
    },

    // * Container Style
    container: {
      textAlign: "center",
      my: "10px",
    },

    // * 슬라이드가 나타나는 Box Style
    content_box: {
      flex: 1,
      bgcolor: "#f5f5f5",
      borderRadius: "8px",
      boxShadow: "inset 3px 3px 9px #c0c0c0, inset -3px -3px 9px #ffffff",
      p: "10px",
      overflow: "auto",
    },

    // * 슬라이드 추가 버튼 컨테이너 Style
    add_slide_box: {
      borderTop: 1,
      borderColor: "#cdcdcd",
      pb: "10px",
      mt: "10px",
      pt: "10px",
    },
  },

  // * Slide Styles
  slide: {
    // * Grid Style
    grid: {
      display: "flex",
      flexDirection: "column",
      borderRight: 1,
      borderColor: "#cdcdcd",
      px: "3px",
      height: "100%",
    },

    // * Container Styles
    container: {
      mt: "5px",
      borderBottom: "1px solid #00000028",
      margin: "0px 20px 10px 20px",
      paddingBottom: "10px",
    },

    // * 프로젝트 제목 Style
    title_textfield: {
      borderRadius: "10px",
    },

    // * 슬라이드 미리보기 Box Styles
    slide_box: {
      display: "flex",
      flex: 1,
      flexWrap: "wrap",
      overflow: "auto",
      m: 1,
      p: 2,
      bgcolor: "#f5f5f5",
      boxShadow: "inset 3px 3px 9px #c0c0c0, inset -3px -3px 9px #ffffff",
      borderRadius: "10px",
      boxSizing: "border-box",
    },

    // * 슬라이드 미리보기 Paper Styles
    slide_paper: {
      width: "100%",
      p: 1,
    },

    // * Grid Style
    paper_container: { height: "100%" },

    // * Grid Left Style
    grid_left: {
      // borderRight: 1,
      // borderColor: "#cdcdcd",
    },

    // * Grid Left Container Style
    content_box: { height: "100%", mx: 1, display: "flex", flexDirection: "column" },

    // * Content Header Styles
    content_header: {
      display: "flex",
      justifyContent: "space-between",
      mb: "10px",
      alignItems: "center",
    },

    // * Header Title Styles
    content_header_title: { font: "700 17px Pretendard" },

    // * Header 참여자 UI Styles
    content_header_participants: { display: "flex" },

    // * 시간제한 Container Styles
    timeline_container: { display: "flex", alignItems: "center", mt: "10px", px: "10px" },

    // * timeline 막대 바 Box Styles
    timeline_box: { flex: 1 },

    // * timeline 막대 바 전체 길이 Styles
    timeline_full_width: {
      width: "100%",
      bgcolor: "#aaaaaa",
      height: "10px",
      borderRadius: "10px",
      position: "relative",
    },

    // * timeline 막대 바 현재 남은 시간
    timeline_remains: {
      position: "absolute",
      height: "10px",
      bgcolor: "#c33c3c",
      width: "25%",
      borderRadius: "10px",
    },

    // * 남은 시간 텍스트 표기 Box
    timeline_text_box: { mx: "5px", display: "flex", alignItems: "center" },

    // * Alarm Icon Style
    alarm_icon: { mr: "2px" },

    // * Quiz Box
    quiz_box: {
      flex: 1,
      my: "10px",
      border: 1,
      borderRadius: "8px",
      borderColor: "#cdcdcd",
      px: "10px",
      display: "flex",
      flexDirection: "column",
      // overflow: "auto",
      overflow: "hidden",
      position: "relative",
    },

    // * 문제 Container
    question_box: {
      height: "100%",
      // height: "100px",
      borderBottom: 1,
      borderColor: "#cdcdcd",
      display: "flex",
      alignItems: "center",
      p: "10px 5px",
      flex: 1,
      // maxHeight: "300px",
      // height: "10px !important",
      // overflow: "hidden",
      // transform: "scale(0.5)",
      transition: "height 0.5s",
    },

    // * 주관식 문제 Container
    short_answer_question_box: {
      height: "auto",
      borderBottom: 1,
      borderColor: "#cdcdcd",
      display: "flex",
      alignItems: "center",
      px: "10px",
      justifyContent: "center",
      py: "15px",
    },

    // * OX 퀴즈 nawp qkrtm
    OX_question_box: {
      borderBottom: 1,
      borderColor: "#cdcdcd",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    // * 객관식 문제 Text
    question_text: {
      font: "900 32px Pretendard",
      // width: "60%",
      height: "100%",
      wordBreak: "break-all",
      whiteSpace: "pre-wrap",
    },

    // * 답안들 Container
    answers_grid: {
      // height: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "end",
      my: "20px",
    },

    // * 답안 Box
    answer_item: {
      // flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      // alignItems: "end",
      // justifyContent: "center",
      p: "2.5px",
      boxSizing: "border-box",
      // bgcolor: "red",
      height: "300px",
      position: "relative",
    },

    answer_box: {
      // flex: 1,
      display: "flex",
      alignItems: "center",
      border: "1px solid #aaa",
      borderRadius: "15px",
      width: "90%",
      height: "auto",
      aspectRatio: "16/9",
      maxWidth: "533px",
      // maxHeight: "300px",
      // maxHeight: "100px",
      position: "relative",
      overflow: "hidden",
      transition: "max-height 0.5s",
      flexShrink: 0,
    },

    // * 답안 Circle Icon
    circle_icon: {
      fontSize: "18px",
      mr: "20px",
    },

    // * 답안 Text
    answer_text: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      font: "bold 16px Pretendard",
      height: "100%",
      width: "100%",
      p: "10px",
      boxSizing: "border-box",
      // mix-blend-mode: difference;
      wordBreak: "break-all",
    },

    // * 주관식 정답 Container
    short_answer_container: {
      borderTop: 1,
      borderColor: "#cdcdcd",
      p: "10px",
      textAlign: "center",
      position: "relative",
      height: "clamp(157.5px, 11.25vw, 216px)",
    },

    // * OX 퀴즈 정답 박스
    OX_answer_box: {
      // flex: 2,
      py: "20px",
    },

    // * OX 퀴즈 정답 Grid
    OX_answer_item: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    OX_answer_button: (answer, correctAnswer) => {
      return {
        width: "70%",
        aspectRatio: "auto 4/3",
        border: 3,
        borderColor: answer === correctAnswer ? "#c33c3c" : "#cdcdcd",
        bgcolor: answer === correctAnswer ? "#00ff00" : "",
        borderRadius: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // textAlign: "center",
        boxShadow: "3px 3px 6px #00000050",
      };
    },

    // * 주관식 힌트 컨테이너
    short_answer_hint_container: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },

    hint_title: {
      font: "20px Pretendard",
    },

    // * 힌트 박스
    hints_box: {
      borderRadius: "10px",
      padding: "10px",
      my: "10px",
      backgroundColor: "#eee",
      width: "70%",
    },

    // * 힌트 버튼 (안 보일때)
    hint_button: {
      padding: "5px",
      my: "20px",
      mx: "10px",
      backgroundColor: "#f1f1f1",
      textAlign: "center",
      boxShadow: "inset -2px -2px 6px rgba(255,255,255,0.7), inset 2px 2px 4px rgba(0,0,0,0.1)",
      cursor: "pointer",

      // "&:hover": {
      //   boxShadow: "-2px -2px 6px rgba(255,255,255,0.7), 2px 2px 4px rgba(0,0,0,0.1)",
      // },
    },

    // * 힌트버튼 (보일때)
    active: {
      boxShadow: "inset -2px -2px 6px rgba(255,255,255,0.7), inset 2px 2px 4px rgba(0,0,0,0.1)",
    },

    // * 슬라이드 오른쪽
    slide_right: { margin: "auto", display: "flex", justifyContent: "center" },

    // * QR Coode 및 접속코드 컨테이너
    code_box: {
      width: "70%",
      margin: "15px auto",
      padding: "3px",
      borderRadius: "10px",
      border: "2px solid #B43F3F",
      boxSizing: "border-box",
    },

    // * QR Code Box
    qrcode_box: { textAlign: "center" },

    // * QR Code
    qrcode: { fontSize: "50px" },

    // * "접속코드" Text
    access_code_text: {
      display: "flex",
      justifyContent: "center",
      font: "bold 20px Pretendard",
      color: "#797979",
    },

    // * 접속코드 (숫자)
    access_code: {
      display: "flex",
      justifyContent: "center",
      font: "bold 18px Pretendard",
      color: "#B43F3F",
    },
  },

  edit: {
    // * 퀴즈 에디터 Grid
    grid: { px: "5px", overflowY: "scroll", height: "100%" },

    // * 퀴즈 에디터 Grid item
    grid_item: { borderBottom: 1, borderColor: "#cdcdcd", my: "10px", py: "10px" },

    // * 퀴즈 에디터 Grid item 제목
    grid_item_title: {
      font: "bold 20px Pretendard",
      mb: "10px",
      "> span": { font: "500 14px Pretendard", color: "#777" },
    },

    // * 문제 유형 Grid
    quiz_type_item: { display: "flex", justifyContent: "center" },

    // * 문제 유형 Button Active
    quiz_type_box_active: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: 1,
      borderColor: "#c33c3c",
      borderRadius: "5px",
      width: "70%",
      my: 1,
      textAlign: "center",
      aspectRatio: "auto 1/1",
      cursor: "pointer",
      // py: "auto",
      backgroundColor: "#c33c3c",
      flexDirection: "column",
    },

    // * 문제 유형 Icon Active
    quiz_type_icon_active: { color: "white" },

    // * 문제 유형 Text Active
    quiz_type_text_active: { font: "16px Pretendard", color: "white" },

    // * 문제 유형 Button
    quiz_type_box: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: 1,
      borderColor: "#ababab",
      borderRadius: "5px",
      width: "70%",
      my: 1,
      textAlign: "center",
      aspectRatio: "auto 1/1",
      cursor: "pointer",
      // py: "auto",
      flexDirection: "column",
      "&:hover": {
        bgcolor: "#efefef",
      },
    },

    // * 문제 유형 Text
    quiz_type_text: { font: "16px Pretendard" },

    // * 정답 (Answer) Title Box
    answers_title_box: { display: "flex", alignItems: "center" },

    // * 정답 (Answer) Tooltip Icon
    answers_title_tooltip_icon: { mb: "10px", ml: "5px", color: "#ababab", cursor: "pointer" },

    // * answer box
    answer_box: { display: "flex", alignItems: "center", my: "5px" },

    // * 답안 추가 버튼 텍스트
    add_answer_button_text: { fontFamily: "Pretendard" },

    // * Option item
    option_item: {
      display: "flex",
      justifyContent: "space-between",
      pl: "16px",
      mb: "5px",
      alignItems: "center",
    },

    // * Option item Text
    option_item_text: { fontFamily: "Pretendard" },

    // * 시간 제한 select box
    time_limit_select: { backgroundColor: "#f5f5f5" },

    // * 단답형 힌트 제목 박스
    hint_title_box: {
      display: "flex",
    },

    // * 단답형 힌트 박스
    hint_box: (hints) => {
      return {
        pl: "16px",
        display: hints.length === 0 ? "none" : "",
        mb: "10px",
      };
    },

    // * OX 퀴즈 정답 Box
    OX_box: (answer, correctAnswer) => {
      return {
        my: "5px",
        mx: "20px",
        border: 2,
        borderColor: answer !== correctAnswer ? "#cdcdcd" : "#c33c3c",
        borderRadius: "10px",
        textAlign: "center",
        py: "15px",
        cursor: "pointer",

        "&:hover": {
          backgroundColor: answer !== correctAnswer ? "#efefef" : "",
        },
      };
    },
  },

  list_item: {
    // * 리스트 아이템 container
    box: { display: "flex", mb: "10px" },

    // * 리스트 아이템 Paper
    paper: {
      position: "relative",
      width: "80%",
      aspectRatio: "auto 4/3",
      margin: "auto",
      cursor: "pointer",
      userSelect: "none",
    },

    // * 슬라이드 편집 완료 여부 container
    has_complete_box: { justifyContent: "flex-end", display: "flex" },

    // * 슬라이드 type box
    slide_type_box: { mb: "10px" },

    // * 슬라이드 icon buttons box
    icon_buttons_box: { position: "absolute", bottom: 5, right: 5, display: "flex" },

    // * 슬라이드 icon button
    icon_button: { width: "25px", height: "25px", mx: "5px" },
  },

  // * save button tooltip title
  save_button_tooltip_title: { font: "18px Pretendard" },

  // * save button
  save_button: {
    position: "absolute",
    bottom: 20,
    right: 20,
    backgroundColor: "#c33c3c",
    "&:hover": { backgroundColor: "#a31c1c" },
    width: "60px",
    height: "60px",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
  },

  // * save icon
  save_button_icon: { color: "white" },

  menu: {
    width: "200px",
    boxShadow: "1px 1px 2px #00000020",
    padding: "0 10px",
    borderRadius: "10px",
  },

  menuitem: {
    box: { mt: "5px", mb: "5px" },

    icon: { mr: "10px" },

    font: { fontFamily: "Pretendard" },
  },

  data_grid: {
    quiz_name: { cursor: "pointer", width: "100%", display: "flex" },

    icon: { mr: "10px", color: "#9a9a9a", "&:hover": { color: "#ababab" } },
  },

  code_container: {
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },

  QRCode_box: {
    display: "flex",
    justifyContent: "center",
    mt: "10px",
    scale: "scale(1)",
    cursor: "pointer",
    transition: "transform 0.3s ease-in-out",

    "&:hover": {
      transform: "scale(1.05)",
    },
  },

  access_code: {
    display: "flex",
    justifyContent: "center",
    font: "bold 28px Pretendard",
    color: "#797979",
  },

  access_code_number: {
    display: "flex",
    justifyContent: "center",
    font: "bold 40px Pretendard",
    color: "#B43F3F",
  },

  intro: {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      flexDirection: "column",
      position: "relative",
    },

    title_box: { py: "20px" },

    title_font: { font: "bold 32px Pretendard" },

    participant_box: {
      mt: "20px",
      py: "20px",
      width: "75%",
      borderRadius: "10px",
      backgroundColor: "#f5f5f5",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      icon: { fontSize: "45px", mr: "10px" },

      text: { font: "bold 33px Pretendard" },
    },

    all_user_box: {
      flex: 1,
      m: "20px",
      bgcolor: "#f5f5f5",
      borderRadius: "8px",
      boxShadow: "inset 3px 3px 9px #c0c0c0, inset -3px -3px 9px #ffffff",
      width: "80%",
      p: "2px",
      // overflow: "hidden",
      position: "relative",
      boxSizing: "border-box",
    },

    user_box: {
      display: "flex",
      p: 1,
      boxSizing: "border-box",
      alignItems: "center",
      // gap: "5px",
      avatar: { mr: "5px" },
      name: {
        font: "700 24px Pretendard",
        bgcolor: "#fff",
        boxShadow: "0px 3px 6px #00000058",
        borderRadius: "4px",
        height: "calc(100% - 5px)",
        transform: "translateX(-10px)",
        display: "flex",
        alignItems: "center",
        zIndex: 1,
        px: "25px",
      },
    },

    start_button: {
      borderRadius: "50%",
      aspectRatio: "1/1 auto",
      boxShadow: "2px 2px 6px #00000090",
      fontSize: "clamp(13.125px, 1.09375vw, 21px)",
    },
  },
};

export default styles;
