import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Checkbox, Divider, MenuItem, Select } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import styles from "../styles/styles.module.css";
import { Textfield } from "components/Textfield";
import { TermOfUseModal } from "components/Modal";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);

  const [product, setproduct] = useState("");
  const [koreanProduct, setKoreanProduct] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [checkBoxes, setCheckBoxes] = useState([false, false]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [count, setCount] = useState(0);
  const [price, setPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [modal, setModal] = useState(false);

  const goToPayment = async () => {
    await sleep(500);

    navigate(
      `/diagnosis-plan/payment?product=${product}&name=${name}&phone=${phone}&company=${company}&count=${count}&totalPrice=${totalPrice}`
    );
  };

  const handleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (!query.get("product")) {
      navigate("/diagnosis-plan");
    }

    setproduct(query.get("product"));

    switch (query.get("product")) {
      case "hexagram":
        setKoreanProduct("라이브 헥사그램");
        setPrice(10_000);
        break;
      default:
        navigate("/diagnosis-plan");
        break;
    }
  }, []);

  useEffect(() => {
    if (name && phone && checkBoxes[0] && checkBoxes[1] && count > 0) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  }, [name, phone, checkBoxes, count]);

  useEffect(() => {
    setTotalPrice(price * count);
  }, [price, count]);

  return (
    <div className={styles["paypage"]}>
      <Button className={styles["back_btn"]} onClick={() => navigate(-1)}>
        <ArrowBackIcon />
        뒤로가기
      </Button>

      <div className={styles["container"]}>
        <p className={styles["title"]}>위드플러스 라이브 상품결제</p>

        <p className={styles["sub_title"]}>
          상품 결제 후 7일 이내에 환불이 가능하며, 서비스를 이용하신 경우 환불이 불가능 합니다.
        </p>

        <div className={styles["grid"]}>
          <div>
            <div className={styles["paper"]}>
              <p className={styles["title"]}>
                정보 입력 <span>(* 필수항목)</span>
              </p>

              <div>
                <div className={styles["user_info"]}>
                  <p>
                    이름 <span>*</span>
                  </p>

                  <Textfield
                    sx={{ width: "85%" }}
                    placeholder="이름을 입력해주세요."
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className={styles["user_info"]}>
                  <p>
                    연락처 <span>*</span>
                  </p>

                  <Textfield
                    sx={{ width: "85%" }}
                    placeholder="- 없이 입력 (예: 01012345678)"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>

                <div className={styles["user_info"]}>
                  <p>소속</p>

                  <Textfield
                    sx={{ width: "85%" }}
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
              </div>

              <div className={styles["checkbox"]}>
                <Checkbox
                  checked={checkBoxes[0]}
                  onChange={() => {
                    setCheckBoxes([!checkBoxes[0], checkBoxes[1]]);
                  }}
                />
                <span>결제 서비스 업체 및 위드플러스 라이브에 본인 개인 정보 제공 동의</span>
              </div>

              <div className={styles["checkbox"]}>
                <Checkbox
                  checked={checkBoxes[1]}
                  onChange={() => {
                    setCheckBoxes([checkBoxes[0], !checkBoxes[1]]);
                  }}
                />
                <span>구매조건(결제조건) 확인 및 결제진행 동의, 이용약관 동의</span>
                <div className={styles["see_more"]} onClick={handleModal}>
                  <span>보기</span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={styles["paper"]}>
              <p className={styles["title"]}>상품 정보</p>

              <Divider className={styles["divider"]} />

              <div className={styles["product_info"]}>
                <p>상품명</p>

                <p>{koreanProduct}</p>
              </div>

              <div className={styles["product_info"]}>
                <p>
                  구매 갯수 <span>*</span>
                </p>

                <Select
                  className={styles["select"]}
                  size="small"
                  value={count}
                  MenuProps={{
                    style: { maxHeight: "400px" },
                  }}
                  onChange={(e) => setCount(e.target.value)}
                >
                  <MenuItem value={0} disabled>
                    개수를 선택해주세요
                  </MenuItem>

                  {new Array(100).fill(0).map((_, i) => (
                    <MenuItem key={i} value={i + 1}>
                      {i + 1}개
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            <div className={styles["paper"]}>
              <p className={styles["title"]}>최종 결제 정보</p>

              <Divider className={styles["divider"]} />

              <div className={styles["product_info"]}>
                <p>결제 금액</p>

                <p>
                  {price.toLocaleString("ko-KR")}원 X {count}개
                </p>
              </div>

              <div className={styles["product_info"]}>
                <p>최종 결제 금액</p>

                <p>{totalPrice.toLocaleString("ko-KR")}원</p>
              </div>

              <Divider className={styles["divider"]} sx={{ borderColor: "#ddd !important" }} />

              <Button
                className={styles["payment_btn"]}
                variant="contained"
                disabled={!isCompleted}
                onClick={goToPayment}
              >
                결제하기
              </Button>
            </div>
          </div>
        </div>
      </div>
      <TermOfUseModal open={modal} handleModal={handleModal} />
    </div>
  );
};
