import React, { useEffect, useRef, useState } from "react";
import clonedeep from "lodash/cloneDeep";
import classes from "./GhostLeg.module.css";
import { random } from "lodash";
import vehicle1 from "./GhostLegImg/vehicle1.png";
import vehicle2 from "./GhostLegImg/vehicle2.png";
import vehicle3 from "./GhostLegImg/vehicle3.png";
import vehicle4 from "./GhostLegImg/vehicle4.png";
import vehicle5 from "./GhostLegImg/vehicle5.png";
import vehicle6 from "./GhostLegImg/vehicle6.png";
import vehicle7 from "./GhostLegImg/vehicle7.png";
import vehicle8 from "./GhostLegImg/vehicle8.png";

// 기본 캔버스 적용사항
const DEFAUTL_USERS = 3;
const DEFAUTL_LINE_WIDTH = 7;
const DEFAUTL_LINE_COLOR = "#60A7FF";

// 깊은 복사를 위한 함수
const sort = (arr) => {
  const _arr = clonedeep(arr);
  _arr.map((arg) => {
    arg.sort((a, b) => a.y - b.y);
    return arg;
  });
  return _arr;
};

const GhostLeg = () => {
  const canvasRef = useRef(null);
  const contextRef = useRef(null);

  //이미지 경로 추적 캔버스
  const pathCanvasRef = useRef(null);

  const [ctx, setCtx] = useState();

  //이미지 경로 추적 캔버스
  const [pathCtx, setPathCtx] = useState();
  const [users, setUsers] = useState(DEFAUTL_USERS);

  const [data, setData] = useState([]);
  const [bridgeData, setBridgeData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  //높이 조절
  const [num, setNum] = useState(14);
  const [num2, setNum2] = useState(-27);
  //애니메이션 중지
  const [animationId, setAnimationId] = useState(null);
  //이미지 선택
  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(null);

  const GhostLegImg = [
    vehicle1,
    vehicle2,
    vehicle3,
    vehicle4,
    vehicle5,
    vehicle6,
    vehicle7,
    vehicle8,
  ];

  useEffect(() => {
    init();
    const canvas = canvasRef.current;
    const pathCanvas = pathCanvasRef.current;

    canvas.width = 900;
    canvas.height = 550;
    pathCanvas.width = 900;
    pathCanvas.height = 550;

    const context = canvas.getContext("2d");
    const pathContext = pathCanvas.getContext("2d");

    context.lineJoin = "round";
    context.lineWidth = 3;
    contextRef.current = context;

    if (ctx) {
      drawBaseLine();
    }

    setCtx(context);
    setPathCtx(pathContext);
    // setSelectedVehicleIndex(users);
    console.log("유저수" + users);
  }, [users, ctx]);

  // 캔버스 초기설정
  const init = () => {
    if (!ctx) return;
    const canvas = canvasRef.current;
    const { width, height } = canvas;
    ctx.save();
    ctx.clearRect(0, 0, width, height);
    ctx.restore();
  };
  // 사다리타기 뼈대 작성
  const drawBaseLine = () => {
    init();
    setBridgeData([]);
    const canvas = canvasRef.current;
    const width = canvas.width;
    const height = canvas.height;
    const _arr = [];

    for (let i = 0; i < users; i++) {
      let startPosX = (i / users) * width + ((1 / users) * width) / 2;
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = DEFAUTL_LINE_WIDTH;
      ctx.strokeStyle = DEFAUTL_LINE_COLOR;
      ctx.moveTo(startPosX, 0);
      ctx.lineTo(startPosX, height);
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
      let arr = [
        { x: startPosX, y: 0 },
        { x: startPosX, y: height },
      ];
      _arr.push(arr);
    }
    setData(_arr);

    // const numBridges = (users == 2 ? 3 : users == 3 ? 4 : users === 4 ? 5 : 5);
    // 각 인접한 기둥 사이에 무조건 하나 이상의 다리 생성
    // 각 인접한 기둥 사이에 다리 생성
    // for (let i = 0; i < users - 1; i++) {
    //   const numBridges = (users == 2 ? 3 : users == 3 ? 4 : users === 4 ? 5 : 5);
    //   drawRandomBridge2(i, i + 1, numBridges)
    // }
    // drawRandomBridge2(1,2, 6 , 0); // 인접한 기둥의 인덱스와 생성할 다리 개수를 전달
    // drawRandomBridge2(0,1, 5 , 30);

    setNum((prevNum) => (prevNum === 14 ? -27 : 14));
    setNum2((prevNum2) => (prevNum2 === -27 ? 14 : -17));
    // 사용사 수에 따른 랜덤한 가로 다리 설정
    let numBridges = Math.floor(Math.random() * 5) + 2;
    for (let i = 0; i < users - 1; i++) {
      if (i % 2 === 0) {
        drawRandomBridge2(i, i + 1, numBridges, num);
      } else {
        drawRandomBridge2(i, i + 1, numBridges, num2);
      }
      numBridges = Math.floor(Math.random() * 5) + 2;
    }
  };

  // 가로 사다리 경로 설정
  function drawBridge() {
    const canvas = canvasRef.current;
    const width = canvas.width;
    const height = canvas.height;
    if (!ctx) return;

    for (let i = 0; i < users; i++) {
      let startPosX = (i / users) * width + ((1 / users) * width) / 2;
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = DEFAUTL_LINE_WIDTH;
      ctx.strokeStyle = DEFAUTL_LINE_COLOR;
      ctx.moveTo(startPosX, 0);
      ctx.lineTo(startPosX, height);
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    }
    if (bridgeData.length < 1) return;
    bridgeData.forEach((item) => {
      let { startBridge, endBridge } = item;
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = DEFAUTL_LINE_WIDTH;
      ctx.strokeStyle = DEFAUTL_LINE_COLOR;
      ctx.moveTo(startBridge.x, startBridge.y);
      ctx.lineTo(endBridge.x, endBridge.y);
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    });
  }
  // 캔버스 리셋
  const clearContext = () => {
    const canvas = canvasRef.current;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setData([]);
    setBridgeData([]);
    setSortedData([]);
    setUsers(0);
    console.clear();
  };

  useEffect(() => {
    if (bridgeData.length < 1) return;
    init();
    drawBridge();

    const temp = clonedeep(data);

    bridgeData.forEach(({ startBridge, endBridge }) => {
      temp[startBridge.targetIndex].push(startBridge);
      temp[endBridge.targetIndex].push(endBridge);
    });

    setSortedData(sort(temp, "y"));
  }, [bridgeData]);

  //기존 requestAnimationFrame을 setTimeout로 뽑음. 느려는 지는데, 프레임이 끊힘
  const tracePathAnimated = (idx) => {
    if (sortedData.length < 1) return;
    init();
    drawBridge();
    const path = [];
    let currentLine = idx;
    let nodeIdx = 0;
    const usedBridge = new Set();

    // 이미 실행 중인 애니메이션이 있다면 중지
    if (animationId) {
      clearTimeout(animationId);
    }

    let animationProgress = 0;
    const animationDuration = 10000; // 애니메이션 전체 기간
    const interval = 180; // 180ms마다 한 번씩 업데이트

    const animate = () => {
      // 경로 추적
      if (sortedData[currentLine].length === nodeIdx) {
        return;
      }
      const node = sortedData[currentLine][nodeIdx];
      path.push({ i: currentLine, x: node.x, y: node.y });

      if (!node.linkId) {
        nodeIdx++;
      } else if (usedBridge.has(node.linkId)) {
        const start = path[path.length - 2].i;
        const end = currentLine;

        if (start !== end) nodeIdx++;
        else {
          currentLine = node.linkIndex;
          nodeIdx = sortedData[currentLine].findIndex((el) => node.linkId === el.linkId);
        }
      } else {
        currentLine = node.linkIndex;
        nodeIdx = sortedData[currentLine].findIndex((el) => node.linkId === el.linkId);
        usedBridge.add(node.linkId);
      }
      // 애니메이션 진행 상태 갱신
      animationProgress += interval / animationDuration;

      // 캔버스에 경로를 그리는 부분
      drawPathOnCanvas(path, idx);

      if (animationProgress < 1) {
        setAnimationId(setTimeout(animate, interval));
      }
    };
    // 애니메이션 시작
    animate();
  };

  // 실제 캔버스에서 그려지는 경로에 대한 함수
  const drawPathOnCanvas = (path, idx) => {
    // const canvas = canvasRef.current;
    // const ctx = canvas.getContext("2d");

    const pathCanvas = pathCanvasRef.current;
    const ctx = pathCanvas.getContext("2d");

    const image = new Image();

    image.src = GhostLegImg[idx];
    console.log("함수 내" + idx);

    ctx.clearRect(0, 0, pathCanvas.width, pathCanvas.height);

    // 경로 및 이미지 지우기
    for (let i = 0; i < path.length - 1; i++) {
      ctx.clearRect(
        path[i].x - image.width / 2,
        path[i].y - image.height / 2,
        image.width,
        image.height
      );
    }

    for (let i = 0; i < path.length - 1; i++) {
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = DEFAUTL_LINE_WIDTH;

      ctx.strokeStyle = "#FFFFFF";
      ctx.lineCap = "round";
      ctx.lineJoin = "round";
      ctx.lineWidth = 7;

      ctx.moveTo(path[i].x, path[i].y);
      ctx.lineTo(path[i + 1].x, path[i + 1].y);
      ctx.stroke();
      if (i === path.length - 2) {
        ctx.drawImage(
          image,
          path[i + 1].x - image.width / 2,
          path[i + 1].y - image.height / 2,
          image.width,
          image.height
        );
      }
      ctx.closePath();
      ctx.restore();
    }
    // console.log(path);
  };

  // 랜덤으로 생성되는 가로 사다리의 위치와 시작, 끝 지점의 index값 설정
  const drawRandomBridge2 = (startIndex, endIndex, numBridges, canvasHeight) => {
    if (data.length < 1) return;

    const canvas = canvasRef.current;
    const width = canvas.width;
    const height = canvas.height;
    const usedAreas = Array(7).fill(false); // 사용된 등분을 추적하기 위한 배열
    const usedIndexes = new Set(); // 현재까지 그려진 다리들의 인덱스 배열

    const startY = 50 + canvasHeight;

    for (let i = 0; i < numBridges; i++) {
      // 사용된 기둥 인덱스를 추적
      usedIndexes.add(startIndex);
      usedIndexes.add(endIndex);

      const startX = (startIndex / users) * width + ((1 / users) * width) / 2;
      const endX = (endIndex / users) * width + ((1 / users) * width) / 2;

      let area; // 변수 추가: 선택된 등분
      let maxTries = 10; // 몇 번 시도할 것인지를 정의
      let tries = 0; // 시도 횟수 초기화

      // 사용되지 않은 등분을 선택할 때까지 반복 (최대 시도 횟수 10)
      do {
        // 랜덤하게 등분 선택
        area = Math.floor(Math.random() * 7);
        tries++;
      } while (usedAreas[area] && tries < maxTries);

      // 시도 횟수가 최대 시도 횟수보다 작은 경우에만 해당 등분을 사용됨으로 표시
      if (tries < maxTries) {
        usedAreas[area] = true;

        // 해당 등분 내에서 Y 값을 선택
        // const minY = (height / 7) * area;
        // const maxY = (height / 7) * (area + 1);

        // 여분의 여백을 고려하여 Y 값을 선택

        // Y 값을 시작과 끝 사이 일정 간격으로 계산
        // const randomOffset = Math.random() * 20 - 10;

        const Y1 = startY + (i / numBridges) * height;

        const linkId = new Date().getTime() * Math.random();

        const newBridge = {
          startBridge: {
            targetIndex: startIndex,
            x: startX,
            y: Y1,
            linkId,
            linkIndex: endIndex,
          },
          endBridge: {
            targetIndex: endIndex,
            x: endX,
            y: Y1,
            linkId,
            linkIndex: startIndex,
          },
        };
        setBridgeData((prev) => [...prev, newBridge]);
      }
    }
  };

  return (
    <div>
      <div className={classes.contentBox}>
        <h1 className={classes.title}>사다리 타기</h1>
        <div className={classes.inputBox}>
          <label htmlFor="user-count">인원 수 : </label>
          <input
            type="number"
            id="user-count"
            value={users}
            onChange={(e) => {
              const { value } = e.target;
              if (value < 2 || value > 8) {
                alert("2~8의 숫자를 골라주세요");
                setUsers(8);
                return;
              }
              setUsers(value);
            }}
          />
          <button onClick={drawBaseLine}>생성</button>
          <button onClick={clearContext}>리셋</button>
        </div>
        <div className={classes.containerBox}>
          <div className={classes.gameBox}>
            <div
              style={{
                width: 900,
                // border: "2px solid black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: ((1 / users) * 730) / 2,
                  marginRight: ((1 / users) * 730) / 2,
                }}
              >
                {Array.from({ length: users }, (_, i) => i + 1).map((u) => (
                  <img
                    style={{ width: 70, height: 70 }}
                    onClick={() => {
                      tracePathAnimated(u - 1);
                      setSelectedVehicleIndex(u - 1);
                    }}
                    src={GhostLegImg[u - 1]}
                  />
                ))}
              </div>
              {console.log(selectedVehicleIndex)}
            </div>
            <canvas
              style={{ display: "block", zIndex: 1, position: "absolute" }}
              ref={canvasRef}
            ></canvas>
            <canvas
              style={{ display: "block", zIndex: 2, position: "absolute" }}
              ref={pathCanvasRef}
            ></canvas>

            <div
              style={{
                width: 900,
                border: "1px solid black",
                height: "600px",
                alignItems: "flex-end",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "900px",
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: ((1 / users) * 700) / 2,
                  marginRight: ((1 / users) * 700) / 2,
                }}
              >
                {Array.from({ length: users }, (v, i) => i + 1).map((u) => (
                  <input
                    key={new Date().getTime() * u}
                    style={{ width: 50, height: 30, textAlign: "center" }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GhostLeg;
