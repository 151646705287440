import React, { useEffect, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import { Header } from "@withplusdream/withplus-live";
import { useMediaQuery } from "react-responsive";

import { Avatar, Box, Container, Grid, IconButton, Tooltip, Typography } from "@mui/material";

import SimpleGameBack from "../../../assets/images/simpleGameBack.png";
import { useLocation } from "react-router-dom";

import classes from "./ArrowSpinning.module.css";
import Arrow from "./ArrowSpinningImg/arrowblue.png";
import ArrowMiddle from "./ArrowSpinningImg/arrowmiddle.png";
import * as S from "./style";

//SimpleGameLayout 레이아웃
export const SimpleGameArrowSpinning = (props) => {
  const { children, isLobby, type } = props;
  const handle = useFullScreenHandle();

  const [isFullscreen, setFullscreen] = useState(false);
  const [isAccessCodeOpened, setAccessCodeOpened] = useState(false);
  const [isAccessCodeVisible, setAccessCodeVisible] = useState(false);

  const { state } = useLocation();

  const laptop = useMediaQuery({
    query: "(min-width:1400px)",
  });

  const desktop = useMediaQuery({
    query: "(max-width:1700px)",
  });

  const isPc = useMediaQuery({
    query: "(min-width:1001px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width:1000px)",
  });

  const [animationStarted, setAnimationStarted] = useState(false);

  const randomAngle = Math.floor(Math.random() * 360);

  let testRandom = randomAngle;

  const startAnimation = () => {
    console.log(randomAngle);
    setAnimationStarted((pre) => !pre);
  };

  console.log(animationStarted);

  const handleFullscreen = () => {
    setFullscreen(!isFullscreen);

    if (isFullscreen) handle.exit();
    else handle.enter();
  };

  const handleAccessCodeContainer = () => {
    setAccessCodeOpened(!isAccessCodeOpened);
  };

  useEffect(() => {
    if (isAccessCodeOpened) {
      setAccessCodeVisible(true);
    } else {
      setTimeout(() => {
        setAccessCodeVisible(false);
      }, 1000);
    }
  }, [isAccessCodeOpened]);

  useEffect(() => {
    if (handle.active) {
      setFullscreen(true);
    } else {
      setFullscreen(false);
    }
  }, [handle.active]);

  useEffect(() => {
    if (isLobby) {
      setTimeout(() => {
        setAccessCodeOpened(true);
      }, 2000);
    } else {
      if (state?.isGameStarted) {
        setTimeout(() => {
          setAccessCodeOpened(false);
        }, 2000);
      } else {
        setAccessCodeOpened(false);
      }
    }
  }, [isLobby]);

  return (
    <>
      {isPc && (
        <Box
          sx={{
            bgcolor: "#f5f5f5",
            height: "calc(100vh - 80px)",
            pt: "80px",
          }}
        >
          <Box
            className="paper"
            sx={{
              height: "calc(100% - 20px)",
              mt: "10px",
              mx: "10px",
            }}
          >
            <Box
              sx={{
                height: "100%",
                p: "30px",
                boxSizing: "border-box",
                background: `url(${SimpleGameBack})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
                <div className={classes.content}>
                  <div className={classes.boxStyle} />
                  <div className={classes.middleBox}>
                    <h2 className={classes.middleBoxH2}>&nbsp;&nbsp;화살표 돌리기&nbsp;&nbsp;</h2>
                  </div>
                  <div className={classes.ArrowSpinningBox}>
                    <S.ArrowAnimation
                      src={Arrow}
                      $isClicked={animationStarted}
                      $rotationAngle={randomAngle + 810}
                    />
                    <img className={classes.arrowImg2} src={ArrowMiddle} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button className={classes.button} onClick={startAnimation}>
                      시작하기
                    </button>
                  </div>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {isMobile && (
        <>
          <Box className={classes.mobileBody}>
            <Container
              maxWidth="xs"
              disableGutters
              sx={{
                bgcolor: "red",
                height: "calc(100% - 10px)",
                p: "20px",
                background: `url(${SimpleGameBack})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              <div className={classes.mobileContent}>
                <div className={classes.boxStyle} style={{ marginTop: "0px" }} />
                <div className={classes.middleBox}>
                  <h3 className={classes.middleBoxH2} style={{ marginTop: "10px" }}>
                    &nbsp;&nbsp;화살표 돌리기&nbsp;&nbsp;
                  </h3>
                </div>
                <div className={classes.mobileArrowSpinningBox}>
                  <S.ArrowAnimation
                    src={Arrow}
                    $isClicked={animationStarted}
                    $rotationAngle={randomAngle + 810}
                  />
                  <img className={classes.moblieArrowImg2} src={ArrowMiddle} />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button className={classes.button} onClick={startAnimation}>
                    시작하기
                  </button>
                </div>
              </div>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};
