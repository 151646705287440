import React, { useContext, useEffect, useState, useRef } from "react";

import { useParams } from "react-router-dom";
import { DndContext, useSensors, useSensor, PointerSensor, DragOverlay } from "@dnd-kit/core";
import { arrayMove, horizontalListSortingStrategy } from "@dnd-kit/sortable";

import { Box, Button, Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import * as S from "../styles";

import { ManagerContext } from "views/LiveBoard/contexts/Manager";
import { Sortable } from "components/DragDrop/Sortable";
import { SortableBox } from "components/DragDrop/Droppable";
import GroupHeader from "../components/GroupHeader";
import Card from "../components/Card";

export default () => {
  const { gameId } = useParams();
  const { list, layout, setList, socket, sections, handlePostModal } = useContext(ManagerContext);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 },
    })
  );

  const [postList, setPostList] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);

  const [cardSize, setCardSize] = useState("16%");

  const onDragStart = (e) => {
    const { id } = e.active;

    const item = list.find((item) => item.id === id);

    setDraggingItem(item);
  };

  const onDragOver = (e) => {
    const { active, over } = e;

    if (!over) return;

    setDraggingItem({ ...draggingItem, index: over.data.current?.sortable.index || 0 });

    if (layout.section) {
      const { containerId: id } = active.data.current.sortable;
      const overId = over.data.current?.sortable.containerId || over.id;

      const activeGroup = postList.find((section) => section.id === id);
      const overGroup = postList.find((section) => section.id === overId);

      if (!activeGroup || !overGroup || activeGroup === overGroup) return;

      setPostList((prev) => {
        const activeItems = activeGroup.list;
        const overItems = overGroup.list;

        const activeItem = activeItems.find((item) => item.id === active.id);

        return prev.map((group) => {
          if (group.id === id) {
            return { ...group, list: activeItems.filter((item) => item.id !== active.id) };
          }

          if (group.id === overId) {
            return { ...group, list: [...overItems, { ...activeItem, section: overId }] };
          }

          return group;
        });
      });
    }
  };

  const onDragEnd = (e) => {
    setDraggingItem(null);

    const { active, over } = e;

    if (!over) return;

    if (layout.section) {
      const { id } = active;
      const { id: overId } = over;
      const { containerId } = active.data.current.sortable;
      const activeGroup = postList.find((section) => section.id === containerId);

      if (id === overId) return;

      const activeIndex = activeGroup.list.findIndex((item) => item.id === id);
      const overIndex = activeGroup.list.findIndex((item) => item.id === overId);

      const result = arrayMove(activeGroup.list, activeIndex, overIndex);

      let newPostList = postList.map((group) =>
        group.id === containerId ? { ...group, list: result } : group
      );

      setPostList(newPostList);

      let newList = newPostList.reduce((acc, cur) => acc.concat(cur.list), []);

      setList(newList);

      socket.emit("sortPosts", { gameId, list: newList });
    } else {
      const activeIndex = list.findIndex((item) => item.id === active.id);
      const overIndex = list.findIndex((item) => item.id === over.id);

      const result = arrayMove(list, activeIndex, overIndex);

      setList(result);

      socket.emit("sortPosts", { gameId, list: result });
    }
  };

  useEffect(() => {
    if (!layout.type) return;

    if (layout.postSize === "big") {
      setCardSize("25%");
    } else {
      setCardSize("16%");
    }
  }, [layout]);

  useEffect(() => {
    if (sections.length === 0 || !layout.type) return;

    if (layout.section) {
      let newPostList = [];

      for (let section of sections) {
        let sectionList = list.filter((item) => item.section === section.id);

        newPostList.push({ ...section, list: sectionList });
      }

      setPostList(newPostList);
    }
  }, [layout, sections, list]);

  if (layout.section) {
    if (layout.sort === "likes") {
      return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          {postList.map((section) => (
            <Box key={section.id}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Box sx={{ width: "30%" }}>
                  <GroupHeader section={section} />
                </Box>

                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#888",
                    font: "600 16px Pretendard",
                    "&:hover": { bgcolor: "#777" },
                  }}
                  onClick={handlePostModal}
                  id={section.id}
                >
                  <AddIcon />
                  게시물 등록하기
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  width: "calc(100% - 10px)",
                  overflow: "auto",
                  "::-webkit-scrollbar": { height: "12.5px" },
                  bgcolor: "#eee",
                  boxShadow: "inset 0 0 10px #00000033",
                  borderRadius: "8px",
                  minHeight: "300px",
                  p: "10px",
                }}
              >
                {section.list
                  .sort((a, b) => (b.likes?.length || 0) - (a.likes?.length || 0))
                  .map((item) => (
                    <Box key={item.id} style={{ width: cardSize, p: "10px", flexShrink: 0 }}>
                      <Card item={item} key={item.id} timeline />
                    </Box>
                  ))}
              </Box>
            </Box>
          ))}

          <Box sx={{ width: "30%", pb: "10px" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                bgcolor: "#aaaaaa88",
                "&:hover": { bgcolor: "#88888888" },
              }}
              onClick={() => {
                socket.emit("addGroup", { gameId });
              }}
            >
              <AddIcon sx={{ fontSize: "30px", color: "#000" }} />

              <Typography sx={{ font: "700 16px Pretendard", color: "#000" }}>
                그룹 추가하기
              </Typography>
            </Button>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
        <DndContext
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDragEnd={onDragEnd}
          sensors={sensors}
        >
          {postList.map((section) => (
            <Box key={section.id}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Box sx={{ width: "30%" }}>
                  <GroupHeader section={section} />
                </Box>

                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#888",
                    font: "600 16px Pretendard",
                    "&:hover": { bgcolor: "#777" },
                  }}
                  onClick={handlePostModal}
                  id={section.id}
                >
                  <AddIcon />
                  게시물 등록하기
                </Button>
              </Box>

              <SortableBox
                id={section.id}
                items={section.list}
                strategy={horizontalListSortingStrategy}
                sx={{
                  display: "flex",
                  gap: "20px",
                  width: "100%",
                  overflow: "auto",
                  "::-webkit-scrollbar": { height: "12.5px" },
                  bgcolor: "#eee",
                  boxShadow: "inset 0 0 10px #00000033",
                  borderRadius: "8px",
                  minHeight: "300px",
                }}
              >
                {section.list.map((item, index) => (
                  <Sortable
                    key={item.id}
                    id={item.id}
                    style={{ width: cardSize, p: "10px", flexShrink: 0 }}
                    dragging-style={{ opacity: 0.5 }}
                    is-dragging={draggingItem ? "true" : null}
                  >
                    <Card
                      item={item}
                      index={index}
                      timeline
                      sort-mode={draggingItem ? "true" : null}
                    />
                  </Sortable>
                ))}
              </SortableBox>
            </Box>
          ))}

          {draggingItem && (
            <DragOverlay>
              <Box sx={{ position: "relative" }}>
                <Card item={draggingItem} timeline sort-mode="true" />

                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bgcolor: "#c33c3c",
                    color: "#fff",
                    p: "5px",
                    fontWeight: "bold",
                    borderTopRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                  }}
                >
                  {draggingItem.index}
                </Box>
              </Box>
            </DragOverlay>
          )}
        </DndContext>

        <Box sx={{ width: "30%", pb: "10px" }}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              bgcolor: "#aaaaaa88",
              "&:hover": { bgcolor: "#88888888" },
            }}
            onClick={() => {
              socket.emit("addGroup", { gameId });
            }}
          >
            <AddIcon sx={{ fontSize: "30px", color: "#000" }} />

            <Typography sx={{ font: "700 16px Pretendard", color: "#000" }}>
              그룹 추가하기
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  }

  if (layout.sort === "likes") {
    return (
      <S.TimeLine>
        <Box
          sx={{
            width: "100%",
            overflow: "auto",
            display: "flex",
            gap: "20px",
            flexShrink: 0,
            "::-webkit-scrollbar": { height: "12.5px" },
            "> div": {
              flexShrink: 0,
            },
          }}
        >
          {list
            .sort((a, b) => (b.likes?.length || 0) - (a.likes?.length || 0))
            .map((item) => (
              <Box key={item.id} style={{ width: cardSize, p: "10px" }}>
                <Card item={item} key={item.id} timeline />
              </Box>
            ))}
        </Box>
      </S.TimeLine>
    );
  }

  return (
    <S.TimeLine>
      <DndContext
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragEnd={onDragEnd}
        sensors={sensors}
      >
        <SortableBox
          items={list}
          strategy={horizontalListSortingStrategy}
          sx={{
            width: "100%",
            overflow: "auto",
            display: "flex",
            gap: "20px",
            flexShrink: 0,
            "::-webkit-scrollbar": { height: "12.5px" },
            "> div": {
              flexShrink: 0,
            },
          }}
          id="list"
        >
          {list.map((item) => (
            <Sortable
              key={item.id}
              id={item.id}
              style={{ width: cardSize, p: "10px" }}
              dragging-style={{
                opacity: 0.5,
              }}
              is-dragging={draggingItem ? "true" : null}
            >
              <Card item={item} timeline sort-mode={draggingItem ? "true" : null} />
            </Sortable>
          ))}
        </SortableBox>

        {draggingItem && (
          <DragOverlay>
            <Box sx={{ position: "relative" }}>
              <Card item={draggingItem} timeline sort-mode="true" />

              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bgcolor: "#c33c3c",
                  color: "#fff",
                  p: "5px",
                  fontWeight: "bold",
                  borderTopRightRadius: "8px",
                  borderBottomLeftRadius: "8px",
                }}
              >
                {draggingItem.index}
              </Box>
            </Box>
          </DragOverlay>
        )}
      </DndContext>
    </S.TimeLine>
  );
};
