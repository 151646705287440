import { styled } from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";
import { Box, Typography, Container, Button } from "@mui/material";

export const Body = MuiStyled((props) => (
  <Box
    sx={{
      pt: "75px",
      height: "100vh",
      boxSizing: "border-box",

      "@media (max-width: 1000px)": {
        bgcolor: "#f5f5f5",
        height: "auto",
      },
    }}
    {...props}
  />
))({});

export const container = MuiStyled((props) => (
  <Container
    sx={{
      bgcolor: "white",
      minWidth: "315px",
    }}
    {...props}
  />
))({});

export const Intro = MuiStyled((props) => (
  <Box
    sx={{
      backgroundSize: "cover",
      width: "100%",
      maxWidth: "1920px",
      aspectRatio: "1917/765",
      position: "relative",
      margin: "0 auto",
      imageRendering: "-webkit-optimize-contrast",
      backfaceVisibility: "hidden",
      transform: "translateZ(0)",

      "@media (max-width: 1000px)": {
        backgroundSize: "100% 100%",
        aspectRatio: "444/500",
      },
    }}
    {...props}
  />
))({});

export const IntroTitle = MuiStyled((props) => (
  <Typography
    sx={{
      font: "bold 5.208vw Pretendard",
      top: "25%",
      left: "12.5%",
      position: "absolute",
      fontSize: "clamp(52px, 5.208vw, 100px)",

      "@media (max-width: 1000px)": {
        top: "22%",
        fontSize: "clamp(35px, 11.26vw, 50px)",
      },
    }}
    {...props}
  />
))({});

export const IntroExplain1 = MuiStyled((props) => (
  <Typography
    sx={{
      position: "absolute",
      top: "50%",
      left: "12.5%",
      font: "600 1.14vw Pretendard",
      fontSize: "clamp(11px, 1.14vw, 22px)",

      "@media (max-width: 1000px)": {
        top: "37.5%",
        fontSize: "clamp(10px, 3.153vw, 14px)",
      },
    }}
    {...props}
  />
))({});

export const IntroExplain2 = MuiStyled((props) => (
  <Typography
    sx={{
      position: "absolute",
      top: "60%",
      left: "12.5%",
      font: "600 1.14vw Pretendard",
      fontSize: "clamp(11px, 1.14vw, 22px)",

      "@media (max-width: 1000px)": {
        top: "47.5%",
        fontSize: "clamp(10px, 3.153vw, 14px)",
      },
    }}
    {...props}
  />
))({});

export const IntroButton = MuiStyled((props) => (
  <Button
    variant="contained"
    sx={{
      position: "absolute",
      top: "71%",
      left: "12.5%",
      background: "linear-gradient(97.7deg, #CC2A4C 1.23%, #691321 134.48%)",
      font: "600 1.35vw Pretendard",
      fontSize: "clamp(13px, 1.35vw, 26px)",
      padding: "clamp(10px, 1.04vw, 20px) clamp(26px, 2.6vw, 52px)",
      borderRadius: "13px",
      transition: "transform 0.3s",

      "&:hover": {
        transform: "scale(1.05)",
      },
      "&:active": {
        transform: "scale(1.05) translateY(4px)",
      },

      "@media (max-width: 1000px)": {
        top: "60%",
        fontSize: "clamp(14px, 4.5vw, 20px)",
      },
    }}
    {...props}
  />
))({});

export const IntroCharacter = styled.img`
  position: absolute;
  bottom: 15%;
  right: 15%;
  width: 30%;

  @media (max-width: 1000px) {
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 50%;
  }
`;

export const LivePickBox = MuiStyled((props) => (
  <Box
    sx={{
      textAlign: "center",
      bgcolor: "#f0cbc6",
      font: "16px Pretendard-Regular",
      color: "#c91f5f",
      padding: "3px 20px",
      borderRadius: "14px",
      width: "max-content",
    }}
    {...props}
  />
))({});

export const EffectContainer = MuiStyled((props) => (
  <Box
    sx={{
      mt: "95px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
    {...props}
  />
))({});

export const Title = MuiStyled((props) => (
  <Typography
    sx={{
      mt: "10px",
      font: "700 36px Pretendard",
      color: "#202020",

      "@media (max-width: 1000px)": {
        fontSize: "clamp(25px, 8.1vw, 36px)",
      },
    }}
    {...props}
  />
))({});

export const Explain = MuiStyled((props) => (
  <Typography
    sx={{
      mt: "15px",
      font: "500 20px Pretendard",
      color: "#343434",
      textAlign: "center",

      "@media (max-width: 1000px)": {
        fontSize: "clamp(11px, 3.6vw, 16px)",
      },
    }}
    {...props}
  />
))({});

export const EffectBox = MuiStyled((props) => (
  <Box
    sx={{
      mt: "25px",
      width: "100%",
      maxWidth: "1500px",
      display: "flex",
      gap: "50px",
      flexWrap: "wrap",
      justifyContent: "center",
      padding: "25px 0",

      "> .swiper": {
        padding: "10px 0",
        paddingBottom: "50px",
      },

      "> .swiper .swiper-wrapper .swiper-slide": {
        display: "flex",
        justifyContent: "center",
      },

      "> .swiper .swiper-button-prev, > .swiper .swiper-button-next": {
        width: "clamp(27px , 9vw, 40px)",
        height: "clamp(27px , 9vw, 40px)",
      },

      "> .swiper .swiper-button-prev:after, > .swiper .swiper-button-next:after": {
        width: "clamp(27px , 9vw, 40px)",
        height: "clamp(27px , 9vw, 40px)",

        backgroundColor: "#2f3542",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: "clamp(14px ,4.5vw,20px)",
        fontWeight: "bold",
        cursor: "pointer",
      },
    }}
    {...props}
  />
))({});

export const EffectItem = MuiStyled((props) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "25px",
      width: "250px",
      height: "250px",
      borderRadius: "30px",
      boxShadow: "0px 3px 6px #0000004d",
    }}
    {...props}
  />
))({});

export const EffectItemIcon = styled.img`
  width: 55px;
`;

export const EffectItemTitle = MuiStyled((props) => (
  <Typography
    sx={{
      font: "bold 22px Pretendard",
      color: "#343434",
    }}
    {...props}
  />
))({});

export const EffectItemDescription = MuiStyled((props) => (
  <Typography
    sx={{
      font: "600 18px Pretendard",
      color: "#343434",
      textAlign: "center",
    }}
    {...props}
  />
))({});

export const LearningStructureContainer = MuiStyled((props) => (
  <Box
    sx={{
      mt: "80px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
    {...props}
  />
))({});

export const LearningStructureBox = MuiStyled((props) => (
  <Box
    sx={{
      mt: "50px",
      width: "100%",
      maxWidth: "1500px",
      display: "flex",
      flexWrap: "wrap",

      "> img": {
        width: "65%",
      },

      "@media (max-width: 1000px)": {
        flexDirection: "column",
        "> img": {
          width: "100%",
        },
      },
    }}
    {...props}
  />
))({});

export const LearningStructureExplain = MuiStyled((props) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",

      "@media (max-width: 1000px)": {
        mt: "10px",
        gap: "30px",
        justifyContent: "start",
      },
    }}
    {...props}
  />
))({});

export const LearningStructureItem = MuiStyled((props) => (
  <Box
    sx={{
      "@media (max-width: 1000px)": {
        width: "80%",
        margin: "0 auto",
      },
    }}
    {...props}
  />
))({});

export const LearningStructureTitle = MuiStyled((props) => (
  <Typography
    sx={{
      font: "700 23px Pretendard",
      color: "#202020",

      "& span": {
        font: "700 18px Pretendard",
        color: "#C91F5F",
      },
    }}
    {...props}
  />
))({});

export const LearningStructureDescription = MuiStyled((props) => (
  <Typography
    sx={{
      mt: "20px",
      font: "500 17px Pretendard",
      color: "#202020",

      "@media (max-width: 1000px)": {
        fontSize: "clamp(12px, 3.8vw, 17px)",
      },
    }}
    {...props}
  />
))({});

export const ConnectionScreenContainer = MuiStyled((props) => (
  <Box
    sx={{
      m: "80px auto",
      bgcolor: "#ebebeb",
      width: "100%",
      maxWidth: "1920px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "100px 0",
    }}
    {...props}
  />
))({});

export const ConnectionScreenBox = MuiStyled((props) => (
  <Box
    sx={{
      mt: props.id === "user" ? "150px" : null,
      p: "25px 0",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      width: "100%",
    }}
    {...props}
  />
))({});

export const ConnectionScreenTitle = MuiStyled((props) => (
  <Typography
    sx={{
      mt: "10px",
      font: "700 36px Pretendard",
      color: "#202020",
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplain = MuiStyled((props) => (
  <Typography
    sx={{
      mt: "50px",
      font: "500 20px Pretendard",
      color: "#343434",
      textAlign: "center",
      wordBreak: "keep-all",

      "@media (max-width: 1000px)": {
        fontSize: "16px",
      },
    }}
    {...props}
  />
))({});

export const ConnectionScreenImgBox = MuiStyled((props) => (
  <Box
    sx={{
      mt: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "413px",
      height: "485px",
      backgroundColor: "#fff",
      borderRadius: "30px",
      boxShadow: "0px 3px 6px #00000029",

      "> img": {
        width: props.id === "user" ? "328px" : "308px",
        height: props.id === "user" ? "321px" : "287px",
      },

      "@media (max-width: 1000px)": {
        width: "60%",
        height: "auto",
        aspectRatio: "413/485",

        "> img": {
          width: "80%",
          height: "auto",
        },
      },
    }}
    {...props}
  />
))({});

export const ConnectionScreenButton = MuiStyled((props) => (
  <Button
    variant="contained"
    sx={{
      mt: props.target === "true" ? "20px" : "50px",
      background: "linear-gradient(97.7deg, #CC2A4C 1.23%, #691321 134.48%)",
      font: "700 26px Pretendard",
      padding: "20px 30px",
      borderRadius: "13px",
      transition: "transform 0.3s",

      "&:hover": {
        transform: "scale(1.05)",
      },
      "&:active": {
        transform: "scale(1.05) translateY(4px)",
      },
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplainsContainer = MuiStyled((props) => (
  <Box
    sx={{
      position: props.target === "true" ? "static" : "absolute",
      width: props.target === "true" ? "auto" : "100%",
      maxWidth: "1145px",
      height: props.target === "true" ? "auto" : "343px",
      top: "35%",
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplainsBox = MuiStyled((props) => (
  <Box
    sx={{
      position: "relative",
      width: "100%",
      height: "100%",
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplainItem = MuiStyled((props) => (
  <Box
    sx={{
      position: props.target === "true" ? "static" : "absolute",
      top: props.id === "1" || props.id === "3" ? 0 : null,
      bottom: props.id === "2" || props.id === "4" ? 0 : null,
      left: props.id === "1" || props.id === "2" ? 0 : null,
      right: props.id === "3" || props.id === "4" ? 0 : null,
      my: props.target === "true" ? "20px" : null,
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplainItemTitleBox = MuiStyled((props) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "10px",
    }}
    {...props}
  />
))({});

export const RedCircle = MuiStyled((props) => (
  <Box
    sx={{
      bgcolor: "#CC2A4C",
      width: "28px",
      height: "28px",
      borderRadius: "50%",
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      font: "600 20px Pretendard",
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplainItemTitle = MuiStyled((props) => (
  <Typography
    sx={{
      font: "700 20px Pretendard",
      color: "#343434",
      fontSize: "clamp(17px, 1.74vw, 20px)",
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplainItemDescription = MuiStyled((props) => (
  <Typography
    sx={{
      mt: "10px",
      ml: "10px",
      font: "500 20px Pretendard",
      fontSize: "clamp(17px, 1.74vw, 20px)",
      color: "#343434",
    }}
    {...props}
  />
))({});
