import Axios from "axios";

const axios = Axios.create({
  baseURL: "https://board.withplus.live",
});

// * 보드 추가 API
export const addBoard = (auth, title, explain, callback) => {
  axios.post("/add", null, { params: { auth, title, explain } }).then((res) => {
    callback(res);
  });
};

// * 보드 리스트 API
export const getBoardList = (auth, callback) => {
  axios.get("/list", { params: { auth } }).then((res) => {
    callback(res);
  });
};

// * 보드 삭제 API
export const deleteBoard = (boardIdList, callback) => {
  axios.post("/delete", null, { params: { boardIdList } }).then((res) => {
    callback(res);
  });
};

// * 보드 복제 API
export const duplicateBoard = (auth, boardId, callback) => {
  axios.post("/duplicate", null, { params: { auth, boardId } }).then((res) => {
    callback(res);
  });
};

// * 이미지 업로드 API
export const uploadImage = async (file, fileId, callback) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post(`/image?name=${fileId}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  callback(response);
};

// * 보드 리셋 API
export const resetBoard = (boardId, callback) => {
  axios.post("/reset", null, { params: { boardId } }).then((res) => {
    callback(res);
  });
};

// * 보드 링크 검색 API
export const searchLink = (link, callback) => {
  axios.get("/search", { params: { link } }).then((res) => {
    callback(res);
  });
};

// * 보드 게시물 추가 API
export const addPost = (data, callback) => {
  axios.post("/post", data).then((res) => {
    callback(res);
  });
};

// * 보드 게시물 수정 API
export const modifyPost = (data, callback) => {
  axios.post("/modify", data).then((res) => {
    callback(res);
  });
};

// * 첨부파일 다운로드 API
export const downloadFile = (data, callback) => {
  const { gameId, fileId, type, fileName } = data;

  axios
    .get(`/download?gameId=${gameId}&fileId=${fileId}&type=${type}&fileName=${fileName}`, {
      responseType: "blob",
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      console.error(err);
      window.alert("파일을 다운로드하는 중 오류가 발생했습니다.");
      window.location.reload();
    });
};
