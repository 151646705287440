import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

import styles from "../styles/payment.module.css";

import logo from "assets/images/wLiveLogo(Red).png";
import axios from "axios";

export default () => {
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const params = {
      orderId: searchParams.get("order-id"),
    };

    axios.post("/api/payment/cancel-order", params);
  }, []);

  return (
    <div className={styles.checkout}>
      <div className={styles.body}>
        <div className={styles.logo}>
          <img src={logo} />

          <p>Withplus Live - 상품 결제</p>
        </div>

        <div className={styles.fail}>
          <img src="https://static.toss.im/lotties/error-spot-no-loop-space-apng.png" />

          <p>결제를 실패했어요.</p>
        </div>

        <div>
          <div className={styles.info}>
            <p>에러 코드</p>

            <p>{searchParams.get("code")}</p>
          </div>

          <div className={styles.info}>
            <p>에러 메시지</p>

            <p>{searchParams.get("message")}</p>
          </div>
        </div>

        <div>
          <Button
            className={styles.fail_btn}
            onClick={() => {
              window.location.href = "/diagnosis-plan";
            }}
          >
            다시 시도하기
          </Button>
        </div>
      </div>
    </div>
  );
};
