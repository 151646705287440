import { useEffect, useState } from "react";
import S from "../style.module.css";

const SixthPageMobile = ({ onNextClick, onPrevClick, resultObjectArray, userData, firstTypeArray }) => {
  const [userType, setUserType] = useState("");
  const [userTypeSplit, setUserTypeSplit] = useState("");
  const [desires, setDesires] = useState([]);

  useEffect(() => {
    if (resultObjectArray[5]?.name) {
      const name = resultObjectArray[5]?.name.split("(")[0];
      setUserType(resultObjectArray[5]?.name);
      setUserTypeSplit(name);
    }
    if (firstTypeArray[3]?.desire) {
      setDesires(firstTypeArray[3]?.desire.split("."));
    }
  }, [resultObjectArray, firstTypeArray]);

  return (
    <div className={S.Page}>
      <div className={S.Title}>
        <p>{userData.name} 님의 심리적 욕구</p>
      </div>
      <div className={S.PageContents}>
        <div className={S.Box}>
          <p className={S.SixthPageDesireTitle}>{userTypeSplit} 심리적 욕구</p>
          <p className={S.SixthPageDesireDesc}>
            {desires.map((desire, index) => desire && (
              <span key={index}>{desire.trim()}</span>
            ))}
          </p>
        </div>
      </div>
      <p className={S.alert}>※ 본 모바일 결과지는 요약본입니다</p>
      <div className={S.PageFooter}>
        <div className={S.PrevBtn} onClick={onPrevClick}>
          ← 뒤로가기
        </div>
        <div className={S.NextBtn} onClick={onNextClick}>
          다음
        </div>
      </div>
    </div>
  );
}

export default SixthPageMobile;