import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Auth from "auth/auth";

import ListPage from "./ListPage";
import EditPage from "./EditPage";
import ManagerPage from "./ManagerPage";
import UserPage from "./UserPage";

import { ManagerProvider } from "./contexts/ManagerContext";
import { UserProvider } from "./contexts/UserContext";

const Index = (props) => {
  return (
    <Routes>
      <Route path="/list" element={<Auth Component={ListPage} isAuthRequired />} />
      <Route path="/edit/:gameId" element={<Auth Component={EditPage} isAuthRequired />} />

      <Route
        path="/manager/:gameId/*"
        element={
          <ManagerProvider>
            <Auth Component={ManagerPage} isAuthRequired />
          </ManagerProvider>
        }
      />

      <Route
        path="/user/:gameId/*"
        element={
          <UserProvider>
            <UserPage />
          </UserProvider>
        }
      />
    </Routes>
  );
};

export default Index;
