// * npm modules
import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";

// * material UI components
import { Box, IconButton, Typography } from "@mui/material";

// * material UI Icons
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

// * 프로젝트 컴포넌트
import Intro from "./Intro";
import WordCloud from "./WordCloud";
import Choice from "./Choice";
import Outro from "./Outro";

import { UserContext } from "../contexts/UserContext";
import { UserQnAModal } from "components/Modal";
import { SnackBarContext } from "components/SnackBar/ContextAPI";

// * images
import wliveLogo from "assets/images/wliveLogo.png";

// * styles
import * as S from "./styles";
import OpenEnded from "./OpenEnded";

// * 라우터
export default () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/:slideId" element={<Layout />} />
    </Routes>
  );
};

// * 라우터 컴포넌트 (Index)
const Index = () => {
  const navigate = useNavigate(); // * 네비게이션
  const { gameId } = useParams(); // * 게임 아이디
  const { socket } = useContext(UserContext); // * 소켓

  useEffect(() => {
    if (!socket) return; // * 소켓이 없으면 리턴

    // * 폴링 데이터 요청
    socket.emit("getPolling", { gameId }, (poll) => {
      const slideList = poll.playingData; // * 슬라이드 리스트
      const currentSlideIndex = poll.currentSlideIndex; // * 현재 슬라이드 인덱스

      navigate(`${slideList[currentSlideIndex].id}`, { replace: true }); // * 현재 슬라이드로 이동
    });
  }, [socket]);

  return <></>;
};

// * 	레이아웃 컴포넌트
const Layout = () => {
  const { gameId, slideId } = useParams(); // * 게임 아이디, 슬라이드 아이디
  const { socket, likes, playingData, setLikes } = useContext(UserContext); // * 소켓, 좋아요, 플레이 데이터, 좋아요 설정
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext); // * SnackBar Context

  const [currentSlide, setCurrentSlide] = useState(null); // * 현재 슬라이드
  const [isQnAModalOpened, setQnAModalOpened] = useState(false); // * QnA 모달 오픈 여부

  // * QnA 모달 핸들러
  const handleQnA = () => {
    setQnAModalOpened((prev) => !prev); // * QnA 모달 오픈 여부 토글
  };

  // * 좋아요 제출
  const submitLike = () => {
    const uuid = localStorage.getItem("uuid"); // * 유저 아이디

    // * 좋아요 제출
    socket.emit("submitLike", { gameId, userId: uuid }, (res) => {
      if (res.success) {
        setLikes(true); // * 좋아요 설정
      }
    });
  };

  // * 질문 제출
  const submitQuestion = (question) => {
    // * 질문 내용이 없을 때
    if (question.trim() === "") {
      setOpen(true); // * SnackBar 오픈
      setSeverity("warning"); // * SnackBar 타입
      setText("질문을 입력해주세요."); // * SnackBar 내용
      return;
    }

    const uuid = localStorage.getItem("uuid"); // * 유저 아이디

    // * 질문 제출
    socket.emit("submitQuestion", { gameId, userId: uuid, question }, (res) => {
      // * 질문이 성공적으로 등록되었을 때
      if (res.success) {
        setOpen(true); // * SnackBar 오픈
        setSeverity("success"); // * SnackBar 타입
        setText("질문이 성공적으로 등록되었습니다."); // * SnackBar 내용
        setQnAModalOpened(false); // * QnA 모달 닫기
      } else {
        setOpen(true); // * SnackBar 오픈
        setSeverity("error"); // * SnackBar 타입
        setText(
          "질문 등록은 3개까지 가능합니다. 진행자가 이전 질문에 답변을 하면 추가로 질문을 등록할 수 있습니다."
        ); // * SnackBar 내용
      }
    });
  };

  // * 현재 슬라이드 설정
  useEffect(() => {
    if (!playingData.length) return; // * 플레이 데이터가 없으면 리턴

    const currentSlide = playingData.find((data) => data.id === slideId); // * 현재 슬라이드
    setCurrentSlide(currentSlide); // * 현재 슬라이드 설정
  }, [playingData, slideId]);

  return (
    <S.Body>
      <S.container>
        {/* 헤더 */}
        <S.Header>
          <S.Logo src={wliveLogo} alt="wlive logo" $filter />

          <S.QnaButton onClick={handleQnA}>Q&A</S.QnaButton>
        </S.Header>

        {/* 현재 슬라이드 */}
        <Box>
          {
            // * 현재 슬라이드가 있을 때
            currentSlide &&
              (() => {
                // * 현재 슬라이드 타입에 따라 컴포넌트 렌더링
                switch (currentSlide.type) {
                  case "Intro":
                    return <Intro />;
                  case "WordCloud":
                    return <WordCloud />;
                  case "Choice":
                    return <Choice />;
                  case "OpenEnded":
                    return <OpenEnded />;
                  case "Outro":
                    return <Outro />;
                  default:
                    return <></>;
                }
              })()
          }
        </Box>

        {/* 푸터 */}
        <S.Footer>
          {/* 좋아요 버튼 */}
          <S.LikeButton
            size="large"
            onClick={submitLike}
            sx={{
              bgcolor: likes ? "#c33c3c" : "#fff",
              color: likes ? "#fff" : "#c33c3c",
              "&:hover": {
                bgcolor: likes ? "#c33c3c" : "#eee",
              },
            }}
          >
            <ThumbUpIcon fontSize="large" />
          </S.LikeButton>

          {/* 저작권 */}
          <Typography sx={{ font: "400 14px Pretendard", ml: "10px" }}>
            Copyright ⓒ Withplus. All Right Reserved
          </Typography>
        </S.Footer>
      </S.container>

      {/* QnA 모달 */}
      {isQnAModalOpened && (
        <UserQnAModal open={isQnAModalOpened} onClose={handleQnA} action={submitQuestion} />
      )}
    </S.Body>
  );
};
