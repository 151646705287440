import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

import {
  Box,
  Typography,
  Button,
  Checkbox,
  InputAdornment,
  Avatar,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PendingIcon from "@mui/icons-material/Pending";

import { Textfield } from "components/Textfield";
import { AddMemberModal, DeleteMemberModal } from "components/Modal/Modal";
import { SnackBarContext } from "components/SnackBar/ContextAPI";

const Members = ({ user }) => {
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);

  const [isAddMemberModalOpened, setIsAddMemberModalOpened] = useState(false);
  const [isDeleteMemberModalOpened, setIsDeleteMemberModalOpened] = useState(false);
  const [members, setMembers] = useState([]);
  const [list, setList] = useState([]);
  const [input, setInput] = useState("");
  const [checkedList, setCheckedList] = useState([]);
  const [maximumMembersCount, setMaximumMembersCount] = useState(0);

  const handleAddMemberModal = (e) => {
    setIsAddMemberModalOpened(!isAddMemberModalOpened);

    if (e === "add_success") {
      setInput("");
      getMembers();
    }
  };

  const getMembers = () => {
    axios.get("/api/group/members", { params: { auth: cookies.auth } }).then((res) => {
      const { success, members, groupMemberCount } = res.data;

      if (success) {
        setMembers(members);
        setCheckedList([]);
        setMaximumMembersCount(groupMemberCount);
      } else {
        window.location.reload();
      }
    });
  };

  const handleInput = (e) => {
    setCheckedList([]);
    setInput(e.target.value);
  };

  const handleDeleteMemberModal = (e) => {
    if (e.currentTarget.id !== "") {
      setCheckedList(members.filter((member) => member._id === e.currentTarget.id));
    }

    setIsDeleteMemberModalOpened(!isDeleteMemberModalOpened);
  };

  const handleAllCheckBox = () => {
    if (checkedList.length === list.length) {
      setCheckedList([]);
    } else {
      setCheckedList(list);
    }
  };

  const handleCheckBox = (e) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, members.find((member) => member._id === e.target.id)]);
    } else {
      setCheckedList(checkedList.filter((checked) => checked._id !== e.target.id));
    }
  };

  const handleSnackBar = () => {
    setText(`멤버는 최대 ${maximumMembersCount}명까지 추가할 수 있습니다.`);
    setSeverity("error");
    setOpen(true);
  };

  useEffect(() => {
    getMembers();
  }, []);

  useEffect(() => {
    if (input === "") {
      setList(members);
    } else {
      setList(members.filter((member) => member.name.includes(input)));
    }
  }, [members, input]);

  return (
    <div className="DashBoard">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            borderBottom: "1px solid #ddd",
            justifyContent: "space-between",
            marginBottom: "10px",
            paddingBottom: "10px",
          }}
        >
          <Typography
            fontSize="2rem"
            textAlign="left"
            color="primary"
            fontFamily="Pretendard"
            fontWeight="bold"
          >
            멤버 관리
            {user.plan !== "free" && (
              <span>({members.length + 1 + " / " + maximumMembersCount})</span>
            )}
          </Typography>

          <Button
            variant="contained"
            size="small"
            sx={{ font: "15px Pretendard", fontWeight: "500", p: "10px" }}
            disabled={user.plan === "free"}
            onClick={
              members.length + 1 >= maximumMembersCount ? handleSnackBar : handleAddMemberModal
            }
          >
            멤버 추가하기
          </Button>

          {isAddMemberModalOpened && (
            <AddMemberModal open={isAddMemberModalOpened} onClose={handleAddMemberModal} />
          )}
        </div>

        {user.plan === "free" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Typography sx={{ font: "500 16px Pretendard", lineHeight: "1.5", color: "#777" }}>
              무료 플랜인 계정에게는 멤버를 추가할 수 없습니다. <br />
              구독 플랜을 업그레이드 해주세요.
            </Typography>

            <Button
              variant="contained"
              onClick={() => navigate("/plan")}
              sx={{ font: "bold 14px NotoSansKR-Regular", width: "fit-content" }}
            >
              구독 플랜 변경하기 {user.status === "Group" && "(단체 계정용)"}
            </Button>
          </div>
        ) : (
          <div style={{ flex: 1 }}>
            <Box sx={{ borderRadius: "10px", overflow: "hidden" }}>
              <Box
                sx={{
                  position: "relative",
                  bgcolor: "#ebebeb",
                  padding: "10px",
                  boxSizing: "border-box",
                  textAlign: "left",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    width: "fit-content",
                    alignItems: "center",
                    // "&:hover": {
                    //   bgcolor: "#e5e5e5",
                    // },
                    pr: "10px",
                    // cursor: "pointer",
                  }}
                >
                  <Checkbox
                    checked={checkedList.length === list.length && list.length !== 0}
                    indeterminate={checkedList.length !== 0 && checkedList.length !== list.length}
                    onChange={handleAllCheckBox}
                  />

                  {checkedList.length === 0 ? (
                    <Typography sx={{ font: "500 18px Pretendard" }}>전체 선택</Typography>
                  ) : (
                    <Select
                      value="text"
                      sx={{
                        border: "1px solid #ced4da",
                        p: "5px",
                        "& .MuiInputBase-input": {
                          padding: "5px !important",
                          paddingRight: "30px !important",
                        },
                      }}
                      size="small"
                    >
                      <MenuItem sx={{ maxHeight: 0, overflow: "hidden", p: 0 }} value="text">
                        {checkedList.length}명이 선택됨
                      </MenuItem>
                      <MenuItem
                        onClick={handleDeleteMemberModal}
                        sx={{ font: "600 16px Pretendard", color: "#c33c3c" }}
                      >
                        선택한 멤버 제거
                      </MenuItem>
                    </Select>
                  )}
                </Box>
              </Box>

              {user && user.status === "Group" && (
                <Box
                  sx={{
                    bgcolor: "white",
                    height: "80px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: "5px",
                    borderBottom: "1px solid #eee",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <Checkbox disabled sx={{ opacity: 0 }} />

                    <Avatar sx={{ font: "bold 20px Pretendard" }}>{user.name.slice(0, 1)}</Avatar>

                    <Box sx={{ textAlign: "left" }}>
                      <Typography sx={{ font: "500 19px Pretendard", ml: "10px" }}>
                        {user.name}
                      </Typography>
                      <Typography sx={{ font: "400 15px Pretendard", ml: "10px", color: "#888" }}>
                        {user.email}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex", gap: "30px", alignItems: "center" }}>
                    <Typography
                      sx={{
                        font: "500 16px Pretendard",
                        color: "#0f0",
                      }}
                    >
                      나 (관리자 계정)
                    </Typography>
                    <Button
                      sx={{
                        font: "500 16px Pretendard",
                        opacity: 0,
                        "&:hover": {
                          backgroundColor: "#c33c3c",
                          color: "white",
                          boxShadow: "2px 2px 4px #00000050",
                        },
                      }}
                      disabled
                    >
                      제거하기
                    </Button>
                  </Box>
                </Box>
              )}

              <Box sx={{ bgcolor: "white", p: "10px 20px", borderBottom: "1px solid #eee" }}>
                <Textfield
                  size="small"
                  fullWidth
                  placeholder="찾고 싶은 멤버의 이름을 적어주세요."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: "6px",
                    },
                  }}
                  value={input}
                  onChange={handleInput}
                />
              </Box>

              <Box sx={{ maxHeight: "500px", overflow: "auto" }}>
                {list.map((member, i) => (
                  <Box
                    key={member._id}
                    sx={{
                      bgcolor: "white",
                      height: "80px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      px: "5px",
                      borderBottom: "1px solid #eee",
                      opacity: member.isAccepted ? "1" : "0.6",
                    }}
                  >
                    <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <Checkbox
                        checked={checkedList.some((checked) => checked._id === member._id)}
                        onChange={handleCheckBox}
                        id={member._id}
                      />

                      <Avatar sx={{ font: "bold 20px Pretendard" }}>
                        {member.name.slice(0, 1)}
                      </Avatar>

                      <Box sx={{ textAlign: "left" }}>
                        <Typography sx={{ font: "500 19px Pretendard", ml: "10px" }}>
                          {member.name}
                        </Typography>
                        <Typography sx={{ font: "400 15px Pretendard", ml: "10px", color: "#888" }}>
                          {member.email}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex", gap: "30px", alignItems: "center" }}>
                      <Typography
                        sx={{
                          font: "500 16px Pretendard",
                          color: member.isAccepted ? "#0f0" : "#bbb",
                        }}
                      >
                        {member.isMe ? "나" : member.isAccepted ? "참가자" : "초대 수락 대기중"}
                      </Typography>
                      <Button
                        sx={{
                          font: "500 16px Pretendard",
                          "&:hover": {
                            backgroundColor: "#c33c3c",
                            color: "white",
                            boxShadow: "2px 2px 4px #00000050",
                          },
                        }}
                        id={member._id}
                        onClick={handleDeleteMemberModal}
                      >
                        제거하기
                      </Button>
                    </Box>
                  </Box>
                ))}
              </Box>

              {/* <Box
                sx={{
                  bgcolor: "white",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  p: "5px 10px",
                  gap: "20px",
                }}
              >
                <Typography sx={{ font: "600 16px Pretendard", color: "#888" }}>
                  총 5명의 멤버 중 1-5
                </Typography>

                <Box sx={{ display: "flex" }}>
                  <IconButton size="small" sx={{ px: "10px" }} disabled>
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton size="small" sx={{ px: "10px" }}>
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box> */}
            </Box>
          </div>
        )}
      </div>

      {isDeleteMemberModalOpened && (
        <DeleteMemberModal
          open={isDeleteMemberModalOpened}
          onClose={handleDeleteMemberModal}
          checkedList={checkedList}
          getMembers={getMembers}
        />
      )}
    </div>
  );
};

export default Members;
