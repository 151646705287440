import React, { useContext, useEffect, useState } from "react";

import { Box, Typography, InputAdornment, Checkbox } from "@mui/material";

import { EditContext } from "../contexts/EditContext";
import * as S from "./styles";
import { Textfield } from "components/Textfield";
import { SnackBarContext } from "components/SnackBar/ContextAPI";

export const getOpenEndedData = () => {
  return {
    type: "OpenEnded",
    question: "",
    duplicateSubmission: false,
    completed: false,
  };
};

export const OpenEndedPreview = () => {
  const { currentSlide } = useContext(EditContext);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        p: "5px",
        boxSizing: "border-box",
      }}
    >
      <Typography
        sx={{
          font: "700 32px Pretendard",
          borderBottom: "1px solid #eee",
          color: "#252b36",
          wordBreak: "break-all",
        }}
      >
        {currentSlide.question === "" ? "질문을 입력해주세요." : currentSlide.question}
      </Typography>

      <Box
        sx={{
          flex: 1,
          position: "relative",
          p: "10px",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            font: "500 16px Pretendard",
            color: "#777",
          }}
        >
          투표 수: 100
          <br />
          <br />
          {"(예시 화면입니다.)"}
        </Typography>

        <Box sx={{ flex: 1, overflow: "auto", display: "flex", gap: "2%" }}>
          <Box
            sx={{
              width: "32%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                font: "500 20px Pretendard",
                p: "15px",
                bgcolor: "#f5f5f5",
                fontSize: "clamp(12px, 1.04vw, 20px)",
                wordBreak: "keep-all",
              }}
            >
              진행자님께 발표 중에 처음 언급된 주제에 대해 질문하고 싶습니다.
            </Box>

            <Box
              sx={{
                font: "500 20px Pretendard",
                p: "15px",
                bgcolor: "#f5f5f5",
                fontSize: "clamp(12px, 1.04vw, 20px)",
                wordBreak: "keep-all",
              }}
            >
              오늘 정말 즐거운 시간을 보냈어요!
            </Box>

            <Box
              sx={{
                font: "500 20px Pretendard",
                p: "15px",
                bgcolor: "#f5f5f5",
                fontSize: "clamp(12px, 1.04vw, 20px)",
                wordBreak: "keep-all",
              }}
            >
              이 프레젠테이션은 정말 재밌었어요! 감사합니다!
            </Box>
          </Box>

          <Box
            sx={{
              width: "32%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                font: "500 20px Pretendard",
                p: "15px",
                bgcolor: "#f5f5f5",
                fontSize: "clamp(12px, 1.04vw, 20px)",
                wordBreak: "keep-all",
              }}
            >
              이 인터렉션은 정말 재밌었어요! 감사합니다!
            </Box>

            <Box
              sx={{
                font: "500 20px Pretendard",
                p: "15px",
                bgcolor: "#f5f5f5",
                fontSize: "clamp(12px, 1.04vw, 20px)",
                wordBreak: "keep-all",
              }}
            >
              프레젠테이션을 진행해주셔서 정말 감사합니다.
            </Box>

            <Box
              sx={{
                font: "500 20px Pretendard",
                p: "15px",
                bgcolor: "#f5f5f5",
                fontSize: "clamp(12px, 1.04vw, 20px)",
                wordBreak: "keep-all",
              }}
            >
              이것은 매우 잘 준비된 발표였습니다. 당신의 지식을 우리와 공유해 주셔서 감사합니다!
              훌륭했습니다
            </Box>
          </Box>

          <Box
            sx={{
              width: "32%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                font: "500 20px Pretendard",
                p: "15px",
                bgcolor: "#f5f5f5",
                fontSize: "clamp(12px, 1.04vw, 20px)",
                wordBreak: "keep-all",
              }}
            >
              이 대화형 프레젠테이션에서 제 의견을 말할 수 있어서 정말 기쁩니다
            </Box>

            <Box
              sx={{
                font: "500 20px Pretendard",
                p: "15px",
                bgcolor: "#f5f5f5",
                fontSize: "clamp(12px, 1.04vw, 20px)",
                wordBreak: "keep-all",
              }}
            >
              마지막 부분을 다시 말씀해 주시겠습니까? 무슨 말인지 정확히 이해하지 못했습니다
            </Box>

            <Box
              sx={{
                font: "500 20px Pretendard",
                p: "15px",
                bgcolor: "#f5f5f5",
                fontSize: "clamp(12px, 1.04vw, 20px)",
                wordBreak: "keep-all",
              }}
            >
              잘했어요!
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const OpenEndedEditor = () => {
  const { currentSlide, updateCurrentSlide } = useContext(EditContext);
  // const { setText, setOpen, setSeverity } = useContext(SnackBarContext);

  const handleQuestion = (e) => {
    if (e.target.value.length > 75) {
      updateCurrentSlide({ question: e.target.value.slice(0, 75) });
    } else {
      updateCurrentSlide({ question: e.target.value });
    }
  };

  const handleDuplicateSubmission = (e) => {
    updateCurrentSlide({ duplicateSubmission: e.target.checked });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography sx={{ fontWeight: "600", color: "#c33c3c" }}>{"(* 필수 입력)"}</Typography>

      <Box>
        <Typography sx={{ fontWeight: "600", mb: "10px" }}>
          질문 <span style={{ color: "#c33c3c" }}>*</span>
        </Typography>

        <Textfield
          size="small"
          placeholder="질문을 입력해주세요. (75자 이내)"
          fullWidth
          value={currentSlide.question}
          onChange={handleQuestion}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography sx={{ color: "#252b36" }}>
                  {75 - currentSlide.question.length}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ fontWeight: "600", mb: "10px" }}>중복 제출 가능 여부</Typography>

        <Checkbox checked={currentSlide.duplicateSubmission} onChange={handleDuplicateSubmission} />
      </Box>
    </Box>
  );
};
