import io from "socket.io-client";

const environment = process.env.NODE_ENV;

// * 현재 연결된 와이파이 ip로 설정
// export const socket = io.connect(
//   // environment === "development" ? "192.168.0.50" : "https://plussol.wplusedu.co.kr",
//   environment === "development" ? "localhost" : "https://www.withplus.live",
//   // environment === "development" ? "192.168.0.154" : "https://www.withplus.live",
//   // environment === "development" ? "172.30.1.8" : "https://www.withplus.live",
//   // environment === "development" ? "172.30.1.36" : "https://www.withplus.live",
//   // environment === "development" ? "192.168.0.51" : "https://plussol.wplusedu.co.kr",

//   { cors: { origin: "*", reconnection: true } }
// );

export const socket = io.connect(
  // environment === "development" ? "192.168.0.50" : "https://plussol.wplusedu.co.kr",
  "https://www.withplus.live",
  // environment === "development" ? "192.168.0.154" : "https://www.withplus.live",
  // environment === "development" ? "172.30.1.8" : "https://www.withplus.live",
  // environment === "development" ? "172.30.1.36" : "https://www.withplus.live",
  // environment === "development" ? "192.168.0.51" : "https://plussol.wplusedu.co.kr",

  { cors: { origin: "*", reconnection: true } }
);
