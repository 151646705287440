import { Box, IconButton, Typography } from "@mui/material";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useContext, useEffect, useState } from "react";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

import Title from "components/Title";
// import { AudioContext } from "contexts/Audio";
import { ManagerContext } from "../contexts/ManagerContext";
import { AudioContext } from "../contexts/AudioContext";

const ManagerPageLayout = (props) => {
  const { children, title } = props;
  // const { bgm, bgmVolumn, setBgmVolumn, playBGM } = useContext(AudioContext);
  // const { handleBGM, setSoundVolume, soundVolume, BGM, prepareSounds } = useContext(ManagerContext);
  const { handleBGM, setSoundVolume, soundVolume, BGM, prepareSounds } = useContext(AudioContext);

  const [isFullscreen, setFullscreen] = useState(false);
  const [isAccessCodeOpened, setAccessCodeOpened] = useState(
    props.codeOpen !== undefined ? props.codeOpen : true
  );
  // const [isAccessCodeOpened, setAccessCodeOpened] = useState(true);
  const [isAccessCodeVisible, setAccessCodeVisible] = useState(false);

  // const [bgmVolumn, setBgmVolumn] = useState(0);

  const handle = useFullScreenHandle();

  // QR코드 창이 열려있는지 확인하는 함수
  const handleAccessCodeContainer = () => {
    setAccessCodeOpened(!isAccessCodeOpened);
  };

  const playBGM = () => {
    if (BGM.paused) {
      handleBGM();
      setSoundVolume(0.24);
    }
    prepareSounds();
  };

  const handleMuteSound = () => {
    if (BGM.paused) {
      handleBGM();
    }

    if (soundVolume === 0) {
      setSoundVolume(0.25);
    } else {
      setSoundVolume(0);
    }
  };

  const handleSoundVolume = (e) => {
    if (BGM.paused) {
      handleBGM();
    }

    setSoundVolume((e.target.value * 1) / 200);
  };

  // QR코드 창이 열렸는지 닫혔는지 상태가 변화할 떄마다 확인
  useEffect(() => {
    if (isAccessCodeOpened) {
      setAccessCodeVisible(true);
    } else {
      setTimeout(() => {
        setAccessCodeVisible(false);
      }, 1000);
    }
  }, [isAccessCodeOpened]);

  return (
    <Box
      sx={{
        height: "100vh",
        pt: "80px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        bgcolor: "#f5f5f5",
      }}
    >
      <Box sx={{ px: "20px" }}>
        <Title children={{ name: title }} />
      </Box>
      <FullScreen handle={handle}>
        <Box
          sx={{
            bgcolor: "#fff",
            width: "calc(100% - 20px)",
            borderRadius: "10px",
            boxSizing: "border-box",
            height: isFullscreen ? "100vh" : "calc(100% - 10px)",
            boxShadow: "0 3px 6px #00000038",
            m: "0 auto",
            maxWidth: "2560px",
            maxHeight: "1440px",
          }}
        >
          <Box
            sx={{
              height: "100%",
              overflow: "hidden",
              display: "flex",
              position: "relative",
              width: "100%",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                right: "5px",
                top: "5px",

                // flexWrap: "wrap",
                borderRadius: "50px",
                border: "1px solid #eee",
                bgcolor: "#fff",
                px: "5px",
                boxShadow: "0px 3px 6px #00000020",
                zIndex: 100,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={soundVolume * 200}
                  onChange={handleSoundVolume}
                  style={{
                    accentColor: "#c26974",
                  }}
                />
                <IconButton size="small" onClick={handleMuteSound}>
                  {soundVolume === 0 ? (
                    <VolumeOffIcon sx={{ fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)" }} />
                  ) : (
                    <VolumeUpIcon sx={{ fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)" }} />
                  )}
                </IconButton>
              </Box>

              {BGM.paused && (
                <Typography sx={{ px: "10px", color: "#444", fontWeight: "500" }}>
                  소리가 나지 않는다면?
                  <span
                    style={{ cursor: "pointer", textDecoration: "underline", color: "#c33c3c" }}
                    onClick={playBGM}
                  >
                    클릭하여 재생하기
                  </span>
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                borderRight: "1px solid #cdcdcd",
                position: "relative",
                transition: "1s",
                width: "100%",
                minWidth: isAccessCodeOpened ? "75%" : "100%",
              }}
            >
              {children.ContentBox}
              <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translate(50%, -50%)",
                }}
              >
                <IconButton
                  sx={{
                    bgcolor: "#dddddd",
                    color: "white",
                  }}
                  onClick={handleAccessCodeContainer}
                >
                  {isAccessCodeOpened ? (
                    <KeyboardArrowRightIcon />
                  ) : (
                    <KeyboardArrowLeftIcon
                      sx={{
                        margin: "2px 0px",
                        marginRight: "16px",
                      }}
                    />
                  )}
                </IconButton>
              </Box>
            </Box>

            <Box
              sx={{
                margin: "auto",
                display: isAccessCodeVisible ? "flex" : "none",
                justifyContent: "center",
                flexDirection: "column",
                transitionDelay: "1s",
                minWidth: isAccessCodeOpened ? "25%" : "0%",
              }}
            >
              {children.QRCodeBox}
            </Box>
          </Box>
        </Box>
      </FullScreen>
    </Box>
  );
};

export default ManagerPageLayout;
