import React, { useContext } from "react";
import { FullScreen } from "react-full-screen";

import { Box, Grid } from "@mui/material";

// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import FullscreenIcon from "@mui/icons-material/Fullscreen";
// import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import Title from "components/Title";
// import { LandingHeader } from "components/Header";
import { Header } from "@withplusdream/withplus-live";
import { FullScreenProvider, FullScreenContext } from "contexts/FullScreen";

const SlidePage = (props) => {
  return (
    <FullScreenProvider>
      <SlidePageLayout {...props} />
    </FullScreenProvider>
  );
};

const SlidePageLayout = (props) => {
  const { children } = props;
  // const handle = useFullScreenHandle();
  const { handle } = useContext(FullScreenContext);

  // const [left, setLeft] = useState(0);

  // const handleMenuOpen = () => {
  //   setLeft((prev) => (prev === "50px" ? 0 : "50px"));
  // };

  // const handleFullScreen = () => {
  //   if (handle.active) handle.exit();
  //   else handle.enter();
  //   setLeft(0);
  // };

  return (
    <Box className="body" sx={{ minWidth: "1200px", height: "100vh" }}>
      {/* <Header /> */}

      <Grid className="justify-content-center" container sx={{ height: "100vh", overflow: "auto" }}>
        <Grid
          item
          xs={12}
          sx={{ height: "100%", display: "flex", flexDirection: "column", p: "10px" }}
        >
          {/* <Title {...props} /> */}

          <FullScreen handle={handle}>
            <Box className="paper" sx={{ position: "relative", height: "100%" }}>
              {children.content}

              {/* <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: left,
                  transform: "translateX(-100%)",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  zIndex: 1,
                  transition: "all 0.5s ease-in-out",
                }}
              >
                <Box
                  sx={{
                    bgcolor: "#f5f5f5",
                    width: "50px",
                    py: "20px",
                    textAlign: "center",
                    borderRadius: "0px 10px 10px 0px",
                  }}
                >
                  <IconButton
                    sx={{
                      bgcolor: "#ddd",
                      boxShadow: "1px 2px 2px #aaa",
                      "&:hover": { bgcolor: "#ccc" },
                      "&:active": { boxShadow: "none", transform: "translateY(1px)" },
                    }}
                    onClick={handleFullScreen}
                  >
                    {handle.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
                  </IconButton>
                </Box>
              </Box> */}

              {/* <Box
                sx={{
                  position: "absolute",
                  left,
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  transition: "all 0.5s ease-in-out",
                }}
              >
                <Tooltip
                  title={"메뉴바 " + (left === "50px" ? "닫기" : "열기")}
                  arrow
                  placement="right"
                >
                  <IconButton
                    sx={{
                      bgcolor: "#aaaaaa30",
                      "&:hover": { bgcolor: "#aaaaaa88", "> svg": { opacity: 1 } },
                    }}
                    onClick={handleMenuOpen}
                  >
                    <ArrowForwardIosIcon
                      sx={{
                        fontSize: "25px",
                        transform: "translateX(10px)" + (left === "50px" ? " rotate(180deg)" : ""),
                        opacity: 0.25,
                        transition: "all 0.5s ease-in-out",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Box> */}
            </Box>
          </FullScreen>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SlidePage;
