import React from "react";

import { styled } from "@mui/material/styles";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  tableCellClasses,
  IconButton,
} from "@mui/material";

import ReceiptIcon from "@mui/icons-material/Receipt";

// dashboard의 billing history table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EBEBEB",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1rem",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

/** 테이블의 columns */
const columns = [
  { id: "method", label: "결제수단", minWidth: 170, format: (value) => value || "신용 / 체크카드" },
  {
    id: "approvedAt",
    label: "승인 날짜",
    minWidth: 100,
    format: (value) => value?.slice(0, 10),
  },
  {
    id: "description",
    label: "설명",
    minWidth: 170,
    // format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "totalAmount",
    label: "결제 금액",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("ko-KR") + " 원",
  },
  {
    id: "receipt",
    label: "영수증",
    align: "right",
    format: (value) => (
      <IconButton
        onClick={() => {
          if (!value) {
            alert("영수증이 없습니다.");
            return;
          }
          window.open(value);
        }}
      >
        <ReceiptIcon />
      </IconButton>
    ),
  },
];

function createData(cardname, date, description, amount) {
  return { cardname, date, description, amount };
}

// * 데이터 서버에서 받아와서 createData 해서 push

/** 결제 내역 테이블 */
export const BillingHistoryTable = ({ billings }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  /** 페이지 넘기기 */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /** 페이지에 보이는 줄 갯수 바꿈 */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 250 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableCell
                  key={index}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {billings.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {columns.map((column, index) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={index} align={column.align}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={billings.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
