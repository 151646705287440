import React, { useContext } from "react";

import * as S from "./style";
import { Context } from "views/PaymentPage/context";
import { Box, Typography } from "@mui/material";

const Coupon = (props) => {
  const { V, F } = useContext(Context);
  const { coupon } = props;
  const { couponName, discount, unlimited, discountApplication, discountType, expiredDate } =
    coupon.content;

  return (
    // <S.Coupon onClick={F.selectCoupon(discount * 1)}>
    <S.Coupon onClick={F.selectCoupon({ ...coupon.content, _id: coupon._id })}>
      <S.CouponContent>
        <S.CouponTitle>{couponName}</S.CouponTitle>

        <span style={{ font: "14px Pretendard", color: "#c33c3c" }}>
          {discountApplication === "once" && "첫 정기결제시에만"}
          {discountApplication === "every" && "매 정기결제마다"}
        </span>

        <Box sx={{ display: "flex", alignItems: "end", justifyContent: "space-between" }}>
          <S.CouponPrice>
            {discount.toLocaleString("ko-KR")}
            {discountType === "fixed" && "원 "}
            {discountType === "percentage" && "% "}할인
          </S.CouponPrice>

          {!unlimited && (
            <Typography sx={{ font: "12px Pretendard", color: "#aaa" }}>
              {expiredDate}까지
            </Typography>
          )}
        </Box>
      </S.CouponContent>

      <S.DownloadButton />
    </S.Coupon>
  );
};

export default Coupon;
