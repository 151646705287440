// * npm modules
import React, { useContext, useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { FullScreen } from "react-full-screen";
import { QRCode } from "react-qr-svg";

// * material-ui components
import { Box, IconButton, Typography, Menu, MenuItem, Tooltip, Button } from "@mui/material";

// * material-ui icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import TimerIcon from "@mui/icons-material/Timer";
import ReplayIcon from "@mui/icons-material/Replay";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";

// * 프로젝트 내 컴포넌트
import { ManagerContext } from "../contexts/ManagerContext";
import { FullScreenProvider, FullScreenContext } from "contexts/FullScreen";
import Title from "components/Title";
import { QRCodeWideModal, QnAModal, ResetModal } from "components/Modal";
import logo from "assets/images/wliveLogo.png";

// * 프로젝트 내 컴포넌트
import Intro from "./Intro";
import WordCloud from "./WordCloud";
import Choice from "./Choice";
import OpenEnded from "./OpenEnded";
import Outro from "./Outro";

// * 스타일 컴포넌트
import * as S from "./styles";

// * Live Polling Manager Page
export default () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />

      <Route
        path="/:slideId"
        element={
          <FullScreenProvider>
            <Layout />
          </FullScreenProvider>
        }
      />
    </Routes>
  );
};

// * 라이브 폴링 매니저 페이지 처음 접근 시
const Index = (props) => {
  const navigate = useNavigate(); // * 라우터 네비게이션
  const { gameId } = useParams(); // * 게임 ID
  const { socket } = useContext(ManagerContext); // * 매니저 컨텍스트

  // * 라우팅 이벤트
  useEffect(() => {
    if (!socket) return;

    // * 폴링 데이터 요청
    socket.emit("getPolling", { gameId }, (poll) => {
      const slideList = poll.playingData; // * 슬라이드 리스트
      const currentSlideIndex = poll.currentSlideIndex; // * 현재 슬라이드 인덱스

      navigate(`${slideList[currentSlideIndex].id}`, { replace: true }); // * 현재 슬라이드로 라우터 네비게이션
    });
  }, [socket]);

  return <></>;
};

// * 라이브 폴링 매니저 페이지
const Layout = () => {
  const { gameId } = useParams(); // * 게임 ID
  const ref = useRef(null);
  // * 매니저 컨텍스트
  const {
    socket,
    playingData,
    currentSlideIndex,
    isQrGridOpened,
    qrCodeURL,
    likes,
    isQnACreated,
    QnA,
    isQnaModalOpened,
    code,

    handleCurrentSlideIndex,
    handleQRCode,
    setQnACreated,
    setIsQnaModalOpened,
  } = useContext(ManagerContext);
  const { handle, handleFullScreen } = useContext(FullScreenContext); // * 전체화면 컨텍스트

  const [buttonBoxOpacity, setButtonBoxOpacity] = useState(0); // * 메뉴 박스 투명도
  const [menuItem, setMenuItem] = useState([]); // * 메뉴 아이템 리스트
  const [QRModalOpened, setQRModalOpened] = useState(false); // * QR코드 모달 오픈 여부
  const [notAnsweredQuestionCount, setNotAnsweredQuestionCount] = useState(0); // * 미응답 질문 수
  const [anchorEl, setAnchorEl] = useState(null); // * 메뉴 앵커
  const [timer, setTimer] = useState(null); // * 타이머
  const [isResetModalOpened, setResetModalOpened] = useState(false); // * 리셋 모달 오픈 여부

  // * 마우스 오버 이벤트
  const onMouseEnter = () => {
    setButtonBoxOpacity(1); // * 메뉴 박스 투명도 1로 설정
  };

  // * 마우스 리브 이벤트
  const onMouseLeave = () => {
    // * 0.3초 후 메뉴 박스 투명도 0으로 설정
    setTimeout(() => {
      setButtonBoxOpacity(0);
    }, 300);
  };

  // * QR코드 모달 핸들러
  const handleQRcodeModal = () => {
    setQRModalOpened(!QRModalOpened); // * QR코드 모달 오픈 여부 토글
  };

  // * Q&A 모달 핸들러
  const handleQnAModal = () => {
    setIsQnaModalOpened(!isQnaModalOpened); // * Q&A 모달 오픈 여부 토글
  };

  // * Q&A 아이콘 버튼 생성 핸들러
  const handleQnA = () => {
    setQnACreated(!isQnACreated); // * Q&A 아이콘 버튼 생성 여부 토글
  };

  // * 타이머 메뉴 핸들러
  const handleTimer = (e) => {
    if (!anchorEl) setAnchorEl(e.currentTarget); // * 메뉴 앵커 설정
    else {
      onMouseLeave(); // * 마우스 리브 이벤트
      setAnchorEl(null); // * 메뉴 앵커 null로 설정
    }
  };

  // * 타이머 핸들러
  const handleTime = (e) => {
    onMouseLeave(); // * 마우스 리브 이벤트

    // * 타이머 취소
    if (e.currentTarget.id === "cancel") {
      setTimer(null); // * 타이머 null로 설정
      setAnchorEl(null); // * 메뉴 앵커 null로 설정
      return;
    }

    // * 투표 제한 해제 소켓 아밴트
    socket.emit("unrestrictPolling", {
      gameId,
      slideId: playingData[currentSlideIndex].id,
    });

    setTimer((prev) => prev + e.currentTarget.id * 1); // * 타이머 설정
    setAnchorEl(null); // * 메뉴 앵커 null로 설정
  };

  // * 투표 제한 설정/해제 핸들러
  const handleRestrict = () => {
    // * 투표가 제한되어 있으면 투표 제한 해제 소켓 아밴트
    if (playingData[currentSlideIndex].restricted) {
      socket.emit(
        "unrestrictPolling",
        { gameId, slideId: playingData[currentSlideIndex].id },
        (res) => {
          if (res.success) {
            setTimer(null); // * 타이머 null로 설정
          }
        }
      );
      return;
    }

    // * 투표가 제한되어 있지 않으면 투표 제한 소켓 아밴트
    socket.emit(
      "restrictPolling",
      { gameId, slideId: playingData[currentSlideIndex].id },
      (res) => {
        if (res.success) {
          setTimer(null); // * 타이머 null로 설정
        }
      }
    );
  };

  // * 리셋 모달 핸들러
  const handleResetModal = () => {
    setResetModalOpened(!isResetModalOpened); // * 리셋 모달 오픈 여부 토글
  };

  // * 키보드 이벤트 핸들러
  const keydownHandler = (e) => {
    if (isQnaModalOpened) return; // * QnA 모달이 열려있으면 리턴
    if (e.altkey || e.ctrlKey || e.shiftKey || e.metaKey) return; // * 키보드 이벤트가 alt, ctrl, shift, command 키와 함께 눌렸을 때 리턴

    // * 키보드 이벤트에 따른 핸들러
    switch (e.key.toLowerCase()) {
      case "arrowleft":
        if (currentSlideIndex === 0) return; // * 첫 번째 슬라이드에서는 더 이상 뒤로 이동 불가

        handleCurrentSlideIndex({ currentTarget: { id: "prev" } }); // * 이전 슬라이드로 이동
        break;

      case "arrowright":
        if (currentSlideIndex === playingData.length - 1) return; // * 마지막 슬라이드에서는 더 이상 앞으로 이동 불가

        handleCurrentSlideIndex({ currentTarget: { id: "next" } }); // * 다음 슬라이드로 이동
        break;

      case "f":
      case "ㄹ":
        handleFullScreen(); // * 전체화면 토글
        break;

      case "q":
      case "ㅂ":
        if (
          playingData[currentSlideIndex].type === "Intro" ||
          playingData[currentSlideIndex].type === "Outro"
        )
          return;

        handleQRCode(); // * QR코드 토글
        break;

      case "t":
      case "ㅅ":
        if (
          playingData[currentSlideIndex].type === "Intro" ||
          playingData[currentSlideIndex].type === "Outro"
        )
          return;

        handleTimer({ currentTarget: ref.current }); // * 타이머 메뉴 토글
        break;

      case "r":
      case "ㄱ":
        if (
          playingData[currentSlideIndex].type === "Intro" ||
          playingData[currentSlideIndex].type === "Outro"
        )
          return;

        handleResetModal(); // * 리셋 모달 토글
        break;

      case "a":
      case "ㅁ":
        handleQnA(); // * Q&A 아이콘 버튼 생성 토글
        break;

      case "d":
      case "ㅇ":
        if (
          playingData[currentSlideIndex].type === "Intro" ||
          playingData[currentSlideIndex].type === "Outro"
        )
          return;

        handleRestrict(); // * 투표 제한 설정/해제
        break;

      default:
        break;
    }
  };

  // * 타이머 핸들러
  useEffect(() => {
    if (timer === null) return; // * 타이머가 null이면 리턴

    // * 1초마다 타이머 감소
    const interval = setInterval(() => {
      // * 타이머가 0이면 폴링 제한 소켓 아밴트
      if (timer <= 0) {
        socket.emit(
          "restrictPolling",
          { gameId, slideId: playingData[currentSlideIndex].id },
          (res) => {
            if (res.success) {
              setTimer(null); // * 타이머 null로 설정
            }
          }
        );

        clearInterval(interval); // * 인터벌 해제
        return;
      }

      // * 타이머 1초 감소
      setTimer((prev) => {
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval); // * 컴포넌트 언마운트 시 인터벌 해제
  }, [timer]);

  // * 메뉴 아이템 설정
  useEffect(() => {
    if (playingData.length === 0) return; // * 플레이 중인 데이터가 없으면 리턴

    // * 메뉴 아이템 설정
    setMenuItem([
      {
        Icon: handle.active ? <FullscreenExitIcon /> : <FullscreenIcon />,
        name: handle.active ? "전체화면 나가기" : "전체화면",
        action: handleFullScreen,
        key: "F",
      },
      {
        Icon: <QrCode2Icon />,
        name: isQrGridOpened ? "QR코드 닫기" : "QR코드",
        action: handleQRCode,
        disabled:
          playingData[currentSlideIndex].type === "Intro" ||
          playingData[currentSlideIndex].type === "Outro",
        key: "Q",
      },
      {
        Icon: <TimerIcon />,
        name: "타이머",
        disabled:
          playingData[currentSlideIndex].type === "Intro" ||
          playingData[currentSlideIndex].type === "Outro",
        action: handleTimer,
        key: "T",
      },
      {
        Icon: <ReplayIcon />,
        name: "리셋",
        disabled:
          playingData[currentSlideIndex].type === "Intro" ||
          playingData[currentSlideIndex].type === "Outro",
        action: handleResetModal,
        key: "R",
      },
      {
        Icon: <NotInterestedIcon />,
        name: playingData[currentSlideIndex].restricted ? "투표 제한 해제" : "투표 막기",
        disabled:
          playingData[currentSlideIndex].type === "Intro" ||
          playingData[currentSlideIndex].type === "Outro",
        action: handleRestrict,
        key: "D",
      },
      {
        Icon: <QuestionAnswerIcon />,
        name: isQnACreated ? "Q&A 닫기" : "Q&A 생성",
        action: handleQnA,
        key: "A",
      },
    ]);
  }, [playingData, currentSlideIndex, isQrGridOpened, handle, isQnACreated]);

  // * 미응답 질문 수 설정
  useEffect(() => {
    let notAnsweredQuestionCount = QnA.filter((item) => !item.completed).length; // * 미응답 질문 수

    setNotAnsweredQuestionCount(notAnsweredQuestionCount); // * 미응답 질문 수 설정
  }, [QnA]);

  // useEffect(() => {
  //   document.addEventListener("keydown", keydownHandler); // * 키보드 이벤트 리스너 추가

  //   return () => {
  //     document.removeEventListener("keydown", keydownHandler); // * 컴포넌트 언마운트 시 키보드 이벤트 리스너 제거
  //   };
  // });

  return (
    // * 레이아웃
    <S.Body>
      <S.Content>
        <Title children={{ name: "Live Polling" }} />

        <FullScreen handle={handle} style={{ flex: 1, flexShrink: 0 }}>
          <S.Slide>
            <S.SlideGrid>
              <S.MainBox is-qr-grid-opened={isQrGridOpened.toString()} label="메인 슬라이드 박스">
                {playingData.length !== 0 &&
                  (() => {
                    switch (
                      playingData[currentSlideIndex].type // * 현재 슬라이드 타입에 따라 렌더링
                    ) {
                      case "Intro":
                        return <Intro />; // * Intro 컴포넌트
                      case "WordCloud":
                        return <WordCloud />; // * WordCloud 컴포넌트
                      case "Choice":
                        return <Choice />; // * Choice 컴포넌트
                      case "OpenEnded":
                        return <OpenEnded />; // * OpenEnded 컴포넌트
                      case "Outro":
                        return <Outro />; // * Outro 컴포넌트
                      default:
                        return <></>; // * 기본값
                    }
                  })()}
              </S.MainBox>

              {/* QR 코드  */}
              <S.QrArea>
                <S.ToolTip title="QR코드 크게 보기" placement="top">
                  <S.QrBox onClick={handleQRcodeModal}>
                    <QRCode value={qrCodeURL} />
                  </S.QrBox>
                </S.ToolTip>

                <Box>
                  <S.AccessCodeTitle>접속 코드</S.AccessCodeTitle>
                  <S.AccessCode>{code.slice(0, 3) + " " + code.slice(3)}</S.AccessCode>
                </Box>
              </S.QrArea>
            </S.SlideGrid>

            {/* 슬라이드 컨트롤러 및 메뉴*/}
            <S.ActionBox onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave}>
              {/* 슬라이드 네비게이션 */}
              <S.NavigationBox>
                <S.ToolTip
                  title={
                    currentSlideIndex === 0 ? (
                      ""
                    ) : (
                      <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        슬라이드 뒤로
                        <button className="kbc-button kbc-button-xxs disabled">
                          <ArrowBackIcon sx={{ fontSize: "14px" }} />
                        </button>
                      </span>
                    )
                  }
                  placement="top"
                >
                  <IconButton
                    disabled={currentSlideIndex === 0} // * 현재 슬라이드 인덱스가 0이면 비활성화
                    id="prev"
                    onClick={handleCurrentSlideIndex} // * 현재 슬라이드 인덱스 변경 핸들러
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </S.ToolTip>

                <Typography fontWeight={600}>
                  {currentSlideIndex + 1 + " / " + playingData.length}
                </Typography>

                <S.ToolTip
                  title={
                    currentSlideIndex === playingData.length - 1 ? (
                      ""
                    ) : (
                      <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        슬라이드 앞으로
                        <button className="kbc-button kbc-button-xxs disabled">
                          <ArrowForwardIcon sx={{ fontSize: "14px" }} />
                        </button>
                      </span>
                    )
                  }
                  placement="top"
                >
                  <IconButton
                    id="next"
                    disabled={currentSlideIndex === playingData.length - 1}
                    onClick={handleCurrentSlideIndex}
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </S.ToolTip>
              </S.NavigationBox>

              {/* 슬라이드 메뉴  */}
              <S.SlideButtonBox data-opacity={buttonBoxOpacity}>
                {/* 아이콘 버튼 */}
                {menuItem.map((item, index) => {
                  return (
                    <S.ToolTip
                      title={
                        item.disabled ? (
                          ""
                        ) : (
                          <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            {item.name}
                            <button className="kbc-button kbc-button-xxs disabled">
                              {item.key}
                            </button>
                          </span>
                        )
                      }
                      key={index}
                      placement="top"
                    >
                      <IconButton
                        sx={{ p: "5px", color: "#000", "&:hover": { bgcolor: "#ccc" } }}
                        onClick={item.action}
                        disabled={item.disabled}
                        ref={item.name === "타이머" ? ref : null}
                      >
                        <Icon item={item} />
                      </IconButton>
                    </S.ToolTip>
                  );
                })}

                {/* 타이머 메뉴 */}
                <Menu
                  anchorEl={anchorEl}
                  open={anchorEl !== null}
                  onClose={() => setAnchorEl(null)}
                  disablePortal
                  anchorOrigin={{ vertical: -160, horizontal: -20 }}
                >
                  {[
                    { value: "60", name: "1분" },
                    { value: "30", name: "30초" },
                    { value: "10", name: "10초" },
                    { value: "cancel", name: "타이머 취소" },
                  ].map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        id={item.value}
                        onClick={handleTime}
                        sx={{ font: "600 16px Pretendard", mb: "5px" }}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </S.SlideButtonBox>
            </S.ActionBox>

            {/* 좋아요 및 Q&A */}
            <S.ReactionBox>
              {/* 좋아요 */}
              <S.ToolTip title="좋아요">
                <S.ReactionButton>
                  <S.ReactionBadge badgeContent={likes.length} color="primary">
                    <ThumbUpAltIcon />
                  </S.ReactionBadge>
                </S.ReactionButton>
              </S.ToolTip>

              {/* Q&A */}
              {isQnACreated && (
                <Tooltip title="Q&A" arrow PopperProps={{ disablePortal: true }}>
                  <S.ReactionButton onClick={handleQnAModal}>
                    <S.ReactionBadge badgeContent={notAnsweredQuestionCount} color="primary">
                      <QuestionAnswerIcon />
                    </S.ReactionBadge>
                  </S.ReactionButton>
                </Tooltip>
              )}
            </S.ReactionBox>

            {/* 타이머 */}
            {(timer !== null || playingData[currentSlideIndex]?.restricted) && (
              <S.TimerBox>
                {timer !== null && (
                  <Typography sx={{ font: "600 24px Pretendard", textAlign: "center" }}>
                    제출까지 남은 시간 <br />
                    {("00" + Math.floor(timer / 60)).slice(-2)} :
                    {("00" + Math.floor(timer % 60)).slice(-2)}
                  </Typography>
                )}

                {playingData[currentSlideIndex]?.restricted && (
                  <Box sx={{ textAlign: "center" }}>
                    <Typography sx={{ font: "600 24px Pretendard", mb: "10px" }}>
                      폴링 투표가 제한되었습니다.
                    </Typography>

                    <Button
                      variant="contained"
                      sx={{ fontWeight: "600", fontFamily: "Pretendard", fontSize: "16px" }}
                      onClick={() => {
                        socket.emit("unrestrictPolling", {
                          gameId,
                          slideId: playingData[currentSlideIndex].id,
                        });
                      }}
                    >
                      투표 제한 해제하기
                    </Button>
                  </Box>
                )}
              </S.TimerBox>
            )}

            {/* 우측 상단 로고 */}
            {playingData[currentSlideIndex]?.type !== "Outro" && (
              <S.SlideLogo src={logo} alt="logo" />
            )}
          </S.Slide>

          {/* QR코드 모달 */}
          <QRCodeWideModal
            open={QRModalOpened}
            handleModal={handleQRcodeModal}
            ModalQRCode={qrCodeURL}
          />

          {/* Q&A 모달 */}
          <QnAModal open={isQnaModalOpened} onClose={handleQnAModal} list={QnA} />

          {/* 리셋 모달 */}
          <ResetModal open={isResetModalOpened} onClose={handleResetModal} />
        </FullScreen>
      </S.Content>
    </S.Body>
  );
};

// * 아이콘 컴포넌트
const Icon = ({ item }) => {
  const style = {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return <Box sx={style}>{item.Icon}</Box>;
};
