import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

import { Box, Typography, Grid, Button, Checkbox, Select, MenuItem, Divider } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DragHandleIcon from "@mui/icons-material/DragHandle";

import { CouponModal } from "./../components/Modal";
import { Textfield as TextField } from "./../components/Textfield";

// * 결제 페이지
const PayPage = () => {
  const { type } = useParams();
  const { state } = useLocation();
  const [cookies] = useCookies();
  const today = new Date();

  const [payBy, setPayBy] = useState(1);
  const [productPrice, setProductPrice] = useState(49500);
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(productPrice - discount);
  const [modalOpened, setModalOpened] = useState(false);

  // * 결제 방식 change event
  const handlePayBy = (e) => {
    setPayBy(e.target.value);
  };
  // * 쿠폰 Modal open/close
  const handleModal = () => {
    setModalOpened(!modalOpened);
  };
  // * 할인 가격 Change event
  const handleDiscount = (discount) => {
    setDiscount(-discount);
  };
  // * 플랜 업그레이드
  const upgradePlan = () => {
    if (!cookies.auth) {
      alert("로그인이 만료되어 결제에 실패했습니다.");
      window.location.reload();
    }
    if (!totalPrice) {
      const request = axios
        .post("/account/upgradePlan", null, {
          params: { type: type, token: cookies.auth },
        })
        .then((response) => {
          const data = response.data;

          if (data.success) {
            alert("구독 플랜 업그레이드가 완료되었습니다.");
            window.location.href = "/dashboard";
          }
        });
      // console.log(request);
    }
  };
  // * 최종금액 계산
  useEffect(() => {
    setTotalPrice(productPrice - discount);
    // eslint-disable-next-line
  }, [discount]);
  return (
    <Box sx={{ background: "rgb(246, 246, 246)", height: "100vh", pb: "50px", pt: "50px" }}>
      {/* Header */}

      {/* Body */}
      <Grid container sx={{ display: "flex", justifyContent: "center", pt: "50px" }}>
        {/* Title */}
        <Grid item xs={10} sx={{ height: "100px" }}>
          <Typography sx={{ fontSize: "30px", textAlign: "center" }}>상품 결제</Typography>
        </Grid>
        {/* Content */}
        <Grid item xs={9} sx={{ minWidth: "800px" }}>
          <Grid container>
            {/* Left - 정보 입력 */}
            <Grid item xs={8}>
              <Box
                sx={{
                  background: "white",
                  mx: "10px",
                  borderRadius: "8px",
                  px: 3,
                  py: 2,
                  border: 1,
                  borderColor: "#00000020",
                  height: "auto",
                }}
              >
                <Box>
                  <Typography>* 필수항목</Typography>
                </Box>

                <Box sx={{ py: 3 }}>
                  {/* 소속 Container */}
                  <Grid container sx={{ display: "flex", alignItems: "center", my: 2 }}>
                    <Grid item xs={2}>
                      <Typography sx={{ fontSize: "20px" }}>소속</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField fullWidth size="small" />
                    </Grid>
                  </Grid>
                  {/* 주소 Container */}
                  <Grid container sx={{ display: "flex", alignItems: "center", my: 2 }}>
                    <Grid item xs={2}>
                      <Typography sx={{ fontSize: "20px" }}>주소</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <Box sx={{ display: "flex", mb: 1 }}>
                        <TextField size="small" />
                        <Button
                          variant="contained"
                          sx={{
                            mx: 1,
                            background: "#555555",
                            "&:hover": { background: "#444444" },
                            fontSize: "16px",
                          }}
                        >
                          우편번호 검색
                        </Button>
                      </Box>
                      <TextField fullWidth size="small" />
                    </Grid>
                  </Grid>

                  {/* 이름 Container */}
                  <Grid container sx={{ display: "flex", alignItems: "center", my: 2 }}>
                    <Grid item xs={2}>
                      <Typography sx={{ fontSize: "20px" }}>이름</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField fullWidth size="small" />
                    </Grid>
                  </Grid>

                  {/* 연락처 Container */}
                  <Grid container sx={{ display: "flex", alignItems: "center", my: 2 }}>
                    <Grid item xs={2}>
                      <Typography sx={{ fontSize: "20px" }}>연락처</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="-없이 입력 (ex: 01012345678)"
                      />
                    </Grid>
                  </Grid>

                  {/* 체크박스 Container */}
                  <Grid container sx={{ display: "flex", justifyContent: "center", pt: 2 }}>
                    <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox />
                      <Typography>19세 이상 가입자입니다.</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox />
                      <Typography>
                        본인의 개인 정보를 결제 서비스 업체에 제공하는데 동의합니다.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            {/* Right - 결제 정보 */}
            <Grid item xs={4}>
              {/* 결제 정보 Container */}
              <Box
                sx={{
                  minWidth: "400px",
                  background: "white",
                  mx: "10px",
                  borderRadius: "8px",
                  pl: 3,
                  py: 2,
                  mb: 1,
                  border: 1,
                  borderColor: "#00000020",
                  boxSizing: "border-box",
                }}
              >
                <Grid container rowSpacing={2}>
                  <Grid
                    item
                    xs={11}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ color: "#888888" }}>상품명</Typography>
                    <Typography sx={{ fontSize: "28px" }}>{"PRO"}</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={11}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ color: "#888888" }}>결제일</Typography>
                    <Typography sx={{ fontSize: "18px", color: "#eb7e5b" }}>
                      매월 {today.getDate()}일에 결제됩니다.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={11}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ color: "#888888" }}>결제수단</Typography>
                    <Select
                      size="small"
                      value={payBy}
                      onChange={handlePayBy}
                      MenuProps={{ disableScrollLock: true }}
                      sx={{ backgroundColor: "#f5f5f5" }}
                    >
                      <MenuItem value={1}>신용카드 (국내)</MenuItem>
                      <MenuItem value={2}>VISA/MASTER/JCB(해외)</MenuItem>
                      <MenuItem value={3}>AMEX(해외)</MenuItem>
                      <MenuItem value={4}>PayPal</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              </Box>
              {/* 쿠폰 Container */}
              <Box
                onClick={handleModal}
                sx={{
                  minWidth: "400px",
                  background: "white",
                  mx: "10px",
                  borderRadius: "8px",
                  px: 3,
                  py: 2,
                  my: 1,
                  border: 1,
                  borderColor: "#00000020",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  "&:hover": { background: "#f5f5f5" },
                }}
              >
                <AddIcon />
                <Typography sx={{ fontSize: "18px" }}> 나의 쿠폰함</Typography>
              </Box>

              {/* 최종 결제 정보 Container */}
              <Box
                sx={{
                  minWidth: "400px",
                  background: "white",
                  mx: "10px",
                  borderRadius: "8px",
                  px: 3,
                  py: 2,
                  my: 1,
                  border: 1,
                  borderColor: "#00000020",
                  boxSizing: "border-box",
                }}
              >
                <Box sx={{ borderBottom: 1, borderColor: "#cdcdcd", mb: 2 }}>
                  <Typography sx={{ fontSize: "20px" }}>최종 결제 정보</Typography>
                </Box>
                <Grid container rowSpacing={1}>
                  <Grid item xs={6}>
                    <Typography>결제상품금액</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography sx={{ fontSize: "22px" }}>
                        {productPrice.toLocaleString("ko-KR")}
                      </Typography>
                      <Typography>원</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>할인</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          background: "#c0c0c0",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          width: "24px",
                        }}
                      >
                        <RemoveIcon sx={{ color: "#ffffff" }} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography sx={{ fontSize: "22px" }}>
                        {discount.toLocaleString("ko-KR")}
                      </Typography>
                      <Typography>원</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography>최종 결제 금액</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        sx={{
                          background: "#c0c0c0",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          width: "24px",
                        }}
                      >
                        <DragHandleIcon sx={{ color: "#ffffff" }} />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography sx={{ fontSize: "22px" }}>
                        {totalPrice.toLocaleString("ko-KR")}
                      </Typography>
                      <Typography>원</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Divider />
                <Box sx={{ pt: 1 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      fontSize: "18px",
                      background: "#ababab",
                      "&:hover": { background: "#9a9a9a" },
                    }}
                    onClick={upgradePlan}
                  >
                    결제하기
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* 쿠폰 Modal */}
      <CouponModal open={modalOpened} handleModal={handleModal} handleDiscount={handleDiscount} />
    </Box>
  );
};

export default PayPage;
