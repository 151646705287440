import React from "react";
import { useMediaQuery } from "react-responsive";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import background from "assets/images/ContentPage/LiveDeal/background.png";
import characterImg from "assets/images/ContentPage/LiveDeal/characterImg.png";
import structureImg from "assets/images/ContentPage/LiveDeal/structureImg.png";

import * as S from "./styles";
import { benefitData, connectionScreenData } from "./data";
import { Footer } from "components/Footer";

const manual = "https://withplus-live.notion.site/Live-Deal-27a8fdc57d9341cc918b36aafc936053";

const Index = (props) => {
  const isMobile = useMediaQuery({
    query: "(max-width:1000px)",
  });

  if (isMobile) {
    return (
      <S.Body>
        <S.container disableGutters maxWidth="xs">
          <Intro isMobile />

          <Benefit isMobile />

          <LearningStructure isMobile />

          <ConnectionScreen isMobile />
        </S.container>

        <Footer />
      </S.Body>
    );
  }

  return (
    <S.Body>
      <Intro />

      <Benefit />

      <LearningStructure />

      <ConnectionScreen />

      <Footer />
    </S.Body>
  );
};

const Intro = ({ isMobile }) => {
  const navigate = useNavigate();

  const goToDashBoard = () => {
    if (/Android|iPhone/i.test(navigator.userAgent)) {
      alert("진행자 기능 사용은 PC(노트북)에서 진행해 주세요!");
    } else {
      setTimeout(() => {
        navigate("/dashboard");
      }, 300);
    }
  };

  return (
    <S.Intro sx={{ backgroundImage: `url(${background})` }}>
      <S.IntroTitle>Live Deal</S.IntroTitle>

      <S.IntroExplain1>
        전략적 의사결정을 위한 최적의 선택과 {isMobile && <br />}분석력 함양!
      </S.IntroExplain1>

      <S.IntroExplain2>
        18장의 카드를 두 장씩 선택하여 제출하는 과정에서
        <br />
        협상을 통해 변화에 대응하는 {isMobile && <br />}의사결정 훈련 게임입니다.
      </S.IntroExplain2>

      <S.IntroButton onClick={goToDashBoard}>체험하기</S.IntroButton>

      {<S.IntroCharacter src={characterImg} alt="character" />}
    </S.Intro>
  );
};

const Benefit = ({ isMobile }) => {
  return (
    <S.EffectContainer>
      <S.LiveDealBox>Live Deal</S.LiveDealBox>

      <S.Title>기대 효과</S.Title>

      <S.Explain>
        변화하는 규칙에 적응하여 최적의 의사결정을 내리기 위해 {isMobile && <br />}소통하고 협상해라
      </S.Explain>

      <S.EffectBox>
        {isMobile ? (
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            pagination={{ clickable: true }}
            navigation
          >
            {benefitData.map((data, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <S.EffectItem>
                    <S.EffectItemIcon src={data.img} alt="icon1" />

                    <S.EffectItemTitle>{data.title}</S.EffectItemTitle>

                    <S.EffectItemDescription>
                      {data.description[0]} <br /> {data.description[1]} &nbsp;
                    </S.EffectItemDescription>
                  </S.EffectItem>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          benefitData.map((data, idx) => {
            return (
              <S.EffectItem key={idx}>
                <S.EffectItemIcon src={data.img} alt="icon1" />

                <S.EffectItemTitle>{data.title}</S.EffectItemTitle>

                <S.EffectItemDescription>
                  {data.description[0]} <br /> {data.description[1]} &nbsp;
                </S.EffectItemDescription>
              </S.EffectItem>
            );
          })
        )}
      </S.EffectBox>
    </S.EffectContainer>
  );
};

const LearningStructure = ({ isMobile }) => {
  return (
    <S.LearningStructureContainer>
      <S.LiveDealBox>Live Deal</S.LiveDealBox>

      <S.Title>학습구조</S.Title>

      <S.Explain>진행자의 화면은 Game Screen, 참여자의 화면은 Controller</S.Explain>

      <S.LearningStructureBox>
        <img src={structureImg} />

        <S.LearningStructureExplain>
          <S.LearningStructureItem>
            <S.LearningStructureTitle>
              진행자 <span>Game Board Screen</span>
            </S.LearningStructureTitle>

            <S.LearningStructureDescription>
              PC, 노트북에서 라이브 픽 게임 생성
            </S.LearningStructureDescription>
          </S.LearningStructureItem>

          <S.LearningStructureItem>
            <S.LearningStructureTitle>
              참여자 <span>Controller</span>
            </S.LearningStructureTitle>

            <S.LearningStructureDescription>
              모바일, 태블릿PC, PC를 이용하여 <br />
              공유 받은 QR코드 또는 URL주소로 접속하여 플레이
            </S.LearningStructureDescription>
          </S.LearningStructureItem>
        </S.LearningStructureExplain>
      </S.LearningStructureBox>
    </S.LearningStructureContainer>
  );
};

const ConnectionScreen = ({ isMobile }) => {
  return (
    <S.ConnectionScreenContainer>
      {connectionScreenData.map((data, idx) => {
        return (
          <S.ConnectionScreenBox key={idx} id={data.id}>
            <S.LiveDealBox>Live Deal</S.LiveDealBox>

            <S.ConnectionScreenTitle>{data.title}</S.ConnectionScreenTitle>

            <S.ConnectionScreenExplain>
              {data.explain[0]} <br /> {isMobile && <br />} {data.explain[1]}
            </S.ConnectionScreenExplain>

            <S.ConnectionScreenImgBox id={data.id}>
              <img src={data.img} alt={data.id} />
            </S.ConnectionScreenImgBox>

            <S.ConnectionScreenExplainsContainer target={(isMobile || false).toString()}>
              <S.ConnectionScreenExplainsBox>
                {data.explains.map((explain, idx) => {
                  return (
                    <S.ConnectionScreenExplainItem
                      key={idx}
                      id={explain.id + ""}
                      target={(isMobile || false).toString()}
                    >
                      <S.ConnectionScreenExplainItemTitleBox>
                        <S.GreenCircle>{explain.id}</S.GreenCircle>

                        <S.ConnectionScreenExplainItemTitle>
                          {explain.title}
                        </S.ConnectionScreenExplainItemTitle>
                      </S.ConnectionScreenExplainItemTitleBox>

                      <S.ConnectionScreenExplainItemDescription>
                        {explain.description[0]} <br /> {explain.description[1]}
                        <br />
                        {explain.description[2]}
                      </S.ConnectionScreenExplainItemDescription>
                    </S.ConnectionScreenExplainItem>
                  );
                })}
              </S.ConnectionScreenExplainsBox>
            </S.ConnectionScreenExplainsContainer>

            <a href={manual} target="_blank" rel="noreferrner">
              <S.ConnectionScreenButton target={(isMobile || false).toString()}>
                메뉴얼 보러가기
              </S.ConnectionScreenButton>
            </a>
          </S.ConnectionScreenBox>
        );
      })}
    </S.ConnectionScreenContainer>
  );
};

export default Index;
