import { useEffect, useState } from "react";
import S from "../style.module.css";

const FourthPage = ({ resultObjectArray, userData, firstTypeArray, secondTypeArray }) => {
  const [userTypeSplitFirst, setUserTypeSplitFirst] = useState("");
  const [userTypeSplitSecond, setUserTypeSplitSecond] = useState("");

  const getNumByName = (name) => {
    const obj = resultObjectArray.find((item) => item.name === name);
    return obj ? obj.num : "";
  };
  console.log(resultObjectArray);

  useEffect(() => {
    if (resultObjectArray[5]?.name) {
      var name = resultObjectArray[5]?.name.split("(")[0];
      setUserTypeSplitFirst(name);
      var name = resultObjectArray[4]?.name.split("(")[0];
      setUserTypeSplitSecond(name);
    }
  }, [resultObjectArray]);
  return (
    <div className={S.Page}>
      <div className={S.PageHeader}>HEXA GRAM Personality Profile</div>
      <div className={S.PageTitle}>
        <div className={S.TitlePart}>Part lll</div>
        <p className={S.TitlePartName}>{userData.name} 님은 어떤 타입의 사람인가?</p>
      </div>
      <div className={S.FourthTable}>
        <table>
          <thead>
            <tr>
              <th>구분</th>
              <th>유희형(PL)</th>
              <th>신념형(PE)</th>
              <th>상상형(DR)</th>
              <th>분석형(AN)</th>
              <th>행동형(AC)</th>
              <th>감성형(EM)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>자가진단 결과</td>
              <td>{getNumByName("유희형(PL)")}</td>
              <td>{getNumByName("신념형(PE)")}</td>
              <td>{getNumByName("상상형(DR)")}</td>
              <td>{getNumByName("분석형(AN)")}</td>
              <td>{getNumByName("행동형(AC)")}</td>
              <td>{getNumByName("감성형(EM)")}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={S.FourthContentsFirstTitle}>
        <div>
          <span style={{ fontSize: "24px", fontWeight: "bold" }}>
            01.
            <span
              style={{
                background: "linear-gradient(to top, rgba(245,194,66,0.5) 40%, transparent 40%)",
              }}
            >
              {userTypeSplitFirst}
            </span>
          </span>
          <br />
          {firstTypeArray[1]?.typeWord}
        </div>
      </div>
      <div className={S.FourthContentsFirstContents}>
        <p>
          <span style={{ color: "#f5c246", fontSize: "18px", fontWeight: "bold" }}>Key-Word: </span>
          {firstTypeArray[1]?.keyWord}
        </p>
        <p>{firstTypeArray[1]?.typeDescription}</p>
      </div>
      <div className={S.FourthContentsSecondTitle}>
        <div>
          <span style={{ fontSize: "24px", fontWeight: "bold" }}>
            02.
            <span
              style={{
                background: "linear-gradient(to top, rgba(245,194,66,0.5) 40%, transparent 40%)",
              }}
            >
              {userTypeSplitSecond}
            </span>
          </span>
          <br />
          {secondTypeArray[1]?.typeWord}
        </div>
      </div>
      <div className={S.FourthContentsFirstContents}>
        <p>
          <span style={{ color: "#f5c246", fontSize: "18px", fontWeight: "bold" }}>Key-Word: </span>
          {secondTypeArray[1]?.keyWord}
        </p>
        <p>{secondTypeArray[1]?.typeDescription}</p>
      </div>
      <div className={S.PageFooter}>
      <p>All right reserved by Withplus Inc. copyright</p>
      </div>
    </div>
  );
};

export default FourthPage;
