import S from "../style.module.css";
import MainImg from "../../../../../src/assets/images/HexagramMainImg.png"

const FirstPage = ({ userData }) => {
  return (
    <div className={S.Page}>
      <div className={S.PageHeader}>HEXA GRAM Personality Profile</div>
      <div className={S.LineTop}></div>
      <div className={S.FirstPageTitle}>
        <p className={S.TitleText}>
          HEXA GRAM
          <br />
          Personality Profile
        </p>
      </div>
      <div className={S.LineBottom}></div>
      <div className={S.FirstPageImage}>
        <img src={MainImg} alt="MainImg" />
      </div>
      <div className={S.FirstPageUser}>
        <table>
          <tbody>
            <tr>
              <th>소속</th>
              <td>{userData.affiliation}</td>
            </tr>
            <tr>
              <th>이름</th>
              <td>{userData.name}</td>
            </tr>
            <tr>
              <th>검사일</th>
              <td>{userData.date}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div></div>
      <div className={S.PageFooter}>
      <p>All right reserved by Withplus Inc. copyright</p>
      </div>
    </div>
  );
};

export default FirstPage;
