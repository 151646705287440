// * npm modules
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// * material UI components
import { Box, Radio, Typography } from "@mui/material";

// * local components
import { UserContext } from "../contexts/UserContext";
import { Textfield } from "components/Textfield";
import { SnackBarContext } from "components/SnackBar/ContextAPI";

// * styles
import * as S from "./styles";

// * Choice component
export default () => {
  const { gameId, slideId } = useParams(); // * gameId, slideId from URL
  const { socket, playingData, currentSlideIndex, setPlayingData } = useContext(UserContext); // * 소켓, 플레이 데이터, 현재 슬라이드 인덱스
  const { setOpen, setText, setSeverity } = useContext(SnackBarContext); // * SnackBar Context

  const [currentSlide, setcurrentSlide] = useState(null); // * 현재 슬라이드
  const [duplicateSubmission, setDuplicateSubmission] = useState(false); // * 중복 제출 가능 여부
  const [selectedOptions, setSelectedOptions] = useState([]); // * 선택된 옵션
  const [etcOpinion, setEtcOpinion] = useState(""); // * 기타 의견
  const [loading, setLoading] = useState(false); // * 제출 로딩

  // * 선택지 핸들러
  const handleOption = (e) => {
    // * 중복 제출 가능 여부에 따라 선택지 핸들링
    if (duplicateSubmission) {
      // * 선택지가 이미 선택되어있으면 제거, 아니면 추가
      if (selectedOptions.includes(e.currentTarget.id)) {
        setSelectedOptions(selectedOptions.filter((option) => option !== e.currentTarget.id));
      } else {
        setSelectedOptions([...selectedOptions, e.currentTarget.id]);
      }
    } else {
      // * 선택지가 이미 선택되어있으면 제거, 아니면 선택
      if (selectedOptions.includes(e.currentTarget.id)) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions([e.currentTarget.id]);
      }
    }
  };

  // * 선택지 제출 핸들러
  const submitChoice = () => {
    // * 선택지가 선택되지 않았을 때
    if (!selectedOptions.length) {
      setText("선택지를 선택해주세요.");
      setSeverity("warning");
      setOpen(true);

      return;
    }

    // * 기타의견이 선택되었지만 입력되지 않았을 때
    if (selectedOptions.includes("etc") && !etcOpinion) {
      setText("기타의견을 입력해주세요.");
      setSeverity("warning");
      setOpen(true);

      return;
    }

    setLoading(true); // * 로딩 시작
    const uuid = localStorage.getItem("uuid"); // * 유저 아이디

    // * 선택지 제출
    socket.emit(
      "submitChoice",
      { gameId, slideId, userId: uuid, selectedOptions, etcOpinion }, // * 게임 아이디, 슬라이드 아이디, 유저 아이디, 선택지, 기타의견
      (res) => {
        setLoading(false); // * 로딩 종료

        // * 제출 결과에 따른 알림
        if (res.success) {
          setSelectedOptions([]); // * 선택지 초기화
          setEtcOpinion(""); // * 기타의견 초기화
          setText("선택이 제출되었습니다."); // * 알림 텍스트
          setSeverity("success"); // * 알림 타입
          setOpen(true); // * 알림 오픈
          setPlayingData(res.poll.playingData); // * 플레이 데이터 설정
        } else {
          setText(res.message); // * 알림 텍스트
          setSeverity("error"); // * 알림 타입
          setOpen(true); // * 알림 오픈
        }
      }
    );
  };

  // * 현재 슬라이드 설정
  useEffect(() => {
    if (!playingData) return; // * 플레이 데이터가 없거나 현재 슬라이드 인덱스가 없을 때 리턴

    const currentSlide = playingData[currentSlideIndex]; // * 현재 슬라이드

    setcurrentSlide(currentSlide); // * 현재 슬라이드 설정
    setDuplicateSubmission(currentSlide.duplicateSubmission); // * 중복 제출 가능 여부 설정
  }, [playingData, currentSlideIndex]);

  // * 슬라이드 변경 시 선택지 초기화
  useEffect(() => {
    setSelectedOptions([]); // * 선택지 초기화
    setEtcOpinion(""); // * 기타의견 초기화
  }, [slideId]);

  return (
    <S.ChoiceBody>
      <Typography sx={{ font: "700 20px Pretendard", mb: "20px" }}>
        {"선택형 "}

        {(() => {
          // * 그래프 레이아웃에 따른 텍스트
          switch (currentSlide?.resultLayout) {
            case "bar":
              return "(막대 그래프)";
            case "circle":
              return "(원형 그래프)";
            case "doughnut":
              return "(도넛 그래프)";
            default:
              return "";
          }
        })()}
      </Typography>

      {
        // * 투표 제한 되어있을 때 제한 박스 생성
        currentSlide?.restricted && (
          <S.ChoiceRestrictedBox>
            <Typography sx={{ font: "600 18px Pretendard", mb: "10px" }}>
              투표가 종료되었습니다.
            </Typography>

            <Typography sx={{ font: "500 14px Pretendard", color: "#888", wordBreak: "keep-all" }}>
              진행자가 다음 슬라이드로 넘어가기 전까지 대기하거나, 진행자에게 투표 제한 해제를
              요청해주세요.
            </Typography>
          </S.ChoiceRestrictedBox>
        )
      }

      {/* 질문 박스 */}
      <Box sx={{ border: "1px solid #eee", borderRadius: "10px", p: "10px", mb: "20px" }}>
        <Typography sx={{ font: "600 18px Pretendard", mb: "10px" }}>
          {"질문. "}

          {/* 중복 제출 가능할 때 컴포넌트 생성 */}
          {currentSlide?.duplicateSubmission && (
            <span style={{ color: "blue", fontSize: "16px" }}>{"(중복 제출 가능)"}</span>
          )}
        </Typography>

        {/* 질문 텍스트 */}
        <Typography sx={{ font: "700 24px Pretendard" }}>{currentSlide?.question}</Typography>
      </Box>

      {/* 선택지 박스 */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", mb: "10px" }}>
        {currentSlide?.options?.map((option) => (
          <S.OptionBox
            key={option.id}
            id={option.id}
            sx={{
              borderColor: selectedOptions.includes(option.id) ? "#c33c3c" : "#eee", // * 선택된 옵션일 때 테두리 색상
              bgcolor: selectedOptions.includes(option.id) ? "#c33c3c50" : "#f5f5f5", // * 선택된 옵션일 때 배경 색상
            }}
            onClick={handleOption}
          >
            {/* 체크 라디오 */}
            <Radio checked={selectedOptions.includes(option.id)} />

            {/* 선택지 텍스트 */}
            <Typography sx={{ font: "600 18px Pretendard" }}>{option.value}</Typography>
          </S.OptionBox>
        ))}

        {/* 기타 의견 옵션이 선택된 경우 추가 */}
        {currentSlide?.etcContained && (
          <S.OptionBox
            id="etc"
            sx={{
              borderColor: selectedOptions.includes("etc") ? "#c33c3c" : "#eee",
              bgcolor: selectedOptions.includes("etc") ? "#c33c3c50" : "#f5f5f5",
            }}
            onClick={handleOption}
          >
            <Radio checked={selectedOptions.includes("etc")} />

            <Typography sx={{ font: "600 18px Pretendard" }}>기타 의견</Typography>
          </S.OptionBox>
        )}
      </Box>

      {/* 기타 의견 입력 박스 */}
      <S.EtcOpinionBox sx={{ height: selectedOptions.includes("etc") ? "45px" : "0px" }}>
        <Textfield
          size="small"
          fullWidth
          placeholder="기타의견을 입력해주세요."
          value={etcOpinion}
          onChange={(e) => setEtcOpinion(e.target.value)}
        />
      </S.EtcOpinionBox>

      {/* 제출 버튼 */}
      <Box sx={{ display: "flex", justifyContent: "center", my: "20px" }}>
        <S.ChoiceSubmitButton
          fullWidth
          variant="contained"
          loading={loading}
          onClick={submitChoice}
          disabled={
            currentSlide?.restricted ||
            currentSlide?.userData?.filter((user) => user.userId === localStorage.getItem("uuid"))
              .length > 0
          } // * 투표 제한, 이미 제출한 경우 버튼 비활성화
        >
          {/* 투표 제한, 이미 제출한 경우 버튼 비활성화 */}
          {currentSlide?.userData?.filter((user) => user.userId === localStorage.getItem("uuid"))
            .length > 0
            ? "이미 제출하셨습니다."
            : "제출하기"}
        </S.ChoiceSubmitButton>
      </Box>
    </S.ChoiceBody>
  );
};
