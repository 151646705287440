import { styled } from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Container,
  Button,
  Grid,
  IconButton,
  Paper,
  MenuItem,
} from "@mui/material";

export const Body = MuiStyled((props) => (
  <Box
    sx={{
      position: "relative",
      pt: "80px",
      height: "1px",
      minHeight: "100vh",
      maxWidth: "1920px",
      bgcolor: "#f5f5f5",
      boxSizing: "border-box",
      margin: "auto",
    }}
    {...props}
  />
))({});

export const Content = MuiStyled((props) => (
  <Box
    sx={{
      height: "100%",
      width: "calc(100% - 20px)",
      minHeight: "600px",
      maxHeight: "1080px",
      margin: "auto",
      pb: "20px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
    }}
    {...props}
  />
))({});

export const EditContainer = MuiStyled((props) => (
  <Grid
    container
    sx={{
      flex: 1,
      bgcolor: "#fff",
      borderRadius: "10px",
      boxShadow: "0px 3px 6px #00000028",
      p: "10px",
      overflow: "hidden",
    }}
    {...props}
  />
))({});

export const TitleArea = MuiStyled((props) => (
  <Grid item xs={12} sx={{ height: "45px", borderBottom: "1px solid #eee" }} {...props} />
))({});

export const SlideGrid = MuiStyled((props) => (
  <Grid
    item
    xs={2}
    sx={{
      overflow: "auto",
      height: "calc(100% - 45px)",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      pr: "10px",
      pt: "5px",
      boxSizing: "border-box",
      borderRight: "1px solid #eee",
    }}
    {...props}
  />
))({});

export const SlideArea = MuiStyled((props) => (
  <Box
    sx={{
      flex: 1,
      boxShadow: "inset 3px 3px 9px #c0c0c0, inset -3px -3px 9px #ffffff",
      bgcolor: "#f5f5f5",
      borderRadius: "8px",
      border: "1px solid #eee",
      overflow: "auto",
      p: "10px",
      pt: "20px",
    }}
    {...props}
  />
))({});

export const SlideTitle = MuiStyled((props) => (
  <Typography
    noWrap
    sx={{
      width: "100%",
      px: "10px",
      boxSizing: "border-box",
      textAlign: "center",
      fontWeight: "600",
    }}
    {...props}
  />
))({});

export const AddSlideButton = MuiStyled((props) => (
  <Button
    variant="contained"
    sx={{
      font: "600 18px Pretendard",
      mb: "5px",
      "&:active": { transform: "translateY(3px)", boxShadow: "none" },
    }}
    {...props}
  />
))({});

export const PreviewArea = MuiStyled((props) => (
  <Grid
    item
    xs={7}
    sx={{
      position: "relative",
      overflow: "auto",
      height: "calc(100% - 45px)",
      p: "5px 10px",
      boxSizing: "border-box",
      borderRight: "1px solid #eee",

      display: "flex",
      flexDirection: "column",
      gap: "10px",
    }}
    {...props}
  />
))({});

export const PreviewTitle = MuiStyled((props) => (
  <Typography sx={{ font: "600 18px Pretendard", textAlign: "center" }} {...props} />
))({});

export const PreviewOverlay = MuiStyled((props) => (
  <Box
    sx={{
      boxShadow: "inset 3px 3px 9px #c0c0c0, inset -3px -3px 9px #ffffff",
      bgcolor: "#f5f5f5",
      borderRadius: "8px",
      overflow: "auto",
      p: "10px",
      boxSizing: "border-box",
      // height: "100%",
      flex: 1,
    }}
    {...props}
  />
))({});

export const Preview = MuiStyled((props) => (
  <Box
    sx={{
      p: "5px 10px",
      height: "100%",
      boxSizing: "border-box",
      bgcolor: "#fff",
      borderRadius: "10px",
      boxShadow: "0px 3px 6px #00000028",
      overflow: "auto",
    }}
    {...props}
  />
))({});

export const DefaultPreview = MuiStyled((props) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      height: "100%",
      width: "100%",
      justifyContent: "center",
    }}
    {...props}
  />
))({});

export const EditorArea = MuiStyled((props) => (
  <Grid
    item
    xs={3}
    sx={{
      overflow: "auto",
      height: "calc(100% - 45px)",
      p: "5px 10px",
      boxSizing: "border-box",
    }}
    {...props}
  />
))({});

export const SlideItem = MuiStyled((props) => (
  <Box
    sx={{
      display: "flex",
      gap: "10px",
    }}
    {...props}
  />
))({});

export const SlidePaper = MuiStyled((props) => (
  <Paper
    sx={{
      position: "relative",
      width: "75%",
      aspectRatio: "4/3",
      margin: "auto",
      cursor: "pointer",
      userSelect: "none",
      mb: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      border: "2px solid #00000000",
      borderColor: props["current-slide-id"] === props.id ? "#c33c3c" : "#00000000",
    }}
    {...props}
  />
))({});

export const SlideButtonArea = MuiStyled((props) => (
  <Box
    sx={{
      position: "absolute",
      bottom: "5px",
      right: "5px",
      display: "flex",
      gap: "clamp(6px, 0.5vw, 10px)",
    }}
    {...props}
  />
))({});

export const SlideIconButton = MuiStyled((props) => (
  <IconButton
    sx={{
      width: "clamp(15px, 1.3vw, 25px)",
      height: "clamp(15px, 1.3vw, 25px)",
      borderRadius: "50%",
      fontSize: "15px !important",
      "&:hover": { bgcolor: "#e0e0e0" },
      "> svg": { fontSize: "clamp(12px, 0.9375vw, 18px)" },
    }}
    {...props}
  />
))({});

export const SelectItem = MuiStyled((props) => (
  <MenuItem
    sx={{
      " .MuiTypography-root": {
        font: "600 16px Pretendard",
      },
    }}
    {...props}
  />
))({});

export const IsCompletedArea = MuiStyled((props) => (
  <Box
    sx={{
      position: "absolute",
      top: "2px",
      right: "2px",
    }}
    {...props}
  />
))({});

export const SaveButton = MuiStyled((props) => (
  <IconButton
    sx={{
      position: "absolute",
      top: "95px",
      right: "10px",
      width: "max-content",
      height: "60px",
      borderRadius: "10px",
      bgcolor: "#4CAF50",
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      gap: "10px",
      backgroundColor: "#c33c3c",
      "&:hover": {
        backgroundColor: "#a31c1c",
      },
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
      transition: "all 0.3s ease-in-out",
    }}
    {...props}
  />
))({});

export const SlideTypeBox = MuiStyled((props) => (
  <Box sx={{ py: "10px", borderBottom: "1px solid #eee", mb: "10px" }} {...props} />
))({});

// * Intro Preview or Editor
export const IntroPreview = MuiStyled((props) => (
  <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }} {...props} />
))({});

export const IntroPreviewTitle = MuiStyled((props) => (
  <Typography
    sx={{
      mb: "10px",
      fontWeight: "600",
      fontFamily: "Pretendard",
      textAlign: "center",
      color: "#252b36",
      fontSize: "clamp(36px, 3.125vw, 60px)",
      width: "100%",
      wordBreak: "break-all",
    }}
    {...props}
  />
))({});

// * WordCloud Preview or Editor
export const WordCloudPreview = MuiStyled((props) => (
  <Box
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      p: "5px",
      boxSizing: "border-box",
    }}
    {...props}
  />
))({});

export const WordCloudPreviewTitle = MuiStyled((props) => (
  <Typography
    sx={{
      font: "700 32px Pretendard",
      borderBottom: "1px solid #eee",
      color: "#252b36",
      wordBreak: "break-all",
    }}
    {...props}
  />
))({});

export const ResponseText = MuiStyled((props) => (
  <Box
    sx={{
      position: "absolute",
      top: "10px",
      left: "10px",
      font: "500 16px Pretendard",
      color: "#777",
    }}
    {...props}
  />
))({});
