const NODE_ENV = process.env.NODE_ENV;

const NAVER = {
  CLIENT_ID: NODE_ENV === "development" ? "8cQJD1SZUBHaq15yFBjr" : "aSdOZ0wTafWxGrGxpjFW",
  CLIENT_SECRET: NODE_ENV === "development" ? "gHT087BcDP" : "BAPMfi81oQ",
  CALLBACK_URL:
    NODE_ENV === "development"
      ? "http://localhost:3000/oauth/naver"
      : "https://www.withplus.live/oauth/naver",
};

export default NAVER;
