import { useEffect, useState } from "react";
import S from "../style.module.css";

const SeventhPageMobile = ({ onNextClick, onPrevClick, resultObjectArray, userData, firstTypeArray }) => {
  const [userType, setUserType] = useState("");
  const [userTypeSplit, setUserTypeSplit] = useState("");
  const [stressResponses, setStressResponses] = useState([]);
  const [stressManagement, setStressManagement] = useState([]);

  useEffect(() => {
    if (resultObjectArray[5]?.name) {
      const name = resultObjectArray[5]?.name.split("(")[0];
      setUserType(resultObjectArray[5]?.name);
      setUserTypeSplit(name);
    }
    if (firstTypeArray[3]?.stress) {
      setStressResponses(firstTypeArray[3]?.stress.split("."));
    }
    if (firstTypeArray[3]?.stressManage) {
      setStressManagement(firstTypeArray[3]?.stressManage.split("."));
    }
  }, [resultObjectArray, firstTypeArray]);

  return (
    <div className={S.Page}>
      <div className={S.Title}>
        <p>스트레스 반응과 관리 방법</p>
      </div>
      <div className={S.PageContents}>
        <div className={S.Box}>
          <p className={S.SeventhPageStressTitle}>{userTypeSplit} 스트레스 행동</p>
          <p className={S.SeventhPageStressDesc}>
            {stressResponses.map((response, index) => response && (
              <span key={index}>{response.trim()}</span>
            ))}
          </p>
          <p className={S.SeventhPageStressTitle} style={{ borderRadius: "0" }}>
            {userTypeSplit} 스트레스 관리 방법
          </p>
          <p className={S.SeventhPageStressDesc}>
            {stressManagement.map((management, index) => management && (
              <span key={index}>{management.trim()}</span>
            ))}
          </p>
        </div>
      </div>
      <p className={S.alert}>※ 본 모바일 결과지는 요약본입니다</p>
      <div className={S.PageFooter}>
        <div className={S.PrevBtn} onClick={onPrevClick}>
          ← 뒤로가기
        </div>
        <div className={S.NextBtn} onClick={onNextClick}>
          다음
        </div>
      </div>
    </div>
  );
};

export default SeventhPageMobile;