// * npm modules
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

// * chart.js modules
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as ToolTip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

// * material ui modules
import { Typography, Grid, Skeleton } from "@mui/material";

// * material ui icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// * 프로젝트 내부 파일
import { ManagerContext } from "../contexts/ManagerContext";

// * style
import * as S from "./styles";

// * chart.js 설정
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ToolTip,
  Legend,
  ArcElement,
  ChartDataLabels
);

// * 차트 색상
const backgroundColor = [
  "rgba(255, 99, 132, 0.7)",
  "rgba(54, 162, 235, 0.7)",
  "rgba(255, 206, 86, 0.7)",
  "rgba(255, 162, 132, 0.7)",
  "rgba(153, 102, 255, 0.7)",
  "rgba(255, 159, 64, 0.7)",

  "rgba(75, 159, 206, 0.7)",
  "rgba(255, 162, 64, 0.7)",
  "rgba(75, 159, 64, 0.7)",
  "rgba(75, 192, 192, 0.7)",
];

// * Choice 컴포넌트
export default () => {
  const { slideId } = useParams(); // * 슬라이드 ID
  const { playingData, currentSlideIndex } = useContext(ManagerContext); // * 플레이 중인 데이터, 현재 슬라이드 인덱스

  const [isResizing, setIsResizing] = useState(false); // * 리사이징 중 여부

  const [currentSlide, setCurrentSlide] = useState(null); // * 현재 슬라이드
  const [question, setQuestion] = useState(""); // * 질문
  const [data, setData] = useState(null); // * 차트 데이터
  const [options, setOptions] = useState(null); // * 차트 옵션
  const [etcOpinions, setEtcOpinions] = useState([]); // * 기타 의견
  const [translateX, setTranslateX] = useState(0); // * translateX
  const [display, setDisplay] = useState("flex"); // * display

  // * 차트 데이터 설정
  useEffect(() => {
    // * 플레이 중인 데이터가 있으면 실행
    if (playingData[currentSlideIndex]) {
      const currentSlide = playingData[currentSlideIndex]; // * 현재 슬라이드
      // * 옵션별 투표 수 배열 생성
      let options = new Array(
        currentSlide.etcContained ? currentSlide.options.length + 1 : currentSlide.options.length
      ).fill(0);
      let etcOpinions = []; // * 기타 의견 배열 생성

      // * 투표 수 계산
      for (let item of currentSlide.userData) {
        for (let option of item.selectedOptions) {
          // * 기타 의견이면 기타 의견 배열에 추가
          if (option === "etc") {
            etcOpinions.push(item.etcOpinion); // * 기타 의견 추가
            options[currentSlide.options.length]++; // * 기타 의견 투표 수 추가
          } else {
            let index = currentSlide.options.findIndex((temp) => temp.id === option); // * 옵션 인덱스
            options[index]++; // * 옵션 투표 수 추가
          }
        }
      }

      setCurrentSlide(currentSlide); // * 현재 슬라이드 설정
      setQuestion(currentSlide.question); // * 질문 설정
      setEtcOpinions(etcOpinions); // * 기타 의견 설정

      // * 차트 데이터 설정
      if (currentSlide.etcContained) {
        setData({
          labels: [...currentSlide.options.map((option) => option.value), "기타"], // * 라벨 설정
          // * 데이터 설정
          datasets: [
            {
              backgroundColor,
              data: options,
            },
          ],
        });
      } else {
        setData({
          labels: currentSlide.options.map((option) => option.value), // * 라벨 설정
          // * 데이터 설정
          datasets: [
            {
              backgroundColor,
              data: options,
            },
          ],
        });
      }

      // * 차트 옵션 설정 (막대 그래프)
      if (currentSlide.resultLayout === "bar") {
        const max = Math.max(...options); // * 최대 투표 수

        // * 차트 옵션 설정
        setOptions({
          plugins: {
            // * 범례 설정 (막대 그래프)
            legend: {
              display: false,
            },
            // * 툴팁 설정
            tooltip: {
              callbacks: {
                label: function (value) {
                  return " 투표 수 : " + value.raw;
                },
              },
            },
            // * 데이터 라벨 설정
            datalabels: {
              display: true, // * 표시 여부
              color: "black", // * 색상
              font: { size: "20px", weight: "bold", family: "Pretendard" }, // * 폰트 설정
              align: "end", // * 정렬
              anchor: "end", // * 앵커
              // * 퍼센티지 표시 여부
              formatter: (value, ctx) => {
                const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                const percentage = sum === 0 ? "0 %" : ((value * 100) / sum).toFixed(1) + "%";
                return currentSlide.percentage ? percentage : value;
              },
            },
          },
          // * 축 설정
          scales: {
            y: {
              min: 0, // * 최소값
              max: max + 5, // * 최대값
              ticks: { font: { size: 16, weight: 600 } }, // * 눈금선 설정 (폰트)
            },
            x: { ticks: { font: { size: 18, weight: 600 } } }, // * 눈금선 설정 (폰트)
          },
        });
      } else {
        // * 차트 옵션 설정 (원형 그래프, 도넛 그래프)
        setOptions({
          plugins: {
            // * 범례 설정 (원형 그래프, 도넛 그래프)
            legend: {
              position: "top", // * 위치
              labels: {
                font: { size: 16, family: "Pretendard", weight: "bold" }, // * 폰트 설정
              },
            },
            // * 툴팁 설정
            tooltip: {
              callbacks: {
                label: function (context) {
                  return " 투표 수 : " + context.raw;
                },
              },
            },
            // * 데이터 라벨 설정
            datalabels: {
              display: true, // * 표시 여부
              color: "white", // * 색상
              // * 퍼센티지 표시 여부
              formatter: (value, ctx) => {
                const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                const percentage = sum === 0 ? "0 %" : ((value * 100) / sum).toFixed(2) + "%";
                return currentSlide?.percentage ? percentage : value + " 표";
              },
              // * 폰트 설정
              font: {
                size: "28px",
                weight: "bold",
                family: "Pretendard",
                align: "center",
              },
            },
          },
        });
      }
    }
  }, [playingData, currentSlideIndex]);

  // * 리사이징 이벤트
  useEffect(() => {
    // * 리사이징 이벤트 리스너 등록
    window.addEventListener("resize", () => {
      setIsResizing(true); // * 리사이징 중 여부 설정

      setTimeout(() => {
        setIsResizing(false); // * 리사이징 중 여부 설정
      }, 100);
    });
  }, []);

  useEffect(() => {
    setTranslateX(0); // * translateX 초기화
    setIsResizing(true); // * 리사이징 중 여부 설정
    setDisplay("none"); // * display 설정

    setTimeout(() => {
      setIsResizing(false); // * 리사이징 중 여부 설정
      setDisplay("flex"); // * display 설정
    }, 100);
  }, [slideId]);

  return (
    <S.ChoiceBody>
      {/* 질문 */}
      <S.ChoiceQuestion sx={{ width: "calc(100% - 100px)" }}>
        {question || "질문이 없습니다."}
      </S.ChoiceQuestion>

      {/* 콘텐츠 */}
      <S.ChoiceContent>
        <S.ExplainBox>
          {/* 타입 */}
          <S.TypeText>
            {"선택형 "}

            {(() => {
              // * 결과 레이아웃에 따른 텍스트 설정
              switch (playingData[currentSlideIndex]?.resultLayout) {
                case "bar":
                  return "(막대 그래프)";
                case "circle":
                  return "(원형 그래프)";
                case "doughnut":
                  return "(도넛 그래프)";
                default:
                  return "";
              }
            })()}
          </S.TypeText>

          {/* 투표 수 */}
          <S.PollingCount>
            투표 수 : {playingData[currentSlideIndex]?.userData.length}
          </S.PollingCount>
        </S.ExplainBox>

        {/* 메인 */}
        <S.ChoiceMain sx={{ display }}>
          <S.ChoiceArea
            sx={{
              alignItems: currentSlide?.userData.length !== 0 ? "end" : "center",
              transform: `translateX(${0 - translateX}%)`, // * translateX 설정
            }}
          >
            {
              // * 차트 데이터가 있으면 실행
              currentSlide?.userData.length !== 0 && (
                <S.ChartBox>
                  {playingData[currentSlideIndex]?.userData.length !== 0 &&
                    !isResizing &&
                    options &&
                    (() => {
                      switch (playingData[currentSlideIndex]?.resultLayout) {
                        case "bar":
                          return <Bar data={data} options={options} />; // * 막대 그래프
                        case "circle":
                          return <Pie data={data} options={options} />; // * 원형 그래프
                        case "doughnut":
                          return <Doughnut data={data} options={options} />; // * 도넛 그래프
                        default:
                          return <></>;
                      }
                    })()}
                </S.ChartBox>
              )
            }

            {
              // * 차트 데이터가 없으면
              playingData[currentSlideIndex]?.userData.length === 0 && (
                <S.WaitingText>참여자의 투표를 기다리는 중...</S.WaitingText>
              )
            }
          </S.ChoiceArea>

          {/* 기타의견 */}
          <S.EtcOpinionBox sx={{ transform: `translateX(${100 - translateX}%)` }}>
            <S.EtcOpinionTitle>기타의견</S.EtcOpinionTitle>

            {/* 컨테이너*/}
            <Grid container sx={{ height: "100%", width: "95%", overflow: "auto" }}>
              {
                // * 기타 의견이 1줄에 4개씩 나오도록 설정
                etcOpinions.length !== 0 &&
                  new Array(4).fill(0).map((_, index) => {
                    return (
                      <Grid key={index} item xs={3} sx={{ height: "100%", p: "10px" }}>
                        {etcOpinions.map((opinion, i) => {
                          if (i % 4 === index) {
                            return (
                              <S.EtcOpinion key={i}>
                                <Typography
                                  sx={{ font: "600 16px Pretendard", mb: "10px", color: "#888" }}
                                >
                                  기타 의견 {i + 1}
                                </Typography>

                                <Typography
                                  sx={{
                                    font: "600 18px Pretendard",
                                    wordBreak: "break-all",
                                  }}
                                >
                                  {opinion}
                                </Typography>
                              </S.EtcOpinion>
                            );
                          }
                        })}
                      </Grid>
                    );
                  })
              }

              {
                // * 기타 의견이 없으면 실행
                etcOpinions.length === 0 && (
                  <S.EtcOpinionNoData>
                    <S.WaitingText>기타 의견이 없습니다.</S.WaitingText>
                  </S.EtcOpinionNoData>
                )
              }
            </Grid>
          </S.EtcOpinionBox>
        </S.ChoiceMain>
      </S.ChoiceContent>

      {/* 네비게이션 오른쪽 버튼 (기타의견 보기) */}
      <S.ToolTip
        title={!currentSlide?.etcContained || translateX === 100 ? "" : "기타의견 보기"}
        placement="left"
      >
        <S.ChoiceLeftNav
          onClick={() => setTranslateX(100)}
          disabled={!currentSlide?.etcContained || translateX === 100}
        >
          <NavigateNextIcon />
        </S.ChoiceLeftNav>
      </S.ToolTip>

      {/* 네비게이션 왼쪽 버튼 (차트 보기) */}
      <S.ToolTip title={translateX === 0 ? "" : "그래프 보기"} placement="right">
        <S.ChoiceRightNav onClick={() => setTranslateX(0)} disabled={translateX === 0}>
          <NavigateBeforeIcon />
        </S.ChoiceRightNav>
      </S.ToolTip>
    </S.ChoiceBody>
  );
};
