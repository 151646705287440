import { Box, IconButton, Typography, Grid } from "@mui/material";
import { CustomButton as Button } from "../../../components/Button";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useState, useContext, useEffect, useRef } from "react";
import { ManagerContext } from "../contexts/ManagerContext";
import { START_GAME } from "utils/api/Tap";
import TimerIcon from "@mui/icons-material/Timer";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

// import background from "assets/images/LiveTap/background.png";
import background from "assets/images/LiveTap_new/background_start.png";
import backgroundLast from "assets/images/LiveTap_new/Bg-Last.png";
import characters from "assets/images/LiveTap_new/Character";
import focusEffect from "assets/images/LiveTap_new/focusEffect.gif";
import Character_Goal from "assets/images/LiveTap_new/Character_Goal";

// import starImg from "assets/images/LiveTap/star.png";
// import postIt from "assets/images/LiveTap/post-it.png";
// import postItGif from "assets/images/LiveTap/post-it.gif";
// import maskingTapeImg from "assets/images/LiveTap/maskingTape.png";
// import ropeImages from "assets/images/LiveTap/rope";
// import teamCharacter from "assets/images/LiveTap/teamCharacter";
// import teamNameTag from "assets/images/LiveTap/teamNameTag";
// import teamCatchingAnimation from "assets/images/LiveTap/teamCatchingAnimation";
// import teamCatchedImage from "assets/images/LiveTap/teamCatchedImage";
// import tadaImg from "assets/images/LiveTap/tada.png";
// import winningComponents from "assets/images/LiveTap/winningComponents";
// import loapImg from "assets/images/LiveTap/rope.png";
import { AudioContext } from "../contexts/AudioContext";
import Title from "components/Title";

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const GameScreen = () => {
  const boxRef = useRef(null);
  const gameScreenRef = useRef(null);
  // const { bgmVolumn, setBgmVolumn, handleBGM, playSFX } = useContext(AudioContext);

  // const [isFullscreen, setFullscreen] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [resetTriggered, setResetTriggered] = useState(false);
  const handle = useFullScreenHandle();
  // const navigate = useNavigate();
  // const { socket, tap, timer, handleBGM, setSoundVolume, soundVolume, BGM, prepareSounds } =
  //   useContext(ManagerContext);
  const { socket, tap, timer } = useContext(ManagerContext);
  const { handleBGM, setSoundVolume, soundVolume, BGM, prepareSounds, finalSFX } =
    useContext(AudioContext);

  const [boxStyles, setBoxStyles] = useState({});
  const [preloadedImages, setPreloadedImages] = useState(null);

  // const [ropePostion, setRopePostion] = useState({ top: 0, left: 0 });
  // const [isTeamProgessOver75, setIsTeamProgessOver75] = useState(null);
  // const [showEndingScreen, setShowEndingScreen] = useState(false);
  // const [fontPixel, setFontPixel] = useState(null);
  // const [fontStroke, setFontStroke] = useState(null);

  const [winnerTeamIdx, setWinnerTeamIdx] = useState(null);
  const [gameScreenWidth, setGameScreenWidth] = useState(null);
  const [characterPosition, setCharacterPosition] = useState([
    { top: "35%", left: "33%" },
    { top: "35%", left: "41%" },
    { top: "35%", left: "48.5%" },
    { top: "34%", left: "56.5%" },
  ]);
  const [progess, setProgess] = useState(null);
  const [firstInRunning, setFirstInRunning] = useState(null);
  const [maxProgessIsOver50, setMaxProgessIsOver50] = useState(false);
  const [maxProgoessIsOver75, setMaxProgoessIsOver75] = useState(false);

  const playBGM = () => {
    if (BGM.paused) {
      handleBGM();
      setSoundVolume(0.24);
    }
    prepareSounds();
  };

  const handleMuteSound = () => {
    if (BGM.paused) {
      handleBGM();
    }

    if (soundVolume === 0) {
      setSoundVolume(0.25);
    } else {
      setSoundVolume(0);
    }
  };

  const handleSoundVolume = (e) => {
    if (BGM.paused) {
      handleBGM();
    }

    setSoundVolume((e.target.value * 1) / 200);
  };
  // const [showWinner, setShowWinner] = useState(false);

  // useEffect(() => {

  //   socket.on("updatedTap", (updatedTap) => {
  //     setTap(updatedTap);
  //     if (updatedTap.isGamePaused || updatedTap.isGameEnded) {
  //       setGameEnded(true);
  //     }
  //     if (!updatedTap.isGameStart && !updatedTap.isGamePaused && !updatedTap.isGameEnded) {
  //       setResetTriggered(true);
  //       setGameEnded(false);
  //       setTimeout(() => {
  //         setResetTriggered(false);
  //       }, 1000);
  //     }
  //   });

  //   // socket.on("gameEnded", () => {
  //   //   setGameEnded(true);
  //   // });

  //   return () => {
  //     socket.off("updatedTap");
  //     socket.off("gameEnded");
  //   };
  // });

  useEffect(() => {
    const preloadImages = () => {
      const loadedImages = {};
      let loadedCount = 0;

      for (let i = 0; i < 4; i++) {
        const image = new Image();
        image.src = Character_Goal[i];
        image.onload = () => {
          loadedImages[i] = image;
          loadedCount++;

          if (loadedCount === 4) {
            setPreloadedImages(loadedImages);
          }
        };

        image.onerror = () => {
          const fallbackImage = new Image();
          fallbackImage.src = Character_Goal[0];
          loadedImages[i] = fallbackImage;
          loadedCount++;

          if (loadedCount === 4) {
            setPreloadedImages(loadedImages);
          }
        };
      }

      // Character_Goal.forEach((src, index) => {
      //   const image = new Image();
      //   image.src = src;
      //   image.onload = () => {
      //     loadedImages.push(image);
      //     loadedCount++;

      //     if (loadedCount === Character_Goal.length) {
      //       setPreloadedImages(loadedImages);
      //     }
      //   };

      //   image.onerror = () => {
      //     const fallbackImage = new Image();
      //     fallbackImage.src = "https://via.placeholder.com/150";
      //     loadedImages[index] = fallbackImage;
      //     loadedCount++;

      //     if (loadedCount === Character_Goal.length) {
      //       setPreloadedImages(loadedImages);
      //     }
      //   };
      // });
    };

    preloadImages();
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const boxWidth = entries[0].contentRect.width;
      const boxHeight = entries[0].contentRect.height;

      if (boxWidth / boxHeight > 16 / 9) {
        // console.log("가로가 더 길어요");
        setBoxStyles({
          height: "100%",
        });
      } else {
        // console.log("세로가 더 길어요");
        setBoxStyles({
          width: "100%",
        });
      }
    });

    if (boxRef.current) observer.observe(boxRef.current);

    return () => {
      boxRef.current && observer.unobserve(boxRef.current);
    };
  }, []);

  useEffect(() => {
    if (!progess) return;

    const maxProgess = Math.max(...progess);

    if (maxProgess === 0) {
      setFirstInRunning(null);
      return;
    }

    // 랭킹도 포함해서 표시해야함
    let newProgess = progess.map((progess, idx) => {
      return { progess: progess, teamIdx: idx };
    });

    newProgess.sort((a, b) => b.progess - a.progess);

    newProgess = newProgess.map((progess, idx) => {
      return { ...progess, rank: idx + 1 };
    });

    newProgess.sort((a, b) => a.teamIdx - b.teamIdx);

    let newCharacterPosition = [];

    if (maxProgess >= 0.33) {
      setMaxProgessIsOver50(true);

      if (maxProgess >= 0.66) {
        setMaxProgoessIsOver75(true);

        newProgess.forEach((progess) => {
          switch (progess.rank) {
            case 1:
              switch (progess.teamIdx) {
                case 0:
                  newCharacterPosition.push({ width: "50%", top: "22%", left: "-12%", zIndex: 6 });
                  break;
                case 1:
                  newCharacterPosition.push({ width: "50%", top: "24%", left: "12.5%", zIndex: 6 });
                  break;
                case 2:
                  newCharacterPosition.push({ width: "50%", top: "24%", left: "36%", zIndex: 6 });
                  break;
                case 3:
                  newCharacterPosition.push({ width: "50%", top: "22%", left: "60%", zIndex: 6 });
                  break;
                default:
                  break;
              }
              break;

            case 2:
              switch (progess.teamIdx) {
                case 0:
                  newCharacterPosition.push({ width: "45%", top: "24%", left: "-4%", zIndex: 5 });
                  break;
                case 1:
                  newCharacterPosition.push({ width: "45%", top: "24%", left: "18%", zIndex: 5 });
                  break;
                case 2:
                  newCharacterPosition.push({ width: "45%", top: "24%", left: "36%", zIndex: 5 });
                  break;
                case 3:
                  newCharacterPosition.push({ width: "45%", top: "24%", left: "55%", zIndex: 5 });
                  break;
                default:
                  break;
              }
              break;

            case 3:
              switch (progess.teamIdx) {
                case 0:
                  newCharacterPosition.push({ width: "40%", top: "27%", left: "5%", zIndex: 4 });
                  break;
                case 1:
                  newCharacterPosition.push({ width: "40%", top: "27%", left: "23%", zIndex: 4 });
                  break;
                case 2:
                  newCharacterPosition.push({ width: "40%", top: "27%", left: "35%", zIndex: 4 });
                  break;
                case 3:
                  newCharacterPosition.push({ width: "40%", top: "27%", left: "52%", zIndex: 4 });
                  break;
                default:
                  break;
              }
              break;

            case 4:
              switch (progess.teamIdx) {
                case 0:
                  newCharacterPosition.push({ width: "35%", top: "30%", left: "14%", zIndex: 3 });
                  break;
                case 1:
                  newCharacterPosition.push({ width: "35%", top: "30%", left: "26%", zIndex: 3 });
                  break;
                case 2:
                  newCharacterPosition.push({ width: "35%", top: "30%", left: "38%", zIndex: 3 });
                  break;
                case 3:
                  newCharacterPosition.push({ width: "35%", top: "30%", left: "52%", zIndex: 3 });
                  break;
                default:
                  break;
              }
              break;

            default:
              break;
          }
        });

        // console.log("newCharacterPosition:", newCharacterPosition);

        setCharacterPosition(newCharacterPosition);

        // console.log("newProgess:", newProgess);

        // setCharacterPosition([
        //   { top: "35%", left: "18%" },
        //   { top: "35%", left: "32%" },
        //   { top: "35%", left: "47%" },
        //   { top: "34%", left: "62%" },
        // ]);
      } else {
        // console.log(newProgess);
        // ? 1등 2등 3등 4등
        newProgess.forEach((progess) => {
          switch (progess.rank) {
            case 1:
              switch (progess.teamIdx) {
                case 0:
                  newCharacterPosition.push({ top: "35%", left: "18%", zIndex: 6, width: "20%" });
                  break;
                case 1:
                  newCharacterPosition.push({ top: "35%", left: "32%", zIndex: 6, width: "20%" });
                  break;
                case 2:
                  newCharacterPosition.push({ top: "35%", left: "47%", zIndex: 6, width: "20%" });
                  break;
                case 3:
                  newCharacterPosition.push({ top: "34%", left: "62%", zIndex: 6, width: "20%" });
                  break;
                default:
                  break;
              }
              break;
            case 2:
              switch (progess.teamIdx) {
                case 0:
                  newCharacterPosition.push({ top: "33%", left: "20%", zIndex: 5, width: "19%" });
                  break;
                case 1:
                  newCharacterPosition.push({ top: "33%", left: "33%", zIndex: 5, width: "19%" });
                  break;
                case 2:
                  newCharacterPosition.push({ top: "33%", left: "47%", zIndex: 5, width: "19%" });
                  break;
                case 3:
                  newCharacterPosition.push({ top: "32%", left: "61%", zIndex: 5, width: "19%" });
                  break;
                default:
                  break;
              }
              break;
            case 3:
              switch (progess.teamIdx) {
                case 0:
                  newCharacterPosition.push({ top: "30%", left: "23%", zIndex: 4, width: "18%" });
                  break;
                case 1:
                  newCharacterPosition.push({ top: "30%", left: "35%", zIndex: 4, width: "18%" });
                  break;
                case 2:
                  newCharacterPosition.push({ top: "30%", left: "46%", zIndex: 4, width: "18%" });
                  break;
                case 3:
                  newCharacterPosition.push({ top: "29%", left: "57%", zIndex: 4, width: "18%" });
                  break;
                default:
                  break;
              }
              break;
            case 4:
              switch (progess.teamIdx) {
                case 0:
                  newCharacterPosition.push({ top: "27%", left: "27%", zIndex: 3, width: "17%" });
                  break;
                case 1:
                  newCharacterPosition.push({ top: "27%", left: "37%", zIndex: 3, width: "17%" });
                  break;
                case 2:
                  newCharacterPosition.push({ top: "27%", left: "45%", zIndex: 3, width: "17%" });
                  break;
                case 3:
                  newCharacterPosition.push({ top: "26%", left: "55%", zIndex: 3, width: "17%" });
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
        });
        setCharacterPosition(newCharacterPosition);

        // setCharacterPosition([
        //   // { top: "35%", left: "18%", width: "20%" }, // 1팀 1등
        //   // { top: "33%", left: "20%", width: "19%" }, // 1팀 2등
        //   // { top: "30%", left: "23%", width: "18%" }, // 1팀 3등
        //   { top: "27%", left: "27%", width: "17%" }, // 1팀 4등
        //   //
        //   // { top: "35%", left: "32%", width: "20%" }, // 2팀 1등
        //   // { top: "33%", left: "33%", width: "19%" }, // 2팀 2등
        //   // { top: "30%", left: "35%", width: "18%" }, // 2팀 3등
        //   { top: "27%", left: "37%", width: "17%" }, // 2팀 4등
        //   //
        //   // { top: "35%", left: "47%", width: "20%" }, // 3팀 1등
        //   // { top: "33%", left: "47%", width: "19%" }, // 3팀 2등
        //   // { top: "30%", left: "46%", width: "18%" }, // 3팀 3등
        //   { top: "27%", left: "45%", width: "17%" }, // 3팀 4등
        //   //
        //   // { top: "34%", left: "62%", width: "20%" }, // 4팀 1등
        //   // { top: "32%", left: "61%", width: "19%" }, // 4팀 2등
        //   // { top: "29%", left: "57%", width: "18%" }, // 4팀 3등
        //   { top: "26%", left: "55%", width: "17%" }, // 4팀 3등
        // ]);
      }
    }

    const maxProgessIdx = progess.findIndex((progess) => progess === maxProgess);

    setFirstInRunning(maxProgessIdx);
  }, [progess]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const gameScreenWidth = entries[0].contentRect.width;

      setGameScreenWidth(gameScreenWidth / 100);

      // console.log("gameScreenWidth:", gameScreenWidth);
      // console.log("gameScreenHeight:", gameScreenHeight);

      // setFontPixel(gameScreenWidth * 0.05 + "px");
      // setFontStroke(gameScreenWidth * 0.0026 + "px");
    });

    if (gameScreenRef.current) observer.observe(gameScreenRef.current);

    return () => {
      gameScreenRef.current && observer.unobserve(gameScreenRef.current);
    };
  }, []);

  useEffect(() => {
    let { teamData } = tap;

    if (!teamData) return;

    if (tap.isGamePaused || tap.isGameEnded) {
      setGameEnded(true);
    }

    // if (tap.isGamePaused || tap.isGameEnded) {

    //   setGameEnded(true);
    // }
    if (!tap.isGameStart && !tap.isGamePaused && !tap.isGameEnded) {
      setResetTriggered(true);
      setGameEnded(false);
      setTimeout(() => {
        setResetTriggered(false);
      }, 1000);
    }

    let taps = teamData.map(
      (team) => team.teamMembers.reduce((acc, cur) => acc + cur.tapCount, 0) / (team.teamScore || 1)
    );

    setProgess(taps);

    // let taps = getTaps(teamData).then((taps) => {
    // taps가 모두 0이면 리턴
    // if (taps.every((tap) => tap === 0)) {
    //   setRopePostion({ top: 0, left: 0 });
    // } else {
    //   let maxTap = Math.max(...taps);

    //   let maxTapIdx = taps.findIndex((tap) => tap === maxTap);

    //   switch (maxTapIdx) {
    //     case 0:
    //       setRopePostion({ top: -20, left: -20 }); // 1팀
    //       break;
    //     case 1:
    //       setRopePostion({ top: 15, left: 20 }); // 2팀
    //       break;
    //     case 2:
    //       setRopePostion({ top: -20, left: 20 }); // 3팀
    //       break;
    //     case 3:
    //       setRopePostion({ top: 15, left: -20 });
    //       break;
    //     default:
    //       setRopePostion({ top: 0, left: 0 });
    //       break;
    //   }
    // }

    // let maxTap = Math.max(...taps);

    // let maxTapIdx = taps.findIndex((tap) => tap === maxTap);

    // switch (maxTapIdx) {
    //   case 0:
    //     setRopePostion({ top: -20, left: -20 }); // 1팀
    //     break;
    //   case 1:
    //     setRopePostion({ top: 15, left: 20 }); // 2팀
    //     break;
    //   case 2:
    //     setRopePostion({ top: -20, left: 20 }); // 3팀
    //     break;
    //   case 3:
    //     setRopePostion({ top: 15, left: -20 });
    //     break;
    //   default:
    //     setRopePostion({ top: 0, left: 0 });
    //     break;
    // }

    // setIsTeamProgessOver75(taps);

    if (tap.isGameEnded) {
      // const sleep = () => new Promise((resolve) => setTimeout(resolve, 2000));

      // sleep().then(() => {
      //   setShowEndingScreen(true);
      // });

      // taps array의 최대값을 찾아서 그 인덱스를 찾아서 그에 맞는 이미지를 보여준다.
      let winnerTamsIdx = taps.findIndex((tap) => tap === 1);

      // taps에서 1인 값이 없으면 가장 큰 값을 찾아서 그에 맞는 이미지를 보여준다.
      if (winnerTamsIdx === -1) {
        const maxTap = Math.max(...taps);

        winnerTamsIdx = taps.findIndex((tap) => tap === maxTap);
      }

      // console.log("winnerTamsIdx:", winnerTamsIdx);
      setWinnerTeamIdx(winnerTamsIdx);
    }
    // else {
    //   setShowEndingScreen(false);
    // }
  }, [tap]);

  // const handleBack = () => {
  //   if (!tap) return; // tap이 존재하지 않을 때 early return
  //   navigate(`/tap/manager/${tap._id}/waitingRoom`);
  // };

  const handleStartGame = async () => {
    if (!tap) return; // tap이 존재하지 않을 때 early return
    try {
      setResetTriggered(true);

      await START_GAME(tap._id);
      socket.emit("startGame", { gameId: tap._id });
    } catch (error) {
      console.error("Failed to start game:", error);
    }
  };

  const handleStopGame = () => {
    if (!tap) return; // tap이 존재하지 않을 때 early return
    socket.emit("stopGame", { gameId: tap._id });
  };

  // const handleResetGame = async () => {
  //   if (!tap) return; // tap이 존재하지 않을 때 early return
  //   try {
  //     // await RESET_GAME(tap._id);
  //     socket.emit("resetGame", { gameId: tap._id });
  //   } catch (error) {
  //     console.error("Failed to reset game:", error);
  //   }
  // };

  const handleViewWinner = () => {
    if (!tap) return; // tap이 존재하지 않을 때 early return
    // navigate(`/tap/manager/${tap._id}/result`);
    // if(b)
    handleBGM();
    // playSFX("finalRank");
    finalSFX.play();
    sleep(3000).then(() => {
      handleBGM();
    });
    socket.emit("goToRankingPage", { gameId: tap._id });
    // socket.emit("showWinner", { gameId: tap._id });
    // navigate(`/tap/manager/${tap._id}/winner`);
    // setShowWinner(true);
    // navigate(`/tap/manager/${tap._id}/result`, { state: { tap } });
  };

  const isGameFinished = tap.isGamePaused || gameEnded;

  // const Temp = () => {
  //   return (
  //     <Box
  //       sx={{
  //         // bgcolor: "#ff000050",
  //         width: "100%",
  //         height: "100%",
  //         position: "relative",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <img src={starImg} alt="star" style={{ width: "88%", position: "absolute", zIndex: 1 }} />

  //       <Box sx={{ position: "absolute", zIndex: 8, right: 0 }}>
  //         {tap?.teamData?.length > 0 && (
  //           <Box
  //             sx={{
  //               width: "100%",
  //               display: "flex",
  //               flexDirection: "column",
  //               alignItems: "center",
  //             }}
  //           >
  //             {tap.teamData.map((team) => (
  //               <Box key={team.id} sx={{ mb: 2 }}>
  //                 <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
  //                   {team.teamName}
  //                 </Typography>
  //                 {team.teamMembers.map((member) => (
  //                   <Typography key={member.uid} sx={{ fontSize: "16px" }}>
  //                     {member.name}: {member.tapCount} taps
  //                   </Typography>
  //                 ))}
  //               </Box>
  //             ))}
  //           </Box>
  //         )}
  //       </Box>

  //       {showEndingScreen && (
  //         <Box
  //           sx={{
  //             // bgcolor: "#ff000050",
  //             position: "absolute",
  //             top: "0%",
  //             height: "95%",
  //             // height: "120%",
  //             width: "100%",
  //             backgroundImage: `url(${winningComponents.crayon[2]})`,
  //             backgroundSize: "100% 100%",
  //             zIndex: 0,
  //           }}
  //         >
  //           {/* hello */}
  //         </Box>
  //       )}

  //       <Box
  //         sx={{
  //           position: "absolute",
  //           backgroundImage: `url(${showEndingScreen ? postItGif : postIt})`,
  //           // backgroundImage: `url(${postIt})`,
  //           backgroundSize: "cover",
  //           width: "77%",
  //           aspectRatio: "2956/1892",
  //           filter: "drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.25))",
  //           // bgcolor: "#ff000050",
  //           top: "0",
  //           left: "52%",
  //           transform: "translateX(-50%)",
  //           zIndex: 1,
  //         }}
  //       >
  //         <Box
  //           sx={{
  //             // bgcolor: "#00ff0050",
  //             width: "100%",
  //             height: "100%",
  //             position: "relative",
  //           }}
  //         >
  //           {showEndingScreen && (
  //             <>
  //               <Box
  //                 sx={{
  //                   // bgcolor: "#ff000050",
  //                   position: "absolute",
  //                   top: "5.8%",
  //                   left: "6.72%",
  //                   transform: "rotate(-2.2deg)",
  //                   width: "85.5%",
  //                   height: "90%",
  //                   // overflow: "hidden",
  //                   // zIndex: 5,
  //                 }}
  //               >
  //                 <Box
  //                   sx={{
  //                     position: "absolute",
  //                     background: `url(${tadaImg})`,
  //                     width: "50%",
  //                     aspectRatio: "1/1",
  //                     backgroundSize: "100%",
  //                     backgroundRepeat: "no-repeat",
  //                     backgroundPosition: "center",
  //                     top: "-5%",
  //                     left: "-10%",
  //                     display: "flex",
  //                     justifyContent: "center",
  //                     alignItems: "center",
  //                   }}
  //                 >
  //                   <img
  //                     src={teamCharacter[winnerTeamIdx]}
  //                     alt="tada"
  //                     style={{ position: "absolute", width: "50%" }}
  //                   />
  //                 </Box>

  //                 <Box
  //                   sx={{
  //                     // bgcolor: "#ff000050",
  //                     position: "absolute",
  //                     bottom: "0%",
  //                     left: "50%",
  //                     transform: "translateX(-50%)",
  //                     // width: "100%",
  //                     height: "85%",
  //                     aspectRatio: "1744/1443",
  //                   }}
  //                 >
  //                   <img src={teamCatchedImage[winnerTeamIdx]} alt="teamCatchedImage" />
  //                 </Box>

  //                 <Box
  //                   sx={{
  //                     bgcolor: "#ff000050",
  //                     position: "absolute",
  //                     top: "10%",
  //                     right: "2.5%",
  //                     width: "20%",
  //                     aspectRatio: winningComponents.emphasis[0].aspectRatio,
  //                     background: `url(${winningComponents.emphasis[0].img})`,
  //                     backgroundSize: "100%",
  //                     backgroundRepeat: "no-repeat",
  //                     font: "900 96px Pretendard",
  //                     fontSize: fontPixel,
  //                     color: "#fff",
  //                     WebkitTextStroke: `${fontStroke} blue`,
  //                     display: "flex",
  //                     justifyContent: "center",
  //                     alignItems: "center",
  //                   }}
  //                 >
  //                   {winnerTeamIdx + 1}팀
  //                 </Box>
  //               </Box>
  //             </>
  //           )}

  //           {/* {showEndingScreen && ( */}
  //           {!showEndingScreen && (
  //             <Box
  //               sx={{
  //                 // bgcolor: "#ff000050",
  //                 position: "absolute",
  //                 top: "9.2%",
  //                 left: "5.82%",
  //                 transform: "rotate(-2.2deg)",
  //                 width: "82%",
  //                 height: "87%",
  //                 overflow: "hidden",
  //                 // zIndex: 5,
  //               }}
  //             >
  //               <img
  //                 src={ropeImages[tap.teamCount || 1]}
  //                 alt="loap"
  //                 style={{
  //                   position: "absolute",
  //                   width: "150%",
  //                   top: `calc(-30% + ${ropePostion.top}%)`,
  //                   left: `calc(-30% + ${ropePostion.left}%)`,
  //                   transform: "rotate(-2.2deg)",
  //                   transition: "all 0.25s linear",
  //                   filter: "drop-shadow(0px 3px 6px #00000038)",
  //                 }}
  //               />

  //               {/* timer가 0이 아니거나 게임이 시작되었으면  */}
  //               {timer !== 0 && !tap.isGameStart && (
  //                 <Box
  //                   sx={{
  //                     position: "absolute",
  //                     backgroundColor: "rgba(64, 64, 64, 0.9)",
  //                     height: "100%",
  //                     width: "100%",
  //                     display: "flex",
  //                     justifyContent: "center",
  //                     alignItems: "center",
  //                     flexDirection: "column",
  //                   }}
  //                 >
  //                   <Typography sx={{ font: "700 44px Pretendard", color: "#fff", mb: "40px" }}>
  //                     열심히 달릴 준비가 되셨나요?
  //                   </Typography>

  //                   <Box
  //                     sx={{
  //                       display: "flex",
  //                       alignItems: "center",
  //                       gap: "20px",
  //                       filter: "drop-shadow(0px 3px 6px #00000038)",
  //                     }}
  //                   >
  //                     <TimerIcon sx={{ fontSize: "44px", color: "#fff" }} />

  //                     <Typography sx={{ font: "700 44px Pretendard", color: "#fff" }}>
  //                       {timer}
  //                     </Typography>
  //                   </Box>
  //                 </Box>
  //               )}
  //             </Box>
  //           )}
  //         </Box>
  //       </Box>

  //       {/* {timer !== 0 && !tap.isGameStart && ( */}
  //       {!showEndingScreen && (
  //         <>
  //           {tap.teamData?.map((team, idx) => {
  //             const data = [
  //               { top: "10%", left: "7%" },
  //               { top: "73%", left: "83%" },
  //               { top: "10%", left: "83%" },
  //               { top: "70%", left: "7%" },
  //             ];
  //             return (
  //               <Box
  //                 key={team.id}
  //                 sx={{
  //                   position: "absolute",
  //                   zIndex: 2,
  //                   top: data[idx].top,
  //                   left: data[idx].left,
  //                   background: `url(${teamCharacter[idx]})`,
  //                   width: "10%",
  //                   aspectRatio: "1/1.5",
  //                   // height: "10%",
  //                   backgroundSize: "100%",
  //                   // bgcolor: "#ff000050",
  //                   backgroundRepeat: "no-repeat",
  //                 }}
  //               >
  //                 <Box
  //                   sx={{
  //                     position: "absolute",
  //                     top: "-27%",
  //                     left: "50%",
  //                     transform: "translateX(-50%)",
  //                     width: "75%",
  //                     background: `url(${teamNameTag[idx]})`,
  //                     backgroundSize: "100%",
  //                     aspectRatio: "1.3/1",
  //                     backgroundRepeat: "no-repeat",
  //                   }}
  //                 >
  //                   <Typography
  //                     sx={{
  //                       position: "absolute",
  //                       top: "50%",
  //                       left: "50%",
  //                       transform: "translate(-50%, -50%)",
  //                       color: "#fff",
  //                       font: "900 30px Pretendard",
  //                       WebkitTextStroke: "1px #333",
  //                       filter: "drop-shadow(0px 0px 2px #00000038)",
  //                     }}
  //                   >
  //                     {idx + 1}팀
  //                   </Typography>
  //                 </Box>
  //               </Box>
  //             );
  //           })}

  //           {tap.teamData?.map((team, idx) => {
  //             const data = [
  //               { top: "2%", left: "0.5%" },
  //               { top: "64%", right: "0.5%" },
  //               { top: "2%", right: "0.5%" },
  //               { top: "56%", left: "0.5%" },
  //             ];
  //             return (
  //               <Box
  //                 key={team.id}
  //                 sx={{
  //                   // display: "block",
  //                   display: isTeamProgessOver75?.[idx] >= 0.75 ? "block" : "none",
  //                   position: "absolute",
  //                   zIndex: 4,
  //                   top: data[idx].top,
  //                   left: data[idx].left || null,
  //                   right: data[idx].right || null,
  //                   width: "25%",
  //                   // aspectRatio: "1/1.5",

  //                   bgcolor: "#fff",
  //                   borderRadius: "10px",
  //                   boxShadow: "0 0 5px #00000038",
  //                 }}
  //               >
  //                 {isTeamProgessOver75?.[idx] >= 0.75 && isTeamProgessOver75?.[idx] < 1 && (
  //                   <img src={teamCatchingAnimation[idx]} alt="teamCatching" />
  //                 )}

  //                 {isTeamProgessOver75?.[idx] === 1 && (
  //                   <>
  //                     <img src={tadaImg} alt="tada" style={{ position: "absolute" }} />
  //                     <img src={teamCatchedImage[idx]} alt="teamCatched" />
  //                   </>
  //                 )}
  //               </Box>
  //             );
  //           })}
  //         </>
  //       )}
  //       {/* )} */}

  //       <img
  //         src={maskingTapeImg}
  //         alt="masking tape"
  //         style={{
  //           position: "absolute",
  //           width: "25%",
  //           zIndex: 3,
  //           top: showEndingScreen ? "1%" : "3%",
  //           left: "36%",
  //           transform: "rotate(1deg)",
  //         }}
  //       />

  //       <Box
  //         sx={{
  //           position: "absolute",
  //           bottom: "1%",
  //           left: "50%",
  //           transform: "translateX(-50%)",
  //           // bgcolor: "#ff000050",
  //           width: "100%",
  //           zIndex: 3,
  //           py: "5px",
  //         }}
  //       >
  //         {/* <Box
  //                 sx={{
  //                   position: "absolute",
  //                   left: 10,
  //                   top: 20,
  //                   bgcolor: "#ffffff88",
  //                   padding: "5px",
  //                   borderRadius: "5px",
  //                   boxShadow: "0 0 5px #00000038",
  //                   backdropFilter: "blur(5px)",
  //                 }}
  //               >
  //                 <Box
  //                   sx={{
  //                     // width: "85%",
  //                     display: "flex",
  //                     flexDirection: "row",
  //                     marginTop: "10px",
  //                   }}
  //                 >
  //                   <Typography>유저가 다 들어오지 못했다면&nbsp;</Typography>
  //                   <Typography
  //                     onClick={handleBack}
  //                     sx={{
  //                       textDecoration: "underline",
  //                       color: "#B43F3F",
  //                       cursor: "pointer",
  //                     }}
  //                   >
  //                     대기창으로 이동하기
  //                   </Typography>
  //                 </Box>

  //                 <Box
  //                   sx={{
  //                     // width: "85%",
  //                     display: "flex",
  //                     flexDirection: "row",
  //                     marginTop: "3px",
  //                   }}
  //                 >
  //                   <Typography
  //                     onClick={handleResetGame}
  //                     sx={{
  //                       textDecoration: "underline",
  //                       color: "#B43F3F",
  //                       cursor: "pointer",
  //                     }}
  //                   >
  //                     게임 리셋하기
  //                   </Typography>
  //                 </Box>
  //               </Box> */}

  //         <Box
  //           sx={{
  //             display: "flex",
  //             width: "100%",
  //             flexGrow: 1,
  //             gap: "10%",
  //             alignItems: "center",
  //             justifyContent: "center",
  //           }}
  //         >
  //           {isGameFinished ? (
  //             <Button
  //               onClick={handleViewWinner}
  //               sx={{
  //                 font: "700 26px Pretendard",
  //                 fontSize: "clamp(16.25px, 1.354vw, 22px)",
  //                 // borderRadius: "50%",
  //                 // width: "clamp(75px, 6.25vw, 100px)",
  //                 // height: "clamp(75px, 6.25vw, 100px)",
  //                 bgcolor: "#4caf50",
  //                 p: "10px 20px",
  //                 color: "white",
  //                 "&:hover": { bgcolor: "#388e3c" },
  //               }}
  //             >
  //               결과보기
  //             </Button>
  //           ) : (
  //             <>
  //               {tap.isGameStart && (
  //                 <Button
  //                   onClick={handleStopGame}
  //                   disabled={gameEnded} // gameEnded가 true이면 버튼 비활성화
  //                   sx={{
  //                     font: "700 26px Pretendard",
  //                     fontSize: "clamp(16.25px, 1.354vw, 22px)",
  //                     // borderRadius: "50%",
  //                     // width: "clamp(75px, 6.25vw, 110px)",
  //                     // height: "clamp(75px, 6.25vw, 110px)",
  //                     padding: "10px 20px",
  //                     bgcolor: gameEnded ? "#A9A9A9" : "#B43F3F",
  //                     color: "white",
  //                     "&:hover": { bgcolor: gameEnded ? "#A9A9A9" : "#9C3636" },
  //                   }}
  //                 >
  //                   정지하기
  //                 </Button>
  //               )}

  //               {!tap.isGameStart && (
  //                 <Button
  //                   onClick={handleStartGame}
  //                   disabled={tap.isGameStart || gameEnded || resetTriggered} // 게임이 이미 시작되었거나 종료된 경우 또는 리셋된 경우 버튼 비활성화
  //                   sx={{
  //                     font: "700 26px Pretendard",
  //                     fontSize: "clamp(16.25px, 1.354vw, 22px)",
  //                     // borderRadius: "50%",
  //                     // width: "clamp(75px, 6.25vw, 110px)",
  //                     // height: "clamp(75px, 6.25vw, 110px)",
  //                     padding: "10px 20px",
  //                     bgcolor:
  //                       tap.isGameStart || gameEnded || resetTriggered ? "#A9A9A9" : "#d6e2fb",
  //                     color: "#50586C",
  //                     "&:hover": {
  //                       bgcolor:
  //                         tap.isGameStart || gameEnded || resetTriggered ? "#A9A9A9" : "#c6d2eb",
  //                     },
  //                   }}
  //                 >
  //                   게임 시작하기
  //                 </Button>
  //               )}
  //             </>
  //           )}
  //         </Box>
  //       </Box>
  //     </Box>
  //   );
  // };

  return (
    <Box
      sx={{
        height: "100vh",
        pt: "80px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        bgcolor: "#f5f5f5",
      }}
    >
      <Box sx={{ px: "20px", position: "relative" }}>
        <Title children={{ name: "Live Tap" }} />
      </Box>

      <FullScreen handle={handle}>
        <Box
          ref={boxRef}
          sx={{
            bgcolor: "#fff",
            width: "calc(100% - 20px)",
            borderRadius: "10px",
            boxSizing: "border-box",
            height: "calc(100% - 10px)",
            boxShadow: "0 3px 6px #00000038",
            m: "0 auto",
            overflow: "hidden",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              right: "5px",
              top: "5px",
              // display: "flex",
              borderRadius: "50px",
              border: "1px solid #eee",
              bgcolor: "#fff",
              px: "5px",
              boxShadow: "0px 3px 6px #00000020",
              zIndex: 100,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <input
                type="range"
                min="0"
                max="100"
                value={soundVolume * 200}
                onChange={handleSoundVolume}
                style={{
                  accentColor: "#c26974",
                }}
              />
              <IconButton size="small" onClick={handleMuteSound}>
                {soundVolume === 0 ? (
                  <VolumeOffIcon sx={{ fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)" }} />
                ) : (
                  <VolumeUpIcon sx={{ fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)" }} />
                )}
              </IconButton>
            </Box>
            {BGM.paused && (
              <Typography sx={{ px: "10px", color: "#444", fontWeight: "500" }}>
                소리가 나지 않는다면?
                <span
                  style={{ cursor: "pointer", textDecoration: "underline", color: "#c33c3c" }}
                  onClick={playBGM}
                >
                  클릭하여 재생하기
                </span>
              </Typography>
            )}
          </Box>

          <Box
            ref={gameScreenRef}
            sx={{
              ...boxStyles,
              position: "relative",
              // bgcolor: "#ff000050",
              aspectRatio: "16 / 9",
              backgroundImage: `url(${maxProgoessIsOver75 ? backgroundLast : background})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* {console.log(isTeamProgessOver75)} */}
            <Grid
              container
              sx={{
                position: "absolute",
                // bgcolor: "#FF000050",
                pt: `${3.7 * gameScreenWidth}px`,
                zIndex: 3,
              }}
            >
              {tap?.teamData?.map((team, idx) => (
                <Grid item xs={3} key={team.id}>
                  <Box
                    sx={{
                      // bgcolor: "#ffff0050",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        border: `${gameScreenWidth * 0.35}px solid #D86ECC`,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: firstInRunning === idx ? "#ff0" : "#fff",
                        color: "#D86ECC",
                        zIndex: 1,
                        font: `700 ${gameScreenWidth * 3.5}px MaplestoryOTFBold`,
                        width: `${gameScreenWidth * 5}px`,
                        aspectRatio: "1/1",
                      }}
                    >
                      {idx + 1}
                    </Box>
                    <Box
                      sx={{
                        border: `${gameScreenWidth * 0.35}px solid #D86ECC`,
                        borderRadius: `${0.74 * gameScreenWidth}px`,
                        bgcolor: "#fff",
                        width: "70%",
                        height: `${2.96 * gameScreenWidth}px`,
                        boxSizing: "border-box",
                        transform: `translateX(-${0.74 * gameScreenWidth}px)`,
                        zIndex: 0,
                        p: `${0.37 * gameScreenWidth}px`,
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: "#78206E",
                          width: `${progess ? progess[idx] * 100 : 0}%`,
                          height: "100%",
                          borderRadius: `0 ${0.37 * gameScreenWidth}px ${
                            0.37 * gameScreenWidth
                          }px 0`,
                          transition: "0.2s linear",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>

            {!maxProgoessIsOver75 &&
              tap?.teamData?.map((team, idx) => (
                <Box
                  key={team.id}
                  sx={{
                    position: "absolute",
                    width: maxProgessIsOver50 ? characterPosition[idx].width || "20%" : "10%",
                    top: characterPosition[idx].top,
                    left: characterPosition[idx].left,
                    zIndex: characterPosition[idx].zIndex || 1,
                  }}
                >
                  <Typography
                    sx={{
                      position: "absolute",
                      top: "-1%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      font: `700 ${1.8 * gameScreenWidth}px MapleStoryOTFBold`,
                      color: "#333",
                      WebkitTextStroke: `${0.07 * gameScreenWidth}px #fff`,
                    }}
                  >
                    {idx + 1}팀
                  </Typography>

                  <img src={characters[idx]} />
                </Box>
              ))}

            {maxProgoessIsOver75 &&
              tap?.teamData?.map((team, idx) => (
                <Box
                  key={team.id}
                  sx={{
                    position: "absolute",
                    width: characterPosition[idx]?.width,
                    top: characterPosition[idx]?.top,
                    left: characterPosition[idx]?.left,
                    zIndex: characterPosition[idx]?.zIndex || 1,

                    // display: idx === 3 ? "block" : "none",

                    // width: "35%",
                    // top: "30%",
                    // left: "52%",
                    // zIndex: 3,
                  }}
                >
                  <Typography
                    sx={{
                      position: "absolute",
                      top: "-1%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      font: `700 ${1.8 * gameScreenWidth}px MapleStoryOTFBold`,
                      color: "#333",
                      WebkitTextStroke: `${0.07 * gameScreenWidth}px #fff`,
                    }}
                  >
                    {idx + 1}팀
                  </Typography>

                  <img src={characters[idx]} />
                </Box>
              ))}

            {timer !== 0 && !tap.isGameStart && (
              <Box
                sx={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  zIndex: 2,
                  backgroundColor: "rgba(64, 64, 64, 0.8)",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    font: `700 ${3.26 * gameScreenWidth}px Pretendard`,
                    color: "#fff",
                    mb: `${2.96 * gameScreenWidth}px`,
                  }}
                >
                  열심히 달릴 준비가 되셨나요?
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: `${1.48 * gameScreenWidth}px`,
                    filter: "drop-shadow(0px 3px 6px #00000038)",
                  }}
                >
                  <TimerIcon sx={{ fontSize: `${3.26 * gameScreenWidth}px`, color: "#fff" }} />

                  <Typography
                    sx={{ font: `700 ${3.26 * gameScreenWidth}px Pretendard`, color: "#fff" }}
                  >
                    {timer}
                  </Typography>
                </Box>

                <Button
                  onClick={handleStartGame}
                  disabled={tap.isGameStart || gameEnded || resetTriggered} // 게임이 이미 시작되었거나 종료된 경우 또는 리셋된 경우 버튼 비활성화
                  sx={{
                    position: "absolute",
                    bottom: "5%",
                    font: `700 ${1.93 * gameScreenWidth}px Pretendard`,
                    padding: `${0.74 * gameScreenWidth}px ${1.48 * gameScreenWidth}px`,
                    bgcolor: tap.isGameStart || gameEnded || resetTriggered ? "#A9A9A9" : "#d6e2fb",
                    color: "#50586C",
                    "&:hover": {
                      bgcolor:
                        tap.isGameStart || gameEnded || resetTriggered ? "#A9A9A9" : "#c6d2eb",
                    },
                  }}
                >
                  게임 시작하기
                </Button>
              </Box>
            )}

            {tap.isGameStart && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 2,
                }}
              >
                <img src={focusEffect} alt="focusEffect" style={{ width: "100%" }} />
              </Box>
            )}

            {tap.isGameStart && !tap.isGamePaused && (
              <Button
                onClick={handleStopGame}
                disabled={gameEnded} // gameEnded가 true이면 버튼 비활성화
                sx={{
                  position: "absolute",
                  bottom: "3%",
                  left: "50%",
                  zIndex: 7,
                  transform: "translateX(-50%)",
                  font: `700 ${1.93 * gameScreenWidth}px Pretendard`,
                  padding: `${0.74 * gameScreenWidth}px ${1.48 * gameScreenWidth}px`,
                  bgcolor: gameEnded ? "#A9A9A9" : "#B43F3F",
                  color: "white",
                  "&:hover": { bgcolor: gameEnded ? "#A9A9A9" : "#9C3636" },
                }}
              >
                정지하기
              </Button>
            )}

            {isGameFinished && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  bgcolor: "#00000088",
                  backdropFilter: "blur(8px)",
                  zIndex: 10,
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    // bgcolor: "#fff",
                    // padding: "20px",
                    color: "#f00",
                    WebkitTextStroke: `${0.14 * gameScreenWidth}px white`,
                    font: `700 ${5.92 * gameScreenWidth}px MaplestoryOTFBold`,
                    // fontSize: "80px",
                    // fontSize: "clamp(25px, 8.11vw, 36px)",
                  }}
                >
                  {/* {winnerTeamIdx !== null && (
                    <img src={Character_Goal[winnerTeamIdx]} style={{ width: `${570}px` }} />
                  )} */}
                  {winnerTeamIdx !== null && winnerTeamIdx !== -1 && preloadedImages !== null && (
                    <img src={preloadedImages[winnerTeamIdx].src} style={{ width: `${570}px` }} />
                  )}
                  {(winnerTeamIdx === null || winnerTeamIdx === -1) && "게임 종료!"}
                </Box>

                <Button
                  onClick={handleViewWinner}
                  sx={{
                    position: "absolute",
                    bottom: "3%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    font: `700 ${1.93 * gameScreenWidth}px Pretendard`,
                    bgcolor: "#4caf50",
                    p: `${0.74 * gameScreenWidth}px ${1.48 * gameScreenWidth}px`,
                    color: "white",
                    "&:hover": { bgcolor: "#388e3c" },
                  }}
                >
                  결과보기
                </Button>
              </Box>
            )}
          </Box>
          {/* <Box
            sx={{
              height: "100%",
              overflow: "hidden",
              display: "flex",
              position: "relative",
              width: "100%",
            }}
          >
            <Box
              sx={{
                borderRight: "1px solid #cdcdcd",
                position: "relative",
                transition: "1s",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  marginTop: "20px",
                  border: "1px solid #cdcdcd",
                  width: "85%",
                  height: "70%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {resetTriggered ? (
                  <Typography sx={{ fontSize: "24px", fontWeight: "bold", color: "blue" }}>
                    게임이 리셋되었습니다. 다시 시작해 주세요.
                  </Typography>
                ) : gameEnded ? (
                  <Typography sx={{ fontSize: "24px", fontWeight: "bold", color: "red" }}>
                    게임이 종료되었습니다
                  </Typography>
                ) : (
                  <>
                    {tap?.individualData?.length > 0 && (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {tap.individualData.map((user) => (
                          <Typography key={user.uid} sx={{ fontSize: "20px", mb: 1 }}>
                            {user.name}: {user.tapCount} taps
                          </Typography>
                        ))}
                      </Box>
                    )}
                    {tap?.teamData?.length > 0 && (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {tap.teamData.map((team) => (
                          <Box key={team.id} sx={{ mb: 2 }}>
                            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                              {team.teamName}
                            </Typography>
                            {team.teamMembers.map((member) => (
                              <Typography key={member.uid} sx={{ fontSize: "16px" }}>
                                {member.name}: {member.tapCount} taps
                              </Typography>
                            ))}
                          </Box>
                        ))}
                      </Box>
                    )}
                  </>
                )}
              </Box>

              <Box
                sx={{
                  width: "85%",
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <Typography>유저가 다 들어오지 못했다면&nbsp;</Typography>
                <Typography
                  onClick={handleBack}
                  sx={{
                    textDecoration: "underline",
                    color: "#B43F3F",
                    cursor: "pointer",
                  }}
                >
                  대기창으로 이동하기
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "85%",
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "3px",
                }}
              >
                <Typography
                  onClick={handleResetGame}
                  sx={{
                    textDecoration: "underline",
                    color: "#B43F3F",
                    cursor: "pointer",
                  }}
                >
                  게임 리셋하기
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  width: "85%",
                  flexGrow: 1,
                  gap: "10%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isGameFinished ? (
                  <Button
                    onClick={handleViewWinner}
                    sx={{
                      font: "700 26px Pretendard",
                      fontSize: "clamp(16.25px, 1.354vw, 22px)",
                      borderRadius: "50%",
                      width: "clamp(75px, 6.25vw, 110px)",
                      height: "clamp(75px, 6.25vw, 110px)",
                      bgcolor: "#4caf50",
                      color: "white",
                      "&:hover": { bgcolor: "#388e3c" },
                    }}
                  >
                    결과보기
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={handleStopGame}
                      disabled={gameEnded} // gameEnded가 true이면 버튼 비활성화
                      sx={{
                        font: "700 26px Pretendard",
                        fontSize: "clamp(16.25px, 1.354vw, 22px)",
                        borderRadius: "50%",
                        width: "clamp(75px, 6.25vw, 110px)",
                        height: "clamp(75px, 6.25vw, 110px)",
                        bgcolor: gameEnded ? "#A9A9A9" : "#B43F3F",
                        color: "white",
                        "&:hover": { bgcolor: gameEnded ? "#A9A9A9" : "#9C3636" },
                      }}
                    >
                      정지하기
                    </Button>
                    <Button
                      onClick={handleStartGame}
                      disabled={tap.isGameStart || gameEnded || resetTriggered} // 게임이 이미 시작되었거나 종료된 경우 또는 리셋된 경우 버튼 비활성화
                      sx={{
                        font: "700 26px Pretendard",
                        fontSize: "clamp(16.25px, 1.354vw, 22px)",
                        borderRadius: "50%",
                        width: "clamp(75px, 6.25vw, 110px)",
                        height: "clamp(75px, 6.25vw, 110px)",
                        bgcolor:
                          tap.isGameStart || gameEnded || resetTriggered ? "#A9A9A9" : "#d6e2fb",
                        color: "#50586C",
                        "&:hover": {
                          bgcolor:
                            tap.isGameStart || gameEnded || resetTriggered ? "#A9A9A9" : "#c6d2eb",
                        },
                      }}
                    >
                      시작하기
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </Box> */}
        </Box>
      </FullScreen>
    </Box>
  );
};

export default GameScreen;
