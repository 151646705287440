import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LoadingModal } from "components/Modal";
import { API_GET_DECODED } from "utils/api";

export default () => {
  const navigate = useNavigate();
  const code = new URLSearchParams(window.location.search).get("code");

  useEffect(() => {
    API_GET_DECODED(code).then((res) => {
      const { success, decoded } = res.data;

      // console.log(data);
      navigate(`/payment/${decoded.redirection}`, { state: decoded.state });
    });
    //   const { token, user } = data;
    //   localStorage
    // console.log(params.get("code"));
  }, []);

  return <LoadingModal open />;
};
