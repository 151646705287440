import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Typography, Button } from "@mui/material";

import { Textfield } from "components/Textfield";
import { UserContext } from "views/LiveBoard/contexts/User";

const GroupHeader = ({ section }) => {
  const { socket } = useContext(UserContext);
  const { gameId } = useParams();

  const [isClicked, setClicked] = useState(false);
  const [sectionName, setSectionName] = useState("");

  const changeSectionName = () => {
    if (sectionName !== section.name) {
      socket.emit("changeSectionName", { gameId, sectionId: section.id, name: sectionName });
    }

    setClicked(false);
  };

  useEffect(() => {
    setSectionName(section.name);
  }, [section]);

  return (
    <Box
      sx={{
        p: "10px",
        bgcolor: "#c33c3c",
        borderRadius: "8px",
        borderBottomLeftRadius: "0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {isClicked ? (
        <Textfield
          value={sectionName}
          onChange={(e) => setSectionName(e.target.value)}
          onBlur={changeSectionName}
          onKeyPress={(e) => e.key === "Enter" && changeSectionName()}
          InputProps={{
            endAdornment: <Button sx={{ fontWeight: 600 }}>완료</Button>,
          }}
          onFocus={(e) => e.target.select()}
          autoFocus
          fullWidth
          size="small"
        />
      ) : (
        <Typography
          noWrap
          sx={{
            color: "#fff",
            font: "700 20px Pretendard",
            flex: 1,
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() => setClicked(true)}
        >
          {sectionName}
        </Typography>
      )}
    </Box>
  );
};

export default GroupHeader;
