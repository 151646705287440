import { styled } from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";

import { Box, Button, Grid, MenuItem, Select, TextField } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const Body = styled.div`
  min-height: 100vh;
  padding-top: 80px;
  background-color: #f5f5f5;
  box-sizing: border-box;
  overflow: auto;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding: 0 20px;
`;

export const BackButton = MuiStyled((props) => <Button {...props} />)({
  display: "flex",
  gap: "5px",

  font: "bold 30px NotoSansKR-Regular",
  color: "black",

  "&:hover": {
    backgroundColor: "transparent",
    transform: "scale(1.1)",
  },

  transition: "all 0.3s ease",
});

export const GoBackIcon = MuiStyled((props) => <ArrowBackIcon {...props} />)({
  fontSize: "30px",
  fontWeight: "bold",
  color: "black",
});

export const Title = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  font: bold 30px Pretendard;
`;

export const Blank = styled.div`
  width: 1px;
  height: 1px;
`;

export const SubTitle = styled.div`
  margin-top: 10px;
  font: 16px Pretendard-Regular;
  text-align: center;
  color: #c33c3c;
  line-height: 1.2;
`;

export const Content = MuiStyled((props) => <Grid container {...props} />)({
  padding: "0 40px",
  paddingTop: "20px",
  boxSizing: "border-box",
  width: "70%",
  margin: "0 auto",
  marginBottom: "20px",

  "@media (max-width: 1535px)": {
    width: "90%",
  },
});

export const UserInfoGrid = MuiStyled((props) => <Grid item xs={8} {...props} />)({
  paddingRight: "10px",
});

export const Paper = styled.div`
  display: flex;
  ${(props) => props["data-id"] === "userInfo" && "height: 100%;"}
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #ababab;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  box-sizing: border-box;

  & + & {
    margin-top: 20px;
  }

  ${(props) =>
    props["data-id"] === "coupon" && "&:hover { cursor: pointer; background-color: #F5F5F5;}"}
`;

export const PaperTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 5px;
  font: 24px Pretendard-Medium;

  > p {
    font: 16px Pretendard-Medium;
    color: #c33c3c;
  }
`;

export const InputContainer = MuiStyled((props) => <Grid container {...props} />)({
  marginTop: "20px",
  paddingLeft: "20px",
  flex: 1,
});

export const InputGrid = MuiStyled((props) => <Grid item xs={12} {...props} />)({
  display: "flex",
  alignItems: "center",
  marginBottom: "20px",

  "& > .title": {
    width: "20%",
    display: "flex",
    gap: "5px",
    alignItems: "center",
    font: "20px Pretendard-Medium",
    "& > p": {
      font: "16px Pretendard-Medium",
      color: "#c33c3c",
    },
  },
});

export const Input = MuiStyled((props) => (
  <TextField
    fullWidth
    InputProps={{
      style: {
        fontSize: "16px",
        fontFamily: "Pretendard-Medium",
        backgroundColor: "#F5F5F5",
        borderRadius: "10px",
      },
    }}
    placeholder={props.target === "phone" ? "- 없이 입력 (예 : 01012345678)" : props.placeholder}
    type={props.target === "phone" ? "tel" : "text"}
    {...props}
  />
))({});

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  // margin-top: 20px;
  // margin-bottom: 20px;
  // padding-left: 20px;
  // padding-right: 20px;

  font: 500 15px Pretendard;
`;

export const PaymentGrid = MuiStyled((props) => <Grid item xs={4} {...props} />)({
  paddingLeft: "10px",
});

export const PlanTitle = styled.div`
  font: 500 24px Pretendard;
  border-bottom: 1px solid #ababab;
`;

export const PlanContent = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div.title {
    font: 500 18px Pretendard;
    color: #888888;
  }

  > div div.plan {
    font: bold 18px Pretendard;
    color: #000000;
    text-align: right;
  }

  > div.day {
    font: 18px Pretendard;
    color: #c33c3c;
  }

  > div.price {
    font: bold 18px Pretendard;
    color: #000000;
  }
`;

export const SelectBox = MuiStyled((props) => (
  <Select size="small" MenuProps={{ disableScrollLock: true }} {...props} />
))({
  backgroundColor: "#F5F5F5",
  font: "500 18px Pretendard",
  color: "#888888",
});

export const SelectItem = MuiStyled((props) => <MenuItem {...props} />)({
  font: "500 18px Pretendard",
  color: "#555555",
});

export const CouponBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const PaymentButton = MuiStyled((props) => <Button variant="contained" {...props} />)({
  marginTop: "20px",
  marginBottom: "20px",

  borderRadius: "10px",
  backgroundColor: "#3182F6",

  font: "bold 20px Pretendard",

  "&:hover": {
    backgroundColor: "#1162D6",
  },

  "&:disabled": {
    backgroundColor: "#ababab",
    color: "#cdcdcd",
  },
});
