import * as S from "./styles";
import { Typography, Box, LinearProgress, Radio, ButtonBase } from "@mui/material";
import { useState, useContext } from "react";
import { QuestionList } from "./question";
import { useMediaQuery } from "react-responsive";
import { QuestionListMobile } from "./questionMobile";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { POST_USER_RESULT, POST_DECREMENT_HEXAGRAM_TOKEN } from "utils/api/Hexagram";
import { UserContext } from "../contexts/UserContext";

const UserQuestion = () => {
  const [currentStep, setCurrentStep] = useState(1); // 현재 몇번째 문제인지 초기값은 1번째 문제
  const [selectedValues, setSelectedValues] = useState(Array(QuestionList.length).fill("")); // 각 질문에 대한 선택지를 저장
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // 현재 질문의 인덱스 값

  const navigate = useNavigate();
  const { gameId } = useParams(); // URL에서 gameId 가져오기
  const [cookies] = useCookies(["uid"]); // 쿠키에서 uid 가져오기

  const totalSteps = QuestionList.length; // 총 문제의 갯수
  const progress = (currentStep / totalSteps) * 100; // 문제 푼 정도를 나타내는 퍼센트

  const { slotName } = useContext(UserContext);

  // 사용자가 선택지를 선택했을 때, 변경했을 때 실행되는 함수
  const handleRadioChange = (event, index) => {
    const newSelectedValues = [...selectedValues];
    newSelectedValues[currentQuestionIndex] = index;
    setSelectedValues(newSelectedValues);
  };

  // 다음 버튼을 눌렀을 때 실행되는 함수
  const handleNextClick = () => {
    if (currentQuestionIndex < QuestionList.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setCurrentStep(currentStep + 1);
      console.log(selectedValues);
    } else {
      // 설문을 끝까지 했을 때 실행되는 함수
      const results = Array(6).fill(0); // 6단계 결과를 저장할 배열 초기화

      selectedValues.forEach((value, index) => {
        if (value !== "") {
          const remainder = index % 6;
          let resultValue = value + 1; // 결과 값에 1을 더해서 1~7점으로 변환

          // 특정 문항에 대해 1.5배를 곱함
          const multiplierIndices = [8, 9, 14, 19, 23, 24, 36, 37, 40, 41, 57, 58];
          if (multiplierIndices.includes(index + 1)) {
            resultValue *= 1.5;
          }

          results[remainder] += resultValue;
        }
      });

      // 최종 결과를 소수점 이하 반올림
      const roundedResults = results.map((result) => Math.round(result));

      const userId = cookies.uid; // 쿠키에서 유저 아이디 가져오기
      console.log(roundedResults); // 반올림된 결과를 콘솔에 출력
      // 서버에 결과 전송
      POST_USER_RESULT(gameId, userId, roundedResults)
        .then((response) => {
          // 서버 응답 처리
          console.log(response.data);

          // 토큰 감소 API 호출
          POST_DECREMENT_HEXAGRAM_TOKEN(gameId)
            .then((response) => {
              console.log("토큰 감소 성공:", response.data);
              // 페이지 이동
              navigate(`/hexagram/user/${gameId}/result/${roundedResults.join(",")}/1`);
            })
            .catch((error) => {
              console.error("토큰 감소 에러:", error);
            });
        })
        .catch((error) => {
          console.error("결과 전송 에러:", error);
        });
    }
  };

  // 이전 질문으로 이동하는 함수
  const handlePreviousClick = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setCurrentStep(currentStep - 1);
    }
  };

  // 다음 버튼이 비활성화 되는 조건 (선택지를 선택하지 않았을 때)
  const isNextButtonDisabled = selectedValues[currentQuestionIndex] === "";

  // 모바일임을 확인하는 함수
  const isMobile = useMediaQuery({
    query: "(max-width:412px)",
  });

  return (
    <S.UserQuestion>
      {/* 타이틀 */}
      <Box sx={{ alignSelf: "flex-start", width: "100%" }}>
        <Typography sx={{ font: "bold 20px Pretendard", mb: "24px" }}>{slotName}</Typography>
      </Box>
      {/* 문제 게이지 바 */}
      <Box sx={{ display: "flex", alignItems: "center", mb: "16px", width: "98%" }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            flexGrow: 1,
            height: "20px",
            borderRadius: "10px",
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#c33c3c",
              borderRadius: "10px",
            },
          }}
        />
        <Typography sx={{ ml: "12px", font: "bold 18px Pretendard" }}>
          {currentStep} / {totalSteps}
        </Typography>
      </Box>
      {/* 질문 */}
      <Box
        sx={{
          width: "100%",
          height: "auto",
          padding: "10px",
          border: "1px solid #EEEEEE",
          borderRadius: "10px",
          boxSizing: "border-box",
        }}
      >
        <Typography sx={{ font: "bold 18px Pretendard", mb: "4px" }}>
          질문 {currentStep}.
        </Typography>
        <Typography sx={{ font: "bold 24px Pretendard", whiteSpace: "pre-line" }}>
          {isMobile
            ? QuestionListMobile[currentQuestionIndex].q
            : QuestionList[currentQuestionIndex].q}
        </Typography>
      </Box>
      {/* 선택지 */}
      <Box
        sx={{
          width: "100%",
          mt: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* 라디오 버튼들 */}
        <Box
          sx={{
            width: "92%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "10px",
          }}
        >
          {[
            { size: "42px", color: "#778bd6" }, // 동의하지 않음 (큰 원)
            { size: "34px", color: "#778bd6" },
            { size: "30px", color: "#778bd6" },
            { size: "28px", color: "#CCCCCC" }, // 중립
            { size: "30px", color: "#95db67" },
            { size: "34px", color: "#95db67" },
            { size: "42px", color: "#95db67" }, // 동의함 (큰 원)
          ].map((item, index) => (
            <ButtonBase
              key={index}
              onClick={() => handleRadioChange(null, index)}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                width: item.size,
                height: item.size,
                borderRadius: "50%",
              }}
            >
              <Radio
                checked={selectedValues[currentQuestionIndex] === index}
                onChange={(event) => handleRadioChange(event, index)}
                value={index}
                sx={{
                  padding: 0,
                  color: item.color,
                  "&.Mui-checked": {
                    color: item.color,
                  },
                  "& .MuiSvgIcon-root": {
                    fontSize: item.size,
                    borderRadius: "50%",
                    border: `2px solid ${item.color}`,
                    backgroundColor:
                      selectedValues[currentQuestionIndex] === index ? item.color : "transparent",
                  },
                }}
                icon={
                  <span
                    style={{
                      display: "block",
                      width: item.size,
                      height: item.size,
                      borderRadius: "50%",
                      border: `2px solid ${item.color}`,
                    }}
                  />
                }
                checkedIcon={
                  <span
                    style={{
                      display: "block",
                      width: item.size,
                      height: item.size,
                      borderRadius: "50%",
                      backgroundColor: item.color,
                    }}
                  />
                }
              />
            </ButtonBase>
          ))}
        </Box>
        <Box
          sx={{
            width: "100%",
            marginTop: "10px",
            paddingRight: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ font: "bold 16px Pretendard", color: "#778bd6", paddingLeft: "6px" }}>
            매우 아니다
          </Typography>
          <Typography sx={{ font: "bold 16px Pretendard", color: "#95db67" }}>
            매우 그렇다
          </Typography>
        </Box>
      </Box>
      {/* 버튼 */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: "30px",
          width: "80%",
        }}
      >
        {/* 이전 버튼 */}
        <Box
          onClick={handlePreviousClick}
          sx={{
            font: "600 16px Pretendard",
            width: "30%",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#d3d3d3", // 이전 버튼 배경색
            borderRadius: "10px",
            color: "#fff",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#c0c0c0", // hover 시 배경색
            },
            "&:active": {
              backgroundColor: "#a9a9a9", // active 시 배경색
              transform: "translateY(1px)", // active 시 버튼 눌림 효과
            },
          }}
        >
          이전
        </Box>
        {/* 다음 버튼 */}
        <Box
          onClick={!isNextButtonDisabled ? handleNextClick : undefined}
          sx={{
            font: "600 16px Pretendard",
            width: "30%",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: isNextButtonDisabled ? "#e0e0e0" : "#c33c3c", // 다음 버튼 배경색
            borderRadius: "10px",
            color: "#fff",
            cursor: isNextButtonDisabled ? "not-allowed" : "pointer",
            "&:hover": {
              backgroundColor: isNextButtonDisabled ? "#e0e0e0" : "#a32e2e", // hover 시 배경색
            },
            "&:active": {
              backgroundColor: isNextButtonDisabled ? "#e0e0e0" : "#892525", // active 시 배경색
              transform: isNextButtonDisabled ? "none" : "translateY(1px)", // active 시 버튼 눌림 효과
            },
          }}
        >
          {currentQuestionIndex === QuestionList.length - 1 ? "결과보기" : "다음"}
        </Box>
      </Box>
    </S.UserQuestion>
  );
};

export default UserQuestion;
