import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./style.css";

import { Box, Button, Typography } from "@mui/material";

import pollLogo from "assets/images/LiveLandingPageImg/LandingPoll.png";
import quizLogo from "assets/images/LiveLandingPageImg/LandingQuiz.png";
import pickLogo from "assets/images/LiveLandingPageImg/LandingPick.png";
import dealLogo from "assets/images/LiveLandingPageImg/LandingDeal.png";
import egogramLogo from "assets/images/LiveLandingPageImg/LandingEgogram.png";
import boardLogo from "assets/images/LiveLandingPageImg/LandingBoard.png";
import hexagramLogo from "assets/images/LiveLandingPageImg/LandingHexagram.png";
import tapLogo from "assets/images/LiveLandingPageImg/LandingTap.png";

function Program({ name, duedate, daysleft }) {
  const navigate = useNavigate(); // * react-router-dom의 navigate 사용
  const depth = window.location.href.split("/"); // * 현재 url을 /로 split하여 depth에 저장
  // * program 이름에 따라서 설정
  const [id, setId] = useState(""); // * 프로그램 id
  const [logo, setLogo] = useState(null); // * 프로그램 로고 이미지
  const [koreanName, setKoreanName] = useState(""); // * 프로그램 한글 이름

  // * 프로그램 클릭 시 이동
  const goTo = (e) => {
    if (e.currentTarget.id === "pick") {
      window.location.href = `${depth[0]}//${depth[2]}/pick/list`;
    } else if (e.currentTarget.id === "deal") {
      window.location.href = `${depth[0]}//${depth[2]}/deal/list`;
    } else if (e.currentTarget.id === "egogram") {
      window.location.href = `${depth[0]}//${depth[2]}/egogram/list`;
    } else if (e.currentTarget.id === "poll") {
      navigate("/polling/list");
    } else if (e.currentTarget.id === "board") {
      navigate("/board/list");
    } else if (e.currentTarget.id === "hexagram") {
      navigate("/hexagram/list");
    } else if (e.currentTarget.id === "tap") {
      navigate("/tap/list");
    } else {
      navigate(`/features/${e.currentTarget.id}`);
    }
  };

  // * 프로그램 이름에 따라서 설정
  useEffect(() => {
    switch (name) {
      case "Live Polling":
        setId("poll");
        setLogo(pollLogo);
        setKoreanName("라이브 폴링");
        break;
      case "Live Quiz":
        setId("quiz");
        setLogo(quizLogo);
        setKoreanName("라이브 퀴즈");
        break;
      case "Live Pick":
        setId("pick");
        setLogo(pickLogo);
        setKoreanName("라이브 픽");
        break;
      case "Live Deal":
        setId("deal");
        setLogo(dealLogo);
        setKoreanName("라이브 딜");
        break;
      case "Egogram":
        setId("egogram");
        setLogo(egogramLogo);
        setKoreanName("라이브 에고그램");
        break;
      case "Live Board":
        setId("board");
        setLogo(boardLogo);
        setKoreanName("라이브 보드");
        break;
      case "Hexagram":
        setId("hexagram");
        setLogo(hexagramLogo);
        setKoreanName("라이브 핵사그램");
        break;
      case "Live Tap":
        setId("tap");
        setLogo(tapLogo);
        setKoreanName("라이브 탭");
      default:
        break;
    }
  }, []);

  return (
    <Box
      sx={{
        cursor: "pointer",
        transition: "scale 0.5s ease-in-out",
        "&:hover": { scale: "1.1" },
        // width: "30%",
      }}
      id={id}
      onClick={goTo}
    >
      <Box sx={{ "&:active": { scale: "0.9" }, width: "clamp(93.3px, 7.29vw, 140px)" }}>
        <img
          src={logo}
          style={{ width: "100%", filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))" }}
        />
      </Box>

      <Typography
        sx={{
          font: "bold 16px Pretendard",
          fontSize: "clamp(8px, 1.14vw, 22px)",
          wordBreak: "keep-all",
          // bgcolor: "#fff",
        }}
      >
        {koreanName}
      </Typography>
    </Box>
  );
}

function RecentProgram({ name, date, users }) {
  const navigate = useNavigate();

  const goTo = () => {
    switch (name) {
      case "Live Polling":
        navigate("/features/poll");
        break;

      case "Live Quiz":
        navigate("/features/poll");
        break;

      default:
        break;
    }
  };
  return (
    <div className="RecentProgram">
      <div className="date">
        <Typography fontSize="1.5rem" fontFamily="Roboto-Black">
          {date[0]}
        </Typography>
        <Typography color="#797979" fontFamily="Noto Sans">
          {date[1]}
        </Typography>
      </div>
      <div className="date-user">
        <Typography fontFamily="Noto Sans">{name}</Typography>
        <Typography fontFamily="Noto Sans" fontSize="0.9rem" color="#797979">
          {users}
        </Typography>
      </div>
      <div style={{ width: "30%", textAlign: "right" }}>
        <Button variant="contained" onClick={goTo}>
          result
        </Button>
      </div>
    </div>
  );
}

export { Program, RecentProgram };
