// * npm modules
import React, { useContext, useEffect, useState } from "react";
import { QRCode } from "react-qr-svg";

// * material components
import { Box, Grid, Typography } from "@mui/material";

// * styles
import * as S from "./styles";

// * 프로젝트 내 컴포넌트
import { ManagerContext } from "../contexts/ManagerContext"; // * 매니저 컨텍스트
import { QRCodeWideModal } from "components/Modal"; // * QR코드 모달

// * 인트로 컴포넌트
const Intro = (props) => {
  // * 매니저 컨텍스트
  const { playingData, currentSlideIndex, code, qrCodeURL, isQrGridOpened, handleQRCode } =
    useContext(ManagerContext);

  const [currentSlide, setCurrentSlide] = useState({}); // * 현재 슬라이드
  const [QRModalOpened, setQRModalOpened] = useState(false); // * QR코드 모달 오픈 여부

  // * QR코드 모달 핸들러
  const handleQRcodeModal = () => {
    setQRModalOpened(!QRModalOpened); // * QR코드 모달 오픈 여부 토글
  };

  // * 현재 슬라이드 설정
  useEffect(() => {
    setCurrentSlide(playingData[currentSlideIndex]); // * 현재 슬라이드 설정

    // * QR코드 그리드가 열려있을 때 인트로 페이지에서는 닫아준다.
    if (isQrGridOpened) {
      handleQRCode();
    }
  }, [playingData, currentSlideIndex]);

  return (
    <S.Intro>
      <S.IntroTitle sx={{ pt: "10px" }}>{currentSlide.title}</S.IntroTitle>

      <Grid container sx={{ flex: 1 }}>
        {/* 접속코드 입력 접속 가이드 */}
        <S.AccessGuide>
          <Box sx={{ textAlign: "center" }}>
            <Typography sx={{ font: "700 4.5vw Pretendard", mb: "10px" }}>
              www.withplus.live
            </Typography>
            <Typography sx={{ font: "600 32px Pretendard", mb: "20px" }}>에 접속</Typography>
            <Typography sx={{ color: "#c33c3c", font: "700 4.5vw Pretendard", mb: "10px" }}>
              {code.slice(0, 3) + " " + code.slice(3)}
            </Typography>
            <Typography sx={{ font: "600 32px Pretendard" }}>접속 코드 입력</Typography>
          </Box>
        </S.AccessGuide>

        {/* QR코드 */}
        <S.QrGrid>
          <Box onClick={handleQRcodeModal}>
            <QRCode value={qrCodeURL} /> {/* QR코드 */}
          </Box>

          <Typography sx={{ font: "600 2.4vw Pretendard", mt: "20px" }}>
            또는 QR코드를 스캔하여 접속해주세요.
          </Typography>
        </S.QrGrid>
      </Grid>

      {/* QR코드 모달 */}
      <QRCodeWideModal
        open={QRModalOpened}
        handleModal={handleQRcodeModal}
        ModalQRCode={qrCodeURL}
      />
    </S.Intro>
  );
};

export default Intro;
