import React, { createContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";

import { useSocket } from "contexts/Socket";
import { GET_LIVETAP_BY_ID } from "utils/api/Tap";

const UserContext = createContext({
  socket: null,
  currentLiveTap: null,
  teamCount: null,
  name: "",
  team: null,
  tapCount: 0,
  tapDisabled: false,
  showWinner: false,
  teamScore: 0,

  setTapDisabled: () => {},
  times: {},
  setName: () => {},
  setTapCount: () => {},
  getTeamCharacter: () => {},
  getData: () => {},
});

const UserProvider = ({ children }) => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  // const socket = useSocket("http://localhost:3006");
  const socket = useSocket("https://tap.withplus.live");
  // const socket = useSocket("http://172.30.1.69:3006");
  const [cookies, setCookie] = useCookies();

  const [currentLiveTap, setCurrentLiveTap] = useState(null);
  const [teamCount, setTeamCount] = useState(null);
  const [name, setName] = useState("");
  const [team, setTeam] = useState(null);
  const [imgIdx, setImgIdx] = useState(null);
  const [tapCount, setTapCount] = useState(0);
  const [tapDisabled, setTapDisabled] = useState(false);
  const [times, setTimes] = useState({});
  const [showWinner, setShowWinner] = useState(false);
  const [teamScore, setTeamScore] = useState(0);

  const getTeamCharacter = (idx) => {
    switch (idx) {
      case 0:
        return "hippo";
      case 1:
        return "cow";
      case 2:
        return "tiger";
      case 3:
        return "elephant";
    }
  };

  const getData = () => {
    console.log("getData");

    return new Promise((resolve, reject) => {
      GET_LIVETAP_BY_ID(gameId)
        .then((res) => {
          const tap = res.data.liveTap;

          if (tap.gameMode === "team") {
            setTeamCount(tap.teamData.length);
          } else {
            setTeamCount(1);
          }
          setCurrentLiveTap(tap);

          if (tap.gameMode === "team") {
            for (let i = 0; i < tap.teamData.length; i++) {
              const myInfo = tap.teamData[i].teamMembers.find((item) => item.uid === cookies.uid);

              if (myInfo) {
                setName(myInfo.name);
                setTeam(tap.teamData[i]);
                setImgIdx(myInfo.imgIdx);
                setTapCount(myInfo.tapCount);
                setTeamScore(tap.teamData[i].teamScore);
                socket.emit(
                  "joinTeam",
                  { gameId, teamId: tap.teamData[i].id, userId: cookies.uid },
                  (res) => {
                    console.log(res);
                  }
                );

                break;
              }
            }
          } else if (tap.gameMode === "individual") {
            const myInfo = tap.individualData.find((item) => item.uid === cookies.uid);

            if (myInfo) {
              setName(myInfo.name);
              setImgIdx(myInfo.imgIdx);
              setTapCount(myInfo.tapCount);
            }
          }

          if (tap.isGamePaused || tap.isGameEnded) {
            setTapDisabled(true);
          } else {
            setTapDisabled(false);
          }

          setShowWinner(tap.showWinner);
        })
        .catch((err) => {
          console.error(err);
        });

      resolve();
    });
  };

  useEffect(() => {
    // if (!socket) return;
    getData();

    // }, [gameId, cookies.uid, socket]);
  }, []);

  useEffect(() => {
    if (name !== "") return;

    if (sessionStorage.getItem("lveiTapName")) {
      setName(sessionStorage.getItem("lveiTapName"));
    }
  }, [name]);

  useEffect(() => {
    if (!team) return;
    if (team.character) return;

    const newTeam = { ...team };
    const teamIdx = currentLiveTap.teamData.findIndex((t) => t.id === team.id);

    newTeam.teamIdx = teamIdx;

    switch (teamIdx) {
      case 0:
        newTeam.character = "hippo";
        break;
      case 1:
        newTeam.character = "cow";
        break;
      case 2:
        newTeam.character = "tiger";
        break;
      case 3:
        newTeam.character = "elephant";
        break;
    }

    setTeam(newTeam);
  }, [team]);

  // useEffect(() => {
  //   console.log("showWinner", showWinner);
  // }, [showWinner]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connect");
      let userId = cookies.uid;

      if (!userId) {
        console.log("userId", userId);
        userId = uuidv4();

        setCookie("uid", userId, { path: "/" });
      }

      socket.emit("joinRoom", { gameId, userId });
    });

    socket.on("updateUser", (data) => {
      setName(data.name);
      setImgIdx(data.imgIdx);
      setTeam(data.team);
    });

    socket.on("userDeleted", ({ uid }) => {
      if (uid === cookies.uid) {
        navigate(`/tap/user/${gameId}`);
      }
    });

    socket.on("disableTapButton", () => {
      setTapDisabled(true); // Tap 비활성화 처리
    });

    socket.on("saveTime", ({ gameMode, timeData }) => {
      if (gameMode === "team") {
        setCurrentLiveTap((prev) => {
          const updatedTeamData = prev.teamData.map((team) => {
            if (timeData[team.id]) {
              return { ...team, time: timeData[team.id] };
            }
            return team;
          });
          return { ...prev, teamData: updatedTeamData };
        });
      } else if (gameMode === "individual") {
        setCurrentLiveTap((prev) => {
          const updatedIndividualData = prev.individualData.map((member) => {
            if (timeData[member.uid]) {
              return { ...member, time: timeData[member.uid] };
            }
            return member;
          });
          return { ...prev, individualData: updatedIndividualData };
        });
      }
    });

    // socket.on("gameEnded", (res) => {
    //   setTapDisabled(true);
    // });

    socket.on("resetGame", () => {
      alert("진행자가 게임이 리셋했습니다. 초기화면으로 이동합니다.");
      window.location.href = `/tap/user/${gameId}`;
    });

    socket.on("showWinner", (tap) => {
      setCurrentLiveTap(tap);
      setShowWinner(true);
    });

    socket.on("goToRankingPage", ({ tap }) => {
      console.log("goToRankingPage");

      getData().then(() => {
        navigate(`/tap/user/${gameId}/result`);
      });
    });

    socket.on("updatedTap", (tap) => {
      if (tap.gameMode === "team") {
        setTeamCount(tap.teamData.length);
      } else {
        setTeamCount(1);
      }
      setCurrentLiveTap(tap);

      if (tap.gameMode === "team") {
        for (let i = 0; i < tap.teamData.length; i++) {
          const myInfo = tap.teamData[i].teamMembers.find((item) => item.uid === cookies.uid);

          if (myInfo) {
            setName(myInfo.name);
            setTeam(tap.teamData[i]);
            setImgIdx(myInfo.imgIdx);
            setTapCount(myInfo.tapCount);
            setTeamScore(tap.teamData[i].teamScore);

            break;
          }
        }
      }

      if (tap.isGamePaused || tap.isGameEnded) {
        setTapDisabled(true);
      } else {
        setTapDisabled(false);
      }

      setShowWinner(tap.showWinner);
    });

    socket.on("tapUpdated", ({ tap }) => {
      let myTeam = tap.teamData.find((t) => t.id === team.id);
      let totalTapCount = myTeam.teamMembers.reduce((acc, member) => acc + member.tapCount, 0);

      setTapCount(totalTapCount);

      // setCurrentLiveTap(tap);
    });

    return () => {
      socket.off("connect");
      socket.off("updateUser");
      socket.off("userDeleted");
      socket.off("disableTapButton");
      socket.off("saveTime");
      socket.off("gameEnded");
      socket.off("resetGame");
      socket.off("showWinner");
      socket.off("goToRankingPage");
      socket.off("tapUpdated");
      socket.off("updatedTap");
    };
  });

  const value = {
    socket,
    currentLiveTap,
    teamCount,
    name,
    team,
    imgIdx,
    tapCount,
    setName,
    tapDisabled,
    setTapDisabled,
    times,
    setTapCount,
    showWinner,
    getTeamCharacter,
    teamScore,
    getData,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
