import managerImg from "assets/images/ContentPage/LivePick/managerImg.png";
import userImg from "assets/images/ContentPage/LivePick/userImg.png";
import icon1 from "assets/images/ContentPage/LivePick/icon1.png";
import icon2 from "assets/images/ContentPage/LivePick/icon2.png";
import icon3 from "assets/images/ContentPage/LivePick/icon3.png";
import icon4 from "assets/images/ContentPage/LivePick/icon4.png";
//
// import wordCloudIcon from "assets/images/ContentPage/LivePolling/wordCloudIcon.png";
// import choiceIcon from "assets/images/ContentPage/LivePolling/choiceIcon.png";
// import barIcon from "assets/images/ContentPage/LivePolling/barIcon.png";
// import circleIcon from "assets/images/ContentPage/LivePolling/circleIcon.png";
// import donutIcon from "assets/images/ContentPage/LivePolling/donutIcon.png";

export const benefitData = [
  {
    id: 1,
    title: "아이스브레이킹",
    description: ["팀전 또는 개인전을 통해", "참여자 간의 유대감 형성"],
    img: icon1,
  },
  {
    id: 2,
    title: "분석력",
    description: ["상대의 패를 간파하고", "차별화 전략 수립"],
    img: icon2,
  },
  {
    id: 3,
    title: "판단력",
    description: ["의사결정이 가져올", "득과 실을 빠르게 판단"],
    img: icon3,
  },
  {
    id: 4,
    title: "의사소통 능력",
    description: ["빠른 의사결정을 위해", "팀원 간의 소통 촉진"],
    img: icon4,
  },
];

export const connectionScreenData = [
  {
    id: "manager",
    title: "진행자 화면",
    explain: [
      "PC, 노트북 환경에서 진행됩니다.",
      "게임 중 게임보드스크린의 역할을 하며, 이는 참여들 모두에게 공유됩니다",
    ],
    img: managerImg,
    button: "메뉴얼 다운받기",
    explains: [
      {
        id: 1,
        title: "Withplus.Live 접속",
        description: ["위드플러스 라이브에 접속 후", "로그인 - [마이페이지] - [Live Pick]"],
      },
      {
        id: 2,
        title: "라이브픽 게임 생성",
        description: ["제목 입력 및 옵션 설정"],
      },
      {
        id: 3,
        title: "QR코드 배포",
        description: ["QR코드와 접속코드를 ", "학습자에게 배포,스캔 요청"],
      },
      {
        id: 4,
        title: "게임 운영",
        description: ["게임 진행 현황 및 결과를", "실시간으로 공유"],
      },
    ],
  },
  {
    id: "user",
    title: "참여자 화면",
    explain: ["모바일, PC, 태블릿PC 환경에서 진행됩니다.", "게임 중 컨트롤러의 역할을 수행합니다."],
    img: userImg,
    button: "메뉴얼 다운받기",
    explains: [
      {
        id: 1,
        title: "QR 접속",
        description: ["진행자가 배포한 QR코드 스캔", "또는 접속코드 입력"],
      },
      {
        id: 2,
        title: "게임 입장",
        description: ["팀 선택 후 게임 입장"],
      },
      {
        id: 3,
        title: "게임 참여",
        description: ["제한 시간 안에 의사결정을", "수행하며 게임 진행"],
      },
      {
        id: 4,
        title: "결과 확인 (진행자 화면)",
        description: ["최종 순위 및 점수"],
      },
    ],
  },
];

// export const typeDescriptionData = [
//   {
//     id: 1,
//     title: "Word Cloud",
//     description: [
//       "- 참여자들의 주관식 응답을 실시간으로 수집",
//       "- 질문에 대한 짧은 응답이 시각적으로 구성",
//       "- 참여자들의 자유로운 의견 표출 용도로 적합",
//     ],
//     img: wordCloudIcon,
//   },
//   {
//     id: 2,
//     title: "Choice",
//     description: [
//       "- 주어진 보기 중 응답을 선택하는 객관식 질문",
//       "- 3가지 유형의 결과 레이아웃 제공",
//       "- 분석이 수월한 결과 데이터 제공",
//     ],
//     img: choiceIcon,
//   },
// ];

// export const choiceData = [
//   {
//     id: 1,
//     title: "막대 그래프",
//     description: "항목별 응답 수를 막대 모양으로 나타냄",
//     img: barIcon,
//   },
//   {
//     id: 2,
//     title: "원형 그래프",
//     description: "전체 응답에 대한 각 항목의 비율을 원 모양으로 나타냄",
//     img: circleIcon,
//   },
//   {
//     id: 3,
//     title: "도넛 그래프",
//     description: "전체 응답에 대한 각 항목의 비율을 도넛 모양으로 나타냄",
//     img: donutIcon,
//   },
// ];
