// 차트와 표 형태로 결과를 보여주는 컴포넌트 (추후 차트와 표를 사용하려고 삭제 안했음)

import * as S from "./styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApexCharts from "react-apexcharts";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const Result = () => {
  const { results } = useParams(); // URL 파라미터에서 results 값을 받아옵니다.
  const [resultObjectArray, setResultObjectArray] = useState([]); // 결과 객체 배열을 저장할 상태

  useEffect(() => {
    // 새로운 배열에 results 값을 저장
    const newResultArray = results.split(",").map(Number);

    // 배열을 객체 배열로 변환
    const newResultObjectArray = [
      { name: "유희형(PL)", num: newResultArray[0] },
      { name: "신념형(PE)", num: newResultArray[1] },
      { name: "상상형(DR)", num: newResultArray[2] },
      { name: "분석형(AN)", num: newResultArray[3] },
      { name: "행동형(AC)", num: newResultArray[4] },
      { name: "감성형(EM)", num: newResultArray[5] },
    ].sort((a, b) => a.num - b.num); // num 값에 따라 오름차순으로 정렬

    setResultObjectArray(newResultObjectArray);
  }, [results]);

  const getNumByName = (name) => {
    const obj = resultObjectArray.find((item) => item.name === name);
    return obj ? obj.num : '';
  };

  const colors = ['#FF4560', '#00E396', '#FEB019', '#775DD0', '#3F51B5', '#546E7A']; // 각 바의 색상 배열

  return (
    <S.UserResult>
      <ApexCharts
        type="bar"
        series={[
          {
            name: "결과",
            data: resultObjectArray.map((obj) => obj.num), // resultObjectArray의 num 값을 배열로 추출하여 설정
          },
        ]}
        options={{
          chart: {
            type: "bar",
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: true, // 바의 색상을 다르게 분배
            },
          },
          colors: colors,
          xaxis: {
            categories: ["6F", "5F", "4F", "3F", "2F", "1F"], // x축에 표시할 이름을 6F부터 1F로 설정
            labels: {
              show: true,
              style: {
                colors: ["#000000"],
                fontSize: "12px",
                fontFamily: "Pretendard",
                fontWeight: 500,
                cssClass: "apexcharts-xaxis-label",
              },
            },
          },
          yaxis: {
            categories: resultObjectArray.map((_, index) => index + 1), // y축 인덱스를 0에서 30까지 고정
            min: 0,
            max: 30, // y축 인덱스를 0에서 30까지 고정
            labels: {
              show: true,
              style: {
                colors: ["#000000"],
                fontSize: "12px",
                fontFamily: "Pretendard",
                fontWeight: 500,
                cssClass: "apexcharts-yaxis-label",
              },
            },
          },
          legend: {
            show: false // 범례를 숨김
          },
          dataLabels: {
            enabled: true,
            formatter: function(val, opt) {
              return resultObjectArray[opt.dataPointIndex].name; // 바 내부에 텍스트 표시
            },
            style: {
              colors: ['#fff'],
              fontSize: '10px',
              fontWeight: 'bold',
            },
          },
          tooltip: {
            y: {
              formatter: function(val, opt) {
                return resultObjectArray[opt.dataPointIndex].name + `: ${val}`; // 툴팁에서 이름과 숫자 값 표시
              },
            },
          },
        }}
      ></ApexCharts>

      {/* 결과 테이블 */}
      <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow sx={{backgroundColor: "#E0F7FA"}}>
              <TableCell align="center" sx={{font: "bold 8px pretendard", boxSizing:"border-box"}}>구분</TableCell>
              <TableCell align="center" sx={{font: "500 10px pretendard",padding: "4px 0"}}>유희형(PL)</TableCell>
              <TableCell align="center" sx={{font: "500 10px pretendard",padding: "4px 0"}}>신념형(PE)</TableCell>
              <TableCell align="center" sx={{font: "500 10px pretendard",padding: "4px 0"}}>상상형(DR)</TableCell>
              <TableCell align="center" sx={{font: "500 10px pretendard",padding: "4px 0"}}>분석형(AN)</TableCell>
              <TableCell align="center" sx={{font: "500 10px pretendard",padding: "4px 0"}}>행동형(AC)</TableCell>
              <TableCell align="center" sx={{font: "500 10px pretendard",padding: "4px 0"}}>감성형(EM)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center" sx={{font: "bold 8px pretendard", boxSizing:"border-box"}}>자가진단 결과</TableCell>
              <TableCell align="center" sx={{font: "500 10px pretendard",padding: "4px 0"}}>{getNumByName("유희형(PL)")}</TableCell>
              <TableCell align="center" sx={{font: "500 10px pretendard",padding: "4px 0"}}>{getNumByName("신념형(PE)")}</TableCell>
              <TableCell align="center" sx={{font: "500 10px pretendard",padding: "4px 0"}}>{getNumByName("상상형(DR)")}</TableCell>
              <TableCell align="center" sx={{font: "500 10px pretendard",padding: "4px 0"}}>{getNumByName("분석형(AN)")}</TableCell>
              <TableCell align="center" sx={{font: "500 10px pretendard",padding: "4px 0"}}>{getNumByName("행동형(AC)")}</TableCell>
              <TableCell align="center" sx={{font: "500 10px pretendard",padding: "4px 0"}}>{getNumByName("감성형(EM)")}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </S.UserResult>
  );
};

export default Result;