import React from "react";
import S from "../style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import MainImg from "../../../../../../src/assets/images/HexagramMainImg.png"

const FirstPageMobile = ({ onNextClick, userData }) => {

  const navigate = useNavigate();
  const { gameId, results, } = useParams();
  const handleDownloadClick = () => {
    navigate(`/hexagram/user/${gameId}/result/${results}/`);
  };
  return (
    <div className={S.Page}>
      <div className={S.Title}>
        <p>{userData.name} 님의 핵사그램 결과</p>
      </div>
      <div className={S.PageContents}>
        <div className={S.FirstPageImg}>
          <img src={MainImg} alt="MainImg" />
        </div>
        <p className={S.Summary}>※ 본 모바일 결과지는 요약본입니다.</p>
        <p className={S.Pdf} onClick={handleDownloadClick}>PDF로 결과지를 다운받아 세부적으로 확인하기</p>
        <div className={S.FirstPageUserInfo}>
          <div className={S.UserInfo}>
            <p>소속:&nbsp;</p>
            <p>{userData.affiliation}</p>
          </div>
          <div className={S.UserInfo}>
            <p>검사일:&nbsp;</p>
            <p>{userData.date}</p>
          </div>
        </div>
      </div>
      <div className={S.FirstPageFooter}>
        <div className={S.NextBtn} onClick={onNextClick}>다음</div>
      </div>
    </div>
  );
};

export default FirstPageMobile;