import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import styles from "../styles/payment.module.css";

import logo from "assets/images/wLiveLogo(Red).png";

export default () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [responseData, setResponseData] = useState({});

  useEffect(() => {
    const { order, user, response } = state;

    setResponseData({
      product: order.description,
      price: order.totalAmount,
      token: user.hexagramPoint,
      receipt: { url: response.receipt.url },
    });
  }, []);

  return (
    <div className={styles.checkout}>
      <div className={styles.body}>
        <div className={styles.logo}>
          <img src={logo} />

          <p>Withplus Live - 상품 결제</p>
        </div>

        <div className={styles.success}>
          <img src="https://static.toss.im/illusts/check-blue-spot-ending-frame.png" />

          <p>
            이용권 결제가 정상적으로 <br />
            완료되었습니다.
          </p>
        </div>

        <div>
          <div className={styles.info}>
            <p>이용권</p>

            <p>{responseData?.product}</p>
          </div>

          <div className={styles.info}>
            <p>결제 금액</p>

            <p>{responseData.price?.toLocaleString("ko-KR")}원</p>
          </div>

          <div className={styles.info}>
            <p>구입 후 총 토큰</p>

            <p>{responseData.token}개</p>
          </div>
        </div>

        {responseData.receipt && (
          <div
            className={styles.receipt}
            onClick={() => {
              if (!responseData?.receipt?.url) {
                alert("영수증이 없습니다.");
                return;
              }
              window.open(responseData.receipt.url, "_blank");
            }}
          >
            <p>영수증 보기</p>

            <KeyboardArrowRightIcon />
          </div>
        )}

        <div>
          <Button
            className={styles.confirm_btn}
            onClick={() => {
              navigate("/dashboard/subscribe");
            }}
          >
            확인했어요
          </Button>
        </div>
      </div>
    </div>
  );
};
