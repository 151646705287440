import { Box, IconButton, Typography } from "@mui/material";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useContext, useEffect, useState } from "react";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import Title from "components/Title";

const ManagerPageLayout = (props) => {
  const { children, title } = props;

  const [isFullscreen, setFullscreen] = useState(false);
  const [isAccessCodeOpened, setAccessCodeOpened] = useState(true);
  const [isAccessCodeVisible, setAccessCodeVisible] = useState(false);

  // const [bgmVolumn, setBgmVolumn] = useState(0);

  const handle = useFullScreenHandle();

  // QR코드 창이 열려있는지 확인하는 함수
  const handleAccessCodeContainer = () => {
    setAccessCodeOpened(!isAccessCodeOpened);
  };

  // QR코드 창이 열렸는지 닫혔는지 상태가 변화할 떄마다 확인
  useEffect(() => {
    if (isAccessCodeOpened) {
      setAccessCodeVisible(true);
    } else {
      setTimeout(() => {
        setAccessCodeVisible(false);
      }, 1000);
    }
  }, [isAccessCodeOpened]);

  return (
    <Box
      sx={{
        height: "100vh",
        pt: "80px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        bgcolor: "#f5f5f5",
      }}
    >
      <Box sx={{ px: "20px" }}>
        <Title children={{ name: children.name }} />
      </Box>
      <FullScreen handle={handle}>
        <Box
          sx={{
            bgcolor: "#fff",
            width: "calc(100% - 20px)",
            borderRadius: "10px",
            boxSizing: "border-box",
            height: isFullscreen ? "100vh" : "calc(100% - 10px)",
            boxShadow: "0 3px 6px #00000038",
            m: "0 auto",
          }}
        >
          <Box
            sx={{
              height: "100%",
              overflow: "hidden",
              display: "flex",
              position: "relative",
              width: "100%",
            }}
          >
            <Box
              sx={{
                borderRight: "1px solid #cdcdcd",
                position: "relative",
                transition: "1s",
                width: "100%",
                minWidth: isAccessCodeOpened ? "75%" : "100%",
              }}
            >
              {children.ContentBox}
              <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  top: "50%",
                  transform: "translate(50%, -50%)",
                }}
              >
                <IconButton
                  sx={{
                    bgcolor: "#dddddd",
                    color: "white",
                  }}
                  onClick={handleAccessCodeContainer}
                >
                  {isAccessCodeOpened ? (
                    <KeyboardArrowRightIcon />
                  ) : (
                    <KeyboardArrowLeftIcon
                      sx={{
                        margin: "2px 0px",
                        marginRight: "16px",
                      }}
                    />
                  )}
                </IconButton>
              </Box>
            </Box>

            <Box
              sx={{
                margin: "auto",
                display: isAccessCodeVisible ? "flex" : "none",
                justifyContent: "center",
                flexDirection: "column",
                transitionDelay: "1s",
                minWidth: isAccessCodeOpened ? "25%" : "0%",
              }}
            >
              {children.QRCodeBox}
            </Box>
          </Box>
        </Box>
      </FullScreen>
    </Box>
  );
};

export default ManagerPageLayout;
