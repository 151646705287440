// WaitingRoom.js

import { Box, Typography, Button } from "@mui/material";
import * as S from "./styles";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
// import { CustomButton as Button } from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { DELETE_USER } from "utils/api/Tap";
// import postItCow from "assets/images/LiveTap/post-it-elephant.png";
// import { teamCharacterData } from "assets/images/LiveTap/teamCharacter";
import background from "assets/images/LiveTap_new/background_start.png";
import Character_Win from "assets/images/LiveTap_new/Character_Win";

// const avatars = Array.from({ length: 67 }, (_, i) =>
//   require(`../../../assets/images/Avartar/Character${i + 1}.png`)
// );

const WaitingRoom = () => {
  const { gameId } = useParams();
  const { currentLiveTap, name, team, socket } = useContext(UserContext);
  const [cookies] = useCookies();
  const navigate = useNavigate();

  const [teamIdx, setTeamIdx] = useState(-1);

  const handleBack = () => {
    DELETE_USER(cookies.uid, currentLiveTap._id)
      .then((res) => {
        if (res.success) {
          navigate(`/tap/user/${gameId}/teamSelect`, { replace: true }); // 이전 페이지로 이동
        } else {
          console.error("유저 삭제 실패", res.message);
        }
      })
      .catch((error) => {
        console.error("유저 삭제 에러", error);
      });
  };

  useEffect(() => {
    if (!socket) return;

    const handleNavigateToGameScreen = () => {
      navigate(`/tap/user/${currentLiveTap._id}/gameScreen`);
    };

    const handleUserDeleted = ({ uid }) => {
      if (uid === cookies.uid) {
        navigate(`/tap/user/${currentLiveTap._id}`); // 첫 화면으로 리다이렉트
      }
    };

    socket.on("navigateToGameScreen", handleNavigateToGameScreen);
    socket.on("userDeleted", handleUserDeleted);

    return () => {
      socket.off("navigateToGameScreen");
      socket.off("userDeleted");
    };
  }, [socket]);

  useEffect(() => {
    if (!team) return;

    let teamIdx = currentLiveTap.teamData.findIndex((t) => t.id === team.id);

    if (teamIdx !== -1) {
      setTeamIdx(teamIdx);
    } else {
      alert("팀 선택이 잘못되었습니다. 다시 선택해주세요.");
      navigate(`/tap/user/${currentLiveTap._id}`);
    }
  }, [team]);

  useEffect(() => {
    if (!currentLiveTap) return;

    if (currentLiveTap.isGameStart) {
      for (let i = 0; i < currentLiveTap.teamData.length; i++) {
        let myInfo = currentLiveTap.teamData[i].teamMembers.find(
          (user) => user.uid === cookies.uid
        );

        if (myInfo) {
          navigate(`/tap/user/${currentLiveTap._id}/gameScreen`);
          return;
        }
      }

      alert("현재 게임에 참여하고 있지 않습니다. 초기 화면으로 이동합니다.");
      navigate(`/tap/user/${currentLiveTap._id}`);
    }
    console.log(currentLiveTap);
  }, [currentLiveTap]);

  return (
    <S.Intro>
      <Box sx={{ alignSelf: "flex-start", width: "100%" }}>
        <Typography
          sx={{
            font: "bold clamp(14.2px, 4.5vw, 20px) Pretendard",
            mb: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {currentLiveTap?.name}
        </Typography>
      </Box>

      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "clamp(7.1px, 2.3vw, 10px)",
          // bgcolor: "#ffff0050",
        }}
      >
        <Box
          sx={{
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
            // flexDirection: "column",
            justifyContent: "center",
            // alignItems: "center",
            width: "100%",
            backgroundImage: `url(${background})`,
            backgroundSize: "auto 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            aspectRatio: "1/0.7",
            p: "clamp(7.1px, 2.3vw, 10px)",
            // bgcolor: "#ff000050",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              width: "30%",
              margin: "5px auto",
              // aspectRatio: "1 / 1",
              // font: "bold 22px pretendard",
              // borderRadius: "55%",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // mt: "20px",
            }}
          >
            {teamIdx !== -1 && <img src={Character_Win[teamIdx]} alt="User Avatar" />}
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: "5%",
              // left: "50%",
              // transform: "translateX(-50%)",
              bgcolor: "#ffffff90",
              p: "5px",
              backdropFilter: "blur(5px)",
              borderRadius: "6px",
            }}
          >
            <Typography
              sx={{
                font: "900 clamp(14.2px, 4.5vw, 20px) Pretendard",
                // mt: 2,
                // ml: 2,
                textAlign: "center",
              }}
            >
              {name} 님
            </Typography>

            <Typography
              sx={{
                font: "bold clamp(14.2px, 4.5vw, 20px) Pretendard",
                mt: "10px",
                // pl: 3,
                textAlign: "center",
              }}
            >
              {team?.teamName} 입장이 완료되었습니다.
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography
            sx={{
              font: "600 clamp(14.2px, 4.5vw, 20px) Pretendard",
              mt: "25px",
              textAlign: "center",
            }}
          >
            강사 화면에서 닉네임을 확인 후,
          </Typography>
          <Typography
            sx={{
              font: "600 clamp(14.2px, 4.5vw, 20px) Pretendard",
              mt: "15px",
              textAlign: "center",
            }}
          >
            강사가 게임을 시작하기 전까지 <span style={{ fontWeight: "bold" }}>대기</span>해주세요.
          </Typography>
          {/* currentLiveTap.gameMode가 individual이 아닐 때만 박스 표시 */}
          {currentLiveTap?.gameMode !== "individual" && (
            <Box
              sx={{
                mt: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <Typography
                sx={{
                  font: "bold clamp(19.9px, 6.3vw, 28px) Pretendard",
                  textAlign: "center",
                  color: "#9C3535",
                }}
              >
                팀 선택을 잘못했다면?
              </Typography>

              <Button
                onClick={handleBack}
                sx={{
                  mt: "10px",
                  font: "700 clamp(12.8px, 4.1vw, 18px) Pretendard",
                  p: "10px clamp(14.2px, 4.5vw, 20px)",
                  // fontSize: "clamp(14.25px, 1.354vw, 20px)",
                  borderRadius: "6px",
                  // width: "clamp(75px, 6.25vw, 110px)",
                  // height: "clamp(25px, 6.25vw, 40px)",
                  // "&:hover": { bgcolor: "#9C3535" },
                  // boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                {"<-"} 돌아가기
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </S.Intro>
  );
};

export default WaitingRoom;
