import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { Box, Avatar, Typography } from "@mui/material";

import { socket } from "utils/socket";

export const ListPageUserInfo = (props) => {
  const [cookies] = useCookies();

  const [name, setName] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    socket.emit("userInfo", { auth: cookies.auth });
  }, []);

  useEffect(() => {
    socket.on("userInfo", (data) => {
      setName(data.name);
      setStatus(data.status);
    });

    return () => {
      socket.off("userInfo");
    };
  });

  return (
    <Box sx={{ width: "100%", marginBottom: "16px", display: "flex" }}>
      <Box sx={{ display: "flex", my: "auto" }}>
        <Box sx={{ margin: "16px" }}>
          <Avatar sx={{ width: "12vmin", height: "12vmin" }} />
        </Box>

        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <div>
            <Typography sx={{ font: "bold 26px NotoSansKR-Medium", color: "#707070" }}>
              {name}
            </Typography>
            <Typography sx={{ fontSize: "18px", color: "#878787" }}>
              {status === "Personal" && "개인 계정"}
              {status === "Group" && "그룹 계정"}
            </Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
};
