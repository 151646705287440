// 0704 지원 핵사그램 index 페이지
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

import HexagramListPage from "./ListPage";
import Auth from "auth/auth";
import ManagerPage from "./ManagerPage";
import UserPage from "./UserPage";

const Index = () => {
  return (
    <Routes>
      <Route path="/:gameId" element={<Redirect />} />
      <Route path="/list" element={<Auth Component={HexagramListPage} isAuthRequired />}></Route>
      <Route
        path="/manager/:gameId/*"
        element={<Auth Component={ManagerPage} isAuthRequired />}
      ></Route>
      <Route path="/user/:gameId/*" element={<UserPage />}></Route>
    </Routes>
  );
};

const Redirect = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/hexagram/user/${gameId}`, { replace: true });
  }, []);

  return <></>;
};

export default Index;
