import React, { useContext, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Layout from "./Layout";
import { Context, Provider } from "./context";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { LoadingModal } from "./components/Modal";
import axios from "axios";
import Checkout, {
  SuccessPage as CheckoutSuccess,
  FailPage as CheckoutFail,
  PendingPage as CheckoutPending,
} from "./Checkout";

const queryClient = new QueryClient();

const Payment = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider>
        <Routes>
          <Route path="/" element={<PaymentPage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/fail" element={<FailPage />} />

          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout/pending" element={<CheckoutPending />} />
          <Route path="/checkout/success" element={<CheckoutSuccess />} />
          <Route path="/checkout/fail" element={<CheckoutFail />} />
        </Routes>
      </Provider>
    </QueryClientProvider>
  );
};

const PaymentPage = () => {
  return <Layout />;
};

const SuccessPage = () => {
  const searchParams = useSearchParams()[0];
  const navigate = useNavigate();
  const { V, F } = useContext(Context);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({ isError: false, message: "" });
  const [ExpiredAt, setExpiredAt] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);

  const params = {
    customerKey: searchParams.get("customerKey"),
    authKey: searchParams.get("authKey"),
    price: searchParams.get("price"),
    finalPrice: searchParams.get("finalPrice"),
    plan: searchParams.get("plan"),
    month: searchParams.get("month"),
    date: searchParams.get("date"),
    discount: searchParams.get("discount"),
    name: searchParams.get("name"),
    phone: searchParams.get("phone"),
    company: searchParams.get("company"),
    groupUserCount: searchParams.get("groupUserCount"),
    usedCouponId: searchParams.get("usedCoupon"),
  };

  useEffect(() => {
    axios
      .post("/api/payment/success", null, {
        params: params,
      })
      .then((res) => {
        if (res.data.success) {
          const now = new Date();
          const { paymentInfo, ExpiredAt } = res.data;

          navigate("/payment/checkout/success", {
            replace: true,
            state: {
              orderName:
                paymentInfo.plan === "monthly"
                  ? "베이직 구독 (월간 결제)"
                  : "베이직 구독 (연간 결제)",
              StartedAt: now.toISOString().slice(0, 10),
              ExpiredAt: ExpiredAt.slice(0, 10),
              totalAmount: paymentInfo.finalPrice * 1,
            },
          });
        } else {
          navigate(
            `/payment/checkout/fail?code=${res.data.error.code}&message=${res.data.error.message}`,
            {
              replace: true,
            }
          );
        }
      });
  }, []);

  return (
    <LoadingModal
      success={success}
      error={error}
      params={{ ...params, expiredAt: ExpiredAt, paymentInfo }}
    />
  );
};

const FailPage = () => {
  const searchParams = useSearchParams()[0];

  const [error, setError] = useState({ isError: false, message: "" });
  // const

  useEffect(() => {
    setError({
      isError: true,
      message: searchParams.get("message"),
    });
  }, []);
  return <LoadingModal error={error} />;
};

export default Payment;
