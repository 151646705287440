import React from "react";

import { Box, Grid, Typography } from "@mui/material";

import Title from "components/Title";
import { ListPageUserInfo as UserInfo } from "components/UserInfo";

const ListPageLayout = (props) => {
  const { children } = props;

  return (
    <Box className="body">
      <Grid className="pt-80 justify-content-center" container>
        <Grid item xs={10.5} sx={{ mt: "2vh" }}>
          <Box>
            <Title {...props} />

            <UserInfo />

            <Box className="mx-10">
              <Box className="space-between">
                <Typography sx={{ font: "700 25px Pretendard", margin: "auto 0px" }}>
                  My List
                </Typography>

                {children.button}
              </Box>
            </Box>

            <Box className="paper mt-8" sx={{ height: "400px", minWidth: "1100px" }}>
              {children.data}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ListPageLayout;
