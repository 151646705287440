import React, { useCallback, useEffect, useState, useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import * as XLSX from "xlsx";
import { GET_USERS, GET_USER_FORM, POST_SAVE_USERS } from "utils/api/Hexagram";
import FileUploadTable from "./FileUploadTable";
import FileAlreadyUploadTable from "./FileAlreadyUploadTable"; // Import the new component
import { useParams } from "react-router-dom";

const Logo = () => (
  <svg className="icon" x="0px" y="0px" viewBox="0 0 24 24">
    <path fill="transparent" d="M0,0h24v24H0V0z" />
    <path
      fill="#000"
      d="M20.5,5.2l-1.4-1.7C18.9,3.2,18.5,3,18,3H6C5.5,3,5.1,3.2,4.8,3.5L3.5,5.2C3.2,5.6,3,6,3,6.5V19  c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V6.5C21,6,20.8,5.6,20.5,5.2z M12,17.5L6.5,12H10v-2h4v2h3.5L12,17.5z M5.1,5l0.8-1h12l0.9,1  H5.1z"
    />
  </svg>
);

const ReactDragDrop = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [final, setFinal] = useState([]);
  const [file, setFile] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const tableRef = useRef(null);
  const dragRef = useRef(null);
  const fileId = useRef(0);
  const gameId = useParams().gameId;

  const [users, setUsers] = useState([]);

  useEffect(() => {
    GET_USERS(gameId)
      .then((res) => {
        setUsers(res.data.users || []);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, [isSaved]);

  const handleSave = () => {
    if (tableRef.current) {
      const users = tableRef.current.getTableData();
      POST_SAVE_USERS(gameId, users)
        .then((res) => {
          console.log("성공", res.data);
          alert("파일이 성공적으로 저장되었습니다.");
          setIsSaved(true); // Set to true on success
        })
        .catch((err) => {
          console.error("실패", err);
        });
    }
  };

  const onChangeFiles = useCallback((e) => {
    let selectFiles = [];
    if (e.type === "drop") {
      selectFiles = e.dataTransfer.files;
    } else {
      selectFiles = e.target.files;
    }

    const selectedFile = selectFiles[0];
    if (!selectedFile.name.endsWith(".xlsx") && !selectedFile.name.endsWith(".xls")) {
      alert("엑셀 파일이 아닙니다. .xlsx 또는 .xls 파일을 업로드 해주세요.");
      return; // Exit the function if not an Excel file
    }

    const newFile = {
      id: fileId.current++,
      object: selectedFile,
    };
    setFile(newFile);
    setFiles([newFile]);

    // 파일 읽기 및 콘솔 출력
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const firstSheet = workbook.Sheets[firstSheetName];
      const firstSheetJson = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

      // C열이 비어있는 행을 기준으로 데이터 필터링
      const filteredData = [];
      for (let i = 10; i < firstSheetJson.length; i++) {
        const row = firstSheetJson[i];
        if (!row[2]) {
          break;
        }
        filteredData.push(row);
      }

      setFinal(filteredData);
      // 데이터 확인용
      console.log(filteredData);
    };
    reader.readAsArrayBuffer(selectedFile);
  }, []);

  const handleDeleteFile = () => {
    setFile(null);
    setFiles([]);
    setIsSaved(false);
  };

  const handleDragIn = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragOut = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer?.files) {
      setIsDragging(true);
    }
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onChangeFiles(e);
      setIsDragging(false);
    },
    [onChangeFiles]
  );

  const initDragEvents = useCallback(() => {
    if (dragRef.current !== null) {
      dragRef.current.addEventListener("dragenter", handleDragIn);
      dragRef.current.addEventListener("dragleave", handleDragOut);
      dragRef.current.addEventListener("dragover", handleDragOver);
      dragRef.current.addEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  const resetDragEvents = useCallback(() => {
    if (dragRef.current !== null) {
      dragRef.current.removeEventListener("dragenter", handleDragIn);
      dragRef.current.removeEventListener("dragleave", handleDragOut);
      dragRef.current.removeEventListener("dragover", handleDragOver);
      dragRef.current.removeEventListener("drop", handleDrop);
    }
  }, [handleDragIn, handleDragOut, handleDragOver, handleDrop]);

  useEffect(() => {
    initDragEvents();
    return () => resetDragEvents();
  }, [initDragEvents, resetDragEvents]);

  useEffect(() => {
    if (file === null) {
      initDragEvents();
    }
  }, [file, initDragEvents]);

  // console.log("users", users);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      {isSaved ? (
        <FileAlreadyUploadTable users={users} />
      ) : (
        <>
          {!file && (
            <Box sx={{ width: "95%", display: "flex", flexDirection: "column", gap: "15px" }}>
              <Button
                onClick={GET_USER_FORM}
                variant="outlined"
                sx={{
                  alignSelf: "end",
                  borderColor: "#b0b0b0",
                  color: "#656868",
                  "&:hover": {
                    borderColor: "#a0a0a0",
                    backgroundColor: "#f0f0f0",
                  },
                }}
              >
                양식 다운로드
              </Button>
              <Box
                component="label"
                htmlFor="fileUpload"
                ref={dragRef}
                sx={{
                  width: "100%",
                  height: 350,
                  margin: "auto",
                  backgroundColor: "#fff",
                  borderRadius: 1,
                  border: "3px dashed #eee",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  "&:hover, &.dragging": {
                    borderColor: "#111",
                  },
                }}
                className={isDragging ? "dragging" : ""}
              >
                <input
                  type="file"
                  id="fileUpload"
                  types={["xlsx"]}
                  multiple={false}
                  onChange={onChangeFiles}
                  style={{ display: "none" }}
                />
                <Box className="icon" sx={{ width: 100, height: 100 }}>
                  <Logo />
                </Box>
                <Typography sx={{ fontWeight: 500, fontSize: 18, margin: "20px 0 10px" }}>
                  클릭 혹은 파일을 이곳에 드롭하세요.
                </Typography>
              </Box>
            </Box>
          )}
          {file && (
            <Box
              sx={{
                display: "flex",
                gap: "25px",
                alignItems: "center",
                justifyContent: "center",
                width: "95%",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Box
                  component="img"
                  src="https://noticon-static.tammolo.com/dgggcrkxq/image/upload/v1568016998/noticon/ztj0soogsjihpq87vfcp.png"
                  alt="파일 아이콘"
                  sx={{ width: 30 }}
                />
                <Typography sx={{ fontWeight: 600, fontSize: 16 }}>{file.object.name}</Typography>
              </Box>
              <FileUploadTable ref={tableRef} final={final} />
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Button onClick={handleSave} disabled={isSaved}>
                  저장
                </Button>
                <Button
                  onClick={handleDeleteFile}
                  sx={{
                    fontWeight: 500,
                    fontSize: 14,
                    backgroundColor: "#b43f3f",
                    color: "white",
                    borderRadius: 1,
                    padding: "6px 12px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#c33c3c",
                    },
                  }}
                >
                  삭제 및 재업로드
                </Button>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ReactDragDrop;
