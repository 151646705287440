import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { Avatar, Box, Grid, Typography, IconButton, Tooltip } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import PeopleIcon from "@mui/icons-material/People";
import AlarmIcon from "@mui/icons-material/Alarm";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import ClearIcon from "@mui/icons-material/Clear";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";
import FlakyIcon from "@mui/icons-material/Flaky";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RefreshIcon from "@mui/icons-material/Refresh";
import HttpsIcon from "@mui/icons-material/Https";
import SearchIcon from "@mui/icons-material/Search";

import Layout from "Layout/SlidePageLayout";

import logo from "assets/images/wliveLogo.png";
import clock from "assets/images/clock1.png";
import Logo from "assets/images/wLiveLogo(Red).png";
import { QRCode } from "react-qr-svg";
import styles from "./styles/LiveQuizStyles";
import { CustomButton as Button } from "components/Button";
import { QuizSlideContext, QuizSlideProvider } from "./contexts/QuizSlide";
import { SnackBarContext } from "components/SnackBar/ContextAPI";
import { QRCodeWideModal } from "components/Modal";
import { AudioProvider, AudioContext } from "contexts/Audio";
import { FullScreenContext } from "contexts/FullScreen";
import BubbleChart from "./Bubble";
import Avartar from "assets/images/Avartar";
import { subTitles, outro } from "./template/Intro";

import character1 from "assets/images/Character/character1.png";
import character2 from "assets/images/Character/character2.png";
import character3 from "assets/images/Character/character3.png";
import character4 from "assets/images/Character/character4.png";
import Header from "./components/Header";
import { divideHangul } from "utils/chosung";
import useInterval from "utils/useInterval";

const bgcolors = ["#F2EAA2", "#BFBCDE", "#F4CEC2", "#99CCCC", "#EDD39F", "#c26974"];

const QuizSlidePage = (props) => {
  return (
    <AudioProvider>
      <QuizSlideProvider>
        <QuizSlide />
      </QuizSlideProvider>
    </AudioProvider>
  );
};

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

const QuizSlide = (props) => {
  return (
    <>
      <Layout>
        {{
          name: "Live Quiz",

          content: (
            <Grid container sx={{ height: "100%", overflow: "hidden" }}>
              <SlideContainer />
              <CodeContainer />
            </Grid>
          ),
        }}
      </Layout>
    </>
  );
};

const SlideContainer = (props) => {
  const { isAccessCodeOpened, setAccessCodeOpened, currentSlide, playingData } =
    useContext(QuizSlideContext);
  const { bgmVolumn, setBgmVolumn } = useContext(AudioContext);
  const { handle, handleFullScreen } = useContext(FullScreenContext);

  // const [view, setView] = useState(null);

  const handleAccessCodeContainer = () => {
    setAccessCodeOpened(!isAccessCodeOpened);
  };

  const handleBGMVolume = (e) => {
    setBgmVolumn((e.target.value * 1) / 200);
  };

  const handleMuteSound = () => {
    if (bgmVolumn === 0) {
      setBgmVolumn(0.25);
    } else {
      setBgmVolumn(0);
    }
  };

  // useEffect(() => {
  //   if (!currentSlide) return;

  //   setView(currentSlide.type.name);
  // }, [currentSlide]);

  return (
    <Grid
      item
      xs={isAccessCodeOpened ? 9 : 12}
      sx={{
        borderRight: "1px solid #cdcdcd",
        transition: "1.5s",
        position: "relative",
        height: "100%",
      }}
    >
      {currentSlide}
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: "50%",
          transform: "translate(50%, -50%)",
          overflow: "hidden",
          zIndex: 2,
        }}
      >
        <IconButton
          sx={{
            bgcolor: "#aaa",
            color: "white",
            "&:hover": {
              bgcolor: "#999",
            },
          }}
          onClick={handleAccessCodeContainer}
        >
          {isAccessCodeOpened ? (
            <KeyboardArrowRightIcon />
          ) : (
            <KeyboardArrowLeftIcon sx={{ transform: "translateX(-5px)" }} />
          )}
        </IconButton>
      </Box>
      {/*

      <Box
        sx={{
          position: "absolute",
          top: "10px",
          right: "10px",
          display: Object.keys(playingData).length === 0 ? "flex" : "none",
          gap: "10px",
          zIndex: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            boxShadow: "0px 0px 13px 2px rgba(0,0,0,0.2)",
            borderRadius: "50px",
            px: "5px",
            bgcolor: "#fff",
          }}
        >
          <input
            type="range"
            min="0"
            max="100"
            value={bgmVolumn * 200}
            onChange={handleBGMVolume}
            style={{
              accentColor: "#c26974",
            }}
          />
          <IconButton onClick={handleMuteSound}>
            {bgmVolumn === 0 ? (
              <VolumeOffIcon sx={{ fontSize: "clamp(12.5px, 1.0416vw, 20px)" }} />
            ) : (
              <VolumeUpIcon sx={{ fontSize: "clamp(12.5px, 1.0416vw, 20px)" }} />
            )}
          </IconButton>
        </Box>

        <IconButton
          onClick={handleFullScreen}
          sx={{
            boxShadow: "0px 0px 13px 2px rgba(0,0,0,0.2)",
            bgcolor: "#fff",
            ":hover": {
              bgcolor: "#eee",
            },
          }}
        >
          {handle.active ? (
            <FullscreenExitIcon sx={{ fontSize: "clamp(12.5px, 1.0416vw, 20px)" }} />
          ) : (
            <FullscreenIcon sx={{ fontSize: "clamp(12.5px, 1.0416vw, 20px)" }} />
          )}
        </IconButton>
      </Box> */}
    </Grid>
  );
};

export const IntroView = (props) => {
  const { id } = useParams();
  const { users, socket, setAccessCodeOpened, setPlayingData, setSlideList, setCurrentSlide } =
    useContext(QuizSlideContext);
  const snackBar = useContext(SnackBarContext);
  const { playBGM, stopBGM } = useContext(AudioContext);

  const [opacity, setOpacity] = useState(0);
  const [display, setDisplay] = useState("flex");
  const [subTitle, setSubTitle] = useState("");
  const [blinking, setBlinking] = useState(false);

  const startQuiz = () => {
    if (users.length === 0) {
      snackBar.setText("참여자가 없는 경우 퀴즈를 진행할 수 없습니다.");
      snackBar.setSeverity("warning");
      snackBar.setOpen(true);
      return;
    }

    // setAccessCodeOpened(false);
    stopBGM();

    setTimeout(() => {
      socket.emit("startQuiz", { quizID: id });
      playBGM("quizBgm1");
    }, 500);
  };

  const removeUser = (e) => {
    if (window.confirm("이 유저를 삭제하시겠습니까?")) {
      socket.emit("removeUser", { uid: e.currentTarget.id, quizID: id });
    }
  };

  const goToLobby = () => {
    playBGM("lobbyBgm");
    setDisplay("none");
    setAccessCodeOpened(true);
  };

  useEffect(() => {
    if (!blinking) return;

    const Timer = setInterval(() => {
      setOpacity((prevOpacity) => (prevOpacity >= 1 ? 1 : prevOpacity + 0.05));
    }, 100);

    return () => {
      clearInterval(Timer);
    };
  }, [blinking]);

  useEffect(() => {
    const Typer = setInterval(() => {
      if (subTitles.indexOf(subTitle) === subTitles.length - 1) {
        clearInterval(Typer);
        setBlinking(true);
        return;
      }

      setSubTitle((prevSubTitle) => {
        let idx = subTitles.indexOf(prevSubTitle) + 1;
        return subTitles[idx];
      });
    }, 60);

    return () => {
      clearInterval(Typer);
    };
  }, [subTitle]);

  useEffect(() => {
    if (!socket) return;

    socket.emit("getUsers", { quizID: id });
    socket.emit("getQuizData", { quizID: id }, (data) => {
      if (data.quiz.isQuizStarted) {
        setPlayingData(data.quiz.playingData);
        setSlideList(data.quiz.playingData.slideList);
        setCurrentSlide(<SlideView />);
      }

      // if (data.quiz.playingData.isAllQuizOver) {
      //   setCurrentSlide(<QuizResultContainer />);
      // }
    });
  }, [socket]);

  return (
    <Box sx={styles.intro.container}>
      <Header title="참여 대기방" isStatic />

      <Box sx={{ my: "20px", display: "flex", gap: "10px", alignItems: "center", opacity: "0.75" }}>
        <img src={Logo} style={{ width: "clamp(54.6875px, 3.90625vw, 75px)" }} />

        <Typography
          sx={{ font: "bold 39px Pretendard", fontSize: "clamp(28.4375px, 2.03125vw, 39px)" }}
        >
          Withplus Live - 라이브 퀴즈
        </Typography>
      </Box>

      <Box sx={styles.intro.participant_box}>
        <PeopleIcon sx={styles.intro.participant_box.icon} />

        <Typography sx={styles.intro.participant_box.text}>
          참여자 수 : {users.length} 명
        </Typography>
      </Box>

      <Box sx={styles.intro.all_user_box}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            overflow: "auto",
          }}
        >
          <Grid container>
            {users.map((user) => (
              <Grid
                item
                xs={3}
                sx={styles.intro.user_box}
                key={user.uid}
                onClick={removeUser}
                id={user.uid}
              >
                <img
                  src={Avartar[user.imgIdx]}
                  style={{
                    width: "45px",
                    height: "45px",
                    zIndex: 2,
                    filter: "drop-shadow(2px 2px 2px #00000088)",
                  }}
                />
                <Typography sx={styles.intro.user_box.name} noWrap>
                  {user.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Box sx={{ py: "20px" }}>
        <Button
          size="large"
          sx={{
            ...styles.intro.start_button,
            bgcolor: "#d6e2fb",
            color: "#50586C",
            "&:hover": { bgcolor: "#c6d2eb" },
            fontWeight: "700",
            fontFamily: "Pretendard",
            fontSize: "21px",
          }}
          onClick={startQuiz}
        >
          시작하기
        </Button>
      </Box>

      <Box
        sx={{
          position: "absolute",
          bgcolor: "white",
          width: "100%",
          height: "100%",
          display,
          justifyContent: "center",
          alignItems: "center",
          zIndex: 3,
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Header title="인트로" />

        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, calc(-50% - 200px))",
            display: "flex",
            justifyContent: "center",
            // bgcolor: "red",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              font: "bold 40px Pretendard",
              fontSize: "clamp(25px, 2.083vw, 40px)",
              textAlign: "center",
              lineHeight: "1",
            }}
          >
            {subTitle}
          </Typography>

          {subTitle === subTitles[subTitles.length - 1] && <Typography>&nbsp;&nbsp;</Typography>}

          <Typography
            sx={{
              borderRight: "3px solid",
              animation: blinking ? "blinking 1s infinite" : "",
              animationTimingFunction: "step-end",

              "@keyframes blinking": {
                "0%": {
                  opacity: 0,
                },
                "50%": {
                  opacity: 1,
                },
                "100%": {
                  opacity: 0,
                },
              },
            }}
            children={" "}
          />
        </Box>

        <Box sx={{ width: "100%", textAlign: "center", opacity: opacity }}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              opacity: "0.75",
            }}
          >
            <img src={Logo} style={{ width: "clamp(87.5px, 7.2916vw, 140px)" }} />

            <Typography
              sx={{ font: "900 100px Pretendard", fontSize: "clamp(61.25px, 5.104vw, 98px)" }}
            >
              Withplus Live - 라이브 퀴즈
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            px: "20px",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <Box>
            <img src={character2} style={{ width: "clamp(106px, 10.416vw, 200px)" }} />
            <img src={character1} style={{ width: "clamp(106px, 10.416vw, 200px)" }} />
          </Box>

          <Box>
            <img src={character3} style={{ width: "clamp(106px, 10.416vw, 200px)" }} />
            <img src={character4} style={{ width: "clamp(106px, 10.416vw, 200px)" }} />
          </Box>
        </Box>

        <Button
          sx={{
            position: "absolute",
            bottom: "calc(17.5%)",
            font: "700 26px Pretendard",
            fontSize: "clamp(16.25px, 1.354vw, 26px)",
            borderRadius: "50%",
            width: "clamp(75px, 6.25vw, 120px)",
            height: "clamp(75px, 6.25vw, 120px)",
            bgcolor: "#d6e2fb",
            color: "#50586C",
            "&:hover": { bgcolor: "#c6d2eb" },
          }}
          onClick={goToLobby}
        >
          시작하기
        </Button>
      </Box>
    </Box>
  );
};

export const SlideView = () => {
  const { id } = useParams();
  const {
    playingData,
    setPlayingData,
    users,
    setUsers,
    socket,
    currentView,
    setCurrentView,
    setCurrentSlide,
    timer,
    setTimer,
  } = useContext(QuizSlideContext);
  const { playSFX, stopBGM, playBGM, bgmVolumn, setBgmVolumn } = useContext(AudioContext);
  const { handle, handleFullScreen } = useContext(FullScreenContext);

  const [headerTitle, setHeaderTitle] = useState("");

  // const startQuiz = () => {
  //   socket.emit("quizStart", { quizID: id });
  //   if (playingData.slideList[playingData.slideIndex].timer === "0") {
  //     setPlayingData({ ...playingData, isTimeOver: true });
  //   }
  // };

  const showQuestionAnswer = () => {
    stopBGM();
    playSFX("openCorrect");

    socket.emit(
      "showQuestionAnswer",
      {
        quizID: id,
        type: playingData.slideList[playingData.slideIndex].type,
      },
      () => {}
    );
  };

  const showQuestionResult = () => {
    playSFX("resultView");

    socket.emit("showQuestionResult", { quizID: id }, () => {});
  };

  const nextQuiz = () => {
    const nextIdx = playingData.slideIndex + 1;

    if (nextIdx % 2 === 0) {
      playBGM("quizBgm1");
    } else {
      playBGM("quizBgm2");
    }
    socket.emit("nextQuiz", { quizID: id }, (slideIndex) => {});
  };

  const quizResult = () => {
    playSFX("finalRank");

    socket.emit("quizResult", { quizID: id }, () => {});
  };

  const goToOutro = () => {
    socket.emit("goToOutro", { quizID: id });

    setCurrentSlide(<Outro />);
  };

  const handleBGMVolume = (e) => {
    setBgmVolumn((e.target.value * 1) / 200);
  };

  const handleMuteSound = () => {
    if (bgmVolumn === 0) {
      setBgmVolumn(0.25);
    } else {
      setBgmVolumn(0);
    }
  };

  useEffect(() => {
    if (!socket) return;

    socket.emit("getQuizData", { quizID: id }, (data) => {
      let quiz = data.quiz;

      setPlayingData(quiz.playingData);
      setUsers(quiz.playingUsers);

      if (!quiz.playingData.isStarted) {
        setTimer(quiz.slideList[quiz.playingData.slideIndex].timer);
      } else {
        if (quiz.playingData.isTimeOver) setTimer(0);
      }

      if (quiz.playingData.isResultOpened) {
        setCurrentView(<MidResultContainer />);
        setTimer(0);
      }
      if (quiz.playingData.isAllQuizOver) {
        setCurrentView(<QuizResultContainer />);
        setTimer(0);
      }
    });
  }, [socket]);

  useEffect(() => {
    if (timer === 0) {
      socket.emit("timeOver", { quizID: id });
    }
  }, [timer]);

  useEffect(() => {
    if (playingData.isAllQuizOver) {
      setHeaderTitle("최종 결과");
      return;
    }

    if (playingData.isResultOpened) {
      setHeaderTitle(`${playingData.slideIndex + 1} 라운드 결과`);
      return;
    }

    if (playingData.isAnswerOpened) {
      setHeaderTitle(`정답 공개!`);
      return;
    }

    setHeaderTitle(`퀴즈 ${playingData.slideIndex + 1}번 / 총 ${playingData.slideList.length}문제`);
  }, [playingData]);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          // px: "10px",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "10px",
            alignItems: "center",
          }}
        >
          {/* <Box sx={{ display: "flex", gap: "5px", alignItems: "center", opacity: "0.75" }}>
            <img src={Logo} alt="logo" style={{ width: "20px", height: "20px" }} />

            <Typography sx={{ font: "700 18px Pretendard" }}>
              Withplus Live - 라이브 퀴즈
            </Typography>
          </Box> */}
          <Header
            // title={
            //   playingData.isAnswerOpened
            //     ? "정답 공개!"
            //     : `퀴즈 ${playingData.slideIndex + 1}번 / 총 ${playingData.slideList.length}문제`
            // }
            title={headerTitle}
            isStatic
          />
          {playingData.isStarted && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: "50%",
                transform: !playingData.isTimeOver
                  ? "translate(-50%, 10px)"
                  : "translate(-50%, -110%)",
                // transform: "translate(-50%, 10px)",
                transition: "transform .25s",
                // transitionDelay: "0.25s",
                // bgcolor: "#fff",
                background: `url(${clock})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                // p: "10px",
                borderRadius: "10px",
                // filter: "drop-shadow(0px 0px 8px #00000038)",
                // boxShadow: "0px 0px 8px #00000038",
                // boxShadow: "0px 0px 8px #00000038",
                width: "135px",
                // height: "185px",
                aspectRatio: "427/486",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  // flexShrink: 0,
                  width: "123px",
                  height: "123px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  transform: "translateY(-2px)",

                  background: playingData.isTimeOver
                    ? "#efefef"
                    : `conic-gradient(${
                        timer / (playingData.slideList[playingData.slideIndex].timer * 1) > 0.5
                          ? "#3f5bae"
                          : "#ff4d4d"
                      } var(--angle), #efefef var(--angle))`,

                  animation: `conic-effect ${
                    playingData.slideList[playingData.slideIndex].timer
                  }s linear`,

                  "@keyframes conic-effect": {
                    "0%": {
                      "--angle": "360deg",
                    },
                    "100%": {
                      "--angle": "0deg",
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    bgcolor: "#fff",
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                    boxShadow: "0px 0px 8px #00000038",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    font: "800 40px Pretendard",
                  }}
                >
                  {Math.ceil(timer)}
                </Box>
              </Box>
            </Box>
          )}

          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              position: "absolute",
              top: 0,
              right: 10,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                bgcolor: "#fff",
                borderRadius: "20px",
                p: "5px 10px",
              }}
            >
              <Typography sx={{ font: "600 18px Pretendard" }}>
                {playingData.submittedAnswers.length} / {users.length}
              </Typography>
              <PeopleIcon sx={{ ml: 1 }} />
            </Box>

            <Box
              sx={{
                display: "flex",
                borderRadius: "50px",
                border: "1px solid #eee",
                bgcolor: "#fff",
              }}
            >
              <input
                type="range"
                min="0"
                max="100"
                value={bgmVolumn * 200}
                onChange={handleBGMVolume}
                style={{
                  accentColor: "#c26974",
                }}
              />
              <IconButton size="small" onClick={handleMuteSound}>
                {bgmVolumn === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
              </IconButton>
            </Box>

            <IconButton
              onClick={handleFullScreen}
              sx={{
                borderRadius: "50px",
                border: "1px solid #eee",
                bgcolor: "#fff",
                "&:hover": { bgcolor: "#eee" },
              }}
              size="small"
            >
              {handle.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box> */}
        </Box>

        {/* <Box sx={styles.slide.timeline_container}>
          <Typography sx={{ font: "bold 18px Pretendard", mr: "10px" }}>
            {playingData.slideIndex + 1} / {playingData.slideList.length}
          </Typography>

          <Box sx={styles.slide.timeline_box}>
            <Box sx={styles.slide.timeline_full_width}>
              <Box
                sx={{
                  position: "absolute",
                  height: "10px",
                  bgcolor: "#c33c3c",
                  width: `${
                    playingData.slideList[playingData.slideIndex].timer === "0"
                      ? 100
                      : (timer / playingData.slideList[playingData.slideIndex].timer) * 100
                  }%`,
                  borderRadius: "10px",
                  transition: "width 0.25s linear",
                }}
              />
            </Box>
          </Box>

          <Box sx={styles.slide.timeline_text_box}>
            <AlarmIcon sx={styles.slide.alarm_icon} />
            <Typography sx={{ font: "bold 18px Pretendard" }}>
              {playingData.slideList[playingData.slideIndex].timer === "0"
                ? "제한없음"
                : `${Math.ceil(timer)}초`}
            </Typography>
          </Box>
        </Box> */}

        <Box
          sx={{
            ...styles.slide.quiz_box,
            position: "relative",
            display: "flex",
            boxSizing: "border-box",
            mx: "10px",
          }}
        >
          {currentView}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", pb: "10px", minHeight: "50px" }}>
          {/* <Button
            sx={{ mx: "10px" }}
            size="large"
            onClick={startQuiz}
            disabled={playingData.isStarted}
          >
            시작하기
          </Button> */}
          <Button
            sx={{
              p: "10px 20px",
              font: "700 20px Pretendard",
              bgcolor: "#d6e2fb",
              color: "#50586C",
              "&:hover": { bgcolor: "#c6d2eb" },
              display: !playingData.isTimeOver || playingData.isAnswerOpened ? "none" : "",
            }}
            size="large"
            disabled={!playingData.isTimeOver || playingData.isAnswerOpened}
            onClick={showQuestionAnswer}
          >
            <FlakyIcon sx={{ mr: "10px", fontSize: "28px" }} />
            정답 공개
          </Button>

          <Button
            sx={{
              p: "10px 20px",
              font: "700 20px Pretendard",
              bgcolor: "#d6e2fb",
              color: "#50586C",
              "&:hover": { bgcolor: "#c6d2eb" },
              display: !playingData.isAnswerOpened || playingData.isResultOpened ? "none" : "",
            }}
            size="large"
            disabled={!playingData.isAnswerOpened || playingData.isResultOpened}
            onClick={showQuestionResult}
          >
            <LeaderboardIcon sx={{ mr: "10px", fontSize: "28px" }} />
            결과보기
          </Button>
          {!playingData.isAllQuizOver && (
            <Button
              sx={{
                font: "700 20px Pretendard",
                bgcolor: "#d6e2fb",
                color: "#50586C",
                "&:hover": { bgcolor: "#c6d2eb" },
                display: playingData.isResultOpened || playingData.isAllQuizOver ? "" : "none",
                p: "10px 20px",
              }}
              size="large"
              disabled={!playingData.isResultOpened || playingData.isAllQuizOver}
              onClick={
                playingData.slideIndex === playingData.slideList.length - 1 ? quizResult : nextQuiz
              }
            >
              <NavigateNextIcon sx={{ mr: "10px", fontSize: "28px" }} />
              {playingData.slideIndex === playingData.slideList.length - 1
                ? "최종 결과"
                : "다음 퀴즈"}
            </Button>
          )}

          {playingData.isAllQuizOver && (
            <Button
              sx={{
                font: "700 20px Pretendard",
                bgcolor: "#d6e2fb",
                color: "#50586C",
                "&:hover": { bgcolor: "#c6d2eb" },
                p: "10px 20px",
              }}
              size="large"
              onClick={goToOutro}
            >
              <NavigateNextIcon sx={{ mr: "10px", fontSize: "28px" }} />
              다음
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export const QuizContainer = () => {
  const { id } = useParams();

  const { playingData, setPlayingData, socket } = useContext(QuizSlideContext);

  const [percentage, setPercentage] = useState(5);

  const startQuiz = () => {
    if (playingData.isStarted) return;
    // return;

    socket.emit("quizStart", { quizID: id });
    if (playingData.slideList[playingData.slideIndex].timer === "0") {
      setPlayingData({ ...playingData, isTimeOver: true });
    }
  };

  useEffect(() => {
    const Timer = setInterval(() => {
      if (percentage <= 0) {
        clearInterval(Timer);
        if (playingData.isTimeOver) return;
        startQuiz();
      }
      setPercentage((prevPercentage) => (prevPercentage <= 0 ? 0 : prevPercentage - 1));
    }, 1000);

    return () => {
      clearInterval(Timer);
    };
  }, [percentage]);

  return (
    <>
      {(() => {
        switch (playingData.slideList[playingData.slideIndex].type) {
          case "객관식":
            return <SelectQuiz />;
          // case "단답형":
          //   return <ShortAnswerSlide />;
          case "OX 퀴즈":
            return <OXQuiz />;
          case "초성 퀴즈":
            return <InitialQuiz />;
          default:
            break;
        }
      })()}

      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          bgcolor: "#f5f5f5",
          // bgcolor: "#f5f5f588",
          borderRadius: "8px",
          display: playingData.isStarted ? "none" : "flex",
          flexDirection: "column",
          // justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          p: "10px",
          boxSizing: "border-box",
        }}
      >
        <Typography sx={{ font: "bold 48px Pretendard" }}>
          QUIZ.{playingData.slideIndex + 1}
        </Typography>

        <Typography sx={{ font: "bold 40px Pretendard" }}>
          {`[${playingData.slideList[playingData.slideIndex].type}]`}
        </Typography>

        <Typography sx={{ font: "900 48px Pretendard", lineHeight: 1.5 }}>
          {playingData.slideList[playingData.slideIndex].question}
        </Typography>

        <Typography sx={{ font: "800 40px Pretendard", lineHeight: 1.5, color: "#77f" }}>
          [{playingData.slideList[playingData.slideIndex].score}점]
        </Typography>

        <Box
          sx={{
            flex: 1,
            // bgcolor: "yellow",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Box
            sx={{
              bgcolor: "#eee",
              height: "100%",
              aspectRatio: 1,
              borderRadius: "50%",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 0px 6px #00000038",
            }}
          >
            <Box
              sx={{
                background: `conic-gradient(#3f5bae var(--angle), #efefef var(--angle))`,
                position: "absolute",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                animation: "conic-effect 5s linear",

                "@keyframes conic-effect": {
                  "0%": {
                    "--angle": "360deg",
                  },
                  "100%": {
                    "--angle": "0deg",
                  },
                },
              }}
            />
            <Box
              sx={{
                position: "absolute",
                height: "calc(100% - 80px)",
                bgcolor: "#f5f5f5",
                aspectRatio: 1,
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                font: "800 100px Pretendard",
                fontSize: "clamp(62.5px, 5.208vw, 100px)",
                boxShadow: "0px 0px 10px 2px #00000038",
              }}
            >
              {percentage === 0 ? "시작" : Math.ceil(percentage)}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const SelectQuiz = () => {
  const { playingData } = useContext(QuizSlideContext);

  const [maxHeight, setMaxHeight] = useState("300px");
  const [openVotes, setOpenVotes] = useState(false);
  const [votes, setVotes] = useState([]);

  const [showAnswerList, setShowAnswerList] = useState([]);
  // const [votesRate, setVotesRate] = useState([]);

  const handleShowAnswer = (e) => {
    if (!playingData.isAnswerOpened) return;

    const idx = showAnswerList.indexOf(e.currentTarget.id);

    if (idx === -1) {
      setShowAnswerList([...showAnswerList, e.currentTarget.id]);
    } else {
      setShowAnswerList(showAnswerList.filter((item) => item !== e.currentTarget.id));
    }
  };

  useEffect(() => {
    if (!playingData) return;

    if (playingData.isAnswerOpened) {
      setTimeout(() => {
        setMaxHeight("50px");
      }, 1000);
    }
  }, [playingData]);

  useEffect(() => {
    if (maxHeight === "50px") {
      // console.log(playingData.submittedAnswers);
      // console.log(playingData.slideList[playingData.slideIndex].answers);

      const answers = playingData.slideList[playingData.slideIndex].answers;
      let obj = {};
      for (let i = 0; i < answers.length; i++) {
        obj[answers[i].id] = 0;
      }
      // console.log(obj);

      // for (let i = 0; i < answers.length; i++) {
      //   const answerId = answers[i].id;

      for (let j = 0; j < playingData.submittedAnswers.length; j++) {
        for (let k = 0; k < playingData.submittedAnswers[j].selectedAnswers.length; k++) {
          obj[playingData.submittedAnswers[j].selectedAnswers[k]] =
            obj[playingData.submittedAnswers[j].selectedAnswers[k]] === undefined
              ? 1
              : obj[playingData.submittedAnswers[j].selectedAnswers[k]] + 1;
        }
        // if (answerId === playingData.submittedAnswers[j].answerID) {
        //   console.log(playingData.submittedAnswers[j].ans);
        // }
      }

      // console.log(obj);
      const values = Object.values(obj);
      const max = Math.max(...values);

      let votesRate = values.map((value) => {
        return {
          rate: max === 0 ? "0%" : `${((value / max) * 100).toString().slice(0, 5)}%`,
          value: value,
        };
      });

      // setTimeout(() => {
      setVotes(votesRate);
      setOpenVotes(true);
      // }, 500);
    }
  }, [maxHeight]);

  return (
    <>
      <Grid container sx={{ ...styles.slide.question_box, py: "14px" }}>
        <Typography
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            font: "700 32px Pretendard",
            color: "#343434",
          }}
        >
          퀴즈 {playingData.slideIndex + 1}번 / 총 {playingData.slideList.length}문제
        </Typography>

        <Grid item xs={7}>
          <Typography sx={{ ...styles.slide.question_text, fontSize: "40px" }}>
            {playingData.slideList[playingData.slideIndex].question}
            {playingData.slideList[playingData.slideIndex].isMultiple === "true" ? (
              <span style={{ font: "600 36px Pretendard", color: "#777" }}>{" (복수 선택)"}</span>
            ) : (
              <></>
            )}
          </Typography>
        </Grid>

        <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end", height: "100%" }}>
          <Box
            sx={{
              // width: "444px",
              // height: "250px",
              height: "100%",
              aspectRatio: "16/9",
              maxHeight: "300px",
              maxWidth: "533px",
              border: "1px solid #eee",
              borderRadius: "10px",
              overflow: "hidden",
              margin: "auto 0",
            }}
          >
            {playingData.slideList[playingData.slideIndex].mediaName && (
              <img
                src={`https://quiz.withplus.live/image?id=${
                  playingData.slideList[playingData.slideIndex].id
                }&type=cropped`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ ...styles.slide.answers_grid }}>
        {playingData.slideList[playingData.slideIndex].answers.map((answer, idx) => (
          <Grid
            item
            xs={12 / playingData.slideList[playingData.slideIndex].answers.length}
            sx={styles.slide.answer_item}
            key={answer.id}
          >
            {openVotes && (
              <Box
                sx={{
                  position: "relative",
                  bgcolor: bgcolors[idx],
                  width: "100px",
                  height: "0%",
                  // height: votes[idx].rate === "0%" ? "5px" : votes[idx].rate,
                  // opacity: 0,
                  overflow: "hidden",
                  // top: 0,
                  // left: "50%",
                  // transform: "translateX(-50%)",
                  // height: "auto",
                  animation: "grow-up 1s ease forwards",
                  animationDelay: `${idx * 1 * 0.25 + 0.75}s`,
                  "--value": votes[idx].rate === "0%" ? "5px" : votes[idx].rate,

                  "@keyframes grow-up": {
                    "0%": {
                      height: "0%",
                      overflow: "hidden",
                    },
                    "100%": {
                      height: "var(--value)",
                      overflow: "visible",
                    },
                  },
                  // height: "100%",
                  // transition: ".5s ease",
                  // zIndex: 10,
                }}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: votes[idx].value === 0 ? -35 : -20,
                    left: "50%",
                    transform: "translateX(-50%)",
                    textAlign: "center",
                    font: "800 33px Pretendard",
                    animation: "fade 1s ease forwards",
                    opacity: 0,
                    animationDelay: `${idx * 1 * 0.25 + 0.75}s`,
                    WebkitTextStroke: "1px #000",
                    color: "#fff",
                    textShadow: "3px 3px 2px #00000080",
                    zIndex: 2,

                    "@keyframes fade": {
                      "0%": {
                        opacity: 0,
                      },
                      "100%": {
                        opacity: 1,
                      },
                    },
                  }}
                >
                  {votes[idx].value}
                </Typography>

                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    bgcolor:
                      playingData.isAnswerOpened &&
                      !playingData.slideList[playingData.slideIndex].correctAnswerIDs.includes(
                        answer.id
                      )
                        ? "#00000090"
                        : "",
                    zIndex: 1,
                  }}
                />
              </Box>
            )}

            <Box
              id={answer.id}
              onClick={handleShowAnswer}
              sx={{
                ...styles.slide.answer_box,
                backgroundImage: `url("https://quiz.withplus.live/image?id=${answer.id}&type=cropped")`,
                backgroundSize: "cover",
                border: "7px solid #000",
                borderColor:
                  playingData.isAnswerOpened &&
                  playingData.slideList[playingData.slideIndex].correctAnswerIDs.includes(answer.id)
                    ? "#00ff00"
                    : "#aaa",
                bgcolor: bgcolors[idx % 6],
                cursor: "pointer",
                // maxHeight,
                maxHeight: showAnswerList.includes(answer.id) ? "300px" : maxHeight,
              }}
            >
              <Typography
                sx={{
                  color:
                    playingData.isAnswerOpened &&
                    playingData.slideList[playingData.slideIndex].correctAnswerIDs.includes(
                      answer.id
                    )
                      ? "#00ff00"
                      : "#fff",
                  font: "900 20px Pretendard",
                  fontSize: "clamp(18px, 1.5vw, 29px)",
                  fontStyle: "italic",
                  textShadow: "3px 3px 2px #00000080",
                  // position: maxHeight === "50px" ? "static" : "absolute",
                  position: "absolute",
                  display: "flex",
                  justifyContent: showAnswerList.includes(answer.id)
                    ? "flex-end"
                    : maxHeight === "50px"
                    ? "center"
                    : "flex-end",
                  alignItems: "center",
                  width: "100%",
                  WebkitTextStroke: "1px #000",
                  top: showAnswerList.includes(answer.id)
                    ? "2px"
                    : maxHeight === "50px"
                    ? ""
                    : "2px",
                  right: showAnswerList.includes(answer.id)
                    ? "5px"
                    : maxHeight === "50px"
                    ? ""
                    : "5px",
                }}
              >
                {idx + 1}.
              </Typography>

              <Typography
                id={answer.id}
                sx={{
                  ...styles.slide.answer_text,
                  // display: "flex",
                  fontSize: "1.7vw",
                  fontSize: "clamp(20px, 1.7vw, 32px)",
                  textShadow: "3px 3px 2px #00000080",
                  textAlign: "center",
                  WebkitTextStroke: "1px #000000",
                  // wordBreak: "keep-all",
                  // bgcolor: "#aa000080",
                  color:
                    playingData.isAnswerOpened &&
                    playingData.slideList[playingData.slideIndex].correctAnswerIDs.includes(
                      answer.id
                    )
                      ? "#00ff00"
                      : "#fff",
                  bgcolor:
                    playingData.isAnswerOpened &&
                    !playingData.slideList[playingData.slideIndex].correctAnswerIDs.includes(
                      answer.id
                    )
                      ? "#00000090"
                      : "",
                }}
              >
                {showAnswerList.includes(answer.id)
                  ? answer.text
                  : maxHeight !== "50px" && answer.text}
                {/* {answer.text} */}
              </Typography>
            </Box>
          </Grid>
        ))}
        {playingData?.isAnswerOpened && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              font: "600 14px Pretendard",
              color: "#999",
            }}
          >
            {"(정답 내용을 확인하려면 해당 보기를 클릭하세요)"}
          </Box>
        )}
      </Grid>
    </>
  );
};

const OXQuiz = () => {
  const { playingData } = useContext(QuizSlideContext);

  const [maxHeight, setMaxHeight] = useState("300px");
  const [openVotes, setOpenVotes] = useState(false);
  const [votes, setVotes] = useState([]);

  useEffect(() => {
    if (playingData.isAnswerOpened) {
      setTimeout(() => {
        setMaxHeight("50px");
      }, 1000);
    }
  }, [playingData]);

  useEffect(() => {
    if (maxHeight === "50px") {
      let obj = { O: 0, X: 0 };

      for (let j = 0; j < playingData.submittedAnswers.length; j++) {
        for (let k = 0; k < playingData.submittedAnswers[j].selectedAnswers.length; k++) {
          obj[playingData.submittedAnswers[j].selectedAnswers[k]] =
            obj[playingData.submittedAnswers[j].selectedAnswers[k]] === undefined
              ? 1
              : obj[playingData.submittedAnswers[j].selectedAnswers[k]] + 1;
        }
      }

      const values = Object.values(obj);
      const max = Math.max(...values);

      let votesRate = values.map((value) => {
        return {
          rate: max === 0 ? "0%" : `${((value / max) * 100).toString().slice(0, 5)}%`,
          value: value,
        };
      });

      setVotes(votesRate);
      setOpenVotes(true);
    }
  }, [maxHeight]);

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", overflow: "auto" }}>
      <Grid
        container
        sx={{
          borderBottom: 1,
          borderColor: "#cdcdcd",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
        }}
      >
        <Typography
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            font: "700 32px Pretendard",
            color: "#343434",
          }}
        >
          퀴즈 {playingData.slideIndex + 1}번 / 총 {playingData.slideList.length}문제
        </Typography>

        <Grid item xs={7}>
          <Typography sx={{ ...styles.slide.question_text, fontSize: "40px" }}>
            {playingData.slideList[playingData.slideIndex].question}
          </Typography>
        </Grid>

        <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end", height: "100%" }}>
          <Box
            sx={{
              height: "100%",
              aspectRatio: "16/9",
              maxHeight: "300px",
              maxWidth: "533px",
              margin: "auto 0",
              // width: "444px",
              // height: "250px",
              border: "1px solid #eee",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {playingData.slideList[playingData.slideIndex].mediaName && (
              <img
                src={`https://quiz.withplus.live/image?id=${
                  playingData.slideList[playingData.slideIndex].id
                }&type=cropped`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ py: "10px", height: "50%" }}>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {openVotes && (
            <Box
              sx={{
                width: "100px",
                position: "relative",
                bgcolor:
                  playingData.slideList[playingData.slideIndex].answer === "O" ? "#0d0" : "#eee",
                height: "0%",
                overflow: "hidden",
                animation: "grow-up 1s ease forwards",
                animationDelay: `${0 * 1 * 0.25 + 0.75}s`,
                "--value": votes[0].rate === "0%" ? "5px" : votes[0].rate,

                "@keyframes grow-up": {
                  "0%": {
                    height: "0%",
                    overflow: "hidden",
                  },

                  "100%": {
                    height: "var(--value)",
                    overflow: "visible",
                  },
                },
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: votes[0].value === 0 ? -35 : -20,
                  left: "50%",
                  transform: "translateX(-50%)",
                  textAlign: "center",
                  font: "800 33px Pretendard",
                  animation: "fade 1s ease forwards",
                  opacity: 0,
                  animationDelay: `${0 * 1 * 0.25 + 0.75}s`,
                  WebkitTextStroke: "1px #000",
                  color: "#fff",
                  textShadow: "3px 3px 2px #00000080",
                  zIndex: 2,

                  "@keyframes fade": {
                    "0%": {
                      opacity: 0,
                    },
                    "100%": {
                      opacity: 1,
                    },
                  },
                }}
              >
                {votes[0].value}
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              width: "300px",
              height: "auto",
              aspectRatio: "auto 4/3",
              border: 3,
              borderColor: "#cdcdcd",
              bgcolor:
                playingData.isAnswerOpened &&
                playingData.slideList[playingData.slideIndex].answer === "O"
                  ? "#00ff00"
                  : "",
              borderRadius: "16px",
              boxShadow: "3px 3px 6px #00000050",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxHeight,
              transition: "max-height 0.5s",
            }}
          >
            <PanoramaFishEyeIcon
              sx={{
                fontSize: maxHeight === "50px" ? "45px" : "12vw",
                color: "red",
                transition: "font-size 0.4s",
              }}
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {openVotes && (
            <Box
              sx={{
                width: "100px",
                position: "relative",
                bgcolor:
                  playingData.slideList[playingData.slideIndex].answer === "X" ? "#0d0" : "#eee",
                height: "0%",
                overflow: "hidden",
                animation: "grow-up 1s ease forwards",
                animationDelay: `${0 * 1 * 0.25 + 0.75}s`,
                "--value": votes[1].rate === "0%" ? "5px" : votes[1].rate,

                "@keyframes grow-up": {
                  "0%": {
                    height: "0%",
                    overflow: "hidden",
                  },

                  "100%": {
                    height: "var(--value)",
                    overflow: "visible",
                  },
                },
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: votes[1].value === 0 ? -35 : -20,
                  left: "50%",
                  transform: "translateX(-50%)",
                  textAlign: "center",
                  font: "800 33px Pretendard",
                  animation: "fade 1s ease forwards",
                  opacity: 0,
                  animationDelay: `${1 * 1 * 0.25 + 0.75}s`,
                  WebkitTextStroke: "1px #000",
                  color: "#fff",
                  textShadow: "3px 3px 2px #00000080",
                  zIndex: 2,

                  "@keyframes fade": {
                    "0%": {
                      opacity: 0,
                    },
                    "100%": {
                      opacity: 1,
                    },
                  },
                }}
              >
                {votes[1].value}
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              width: "300px",
              height: "auto",
              aspectRatio: "auto 4/3",
              border: 3,
              borderColor: "#cdcdcd",
              bgcolor:
                playingData.isAnswerOpened &&
                playingData.slideList[playingData.slideIndex].answer === "X"
                  ? "#00ff00"
                  : "",
              borderRadius: "16px",
              boxShadow: "3px 3px 6px #00000050",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxHeight,
              transition: "max-height 0.5s",
            }}
          >
            <ClearIcon
              sx={{
                color: "blue",
                fontSize: maxHeight === "50px" ? "45px" : "12vw",
                transition: "font-size 0.4s",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const InitialQuiz = () => {
  const { playingData, users } = useContext(QuizSlideContext);

  const [initials, setInitials] = useState([]);
  const [isAnswerOpened, setIsAnswerOpened] = useState(false);
  const [isVoteRateOpened, setIsVoteRateOpened] = useState(false);
  const [openVotes, setOpenVotes] = useState(false);
  const [votes, setVotes] = useState([]);

  useEffect(() => {
    if (!playingData) return;

    const answer = playingData.slideList[playingData.slideIndex].answer.split("");
    let newInitials = [];

    for (let i = 0; i < answer.length; i++) {
      newInitials.push(divideHangul(answer[i]));
    }

    if (newInitials.join("") === initials.join("")) return;

    setInitials(newInitials);
  }, [initials]);

  useEffect(() => {
    if (!playingData) return;

    if (playingData.isAnswerOpened) {
      setIsAnswerOpened(true);
    }
  }, [playingData]);

  useEffect(() => {
    if (isAnswerOpened) {
      let obj = { correct: 0, wrong: 0 };

      for (let j = 0; j < playingData.submittedAnswers.length; j++) {
        if (
          playingData.submittedAnswers[j].selectedAnswers[0] ===
          playingData.slideList[playingData.slideIndex].answer
        ) {
          obj.correct++;
        } else {
          obj.wrong++;
        }
      }

      const values = Object.values(obj);
      const max = Math.max(...values);

      let votesRate = values.map((value) => {
        return {
          rate: max === 0 ? "0%" : `${((value / max) * 100).toString().slice(0, 5)}%`,
          value: value,
        };
      });

      setVotes(votesRate);

      setTimeout(() => {
        setIsVoteRateOpened(true);
      }, 1000);
    }
  }, [isAnswerOpened]);

  useEffect(() => {
    if (isVoteRateOpened) {
      setOpenVotes(true);
    }
  }, [isVoteRateOpened]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ flex: 1, borderBottom: "1px solid #eee", position: "relative" }}>
        <Typography
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            font: "700 32px Pretendard",
            color: "#343434",
          }}
        >
          퀴즈 {playingData.slideIndex + 1}번 / 총 {playingData.slideList.length}문제
        </Typography>

        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={7}>
            <Typography
              sx={{
                ...styles.slide.question_text,
                fontSize: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {playingData.slideList[playingData.slideIndex].question}
            </Typography>
          </Grid>

          <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end", height: "100%" }}>
            <Box
              sx={{
                height: "100%",
                aspectRatio: "16/9",
                maxHeight: "300px",
                maxWidth: "533px",
                margin: "auto 0",
                border: "1px solid #eee",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              {playingData.slideList[playingData.slideIndex].mediaName && (
                <img
                  src={`https://quiz.withplus.live/image?id=${
                    playingData.slideList[playingData.slideIndex].id
                  }&type=cropped`}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          height: "300px",
          width: "100%",
          display: "flex",
          overflow: "hidden",
        }}
      >
        {!isAnswerOpened && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: "10px",
              justifyContent: "center",
              alignItems: "end",
              p: "10px 0 20px 0",
              // position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
            }}
          >
            {initials.map((item, idx) => (
              <Typography
                key={idx}
                sx={{
                  font: "900 32px Pretendard",
                  fontSize: "clamp(51px, 3.646vw, 70px)",
                  border: "2px solid #aaa",
                  color: "#333",
                  p: item === " " ? "0px" : "5px",
                  px: "clamp(12.39px, 0.8854vw, 17px)",
                  borderRadius: "10px",
                  boxSizing: "border-box",
                  opacity: item === " " ? "0" : "1",
                  boxShadow:
                    "inset 0 -2px 0 #aaaaaa, inset 0px 1px 1px -1px #fff, 0px 1px 1px 0px #7a7a7a;",
                }}
              >
                {item}
              </Typography>
            ))}
          </Box>
        )}

        {isAnswerOpened && (
          <>
            <Box
              sx={{
                width: isVoteRateOpened ? "50%" : "100%",
                // width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
                // bgcolor: "red",
                flexShrink: 0,
                transition: "width 0.5s",
                p: "10px 0 20px 0",
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  // bgcolor: "red",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  width: "100px",
                  mb: "5px",
                }}
              >
                {openVotes && (
                  <Box
                    sx={{
                      height: "0%",
                      position: "relative",
                      bgcolor: "#00b890",
                      overflow: "hidden",
                      animation: "grow-up 1s ease forwards",
                      animationDelay: `${0 * 1 * 0.25 + 0.75}s`,
                      "--value": votes[0].rate === "0%" ? "5px" : votes[0].rate,

                      "@keyframes grow-up": {
                        "0%": {
                          height: "0%",
                          overflow: "hidden",
                        },

                        "100%": {
                          height: "var(--value)",
                          overflow: "visible",
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        position: "absolute",
                        top: votes[0].value === 0 ? -35 : -20,
                        left: "50%",
                        transform: "translateX(-50%)",
                        textAlign: "center",
                        font: "800 33px Pretendard",
                        animation: "fade 1s ease forwards",
                        opacity: 0,
                        animationDelay: `${0 * 1 * 0.25 + 0.75}s`,
                        WebkitTextStroke: "1px #000",
                        color: "#fff",
                        textShadow: "3px 3px 2px #00000080",
                        zIndex: 2,

                        "@keyframes fade": {
                          "0%": {
                            opacity: 0,
                          },
                          "100%": {
                            opacity: 1,
                          },
                        },
                      }}
                    >
                      {votes[0].value}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                {playingData.slideList[playingData.slideIndex].answer.split("").map((item, idx) => (
                  <Typography
                    key={idx}
                    sx={{
                      font: "900 32px Pretendard",
                      fontSize: isVoteRateOpened
                        ? "clamp(21.875px, 1.8229vw, 35px)"
                        : "clamp(51px, 3.646vw, 70px)",
                      border: "2px solid #00b890",
                      bgcolor: "#00b890",
                      color: "#fff",
                      textShadow: "3px 3px 2px #00000080",
                      p: item === " " ? "0px" : "5px",
                      px: isVoteRateOpened
                        ? "clamp(5.3125px, 0.4427vw, 8.5px)"
                        : "clamp(12.39px, 0.8854vw, 17px)",
                      borderRadius: "10px",
                      boxSizing: "border-box",
                      opacity: item === " " ? "0" : "1",
                      boxShadow: "3px 3px 6px 2px #00000038",
                      transition: "all 0.5s",
                    }}
                  >
                    {item}
                  </Typography>
                ))}
              </Box>
            </Box>

            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
                p: "10px 0 20px 0",
                borderLeft: "1px solid #eee",
              }}
            >
              <Box
                sx={{
                  // bgcolor: "red",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  width: "100px",
                  mb: "5px",
                }}
              >
                {openVotes && (
                  <Box
                    sx={{
                      position: "relative",
                      bgcolor: "#ff4949",
                      height: "0%",
                      overflow: "hidden",
                      animation: "grow-up 1s ease forwards",
                      animationDelay: `${1 * 1 * 0.25 + 0.75}s`,
                      "--value": votes[1].rate === "0%" ? "5px" : votes[1].rate,

                      "@keyframes grow-up": {
                        "0%": {
                          height: "0%",
                          overflow: "hidden",
                        },

                        "100%": {
                          height: "var(--value)",
                          overflow: "visible",
                        },
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        position: "absolute",
                        top: votes[1].value === 0 ? -35 : -15,
                        left: "50%",
                        transform: "translateX(-50%)",
                        textAlign: "center",
                        font: "800 33px Pretendard",
                        animation: "fade 1s ease forwards",
                        opacity: 0,
                        animationDelay: `${1 * 1 * 0.25 + 0.75}s`,
                        WebkitTextStroke: "1px #000",
                        color: "#fff",
                        textShadow: "3px 3px 2px #00000080",
                        zIndex: 2,

                        "@keyframes fade": {
                          "0%": {
                            opacity: 0,
                          },
                          "100%": {
                            opacity: 1,
                          },
                        },
                      }}
                    >
                      {votes[1].value}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box sx={{ display: "flex", gap: "10px" }}>
                {"오답자".split("").map((item, idx) => (
                  <Typography
                    key={idx}
                    sx={{
                      font: "900 32px Pretendard",
                      // fontSize: "clamp(51px, 3.646vw, 70px)",
                      fontSize: "clamp(21.875px, 1.8229vw, 35px)",
                      border: "2px solid #ff4949",
                      bgcolor: "#ff4949",
                      color: "#fff",
                      textShadow: "3px 3px 2px #00000080",
                      p: item === " " ? "0px" : "5px",
                      // px: "clamp(12.39px, 0.8854vw, 17px)",
                      px: "clamp(5.3125px, 0.4427vw, 8.5px)",
                      borderRadius: "10px",
                      boxSizing: "border-box",
                      opacity: item === " " ? "0" : "1",
                      boxShadow: "3px 3px 6px 2px #00000038",
                    }}
                  >
                    {item}
                  </Typography>
                ))}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export const MidResultContainer = () => {
  const { playingData, users, playingUsers } = useContext(QuizSlideContext);

  const [temp, setTemp] = useState([]);
  const [maxScore, setMaxScore] = useState(0);
  const [showTotalScore, setShowTotalScore] = useState(false);

  useEffect(() => {
    let ranks = playingData.submittedAnswers.map((user, index) => {
      const myUser = users.find((item) => item.uid + "" === user.uid);

      if (!myUser) {
        return {
          uid: user.uid,
          name: user.name,
          imgIdx: user.imgIdx,
          score: user.score,
          rank: index + 1,
          beforeScore: 0,
        };
      }

      return {
        uid: user.uid,
        name: user.name,
        imgIdx: user.imgIdx,
        score: user.score,
        rank: index + 1,
        beforeScore: myUser.score - user.score,
      };
    });

    let unsubmitedUsers = users.filter(
      (user) => !playingData.submittedAnswers.find((item) => item.uid === user.uid)
    );

    for (let user of unsubmitedUsers) {
      ranks.push({
        uid: user.uid,
        name: user.name,
        imgIdx: user.imgIdx,
        score: 0,
        rank: ranks.length + 1,
        beforeScore: user.score,
      });
    }

    // let maxScore = Math.max(...users.map((user) => user.score * 1));
    let maxScore = 0;

    // for(let user of users) {
    //   let score = playingData.submittedAnswers.find((item) => item.uid === user.uid)?.score * 1;
    //   if(score > maxScore) {
    //     maxScore = score;
    //   }
    // }

    // if (playingData.slideIndex === playingData.slideList.length - 1) {
    if (playingData.slideIndex === playingData.slideList.length + 1) {
      // let scores = playingData.submittedAnswers.map((item) => item.score * 1);
      // maxScore = Math.max.apply(null, scores);

      let submittedAnswers = playingData.submittedAnswers;

      for (let user of submittedAnswers) {
        let score = user.score * 1;

        if (score > maxScore) {
          maxScore = score;
        }
      }
    } else {
      for (let user of users) {
        let score = user.score * 1;

        if (score > maxScore) {
          maxScore = score;
        }
      }
    }

    setMaxScore(maxScore);
    // for(let user of users) {

    // }

    // console.log(ranks);
    setTemp(ranks);
  }, []);

  useEffect(() => {
    if (temp.length === 0) return;

    // setTimeout(() => {
    // setTemp((prev) => {
    //   return prev.map((item) => {
    //     let rank =
    //       users.sort((a, b) => b.score - a.score).findIndex((user) => user.uid === item.uid) + 1;

    //     if (rank === 0) {
    //       rank = item.rank;
    //     }

    //     return {
    //       ...item,
    //       rank: rank,
    //     };
    //   });
    // });

    // setShowTotalScore(true);
    // }, 5500);
  }, [temp]);

  useEffect(() => {
    if (!showTotalScore) return;

    setTemp((prev) => {
      return prev.map((item) => {
        let rank =
          users.sort((a, b) => b.score - a.score).findIndex((user) => user.uid === item.uid) + 1;

        if (rank === 0) {
          rank = item.rank;
        }

        return {
          ...item,
          rank: rank,
        };
      });
    });
    // }, 1000);
  }, [showTotalScore, users]);

  return (
    <Box sx={{ flex: 1, py: "10px", bgcolor: "#fff", position: "relative" }}>
      <Box
        sx={{
          height: "100%",
          overflow: "auto",
          position: "absolute",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: "10px",
            height: "70px",
            position: "relative",
          }}
        >
          <EmojiEventsIcon
            sx={{
              color: "gold",
              fontSize: "60px",
              mr: "10px",
            }}
          />
          <Typography sx={{ font: "800 39px Pretendard" }}>
            {playingData.slideIndex + 1}라운드 결과
          </Typography>

          {playingData.slideIndex !== 0 &&
            playingData.slideIndex !== playingData.slideList.length - 1 && (
              <Box sx={{ position: "absolute", right: 10 }}>
                <Button
                  variant="contained"
                  sx={{
                    font: "700 20px Pretendard",
                    bgcolor: "#d6e2fb",
                    color: "#50586C",
                    "&:hover": { bgcolor: "#c6d2eb" },
                    p: "10px 20px",
                  }}
                  onClick={() => setShowTotalScore(true)}
                  disabled={showTotalScore}
                >
                  총합 점수 보기
                </Button>
              </Box>
            )}
        </Box>

        {/* {playingData.submittedAnswers.map((item, index) => ( */}
        <Box sx={{ position: "relative", height: "100%", flex: 1, overflow: "auto" }}>
          {temp.map((item) => (
            <Box
              sx={{
                position: "absolute",
                // top: `${80 + 175 * (item.rank - 1)}px`,
                top: `${175 * (item.rank - 1)}px`,
                left: "20px",
                borderRadius: "16px",
                // p: "10px",
                boxSizing: "border-box",
                boxShadow: "0px 0px 10px 0px #00000030",
                width: "calc(100% - 40px)",
                // m: "0 auto 20px auto",
                // bgcolor: "#f9f5ff",
                border: "1px solid #cdcdcd",
                height: "150px",
                transition: "top 0.5s",
                transitionDelay: "1.5s",
                display: "flex",
                alignItems: "center",
                bgcolor:
                  item.rank === 1 || item.rank === 2 || item.rank === 3 ? "#e9d7fe" : "#f9f5ff",
                // bgcolor: (item.rank === 1 || item.rank === 2 || item rank === 3) ? "#e9d7fe" : "#f9f5ff",
              }}
              key={item.uid}
            >
              <Grid container>
                <Grid
                  item
                  xs={1}
                  sx={{
                    px: "5px",
                    boxSizing: "border-box",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      // width: "100%",
                      // borderRadius: "50%",
                      // aspectRatio: "1/1 auto",
                      maxWidth: "100px",
                      maxHeight: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // border: "2px solid #000000",
                      // background:
                      //   item.rank === 1
                      //     ? "linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)"
                      //     : item.rank === 2
                      //     ? "radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%)"
                      //     : item.rank === 3
                      //     ? "#967444"
                      //     : "",
                      position: "relative",
                    }}
                  >
                    {/* <Box
                    sx={{
                      position: "absolute",
                      width: "80%",
                      aspectRatio: "1/1 auto",
                      maxWidth: "150px",
                      maxHeight: "150px",
                      border: "1px solid #000",
                      borderRadius: "50%",
                      zIndex: 1,
                    }}
                  ></Box> */}
                    <Typography
                      sx={{
                        font: "900 48px Pretendard",
                        textAlign: "center",
                        color:
                          item.rank === 1 || item.rank === 2 || item.rank === 3
                            ? "#c33c3c"
                            : "#333",
                        animation: showTotalScore ? "fade 2s forwards steps(1, end)" : "",

                        "@keyframes fade": {
                          from: {
                            opacity: 0,
                          },
                          to: {
                            opacity: 1,
                          },
                        },
                      }}
                    >
                      {item.rank}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={2.5}>
                  <Box sx={{ display: "flex", alignItems: "center", height: "100%", ml: "10px" }}>
                    <img
                      src={Avartar[users.filter((user) => user.uid + "" === item.uid)[0]?.imgIdx]}
                      style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                    />
                    {/* <Avatar
                    sx={{
                      bgcolor: stringToColor(
                        users.filter((user) => user.uid + "" === item.uid)[0]?.name || ""
                      ),
                    }}
                  >
                    {users.filter((user) => user.uid + "" === item.uid)[0]?.name[0].toUpperCase() ||
                      ""}
                  </Avatar> */}
                    <Tooltip
                      title={users.filter((user) => user.uid + "" === item.uid)[0]?.name || ""}
                      arrow
                      PopperProps={{ disablePortal: true }}
                    >
                      <Typography
                        sx={{ ml: "10px", font: "900 40px Pretendard", cursor: "default" }}
                        noWrap
                      >
                        {users.filter((user) => user.uid + "" === item.uid)[0]?.name || ""}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Grid>

                <Grid xs={6.5} item sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      height: "75px",
                      width: "100%",
                      display: "flex",
                      bgcolor: "#ddd",
                      borderRadius: "0 6px 6px 0",
                      boxShadow: "inset 0px 2px 6px #00000030, inset 0px -3px 6px #ffffff",
                      // overflowY: "hidden",
                      // overflowX: "visible",
                    }}
                  >
                    <Box sx={{ bgcolor: "#777", width: "2px" }} />
                    <Box
                      sx={{
                        bgcolor: "#c8cce5",
                        height: "100%",
                        width: "calc(0%)",
                        animation: showTotalScore ? "grow 2s forwards" : "",
                        // animationDelay: "3s",
                        // boxShadow: "0px 2px 6px #00000030",
                        "--value": `${
                          maxScore === 0
                            ? "0%"
                            : item.beforeScore === 0
                            ? "0%"
                            : (item.beforeScore / maxScore) * 100 + "%"
                        }`,

                        "@keyframes grow": {
                          from: {
                            width: "0%",
                          },
                          to: {
                            width: "calc(var(--value))",
                          },
                        },
                      }}
                    />
                    <Box
                      sx={{
                        bgcolor: "#9277ed",
                        height: "100%",
                        width: "calc(0%)",
                        borderRadius: "0 6px 6px 0",
                        position: "relative",
                        animation: "grow 2s forwards",
                        animationDelay: "0.5s",
                        "--value": `${
                          maxScore === 0
                            ? "0%"
                            : item.score === 0
                            ? "0%"
                            : (item.score / maxScore) * 100 + "%"
                        }`,

                        "@keyframes grow": {
                          from: {
                            width: "0%",
                          },
                          to: {
                            width: "calc(var(--value))",
                          },
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          position: "absolute",
                          top: "50%",
                          right: "0%",
                          transform: "translate(50%, -50%)",
                          font: "800 33px Pretendard",
                          color: "#fff",
                          textShadow: "3px 3px 2px #00000080",
                          WebkitTextStroke: "1px #000",
                        }}
                      >
                        {item.score}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={2}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      height: "100%",
                      pr: "20px",
                      boxSizing: "border-box",
                    }}
                  >
                    {showTotalScore ? (
                      <Typography sx={{ font: "bold 40px Pretendard" }}>
                        {isNaN(item.score + item.beforeScore) ? 0 : item.score + item.beforeScore}{" "}
                        점
                      </Typography>
                    ) : (
                      <Typography sx={{ font: "bold 40px Pretendard" }}>{item.score} 점</Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export const QuizResultContainer = () => {
  const { users } = useContext(QuizSlideContext);

  const [userList, setUserList] = useState([]);
  const [maxScore, setMaxScore] = useState(0);

  useEffect(() => {
    setUserList(
      users
        .map((user) => {
          return {
            ...user,
            totalScore: user.score,
          };
        })
        .sort((a, b) => b.totalScore - a.totalScore)
    );

    setMaxScore(Math.max(...users.map((user) => user.score)));
  }, [users]);

  return (
    <Box sx={{ flex: 1, overflow: "auto", position: "relative" }}>
      <Box sx={{ height: "100%", width: "100%", position: "absolute" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: "10px",
            gap: "10px",
          }}
        >
          <EmojiEventsIcon sx={{ color: "gold", fontSize: "60px" }} />
          <Typography sx={{ font: "800 39px Pretendard" }}>최종 결과</Typography>
        </Box>

        <Box
          sx={{
            bgcolor: "#fff",
            borderBottom: "1px solid #cdcdcd",
            height: "400px",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {userList[1] && (
              <img
                src={userList[1] && Avartar[userList[1].imgIdx]}
                style={{ width: "110px", height: "110px", borderRadius: "50%" }}
              />
            )}

            {userList[1] && (
              <Box sx={{ color: "#333", textAlign: "center" }}>
                <Typography sx={{ font: "800 39px Pretendard" }}>
                  {userList[1] && userList[1].name}
                </Typography>
                <Typography sx={{ font: "700 33px Pretendard" }}>
                  {userList[1] && userList[1].score}점
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                height: "130px",
                bgcolor: "rgb(150, 225, 235)",
                width: "200px",
                borderRadius: "10px 10px 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  font: "900 48px Pretendard",
                  color: "#fff",
                  textShadow: "3px 3px 2px #00000080",
                }}
              >
                2
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <img
              src={userList[0] && Avartar[userList[0].imgIdx]}
              style={{ width: "130px", height: "130px", borderRadius: "50%" }}
            />

            <Box sx={{ color: "#333", textAlign: "center" }}>
              <Typography sx={{ font: "800 39px Pretendard" }}>
                {userList[0] && userList[0].name}
              </Typography>
              <Typography sx={{ font: "700 33px Pretendard" }}>
                {userList[0] && userList[0].score}점
              </Typography>
            </Box>

            <Box
              sx={{
                height: "170px",
                bgcolor: "rgb(246, 117, 103)",
                width: "200px",
                borderRadius: "10px 10px 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  font: "900 48px Pretendard",
                  color: "#fff",
                  textShadow: "3px 3px 2px #00000080",
                }}
              >
                1
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
            {userList[2] && (
              <img
                src={userList[2] && Avartar[userList[2].imgIdx]}
                style={{ width: "90px", height: "90px", borderRadius: "50%" }}
              />
            )}

            {userList[2] && (
              <Box sx={{ color: "#333", textAlign: "center" }}>
                <Typography sx={{ font: "800 39px Pretendard" }}>
                  {userList[2] && userList[2].name}
                </Typography>
                <Typography sx={{ font: "700 33px Pretendard" }}>
                  {userList[2] && userList[2].score}점
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                height: "90px",
                bgcolor: "rgb(177, 202, 255)",
                width: "200px",
                borderRadius: "10px 10px 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  font: "900 48px Pretendard",
                  color: "#fff",
                  textShadow: "3px 3px 2px #00000080",
                }}
              >
                3
              </Typography>
            </Box>
          </Box>
        </Box>
        {userList
          .filter((_, idx) => idx !== 0 && idx !== 1 && idx !== 2)
          .map((item, index) => (
            <Box
              sx={{
                // borderRadius: "16px",
                p: "10px",
                boxSizing: "border-box",
                borderBottom: "1px solid #cdcdcd",
                // my: "5px",
                width: "calc(100% - 15px)",
                height: "150px",
                display: "flex",
                alignItems: "center",
              }}
              key={item.uid}
            >
              <Grid container>
                <Grid item xs={1} sx={{ px: "5px", boxSizing: "border-box" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100px",
                      height: "100px",
                      // bgcolor: "red",
                      // background:
                      //   index === 0
                      //     ? "linear-gradient(to right, #BF953F, #FCF6BA, #B38728, #FBF5B7, #AA771C)"
                      //     : index === 1
                      //     ? "radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%)"
                      //     : index === 2
                      //     ? "#967444"
                      //     : "",
                      position: "relative",
                    }}
                  >
                    <Typography
                      sx={{ font: "900 48px Pretendard", textAlign: "center", color: "#333" }}
                    >
                      {index + 4}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={2.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      ml: "10px",
                      gap: "5px",
                    }}
                  >
                    {/* <Avatar
                      sx={{
                        bgcolor: stringToColor(item.name),
                      }}
                    >
                      {item.name[0].toUpperCase()}
                    </Avatar> */}
                    <img
                      src={Avartar[item.imgIdx]}
                      style={{ width: "100px", height: "100px", borderRadius: "50%" }}
                    />

                    <Tooltip PopperProps={{ disablePortal: true }} arrow title={item.name}>
                      <Typography sx={{ ml: "10px", font: "800 42px Pretendard" }} noWrap>
                        {item.name}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Grid>

                <Grid
                  xs={6.5}
                  item
                  sx={{
                    height: "50px",
                    bgcolor: "#ddd",
                    boxShadow: "inset 0px 2px 6px #00000030, inset 0px -3px 6px #ffffff",
                    borderRadius: "0 6px 6px 0",
                    margin: "auto 0",
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      bgcolor: "#9277ed",
                      width: `calc(${
                        item.score === 0 || maxScore === 0
                          ? "5px"
                          : (item.score * 100) / maxScore + "%"
                      })`,
                      borderRadius: "0 6px 6px 0",
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      justifyContent: "flex-end",
                      p: "0 20px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Typography sx={{ font: "800 42px Pretendard" }}>{item.score} 점</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

const CodeContainer = (props) => {
  const { id } = useParams();
  const { accessCode, socket, isAccessCodeOpened, isAccessCodeVisible } =
    useContext(QuizSlideContext);

  const [isQRModalOpened, setIsQRModalOpened] = useState(false);

  // 화사 아이피
  const depth = window.location.href.split("/");
  const QRCodeURL = `${depth[0]}//${depth[2]}/app/quiz/${id}`;

  const handleQRcodeModal = () => {
    setIsQRModalOpened(!isQRModalOpened);
  };

  useEffect(() => {
    if (!socket) return;

    socket.emit("getAccessCode", { quizID: id });
  }, [socket]);

  return (
    <Grid
      item
      xs={isAccessCodeOpened ? 3 : 0}
      sx={{
        // justifyContent: "center",
        transition: "1.5s",
        display: isAccessCodeVisible ? "flex" : "none",
        flexDirection: "column",
        gap: "20px",
        position: "relative",
      }}
    >
      <Header title="접속 방법" isStatic />

      <Box sx={{ bgcolor: "#eee", boxShadow: "0px 3px 6px #00000038" }}>
        <Typography sx={{ ...styles.access_code, fontSize: "clamp(22.5px, 1.875vw, 36px)" }}>
          라이브 퀴즈 접속 방법 2가지
        </Typography>
      </Box>

      <Box sx={{ flex: 1 }}>
        <Box sx={{ px: "10px", borderBottom: "1px solid #ccc", height: "50%" }}>
          <Typography
            sx={{
              font: "700 30px Pretendard",
              color: "#c33c3c",
              fontSize: "clamp(18.75px, 1.5625vw, 30px)",
            }}
          >
            1. QR코드로 접속하기
          </Typography>

          <Tooltip title="QR코드 크게보기" placement="top" arrow>
            <Box sx={styles.QRCode_box}>
              <QRCode
                style={{
                  width: "clamp(187.5px, 15.625vw, 300px)",
                  height: "clamp(187.5px, 15.625vw, 300px)",
                }}
                value={QRCodeURL}
                onClick={handleQRcodeModal}
              />
            </Box>
          </Tooltip>
        </Box>

        <Box sx={{ p: "10px", height: "50%" }}>
          <Typography
            sx={{
              font: "700 30px Pretendard",
              color: "#c33c3c",
              fontSize: "clamp(18.75px, 1.5625vw, 30px)",
            }}
          >
            2. 접속 코드로 접속하기
          </Typography>

          <Box sx={{ px: "10px", mt: "10px" }}>
            <Typography
              sx={{
                ...styles.access_code,
                fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)",
                display: "block",
              }}
            >
              {"1 )"} https://www.withplus.live 접속
            </Typography>

            <Typography
              sx={{
                ...styles.access_code,
                display: "block",
                fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)",
                mt: "20px",
                wordBreak: "keep-all",
              }}
            >
              {"2 )"} 접속코드 입력란에{" "}
              <span
                style={{
                  font: "bold 38.1px NotoSansKR-Medium",
                  fontSize: "clamp(18.75px, 1.5625vw, 30px)",
                  color: "#B43F3F",
                  textAlign: "center",
                }}
              >
                {accessCode.slice(0, 3) + " " + accessCode.slice(3) + " "}
              </span>
              입력
            </Typography>
          </Box>

          {/* <Typography sx={styles.access_code}>접속 코드</Typography>
        <Typography sx={styles.access_code_number}>
          {accessCode.slice(0, 3) + " " + accessCode.slice(3)}
        </Typography> */}
        </Box>
      </Box>

      {/* <Box>
        <Typography sx={styles.access_code}>접속 코드</Typography>
        <Typography sx={styles.access_code_number}>
          {accessCode.slice(0, 3) + " " + accessCode.slice(3)}
        </Typography>
      </Box> */}
      <QRCodeWideModal
        open={isQRModalOpened}
        handleModal={handleQRcodeModal}
        ModalQRCode={QRCodeURL}
      />
    </Grid>
  );
};

const Outro = () => {
  const ref = useRef(null);

  const [url, setUrl] = useState("");
  const [blinking, setBlinking] = useState(false);
  const [time, setTime] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const [display, setDisplay] = useState("flex");
  const [openOutro, setOpenOutro] = useState(false);

  useEffect(() => {
    const Typer = setInterval(() => {
      if (time !== 960) {
        setTime((prev) => prev + 60);
        return;
      }

      if (outro.indexOf(url) === outro.length - 1) {
        clearInterval(Typer);
        setBlinking(true);
        return;
      }

      setUrl((prevUrl) => {
        let idx = outro.indexOf(prevUrl) + 1;
        return outro[idx];
      });
    }, 60);

    return () => {
      clearInterval(Typer);
    };
  }, [url, time]);

  useEffect(() => {
    if (blinking) {
      setTimeout(() => {
        setDisplay("none");
      }, 500);

      setTimeout(() => {
        setOpacity(1);
        // ref.current.style.active();
      }, 1000);
    }
  }, [blinking]);

  useEffect(() => {
    if (opacity === 1) {
      setTimeout(() => {
        setOpenOutro(true);
      }, 500);
    }
    console.log(opacity);
  }, [opacity]);

  return (
    <Box
      sx={{
        // bgcolor: "red",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        // gap: "50px",
      }}
    >
      <Header title="퀴즈 종료" isStatic />

      <Box
        sx={{
          bgcolor: "#fff",
          mt: "10px",
          display: "flex",
          alignItems: "center",
          gap: "15px",
          px: "7.5px",
          pb: "7.5px",
          boxSizing: "border-box",
          borderBottom: "1px solid #eee",
        }}
      >
        <ArrowBackIcon sx={{ fontSize: "20px", color: "#777" }} />
        <ArrowForwardIcon sx={{ fontSize: "20px", color: "#777" }} />
        <RefreshIcon sx={{ fontSize: "20px", color: "#777" }} />

        <Box
          sx={{
            flex: 1,
            bgcolor: "#f5f5f5",
            p: "10px 20px",
            borderRadius: "20px",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              bgcolor: "#fff",
              position: "relative",
              borderRadius: "50%",
              p: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HttpsIcon sx={{ fontSize: "20px", color: "#333" }} />
          </Box>

          <Box
            sx={{
              font: "600 18px Pretendard",
              position: "relative",
            }}
          >
            {url}

            {/* {url === outro[outro.length - 1] && <span>&nbsp;</span>} */}
            <Typography
              ref={ref}
              sx={{
                position: "absolute",
                bottom: -25,
                right: -10,
                transform: "translate(50%, 50%)",
                backgroundColor: "#fff",
                boxShadow: "0px 0px 5px 0px #00000030",
                borderRadius: "50%",
                zIndex: 2,
                display,
                justifyContent: "center",
                alignItems: "center",
                padding: "5px",
                animation: blinking ? "click .25s forwards" : "",
                animationTimingFunction: "step-end",

                "@keyframes click": {
                  "0%": {
                    transform: "translate(50%, 50%)",
                  },
                  "50%": {
                    transform: "translate(50%, 50%) scale(0.75)",
                    backgroundColor: "#f5f5f5",
                  },
                  "100%": {
                    transform: "translate(50%, 50%)",
                  },
                },
              }}
            >
              <SearchIcon sx={{ fontSize: "28px" }} />
            </Typography>
          </Box>

          <Typography
            sx={{
              borderRight: "3px solid #333",
              opacity: 1,
              transform: "translateX(-10px)",
              animation: blinking ? "blinking 1s infinite" : null,
              animationTimingFunction: "step-end",
              color: "#f5f5f5",

              "@keyframes blinking": {
                "0%": {
                  opacity: 0,
                },
                "50%": {
                  opacity: 1,
                },
                "100%": {
                  opacity: 0,
                },
              },
            }}
            children={"1"}
          />
        </Box>
      </Box>

      <Box
        sx={{
          flex: 1,
          borderRadius: "0 0 10px 10px",
          position: "relative",
          opacity,
          transition: "opacity 1s",
        }}
      >
        <iframe
          src="https://www.withplus.live"
          style={{ width: "100%", height: "100%", border: 0, zIndex: 0, position: "absolute" }}
        />

        <Box
          sx={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
            height: "100%",
            bgcolor: "#ffffff00",
          }}
        />

        <Box
          sx={{
            position: "absolute",
            zIndex: 2,
            width: "100%",
            height: "100%",
            opacity: openOutro ? 1 : 0,
            bgcolor: "#ffffff88",
            // webKitBackdropFilter: "blur(10px)",
            backdropFilter: "blur(10px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "40px",
            transition: "opacity 1s",
            transitionDelay: "1s",
          }}
        >
          <img src={logo} alt="logo" style={{ width: "50%" }} />

          <Typography sx={{ font: "700 32px Pretendard", textAlign: "center" }}>
            퀴즈가 종료 되었습니다. <br />
            감사합니다.
          </Typography>

          <Button
            size="large"
            sx={{
              // ...styles.intro.start_button,
              bgcolor: "#d6e2fb",
              color: "#50586C",
              "&:hover": { bgcolor: "#c6d2eb" },
              fontWeight: "800",
              fontFamily: "Pretendard",
              fontSize: "36px",
              px: "30px",
            }}
            onClick={() => window.close()}
          >
            확인
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default QuizSlidePage;
