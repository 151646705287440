import { useEffect, useState } from "react";
import S from "../style.module.css";
import { useNavigate, useParams } from "react-router-dom";

const EighthPageMobile = ({ onPrevClick, firstTypeArray }) => {
  const [relationship, setRelationship] = useState([]);
  const [communication, setCommunication] = useState([]);
  const [motivation, setMotivation] = useState([]);
  const navigate = useNavigate();
  const { gameId, results, } = useParams();

  useEffect(() => {
    if (firstTypeArray[4]?.relationship) {
      setRelationship(firstTypeArray[4]?.relationship.split("."));
    }
    if (firstTypeArray[4]?.communication) {
      setCommunication(firstTypeArray[4]?.communication.split("."));
    }
    if (firstTypeArray[4]?.motivation) {
      setMotivation(firstTypeArray[4]?.motivation.split("."));
    }
  }, [firstTypeArray]);

  const handleDownloadClick = () => {
    navigate(`/hexagram/user/${gameId}/result/${results}/`);
  };

  return (
    <div className={S.Page}>
      <div className={S.Title}>
        <p>관계 관리와 동기부여</p>
      </div>
      <div className={S.PageContents}>
        <div className={S.Box} style={{ backgroundColor: "#F2F2F2" }}>
          <p className={S.EighthPageContentsTitle}>1. 선호하는 관계 방식</p>
          <p className={S.EighthPageContentsDesc}>
            {relationship.map((item, index) => item && (
              <span key={index}>{item.trim()}</span>
            ))}
          </p>
          <p className={S.EighthPageContentsTitle} style={{ marginTop: "6px" }}>2. 선호하는 커뮤니케이션</p>
          <p className={S.EighthPageContentsDesc}>
            {communication.map((item, index) => item && (
              <span key={index}>{item.trim()}</span>
            ))}
          </p>
          <p className={S.EighthPageContentsTitle} style={{ marginTop: "6px" }}>3. 동기부여 방법</p>
          <p className={S.EighthPageContentsDesc} style={{ marginBottom: "10px" }}>
            {motivation.map((item, index) => item && (
              <span key={index}>{item.trim()}</span>
            ))}
          </p>
        </div>
      </div>
      <p className={S.alert}>※ 본 모바일 결과지는 요약본입니다</p>
      <div className={S.PageFooter}>
        <div className={S.PrevBtn} onClick={onPrevClick}>
          ← 뒤로가기
        </div>
        <div className={S.NextBtn} style={{ padding: "10px 16px" }} onClick={handleDownloadClick}>
          PDF로 다운
        </div>
      </div>
    </div>
  );
};

export default EighthPageMobile;