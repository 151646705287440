import { useState } from "react";
import { Wheel } from "react-custom-roulette";
import classes from "./Roulette.module.css";
import RouletteImg1 from "./RouletteImg/RouletteImg1.png";

const Roulette = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [numberOfOptions, setNumberOfOptions] = useState(2);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [result, setResult] = useState(null);
  const [userInputs, setUserInputs] = useState(Array(numberOfOptions).fill(""));

  // 돌림판 색깔 지정
  const fixedColors = Array.from({ length: numberOfOptions }, (_, index) => {
    const colorOptions = {
      2: ["#FFFFFF", "#60A7FF"],
      3: ["#FFFFFF", "#BBDAFF", "#60A7FF"],
      4: ["#FFFFFF", "#EFF6FF", "#BBDAFF", "#60A7FF"],
      5: ["#FFFFFF", "#EFF6FF", "#BBDAFF", "#9BC9FF", "#60A7FF"],
      6: ["#FFFFFF", "#EFF6FF", "#9BC9FF", "#BBDAFF", "#9BC9FF", "#60A7FF"],
      7: ["#FFFFFF", "#FAFCFF", "#EFF6FF", "#9BC9FF", "#BBDAFF", "#9BC9FF", "#60A7FF"],
      8: ["#FFFFFF", "#FAFCFF", "#EFF6FF", "#9BC9FF", "#BBDAFF", "#9BC9FF", "#80B9FF", "#60A7FF"],
    };

    const selectedColors = colorOptions[numberOfOptions];

    return selectedColors[index];
  });

  //텍스트 색깔
  const textColors = Array.from({ length: numberOfOptions }, (_, index) => {
    const colorOptions = {
      2: ["#60A7FF", "#FFFFFF"],
      3: ["#60A7FF", "#60A7FF", "#FFFFFF"],
      4: ["#60A7FF", "#60A7FF", "#60A7FF", "#FFFFFF"],
      5: ["#60A7FF", "#60A7FF", "#60A7FF", "#FFFFFF", "#FFFFFF"],
      6: ["#60A7FF", "#60A7FF", "#60A7FF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
      7: ["#60A7FF", "#60A7FF", "#60A7FF", "#60A7FF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
      8: ["#60A7FF", "#60A7FF", "#60A7FF", "#60A7FF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
    };

    const selectedColors = colorOptions[numberOfOptions];

    return selectedColors[index];
  });

  // input 박스에 값이 없으면 true 반환 시작 버튼 비활성화
  const isButtonAble = userInputs.some((input) => input.trim() === "");

  console.log(isButtonAble);

  //룰렛을 돌리고 당첨 번호 랜덤으로 지정
  const spinRoulette = () => {
    if (!isSpinning) {
      setIsSpinning(true);
      const randomPrizeNumber = Math.floor(Math.random() * numberOfOptions);
      setPrizeNumber(randomPrizeNumber);
      setResult(null);
      console.log(randomPrizeNumber);
    }
  };

  // 룰렛 option 감소 버튼
  const handleDecrease = () => {
    setNumberOfOptions(Math.max(2, numberOfOptions - 1));
    setUserInputs((prevInputs) => {
      const newSize = Math.max(2, prevInputs.length - 1);
      return prevInputs.slice(0, newSize);
    });
    setResult(null);
  };

  // 룰렛 option 증가 버튼
  const handleIncrease = () => {
    setNumberOfOptions(Math.min(8, numberOfOptions + 1));
    setUserInputs((prevInputs) => {
      const newSize = Math.min(8, prevInputs.length + 1);
      return [...prevInputs, ...Array(newSize - prevInputs.length).fill("")];
    });
    setResult(null);
  };

  // react-custom-roulette 라이브러리의 data를 넣기 위한 함수 _ option값에 룰렛에 들어갈 단어 기입
  const generateData = () => {
    const newData = Array.from({ length: numberOfOptions }, (_, index) => ({
      option: userInputs[index] || `옵션${index + 1}`,
      style: { backgroundColor: fixedColors[index % fixedColors.length] },
    }));
    return newData || [];
  };

  //돌림판 포인트 스타일 및 이미지 변경 가능
  // const pointerProps = {
  //   // image: {pointerImage},
  //   style: {
  //     width: "50px",
  //     height: "50px",
  //     transform: "translateX(-30%) translateY(40%) rotate(2deg)",
  //   },
  // };

  // 룰렛 회전 종료 후 결과값 저장
  const handleStopSpinning = () => {
    setIsSpinning(false);
    setResult(`결과: ${generateData()[prizeNumber].option}`);
  };

  // input박스 onChange 핸들러
  const handleInputChange = (index, value) => {
    const newInputs = [...userInputs];
    newInputs[index] = value;
    setUserInputs(newInputs);
  };

  // 초기화 버튼
  const handleResetRoulette = () => {
    setUserInputs(Array(numberOfOptions).fill(""));
    setNumberOfOptions(2);
  };

  /*위치만 조정하고 숨김처리*/
  const CustompointerProps = {
    src: { RouletteImg1 },
    style: {
      width: "10%",
      right: "40px",
      top: "25px",
      transform: "rotate(45deg)",
      opacity: "0.0",
    },
  };

  console.log(userInputs);
  console.log(Math.max(2, numberOfOptions - 1));

  return (
    <>
      <div className={classes.content}>
        <div className={classes.justifyContent}>
          <h1>원판 룰렛</h1>
        </div>

        {/* <div className={classes.buttonBox}>
          <button className={classes.button} onClick={handleDecrease}>
            -
          </button>
          <h2 className={classes.numberOfOptionsH2}>
            &nbsp;&nbsp;{numberOfOptions}&nbsp;&nbsp;
          </h2>
          <button className={classes.button} onClick={handleIncrease}>
            +
          </button>
          <p className={classes.min2Max8}>&#40;2~8까지 가능&#41;</p>
        </div> */}

        {/*input value 넣는 곳 */}
        {/* <div className={classes.optionInputBox}>
          <h3 style={{ width: "300px" }}>옵션 내용을 설정해주세요!</h3>
          {Array.from({ length: numberOfOptions }, (_, index) => (
            <div key={index}>
              <label>{`Option ${index + 1}`}: </label>
              <input
                type="text"
                placeholder={`옵션 ${index + 1}`}
                onChange={(e) => handleInputChange(index, e.target.value)}
                value={userInputs[index]}
              />
            </div>
          ))}
        </div> */}

        {/* 가져와서 쓸 코드 */}
        <div className={classes.rouletteBox}>
          <img className={classes.roulettePointer} src={RouletteImg1} />
          <Wheel
            mustStartSpinning={isSpinning}
            // markerImage={RouletteImg1}
            //룰렛 텍스트 가로
            // perpendicularText={[true]}
            pointerProps={CustompointerProps}
            prizeNumber={prizeNumber}
            data={generateData()}
            backgroundColors={["#3e3e3e", "#df3428"]}
            textColors={textColors}
            outerBorderColor={["none"]}
            // outerBorderWidth={[9]}
            innerBorderColor={["#f2f2f2"]}
            radiusLineColor={["tranparent"]}
            radiusLineWidth={[1]}
            textDistance={55}
            fontSize={[20]}
            spinDuration={[0.4]}
            disableInitialAnimation={true}
            onStopSpinning={handleStopSpinning}
          />
          <button className={classes.rouletteButton} onClick={spinRoulette} disabled={isButtonAble}>
            시작하기
          </button>
          {/*버튼 뒤의 배경*/}
          <div className={classes.buttonBackGround}></div>
          {/*룰렛 테두리 배경*/}
          <div className={classes.rouletteBackGround}></div>
        </div>
        {/* 가져와서 쓸 코드 */}
        {/* <div className={classes.justifyContent}>
          <button
            className={classes.startOrReset}
            onClick={spinRoulette}
            disabled={isButtonAble}
          >
            시작!
          </button>
          <button
            className={classes.startOrReset}
            onClick={handleResetRoulette}
          >
            초기화
          </button>
        </div> */}
        <h1 className={classes.justifyContent}>{isButtonAble === true ? "원판 룰렛" : result}</h1>
      </div>
    </>
  );
};

export default Roulette;
