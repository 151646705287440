import { styled } from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";
import { Box, Typography, Container, Button } from "@mui/material";

export const Body = MuiStyled((props) => (
  <Box
    sx={{
      pt: "80px",
      height: "100vh",
      bgcolor: "#f5f5f5",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
    }}
    {...props}
  />
))({});

export const Content = MuiStyled((props) => (
  <Box
    sx={{
      height: "calc(100% - 4vh)",
      width: "87.5%",
      margin: "auto",
      //   bgcolor: "red",
      //   placeItems: "center",
      display: "flex",
      flexDirection: "column",
    }}
    {...props}
  />
))({});

export const MyListBox = MuiStyled((props) => (
  <Box
    sx={{
      mx: "10px",
      flex: 1,
      display: "flex",
      flexDirection: "column",
    }}
    {...props}
  />
))({});

export const MyList = MuiStyled((props) => <Box sx={{ mt: "8px", flex: 1 }} {...props} />)({});
