import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Button, IconButton, Menu, MenuItem, Typography } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SwapVertIcon from "@mui/icons-material/SwapVert";

import { Textfield } from "components/Textfield";

import { BoardSectionDeleteModal } from "components/Modal";

import { ManagerContext } from "views/LiveBoard/contexts/Manager";
import { SnackBarContext } from "components/SnackBar/ContextAPI";

const GroupHeader = ({ section }) => {
  const { gameId } = useParams();
  const { socket, handleSortSectionModal } = useContext(ManagerContext);
  const SnackBar = useContext(SnackBarContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [sectionName, setSectionName] = useState("");

  const [isClicked, setClicked] = useState(false);
  const [isDeleteSectionModalOpened, setIsDeleteSectionModalOpened] = useState(false);

  const changeSectionName = () => {
    if (sectionName !== section.name) {
      socket.emit("changeSectionName", { gameId, sectionId: section.id, name: sectionName });
    }

    setClicked(false);
  };

  const editSectionName = () => {
    setTimeout(() => {
      setClicked(true);
    }, 100);
  };

  const handleDeleteSectionModal = () => {
    setIsDeleteSectionModalOpened((prev) => !prev);
  };

  const deleteSection = () => {
    socket.emit("deleteSection", { gameId, sectionId: section.id }, ({ success }) => {
      if (success) {
        handleDeleteSectionModal();

        SnackBar.setOpen(true);
        SnackBar.setText("그룹이 삭제되었습니다.");
        SnackBar.setSeverity("success");
      }
    });
  };

  useEffect(() => {
    setSectionName(section.name);
  }, [section]);

  return (
    <Box
      sx={{
        p: "10px",
        bgcolor: "#c33c3c",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {isClicked ? (
        <Textfield
          value={sectionName}
          onChange={(e) => setSectionName(e.target.value)}
          onBlur={changeSectionName}
          onKeyPress={(e) => e.key === "Enter" && changeSectionName()}
          InputProps={{
            endAdornment: <Button sx={{ fontWeight: 600 }}>완료</Button>,
          }}
          onFocus={(e) => e.target.select()}
          autoFocus
          fullWidth
          size="small"
        />
      ) : (
        <Typography
          noWrap
          sx={{
            color: "#fff",
            font: "700 20px Pretendard",
            flex: 1,
            cursor: "pointer",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() => setClicked(true)}
        >
          {sectionName}
        </Typography>
      )}
      <IconButton size="small" sx={{ color: "#fff" }} onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
        disablePortal
      >
        <MenuItem onClick={editSectionName}>
          <Box sx={{ display: "flex", gap: "10px", "> p": { fontWeight: 600 } }}>
            <EditIcon />
            <Typography>그룹 이름 변경</Typography>
          </Box>
        </MenuItem>

        <MenuItem onClick={handleSortSectionModal}>
          <Box sx={{ display: "flex", gap: "10px", "> p": { fontWeight: 600 } }}>
            <SwapVertIcon />
            <Typography>그룹 정렬하기</Typography>
          </Box>
        </MenuItem>

        <MenuItem onClick={handleDeleteSectionModal}>
          <Box sx={{ display: "flex", gap: "10px", color: "#c00", "> p": { fontWeight: 600 } }}>
            <DeleteIcon />
            <Typography>그룹 삭제</Typography>
          </Box>
        </MenuItem>
      </Menu>
      <BoardSectionDeleteModal
        open={isDeleteSectionModalOpened}
        onClose={handleDeleteSectionModal}
        action={deleteSection}
      />
    </Box>
  );
};
export default GroupHeader;
