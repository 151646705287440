import cowBackground from "./cow_background.png";
import elephantBackground from "./elephant_background.png";
import hippoBackground from "./hippo_background.png";
import tigerBackground from "./tiger_background.png";

import cowStar from "./cow_star.png";
import elephantStar from "./elephant_star.png";
import hippoStar from "./hippo_star.png";
import tigerStar from "./tiger_star.png";

import cowCongratulation from "./cow_congratulation.png";
import elephantCongratulation from "./elephant_congratulation.png";
import hippoCongratulation from "./hippo_congratulation.png";
import tigerCongratulation from "./tiger_congratulation.png";

import cowWin from "./cow_win.gif";
import elephantWin from "./elephant_win.gif";
import hippoWin from "./hippo_win.gif";
import tigerWin from "./tiger_win.gif";

import crayon1 from "./crayon1.png";
import crayon2 from "./crayon2.png";
import crayon3 from "./crayon3.png";
import crayon4 from "./crayon4.png";

import tape1 from "./tape1.png";
import tape2 from "./tape2.png";
import tape3 from "./tape3.png";
import tape4 from "./tape4.png";

export default {
  cow: {
    background: cowBackground,
    star: {
      src: cowStar,
      aspectRatio: "3633/1804",
      width: 3633,
    },
    congratulation: {
      src: cowCongratulation,
    },
    crayon: crayon4,
    tape: {
      src: tape4,
      width: "90%",
    },
    win: cowWin,
  },
  elephant: {
    background: elephantBackground,
    star: {
      src: elephantStar,
      aspectRatio: "3497/1646",
      width: 3497,
    },
    congratulation: {
      src: elephantCongratulation,
    },
    crayon: crayon1,
    tape: {
      src: tape1,
      width: "100%",
    },
    win: elephantWin,
  },

  hippo: {
    starAspectRation: "3497/1646",
    background: hippoBackground,
    star: {
      src: hippoStar,
      aspectRatio: "3581/1874",
      width: 3581,
    },
    congratulation: {
      src: hippoCongratulation,
    },
    crayon: crayon2,
    tape: {
      src: tape2,
      width: "35%",
    },
    win: hippoWin,
  },
  tiger: {
    background: tigerBackground,
    star: {
      src: tigerStar,
      aspectRatio: "3713/1846",
      width: 3713,
    },
    congratulation: {
      src: tigerCongratulation,
      width: "35%",
    },
    crayon: crayon3,
    tape: {
      src: tape3,
      width: "40%",
    },
    win: tigerWin,
  },
};
