import React, { useEffect, useRef, useState } from "react";
import classes from "./ArrowSpinning.module.css";
import Arrow from "./ArrowSpinningImg/arrowblue.png";
import ArrowMiddle from "./ArrowSpinningImg/arrowmiddle.png"
import * as S from "./style";

const ArrowSpinning = () => {
  const [animationStarted, setAnimationStarted] = useState(false);
  
  const randomAngle = Math.floor(Math.random() * 360);
  
  let testRandom = randomAngle;

  const startAnimation = () => {
    console.log(randomAngle);
    setAnimationStarted((pre) => !pre);
  };


  console.log(animationStarted);
  return (
    <>
      <div className={classes.content}>
        {/* <div className={classes.justifyContent}>
          <h1>화살표 돌리기 게임</h1>
        </div> */}

        <div className={classes.middleBox}>
          <h2 className={classes.middleBoxH2}>
            &nbsp;&nbsp;당첨자는 누구?&nbsp;&nbsp;
          </h2>
        </div>
        {/*사용할 컴포넌트 */}
        <div className={classes.ArrowSpinningBox}>
          <S.ArrowAnimation
            src={Arrow}
            isClicked={animationStarted}
            rotationAngle={randomAngle + 810}
          />
          <img  className={classes.arrowImg2} src={ArrowMiddle}/>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            style={{ width: "7vw", height: "3vw", marginTop:"100px" }}
            onClick={startAnimation}
          >
            시작
          </button>
        </div>
      </div>
    </>
  );
};

export default ArrowSpinning;
