import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";

import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import { Textfield } from "components/Textfield";

import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import GppBadIcon from "@mui/icons-material/GppBad";
import VerifiedIcon from "@mui/icons-material/Verified";

import logo from "assets/images/wliveLogo.png";

export default () => {
  const { state } = useLocation();
  const [, setCookie] = useCookies();

  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordRule, setPasswordRule] = useState(false);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordCheckVisible, setPasswordCheckVisible] = useState(false);
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  //   const passwordRegex = /^(?=.*[a-z])(?=.*\d)[A-Za-z0-9!@#$%^&*()]{8,20}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*\d)[A-Za-z0-9!@#$%^&*()]/;

  const handlePasswordVisible = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handlePasswordCheckVisible = () => {
    setPasswordCheckVisible(!passwordCheckVisible);
  };

  const changePassword = () => {
    console.log(state);
    axios
      .post("/account/password/change", null, {
        params: {
          userId: state.userId,
          password: password,
        },
      })
      .then((res) => {
        const { data } = res;

        if (data.success) {
          alert("비밀번호가 변경되었습니다.");

          let expires = new Date();
          expires.setTime(expires.getTime() + 1000 * 60 * 60 * 5);

          setCookie("auth", data.auth, { path: "/", expires: expires });

          window.location.href = "/dashboard";
        } else {
          alert("비밀번호 변경에 실패했습니다. 다시 로그인 후 시도해주세요.");
          window.location.href = "/";
        }
      });
  };

  useEffect(() => {
    if (password.length >= 8 && password.length <= 20) {
      setPasswordLength(true);
    } else {
      setPasswordLength(false);
    }

    if (passwordRegex.test(password)) {
      setPasswordRule(true);
    } else {
      setPasswordRule(false);
    }
  }, [password]);

  useEffect(() => {
    if (password === "" || passwordCheck === "") return;

    if (password === passwordCheck) {
      setIsPasswordCorrect(true);
    } else {
      setIsPasswordCorrect(false);
    }
  }, [password, passwordCheck]);

  useEffect(() => {
    if (!state) {
      alert("잘못된 접근입니다.");
      window.location.href = "/";
    }
  }, []);

  return (
    <Box sx={{ bgcolor: "#f5f5f5", height: "100vh", p: "10px", boxSizing: "border-box" }}>
      <Container
        maxWidth="xs"
        disableGutters
        sx={{
          bgcolor: "#fff",
          height: "100%",
          borderRadius: "10px",
          boxShadow: "0px 3px 6px #00000028",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="logo" style={{ width: "60%" }} />
        </Box>

        <Typography sx={{ font: "700 36px Pretendard", textAlign: "center", mt: "30px" }}>
          새로운 비밀번호
        </Typography>

        <Typography
          sx={{
            font: "500 14px Pretendard",
            color: "#555",
            textAlign: "center",
            lineHeight: "1.5",
          }}
        >
          위드플러스 라이브에 오신 것을 환영합니다! <br />
          계정을 안전하게 보호하기 위해 새로운 비밀번호를 설정해주세요.
        </Typography>

        <Box sx={{ mt: "30px" }}>
          <Textfield
            fullWidth
            type={passwordVisible ? "text" : "password"}
            placeholder="새로운 비밀번호"
            InputProps={{
              startAdornment: <LockIcon sx={{ color: "#797979", pr: "5px" }} />,
              endAdornment: (
                <IconButton size="small" onClick={handlePasswordVisible}>
                  {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              ),
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Box sx={{ pl: "10px" }}>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                mt: "5px",
                color: passwordLength ? "#00aa00" : "#aa0000",
              }}
            >
              {passwordLength ? (
                <VerifiedIcon sx={{ fontSize: "20px" }} />
              ) : (
                <GppBadIcon sx={{ fontSize: "20px" }} />
              )}
              <Typography sx={{ font: "500 14px Pretendard", fontSize: "14px" }}>
                8자 이상, 20자 이하
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                mt: "5px",
                color: passwordRule ? "#00aa00" : "#aa0000",
              }}
            >
              {passwordRule ? (
                <VerifiedIcon sx={{ fontSize: "20px" }} />
              ) : (
                <GppBadIcon sx={{ fontSize: "20px" }} />
              )}

              <Typography sx={{ font: "500 14px Pretendard", fontSize: "14px" }}>
                영어 대문자, 소문자, 숫자, 특수기호 중 2가지 이상 조합 <br />
                <span style={{ color: "#555" }}>(사용가능한 특수기호 : !@#$%^&*() )</span>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Textfield
            fullWidth
            type={passwordCheckVisible ? "text" : "password"}
            placeholder="비밀번호 확인"
            disabled={!passwordLength || !passwordRule}
            InputProps={{
              startAdornment: (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    pr: "5px",
                  }}
                >
                  {isPasswordCorrect ? (
                    <VerifiedIcon sx={{ color: "#00aa00" }} />
                  ) : (
                    <GppBadIcon sx={{ color: "#aa0000" }} />
                  )}
                </Box>
              ),
              endAdornment: (
                <IconButton size="small" onClick={handlePasswordCheckVisible}>
                  {passwordCheckVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              ),
            }}
            value={passwordCheck}
            onChange={(e) => setPasswordCheck(e.target.value)}
          />
        </Box>

        <Box>
          <Button
            variant="contained"
            fullWidth
            sx={{ font: "600 20px Pretendard", p: "10px" }}
            disabled={!isPasswordCorrect}
            onClick={changePassword}
          >
            비밀번호 변경하기
          </Button>
        </Box>
      </Container>
    </Box>
  );
};
