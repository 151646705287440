import managerImg from "assets/images/ContentPage/Egogram/managerImg.png";
import userImg from "assets/images/ContentPage/Egogram/userImg.png";
import icon2 from "assets/images/ContentPage/Egogram/icon2.png";
import icon3 from "assets/images/ContentPage/Egogram/icon3.png";
import icon4 from "assets/images/ContentPage/Egogram/icon4.png";
import icon5 from "assets/images/ContentPage/Egogram/icon5.png";

export const connectionScreenData = [
  {
    id: "manager",
    title: "진행자용 접속화면",
    explain: ["회원가입 후 에고그램 테스트 생성, 진행", ""],
    img: managerImg,
    button: "메뉴얼 다운받기",
    explains: [
      {
        id: 1,
        title: "Withplus.Live 접속",
        description: ["위드플러스 라이브에 접속 후", "로그인 - [마이페이지] - [Egorgam]"],
      },
      {
        id: 2,
        title: "테스트 생성",
        description: ["원하는 제목을 입력하여 저장 후", "My List에서 확인"],
      },
      {
        id: 3,
        title: "접속 유도",
        description: ["QR 코드와 접속코드를 참여자에게 배포"],
      },
      {
        id: 4,
        title: "결과 확인",
        description: ["참여자들의 테스트 결과를 실시간으로", "확인 및 공유"],
      },
    ],
  },
  {
    id: "user",
    title: "참여자용 접속화면",
    explain: [
      "모바일 : QR코드 스캔 or 위드플러스 라이브 홈페이지에서 접속코드 입력하여 접속",
      "PC : 위드플러스 라이브 홈페이지에서 접속코드 입력하여 접속",
    ],
    img: userImg,
    button: "메뉴얼 다운받기",
    explains: [
      {
        id: 1,
        title: "테스트 접속",
        description: ["진행자가 배포한 QR코드 스캔", "or 접속코드 입력"],
      },
      {
        id: 2,
        title: "정보 입력",
        description: ["간단한 인적사항 기입"],
      },
      {
        id: 3,
        title: "테스트 진행",
        description: ["질문에 대한 답변 진행"],
      },
      {
        id: 4,
        title: "결과 확인",
        description: ["타입 분석 결과 확인"],
      },
    ],
  },
];

export const benefitData = [
  {
    id: 1,
    description: ["자기 결정 방식의 이해로", "효과적 대응 가능"],
    img: icon5,
  },
  {
    id: 2,
    description: ["에고상태에 적합한", "성장 방향을 탐색"],
    img: icon2,
  },
  {
    id: 3,
    description: ["의사소통 스타일 이해로", "긍정적 관계 형성"],
    img: icon3,
  },
  {
    id: 4,
    description: ["리더십 스타일, 팀 상호작용", "이해로 효율성 증진"],
    img: icon4,
  },
];
