import React, { createContext, useState, useEffect } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const FullScreenContext = createContext({
  handle: null,
  handleFullScreen: () => {},
});

const FullScreenProvider = ({ children }) => {
  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    if (handle.active) handle.exit();
    else handle.enter();
  };

  const value = {
    handle,
    handleFullScreen,
  };

  return <FullScreenContext.Provider value={value}>{children}</FullScreenContext.Provider>;
};

export { FullScreenProvider, FullScreenContext };
