import { Box, Typography, IconButton, Paper, Menu } from "@mui/material";
import Title from "../../../components/Title";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useState, useEffect, useRef, useContext } from "react";
// import { GET_LIVETAP_BY_ID } from "utils/api/Tap";
import { useParams } from "react-router-dom";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
// import Hippo from "assets/images/LiveTap/teamCharacter/1.png";
// import Cow from "assets/images/LiveTap/teamCharacter/2.png";
// import Tiger from "assets/images/LiveTap/teamCharacter/3.png";
// import Elephant from "assets/images/LiveTap/teamCharacter/4.png";

import Character_Win from "assets/images/LiveTap_new/Character_Win";
import confetti from "assets/images/LiveTap_new/confetti.gif";

import background from "assets/images/LiveTap_new/rank.png";

import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

import { ManagerContext } from "../contexts/ManagerContext";
import { AudioContext } from "../contexts/AudioContext";

const Result = () => {
  const boxRef = useRef(null);
  // const { bgmVolumn, setBgmVolumn } = useContext(AudioContext);
  // const { tap, handleBGM, setSoundVolume, soundVolume, BGM, prepareSounds } =
  //   useContext(ManagerContext);
  const { tap } = useContext(ManagerContext);
  const { handleBGM, setSoundVolume, soundVolume, BGM } = useContext(AudioContext);
  const handle = useFullScreenHandle();
  // const { gameId } = useParams();

  const [boxStyles, setBoxStyles] = useState({});
  const [tapData, setTapData] = useState(null);
  const [isFullscreen, setFullscreen] = useState(false);
  const [rankings, setRankings] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [contribution, setContribution] = useState(null);

  // const playBGM = () => {
  //   if (BGM.paused) {
  //     handleBGM();
  //     setSoundVolume(0.24);
  //   }
  //   prepareSounds();
  // };

  const handleMuteSound = () => {
    if (BGM.paused) {
      handleBGM();
    }

    if (soundVolume === 0) {
      setSoundVolume(0.25);
    } else {
      setSoundVolume(0);
    }
  };

  const handleSoundVolume = (e) => {
    if (BGM.paused) {
      handleBGM();
    }

    setSoundVolume((e.target.value * 1) / 200);
  };

  const openContribution = (e) => {
    let teamId = e.target.id;
    let targetTeam = tapData.teamData.find((team) => team.id === teamId);

    setAnchorEl(e.currentTarget);

    let totalTapCount = targetTeam.teamMembers.reduce((acc, cur) => acc + cur.tapCount, 0);

    let contribution = targetTeam.teamMembers.map((member) => ({
      name: member.name,
      tapCount: member.tapCount,
      contribution:
        totalTapCount === 0 ? "0%" : ((member.tapCount / totalTapCount) * 100).toFixed(1) + "%",
    }));

    contribution.sort((a, b) => b.tapCount - a.tapCount);

    setContribution(contribution);
  };

  useEffect(() => {
    if (!tap._id) return;

    if (tap.gameMode === "team") {
      let newTap = tap;

      for (let i = 0; i < newTap.teamData.length; i++) {
        newTap.teamData[i].totalTapCount =
          newTap.teamData[i].teamMembers.reduce((acc, cur) => acc + cur.tapCount, 0) /
          newTap.teamData[i].teamScore;
      }

      newTap.teamData.sort((a, b) => b.totalTapCount - a.totalTapCount);

      newTap.teamData.forEach((team, idx) => {
        team.rank = idx + 1;
      });

      setTapData(newTap);
    }
  }, [tap]);

  useEffect(() => {
    if (!tapData) return;

    const rankings = tapData.teamData.map((team) => ({
      rank: team.rank,
      teamName: team.teamName,
      totalTapCount: team.totalTapCount,
      id: team.id,
    }));

    setRankings(rankings);
  }, [tapData]);

  const getImageByTeamName = (teamName) => {
    switch (teamName) {
      case "1 팀":
        return 0;
      case "2 팀":
        return 1;
      case "3 팀":
        return 2;
      case "4 팀":
        return 3;
      default:
        return null;
    }
  };

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const boxWidth = entries[0].contentRect.width;
      const boxHeight = entries[0].contentRect.height;

      if (boxWidth / boxHeight > 16 / 9) {
        // console.log("가로가 더 길어요");
        setBoxStyles({
          height: "100%",
        });
      } else {
        // console.log("세로가 더 길어요");
        setBoxStyles({
          width: "100%",
        });
      }
    });

    if (boxRef.current) observer.observe(boxRef.current);

    return () => {
      boxRef.current && observer.unobserve(boxRef.current);
    };
  }, []);

  // const Temp = () => {
  //   return (
  //     <Box
  //       sx={{
  //         position: "absolute",
  //         top: "50%",
  //         left: "50%",
  //         transform: "translate(-50%, -50%)",
  //         // bgcolor: "#fff",
  //         // boxShadow: "0px 3px 6px #00000038",
  //         // borderRadius: "10px",
  //         borderBottom: "1px solid #cdcdcd",
  //         // height: "400px",
  //         display: "flex",
  //         justifyContent: "space-evenly",
  //         alignItems: "end",
  //         width: "90%",
  //         padding: "20px",
  //         pb: 0,
  //         // bgcolor: "red",
  //         // height: "80%",
  //       }}
  //     >
  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "center",
  //           gap: "10px",
  //           width: "30%",
  //         }}
  //       >
  //         {rankings[1] && (
  //           <img
  //             src={getImageByTeamName(rankings[1].teamName)}
  //             alt={rankings[1].teamName}
  //             style={{ width: "110px", height: "110px", objectFit: "contain" }}
  //           />
  //         )}
  //         {rankings[1] && (
  //           <Box sx={{ color: "#333", textAlign: "center" }}>
  //             <Typography sx={{ font: "800 39px Pretendard" }}>
  //               {rankings[1] && rankings[1].teamName}
  //             </Typography>
  //             {/* <Typography sx={{ font: "700 33px Pretendard" }}>
  //                       Tap {rankings[1] && rankings[1].totalTapCount}회
  //                     </Typography> */}
  //           </Box>
  //         )}
  //         <Box
  //           sx={{
  //             height: "130px",
  //             bgcolor: "rgb(150, 225, 235)",
  //             width: "200px",
  //             borderRadius: "10px 10px 0 0",
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <Typography
  //             sx={{
  //               font: "900 48px Pretendard",
  //               color: "#fff",
  //               textShadow: "3px 3px 2px #00000080",
  //             }}
  //           >
  //             2
  //           </Typography>
  //         </Box>
  //       </Box>

  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "center",
  //           gap: "5px",
  //           width: "30%",
  //         }}
  //       >
  //         {rankings[0] && (
  //           <img
  //             src={getImageByTeamName(rankings[0].teamName)}
  //             alt={rankings[0].teamName}
  //             style={{ width: "130px", height: "130px", objectFit: "contain" }}
  //           />
  //         )}
  //         <Box sx={{ color: "#333", textAlign: "center" }}>
  //           <Typography sx={{ font: "800 39px Pretendard" }}>
  //             {rankings[0] && rankings[0].teamName}
  //           </Typography>
  //           {/* <Typography sx={{ font: "700 33px Pretendard" }}>
  //                     Tap {rankings[0] && rankings[0].totalTapCount}회
  //                   </Typography> */}
  //         </Box>
  //         <Box
  //           sx={{
  //             height: "170px",
  //             bgcolor: "rgb(246, 117, 103)",
  //             width: "200px",
  //             borderRadius: "10px 10px 0 0",
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <Typography
  //             sx={{
  //               font: "900 48px Pretendard",
  //               color: "#fff",
  //               textShadow: "3px 3px 2px #00000080",
  //             }}
  //           >
  //             1
  //           </Typography>
  //         </Box>
  //       </Box>

  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "column",
  //           alignItems: "center",
  //           gap: "10px",
  //           width: "30%",
  //         }}
  //       >
  //         {rankings[2] && (
  //           <img
  //             src={getImageByTeamName(rankings[2].teamName)}
  //             alt={rankings[2].teamName}
  //             style={{ width: "90px", height: "90px", objectFit: "contain" }}
  //           />
  //         )}
  //         {rankings[2] && (
  //           <Box sx={{ color: "#333", textAlign: "center" }}>
  //             <Typography sx={{ font: "800 39px Pretendard" }}>
  //               {rankings[2] && rankings[2].teamName}
  //             </Typography>
  //             {/* <Typography sx={{ font: "700 33px Pretendard" }}>
  //                       Tap {rankings[2] && rankings[2].totalTapCount}회
  //                     </Typography> */}
  //           </Box>
  //         )}
  //         <Box
  //           sx={{
  //             height: "90px",
  //             bgcolor: "rgb(177, 202, 255)",
  //             width: "200px",
  //             borderRadius: "10px 10px 0 0",
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <Typography
  //             sx={{
  //               font: "900 48px Pretendard",
  //               color: "#fff",
  //               textShadow: "3px 3px 2px #00000080",
  //             }}
  //           >
  //             3
  //           </Typography>
  //         </Box>
  //       </Box>
  //     </Box>
  //   );
  // };

  return (
    <Box
      sx={{
        height: "100vh",
        pt: "80px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        bgcolor: "#f5f5f5",
      }}
    >
      <Box sx={{ px: "20px" }}>
        <Title children={{ name: "Live Tap" }} />
      </Box>
      <FullScreen handle={handle}>
        <Box
          ref={boxRef}
          sx={{
            bgcolor: "#fff",
            width: "calc(100% - 20px)",
            borderRadius: "10px",
            boxSizing: "border-box",
            height: isFullscreen ? "100vh" : "calc(100% - 10px)",
            boxShadow: "0 3px 6px #00000038",
            m: "0 auto",
            position: "relative",
            // bgcolor: "red",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              right: "5px",
              top: "5px",
              // display: "flex",
              borderRadius: "50px",
              border: "1px solid #eee",
              bgcolor: "#fff",
              px: "5px",
              boxShadow: "0px 3px 6px #00000020",
              zIndex: 100,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <input
                type="range"
                min="0"
                max="100"
                value={soundVolume * 200}
                onChange={handleSoundVolume}
                style={{
                  accentColor: "#c26974",
                }}
              />
              <IconButton size="small" onClick={handleMuteSound}>
                {soundVolume === 0 ? (
                  <VolumeOffIcon sx={{ fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)" }} />
                ) : (
                  <VolumeUpIcon sx={{ fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)" }} />
                )}
              </IconButton>
            </Box>
            {/* {BGM.paused && (
              <Typography sx={{ px: "10px", color: "#444", fontWeight: "500" }}>
                소리가 나지 않는다면?
                <span
                  style={{ cursor: "pointer", textDecoration: "underline", color: "#c33c3c" }}
                  onClick={playBGM}
                >
                  클릭하여 재생하기
                </span>
              </Typography>
            )} */}
          </Box>

          <Box
            sx={{
              height: "100%",
              overflow: "hidden",
              display: "flex",
              position: "relative",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                ...boxStyles,
                aspectRatio: "16 / 9",
                position: "absolute",
                backgroundImage: `url(${background})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  aspectRatio: "480 / 451",
                }}
              >
                <img src={confetti} alt="confetti" style={{ width: "100%" }} />
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  mt: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <EmojiEventsIcon sx={{ color: "gold", fontSize: "60px" }} />
                  <Typography
                    sx={{
                      font: "900 39px Pretendard",
                      WebkitTextStroke: "1px #fff",
                      filter: "drop-shadow(0 3px 6px #00000038)",
                      color: "#333",
                    }}
                  >
                    최종 결과
                  </Typography>
                </Box>

                {/* 기여도 보기   */}
                <Typography
                  sx={{
                    color: "#444",
                    font: "600 18px Pretendard",
                    bgcolor: "rgb(150,208,150)",
                    width: "max-content",
                    margin: "0 auto",
                    p: "5px 10px",
                    borderRadius: "6px",
                    boxShadow: "0 3px 6px #00000038",
                  }}
                >
                  {"(팀명을 클릭하면 팀 내의 기여도를 볼 수 있어요.)"}
                </Typography>
              </Box>

              {/* 1등 */}
              {rankings[0] && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "22%",
                    left: "40%",
                    width: "20%",
                  }}
                >
                  <Typography
                    sx={{
                      position: "absolute",
                      top: "-15%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      font: "800 39px MapleStoryOTFBold",
                      WebkitTextStroke: "1px #fff",
                      filter: "drop-shadow(3px 3px 2px #00000080)",
                      color: "#333",
                      p: "5px 10px",
                      borderRadius: "10px",
                      "&:hover": {
                        cursor: "pointer",
                        bgcolor: "#00000033",
                      },
                    }}
                    onClick={openContribution}
                    id={rankings[0].id}
                  >
                    {rankings[0].teamName}
                  </Typography>

                  <img src={Character_Win[getImageByTeamName(rankings[0].teamName)]} />
                </Box>
              )}

              {/* 2등 */}
              {rankings[1] && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "34%",
                    left: "15%",
                    width: "20%",
                  }}
                >
                  <Typography
                    sx={{
                      position: "absolute",
                      top: "-15%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      font: "800 39px MapleStoryOTFBold",
                      WebkitTextStroke: "1px #fff",
                      filter: "drop-shadow(3px 3px 2px #00000080)",
                      color: "#333",
                      p: "5px 10px",
                      borderRadius: "10px",
                      "&:hover": {
                        cursor: "pointer",
                        bgcolor: "#00000033",
                      },
                    }}
                    onClick={openContribution}
                    id={rankings[1].id}
                  >
                    {rankings[1].teamName}
                  </Typography>

                  <img src={Character_Win[getImageByTeamName(rankings[1].teamName)]} />
                </Box>
              )}

              {/* 3등 */}
              {rankings[2] && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "38%",
                    left: "64%",
                    width: "20%",
                  }}
                >
                  <Typography
                    sx={{
                      position: "absolute",
                      top: "-15%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      font: "800 39px MapleStoryOTFBold",
                      WebkitTextStroke: "1px #fff",
                      filter: "drop-shadow(3px 3px 2px #00000080)",
                      color: "#333",
                      p: "5px 10px",
                      borderRadius: "10px",
                      "&:hover": {
                        cursor: "pointer",
                        bgcolor: "#00000033",
                      },
                    }}
                    onClick={openContribution}
                    id={rankings[2].id}
                  >
                    {rankings[2].teamName}
                  </Typography>

                  <img src={Character_Win[getImageByTeamName(rankings[2].teamName)]} />
                </Box>
              )}

              {/* 4등 */}
              {rankings[3] && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "64%",
                    left: "82%",
                    width: "17.5%",
                  }}
                >
                  <Typography
                    sx={{
                      position: "absolute",
                      top: "-15%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      font: "800 39px MapleStoryOTFBold",
                      WebkitTextStroke: "1px #fff",
                      filter: "drop-shadow(3px 3px 2px #00000080)",
                      color: "#333",
                      p: "5px 10px",
                      borderRadius: "10px",
                      "&:hover": {
                        cursor: "pointer",
                        bgcolor: "#00000033",
                      },
                    }}
                    onClick={openContribution}
                    id={rankings[3].id}
                    rank={rankings[3].rank}
                  >
                    {rankings[3].teamName}
                  </Typography>

                  <img src={Character_Win[getImageByTeamName(rankings[3].teamName)]} />
                </Box>
              )}

              {anchorEl && (
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => {
                    setAnchorEl(null);
                    setContribution(null);
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    transform: anchorEl.getAttribute("rank")
                      ? "translate(-90px, -20px)"
                      : "translate(270px, 20px)",
                  }}
                  slotProps={{
                    paper: {
                      sx: {
                        bgcolor: "#ffffff80",
                        backdropFilter: "blur(10px)",
                        borderRadius: "10px",
                      },
                    },
                  }}
                >
                  <Box sx={{ px: "10px", width: "250px", maxHeight: "300px" }}>
                    <Typography
                      sx={{
                        font: "900 26px Pretendard",
                        textAlign: "center",
                        borderBottom: "1px solid #aaa",
                        pb: "5px",
                        color: "#333",
                        mb: "10px",
                      }}
                    >
                      팀 내 기여도
                    </Typography>

                    {contribution?.map((member, idx) => (
                      <Box
                        key={idx}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                          borderBottom: "1px solid #fbb",
                          py: "5px",
                          color: "#333",
                        }}
                      >
                        <Typography sx={{ font: "700 28px Pretendard" }}>{member.name}</Typography>
                        <Typography sx={{ font: "600 24px Pretendard" }}>
                          {member.contribution}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Menu>
              )}
            </Box>
          </Box>
        </Box>
      </FullScreen>
    </Box>
  );
};

export default Result;
