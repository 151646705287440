import managerImg from "assets/images/ContentPage/LiveBoard/managerImg.png";
import userImg from "assets/images/ContentPage/LiveBoard/userImg.png";
import icon1 from "assets/images/ContentPage/LiveBoard/icon1.png";
import icon2 from "assets/images/ContentPage/LiveBoard/icon2.png";
import icon3 from "assets/images/ContentPage/LiveBoard/icon3.png";
import icon4 from "assets/images/ContentPage/LiveBoard/icon4.png";

export const benefitData = [
  {
    id: 1,
    description: ["조직 내에서 적극적인", "참여와 지식 공유를 촉진"],
    img: icon1,
  },
  {
    id: 2,
    description: ["정복 구성 간소화로", "콘텐츠를 효율적으로 구성"],
    img: icon2,
  },
  {
    id: 3,
    description: ["멀티미디어 기능으로 다양한", "아이디어를 표현해 창의성장려"],
    img: icon3,
  },
  {
    id: 4,
    description: ["사용자 친화적 플랫폼", "제공으로 포괄적 참여 촉진"],
    img: icon4,
  },
];

export const connectionScreenData = [
  {
    id: "manager",
    title: "진행자용 접속화면",
    explain: ["주제별 보드 생성 및 작성"],
    img: managerImg,
    button: "메뉴얼 다운받기",
    explains: [
      {
        id: 1,
        title: "Withplus.Live 접속",
        description: ["위드플러스 라이브에 접속 후", "로그인 - [마이페이지] - [Live Board]"],
      },
      {
        id: 2,
        title: "보드 생성",
        description: ["원하는 제목과 설명을 입력하여", "저장 후 My List에서 확인"],
      },
      {
        id: 3,
        title: "접속 유도",
        description: ["QR 코드와 접속코드를", "참여자에게 배포"],
      },
      {
        id: 4,
        title: "결과 확인",
        description: ["참여자들의 게시물을", "실시간으로 확인 및 응답"],
      },
    ],
  },
  {
    id: "user",
    title: "참여자용 접속화면",
    explain: [
      "모바일 : QR코드 스캔 or 위드플러스 라이브 홈페이지에서 접속코드 입력하여 접속",
      "PC : 위드플러스 라이브 홈페이지에서 접속코드 입력하여 접속",
    ],
    img: userImg,
    button: "메뉴얼 다운받기",
    explains: [
      {
        id: 1,
        title: "보드 접속",
        description: ["진행자가 배포한 QR코드 스캔", "or 접속코드 입력"],
      },
      {
        id: 2,
        title: "게시물 입력",
        description: ["게시물의 제목과 내용 사진등을", "입력해 의견 작성"],
      },
      {
        id: 3,
        title: "결과 확인",
        description: ["참여자들의 다양한", "게시물 확인 및 공유"],
      },
      {
        id: 4,
        title: "의견 공유",
        description: ["강사의 피드백 확인이나", "참여자들간의 토론"],
      },
    ],
  },
];
