export default ({ selected: select }) => {
  const selected = select === "true";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 94.63 63">
      <defs>
        <style>
          {`
            .check {
              fill: ${selected ? "#fff" : "#c33c3c"};
              stroke: ${selected ? "#c33c3c" : "#fff"};
              stroke-width: 3px;
            }

            .select {
              fill: ${selected ? "#fff" : "#000"};
            }
          `}
        </style>
      </defs>
      <g id="그룹_3" data-name="그룹 3" transform="translate(-0.37 -6)">
        <g id="그룹_1" data-name="그룹 1" transform="translate(1 -2)" className="select">
          <rect
            id="사각형_1"
            data-name="사각형 1"
            width="11"
            height="11"
            rx="3"
            transform="translate(3 8)"
          />
          <rect
            id="사각형_2"
            data-name="사각형 2"
            width="77"
            height="11"
            rx="3"
            transform="translate(17 8)"
          />
        </g>
        <g id="그룹_1-2" data-name="그룹 1" transform="translate(1 24)" className="select">
          <rect
            id="사각형_1-2"
            data-name="사각형 1"
            width="11"
            height="11"
            rx="3"
            transform="translate(3 8)"
          />
          <rect
            id="사각형_2-2"
            data-name="사각형 2"
            width="77"
            height="11"
            rx="3"
            transform="translate(17 8)"
          />
        </g>
        <g id="그룹_1-3" data-name="그룹 1" transform="translate(1 50)" className="select">
          <rect
            id="사각형_1-3"
            data-name="사각형 1"
            width="11"
            height="11"
            rx="3"
            transform="translate(3 8)"
          />
          <rect
            id="사각형_2-3"
            data-name="사각형 2"
            width="77"
            height="11"
            rx="3"
            transform="translate(17 8)"
          />
        </g>
        <path
          id="Icon_awesome-check"
          data-name="Icon awesome-check"
          d="M11.151,28.579.481,17.909a1.642,1.642,0,0,1,0-2.322L2.8,13.266a1.642,1.642,0,0,1,2.322,0l7.188,7.188,15.4-15.4a1.642,1.642,0,0,1,2.322,0L32.35,7.38a1.642,1.642,0,0,1,0,2.322L13.472,28.579A1.642,1.642,0,0,1,11.151,28.579Z"
          transform="translate(1 35.44)"
          className="check"
        />
      </g>
    </svg>
  );
};
