import { useEffect, useState } from "react";
import S from "../style.module.css";

const SeventhPage = ({ resultObjectArray, userData, firstTypeArray }) => {
  const [userType, setUserType] = useState("");
  useEffect(() => {
    setUserType(resultObjectArray[5]?.name);
  }, [resultObjectArray]);
  return (
    <div className={S.Page}>
      <div className={S.PageHeader}>HEXA GRAM Personality Profile</div>
      <div className={S.PageTitle}>
        <div className={S.TitlePart} style={{ backgroundColor: "#B02418" }}>
          Part IⅤ
        </div>
        <p className={S.TitlePartName}>
          {userData.name} 님의 관계관리와 동기부여_
          <span style={{ color: "#B02418" }}>기본타입_{userType}</span>
        </p>
      </div>
      <div className={S.UserMotivationTitle}>
        <p>관계관리 & 동기부여</p>
      </div>
      <div className={S.UserMotivationContents}>
        <div className={S.UserMotivationContentsBox}>
          <p className={S.UserMotivationContentsTitle}>1) 선호하는 관계방식</p>
          <p className={S.UserMotivationContentsContent}>{firstTypeArray[4]?.relationship}</p>
        </div>
        <div className={S.UserMotivationContentsBox}>
          <p className={S.UserMotivationContentsTitle}>2) 선호하는 커뮤니케이션</p>
          <p className={S.UserMotivationContentsContent}>{firstTypeArray[4]?.communication}</p>
        </div>
        <div className={S.UserMotivationContentsBox}>
          <p className={S.UserMotivationContentsTitle}>3) 동기부여 방법</p>
          <p className={S.UserMotivationContentsContent}>{firstTypeArray[4]?.motivation}</p>
        </div>
      </div>
      <div className={S.PageFooter}>
      <p>All right reserved by Withplus Inc. copyright</p>
      </div>
    </div>
  );
};

export default SeventhPage;
