import React from "react";
import { useMediaQuery } from "react-responsive";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import background from "assets/images/ContentPage/LiveQuiz/background.png";
import characterImg from "assets/images/ContentPage/LiveQuiz/characterImg.png";
import choiceIcon from "assets/images/ContentPage/LiveQuiz/choiceIcon.png";
import OXIcon from "assets/images/ContentPage/LiveQuiz/OXIcon.png";

import * as S from "./styles";
import { benefitData, connectionScreenData } from "./data";
import { Footer } from "components/Footer";

const manual = "https://withplus-live.notion.site/Live-Quiz-d7632fc9964a4eb7afb024887706264b";

const Index = (props) => {
  const isMobile = useMediaQuery({
    query: "(max-width:1000px)",
  });

  if (isMobile) {
    return (
      <S.Body>
        <S.container disableGutters maxWidth="xs">
          <Intro isMobile />

          <Benefit isMobile />

          <TypeDescription isMobile />

          {/* <ChoiceDescription isMobile /> */}

          <ConnectionScreen isMobile />
        </S.container>

        <Footer />
      </S.Body>
    );
  }

  return (
    <S.Body>
      <Intro />

      <Benefit />

      <TypeDescription />

      {/* <ChoiceDescription /> */}

      <ConnectionScreen />

      <Footer />
    </S.Body>
  );
};

const Intro = ({ isMobile }) => {
  const navigate = useNavigate();

  const goToDashBoard = () => {
    if (/Android|iPhone/i.test(navigator.userAgent)) {
      alert("진행자 기능 사용은 PC(노트북)에서 진행해 주세요!");
    } else {
      setTimeout(() => {
        navigate("/dashboard");
      }, 300);
    }
  };

  return (
    <S.Intro sx={{ backgroundImage: `url(${background})` }}>
      <S.IntroTitle>Live Quiz</S.IntroTitle>

      <S.IntroExplain1>
        다양한 용도로 사용할 수 있는 {isMobile && <br />}실시간 퀴즈 생성 도구입니다
      </S.IntroExplain1>

      <S.IntroExplain2>
        간단한 절차를 통해 퀴즈를 생성하고 <br /> 수강생들에게 배포할 수 있어 {isMobile && <br />}
        활용도가 높습니다
      </S.IntroExplain2>

      <S.IntroButton onClick={goToDashBoard}>체험하기</S.IntroButton>

      {<S.IntroCharacter src={characterImg} alt="character" />}
    </S.Intro>
  );
};

const Benefit = ({ isMobile }) => {
  return (
    <S.EffectContainer>
      <S.LiveQuizBox>Live Quiz</S.LiveQuizBox>

      <S.Title>기대 효과</S.Title>

      <S.Explain>참여자 모두의 생각을 실시간으로 공유할 수 있는 라이브 퀴즈</S.Explain>

      <S.EffectBox>
        {isMobile ? (
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            pagination={{ clickable: true }}
            navigation
          >
            {benefitData.map((data, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <S.EffectItem>
                    <S.EffectItemIcon src={data.img} alt="icon1" />

                    <S.EffectItemDescription>
                      {data.description[0]} <br /> {data.description[1]} &nbsp;
                    </S.EffectItemDescription>
                  </S.EffectItem>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          benefitData.map((data, idx) => {
            return (
              <S.EffectItem key={idx}>
                <S.EffectItemIcon src={data.img} alt="icon1" />

                <S.EffectItemDescription>
                  {data.description[0]} <br /> {data.description[1]} &nbsp;
                </S.EffectItemDescription>
              </S.EffectItem>
            );
          })
        )}
      </S.EffectBox>
    </S.EffectContainer>
  );
};

const TypeDescription = ({ isMobile }) => {
  return (
    <S.TypeDescriptionContainer>
      <S.LiveQuizBox>Live Quiz</S.LiveQuizBox>

      <S.Title>문제유형</S.Title>

      <S.Explain>
        각 문제 생성 시 두가지 문제 유형(객관식, OX퀴즈) 중 선택
        <br />
        하나의 퀴즈에 두 유형 교차 사용 가능
      </S.Explain>

      <S.TypesBox>
        <S.TypeItem>
          <S.TypeIcon>
            <img src={choiceIcon} alt="choiceIcon" />
          </S.TypeIcon>

          <S.TypeExplain>객관식 유형</S.TypeExplain>
        </S.TypeItem>

        <S.TypeItem>
          <S.TypeIcon>
            <img src={OXIcon} alt="OXIcon" />
          </S.TypeIcon>

          <S.TypeExplain>OX 퀴즈 유형</S.TypeExplain>
        </S.TypeItem>
      </S.TypesBox>
    </S.TypeDescriptionContainer>
  );
};

const ConnectionScreen = ({ isMobile }) => {
  return (
    <S.ConnectionScreenContainer>
      {connectionScreenData.map((data, idx) => {
        return (
          <S.ConnectionScreenBox key={idx} id={data.id}>
            <S.LiveQuizBox>Live Quiz</S.LiveQuizBox>

            <S.ConnectionScreenTitle>{data.title}</S.ConnectionScreenTitle>

            <S.ConnectionScreenExplain>
              {data.explain[0]} <br /> {isMobile && <br />} {data.explain[1]}
            </S.ConnectionScreenExplain>

            <S.ConnectionScreenImgBox id={data.id}>
              <img src={data.img} alt={data.id} />
            </S.ConnectionScreenImgBox>

            <S.ConnectionScreenExplainsContainer target={(isMobile || false).toString()}>
              <S.ConnectionScreenExplainsBox>
                {data.explains.map((explain, idx) => {
                  return (
                    <S.ConnectionScreenExplainItem
                      key={idx}
                      id={explain.id + ""}
                      target={(isMobile || false).toString()}
                    >
                      <S.ConnectionScreenExplainItemTitleBox>
                        <S.RedCircle>{explain.id}</S.RedCircle>

                        <S.ConnectionScreenExplainItemTitle>
                          {explain.title}
                        </S.ConnectionScreenExplainItemTitle>
                      </S.ConnectionScreenExplainItemTitleBox>

                      <S.ConnectionScreenExplainItemDescription>
                        {explain.description[0]} <br /> {explain.description[1]}
                        <br />
                        {explain.description[2]}
                      </S.ConnectionScreenExplainItemDescription>
                    </S.ConnectionScreenExplainItem>
                  );
                })}
              </S.ConnectionScreenExplainsBox>
            </S.ConnectionScreenExplainsContainer>

            <a href={manual} target="_blank" rel="noreferrner">
              <S.ConnectionScreenButton target={(isMobile || false).toString()}>
                메뉴얼 보러가기
              </S.ConnectionScreenButton>
            </a>
          </S.ConnectionScreenBox>
        );
      })}
    </S.ConnectionScreenContainer>
  );
};

export default Index;
