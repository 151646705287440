import React, { createContext, useState, useEffect } from "react";

const AudioContext = createContext({});

const AudioProvider = ({ children }) => {
  const sounds = [
    { name: "lobbyBgm", audio: new Audio("/sounds/WaitLobbyBG.mp3") },
    { name: "quizBgm1", audio: new Audio("/sounds/QuizTime00.mp3") },
    { name: "quizBgm2", audio: new Audio("/sounds/QuizTime01.mp3") },
    { name: "openCorrect", audio: new Audio("/sounds/OpenCorrect.aac") },
    { name: "resultView", audio: new Audio("/sounds/ResultView.aac") },
    { name: "finalRank", audio: new Audio("/sounds/FinalRank.aac") },
    { name: "liveTapBgm", audio: new Audio("/sounds/liveTap.mp3") },
  ];

  const [bgm, setBgm] = useState(null);
  const [bgmVolumn, setBgmVolumn] = useState(0.25);
  const [isPaused, setPaused] = useState(true);

  const playSFX = (event, cb) => {
    const sound = sounds.find((sound) => sound.name === event)?.audio;
    if (sound === undefined) return;

    // sound.load();
    sound.volume = bgmVolumn;
    var promise = sound.play();

    if (promise !== undefined) {
      promise
        .then((res) => {
          // Autoplay started!
          //   console.log(res);
        })
        .catch((error) => {
          console.log(error);
          // cb(error);
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
        });
    }
  };

  const playBGM = (fileName, cb) => {
    const sound = sounds.find((sound) => sound.name === fileName)?.audio;
    if (sound === undefined) return;

    setBgm(sound);
  };

  const stopBGM = (cb) => {
    if (!bgm) return;

    bgm.pause();
    bgm.currentTime = 0;
    setBgm(null);
  };

  const handleBGM = () => {
    if (!bgm) return;

    if (bgm.paused) {
      bgm.play();
      setPaused(false);
    } else {
      bgm.pause();
      setPaused(true);
    }
  };

  useEffect(() => {
    if (!bgm) return;

    bgm.volume = bgmVolumn;
  }, [bgm, bgmVolumn]);

  useEffect(() => {
    if (!bgm) return;

    bgm.play();
    bgm.volume = bgmVolumn;
    bgm.loop = true;
  }, [bgm]);

  const value = {
    bgm,
    setBgm,
    bgmVolumn,
    playSFX,
    handleBGM,
    setBgmVolumn,
    isPaused,
    setPaused,
    playBGM,
    stopBGM,
  };

  return <AudioContext.Provider value={value}>{children}</AudioContext.Provider>;
};

export { AudioContext, AudioProvider };
