import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Box, Grid } from "@mui/material";
import { Masonry } from "@mui/lab";

function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  const style = {
    color: isOver ? "green" : undefined,
  };

  return (
    <div ref={setNodeRef} style={style}>
      {props.children}
    </div>
  );
}

export const SortableContainer = (props) => {
  const { id, children, sx, items } = props;

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext id={id} items={items}>
      <Grid container ref={setNodeRef} sx={{ ...sx }}>
        {children}
      </Grid>
    </SortableContext>
  );
};

export const SortableMasonry = (props) => {
  const { id, children, sx, items, columns, spacing } = props;

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext id={id} items={items}>
      <Masonry ref={setNodeRef} sx={{ ...sx }} spacing={spacing || 0} columns={columns}>
        {children}
      </Masonry>
    </SortableContext>
  );
};

export const SortableBox = (props) => {
  const { id, children, sx, items, strategy } = props;

  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext id={id} items={items} strategy={strategy}>
      <Box ref={setNodeRef} sx={{ ...sx }}>
        {children}
      </Box>
    </SortableContext>
  );
};

export default Droppable;
