import Axios from "axios";

const axios = Axios.create({
  baseURL: "https://quiz.withplus.live",
});

export const GET_QuizList = async (userToken, callback) => {
  const params = { userToken };
  const response = await axios.get("/list", { params });

  callback(response);
};

export const GET_QuizData = async (quizID, callback) => {
  const params = { quizID };
  const response = await axios.get("/data", { params });

  callback(response);
};

export const POST_newQuiz = async (userToken, callback) => {
  const params = { userToken };
  const response = await axios.post("/new", null, { params: params });

  callback(response);
};

export const POST_quizData = async (quizId, title, slideList, callback) => {
  let params = { quizId, title, slideList };

  const response = await axios.post("/edit", { params });

  callback(response);
};

export const POST_duplicateQuiz = async (quizID, callback) => {
  let params = { quizID };

  const response = await axios.post("/duplicate", null, { params });

  callback(response);
};

export const DELETE_Quiz = async (quizList, callback) => {
  let params = { quizList: quizList };

  const response = await axios.post("/delete", null, { params });

  callback(response);
};

export const RESET_Quiz = async (quizID, callback) => {
  let params = { quizID };

  const response = await axios.post("/reset", null, { params });

  callback(response);
};

export const uploadImage = async (file, fileName, callback) => {
  const formData = new FormData();
  formData.append("file", file);

  console.log(formData.getAll("file"));

  const response = await axios.post(`/image?name=${fileName}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  callback(response);
};

export const deleteImage = async (id, callback) => {
  let params = { id };

  const response = await axios.delete("/image", { params });

  callback(response);
};
