// * npm modules
import React from "react";

// * material UI components
import { Box, Typography } from "@mui/material";

// * 프로젝트 컴포넌트
import logo from "assets/images/ContentPage/LivePolling/characterImg.png";

// * styles
import * as S from "./styles";

// * 인트로 컴포넌트
export default () => {
  return (
    <S.Intro>
      {/* 인트로 타이틀 */}
      <Typography
        sx={{
          font: "700 24px Pretendard",
          mb: "30px",
          textAlign: "center",
          wordBreak: "keep-all",
          color: "#c33c3c",
        }}
      >
        라이브 폴링의 모든 슬라이드가 끝났습니다.
      </Typography>

      {/* 라이브폴링 로고*/}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <S.Logo src={logo} alt="logo" width={"60%"} $filter />
      </Box>

      {/* 라이브폴링 */}
      <Typography sx={{ font: "700 30px Pretendard", mt: "30px", textAlign: "center" }}>
        감사합니다.
      </Typography>
    </S.Intro>
  );
};
