export default ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.355"
      height="30.119"
      viewBox="0 0 32.355 30.119"
    >
      <defs>
        <style>
          {`
              .ox-icon {
                  fill: ${color};
              }
          `}
        </style>
      </defs>
      <g id="그룹_1" data-name="그룹 1" transform="translate(0.306 0.119)">
        <path
          className="ox-icon"
          id="빼기_4"
          data-name="빼기 4"
          d="M7.706,27.1h0l-4.112.154.5-13.979a5.848,5.848,0,0,1-1.7-.916,6.005,6.005,0,0,1-1.314-1.413A6.552,6.552,0,0,1,0,7.04,7.307,7.307,0,0,1,.611,4.377,7.5,7.5,0,0,1,2.084,2.166,7.179,7.179,0,0,1,4.206.633,6.533,6.533,0,0,1,6.759,0,5.988,5.988,0,0,1,9.274.444a5.856,5.856,0,0,1,2.018,1.378A6.157,6.157,0,0,1,12.61,3.929a6.739,6.739,0,0,1,.417,2.625,7.475,7.475,0,0,1-2.951,5.65A6.964,6.964,0,0,1,8.2,13.217ZM5.739,24.676a.767.767,0,0,0-.711.74.643.643,0,0,0,.66.689.767.767,0,0,0,.711-.74A.643.643,0,0,0,5.739,24.676ZM6.545,2.155a4.681,4.681,0,0,0-3.2,1.479A5.077,5.077,0,0,0,1.924,6.968a4.4,4.4,0,0,0,1.187,3.237,4.026,4.026,0,0,0,3.1,1.244,4.681,4.681,0,0,0,3.2-1.479,5.076,5.076,0,0,0,1.423-3.335A4.4,4.4,0,0,0,9.647,3.4,4.026,4.026,0,0,0,6.545,2.155Zm-.281,7.864a2.892,2.892,0,0,1-2.969-3.1,3.45,3.45,0,0,1,3.2-3.332,2.892,2.892,0,0,1,2.969,3.1A3.45,3.45,0,0,1,6.264,10.019Z"
          transform="translate(-0.306 6.397) rotate(-30)"
        />
        <path
          className="ox-icon"
          id="빼기_5"
          data-name="빼기 5"
          d="M5.052,27.091h0l-.5-13.979A7.049,7.049,0,0,1,2.78,12.066a7.468,7.468,0,0,1-1.419-1.515A7.385,7.385,0,0,1,0,6.554,6.742,6.742,0,0,1,.422,3.929,6.15,6.15,0,0,1,1.74,1.822,5.853,5.853,0,0,1,3.758.444,5.992,5.992,0,0,1,6.273,0l.059,0A6.542,6.542,0,0,1,8.827.633a7.173,7.173,0,0,1,2.122,1.533,7.489,7.489,0,0,1,1.473,2.211,7.307,7.307,0,0,1,.606,2.663,6.526,6.526,0,0,1-1.153,4.023,5.987,5.987,0,0,1-1.4,1.422,5.833,5.833,0,0,1-1.806.876l.5,13.884Zm1.973-2.425H7.019a.642.642,0,0,0-.66.689.782.782,0,0,0,.214.508.722.722,0,0,0,.491.233H7.07a.643.643,0,0,0,.66-.689.781.781,0,0,0-.214-.508A.721.721,0,0,0,7.025,24.666ZM3.851,3.154a.62.62,0,0,0-.483.191.678.678,0,0,0-.182.5.782.782,0,0,0,.218.506L5.54,6.582,3.557,8.653a.678.678,0,0,0-.182.5.783.783,0,0,0,.218.507.722.722,0,0,0,.486.227A.619.619,0,0,0,4.563,9.7L6.546,7.629,8.683,9.854a.721.721,0,0,0,.486.227.62.62,0,0,0,.483-.191.678.678,0,0,0,.182-.5.782.782,0,0,0-.218-.506L7.479,6.654,9.462,4.582a.678.678,0,0,0,.182-.5.783.783,0,0,0-.218-.507.722.722,0,0,0-.486-.227.619.619,0,0,0-.483.191L6.474,5.607,4.337,3.381A.721.721,0,0,0,3.851,3.154Z"
          transform="translate(20.763 -0.119) rotate(30)"
        />
      </g>
    </svg>
  );
};
