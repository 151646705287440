import * as S from "./styles";
import React, { useState, useContext } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useCookies } from "react-cookie";
import { useParams, useNavigate } from "react-router-dom";
import { POST_USER_JOIN, CHECK_HEXAGRAM_TOKENS } from "utils/api/Hexagram";
import { UserContext } from "../contexts/UserContext";

const UserInfo = (props) => {
  const [name, setName] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [nameError, setNameError] = useState(false);
  const [affiliationError, setAffiliationError] = useState(false);

  const { slotName } = useContext(UserContext);

  // 쿠키에서 uid 가져오기
  const [cookies] = useCookies(["uid"]);
  // URL에서 gameId 가져오기
  const { gameId } = useParams();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    setNameError(name === "");
    setAffiliationError(affiliation === "");

    if (name !== "" && affiliation !== "") {
      CHECK_HEXAGRAM_TOKENS(cookies.auth, gameId) // 수정된 부분
        .then((response) => {
          if (response.data.tokens === 0) {
            // 수정된 부분
            // alert("토큰이 없습니다.");
            alert("인원이 가득 찼습니다. 진행자에게 문의해주세요.");
          } else {
            // 쿠키에서 유저 아이디 가져오기
            const userId = cookies.uid;
            // 서버에 사용자 정보 내보내기
            POST_USER_JOIN(gameId, userId, name, affiliation)
              .then((response) => {
                if (response.data.message === "User already joined") {
                  if (!response.data.result) {
                    // alert("이미 Hexagram 테스트를 참여하셨습니다. 결과 페이지로 이동합니다");
                    // navigate(`/hexagram/user/${gameId}/result/${response.data.result}/1`);
                    props.gotoQuestion();
                    return;
                  }
                  const result = response.data.result.join(",");
                  alert("이미 Hexagram 테스트를 참여하셨습니다. 결과 페이지로 이동합니다");
                  navigate(`/hexagram/user/${gameId}/result/${result}/1`);
                } else if (response.data.message === "FULL USER") {
                  alert("인원이 가득 찼습니다. 진행자에게 문의해주세요.");
                } else {
                  props.gotoQuestion();
                }
              })
              .catch((error) => {
                console.error("에러입니다", error);
              });
          }
        })
        .catch((error) => {
          console.error("토큰 확인 중 에러가 발생했습니다", error);
        });
    }
  };

  return (
    <S.Intro>
      {/* 타이틀 */}
      <Box sx={{ alignSelf: "flex-start", width: "100%" }}>
        <Typography sx={{ font: "bold 20px Pretendard", mb: "30px" }}>{slotName}</Typography>
      </Box>
      <Box
        sx={{
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "auto",
          font: "bold 24px pretendard",
          padding: "10px",
        }}
      >
        {/* 정보 입력 창 */}
        <Typography sx={{ font: "700 18px Pretendard" }}>정보입력</Typography>
        <Typography sx={{ font: "600 16px Pretendard", marginTop: "10px" }}>
          간단한 정보를 기입해주세요
        </Typography>
        <TextField
          fullWidth
          label="이름"
          variant="outlined"
          margin="normal"
          size="small"
          value={name}
          onChange={(e) => setName(e.target.value)}
          InputLabelProps={{
            sx: { font: "400 14px Pretendard" },
          }}
          InputProps={{
            sx: { font: "400 14px Pretendard" },
          }}
          sx={{
            backgroundColor: "#f8f8f8",
            borderRadius: "20px",
            mb: "5px",
            mx: "10px",
          }}
        />
        {nameError && (
          <Typography
            sx={{
              font: "400 12px Pretendard",
              color: "#d32f2f",
              ml: "10px",
              mb: "10px",
              alignSelf: "flex-start",
            }}
          >
            이름을 입력해주세요.
          </Typography>
        )}
        <TextField
          fullWidth
          label="소속"
          variant="outlined"
          margin="normal"
          size="small"
          value={affiliation}
          onChange={(e) => setAffiliation(e.target.value)}
          InputLabelProps={{
            sx: { font: "400 14px Pretendard" },
          }}
          InputProps={{
            sx: { font: "400 14px Pretendard" },
          }}
          sx={{
            backgroundColor: "#f8f8f8",
            borderRadius: "20px",
            mb: "5px",
            mx: "10px",
          }}
        />
        {affiliationError && (
          <Typography
            sx={{
              font: "400 12px Pretendard",
              color: "#d32f2f",
              ml: "10px",
              mb: "10px",
              alignSelf: "flex-start",
            }}
          >
            소속을 입력해주세요.
          </Typography>
        )}
        <Typography sx={{ font: "600 16px Pretendard", marginTop: "10px", textAlign: "center" }}>
          아래의 시작하기 버튼을 눌러
          <br />
          핵사그램 테스트를 시작해주세요.
        </Typography>
        <Box
          onClick={handleButtonClick}
          sx={{
            mt: "15px",
            mb: "10px",
            font: "500 18px Pretendard",
            width: "195px",
            height: "58px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#c33c3c",
            borderRadius: "10px",
            color: "#fff",
            cursor: "pointer",
            transition: "all 0.3s ease",
            "&:hover": {
              backgroundColor: "#a32e2e",
              transform: "scale(1.03)", // 크기 조정
            },
            "&:active": {
              backgroundColor: "#892525",
              transform: "scale(0.97)", // 크기 조정
            },
          }}
        >
          시작하기
        </Box>
      </Box>
    </S.Intro>
  );
};

export default UserInfo;
