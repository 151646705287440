import React, { useContext, useEffect, useState } from "react";

import { Box, Grid, InputAdornment, Typography } from "@mui/material";

import QrCode2Icon from "@mui/icons-material/QrCode2";

import * as S from "./styles";
import { Textfield } from "components/Textfield";
import { EditContext } from "../contexts/EditContext";

export const getIntroData = () => {
  return {
    type: "Intro",
    title: "",
    completed: true,
  };
};

export const IntroPreview = (props) => {
  const { currentSlide } = useContext(EditContext);

  return (
    <S.IntroPreview>
      <S.IntroPreviewTitle>{currentSlide.title}</S.IntroPreviewTitle>

      <Grid container sx={{ flex: 1 }}>
        <Grid item xs={6} sx={{ height: "100%", borderRight: "1px solid #eee", display: "flex" }}>
          <Box sx={{ margin: "auto", textAlign: "center" }}>
            <Typography
              sx={{
                font: "700 54px Pretendard",
                mb: "10px",
                color: "#252b36",
                fontSize: "clamp(18px, 2.8vw, 54px)",
              }}
            >
              www.withplus.live
            </Typography>
            <Typography sx={{ font: "600 1.25vw Pretendard", mb: "20px", color: "#252b36" }}>
              에 접속
            </Typography>

            <Typography
              sx={{
                font: "700 54px Pretendard",
                mb: "10px",
                color: "#c33c3c",
                fontSize: "clamp(18px, 2.8vw, 54px)",
              }}
            >
              123 456
            </Typography>
            <Typography sx={{ font: "600 1.25vw  Pretendard", mb: "20px", color: "#252b36" }}>
              접속 코드 입력
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6} sx={{ height: "100%", display: "flex" }}>
          <Box sx={{ margin: "auto" }}>
            <QrCode2Icon sx={{ fontSize: "330px", fontSize: "clamp(197px, 17vw, 330px)" }} />

            <Typography fontWeight={600} textAlign="center">
              또는 QR코드를 스캔하여 접속해주세요.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </S.IntroPreview>
  );
};

export const IntroEditor = (props) => {
  const { currentSlide, updateCurrentSlide } = useContext(EditContext);

  const handleTitle = (e) => {
    if (e.target.value.length > 75) {
      updateCurrentSlide({ title: e.target.value.slice(0, 75) });
    } else {
      updateCurrentSlide({ title: e.target.value });
    }
  };

  return (
    <Box>
      <Typography sx={{ fontWeight: "600", mb: "10px" }}>제목</Typography>

      <Textfield
        size="small"
        placeholder="제목을 입력해주세요. (75자 이내)"
        fullWidth
        value={currentSlide.title}
        onChange={handleTitle}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography sx={{ color: "#252b36" }}>{75 - currentSlide.title.length}</Typography>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
