import React from "react";
import { useMediaQuery } from "react-responsive";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import background from "assets/images/ContentPage/Egogram/background.png";
import mobile_background from "assets/images/ContentPage/Egogram/mobile_background.png";
import characterImg from "assets/images/ContentPage/Egogram/characterImg.png";

import { Footer } from "components/Footer";
import { connectionScreenData, benefitData } from "./data";
import * as S from "./styles";

const manual = "https://withplus-live.notion.site/Live-Egogram-e41b54e763aa48b58d64a64162d929c3";

const EgogramPage = () => {
  // 반응형 모바일
  const isMobile = useMediaQuery({
    query: "(max-width:1000px)",
  });

  return (
    <S.Body>
      {isMobile ? (
        <S.container disableGutters maxWidth="xs">
          <Intro isMobile />

          <Benefit isMobile />

          <ConnectionScreen isMobile />
        </S.container>
      ) : (
        <>
          <Intro />

          <Benefit />

          <ConnectionScreen />
        </>
      )}

      <Footer />
    </S.Body>
  );
};

const Intro = ({ isMobile }) => {
  const navigate = useNavigate();

  const goToDashBoard = () => {
    if (/Android|iPhone/i.test(navigator.userAgent)) {
      alert("진행자 기능 사용은 PC(노트북)에서 진행해 주세요!");
    } else {
      setTimeout(() => {
        navigate("/dashboard");
      }, 300);
    }
  };

  return (
    <S.Intro sx={{ backgroundImage: `url(${isMobile ? mobile_background : background})` }}>
      <S.IntroTitle>Live Egogram</S.IntroTitle>

      <S.IntroExplain1>자신의 성격을 시각적으로 파악 가능한 자아상태 진단 도구</S.IntroExplain1>

      <S.IntroExplain2>
        자아 인식과 대인관계에서부터 리더십과 팀빌딩, 의사결정에 <br />
        이르기까지 다양한 측면에서 활용할 수 있습니다.
      </S.IntroExplain2>

      <S.IntroButton variant="contained" onClick={goToDashBoard}>
        체험하기
      </S.IntroButton>

      <S.IntroCharacter src={characterImg} alt="character" />
    </S.Intro>
  );
};

const Benefit = ({ isMobile }) => {
  return (
    <S.EffectContainer>
      <S.OrangeBox>Live Egogram</S.OrangeBox>

      <S.EffectTitle>기대 효과</S.EffectTitle>

      <S.EffectExplain>
        개인의 성격과 특징을 시각화한 그래프로 {isMobile && <br />} 다양한 자아 상태 파악
      </S.EffectExplain>

      <S.EffectBox>
        {isMobile ? (
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            pagination={{ clickable: true }}
            navigation
          >
            {benefitData.map((data, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <S.EffectItem>
                    <S.EffectItemIcon src={data.img} alt="icon1" />

                    <S.EffectItemTitle>{data.title}</S.EffectItemTitle>

                    <S.EffectItemDescription>
                      {data.description[0]} <br /> {data.description[1]} &nbsp;
                    </S.EffectItemDescription>
                  </S.EffectItem>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          benefitData.map((data, idx) => {
            return (
              <S.EffectItem key={idx}>
                <S.EffectItemIcon src={data.img} alt="icon1" />

                <S.EffectItemTitle>{data.title}</S.EffectItemTitle>

                <S.EffectItemDescription>
                  {data.description[0]} <br /> {data.description[1]} &nbsp;
                </S.EffectItemDescription>
              </S.EffectItem>
            );
          })
        )}
      </S.EffectBox>
    </S.EffectContainer>
  );
};

const ConnectionScreen = ({ isMobile }) => {
  return (
    <S.ConnectionScreenContainer>
      {connectionScreenData.map((data, idx) => {
        return (
          <S.ConnectionScreenBox key={idx} id={data.id}>
            <S.OrangeBox>Live Egogram</S.OrangeBox>

            <S.ConnectionScreenTitle>{data.title}</S.ConnectionScreenTitle>

            <S.ConnectionScreenExplain>
              {data.explain[0]} <br /> {isMobile && <br />} {data.explain[1]}
            </S.ConnectionScreenExplain>

            <S.ConnectionScreenImgBox id={data.id}>
              <img src={data.img} alt={data.id} />
            </S.ConnectionScreenImgBox>

            <S.ConnectionScreenExplainsContainer target={(isMobile || false).toString()}>
              <S.ConnectionScreenExplainsBox>
                {data.explains.map((explain, idx) => {
                  return (
                    <S.ConnectionScreenExplainItem
                      key={idx}
                      id={explain.id + ""}
                      target={(isMobile || false).toString()}
                    >
                      <S.ConnectionScreenExplainItemTitleBox>
                        <S.OrangeCircle>{explain.id}</S.OrangeCircle>

                        <S.ConnectionScreenExplainItemTitle>
                          {explain.title}
                        </S.ConnectionScreenExplainItemTitle>
                      </S.ConnectionScreenExplainItemTitleBox>

                      <S.ConnectionScreenExplainItemDescription>
                        {explain.description[0]} <br /> {explain.description[1]}
                      </S.ConnectionScreenExplainItemDescription>
                    </S.ConnectionScreenExplainItem>
                  );
                })}
              </S.ConnectionScreenExplainsBox>
            </S.ConnectionScreenExplainsContainer>

            <a href={manual} target="_blank" rel="noreferrner">
              <S.ConnectionScreenButton target={(isMobile || false).toString()}>
                메뉴얼 보러가기
              </S.ConnectionScreenButton>
            </a>
          </S.ConnectionScreenBox>
        );
      })}
    </S.ConnectionScreenContainer>
  );
};

export default EgogramPage;
