import { styled } from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";
import { Box, Grid, Typography } from "@mui/material";

export const Body = MuiStyled(Box)((props) => {
  return {
    position: "relative",
    boxSizing: "border-box",
    minHeight: "100vh",
    minWidth: "320px",
    overflow: "auto",
    backgroundColor: "#fff",
    paddingTop: "80px",
  };
});

export const Container = MuiStyled((props) => <Grid container {...props} />)({
  padding: "20px",
});

export const Content = MuiStyled((props) => <Grid item {...props} />)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const View = MuiStyled((props) => <Grid item {...props} />)({
  height: "100%",
  padding: "20px",
  paddingTop: "80px",
});

export const ViewBox = styled.div`
  display: flex;
`;

export const Logo = styled.img`
  height: 91px;
  width: 740px;

  @media (max-width: 1000px) {
    height: 60px;
    width: 60px;
  }
`;

export const Text = styled.div`
  margin: 20px 0px;
  font-size: 50px;

  font-family: "NotoSansKR-Regular";
  font-weight: 700;
  text-align: center;
  color: #fff;

  @media (max-width: 1000px) {
    font-size: clamp(10px, 5.5vw, 24px);
  }
`;

export const EmpasisText = styled.span`
  color: #ba1f21;
  font-size: 66px;

  @media (max-width: 1000px) {
    font-size: clamp(20px, 10.5vw, 46px);
  }
`;

export const MacContainer = styled.div`
  width: 70%;
  margin-right: 10px;
  position: relative;
`;

export const MacView = styled.div`
  position: absolute;
  top: 5%;
  left: 4.4%;
  right: 4.4%;
  bottom: 36%;
`;

export const MacViewBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  > img {
    position: absolute;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  > img:nth-child(1),
  > img:nth-child(2) {
    object-position: left top;
  }
  > img[target="true"] {
    z-index: 1;
    opacity: 1;
  }
`;

export const PhoneContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  > img {
    z-index: 1;
  }
`;

export const PhoneBox = styled.div`
  position: absolute;
  top: 2.3%;
  bottom: 2.3%;
  left: 3.3%;
  right: 3%;
  border-radius: 20px;
`;

export const PhoneView = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;

  > img {
    position: absolute;
    height: 100%;
    border-radius: 20px;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  > img[target="true"] {
    z-index: 1;
    opacity: 1;
  }
`;

export const DotIndicaotr = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 5px;
`;

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.target === "true" ? "#BA1F21" : "#ababab")};
  transition: background-color 1s ease-in-out;
`;

// 1월 8일 추가

export const GroupAccountContainer = MuiStyled((props) => <Box {...props} />)({
  backgroundColor: "#f9f9f9",
  height: "450px",
  marginTop: "80px",
  padding: "45px 0",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "10px",
});

export const GroupAccountTitle = MuiStyled((props) => <Typography {...props} />)({
  font: "700 30px Pretendard",
  color: "343434",

  "@media (max-width: 1000px)": {
    height: "40px",
    fontSize: "clamp(24px, 7.5vw, 30px)",
  },
});

export const GroupAccountSubTitle = MuiStyled((props) => <Typography {...props} />)({
  font: "300 16px Pretendard",
  color: "#343434",

  "@media (max-width: 1000px)": {
    height: "25px",
    fontSize: "clamp(13px, 4vw, 16px)",
  },
});

export const GroupAccountContent = MuiStyled((props) => <Box {...props} />)({
  margin: "30px 0",
  flex: 1,
  maxWidth: "1300px",
  width: "100%",
});

export const GroupAccountGrid = MuiStyled((props) => <Grid container {...props} />)({
  height: "100%",
});

export const GroupAccountItem = MuiStyled((props) => <Grid item xs={3} {...props} />)({
  padding: "0 30px",
});

export const GroupBox = MuiStyled((props) => <Box {...props} />)({
  height: "100%",
  borderRadius: "10px",
  overflow: "hidden",
  boxShadow: "0px 3px 6px #00000029",
  backgroundColor: "#fff",
  width: "auto",

  "@media (max-width: 1000px)": {
    width: "200px",
    height: "150px",
    margin: "0 auto",
  },
});

export const GroupTitle = MuiStyled((props) => <Typography {...props} />)({
  font: "500 20px Pretendard",
  textAlign: "center",
  backgroundColor: "#c33c3c",
  color: "white",
  padding: "5px 0",
});

export const GroupContent = MuiStyled((props) => <Box {...props} />)({
  height: "100%",
  padding: "10px 20px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});

export const GroupDiscount = MuiStyled((props) => <Typography {...props} />)({
  font: "500 18px Pretendard",
  textAlign: "right",
  color: "#343434",
});

export const GroupBeforeDiscount = MuiStyled((props) => <Typography {...props} />)({
  font: "500 16px Pretendard",
  color: "#7CCC73",
  textDecoration: "line-through",
});

export const GroupPrice = MuiStyled((props) => <Typography {...props} />)({
  font: "700 30px Pretendard",
  fontSize: "clamp(16px, 1.9vw, 30px)",
  color: "#c33c3c",
  lineHeight: "1",
  "> span": { font: "500 10px Pretendard", color: "#808080" },
});
