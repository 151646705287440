import { useEffect, useState } from "react";
import S from "../style.module.css";

const FifthPageMobile = ({ userData, resultObjectArray, onNextClick, onPrevClick, firstTypeArray }) => {
  const [userType, setUserType] = useState("");
  const [userTypeSplit, setUserTypeSplit] = useState("");
  const [pros, setPros] = useState([]);
  const [cons, setCons] = useState([]);

  useEffect(() => {
    if (resultObjectArray[5]?.name) {
      const name = resultObjectArray[5]?.name.split("(")[0];
      setUserType(resultObjectArray[5]?.name);
      setUserTypeSplit(name);
    }
    if (firstTypeArray[2]?.pros) {
      setPros(firstTypeArray[2]?.pros.split("."));
    }
    if (firstTypeArray[2]?.cons) {
      setCons(firstTypeArray[2]?.cons.split("."));
    }
  }, [resultObjectArray, firstTypeArray]);

  return (
    <div className={S.Page}>
      <div className={S.Title}>
        <p>{userData.name} 님의 장·단점</p>
      </div>
      <div className={S.PageContents}>
        <div className={S.Box}>
          <p className={S.FifthPageProsTitle}>{userTypeSplit} 장점</p>
          <p className={S.FifthPageProsDesc}>
            {pros.map((pro, index) => pro && (
              <span key={index}>{pro.trim()}</span>
            ))}
          </p>
          <p className={S.FifthPageProsTitle} style={{ borderRadius: "0" }}>
            {userTypeSplit} 단점
          </p>
          <p className={S.FifthPageProsDesc}>
            {cons.map((con, index) => con && (
              <span key={index}>{con.trim()}</span>
            ))}
          </p>
        </div>
      </div>
      <p className={S.alert}>※ 본 모바일 결과지는 요약본입니다</p>
      <div className={S.PageFooter}>
        <div className={S.PrevBtn} onClick={onPrevClick}>
          ← 뒤로가기
        </div>
        <div className={S.NextBtn} onClick={onNextClick}>
          다음
        </div>
      </div>
    </div>
  );
};

export default FifthPageMobile;