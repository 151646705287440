import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router";
import { useCookies } from "react-cookie";
import { GET_USER_RESULT } from "utils/api/Hexagram";
import styles from "./style.module.css";
import FirstPage from "./ResultPapers/FirstPage";
import SecondPage from "./ResultPapers/SecondPage";
import ThirdPage from "./ResultPapers/ThirdPage";
import FourthPage from "./ResultPapers/FourthPage";
import FifthPage from "./ResultPapers/FifthPage";
import SixthPage from "./ResultPapers/SixthPage";
import SeventhPage from "./ResultPapers/SeventhPage";
import EighthPage from "./ResultPapers/EighthPage";
import NinthPage from "./ResultPapers/NinthPage";
import TenthPage from "./ResultPapers/TenthPage";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import {
  ActorType,
  PersisterType,
  DreamerType,
  EmoterType,
  AnalystType,
  PlayerType,
} from "assets/text/HexagramResultText";
import { keyframes } from "@mui/system"; // keyframes import

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export default () => {
  const { gameId, userId: userID } = useParams();
  const [cookies] = useCookies(["uid"]);
  const [resultObjectArray, setResultObjectArray] = useState([]);
  const [firstTypeArray, setFirstTypeArray] = useState([]);
  const [secondTypeArray, setSecondTypeArray] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(true);

  // 서버에서 받아오는 사용자 데이터 배열 초기화
  const [userData, setUserData] = useState({
    name: "",
    affiliation: "",
    result: "",
    date: "",
  });

  useEffect(() => {
    if (userData.name) {
      // userData가 설정된 후에 1초 대기 후 PDF 다운로드 시작
      setTimeout(() => {
        onCapture();
      }, 1000); // 1초 후에 실행
    }
  }, [userData]);

  // 서버에서 데이터를 받아오고, 이를 정렬하는 함수
  useEffect(() => {
    // 쿠키에서 userId 받아오기
    const userId = userID || cookies.uid;

    // 서버에서 사용자 데이터 받아오기
    GET_USER_RESULT(gameId, userId)
      .then((response) => {
        const { name, affiliation, result, date } = response.data;
        setUserData({ name, affiliation, result, date });

        // result(유저 응답)가 배열인지 확인 후, 아니라면 JSON.parse로 변환
        const resultArray = Array.isArray(result) ? result : JSON.parse(result);

        // 저장된 result(유저 응답)을 naming 할 수 있는 배열로 옮기기, 그리고 1순위부터 정렬
        const newResultObjectArray = [
          { name: "유희형(PL)", num: resultArray[0], type: "PlayerType" },
          { name: "신념형(PE)", num: resultArray[1], type: "PersisterType" },
          { name: "상상형(DR)", num: resultArray[2], type: "DreamerType" },
          { name: "분석형(AN)", num: resultArray[3], type: "AnalystType" },
          { name: "행동형(AC)", num: resultArray[4], type: "ActorType" },
          { name: "감성형(EM)", num: resultArray[5], type: "EmoterType" },
        ].sort((a, b) => a.num - b.num);
        setResultObjectArray(newResultObjectArray);

        // 가장 높은 값과 두 번째로 높은 값을 기준으로 형 타입 배열 설정
        if (newResultObjectArray.length > 1) {
          const firstType = newResultObjectArray[5].type;
          const secondType = newResultObjectArray[4].type;

          // 형 타입 배열 매핑
          const typeMapping = {
            ActorType,
            PersisterType,
            DreamerType,
            EmoterType,
            AnalystType,
            PlayerType,
          };

          setFirstTypeArray(typeMapping[firstType]);
          setSecondTypeArray(typeMapping[secondType]);
        }
      })
      .catch((error) => {
        console.error("에러입니다", error);
      });
  }, [gameId, cookies]);

  // 결과지를 PDF로 변환하고 페이지 높이를 설정하는 함수
  const onCapture = async () => {
    const userId = cookies.uid;
    // A4 크기의 PDF 문서 생성
    const doc = new jsPDF("p", "mm", "a4");
    // PDF 페이지 높이를 변수에 저장
    const pageHeight = doc.internal.pageSize.getHeight();

    // HTML 요소를 캡쳐하고 PDF에 추가하는 함수
    const captureAndAddToPDF = async (elementId) => {
      // 지정된 HTML 요소를 캡쳐하여 캔버스로 변환
      const canvas = await html2canvas(document.getElementById(elementId), {
        scale: 3, // 낮은 해상도로 설정하여 이미지 크기를 줄임
        useCORS: true, // CORS 이슈 방지
      });
      // 캡쳐된 캔버스를 JPEG 포맷으로 변환하고 품질 설정
      const imgData = canvas.toDataURL("image/jpeg", 2);
      // 변환된 이미지를 PDF에 추가
      doc.addImage(imgData, "JPEG", 0, 0, 210, pageHeight);
      // 다음 페이지를 추가
      doc.addPage();
    };

    // 각 HTML 요소를 순서대로 캡쳐하여 PDF에 추가
    await captureAndAddToPDF("capture1");
    await captureAndAddToPDF("capture2");
    await captureAndAddToPDF("capture3");
    await captureAndAddToPDF("capture4");
    await captureAndAddToPDF("capture5");
    await captureAndAddToPDF("capture6");
    await captureAndAddToPDF("capture7");
    await captureAndAddToPDF("capture8");
    await captureAndAddToPDF("capture9");
    await captureAndAddToPDF("capture10");

    // 마지막에 추가된 빈 페이지를 제거
    doc.deletePage(doc.internal.getNumberOfPages());
    // PDF 파일로 저장될 때 이름
    // if (userID) {
    // if (true) {
    // const blob = doc.output("blob");
    // const file = new File([blob], `${userData.name}님의 결과지.pdf`, {
    //   type: "application/pdf",
    // });
    // const blob = doc.output("blob");
    // const file = new File([blob], `${userData.name}님의 결과지.pdf`, {
    //   type: "application/pdf",
    // });

    // window.location.href = URL.createObjectURL(file);
    doc.save(`${userData.name}님의 결과지.pdf`);
    setIsModalOpen(false);
    // return;
    // }

    // doc.save("결과지.pdf");

    // const blob = doc.output("blob");
    // const file = new File([blob], "결과지.pdf", { type: "application/pdf" });

    // const formData = new FormData();
    // formData.append("file", file);

    // axios.post(`/live-hexagram/result/pdf?name=${userId}.pdf`, formData, {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // });
    // setIsModalOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "auto",
        bgcolor: "#c5c5c5",
        position: "relative",
      }}
    >
      {/* 모달창 */}
      {isModalOpen && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(255, 255, 255)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                border: "4px solid #f3f3f3",
                borderTop: "4px solid #3498db",
                borderRadius: "50%",
                width: "24px",
                height: "24px",
                animation: `${spin} 2s linear infinite`, // 애니메이션 적용
                marginBottom: "10px",
              }}
            />
            <p>PDF를 다운 받고 있습니다.</p>
          </Box>
        </Box>
      )}
      {/* 모달창 */}
      <div
        style={{
          position: "relative",
          backgroundColor: "#fff",
          height: "auto",
          display: "flex",
          flexDirection: "column",
        }}
        id="capture"
      >
        <Box className={styles.paper} id="capture1">
          <FirstPage userData={userData} />
        </Box>
        <Box className={styles.paper} id="capture2">
          <SecondPage />
        </Box>
        <Box className={styles.paper} id="capture3">
          <ThirdPage
            resultObjectArray={resultObjectArray}
            userData={userData}
            firstTypeArray={firstTypeArray}
          />
        </Box>
        <Box className={styles.paper} id="capture4">
          <FourthPage
            resultObjectArray={resultObjectArray}
            userData={userData}
            firstTypeArray={firstTypeArray}
            secondTypeArray={secondTypeArray}
          />
        </Box>
        <Box className={styles.paper} id="capture5">
          <FifthPage
            resultObjectArray={resultObjectArray}
            userData={userData}
            firstTypeArray={firstTypeArray}
          />
        </Box>
        <Box className={styles.paper} id="capture6">
          <SixthPage
            resultObjectArray={resultObjectArray}
            userData={userData}
            firstTypeArray={firstTypeArray}
          />
        </Box>
        <Box className={styles.paper} id="capture7">
          <SeventhPage
            resultObjectArray={resultObjectArray}
            userData={userData}
            firstTypeArray={firstTypeArray}
          />
        </Box>
        <Box className={styles.paper} id="capture8">
          <EighthPage
            resultObjectArray={resultObjectArray}
            userData={userData}
            secondTypeArray={secondTypeArray}
          />
        </Box>
        <Box className={styles.paper} id="capture9">
          <NinthPage
            resultObjectArray={resultObjectArray}
            userData={userData}
            secondTypeArray={secondTypeArray}
          />
        </Box>
        <Box className={styles.paper} id="capture10">
          <TenthPage
            resultObjectArray={resultObjectArray}
            userData={userData}
            secondTypeArray={secondTypeArray}
          />
        </Box>
      </div>
    </Box>
  );
};
