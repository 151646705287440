import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import Layout from "./layout";
import { Context, Provider } from "./context";

const PlanPage = () => {
  return (
    <Provider>
      <Content />
    </Provider>
  );
};

const Content = () => {
  // const F = useContext(Context);

  return <Layout />;
};

// // * 구독 플랜 페이지
// const PlanPage = () => {
//   const navigate = useNavigate();

//   // 반응형 웹
//   const isPc = useMediaQuery({
//     query: "(min-width:768px)",
//   });

//   // 반응형 모바일
//   const isMobile = useMediaQuery({
//     query: "(max-width:767px)",
//   });

//   // * 결제 페이지 이동
//   const goToPay = (type) => {
//     navigate(`/pay/${type}`);
//   };

//   return (
//     <>
//       {/* pc화면 */}
//       {isPc && (
//         <div
//           style={{
//             backgroundColor: "#F5F5F5",
//             height: "100vh",
//             textAlign: "center",
//           }}
//         >
//           <div className="container">
//             <div className="plan-background">
//               <div style={{ height: "20%" }}>
//                 <Typography
//                   color="primary"
//                   fontFamily="Noto Sans"
//                   fontWeight="700"
//                   fontSize="1.5rem"
//                   mt="3%"
//                   mb="1%"
//                 >
//                   Withplus Live 구독 플랜
//                 </Typography>
//                 <Typography color="#707070">
//                   신청 즉시 사용이 가능하며, 월별 결제하시는 경우 자동 결제 처리 됩니다.
//                 </Typography>
//                 <Typography color="#969696" fontSize="0.9rem" mt="1%">
//                   서비스 결제 후 15일 이내에 환불이 가능하며 구독 모델을 사용하신 경우 환불은 되지
//                   않습니다.
//                 </Typography>
//               </div>
//               <div className="plan-container">
//                 <div
//                   className="content"
//                   style={{ width: "100%", borderRight: "1px solid #DCDCDC" }}
//                 >
//                   <div
//                     style={{
//                       width: "auto",
//                       height: "auto",
//                     }}
//                   >
//                     <Typography fontSize="1.3rem" color="#C60F0F">
//                       Free
//                     </Typography>
//                     <Typography color="#878787" fontFamily="Noto Sans" mt="2%">
//                       Withplus Live에 가입한 누구나 사용할 수 있습니다.
//                     </Typography>
//                     <Typography color="#ff4545" fontFamily="Noto Sans" fontWeight="700" mt="2%">
//                       {"(무료 버전은 가입 후 30일 동안 사용가능합니다.)"}
//                     </Typography>

//                     <Typography
//                       color="#878787"
//                       fontFamily="Noto Sans"
//                       fontWeight="900"
//                       mt="2%"
//                       fontSize="20px"
//                     >
//                       사용가능한 서비스
//                     </Typography>
//                     <Typography color="#878787" fontFamily="Noto Sans">
//                       Live Polling, Live Quiz
//                     </Typography>

//                     <Typography color="#878787" fontFamily="Noto Sans">
//                       Live Pick, Live Deal
//                     </Typography>

//                     <Button variant="contained" style={{ marginTop: "5%" }} disabled>
//                       현재 구독 플랜입니다.
//                     </Button>
//                   </div>
//                 </div>
//                 <div className="content" style={{ position: "relative" }}>
//                   <div
//                     style={{
//                       width: "auto",
//                       height: "auto",
//                     }}
//                   >
//                     <Typography
//                       fontSize="1.3rem"
//                       color="#C60F0F"
//                       fontFamily="NotoSansKR-Medium"
//                       fontWeight="700"
//                     >
//                       베이식
//                     </Typography>
//                     <Typography color="#878787" fontFamily="Noto Sans" mt="2%">
//                       Withplus Live의 모든 컨텐츠를 사용할 수 있습니다.
//                     </Typography>
//                     <Typography color="#878787" fontFamily="Noto Sans" fontWeight="700" mt="2%">
//                       구독료
//                     </Typography>
//                     <Typography color="#878787" fontFamily="Noto Sans" fontWeight="700">
//                       {`9,900`}원 / 월
//                     </Typography>
//                     <Typography color="#878787" fontFamily="Noto Sans" fontWeight="700" mt="2%">
//                       사용가능한 구독 모델
//                     </Typography>
//                     <Typography color="#878787" fontFamily="Noto Sans">
//                       Free 버전 + <br />
//                       Egogram <br />
//                       Etc...
//                     </Typography>
//                     <Button
//                       variant="contained"
//                       style={{ marginTop: "5%" }}
//                       onClick={() => goToPay("pro")}
//                       // disabled
//                     >
//                       신청하기
//                     </Button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//       {/* 모바일 화면 */}
//       {isMobile && (
//         <div className="mobile-plan">
//           <div>
//             <Typography
//               color="primary"
//               fontWeight="700"
//               fontSize="1.5rem"
//               mt="3%"
//               mb="1%"
//               fontFamily="NotoSansKR-Bold"
//             >
//               Withplus Live 구독 플랜
//             </Typography>
//             <Typography color="#707070" fontFamily="NotoSansKR-Regular">
//               신청 즉시 사용이 가능하며, 월별 결제하시는 경우 자동 결제 처리 됩니다.
//             </Typography>
//             <Typography color="#969696" fontSize="0.9rem" mt="1%" fontFamily="NotoSansKR-Regular">
//               서비스 결제 후 15일 이내에 환불이 가능하며 구독 모델을 사용하신 경우 환불은 되지
//               않습니다.
//             </Typography>
//           </div>
//           <div className="mobile-plan-background">
//             <div className="plan-container">
//               <div
//                 style={{
//                   width: "auto",
//                   height: "auto",
//                 }}
//               >
//                 <Typography fontSize="1.3rem" color="#C60F0F" fontFamily="Roboto-Bold">
//                   Free
//                 </Typography>
//                 <Typography color="#878787" fontFamily="NotoSansKR-Regular" mt="2%">
//                   Withplus Live에 가입한 누구나 사용할 수 있습니다.
//                 </Typography>
//                 <Typography
//                   color="#878787"
//                   fontFamily="NotoSansKR-Regular"
//                   fontWeight="700"
//                   mt="2%"
//                 >
//                   구독료
//                 </Typography>
//                 <Typography color="#878787" fontFamily="NotoSansKR-Regular">
//                   0원 / 월
//                 </Typography>
//                 <Typography
//                   color="#878787"
//                   fontFamily="NotoSansKR-Regular"
//                   fontWeight="700"
//                   mt="2%"
//                 >
//                   사용가능한 구독 모델
//                 </Typography>
//                 <Typography color="#878787" fontFamily="Noto Sans">
//                   Live Polling
//                 </Typography>
//                 <Button variant="contained" style={{ marginTop: "5%" }} disabled>
//                   현재 구독 플랜입니다.
//                 </Button>
//               </div>
//             </div>
//           </div>
//           <div className="mobile-plan-background">
//             <div className="content" style={{ position: "relative" }}>
//               <div
//                 style={{
//                   position: "absolute",
//                   zIndex: 1,
//                   top: 0,
//                   left: 0,
//                   backgroundColor: "#efefefbb",
//                   width: "100%",
//                   height: "100%",
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                 }}
//               >
//                 <Typography sx={{ font: "bold 32px NotoSansKR-Medium" }}>준비중입니다.</Typography>
//               </div>
//               <div
//                 style={{
//                   width: "auto",
//                   height: "auto",
//                 }}
//               >
//                 <Typography fontSize="1.3rem" color="#C60F0F">
//                   Pro
//                 </Typography>
//                 <Typography color="#878787" fontFamily="Noto Sans" mt="2%">
//                   Withplus Live의 모든 컨텐츠를 사용할 수 있습니다.
//                 </Typography>
//                 <Typography color="#878787" fontFamily="Noto Sans" fontWeight="700" mt="2%">
//                   구독료
//                 </Typography>
//                 <Typography color="#878787" fontFamily="Noto Sans">
//                   {`45,000`}원 / 월
//                 </Typography>
//                 <Typography color="#878787" fontFamily="Noto Sans" fontWeight="700" mt="2%">
//                   사용가능한 구독 모델
//                 </Typography>
//                 <Typography color="#878787" fontFamily="Noto Sans">
//                   Free 버전 + <br />
//                   Egogram <br />
//                   Etc...
//                 </Typography>
//                 <Button
//                   variant="contained"
//                   style={{ marginTop: "5%" }}
//                   onClick={() => goToPay("pro")}
//                   disabled
//                 >
//                   신청
//                 </Button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

export default PlanPage;
