import Axios from "axios";

const axios = Axios.create({
  baseURL: "https://hexagram.withplus.live",
});

// 0805 사용자가 사용하겠다고 한 만큼 유저 스키마에서 토큰 빼주는 API
export const POST_USE_TOKENS = (auth, tokenUsage) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/use-tokens", { auth, tokenUsage })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 0805 사용하기로 한 토큰 만큼 핵사그램 스키마에 토큰을 추가해주는 API
export const POST_UPDATE_HEXAGRAM_TOKEN = (auth, hexagramId, tokenUsage) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/update-hexagram-token", { auth, hexagramId, tokenUsage })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 0805 결과보기 하면 토큰 하나씩 감소하는 API
export const POST_DECREMENT_HEXAGRAM_TOKEN = (hexagramId) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/decrement-hexagram-token", { hexagramId })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 0805 핵사그램 생성하는 API
export const POST_ADD_HEXAGRAM = (auth, title, token) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/add", { auth, title, token })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 0806 삭제 될 떄 토큰 회수 하는 API
export const DELETE_HEXAGRAM = (auth, hexagramIds) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/delete", { auth, hexagramIds })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 0806 토큰 회수 하는 API
export const RECLAIM_TOKENS = (auth, hexagramId) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/reclaim-tokens", { auth, hexagramId })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 0806 토큰 갯수 확인하는 API
export const CHECK_HEXAGRAM_TOKENS = (auth, hexagramId) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/check-token", { auth, hexagramId })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//0806 토큰 추가하는 함수
export const POST_ADD_TOKENS = (auth, hexagramId, tokenAmount) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/add-tokens", { auth, hexagramId, tokenAmount })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GET_LIST_HEXAGRAM = (auth) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/list", { params: { auth } })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GET_API_CODE = (hexagramId) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/code", { params: { hexagramId } })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const POST_USER_JOIN = (gameId, userId, name, affiliation) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/user-join", {
        gameId,
        userId,
        name,
        affiliation,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const POST_USER_RESULT = (gameId, userId, result) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/user-result", {
        gameId,
        userId,
        result,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GET_USER_RESULT = (gameId, userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/user-result", {
        params: {
          gameId: gameId,
          userId: userId,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GET_USER_FORM = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/download", { responseType: "blob" }) // Important: specify responseType as 'blob'
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "hexagram_user_template.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GET_USER_RESULTS = (gameId) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/users-result", {
        params: {
          gameId: gameId,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GET_USER_PDF = (userId) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/result/pdf", {
        params: {
          userId: userId,
        },
        responseType: "blob", // 응답 타입을 blob으로 설정
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const POST_SAVE_USERS = (gameId, users) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/save-users", { gameId, users })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const POST_DELETE_USERS = (gameId) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/delete-users", { gameId })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GET_USERS = (gameId) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/users", { params: { gameId } })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const POST_SEND_RESULT = (
  gameId,
  sendMethod,
  senderName,
  senderEmail,
  subject,
  content,
  senderPhone
) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/send-message", {
        gameId,
        sendMethod,
        senderName,
        senderEmail,
        subject,
        content,
        senderPhone,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GET_SMS_SENDED_COUNT = (gameId) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/sms-sended-count", { params: { gameId } })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
