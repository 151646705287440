import React, { useContext, useEffect, useState } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Box,
  Select,
  Typography,
  MenuItem,
  Tooltip,
  IconButton,
  Checkbox as CheckBox,
  Grid,
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HelpIcon from "@mui/icons-material/Help";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import ClearIcon from "@mui/icons-material/Clear";
import ImageIcon from "@mui/icons-material/Image";

import { Textfield as TextField, Textfield } from "components/Textfield";
import { CustomButton as Button } from "components/Button";

import { QuizContext } from "../contexts/Quiz";
import { SnackBarContext } from "../contexts/SnackBar";

import classes from "../styles/LiveQuizStyles";
// import { SnackBar } from "components/SnackBar";
import { UploadImageModal } from "components/Modal";
import { isHangul, isHangulLetter } from "utils/chosung";

const QuizEditor = () => {
  const { currentSlide } = useContext(QuizContext);

  switch (currentSlide.type) {
    case "객관식":
      return <SelectQuizEditor />;
    case "초성 퀴즈":
      return <ShortAnswerQuizEditor />;
    case "OX 퀴즈":
      return <OXQuizEditor />;
    default:
      return <></>;
  }
};

const SelectQuizEditor = () => {
  const { currentSlide, updateCurrentSlide } = useContext(QuizContext);
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);

  const [selectedAnswerId, setSelectedAnswerId] = useState(null);
  const [uploadImageModalOpened, setUploadImageModalOpened] = useState(false);

  const handleAnswer = (e, index) => {
    let text = e.target.value.slice(0, 50);

    const newAnswers = currentSlide.answers.map((answer, i) => {
      if (i === index) return { ...answer, text: text };
      return answer;
    });

    updateCurrentSlide({ answers: newAnswers });
  };

  const addAnswer = () => {
    if (currentSlide.answers.length === 6) {
      setText("답안은 최대 6개까지 가능합니다.");
      setSeverity("warning");
      setOpen(true);
      return;
    }

    const newAnswers = [...currentSlide.answers, { id: new Date().getTime() + "", text: "" }];

    updateCurrentSlide({ answers: newAnswers });
  };

  const deleteAnswer = (id) => {
    if (currentSlide.answers.length === 2) {
      setText("답안은 최소 2개가 필요합니다.");
      setSeverity("warning");
      setOpen(true);
      return;
    }

    const newAnswers = currentSlide.answers.filter((answer) => answer.id !== id);
    const newcorrectAnswerIDs = currentSlide.correctAnswerIDs.filter((index) => index !== id);

    updateCurrentSlide({ answers: newAnswers, correctAnswerIDs: newcorrectAnswerIDs });
  };

  const onDragEnd = (res) => {
    const sourceIndex = res.source.index;
    const destinationIndex = res.destination.index;

    let source = currentSlide.answers.filter((_, index) => index === sourceIndex);
    let remains = currentSlide.answers.filter((_, index) => index !== sourceIndex);

    let newAnswers = [
      ...remains.slice(0, destinationIndex),
      ...source,
      ...remains.slice(destinationIndex),
    ];

    updateCurrentSlide({ answers: newAnswers });
  };

  const handlecorrectAnswerIDs = (e, index) => {
    if (currentSlide.isMultiple === "true") {
      if (currentSlide.correctAnswerIDs.indexOf(e.currentTarget.id) !== -1) {
        updateCurrentSlide({
          correctAnswerIDs: currentSlide.correctAnswerIDs.filter(
            (item) => item !== e.currentTarget.id
          ),
        });
      } else {
        updateCurrentSlide({
          correctAnswerIDs: [...currentSlide.correctAnswerIDs, e.currentTarget.id],
        });
      }
    } else {
      if (currentSlide.correctAnswerIDs.indexOf(e.currentTarget.id) !== -1) {
        updateCurrentSlide({
          correctAnswerIDs: currentSlide.correctAnswerIDs.filter(
            (item) => item !== e.currentTarget.id
          ),
        });
      } else {
        updateCurrentSlide({
          correctAnswerIDs: [e.currentTarget.id],
        });
      }
    }
  };

  const handleMultiple = () => {
    if (currentSlide.isMultiple === "true") {
      updateCurrentSlide({ isMultiple: "false", correctAnswerIDs: [] });
    } else {
      updateCurrentSlide({ isMultiple: "true" });
    }
  };

  const handleUploadImageModal = (id) => {
    if (id) {
      setSelectedAnswerId(id);
    } else {
      setSelectedAnswerId(null);
    }

    setUploadImageModalOpened((prev) => !prev);
  };

  return (
    <>
      <Box sx={classes.edit.grid_item}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Box sx={classes.edit.answers_title_box}>
            <Typography sx={classes.edit.grid_item_title}>정답 (Answer)</Typography>

            <Tooltip
              arrow
              title={
                <>
                  <Typography>답안은 총 2 ~ 6개까지 생성할 수 있고, </Typography>
                  <Typography>복수 정답 설정이 가능합니다 :)</Typography>
                  <Typography>최대 답안 길이는 20자 이내 입니다.</Typography>
                </>
              }
            >
              <HelpIcon sx={classes.edit.answers_title_tooltip_icon} />
            </Tooltip>
          </Box>

          <Box sx={{ mr: "1rem", display: "flex", gap: "5px", alignItems: "center" }}>
            <CheckBox
              fontSize="large"
              checked={currentSlide.isMultiple === "true"}
              onChange={handleMultiple}
            />
            <Typography sx={{ fontFamily: "NotoSansKR-Medium" }}>복수 정답</Typography>
          </Box>
        </Box>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="answers">
            {(provided) => (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                {currentSlide.answers.map((item, index) => (
                  <Draggable draggableId={item.id} index={index} key={item.id}>
                    {(provided) => (
                      <div
                        key={item.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Box sx={classes.edit.answer_box} key={item.id}>
                          <IconButton sx={{ cursor: "grab" }}>
                            <DragIndicatorIcon />
                          </IconButton>

                          <CheckBox
                            fontSize="large"
                            id={item.id}
                            checked={currentSlide.correctAnswerIDs.indexOf(item.id) !== -1}
                            onChange={(e) => handlecorrectAnswerIDs(e)}
                          />

                          <Typography>{index + 1}.</Typography>

                          <TextField
                            size="small"
                            fullWidth
                            placeholder="답안을 입력해주세요."
                            value={item.text}
                            onChange={(e) => handleAnswer(e, index)}
                            InputProps={{
                              endAdornment: (
                                <span style={{ font: "500 12px Pretendard", color: "#777" }}>
                                  {item.text.length}/50
                                </span>
                              ),
                            }}
                          />

                          <Tooltip title="사진 업로드" arrow placement="left">
                            <IconButton onClick={() => handleUploadImageModal(item.id)}>
                              <ImageIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="지우기" arrow placement="right">
                            <IconButton onClick={() => deleteAnswer(item.id)}>X</IconButton>
                          </Tooltip>
                        </Box>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

        <Button fullWidth onClick={addAnswer}>
          <AddCircleOutlineIcon fontSize="large" />
          <Typography sx={classes.edit.add_answer_button_text}>&nbsp;답안 추가</Typography>
        </Button>

        {uploadImageModalOpened && (
          <UploadImageModal
            open={uploadImageModalOpened}
            handleModal={handleUploadImageModal}
            type="answer"
            selectedAnswerId={selectedAnswerId}
          />
        )}
      </Box>

      <Box sx={classes.edit.grid_item}>
        <Typography sx={classes.edit.grid_item_title}>옵션 (Option)</Typography>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <TimeEditor />
          {/* // ? 점수 사용 체크박스 (추후 사용 예정) */}
          <ScoreEditor />
        </Box>
      </Box>
    </>
  );
};

const ShortAnswerQuizEditor = () => {
  const { currentSlide, updateCurrentSlide } = useContext(QuizContext);
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);

  const handleAnswer = (e) => {
    let value = e.target.value;

    if (value.length > 15) {
      setText("최대 15자까지 입력 가능합니다.");
      setSeverity("warning");
      setOpen(true);

      updateCurrentSlide({ answer: value.slice(0, 15) });
      return;
    }

    var regexp = /[a-z0-9]|[\[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'\\]/g;

    if (regexp.test(value)) {
      value = value.replace(regexp, "");

      setText("한글만 입력이 가능합니다.");
      setSeverity("warning");
      setOpen(true);
    }

    updateCurrentSlide({ answer: value });
  };

  const addHint = () => {
    if (currentSlide.hints.length >= 4) {
      setText("힌트는 최대 4개까지 제공이 가능합니다.");
      setSeverity("warning");
      setOpen(true);
      return;
    }

    let newHints = [...currentSlide.hints, { id: new Date().getTime() + "", text: "" }];

    updateCurrentSlide({ hints: newHints });
  };

  const deleteHint = (e) => {
    let newHints = currentSlide.hints.filter((hint) => hint.id !== e.currentTarget.id);

    updateCurrentSlide({ hints: newHints });
  };

  const handleHint = (e) => {
    if (currentSlide.hints.length === 0) addHint();
    else {
      updateCurrentSlide({ hints: [] });
    }
  };

  const changeHint = (e, index) => {
    let newHints = currentSlide.hints.map((answer, i) => {
      if (i === index) return { ...answer, text: e.target.value };
      return answer;
    });

    updateCurrentSlide({ hints: newHints });
  };

  const onDragEnd = (res) => {
    const sourceIndex = res.source.index;
    const destinationIndex = res.destination.index;

    let source = currentSlide.hints.filter((_, index) => index === sourceIndex);
    let remains = currentSlide.hints.filter((_, index) => index !== sourceIndex);

    let newHints = [
      ...remains.slice(0, destinationIndex),
      ...source,
      ...remains.slice(destinationIndex),
    ];
    updateCurrentSlide({ hints: newHints });
  };

  return (
    <>
      <Box sx={classes.edit.grid_item}>
        <Box sx={classes.edit.answer_box}>
          <Box sx={classes.edit.answers_title_box}>
            <Typography sx={classes.edit.grid_item_title}>정답 (Answer)</Typography>

            <Tooltip
              arrow
              placement="top"
              title={<Typography>한글로 띄어쓰기 없이 입력해주세요.</Typography>}
            >
              <HelpIcon sx={classes.edit.answers_title_tooltip_icon} />
            </Tooltip>
          </Box>
        </Box>

        <TextField
          id="answer"
          size="small"
          fullWidth
          placeholder="답안을 입력해주세요."
          value={currentSlide.answer}
          onChange={handleAnswer}
          InputProps={{
            endAdornment: (
              <span style={{ font: "500 12px Pretendard", color: "#777" }}>
                {currentSlide.answer.length}/15
              </span>
            ),
          }}
        />
      </Box>
      <Box sx={classes.edit.grid_item}>
        <Typography sx={classes.edit.grid_item_title}>옵션 (Option)</Typography>

        {/* <Box sx={classes.edit.option_item}>
          <Box sx={classes.edit.hint_title_box}>
            <Typography sx={classes.edit.option_item_text}>힌트 제공</Typography>

            <Tooltip arrow title={<Typography>힌트는 최대 4개까지 가능합니다. </Typography>}>
              <HelpIcon sx={classes.edit.answers_title_tooltip_icon} />
            </Tooltip>
          </Box>

          <CheckBox onChange={handleHint} checked={currentSlide.hints?.length !== 0} />
        </Box> */}

        {/* <Box sx={classes.edit.hint_box(currentSlide.hints || [])}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="hints">
              {(provided) => (
                <Box sx={{}} {...provided.droppableProps} ref={provided.innerRef}>
                  {currentSlide.hints.map((item, index) => (
                    <Draggable draggableId={item.id} index={index} key={item.id}>
                      {(provided) => (
                        <div
                          key={item.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Box sx={{ display: "flex", mb: "5px" }}>
                            <IconButton sx={{ cursor: "grab" }}>
                              <DragIndicatorIcon />
                            </IconButton>

                            <TextField
                              size="small"
                              fullWidth
                              placeholder="힌트를 입력해주세요."
                              value={item.text}
                              onChange={(e) => changeHint(e, index)}
                            />

                            <Tooltip title="지우기" arrow placement="right">
                              <IconButton id={item.id} onClick={deleteHint}>
                                X
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>

          <Button fullWidth onClick={addHint} size="small">
            <AddCircleOutlineIcon fontSize="large" />
            <Typography sx={classes.edit.add_answer_button_text}>&nbsp;힌트 추가</Typography>
          </Button>
        </Box> */}

        <TimeEditor />
        <ScoreEditor />
      </Box>
    </>
  );
};

const OXQuizEditor = () => {
  const { currentSlide, updateCurrentSlide } = useContext(QuizContext);

  const handleOXAnswer = (e) => {
    updateCurrentSlide({ answer: e.currentTarget.id });
  };

  return (
    <>
      <Box sx={classes.edit.grid_item}>
        <Typography sx={classes.edit.grid_item_title}>정답 (Answer)</Typography>

        <Box>
          <Grid container>
            <Grid item xs={6}>
              <Box
                sx={classes.edit.OX_box("O", currentSlide.answer)}
                id="O"
                onClick={handleOXAnswer}
              >
                <PanoramaFishEyeIcon fontSize="large" sx={{ color: "red" }} />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                sx={classes.edit.OX_box("X", currentSlide.answer)}
                id="X"
                onClick={handleOXAnswer}
              >
                <ClearIcon fontSize="large" sx={{ color: "blue" }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box sx={classes.edit.grid_item}>
        <Typography sx={classes.edit.grid_item_title}>옵션 (Option)</Typography>

        <TimeEditor />
        <ScoreEditor />
      </Box>
    </>
  );
};

const ScoreEditor = () => {
  const { currentSlide, updateCurrentSlide } = useContext(QuizContext);
  const SnackBar = useContext(SnackBarContext);

  const [value, setValue] = useState(300);

  const handleScore = (e) => {
    if (e.target.value === "") {
      updateCurrentSlide({ score: "" });
      return;
    }

    if (isNaN(e.target.value * 1)) return;

    if (e.target.value * 1 > 1000) {
      SnackBar.setText("점수는 1000점까지 입력이 가능합니다.");
      SnackBar.setSeverity("warning");
      SnackBar.setOpen(true);

      updateCurrentSlide({ score: 1000 });
      return;
    }

    let score = e.target.value * 1;
    updateCurrentSlide({ score });
  };

  const onBlur = () => {
    if (value === "") {
      updateCurrentSlide({ score: 300 });
    }
  };

  useEffect(() => {
    if (!currentSlide) return;

    if (currentSlide.score === undefined || currentSlide.score === null) {
      updateCurrentSlide({ score: 300 });
    } else {
      setValue(currentSlide.score);
    }
  }, [currentSlide]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        pl: "16px",
        mb: "5px",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ font: "600 16px Pretendard" }}>최대 점수</Typography>

        <Tooltip
          arrow
          placement="top"
          title={<Typography>점수는 0점 ~ 1000점까지 입력이 가능합니다.</Typography>}
        >
          <HelpIcon sx={{ ...classes.edit.answers_title_tooltip_icon, mb: 0 }} />
        </Tooltip>
      </Box>

      <Textfield
        size="small"
        value={value}
        onChange={handleScore}
        onBlur={onBlur}
        sx={{ width: "100px" }}
        InputProps={{
          endAdornment: <span style={{ font: "600 16px Pretendard", color: "#777" }}>점</span>,
        }}
      />

      {/* <Select
        value={value}
        sx={{ bgcolor: "#f5f5f5", fontWeight: "500" }}
        size="small"
        onChange={handleScore}
      >
        <MenuItem value="0" sx={{ fontWeight: "600" }}>
          사용 안함
        </MenuItem>
        <MenuItem value="0.5" sx={{ fontWeight: "600" }}>
          X 1/2배 (150점 만점)
        </MenuItem>
        <MenuItem value="1" sx={{ fontWeight: "600" }}>
          기본 (300점 만점)
        </MenuItem>
        <MenuItem value="2" sx={{ fontWeight: "600" }}>
          X 2배 (600점 만점)
        </MenuItem>
      </Select> */}
    </Box>
  );
};

const TimeEditor = () => {
  const { currentSlide, updateCurrentSlide } = useContext(QuizContext);

  const handleTimer = (e) => {
    updateCurrentSlide({ timer: e.target.value });
  };

  return (
    <Box sx={classes.edit.option_item}>
      <Typography sx={classes.edit.option_item_text}>시간제한</Typography>

      <Select
        value={currentSlide.timer}
        sx={classes.edit.time_limit_select}
        size="small"
        onChange={handleTimer}
      >
        <MenuItem value={5}>5초</MenuItem>
        <MenuItem value={10}>10초</MenuItem>
        <MenuItem value={20}>20초</MenuItem>
        <MenuItem value={30}>30초</MenuItem>
        <MenuItem value={40}>40초</MenuItem>
        <MenuItem value={50}>50초</MenuItem>
        <MenuItem value={60}>60초</MenuItem>
      </Select>
    </Box>
  );
};

export default QuizEditor;
