// * npm modules
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactWordcloud from "react-wordcloud";
import { useMediaQuery } from "react-responsive";

// * material ui components
import { Box, Typography } from "@mui/material";

// * material ui icons
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// * contexts
import { ManagerContext } from "../contexts/ManagerContext";
import { FullScreenContext } from "contexts/FullScreen";

// * styles
import * as S from "./styles";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

// * 워드클라우드 컴포넌트
export default (props) => {
  const { playingData, currentSlideIndex } = useContext(ManagerContext); // * 매니저 컨텍스트

  const [question, setQuestion] = useState(""); // * 질문

  const [translate, setTranslate] = useState("translate(0%)"); // * 워드클라우드, 그래프 이동
  const [wordList, setWordList] = useState([]); // * 워드 리스트

  // * 워드 리스트 설정
  const setWords = (userData) => {
    let newWordList = [];

    for (let data of userData) {
      for (let word of data.wordList) {
        // * 단어가 이미 리스트에 있으면 value + 1
        if (newWordList.find((el) => el.text === word)) {
          newWordList.find((el) => el.text === word).value += 1;
        }
        // * 단어가 리스트에 없으면 추가
        else {
          newWordList.push({ text: word, value: 1 });
        }
      }
    }

    // * 워드 리스트 정렬
    newWordList.sort((a, b) => b.value - a.value); // * value 기준 내림차순 정렬

    setWordList(newWordList); // * 워드 리스트 설정
  };

  // * 현재 슬라이드의 질문과 워드 리스트 설정
  useEffect(() => {
    // * playingData가 로드되었을 때
    if (playingData[currentSlideIndex]) {
      setQuestion(playingData[currentSlideIndex].question); // * 질문 설정

      setWords(playingData[currentSlideIndex].userData); // * 워드 리스트 설정
    }
  }, [playingData, currentSlideIndex]);

  return (
    <S.WordCloud>
      {/* 질문 */}
      <Box sx={{ width: "calc(100% - 100px)" }}>
        <Typography sx={{ font: "700 44px Pretendard", borderBottom: "1px solid #eee" }}>
          {question || "질문이 없습니다."}
        </Typography>
      </Box>

      {/* 워드클라우드, 투표 수  */}
      <Box sx={{ flex: 1, position: "relative", display: "flex", overflow: "hidden" }}>
        <Box sx={{ position: "absolute", top: 10, left: 10 }}>
          <Typography sx={{ font: "700 28px Pretendard", mb: "10px" }}>워드 클라우드</Typography>

          <Typography sx={{ fontWeight: "600", color: "#888" }}>
            투표 수 : {playingData[currentSlideIndex]?.userData.length}
          </Typography>
        </Box>

        {/* 워드클라우드/그래프 메인 컴포넌트 */}
        <WordCloud translate={translate} words={wordList} />
        <Graph translate={translate} words={wordList} />

        <S.ToolTip title={translate === "translate(0%)" ? "" : "워드클라우드"} placement="right">
          {/* 워드클라우드 보기 버튼 */}
          <S.WordCloudLeftButton
            onClick={() => setTranslate("translate(0%)")}
            disabled={translate === "translate(0%)"}
          >
            <NavigateBeforeIcon />
          </S.WordCloudLeftButton>
        </S.ToolTip>

        <S.ToolTip title={translate === "translate(-100%)" ? "" : "그래프"} placement="left">
          {/* 그래프 보기 버튼 */}
          <S.WordCloudRightButton
            onClick={() => setTranslate("translate(-100%)")}
            disabled={translate === "translate(-100%)"}
          >
            <NavigateNextIcon />
          </S.WordCloudRightButton>
        </S.ToolTip>
      </Box>
    </S.WordCloud>
  );
};

// ? 워드클라우드
const WordCloud = ({ translate, words }) => {
  const ref = useRef(null); // * 워드클라우드 캔버스 ref
  const { handle } = useContext(FullScreenContext); // * 풀스크린 컨텍스트

  const [word, setWord] = useState(null); // * 마우스 오버된 단어
  const [isResizing, setIsResizing] = useState(false); // * 리사이징 중 여부
  const [position, setPosition] = useState({}); // * 마우스 위치
  const [maxFontSize, setMaxFontSize] = useState(100); // * 최대 폰트 사이즈
  const [minFontSize, setMinFontSize] = useState(40); // * 최소 폰트 사이즈

  let newMaxFontSize = 100; // * 최대 폰트 사이즈
  let newMinFontSize = 30; // * 최소 폰트 사이즈

  const textLengths = words.map((word) => word.text.length); // * 단어 길이

  // * 24인치 모니터 이상
  const wideScreen = useMediaQuery({
    query: "(min-width:2200px)",
  });

  // * 폰트 사이즈 설정
  useEffect(() => {
    if (wideScreen) {
      if (textLengths.every((length) => length < 10)) {
        newMaxFontSize = 200;
      } else if (textLengths.every((length) => length < 15)) {
        newMaxFontSize = 130;
      } else if (textLengths.every((length) => length < 20)) {
        newMaxFontSize = 93;
        newMinFontSize = 25;
      } else if (textLengths.every((length) => length < 26)) {
        //24인치 기준 25글자 최대 사이즈
        newMaxFontSize = 71;
        newMinFontSize = 20;
      }
    } else if (!wideScreen) {
      if (textLengths.every((length) => length < 10)) {
        newMaxFontSize = 130;
      } else if (textLengths.every((length) => length < 15)) {
        newMaxFontSize = 95;
      } else if (textLengths.every((length) => length < 20)) {
        newMaxFontSize = 70;
        newMinFontSize = 25;
      } else if (textLengths.every((length) => length < 26)) {
        //14인치 기준 25글자 최대 사이즈
        newMaxFontSize = 56;
        newMinFontSize = 25;
      }
    }
    setMaxFontSize(newMaxFontSize);
    setMinFontSize(newMinFontSize);
  }, [words, textLengths, wideScreen]);

  // * 워드클라우드 마우스 오버, 마우스 아웃 콜백
  const callbacks = {
    onWordMouseOver: (word) => {
      setWord(word); // * 마우스 오버된 단어 설정
    },
    onWordMouseOut: (_) => {
      setWord(null); // * 마우스 아웃시 단어 null
    },
  };

  // * 마우스 이동 이벤트
  useEffect(() => {
    if (!handle.active) return; // * 풀스크린이 아닐 때 리턴
    if (!ref.current) return; // * ref가 없을 때 리턴

    // * 마우스 이동 핸들러
    const mouseMoveHandler = (e) => {
      setPosition({ x: e.offsetX, y: e.offsetY - 50 }); // * 툴팁 위치 설정
    };

    // * 마우스 이동 이벤트 리스너 등록
    ref.current.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      if (!ref.current) return; // * ref가 없을 때 리턴

      ref.current.removeEventListener("mousemove", mouseMoveHandler); // * 마우스 이동 이벤트 리스너 제거
    };
  }, [handle, ref]);

  // * 리사이징 이벤트
  useEffect(() => {
    // * 리사이징 이벤트 리스너 등록
    window.addEventListener("resize", () => {
      setIsResizing(true); // * 리사이징 중 여부 설정

      setTimeout(() => {
        setIsResizing(false); // * 리사이징 중 여부 설정
      }, 100);
    });
  }, []);

  return (
    <S.WordCloudContent translate={translate}>
      {/* 워드클라우드 */}
      {words.length !== 0 && (
        <S.WordCloudArea ref={ref}>
          {!isResizing && (
            <ReactWordcloud
              callbacks={callbacks}
              words={words}
              options={{
                fontSizes: [minFontSize, maxFontSize],
                fontFamily: "SUIT-Heavy",
                rotations: 1,
                rotationAngles: [0],
                spiral: "archimedean",
                deterministic: true,
                enableOptimizations: false,
              }}
            />
          )}

          {/* 전체화면 시 툴팁 생성 */}
          {handle.active && word !== null && (
            <S.WordTooltip x={position.x} y={position.y}>
              {`${word.text} (${word.value})`}
            </S.WordTooltip>
          )}
        </S.WordCloudArea>
      )}

      {/* 투표 데이터가 없을 때 텍스트 */}
      {words.length === 0 && (
        <Typography sx={{ font: "600 24px Pretendard", color: "#888" }}>
          참여자의 투표를 기다리는 중...
        </Typography>
      )}
    </S.WordCloudContent>
  );
};

// ? 그래프
const Graph = ({ translate, words }) => {
  return (
    <S.Graph translate={translate}>
      {/* 그래프 */}
      {words.length !== 0 && (
        <S.GraphArea>
          {words.map((word) => (
            <Box key={word.text} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "40%", pl: "10%", display: "flex" }}>
                {/* 단어 */}
                <Typography sx={{ font: "600 18px Pretendard", width: "50%" }}>
                  {word.text}
                </Typography>

                {/* 투표 수 */}
                <Typography sx={{ font: "600 18px Pretendard" }}>({word.value})</Typography>
              </Box>

              {/* 그래프 */}
              <S.GraphBox>
                <S.TotalGraph>
                  <S.GraphBar
                    width={parseInt((word.value / words.reduce((a, b) => a + b.value, 0)) * 100)}
                  />
                </S.TotalGraph>

                {/* 퍼센트 */}
                <Typography sx={{ width: "10%", font: "600 18px Pretendard" }}>
                  {((word.value / words.reduce((a, b) => a + b.value, 0)) * 100).toPrecision(3)}%
                </Typography>
              </S.GraphBox>
            </Box>
          ))}
        </S.GraphArea>
      )}

      {/* 투표 데이터가 없을 때 텍스트 */}
      {words.length === 0 && (
        <Typography sx={{ font: "600 24px Pretendard", color: "#888", margin: "auto" }}>
          참여자의 투표를 기다리는 중...
        </Typography>
      )}
    </S.Graph>
  );
};
