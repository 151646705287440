import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Button, Checkbox, Divider, IconButton, MenuItem, Typography } from "@mui/material";

import FavoriteIcon from "@mui/icons-material/Favorite";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ViewColumnRoundedIcon from "@mui/icons-material/ViewColumnRounded";
import PanToolIcon from "@mui/icons-material/PanTool";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

import * as S from "../styles";

import { Textfield } from "components/Textfield";

import { ManagerContext } from "views/LiveBoard/contexts/Manager";

const Settings = ({ onClose }) => {
  const { gameId } = useParams();
  const { name, explain, socket, layout } = useContext(ManagerContext);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isTitleChanged, setTitleChanged] = useState(false);
  const [isDescriptionChanged, setDescriptionChanged] = useState(false);

  const handleTitle = (e) => {
    if (e.target.value === name) {
      setTitleChanged(false);
    } else {
      setTitleChanged(true);
    }

    setTitle(e.target.value);
  };

  const handleDescription = (e) => {
    if (e.target.value === explain) {
      setDescriptionChanged(false);
    } else {
      setDescriptionChanged(true);
    }

    setDescription(e.target.value);
  };

  const changeTitle = () => {
    if (name === title) return;

    socket.emit("changeTitle", { name: title, gameId });
  };

  const changeDescription = () => {
    if (explain === description) return;

    socket.emit("changeExplain", { explain: description, gameId });
  };

  const handleLayoutType = (e) => {
    socket.emit("changeLayout", { layout: { type: e.target.value }, gameId });
  };

  const handleGroup = (e) => {
    socket.emit("changeLayout", { layout: { section: e.target.checked }, gameId });
  };

  const handleSort = (e) => {
    socket.emit("changeLayout", { layout: { sort: e.target.value }, gameId });
  };

  const handlePostSize = (e) => {
    socket.emit("changeLayout", { layout: { postSize: e.target.value }, gameId });
  };

  useEffect(() => {
    setTitle(name);
    setDescription(explain);

    setTitleChanged(false);
    setDescriptionChanged(false);
  }, [name, explain]);

  return (
    <S.Settings>
      <IconButton className="close-button" onClick={onClose}>
        <CloseIcon />
      </IconButton>

      <Typography className="title">설정</Typography>

      <Box>
        <S.SettingTitle>머리글</S.SettingTitle>

        <S.SettingBox>
          <S.SettingItem>
            <Box className="title">
              <Typography>제목</Typography>

              {isTitleChanged && <Button>변경하기</Button>}
            </Box>

            <Textfield
              fullWidth
              size="small"
              className="input"
              InputProps={{
                onBlur: changeTitle,
              }}
              onChange={handleTitle}
              value={title}
            />
          </S.SettingItem>

          <Divider />

          <S.SettingItem>
            <Box className="title">
              <Typography>설명</Typography>

              {isDescriptionChanged && <Button>변경하기</Button>}
            </Box>

            <Textfield
              fullWidth
              size="small"
              className="input"
              value={description}
              onChange={handleDescription}
              InputProps={{
                onBlur: changeDescription,
              }}
            />
          </S.SettingItem>
        </S.SettingBox>
      </Box>

      <Box>
        <S.SettingTitle>레이아웃</S.SettingTitle>

        <S.SettingBox>
          <S.SettingItem className="layout">
            <Box className="title">
              <Typography>형식</Typography>

              <S.ToolTip title="게시물의 배치 방식을 선택하세요." placement="right">
                <HelpIcon />
              </S.ToolTip>
            </Box>

            <S.SelectBox value={layout.type || "wall"} onChange={handleLayoutType}>
              <MenuItem value="wall">
                <S.SelectItem>
                  <DashboardRoundedIcon />

                  <Typography>대시보드</Typography>
                </S.SelectItem>
              </MenuItem>

              <MenuItem value="grid">
                <S.SelectItem>
                  <GridViewRoundedIcon />

                  <Typography>그리드</Typography>
                </S.SelectItem>
              </MenuItem>

              <MenuItem value="timeline">
                <S.SelectItem>
                  <ViewColumnRoundedIcon />

                  <Typography>타임라인</Typography>
                </S.SelectItem>
              </MenuItem>
            </S.SelectBox>
          </S.SettingItem>

          <Divider />

          <S.SettingItem className="layout">
            <Box className="title">
              <Typography>그룹화</Typography>

              <S.ToolTip title="게시물의 그룹화 여부를 선택하세요." placement="right">
                <HelpIcon />
              </S.ToolTip>
            </Box>

            <Checkbox checked={layout.section || false} onChange={handleGroup} />
          </S.SettingItem>

          <Divider />

          <S.SettingItem className="layout">
            <Box className="title">
              <Typography>정렬</Typography>

              <S.ToolTip title="게시물의 정렬 방식을 선택하세요." placement="right">
                <HelpIcon />
              </S.ToolTip>
            </Box>

            <S.SelectBox value={layout.sort || "dnd"} onChange={handleSort}>
              <MenuItem value="dnd">
                <S.SelectItem>
                  <PanToolIcon />

                  <Typography>드래그 앤 드롭</Typography>
                </S.SelectItem>
              </MenuItem>

              <MenuItem value="likes">
                <S.SelectItem>
                  <FavoriteIcon />

                  <Typography>좋아요 순</Typography>
                </S.SelectItem>
              </MenuItem>
            </S.SelectBox>
          </S.SettingItem>

          <Divider />

          <S.SettingItem className="layout">
            <Box className="title">
              <Typography>게시물 크기</Typography>

              <S.ToolTip title="게시물의 크기를 선택하세요." placement="right">
                <HelpIcon />
              </S.ToolTip>
            </Box>

            <S.SelectBox value={layout.postSize || "small"} onChange={handlePostSize}>
              <MenuItem value="big">
                <S.SelectItem>
                  <ZoomInIcon />

                  <Typography>크게</Typography>
                </S.SelectItem>
              </MenuItem>

              <MenuItem value="small">
                <S.SelectItem>
                  <ZoomOutIcon />

                  <Typography>작게</Typography>
                </S.SelectItem>
              </MenuItem>
            </S.SelectBox>
          </S.SettingItem>
        </S.SettingBox>
      </Box>
    </S.Settings>
  );
};

export default Settings;
