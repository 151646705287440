import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { POST_DELETE_USERS } from "utils/api/Hexagram";
import { SMSSendingModal } from "./Modal/Modal";

const columns = [
  { id: "name", label: "이름", minWidth: 110, align: "center" },
  { id: "affiliation", label: "소속", minWidth: 110, align: "center" },
  { id: "email", label: "이메일", minWidth: 110, align: "center" },
  { id: "phone", label: "연락처", minWidth: 110, align: "center" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderRight: "1px solid rgba(224, 224, 224, 1)",
  "&:last-child": {
    borderRight: 0,
  },
}));

const FileAlreadyUploadTable = forwardRef(({ users, getUsers }, ref) => {
  const gameId = useParams().gameId;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortedRows, setSortedRows] = useState([]);

  const [isAddModalOpened, setAddModalOpened] = useState(false);

  const handleSendResultModal = () => {
    setAddModalOpened(!isAddModalOpened);
  };

  useEffect(() => {
    if (users && Array.isArray(users)) {
      setSortedRows(users);
    }
  }, [users]);

  useImperativeHandle(ref, () => ({
    getTableData: () => sortedRows,
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = async () => {
    try {
      const response = await POST_DELETE_USERS(gameId);
      alert("파일이 성공적으로 삭제되었습니다.");
      getUsers(gameId);
    } catch (error) {
      console.error("Error deleting users:", error);
      alert("파일 삭제 중 오류가 발생했습니다.");
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      <Box sx={{ width: "95%", display: "flex", flexDirection: "column", gap: "15px" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, font: "600 16px pretendard" }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <StyledTableCell key={column.id + rowIndex} align={column.align}>
                            {value}
                          </StyledTableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={sortedRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 1,
          }}
        >
          <Button
            onClick={handleSendResultModal}
            sx={{
              fontWeight: 600,
              fontSize: 14,
              cursor: "pointer",
            }}
          >
            핵사그램 진단 요청 메일/문자 보내기 {"->"}
          </Button>
          <Button
            sx={{
              alignSelf: "flex-end",
              fontWeight: 500,
              fontSize: 14,
              backgroundColor: "#b43f3f",
              color: "white",
              borderRadius: 1,
              padding: "6px 12px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#c33c3c",
              },
            }}
            onClick={handleDelete}
          >
            파일 삭제
          </Button>
        </Box>
      </Box>
      <SMSSendingModal open={isAddModalOpened} onClose={handleSendResultModal} gameId={gameId} />
    </Box>
  );
});

export default FileAlreadyUploadTable;
