import { useEffect, useState } from "react";
import S from "../style.module.css";

const ThirdPageMobile = ({ resultObjectArray, onNextClick, onPrevClick, userData, firstTypeArray }) => {
  const [userType, setUserType] = useState("");
  const [userTypeSplit, setUserTypeSplit] = useState("");
  const [typeDescriptions, setTypeDescriptions] = useState([]);

  useEffect(() => {
    if (resultObjectArray[5]?.name) {
      const name = resultObjectArray[5]?.name.split("(")[0];
      setUserType(resultObjectArray[5]?.name);
      setUserTypeSplit(name);
    }
    if (firstTypeArray[1]?.typeDescription) {
      setTypeDescriptions(firstTypeArray[1]?.typeDescription.split("."));
    }
  }, [resultObjectArray, firstTypeArray]);

  const getNumByName = (name) => {
    const obj = resultObjectArray.find((item) => item.name === name);
    return obj ? obj.num : "";
  };

  return (
    <div className={S.Page}>
      <div className={S.Title}>
        <p>{userData.name} 님의 기본 타입</p>
      </div>
      <div className={S.PageContents}>
        <div className={S.ThirdPageTable}>
          <table>
            <thead>
              <tr>
                <th>구분</th>
                <th>유희형(PL)</th>
                <th>신념형(PE)</th>
                <th>상상형(DR)</th>
                <th>분석형(AN)</th>
                <th>행동형(AC)</th>
                <th>감성형(EM)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  진단
                  <br />
                  결과
                </td>
                <td>{getNumByName("유희형(PL)")}</td>
                <td>{getNumByName("신념형(PE)")}</td>
                <td>{getNumByName("상상형(DR)")}</td>
                <td>{getNumByName("분석형(AN)")}</td>
                <td>{getNumByName("행동형(AC)")}</td>
                <td>{getNumByName("감성형(EM)")}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={S.UserTypeInfo}>
          <p
            className={S.UserTypeInfoTitle}
            style={{
              background: "linear-gradient(to top, rgba(245,194,66,0.5) 40%, transparent 40%)",
            }}
          >
            01. {userTypeSplit}
          </p>
          <p className={S.UserTypeInfoWord}>
            {firstTypeArray[1]?.typeWord}
          </p>
        </div>
        <div className={S.UserTypeBox}>
          <p className={S.KeyWordTitle}>Key-word:</p>
          <p className={S.KeyWordDesc}>
            {firstTypeArray[1]?.keyWord}
          </p>
          <p className={S.UserTypeBoxInfo}>
            {typeDescriptions.map((desc, index) => desc && (
              <span key={index}>{desc.trim()}</span>
            ))}
          </p>
        </div>
      </div>
      <p className={S.alert}>※ 본 모바일 결과지는 요약본입니다</p>
      <div className={S.PageFooter}>
        <div className={S.PrevBtn} onClick={onPrevClick}>
          ← 뒤로가기
        </div>
        <div className={S.NextBtn} onClick={onNextClick}>
          다음
        </div>
      </div>
    </div>
  );
};

export default ThirdPageMobile;