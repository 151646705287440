export default ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29">
      <defs>
        <style>
          {`
          .cls-1 {
            fill: ${fill};
          }
          .cls-2, .cls-3 {
            stroke: none;
          }
          .cls-3 {
            fill: ${fill};
          }
          `}
        </style>
      </defs>
      <g id="그룹_1" data-name="그룹 1" transform="translate(0 -0.5)">
        <g id="빼기_3" data-name="빼기 3" className="cls-1" transform="translate(16 15.5)">
          <path
            className="cls-2"
            d="M 12.99960041046143 13.50040054321289 L 0.9999002814292908 13.50040054321289 C 0.7242502570152283 13.50040054321289 0.5000002980232239 13.27574062347412 0.5000002980232239 12.99960041046143 L 0.5000002980232239 0.9999002814292908 C 0.5000002980232239 0.7242502570152283 0.7242502570152283 0.5000002980232239 0.9999002814292908 0.5000002980232239 L 12.99960041046143 0.5000002980232239 C 13.27574062347412 0.5000002980232239 13.50040054321289 0.7242502570152283 13.50040054321289 0.9999002814292908 L 13.50040054321289 12.99960041046143 C 13.50040054321289 13.27574062347412 13.27574062347412 13.50040054321289 12.99960041046143 13.50040054321289 Z M 2.599600315093994 3.000100374221802 L 2.599600315093994 3.500100374221802 L 2.599600315093994 5.000400066375732 L 2.599600315093994 8.800200462341309 L 2.599600315093994 10.30049991607666 L 2.599600315093994 10.80049991607666 L 3.099600315093994 10.80049991607666 L 11.09969997406006 10.80049991607666 L 11.59969997406006 10.80049991607666 L 11.59969997406006 10.30049991607666 L 11.59969997406006 8.800200462341309 L 11.59969997406006 8.300200462341309 L 11.09969997406006 8.300200462341309 L 5.529200077056885 8.300200462341309 L 5.529200077056885 5.500400066375732 L 11.02950000762939 5.500400066375732 L 11.52950000762939 5.500400066375732 L 11.52950000762939 5.000400066375732 L 11.52950000762939 3.500100374221802 L 11.52950000762939 3.000100374221802 L 11.02950000762939 3.000100374221802 L 3.099600315093994 3.000100374221802 L 2.599600315093994 3.000100374221802 Z"
          />
          <path
            className="cls-3"
            d="M 12.99960041046143 13.00040054321289 L 13.00040054321289 12.99960041046143 L 12.99960041046143 1.000000238418579 L 1.000000238418579 0.9999002814292908 L 0.9999002814292908 13.00040054321289 L 12.99960041046143 13.00040054321289 M 2.099600315093994 2.500100374221802 L 12.02950000762939 2.500100374221802 L 12.02950000762939 6.000400066375732 L 6.029200077056885 6.000400066375732 L 6.029200077056885 7.800200462341309 L 12.09969997406006 7.800200462341309 L 12.09969997406006 11.30049991607666 L 2.099600315093994 11.30049991607666 L 2.099600315093994 2.500100374221802 M 12.99960041046143 14.00040054321289 L 0.9999002814292908 14.00040054321289 C 0.448550283908844 14.00040054321289 2.716064386731887e-07 13.55144023895264 2.716064386731887e-07 12.99960041046143 L 2.716064386731887e-07 0.9999002814292908 C 2.716064386731887e-07 0.448550283908844 0.448550283908844 2.716064386731887e-07 0.9999002814292908 2.716064386731887e-07 L 12.99960041046143 2.716064386731887e-07 C 13.55144023895264 2.716064386731887e-07 14.00040054321289 0.448550283908844 14.00040054321289 0.9999002814292908 L 14.00040054321289 12.99960041046143 C 14.00040054321289 13.55144023895264 13.55144023895264 14.00040054321289 12.99960041046143 14.00040054321289 Z M 3.099600315093994 3.500100374221802 L 3.099600315093994 10.30049991607666 L 11.09969997406006 10.30049991607666 L 11.09969997406006 8.800200462341309 L 5.029200077056885 8.800200462341309 L 5.029200077056885 5.000400066375732 L 11.02950000762939 5.000400066375732 L 11.02950000762939 3.500100374221802 L 3.099600315093994 3.500100374221802 Z"
          />
        </g>
        <g id="빼기_2" data-name="빼기 2" className="cls-1" transform="translate(0 15.5)">
          <path
            className="cls-2"
            d="M 12.99960041046143 13.50040054321289 L 0.9999002814292908 13.50040054321289 C 0.7242502570152283 13.50040054321289 0.5000002980232239 13.27574062347412 0.5000002980232239 12.99960041046143 L 0.5000002980232239 0.9999002814292908 C 0.5000002980232239 0.7242502570152283 0.7242502570152283 0.5000002980232239 0.9999002814292908 0.5000002980232239 L 12.99960041046143 0.5000002980232239 C 13.27574062347412 0.5000002980232239 13.50040054321289 0.7242502570152283 13.50040054321289 0.9999002814292908 L 13.50040054321289 12.99960041046143 C 13.50040054321289 13.27574062347412 13.27574062347412 13.50040054321289 12.99960041046143 13.50040054321289 Z M 2.599600315093994 3.129700183868408 L 2.599600315093994 3.629700183868408 L 2.599600315093994 10.12950038909912 L 2.599600315093994 10.62950038909912 L 3.099600315093994 10.62950038909912 L 11.25 10.62950038909912 L 11.75 10.62950038909912 L 11.75 10.12950038909912 L 11.75 8.630100250244141 L 11.75 8.130100250244141 L 11.25 8.130100250244141 L 5.399600505828857 8.130100250244141 L 5.399600505828857 3.629700183868408 L 5.399600505828857 3.129700183868408 L 4.899600505828857 3.129700183868408 L 3.099600315093994 3.129700183868408 L 2.599600315093994 3.129700183868408 Z"
          />
          <path
            className="cls-3"
            d="M 12.99960041046143 13.00040054321289 L 13.00040054321289 12.99960041046143 L 12.99960041046143 1.000000238418579 L 1.000000238418579 0.9999002814292908 L 0.9999002814292908 13.00040054321289 L 12.99960041046143 13.00040054321289 M 2.099600315093994 2.629700183868408 L 5.899600505828857 2.629700183868408 L 5.899600505828857 7.630100250244141 L 12.25 7.630100250244141 L 12.25 11.12950038909912 L 2.099600315093994 11.12950038909912 L 2.099600315093994 2.629700183868408 M 12.99960041046143 14.00040054321289 L 0.9999002814292908 14.00040054321289 C 0.448550283908844 14.00040054321289 2.716064386731887e-07 13.55144023895264 2.716064386731887e-07 12.99960041046143 L 2.716064386731887e-07 0.9999002814292908 C 2.716064386731887e-07 0.448550283908844 0.448550283908844 2.716064386731887e-07 0.9999002814292908 2.716064386731887e-07 L 12.99960041046143 2.716064386731887e-07 C 13.55144023895264 2.716064386731887e-07 14.00040054321289 0.448550283908844 14.00040054321289 0.9999002814292908 L 14.00040054321289 12.99960041046143 C 14.00040054321289 13.55144023895264 13.55144023895264 14.00040054321289 12.99960041046143 14.00040054321289 Z M 3.099600315093994 3.629700183868408 L 3.099600315093994 10.12950038909912 L 11.25 10.12950038909912 L 11.25 8.630100250244141 L 4.899600505828857 8.630100250244141 L 4.899600505828857 3.629700183868408 L 3.099600315093994 3.629700183868408 Z"
          />
        </g>
        <g id="빼기_1" data-name="빼기 1" className="cls-1" transform="translate(8 0.5)">
          <path
            className="cls-2"
            d="M 12.99960041046143 13.50040054321289 L 0.9999002814292908 13.50040054321289 C 0.7242502570152283 13.50040054321289 0.5000002980232239 13.27574062347412 0.5000002980232239 12.99960041046143 L 0.5000002980232239 0.9999002814292908 C 0.5000002980232239 0.7242502570152283 0.7242502570152283 0.5000002980232239 0.9999002814292908 0.5000002980232239 L 12.99960041046143 0.5000002980232239 C 13.27574062347412 0.5000002980232239 13.50040054321289 0.7242502570152283 13.50040054321289 0.9999002814292908 L 13.50040054321289 12.99960041046143 C 13.50040054321289 13.27574062347412 13.27574062347412 13.50040054321289 12.99960041046143 13.50040054321289 Z M 8.750200271606445 6.040400505065918 L 8.750200271606445 10.81980037689209 L 8.750200271606445 11.31980037689209 L 9.250200271606445 11.31980037689209 L 11.01959991455078 11.31980037689209 L 11.51959991455078 11.31980037689209 L 11.51959991455078 10.81980037689209 L 11.51959991455078 6.040900230407715 L 11.52965068817139 6.040910243988037 L 11.5296106338501 5.540370464324951 L 11.52950000762939 4.120160102844238 L 11.52945995330811 3.620200157165527 L 11.02950000762939 3.620200157165527 L 3.079800367355347 3.620200157165527 L 2.579800367355347 3.620200157165527 L 2.579800367355347 4.120200157165527 L 2.579800367355347 5.540400505065918 L 2.579800367355347 6.040400505065918 L 3.079800367355347 6.040400505065918 L 8.750200271606445 6.040400505065918 Z"
          />
          <path
            className="cls-3"
            d="M 12.99960041046143 13.00040054321289 L 13.00040054321289 12.99960041046143 L 12.99960041046143 1.000000238418579 L 1.000000238418579 0.9999002814292908 L 0.9999002814292908 13.00040054321289 L 12.99960041046143 13.00040054321289 M 8.250200271606445 6.540400505065918 L 2.079800367355347 6.540400505065918 L 2.079800367355347 3.120200157165527 L 12.02941989898682 3.120200157165527 L 12.02969074249268 6.541410446166992 L 12.01959991455078 6.541399955749512 L 12.01959991455078 11.81980037689209 L 8.250200271606445 11.81980037689209 L 8.250200271606445 6.540400505065918 M 12.99960041046143 14.00040054321289 L 0.9999002814292908 14.00040054321289 C 0.448550283908844 14.00040054321289 2.716064386731887e-07 13.55144023895264 2.716064386731887e-07 12.99960041046143 L 2.716064386731887e-07 0.9999002814292908 C 2.716064386731887e-07 0.448550283908844 0.448550283908844 2.716064386731887e-07 0.9999002814292908 2.716064386731887e-07 L 12.99960041046143 2.716064386731887e-07 C 13.55144023895264 2.716064386731887e-07 14.00040054321289 0.448550283908844 14.00040054321289 0.9999002814292908 L 14.00040054321289 12.99960041046143 C 14.00040054321289 13.55144023895264 13.55144023895264 14.00040054321289 12.99960041046143 14.00040054321289 Z M 3.079800367355347 4.120200157165527 L 3.079800367355347 5.540400505065918 L 9.250200271606445 5.540400505065918 L 9.250200271606445 10.81980037689209 L 11.01959991455078 10.81980037689209 L 11.01959991455078 5.540400505065918 L 11.0296106338501 5.540410041809082 L 11.02950000762939 4.120200157165527 L 3.079800367355347 4.120200157165527 Z"
          />
        </g>
      </g>
    </svg>
  );
};
