import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { Button, TextField, Container, Typography, Box, Link } from "@mui/material";

import { LOGIN_USER } from "utils/api";
import { NaverButton } from "../oAuth/Naver";
import { GoogleButton } from "../oAuth/Google";
import { KakaoButton } from "views/oAuth/Kakao";

function Login(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const date = new Date();
  const [, setCookie] = useCookies();
  const [loginType, setloginType] = useState("Admin");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  // 기본 admin으로 사용
  const [checkBox, setCheckBox] = useState([false, true]);
  const [helperStyle, setHelperStyle] = useState(false);

  // 반응형 웹
  const isPc = useMediaQuery({
    query: "(min-width:768px)",
  });

  // 반응형 모바일
  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });

  // * 로그인 정보 서버 전달
  // ! loginType 지우기
  const handleSubmit = (event) => {
    event.preventDefault();

    const sso = searchParams.get("sso");
    const env = searchParams.get("env");

    if (sso) {
      LOGIN_USER({ loginType, email, password }, (res) => {
        let data = res.data;

        if (data.success) {
          if (env === "dev") {
            window.location.replace(`http://localhost:5173/sso?token=${data.auth}`);
          } else {
            window.location.replace(`https://hexagram.withplus.live/sso?token=${data.auth}`);
          }
        } else {
          alert("로그인에 실패하였습니다.");
        }
      });

      return;
    }

    if (loginType === "") {
      setHelperStyle(true);
      return;
    }

    if (loginType !== "") {
      LOGIN_USER({ loginType, email, password }, (res) => {
        let data = res.data;

        if (data.success) {
          if (
            data.user.company === "성문고등학교" &&
            data.user.password ===
              "eyJhbGciOiJIUzI1NiJ9.MTIzNA.T8KywaEgwcDaUd_KSuhIhymLM3aI9Cv-lQUUD2J2P6A"
          ) {
            navigate("/password/change", { state: { userId: data.user._id } });
          } else if (data.user.passwordChangingNeeded) {
            navigate("/password/change", { state: { userId: data.user._id } });
          } else {
            let expires = new Date();
            expires.setTime(date.getTime() + 1000 * 60 * 60 * 5);

            setCookie("auth", data.auth, { path: "/", expires: expires });
            if (state) {
              navigate(state.redirect, { state: state.state });
            } else {
              navigate("/dashboard");
            }
          }
        } else {
          if (data.error === "Not Certificated")
            navigate("/certification", { state: { userId: data.user._id } });
          else alert("로그인에 실패하였습니다.");
        }
      });
    }
  };

  // * Email value Change Event
  const handleEmail = (e) => {
    setemail(e.target.value);
  };
  // * Password value Change Event
  const handlePassword = (e) => {
    setpassword(e.target.value);
  };
  // *  Super Admin value Change Event
  const handleChangeSuperAdmin = (e) => {
    if (!e.target.checked) {
      let newArr = [...checkBox];
      newArr[0] = false;
      setCheckBox(newArr);
    } else {
      setCheckBox([e.target.checked, !e.target.checked]);
    }
  };
  // *  Admin value Change Event
  const handleChangeAdmin = (e) => {
    if (!e.target.checked) {
      let newArr = [...checkBox];
      newArr[1] = false;
      setCheckBox(newArr);
    } else {
      setCheckBox([!e.target.checked, e.target.checked]);
    }
  };
  // * 비밀번호 찾기 페이지 이동 함수
  const goToPasswordPage = () => {
    navigate("/password");
  };

  useEffect(() => {
    window.parentCallback = (res) => {
      window.location.href = res;
    };
  }, []);

  // // * 체크박스 변경시 로그인 타입명 변경
  // useEffect(() => {
  //   if (checkBox[0]) setloginType("SuperAdmin");
  //   else if (checkBox[1]) setloginType("Admin");
  //   else setloginType("");
  // }, [checkBox]);

  // useEffect(() => {
  //   socket.on("login", (data) => {
  //     if (data.success) {
  //       let expires = new Date();
  //       expires.setTime(date.getTime() + 1000 * 60 * 60 * 5);
  //       setCookie("auth", data.auth, { path: "/", expires: expires });
  //       navigate(`/dashboard`);
  //     } else {
  //       if (data.error === "Not Certificated") {
  //         navigate("/certification", { state: { userId: data.user._id } });
  //       } else {
  //         alert("로그인에 실패하였습니다.");
  //       }
  //     }
  //   });

  //   return () => {
  //     socket.off("login");
  //   };
  // }, []);
  return (
    <>
      {/* pc 화면 */}
      {isPc && (
        <div className="login-container">
          <Container
            maxWidth="md"
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box onSubmit={handleSubmit} component="form" sx={{ width: "100%" }}>
              <div className="login-text-title">로그인</div>

              {/* 이메일, 비밀번호 입력 필드 */}
              <div
                style={{
                  display: "block",
                  justifyContent: "center",
                  marginTop: "3%",
                }}
              >
                <TextField
                  sx={{
                    width: "60%",
                    // height: "70px",
                    backgroundColor: "#F5F5F5",
                    border: "none",
                  }}
                  type="text"
                  margin="normal"
                  label="이메일"
                  required
                  onChange={(e) => handleEmail(e)}
                  autoComplete="off"
                />
                {/* 패스워드 Textfield */}
                <TextField
                  sx={{
                    width: "60%",
                    // height: "70px",
                    backgroundColor: "#F5F5F5",
                    border: "none",
                  }}
                  type="password"
                  margin="normal"
                  label="패스워드"
                  required
                  onChange={(e) => handlePassword(e)}
                />

                {/* 비밀번호 찾기 */}
                <div>
                  <Link
                    className="test"
                    onClick={goToPasswordPage}
                    sx={{
                      cursor: "pointer",
                      fontFamily: "Noto Sans",
                      fontSize: "15px",
                      color: "#191919",
                      "&:hover": {
                        color: "#19191999",
                      },
                    }}
                  >
                    비밀번호를 잊으셨나요? →
                  </Link>
                </div>
                {/* 로그인 버튼 */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    width: "60%",
                    display: "block",
                    margin: "auto",
                    mt: 3,
                    mb: 3,
                    fontFamily: "Noto Sans",
                    fontWeight: "bold",
                    fontSize: "17px",
                    borderRadius: "5px",
                  }}
                >
                  로그인
                </Button>

                {/* 카카오 로그인  */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #cdcdcd",
                    py: "20px",
                    borderRadius: "10px",
                    position: "relative",
                    margin: "auto",
                    width: "60%",
                  }}
                >
                  <Typography
                    sx={{
                      position: "absolute",
                      top: -10,
                      left: 10,
                      bgcolor: "#fff",
                      fontFamily: "NotoSansKR-Medium",
                      px: "10px",
                    }}
                  >
                    간편 로그인
                  </Typography>

                  <KakaoButton />
                  <NaverButton />
                  <GoogleButton />
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center", mt: "10px" }}></Box>
              </div>
            </Box>
          </Container>
        </div>
      )}
      {/* 모바일 화면 */}
      {isMobile && (
        <div className="mobile-login-container">
          <Container
            maxWidth="md"
            sx={{
              height: "100%",
              // textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box onSubmit={handleSubmit} component="form" sx={{ width: "100%" }}>
              <div
                style={{
                  font: "bold 27px Pretendard",
                  color: "#c33c3c",
                }}
              >
                로그인
              </div>
              {/* 이메일, 비밀번호 입력 필드 */}
              <div
                style={{
                  display: "block",
                  justifyContent: "center",
                  marginTop: "3%",
                }}
              >
                <TextField
                  sx={{
                    width: "80%",
                    border: "none",
                  }}
                  type="text"
                  margin="normal"
                  label="이메일"
                  required
                  onChange={(e) => handleEmail(e)}
                  autoComplete="off"
                  variant="standard"
                />
                {/* 패스워드 Textfield */}
                <TextField
                  sx={{
                    width: "80%",
                    border: "none",
                  }}
                  type="password"
                  margin="normal"
                  label="패스워드"
                  required
                  onChange={(e) => handlePassword(e)}
                  variant="standard"
                />

                {/* 비밀번호 찾기 */}
                <div style={{ marginTop: "5%" }}>
                  <Link
                    className="test"
                    onClick={goToPasswordPage}
                    sx={{
                      cursor: "pointer",
                      fontFamily: "Noto Sans",
                      fontSize: "15px",
                      color: "#191919",
                    }}
                  >
                    비밀번호를 잊으셨나요? →
                  </Link>
                </div>
                {/* 로그인 버튼 */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    width: "80%",
                    display: "block",
                    margin: "auto",
                    mt: 3,
                    mb: 2,
                    fontFamily: "Noto Sans",
                    fontWeight: "bold",
                    fontSize: "17px",
                    borderRadius: "5px",
                  }}
                >
                  로그인
                </Button>

                {/* 카카오 로그인  */}
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #cdcdcd",
                    py: "10px",
                    mx: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      position: "absolute",
                      top: -10,
                      left: 10,
                      bgcolor: "white",
                      font: "12px NotoSansKR-Medium",
                    }}
                  >
                    소셜 로그인
                  </Typography>
                  <KakaoButton />
                  <NaverButton />
                  <GoogleButton />
                </Box>
              </div>
            </Box>
          </Container>
        </div>
      )}
    </>
  );
}

export default Login;
