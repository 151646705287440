import managerImg from "assets/images/ContentPage/LivePolling/managerImg.png";
import userImg from "assets/images/ContentPage/LivePolling/userImg.png";
import icon1 from "assets/images/ContentPage/LivePolling/icon1.png";
import icon2 from "assets/images/ContentPage/LivePolling/icon2.png";
import icon3 from "assets/images/ContentPage/LivePolling/icon3.png";
import icon4 from "assets/images/ContentPage/LivePolling/icon4.png";
import wordCloudIcon from "assets/images/ContentPage/LivePolling/wordCloudIcon.png";
import choiceIcon from "assets/images/ContentPage/LivePolling/choiceIcon.png";
import barIcon from "assets/images/ContentPage/LivePolling/barIcon.png";
import circleIcon from "assets/images/ContentPage/LivePolling/circleIcon.png";
import donutIcon from "assets/images/ContentPage/LivePolling/donutIcon.png";

export const benefitData = [
  {
    id: 1,
    description: ["진행자와 참여자 간의", "실시간 소통"],
    img: icon1,
  },
  {
    id: 2,
    description: ["강의 전", "아이스브레이킹"],
    img: icon2,
  },
  {
    id: 3,
    description: ["강의 후 기억에 남는", "키워드 리뷰"],
    img: icon3,
  },
  {
    id: 4,
    description: ["진행자와 참여자 간의", "유대감 형성"],
    img: icon4,
  },
];

export const connectionScreenData = [
  {
    id: "manager",
    title: "진행자용 접속화면",
    explain: [
      "생성한 질문은 Live Polling의 My List에서 확인 가능",
      "주관식 Word Cloud 유형과 객관식 Choice 유형 중 선택",
    ],
    img: managerImg,
    button: "메뉴얼 다운받기",
    explains: [
      {
        id: 1,
        title: "Withplus.Live 접속",
        description: ["위드플러스 라이브에 접속 후", "로그인 - [마이페이지] - [Live Polling]"],
      },
      {
        id: 2,
        title: "설문 생성",
        description: ["제목과 설문 내용을 입력", "타입 선택 후 옵션 설정"],
      },
      {
        id: 3,
        title: "QR코드 배포",
        description: ["QR코드와 접속코드를 참여자에게", "배포, 스캔 요청"],
      },
      {
        id: 4,
        title: "결과도출",
        description: ["참여자들의 답변을 실시간으로", "확인 및 공유"],
      },
    ],
  },
  {
    id: "user",
    title: "참여자용 접속화면",
    explain: [
      "모바일 접속 -> QR코드로 접속 or 코드 넘버 입력",
      "PC, 노트북 접속 -> Withplus.Live 페이지 -> 우측 상단의 접속코드 클릭 -> 코드 넘버 입력",
    ],
    img: userImg,
    button: "메뉴얼 다운받기",
    explains: [
      {
        id: 1,
        title: "QR 접속",
        description: ["진행자가 배포한 QR코드 스캔"],
      },
      {
        id: 2,
        title: "접속 코드 입력",
        description: ["접속 코드 입력 후", "라이브 폴링에 접속"],
      },
      {
        id: 3,
        title: "답변 제출",
        description: ["질문에 대한 답변을 입력한 후 제출"],
      },
      {
        id: 4,
        title: "진행자 화면에서 결과 확인",
        description: ["진행자 화면에서", "다른 참여자들의 답변 확인"],
      },
    ],
  },
];

export const typeDescriptionData = [
  {
    id: 1,
    title: "Word Cloud",
    description: [
      "- 참여자들의 주관식 응답을 실시간으로 수집",
      "- 질문에 대한 짧은 응답이 시각적으로 구성",
      "- 참여자들의 자유로운 의견 표출 용도로 적합",
    ],
    img: wordCloudIcon,
  },
  {
    id: 2,
    title: "Choice",
    description: [
      "- 주어진 보기 중 응답을 선택하는 객관식 질문",
      "- 3가지 유형의 결과 레이아웃 제공",
      "- 분석이 수월한 결과 데이터 제공",
    ],
    img: choiceIcon,
  },
];

export const choiceData = [
  {
    id: 1,
    title: "막대 그래프",
    description: "항목별 응답 수를 막대 모양으로 나타냄",
    img: barIcon,
  },
  {
    id: 2,
    title: "원형 그래프",
    description: "전체 응답에 대한 각 항목의 비율을 원 모양으로 나타냄",
    img: circleIcon,
  },
  {
    id: 3,
    title: "도넛 그래프",
    description: "전체 응답에 대한 각 항목의 비율을 도넛 모양으로 나타냄",
    img: donutIcon,
  },
];
