import React, { useContext } from "react";

import { Box, Grid, Typography } from "@mui/material";

import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import TextsmsIcon from "@mui/icons-material/Textsms";
import FlakyIcon from "@mui/icons-material/Flaky";

import classes from "../styles/LiveQuizStyles";
import { QuizContext } from "../contexts/Quiz";
import { QuizTemplate } from "template/Quiz";

import Chosung from "assets/images/Icon/chosung";
import OXIcon from "assets/images/Icon/OXIcon";
import SelectIcon from "assets/images/Icon/SelectIcon";

const QuizType = (props) => {
  const { item, selected } = props;
  const { slideList, currentSlide, setCurrentSlide, setSlideList } = useContext(QuizContext);

  const changeSlideType = (type) => {
    if (currentSlide.type === type) return;

    let newCurrentSlide = {
      ...currentSlide,
      ...QuizTemplate(type),
      timer: currentSlide.timer,
      question: currentSlide.question,
      mediaType: currentSlide.mediaType,
      // mediaObject: currentSlide.mediaObject,
      // mediaCroppedObject: currentSlide.mediaCroppedObject,
    };

    setCurrentSlide(newCurrentSlide);

    setSlideList(
      slideList.map((item) => {
        return item.id === currentSlide.id ? newCurrentSlide : item;
      })
    );
  };

  return (
    <Grid item xs={4} sx={classes.edit.quiz_type_item}>
      <Box
        sx={{
          ...(selected ? classes.edit.quiz_type_box_active : classes.edit.quiz_type_box),
          display: "flex",
          gap: "10px",
        }}
        onClick={() => changeSlideType(item)}
        id={item}
      >
        {(() => {
          switch (item) {
            case "객관식":
              return (
                // <DoneOutlineOutlinedIcon
                //   fontSize="large"
                //   sx={selected ? classes.edit.quiz_type_icon_active : undefined}
                // />
                <SelectIcon selected={selected ? "true" : "false"} />
              );

            case "초성 퀴즈":
              return (
                // <TextsmsIcon
                //   fontSize="large"
                //   sx={selected ? classes.edit.quiz_type_icon_active : undefined}
                // />
                // <img
                //   src={Chosung}
                //   alt="chosung"
                //   style={{ width: "30px", height: "30px", fill: "#ffffff" }}
                // />
                <Chosung fill={selected ? "white" : "#444"} />
              );

            case "OX 퀴즈":
              // return (
              //   <FlakyIcon
              //     fontSize="large"
              //     sx={selected ? classes.edit.quiz_type_icon_active : undefined}
              //   />
              // );
              return <OXIcon color={selected ? "white" : "#444"} />;

            default:
              return <></>;
          }
        })()}

        <Typography
          sx={selected ? classes.edit.quiz_type_text_active : classes.edit.quiz_type_text}
        >
          {item}
        </Typography>
      </Box>
    </Grid>
  );
};

export default QuizType;
