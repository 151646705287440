import axios from "axios";

import KAKAO from "../config/kakao";

// export const editPoll = (id, type, name, question, data, callback) => {
//   axios
//     .post("/poll/edit", null, {
//       params: { id: id, name: name, data: data, type: type, question: question },
//     })
//     .then((res) => {
//       let data = res.data;

//       if (data.success) {
//         alert("수정이 완료되었습니다.");
//         callback();
//       }
//     });
// };

export const checkCode = (code, callback) => {
  axios.get("/api/code", { params: { code: code } }).then((res) => callback(res));
};

export const KAKAO_getAccessToken = async (code) => {
  const res = await axios.post(
    `https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${KAKAO.REST_API_KEY}&redirect_uri=${KAKAO.REDIRECT_PAGE}&code=${code}`,
    {
      headers: {
        "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }
  );

  return res.data.access_token;
};

export const KAKAO_getAccount = async (ACCESS_TOKEN) => {
  const res = await axios.get(`https://kapi.kakao.com/v2/user/me`, {
    headers: {
      "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    },
  });

  return res.data.kakao_account;
};

export const API_CheckUser = async (email, name, profile, token) => {
  const res = await axios.get("/account/checkUser", {
    params: { email: email, name: name },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
};

// export const API_DeletePoll = (deletePollList, callback) => {
//   axios.post("/poll/delete", null, { params: { polls: deletePollList } }).then((res) => {
//     callback(res);
//   });
// };

// export const API_DuplicatePoll = (pollId, callback) => {
//   axios.post("/poll/duplicate", null, { params: { pollId: pollId } }).then((res) => {
//     callback(res);
//   });
// };

// export const API_ResetPoll = (pollId, callback) => {
//   axios.post("/poll/reset", null, { params: { pollId: pollId } }).then((res) => {
//     callback(res);
//   });
// };

export const LOGIN_USER = (params, callback) => {
  axios.get("/account/login", { params }).then((res) => callback(res));
};

export const oAuthLogin = (type, params, callback, err) => {
  axios
    .get(`/api/oAuth/${type}`, { params })
    .then((res) => callback(res))
    .catch(() => err());
};

export const API_GET_DECODED = (code) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/decode", { params: { code: code } })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
