import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import TOSS_PAYMENTS from "config/tossPayments";
import config from "views/PaymentPage/config";

export default () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const requestData = {
      orderId: searchParams.get("orderId"),
      amount: searchParams.get("amount"),
      paymentKey: searchParams.get("paymentKey"),
    };

    const secretKey = config.TOSS_PAYMENT_WIDGET_SECRET_KEY;
    const encryptedSecretKey = `Basic ${window.btoa(secretKey + ":")}`;

    const confirm = async () => {
      const response = await fetch("https://api.tosspayments.com/v1/payments/confirm", {
        method: "POST",
        headers: {
          Authorization: encryptedSecretKey,
          "Content-Type": "application/json",
          //   "TossPayments-Test-Code": "INVALID_STOPPED_CARD",
        },
        body: JSON.stringify(requestData),
      });

      const json = await response.json();

      if (!response.ok) {
        navigate(
          `/diagnosis-plan/payment/fail?code=${json.code}&message=${json.message}&order-id=${requestData.orderId}`,
          { replace: true }
        );
        return;
      }

      const params = {
        orderId: json.orderId,
        orderName: json.orderName,
        approvedAt: json.approvedAt,
        paymentKey: json.paymentKey,
        method: json.method,
        receipt: json.receipt.url,
        product: searchParams.get("product"),
        count: searchParams.get("count"),
      };

      axios.post(`/api/payment/enroll/diagnosis`, params).then((res) => {
        const { success, user, order, code, message } = res.data;

        if (success) {
          navigate("/diagnosis-plan/payment/success", {
            replace: true,
            state: { user, order, response: json },
          });
        } else {
          navigate(
            `/diagnosis-plan/payment/fail?code=${code}&message=${message}&order-id=${params.orderId}`,
            {
              replace: true,
            }
          );
        }
      });
    };

    confirm();
  }, []);
  return <></>;
};
