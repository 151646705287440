import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";

import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ReplayIcon from "@mui/icons-material/Replay";

import { DataGrid } from "@mui/x-data-grid";

import * as API from "utils/api/Board";

import Title from "components/Title";
import { ListPageUserInfo as UserInfo } from "components/UserInfo";
import { CustomButton as Button } from "components/Button";
import { BoardAddModal, DeleteBoardModal } from "components/Modal";
import { SnackBarContext } from "components/SnackBar/ContextAPI";

import * as S from "./styles";

export default () => {
  // * 보드 리스트 컬럼
  const columns = [
    {
      field: "name",
      headerName: "보드 이름",
      flex: 2,
      renderCell: (params) => {
        return (
          <Tooltip title="진행 페이지 이동" arrow>
            <Link to={`/board/manager/${params.row.id}`} target="_blank" style={{ width: "100%" }}>
              <S.BoardNameBox id={params.id}>
                <PlayCircleFilledWhiteIcon />

                <Typography>{params.value}</Typography>
              </S.BoardNameBox>
            </Link>
          </Tooltip>
        );
      },
    },
    { field: "type", headerName: "타입", flex: 1 },
    { field: "created", headerName: "날짜", flex: 1 },
    {
      field: "action",
      headerName: "",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <>
            <IconButton id={params.id} onClick={handleMenu}>
              <MoreVertIcon />
            </IconButton>

            <S.BoardActionMenu
              id={params.id}
              anchorEl={anchorEl}
              open={anchorEl === null ? false : params.id === anchorEl.id}
              onClose={handleMenu}
            >
              <S.Item onClick={goToBoard} id={params.id}>
                <PlayCircleOutlineOutlinedIcon />
                <Typography>슬라이드로 이동</Typography>
              </S.Item>

              <S.Item onClick={handleDeleteModalByMenu} id={params.id}>
                <DeleteForeverOutlinedIcon />
                <Typography>삭제</Typography>
              </S.Item>

              <S.Item onClick={duplicateBoard} id={params.id}>
                <ContentCopyOutlinedIcon />
                <Typography>복제</Typography>
              </S.Item>

              <S.Item onClick={resetBoard} id={params.id}>
                <ReplayIcon />
                <Typography>리셋</Typography>
              </S.Item>
            </S.BoardActionMenu>
          </>
        );
      },
    },
  ];

  const [cookies] = useCookies(); // * 쿠키
  const navigate = useNavigate(); // * 페이지 이동 함수

  const { setOpen, setSeverity, setText } = useContext(SnackBarContext); // * 스낵바

  const [boardList, setBoardList] = useState([]); // * 보드 리스트
  const [checkedList, setCheckedList] = useState([]); // * 체크된 보드 리스트
  const [anchorEl, setAnchorEl] = useState(null); // * 메뉴

  const [isAddModalOpened, setAddModalOpened] = useState(false); // * 보드 추가 모달 오픈 여부
  const [isDeleteModalOpened, setDeleteModalOpened] = useState(false); // * 보드 삭제 모달 오픈 여부

  // * 보드 추가 모달 오픈/클로즈
  const handleAddModal = () => {
    setAddModalOpened(!isAddModalOpened); // * 모달 오픈/클로즈
  };

  // * 보드 삭제 모달 오픈/클로즈
  const handleDeleteModal = () => {
    // * 삭제 모달이 닫힐 때 체크된 리스트 초기화
    if (isDeleteModalOpened) {
      setCheckedList([]);
    }

    setDeleteModalOpened(!isDeleteModalOpened); // * 모달 오픈/클로즈
  };

  // * 체크된 리스트 변경
  const handleCheckList = (list) => {
    setCheckedList([...list]); // * 체크된 리스트 변경
  };

  // * 보드 추가
  const addBoard = (title, explain) => {
    // * 보드 추가 API 호출 (유저 토큰, 보드 이름, 콜백 함수)
    API.addBoard(cookies.auth, title, explain, (res) => {
      let { success, board } = res.data; // * 응답 데이터 (성공 여부, 보드 정보)

      // * 성공 시
      if (success) {
        setOpen(true); // * 스낵바 오픈
        setSeverity("success"); // * 스낵바 타입 (성공)
        setText("보드가 추가되었습니다."); // * 스낵바 텍스트

        // navigate(`/board/manager/${board._id}`); // * 보드 페이지로 이동
        window.open(`/board/manager/${board._id}`, "_blank"); // * 새 탭으로 보드 페이지 열기

        getBoardList(); // * 보드 리스트 다시 가져오기
      } else {
        setOpen(true); // * 스낵바 오픈
        setSeverity("error"); // * 스낵바 타입 (에러)
        setText("보드 추가에 실패했습니다."); // * 스낵바 텍스트
      }
      setAddModalOpened(false); // * 모달 닫기
    });
  };

  // * 보드 리스트 가져오기
  const getBoardList = () => {
    // * 보드 리스트 API 호출 (유저 토큰, 콜백 함수)
    API.getBoardList(cookies.auth, (res) => {
      let { success, boards } = res.data; // * 응답 데이터 (성공 여부, 보드 리스트)

      // * 성공 시
      if (success) {
        // * 보드 리스트 변경
        setBoardList(() => {
          return boards.map((board) => {
            return {
              id: board._id, // * 보드 id
              name: board.name, // * 보드 이름
              type: "Live Board", // * 보드 타입
              created: board.created.slice(0, 10), // * 보드 생성 날짜
            };
          });
        });
      }
    });
  };

  // * 보드 삭제
  const deleteBoard = () => {
    // * 보드 삭제 API 호출 (체크된 리스트, 콜백 함수)
    API.deleteBoard(checkedList, (res) => {
      let { success } = res.data; // * 응답 데이터 (성공 여부)

      // * 성공 시
      if (success) {
        setOpen(true); // * 스낵바 오픈
        setSeverity("success"); // * 스낵바 타입 (성공)
        setText("보드가 삭제되었습니다."); // * 스낵바 텍스트

        getBoardList(); // * 보드 리스트 다시 가져오기
      } else {
        setOpen(true); // * 스낵바 오픈
        setSeverity("error"); // * 스낵바 타입 (에러)
        setText("보드 삭제에 실패했습니다."); // * 스낵바 텍스트
      }

      setDeleteModalOpened(false); // * 모달 닫기
    });
  };

  // * 메뉴 오픈/클로즈
  const handleMenu = (e) => {
    // * 메뉴가 닫혀있을 때
    if (anchorEl === null) {
      setAnchorEl(e.currentTarget); // * 메뉴 오픈
    } else {
      setAnchorEl(null); // * 메뉴 클로즈
    }
  };

  // * 보드 페이지로 이동
  const goToBoard = (e) => {
    let id = e.currentTarget.id; // * 보드 id

    window.open(`/board/manager/${id}`, "_blank"); // * 새 탭으로 보드 페이지 열기

    // navigate(`/board/manager/${id}`); // * 보드 페이지로 이동
  };

  // * 메뉴에서 삭제 클릭 시
  const handleDeleteModalByMenu = (e) => {
    let id = e.currentTarget.id; // * 보드 id

    setCheckedList([id]); // * 체크된 리스트 변경 (메뉴에서 삭제 클릭 시 해당 보드만 삭제)
    handleDeleteModal(); // * 삭제 모달 오픈
    handleMenu(); // * 메뉴 클로즈
  };

  // * 보드 복제
  const duplicateBoard = (e) => {
    handleMenu(); // * 메뉴 클로즈

    // * 보드 복제 API 호출 (유저 토큰, 보드 id, 콜백 함수)
    API.duplicateBoard(cookies.auth, e.currentTarget.id, (res) => {
      let { success } = res.data; // * 응답 데이터 (성공 여부)

      // * 성공 시
      if (success) {
        setOpen(true); // * 스낵바 오픈
        setSeverity("success"); // * 스낵바 타입 (성공)
        setText("보드가 복제되었습니다."); // * 스낵바 텍스트

        getBoardList(); // * 보드 리스트 다시 가져오기
      } else {
        setOpen(true); // * 스낵바 오픈
        setSeverity("error"); // * 스낵바 타입 (에러)
        setText("무료 모델 사용자는 2개까지 보드를 생성할 수 있습니다."); // * 스낵바 텍스트
      }
    });
  };

  // * 보드 리셋
  const resetBoard = (e) => {
    if (!window.confirm(" 보드를 리셋하시겠습니까? \n 리셋이 시작되면 데이터 복구는 불가능합니다."))
      return;

    API.resetBoard(e.currentTarget.id, (res) => {
      let { success } = res.data; // * 응답 데이터 (성공 여부)

      // * 성공 시
      if (success) {
        setOpen(true); // * 스낵바 오픈
        setSeverity("success"); // * 스낵바 타입 (성공)
        setText("보드가 리셋되었습니다."); // * 스낵바 텍스트

        getBoardList(); // * 보드 리스트 다시 가져오기
      } else {
        setOpen(true); // * 스낵바 오픈
        setSeverity("error"); // * 스낵바 타입 (에러)
        setText("보드 리셋에 실패했습니다."); // * 스낵바 텍스트
      }
    });
  };

  // * 페이지 로드 시 보드 리스트 가져오기
  useEffect(() => {
    getBoardList();
  }, []);

  return (
    <S.Body>
      <S.Main>
        {/* 타이틀 */}
        <Title children={{ name: "Live Board" }} />

        {/* 유저 정보 */}
        <UserInfo />

        {/* 보드 리스트 */}
        <S.MyListBox>
          <Box id="header">
            <Typography>My List</Typography>

            <Button
              sx={{
                bgcolor: checkedList.length > 0 ? "red" : "",
                "&:hover": {
                  bgcolor: checkedList.length > 0 ? "#e00" : "",
                },
              }}
              onClick={checkedList.length > 0 ? handleDeleteModal : handleAddModal}
            >
              {checkedList.length > 0 ? "삭제" : "추가"}
            </Button>
          </Box>

          <Box id="board-list">
            <DataGrid
              columns={columns}
              rows={boardList}
              rowsPerPageOptions={[100]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={handleCheckList}
              selectionModel={checkedList}
              disableColumnMenu
              sx={{
                font: "600 16px Pretendard",
                bgcolor: "#fff",
                borderRadius: "15px",
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "created", sort: "desc" }],
                },
              }}
            />
          </Box>
        </S.MyListBox>
      </S.Main>

      {/* 보드 추가 모달 */}
      {isAddModalOpened && (
        <BoardAddModal open={isAddModalOpened} onClose={handleAddModal} action={addBoard} />
      )}

      {/* 보드 삭제 모달 */}
      {isDeleteModalOpened && (
        <DeleteBoardModal
          open={isDeleteModalOpened}
          handleModal={handleDeleteModal}
          list={checkedList}
          action={deleteBoard}
        />
      )}
    </S.Body>
  );
};
