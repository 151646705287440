import React, { useContext, useEffect, useState } from "react";
import ReactWordcloud from "react-wordcloud";

import { Box, Checkbox, Grid, InputAdornment, Typography } from "@mui/material";

import { EditContext } from "../contexts/EditContext";
import * as S from "./styles";
import { Textfield } from "components/Textfield";

export const getWordCloudData = () => {
  return {
    type: "WordCloud",
    question: "",
    duplicateSubmission: false,
    submissionCount: 0,
    inputLimit: 10,
    completed: false,
  };
};

const words = [
  { text: "협업", value: 52 },
  { text: "소통", value: 20 },
  { text: "창의성", value: 18 },
  { text: "커뮤니케이션", value: 8 },
  { text: "협업능력", value: 6 },
  { text: "창의력", value: 5 },
  { text: "전략적 사고", value: 3 },
  { text: "정보수집", value: 3 },
  { text: "문제해결능력", value: 3 },
  { text: "소통력량", value: 3 },
  { text: "낮잠", value: 3 },
  { text: "비판적 사고", value: 2 },
  { text: "전략적사고", value: 1 },
  { text: "나에대한 믿음", value: 1 },
  { text: "트렌드 분석", value: 1 },
  { text: "문제해결력", value: 1 },
  { text: "새로운 생각", value: 1 },
  { text: "문제해결", value: 1 },
  { text: "협업 능력", value: 1 },
  { text: "커뮤니케이션 능력", value: 1 },
  { text: "의사소통능력", value: 1 },
  { text: "의사소통능력", value: 1 },
  { text: "균형", value: 1 },
];

export const WordCloudPreview = (props) => {
  const { currentSlide } = useContext(EditContext);

  return (
    <S.WordCloudPreview>
      <S.WordCloudPreviewTitle>
        {currentSlide.question === "" ? "질문을 입력해주세요." : currentSlide.question}
      </S.WordCloudPreviewTitle>

      <Box sx={{ flex: 1, position: "relative", p: "10px", boxSizing: "border-box" }}>
        <S.ResponseText>
          투표 수: 100
          <br />
          <br />
          {"(예시 화면입니다.)"}
        </S.ResponseText>

        <Box
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
        >
          <Box sx={{ width: "90%", height: "80%" }}>
            <ReactWordcloud
              words={words}
              options={{
                fontSizes: [20, 71],
                fontFamily: "SUIT-Heavy",
                rotations: 1,
                rotationAngles: [0],
                spiral: "archimedean",
                deterministic: true,
                enableOptimizations: false,
              }}
            />
          </Box>
        </Box>
      </Box>
    </S.WordCloudPreview>
  );
};

export const WordCloudEditor = (props) => {
  const { currentSlide, updateCurrentSlide } = useContext(EditContext);

  const handleQuestion = (e) => {
    if (e.target.value.length > 75) {
      updateCurrentSlide({ question: e.target.value.slice(0, 75) });
    } else {
      updateCurrentSlide({ question: e.target.value });
    }
  };

  const handleDuplicateSubmission = (e) => {
    updateCurrentSlide({ duplicateSubmission: e.target.checked });
  };

  const handleSubmissionCount = (e) => {
    if (e.target.value > 10) {
      e.target.value = 10;
    } else if (e.target.value < 0) {
      e.target.value = 0;
    }

    updateCurrentSlide({ submissionCount: e.target.value });
  };

  const handleInputLimit = (e) => {
    if (e.target.value > 25) {
      e.target.value = 25;
    } else if (e.target.value < 10) {
      e.target.value = 10;
    }

    updateCurrentSlide({ inputLimit: e.target.value });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography sx={{ fontWeight: "600", color: "#c33c3c" }}>{"(* 필수 입력)"}</Typography>

      <Box>
        <Typography sx={{ fontWeight: "600", mb: "10px" }}>
          질문 <span style={{ color: "#c33c3c" }}>*</span>
        </Typography>

        <Textfield
          size="small"
          placeholder="질문을 입력해주세요. (75자 이내)"
          fullWidth
          value={currentSlide.question}
          onChange={handleQuestion}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography sx={{ color: "#252b36" }}>
                  {75 - currentSlide.question.length}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ fontWeight: "600", mb: "10px" }}>중복 제출 가능 여부</Typography>

        <Checkbox checked={currentSlide.duplicateSubmission} onChange={handleDuplicateSubmission} />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          overflow: "hidden",
          maxHeight: currentSlide.duplicateSubmission ? "60px" : 0,
          transition: ".7s ease",
        }}
      >
        <Box>
          <Typography
            sx={{ color: "#191919", fontWeight: "600", fontSize: "clamp(14px, 0.83vw, 18px)" }}
          >
            최대 제출 가능 수 (0 ~ 10개)
          </Typography>
          <Typography
            sx={{ color: "#797979", fontWeight: "600", fontSize: "clamp(12px, 0.83vw, 16px)" }}
          >
            (0으로 설정 시 제출 무제한 가능)
          </Typography>
        </Box>

        <Textfield
          size="small"
          sx={{ width: "70px", height: "40px" }}
          type="Number"
          value={currentSlide.submissionCount}
          InputProps={{
            inputProps: { min: 0, max: 10 },
          }}
          onChange={handleSubmissionCount}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ fontWeight: "600", mb: "10px", fontSize: "clamp(12px, 0.83vw, 16px)" }}>
          입력 글자 수 제한 (10 ~ 25자 내외)
        </Typography>

        <Textfield
          size="small"
          sx={{ width: "70px", height: "40px" }}
          type="Number"
          value={currentSlide.inputLimit}
          InputProps={{
            inputProps: { min: 10, max: 25 },
          }}
          onChange={handleInputLimit}
        />
      </Box>
    </Box>
  );
};
