// * npm modules
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// * Material UI Components
import { Box, Typography } from "@mui/material";

// * Local Components
import logo from "assets/images/wliveLogo.png";

// * styles
import * as S from "./styles";
import { ManagerContext } from "../contexts/ManagerContext";

// * Outro Component
export default () => {
  const { slideId } = useParams(); // * 슬라이드 ID
  const { playingData, currentSlideIndex, isQrGridOpened, handleQRCode } =
    useContext(ManagerContext);
  const [opacity, setOpacity] = useState(0); // * 투명도

  // * 슬라이드 ID가 "Outro"일 때 투명도 1로 설정
  useEffect(() => {
    if (slideId === "Outro") {
      setOpacity(1); // * 투명도 1
    } else {
      setOpacity(0); // * 투명도 0
    }
  }, [slideId]);

  useEffect(() => {
    // * QR코드 그리드가 열려있을 때 인트로 페이지에서는 닫아준다.
    if (isQrGridOpened) {
      handleQRCode();
    }
  }, [playingData, currentSlideIndex]);

  return (
    <S.OutroBody>
      <S.OutroContent sx={{ opacity }}>
        <img src={logo} alt="logo" />

        <S.OutroText>
          슬라이드가 끝났습니다. <br />
          감사합니다.
        </S.OutroText>
      </S.OutroContent>
    </S.OutroBody>
  );
};
