import React, { useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { Tooltip, CircularProgress, Backdrop } from "@mui/material";

import googleIcon from "../../assets/images/googleIcon.png";
import GOOGLE from "config/google";
import { oAuthLogin } from "utils/api";

export const GoogleButton = () => {
  const loginWithGoogle = () => {
    let clientId = GOOGLE.CLIENT_ID;
    let redirectUri = GOOGLE.REDIRECT_URI;

    // 모바일인 경우
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=email profile&access_type=offline&prompt=consent`;
    } else {
      window.open(
        `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=email profile&access_type=offline&prompt=consent`,
        "_blank",
        "width=500, height=600"
      );
    }
  };

  return (
    <Tooltip title="구글 로그인" arrow>
      <div>
        <img
          src={googleIcon}
          onClick={loginWithGoogle}
          className="kakao-button"
          style={{ width: "45px", borderRadius: "50%", height: "45px" }}
        />
      </div>
    </Tooltip>
  );
};

export const GoogleOauth = () => {
  const [, setCookie] = useCookies();
  const navigate = useNavigate();
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
  let params = new URL(document.location.toString()).searchParams;
  let code = params.get("code"); // 인가코드 받는 부분

  useEffect(() => {
    oAuthLogin(
      "google",
      { code },
      (res) => {
        const { success, auth } = res.data;

        if (success) {
          let expires = new Date();
          expires.setTime(expires.getTime() + 1000 * 60 * 60 * 5);
          setCookie("auth", auth, { path: "/", expires: expires });
          setCookie("OAUTH", "google", { path: "/", expires: expires });

          !isMobile ? window.opener.parentCallback(`/dashboard`) : navigate(`/dashboard`);
        } else {
          !isMobile ? window.opener.parentCallback(`/login`) : navigate(`/login`);
        }
        if (!isMobile) window.close();
      },
      () => {
        !isMobile ? window.opener.parentCallback(`/login`) : navigate(`/login`);
      }
    );
  }, []);

  return (
    <Backdrop
      sx={{ bgcolor: "#ababab50", color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
