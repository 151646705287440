import React from "react";
import { Route, Routes } from "react-router-dom";

import Auth from "auth/auth";

import Main from "./views/main";
import PayPage from "./views/PayPage";
import PaymentPage from "./views/PaymentPage";
import PendingPage from "./views/PendingPage";
import SuccessPage from "./views/SuccessPage";
import FailPage from "./views/FailPage";

export default () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/pay" element={<Auth Component={PayPage} isAuthRequired />} />
      <Route path="/payment" element={<Auth Component={PaymentPage} isAuthRequired />} />
      <Route path="/payment/pending" element={<Auth Component={PendingPage} isAuthRequired />} />
      <Route path="/payment/success" element={<Auth Component={SuccessPage} isAuthRequired />} />
      <Route path="/payment/fail" element={<Auth Component={FailPage} isAuthRequired />} />
    </Routes>
  );
};
