import { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { socket } from "utils/socket";

const Context = createContext({});

const Provider = ({ children }) => {
  const navigate = useNavigate();
  const [cookies] = useCookies();

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  useEffect(() => {
    if (!cookies.auth) setLoggedIn(false);
    else setLoggedIn(true);

    cookies.auth && socket.emit("userInfo", { auth: cookies.auth });
  }, []);

  useEffect(() => {
    socket.on("userInfo", (data) => {
      setUser(data);
    });

    return () => {
      socket.off("userInfo");
    };
  });

  const value = {
    user,
    isLoggedIn,
    setLoggedIn,
    // handle: (e) => {
    //   console.log("handle");
    //   console.log(e.currentTarget.getAttribute("data-id"));
    // },
    goToLogin: () => {
      if (isMobile()) {
        alert("구독 상품 신청은 PC에서만 가능합니다.");
        return;
      }
      navigate("/login");
    },
    goToPayment: (e) => {
      if (isMobile()) {
        alert("구독 상품 신청은 PC에서만 가능합니다.");
        return;
      }
      if (!isLoggedIn)
        return navigate("/login", {
          state: {
            redirect: `/payment?type=personal`,
            state: { plan: e.currentTarget.getAttribute("data-plan") },
          },
        });
      navigate(`/payment?type=${user.status.toLowerCase()}`, {
        state: { plan: e.currentTarget.getAttribute("data-plan") },
      });
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Context, Provider };
