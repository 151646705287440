import React, { useEffect } from "react";
// import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { CircularProgress, Backdrop, Tooltip } from "@mui/material";

import { KAKAO_getAccessToken, KAKAO_getAccount, API_CheckUser } from "../../utils/api";
import kakaoIcon from "../../assets/images/kakaoIcon.png";
import KAKAO from "../../config/kakao";

export const KakaoButton = () => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  const KakaoLogin = () => {
    isMobile
      ? window.Kakao.Auth.authorize({
          redirectUri: KAKAO.REDIRECT_PAGE,
          prompt: "select_account",
        })
      : window.open("/oauth/popup/kakao", "_blank", "width=500, height=600");
  };

  return (
    <Tooltip title="카카오 로그인" arrow>
      <img
        src={kakaoIcon}
        alt="카카오 아이콘"
        onClick={KakaoLogin}
        className="kakao-button"
        style={{
          width: "45px",
          borderRadius: "50%",
          marginRight: "20px",
          height: "100%",
        }}
      />
    </Tooltip>
  );
};

export const KakaoPopup = () => {
  useEffect(() => {
    window.Kakao.init(KAKAO.JAVASCRIPT_KEY);

    window.Kakao.Auth.authorize({
      redirectUri: KAKAO.REDIRECT_PAGE,
      prompt: "select_account",
    });
  }, []);
  return <></>;
};

const KakaoOauth = () => {
  const [, setCookie] = useCookies();
  const navigate = useNavigate();
  const now = new Date();
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  useEffect(() => {
    let params = new URL(document.location.toString()).searchParams;
    let code = params.get("code"); // 인가코드 받는 부분

    KAKAO_getAccessToken(code)
      .then((ACCESS_TOKEN) => {
        // ? 쿠키 이름 난독화 시킬것
        let expire = new Date();
        expire.setTime(now.getTime() + 1000 * 60 * 60 * 5);
        setCookie("ACCESS_TOKEN", ACCESS_TOKEN, { path: "/", expires: expire });
        // ?

        KAKAO_getAccount(ACCESS_TOKEN).then((KAKAO_ACCOUNT) => {
          API_CheckUser(
            KAKAO_ACCOUNT.email,
            KAKAO_ACCOUNT.name,
            KAKAO_ACCOUNT.profile,
            ACCESS_TOKEN
          ).then((res) => {
            let { /*isExisted,*/ auth } = res;
            let expires = new Date();

            expires.setTime(now.getTime() + 1000 * 60 * 60 * 5);
            setCookie("auth", auth, { path: "/", expires: expires });
            setCookie("OAUTH", "kakao", { path: "/", expires: expires });

            isMobile ? navigate(`/dashboard`) : window.opener.parentCallback(`/dashboard`);
            !isMobile && window.close();
            // navigate(`/dashboard`);
          });
        });
      })
      .catch((e) => {
        console.log(e);

        isMobile ? navigate(`/login`) : window.opener.parentCallback(`/login`);
        !isMobile && window.close();
      });
    // eslint-disable-next-line
  }, []);

  return (
    <Backdrop
      sx={{ bgcolor: "#ababab50", color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default KakaoOauth;
