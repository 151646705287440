import React, { createContext, useEffect, useState } from "react";

import { Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const SnackBarContext = createContext({
  open: false,
  severity: "success",
  text: "",

  handleSnackBar: () => {},
  setOpen: () => {},
  setSeverity: () => {},
  setText: () => {},
  setOrigin: () => {},
});

const SnackBarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [text, setText] = useState("");
  const [origin, setOrigin] = useState({ vertical: "bottom", horizontal: "center" });

  const handleSnackBar = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setOrigin({ vertical: "bottom", horizontal: "center" });
      }, 1000);
    }
  }, [open]);

  const value = {
    open,
    severity,
    text,

    handleSnackBar,
    setOpen,
    setSeverity,
    setText,
    setOrigin,
  };

  return (
    <SnackBarContext.Provider value={value}>
      {children}

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleSnackBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert elevation={8} variant="filled" severity={severity} onClose={handleSnackBar}>
          <Typography sx={{ fontFamily: "Pretendard", fontWeight: 600 }}>{text}</Typography>
        </MuiAlert>
      </Snackbar>
    </SnackBarContext.Provider>
  );
};

export { SnackBarContext, SnackBarProvider };
