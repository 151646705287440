import * as S from "./styles";
import React from "react";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import wliveLogo from "assets/images/wliveLogo.png";
import Intro from "./intro";
import UserQuestion from "./userquestion";
import Result from "./result";
import NewResult from "./NewResult";
import UserInfo from "./userInfo";
import ResultMobile from "./ResultMobile/resultMobile";
import { UserProvider } from "../contexts/UserContext";

export default () => {
  return (
    // Layout틀 안에서 컴포넌트를 불러오는 시스템
    <UserProvider>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/user/:slideId" element={<Layout />} />
        <Route path="/:slideId" element={<Layout />} />
        <Route path="/question" element={<Layout />} />
        <Route path="/userInfo" element={<Layout />} />
        <Route path="/result/:results/:pageNumber" element={<Layout />} />
        <Route path="/result/:results" element={<NewResult />} />
        <Route path="/user-result/:userId" element={<NewResult />} />
      </Routes>
    </UserProvider>
  );
};

// Layout 컴포넌트
const Layout = () => {
  const { gameId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // 설문 시작으로 가는 함수
  const gotoQuestion = () => {
    navigate(`/hexagram/user/${gameId}/question`);
  };
  // 유저 정보 입력 창으로 가는 함수
  const gotoUserInfo = () => {
    navigate(`/hexagram/user/${gameId}/userInfo`);
  };
  // Layout 안에서 어디로 이동할지를 정하기 위해서 현재 위치를 저장하는 함수
  const isQuestionPage = location.pathname.endsWith("/question");
  const isResultPage = location.pathname.includes("/result");
  const isUserInfoPage = location.pathname.includes("/userInfo");

  return (
    <S.Body>
      <S.container>
        {/* 헤더 */}
        <S.Header>
          <S.Logo src={wliveLogo} alt="wlive logo" $filter />
        </S.Header>
        {/* Layout 안에 어떤 컴포넌트가 들어갈 지 정하는 부분 = body */}
        <Box>
          {isResultPage ? (
            <ResultMobile />
          ) : isQuestionPage ? (
            <UserQuestion />
          ) : isUserInfoPage ? (
            <UserInfo gotoQuestion={gotoQuestion} />
          ) : (
            <Intro gotoUserInfo={gotoUserInfo} />
          )}
        </Box>
        {/* 푸터 */}
        <S.Footer>
          {/* 저작권 표시*/}
          <div></div>
          <Typography sx={{ font: "400 14px Pretendard", ml: "10px" }}>
            Copyright ⓒ Withplus. All Right Reserved
          </Typography>
        </S.Footer>
      </S.container>
    </S.Body>
  );
};
