import React, { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { DndContext, useSensors, useSensor, PointerSensor, DragOverlay } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

import { Box, Button, Typography, Grid } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import * as S from "../styles";

import { ManagerContext } from "views/LiveBoard/contexts/Manager";
import { SortableItem } from "components/DragDrop/Sortable";
import { SortableContainer } from "components/DragDrop/Droppable";
import GroupHeader from "../components/GroupHeader";
import Card from "../components/Card";

export default () => {
  const { gameId } = useParams();

  const { list, layout, socket, setList, sections, handlePostModal } = useContext(ManagerContext);

  const [gridSize, setGridSize] = useState(2);
  const [cardSize, setCardSize] = useState("16%");

  const [postList, setPostList] = useState([]);
  const [draggingItem, setDraggingItem] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 },
    })
  );

  useEffect(() => {
    if (sections.length === 0 || !layout.type) return;

    if (layout.section) {
      let newPostList = [];

      for (let section of sections) {
        let sectionList = list.filter((item) => item.section === section.id);

        newPostList.push({ ...section, list: sectionList });
      }

      setPostList(newPostList);
    }
  }, [layout, sections, list]);

  const sortList = (e) => {
    const { active, over } = e;

    if (!over) return;

    const activeIndex = list.findIndex((item) => item.id === active.id);
    const overIndex = list.findIndex((item) => item.id === over.id);

    const result = arrayMove(list, activeIndex, overIndex);

    setList(result);
    setDraggingItem(null);

    socket.emit("sortPosts", { gameId, list: result });
  };

  const onDragStart = (e) => {
    const { id } = e.active;

    setDraggingItem(list.find((item) => item.id === id));
  };

  const onDragOver = (e) => {
    const { active, over } = e;

    if (!over) return;

    const { containerId: id } = active.data.current.sortable;
    const overId = over.data.current?.sortable.containerId || over.id;

    const activeGroup = postList.find((section) => section.id === id);
    const overGroup = postList.find((section) => section.id === overId);

    setDraggingItem({
      ...draggingItem,
      index: over.data.current?.sortable.index || 0,
    });

    if (!layout.section) return;
    if (!activeGroup || !overGroup || activeGroup === overGroup) return;

    setPostList((prev) => {
      const activeItems = activeGroup.list;
      const overItems = overGroup.list;

      const activeItem = activeItems.find((item) => item.id === active.id);

      return prev.map((group) => {
        if (group.id === id) {
          return { ...group, list: activeItems.filter((item) => item.id !== active.id) };
        }

        if (group.id === overId) {
          return { ...group, list: [...overItems, { ...activeItem, section: overId }] };
        }

        return group;
      });
    });
  };

  const onDragEnd = (e) => {
    const { active, over } = e;

    setDraggingItem(null);

    if (!over) return;

    const { id } = active;
    const { id: overId } = over;
    const { containerId } = active.data.current.sortable;
    const activeGroup = postList.find((section) => section.id === containerId);

    // if (id === overId) return;

    const activeIndex = activeGroup.list.findIndex((item) => item.id === id);
    const overIndex = activeGroup.list.findIndex((item) => item.id === overId);

    const result = arrayMove(activeGroup.list, activeIndex, overIndex);

    let newPostList = postList.map((group) =>
      group.id === containerId ? { ...group, list: result } : group
    );

    setPostList(newPostList);

    let newList = newPostList.reduce((acc, cur) => acc.concat(cur.list), []);

    setList(newList);

    socket.emit("sortPosts", { gameId, list: newList });
  };

  useEffect(() => {
    if (!layout.type) return;

    if (layout.postSize === "big") {
      setGridSize(3);
      setCardSize("25%");
    } else {
      setGridSize(2);
      setCardSize("16%");
    }
  }, [layout]);

  if (layout.section) {
    if (layout.sort === "likes") {
      return (
        <Box sx={{ position: "relative", height: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
            {postList.map((section) => (
              <Box key={section.id}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <Box sx={{ width: "30%" }}>
                    <GroupHeader section={section} />
                  </Box>

                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#888",
                      font: "600 16px Pretendard",
                      "&:hover": { bgcolor: "#777" },
                    }}
                    onClick={handlePostModal}
                    id={section.id}
                  >
                    <AddIcon />
                    게시물 등록하기
                  </Button>
                </Box>
                <Grid
                  container
                  sx={{
                    bgcolor: "#eee",
                    boxShadow: "inset 0 0 10px #00000033",
                    borderRadius: "8px",
                    width: "calc(100% - 10px)",
                    pt: "10px",
                    minHeight: "300px",
                  }}
                >
                  {section.list
                    .sort((a, b) => (b.likes?.length || 0) - (a.likes?.length || 0))
                    .map((item) => (
                      <Grid item xs={gridSize} key={item.id} sx={{ px: "10px", mb: "10px" }}>
                        <Card item={item} />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            ))}

            <Box sx={{ width: "30%", pb: "10px" }}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: "#aaaaaa88",
                  "&:hover": { bgcolor: "#88888888" },
                }}
                onClick={() => {
                  socket.emit("addGroup", { gameId });
                }}
              >
                <AddIcon sx={{ fontSize: "30px", color: "#000" }} />

                <Typography sx={{ font: "700 16px Pretendard", color: "#000" }}>
                  그룹 추가하기
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ height: "100%", position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            // height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "40px",
            overflow: "auto",
          }}
        >
          <DndContext
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            sensors={sensors}
          >
            {postList.map((section) => (
              <Box key={section.id}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <Box sx={{ width: "30%" }}>
                    <GroupHeader section={section} />
                  </Box>

                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#888",
                      font: "600 16px Pretendard",
                      "&:hover": { bgcolor: "#777" },
                    }}
                    onClick={handlePostModal}
                    id={section.id}
                  >
                    <AddIcon />
                    게시물 등록하기
                  </Button>
                </Box>

                <SortableContainer
                  id={section.id}
                  items={section.list}
                  sx={{
                    bgcolor: "#eee",
                    boxShadow: "inset 0 0 10px #00000033",
                    borderRadius: "8px",
                    width: "calc(100% - 10px)",
                    minHeight: "300px",
                    height: "100%",
                  }}
                >
                  {section.list.map((item, index) => (
                    <SortableItem
                      key={item.id}
                      id={item.id}
                      xs={gridSize}
                      style={{
                        p: "10px",
                        position: "relative",
                      }}
                      dragging-style={{
                        opacity: 0.3,
                      }}
                      is-dragging={draggingItem ? "true" : null}
                    >
                      <Card item={item} sort-mode={!draggingItem ? null : "true"} />
                    </SortableItem>
                  ))}
                </SortableContainer>
              </Box>
            ))}

            {draggingItem && (
              <DragOverlay>
                {
                  <Box sx={{ maxHeight: "300px", overflow: "hidden", position: "relative" }}>
                    <Card item={draggingItem} sort-mode="true" />

                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bgcolor: "#c33c3c",
                        color: "#fff",
                        p: "5px",
                        fontWeight: "bold",
                        borderTopRightRadius: "8px",
                        borderBottomLeftRadius: "8px",
                      }}
                    >
                      {draggingItem.index}
                    </Box>
                  </Box>
                }
              </DragOverlay>
            )}
          </DndContext>

          <Box sx={{ width: "30%", pb: "10px" }}>
            <Button
              variant="contained"
              fullWidth
              sx={{
                bgcolor: "#aaaaaa88",
                "&:hover": { bgcolor: "#88888888" },
              }}
              onClick={() => {
                socket.emit("addGroup", { gameId });
              }}
            >
              <AddIcon sx={{ fontSize: "30px", color: "#000" }} />

              <Typography sx={{ font: "700 16px Pretendard", color: "#000" }}>
                그룹 추가하기
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  if (layout.sort === "likes") {
    return (
      <Box sx={{ position: "relative", height: "100%" }}>
        <Grid container>
          {list
            .sort((a, b) => (b.likes?.length || 0) - (a.likes?.length || 0))
            .map((item) => (
              <Grid item xs={gridSize} key={item.id} sx={{ px: "10px", mb: "10px" }}>
                <Card item={item} />
              </Grid>
            ))}
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      <DndContext
        onDragEnd={sortList}
        sensors={sensors}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
      >
        <SortableContainer id="list" items={list.map((item) => item.id)}>
          {list.map((item) => (
            <SortableItem
              xs={gridSize}
              key={item.id}
              id={item.id}
              style={{
                p: "10px",
                position: "relative",
                minHeight: "300px",
                height: draggingItem ? "300px" : undefined,
                overflow: draggingItem ? "hidden" : undefined,
              }}
              dragging-style={{ opacity: 0.3 }}
              is-dragging={draggingItem ? "true" : null}
            >
              <Card item={item} sort-mode={draggingItem === null ? null : "true"} />
            </SortableItem>
          ))}
        </SortableContainer>

        {draggingItem && (
          <DragOverlay>
            <Box sx={{ maxHeight: "300px", overflow: "hidden", position: "relative" }}>
              <Card item={draggingItem} sort-mode="true" />

              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bgcolor: "#c33c3c",
                  color: "#fff",
                  p: "5px",
                  fontWeight: "bold",
                  borderTopRightRadius: "8px",
                  borderBottomLeftRadius: "8px",
                }}
              >
                {draggingItem.index}
              </Box>
            </Box>
          </DragOverlay>
        )}
      </DndContext>
    </Box>
  );
};
