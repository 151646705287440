import React, { useEffect, useState, useRef } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import { Header } from "@withplusdream/withplus-live";
import { useMediaQuery } from "react-responsive";

import { Avatar, Box, Container, Grid, IconButton, Tooltip, Typography } from "@mui/material";

import plus from "./GhostLegImg/buttonPlus.png";
import minus from "./GhostLegImg/buttonMinus.png";
import SimpleGameBack from "../../../assets/images/simpleGameBack.png";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useLocation } from "react-router-dom";
import classes from "./GhostLeg.module.css";
import clonedeep from "lodash/cloneDeep";
import { random } from "lodash";
import vehicle1 from "./GhostLegImg/vehicle1.png";
import vehicle2 from "./GhostLegImg/vehicle2.png";
import vehicle3 from "./GhostLegImg/vehicle3.png";
import vehicle4 from "./GhostLegImg/vehicle4.png";
import vehicle5 from "./GhostLegImg/vehicle5.png";
import vehicle6 from "./GhostLegImg/vehicle6.png";
import vehicle7 from "./GhostLegImg/vehicle7.png";
import vehicle8 from "./GhostLegImg/vehicle8.png";

// 기본 캔버스 적용사항
const DEFAUTL_USERS = 3;
const DEFAUTL_LINE_WIDTH = 7;
const DEFAUTL_LINE_COLOR = "#60A7FF";

// 깊은 복사를 위한 함수
const sort = (arr) => {
  const _arr = clonedeep(arr);
  _arr.map((arg) => {
    arg.sort((a, b) => a.y - b.y);
    return arg;
  });
  return _arr;
};

//SimpleGameLayout 레이아웃
export const SimpleGameGhostLeg = (props) => {
  const { children, isLobby, type } = props;
  const handle = useFullScreenHandle();

  const [isFullscreen, setFullscreen] = useState(false);
  const [isAccessCodeOpened, setAccessCodeOpened] = useState(true);
  const [isAccessCodeVisible, setAccessCodeVisible] = useState(true);

  const { state } = useLocation();
  //사다라타기 코드---------------------------------------------------------
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  //이미지 경로 추적 캔버스
  const pathCanvasRef = useRef(null);
  //이미지 캔버스
  const imgCanvasRef = useRef(null);

  const [ctx, setCtx] = useState();
  const [imgCtx, setImgCtx] = useState();

  //이미지 경로 추적 캔버스
  const [pathCtx, setPathCtx] = useState();
  const [users, setUsers] = useState(DEFAUTL_USERS);
  //모바일 및 pc 사다리 그림 배열
  const [usersWidth, setUsersWidth] = useState("670px");
  const [mobileUsersWidth, setMobileUsersWidth] = useState("255px");

  const [data, setData] = useState([]);
  const [bridgeData, setBridgeData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  //높이 조절
  const [num, setNum] = useState(5);
  const [num2, setNum2] = useState(-5);
  //애니메이션 중지
  const [animationId, setAnimationId] = useState(null);
  //이미지 선택
  // const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(null);
  const [userInputs, setUserInputs] = useState(Array.from({ length: users }, () => ""));

  const GhostLegImg = [
    vehicle1,
    vehicle2,
    vehicle3,
    vehicle4,
    vehicle5,
    vehicle6,
    vehicle7,
    vehicle8,
  ];
  //사다라타기 코드 끝---------------------------------------------------------
  const laptop = useMediaQuery({
    query: "(min-width:1400px)",
  });

  const desktop = useMediaQuery({
    query: "(max-width:1700px)",
  });

  const isPc = useMediaQuery({
    query: "(min-width:1080px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width:1079px)",
  });

  const handleFullscreen = () => {
    setFullscreen(!isFullscreen);

    if (isFullscreen) handle.exit();
    else handle.enter();
  };

  const handleAccessCodeContainer = () => {
    setAccessCodeOpened(!isAccessCodeOpened);
  };

  useEffect(() => {
    if (isAccessCodeOpened) {
      setAccessCodeVisible(true);
    } else {
      setTimeout(() => {
        setAccessCodeVisible(false);
      }, 1000);
    }
  }, [isAccessCodeOpened]);

  useEffect(() => {
    console.log(completedData);
  }, [completedData]);

  // useEffect(() => {
  //   if (isLobby) {
  //     setTimeout(() => {
  //       setAccessCodeOpened(true);
  //     }, 2000);
  //   } else {
  //     if (state?.isGameStarted) {
  //       setTimeout(() => {
  //         setAccessCodeOpened(false);
  //       }, 2000);
  //     } else {
  //       setAccessCodeOpened(false);
  //     }
  //   }
  // }, [isLobby]);

  useEffect(() => {
    console.log("render");
    if (completedData.length === 0) return;

    for (let data of completedData) {
      const imgCanvas = imgCanvasRef.current;
      const imgCtx = imgCanvas.getContext("2d");
    }

    for (let data of completedData) {
      const imgCanvas = imgCanvasRef.current;
      const imgCtx = imgCanvas.getContext("2d");
      const pathCanvas = pathCanvasRef.current;

      const image = new Image();
      let idx = data.i;
      let scaledWidth;
      let scaledHeight;

      image.src = GhostLegImg[idx];
      if (isMobile) {
        scaledWidth = 25; // 모바일일 때 조절된 너비
        scaledHeight = (scaledWidth / image.width) * image.height; // 비율에 따라 높이 조절
      } else {
        scaledWidth = 80; // 모바일이 아닐 때 이미지의 너비
        scaledHeight = 80; // 모바일이 아닐 때 이미지의 높이
      }

      // imgCtx.clearRect(0, 0, image.width, image.height);
      imgCtx.clearRect(
        data.x - scaledWidth / 2,
        data.y - scaledWidth / 2,
        scaledWidth + 10,
        scaledHeight + 10
      );

      imgCtx.drawImage(
        image,
        data.x - scaledWidth / 2,
        data.y - scaledWidth / 2 + 10,
        scaledWidth,
        scaledHeight
      );
    }
  });

  /*--------------사다리타기 코드-----------------*/

  useEffect(() => {
    init();
    const canvas = canvasRef.current;
    const pathCanvas = pathCanvasRef.current;
    const imgCanvas = imgCanvasRef.current;
    console.log(imgCanvas);

    if (!canvas || !pathCanvas || !imgCanvas) {
      return; // canvas 및 pathCanvas가 정의되지 않았을 경우 종료
    }

    if (isMobile) {
      canvas.width = 300;
      canvas.height = 150;
      pathCanvas.width = 300;
      pathCanvas.height = 180;
      imgCanvas.width = 300;
      imgCanvas.height = 180;
    } else {
      canvas.width = 900;
      canvas.height = 380;
      pathCanvas.width = 900;
      pathCanvas.height = 450;
      imgCanvas.width = 900;
      imgCanvas.height = 450;
    }

    const context = canvas.getContext("2d");
    const pathContext = pathCanvas.getContext("2d");
    const imgContext = imgCanvas.getContext("2d");

    context.lineJoin = "round";
    context.lineWidth = 3;
    contextRef.current = context;

    if (ctx) {
      drawBaseLine();
    }

    setCtx(context);
    setPathCtx(pathContext);
    setImgCtx(imgContext);
    // setSelectedVehicleIndex(users);
    if (users === 4) {
      setUsersWidth("750px");
    } else if (users === 2) {
      setUsersWidth("520px");
    } else if (users === 3) {
      setUsersWidth("670px");
    } else if (users === 5) {
      setUsersWidth("780px");
    } else if (users === 6) {
      setUsersWidth("820px");
    } else if (users === 7) {
      setUsersWidth("840px");
    } else if (users === 8) {
      setUsersWidth("860px");
    }

    if (users === 4) {
      setMobileUsersWidth("265px");
    } else if (users === 2) {
      setMobileUsersWidth("190px");
    } else if (users === 3) {
      setMobileUsersWidth("240px");
    } else if (users === 5) {
      setMobileUsersWidth("280px");
    } else if (users === 6) {
      setMobileUsersWidth("290px");
    } else if (users === 7) {
      setMobileUsersWidth("290px");
    } else if (users === 8) {
      setMobileUsersWidth("300px");
    }
  }, [users, ctx, isMobile]);

  const handleIncrease = () => {
    setUsers((pre) => pre + 1);
    if (users > 7) {
      setUsers(8);
    }
  };

  const craeteBaseLine = () => {
    const canvas = pathCanvasRef.current;
    const { width, height } = canvas;
    pathCtx.clearRect(0, 0, width, height);
    setUsers((pre) => pre);
    drawBaseLine();
  };

  const resetButton = () => {
    const canvas = pathCanvasRef.current;
    const { width, height } = canvas;
    pathCtx.clearRect(0, 0, width, height);
    setUserInputs(Array.from({ length: users }, () => ""));

    for (let data of completedData) {
      const imgCanvas = imgCanvasRef.current;
      const imgCtx = imgCanvas.getContext("2d");

      const image = new Image();
      let idx = data.i;
      let scaledWidth;
      let scaledHeight;

      image.src = GhostLegImg[idx];
      if (isMobile) {
        scaledWidth = 25; // 모바일일 때 조절된 너비
        scaledHeight = (scaledWidth / image.width) * image.height; // 비율에 따라 높이 조절
      } else {
        scaledWidth = 80; // 모바일이 아닐 때 이미지의 너비
        scaledHeight = 80; // 모바일이 아닐 때 이미지의 높이
      }

      // imgCtx.clearRect(0, 0, image.width, image.height);
      imgCtx.clearRect(
        data.x - scaledWidth / 2,
        data.y - scaledWidth / 2,
        scaledWidth + 10,
        scaledHeight + 10
      );
    }
    setCompletedData([]);

    // console.log(completedData);
    // if (completedData.length === 0) return;

    // for (let data of completedData) {
    //   const imgCanvas = imgCanvasRef.current;
    //   const imgCtx = imgCanvas.getContext("2d");

    //   const image = new Image();
    //   let idx = data.i;
    //   let scaledWidth;
    //   let scaledHeight;

    //   image.src = GhostLegImg[idx];
    //   if (isMobile) {
    //     scaledWidth = 25; // 모바일일 때 조절된 너비
    //     scaledHeight = (scaledWidth / image.width) * image.height; // 비율에 따라 높이 조절
    //   } else {
    //     scaledWidth = 80; // 모바일이 아닐 때 이미지의 너비
    //     scaledHeight = 80; // 모바일이 아닐 때 이미지의 높이
    //   }

    //   // imgCtx.clearRect(0, 0, image.width, image.height);
    //   imgCtx.clearRect(
    //     data.x - scaledWidth / 2,
    //     data.y - scaledWidth / 2,
    //     scaledWidth + 10,
    //     scaledHeight + 10
    //   );
    // }
  };

  const handleDecrease = () => {
    setUsers((pre) => pre - 1);
    if (users < 3) {
      setUsers(2);
    }
  };

  // 사다리 타기 input 박스 함수
  const handleInputChange = (index, value) => {
    const newInputs = [...userInputs];
    newInputs[index] = value;
    setUserInputs(newInputs);
  };

  // 캔버스 초기설정
  const init = () => {
    //canvasWidth, height 화면 줄일 시 null 값으로 할당되는 부분
    if (!ctx || !canvasRef.current) return;
    const canvas = canvasRef.current;
    const { width, height } = canvas;
    ctx.save();
    ctx.clearRect(0, 0, width, height);
    ctx.restore();
  };
  // 사다리타기 뼈대 작성
  const drawBaseLine = () => {
    init();
    setBridgeData([]);
    const canvas = canvasRef.current;
    const width = canvas.width;
    const height = canvas.height;
    const _arr = [];

    for (let i = 0; i < users; i++) {
      let startPosX = (i / users) * width + ((1 / users) * width) / 2;
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = DEFAUTL_LINE_WIDTH;
      ctx.strokeStyle = DEFAUTL_LINE_COLOR;
      ctx.moveTo(startPosX, 0);
      ctx.lineTo(startPosX, height);
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
      let arr = [
        { x: startPosX, y: 0 },
        { x: startPosX, y: height },
      ];
      _arr.push(arr);
    }
    setData(_arr);

    let numBridges = Math.floor(Math.random() * 3) + 2;
    // 캔버스의 높이 패턴이 pc버전이라 조율을 안해주면 모바일 화면에서 깨짐
    if (isMobile) {
      setNum((prevNum) => (prevNum === 0 ? -10 : 0));
      setNum2((prevNum2) => (prevNum2 === -10 ? 0 : -10));
      numBridges = Math.floor(Math.random() * 2) + 3; // 2 또는 3이 나오게 설정
    } else {
      setNum((prevNum) => (prevNum === 14 ? -27 : 14));
      setNum2((prevNum2) => (prevNum2 === -27 ? 14 : -27));
      numBridges = Math.floor(Math.random() * 3) + 2;
    }
    // 사용사 수에 따른 랜덤한 가로 다리 설정

    for (let i = 0; i < users - 1; i++) {
      if (i % 2 === 0) {
        drawRandomBridge2(i, i + 1, numBridges, num);
      } else {
        drawRandomBridge2(i, i + 1, numBridges, num2);
      }
      numBridges = isMobile ? Math.floor(Math.random() * 2) + 2 : Math.floor(Math.random() * 3) + 2;
    }
  };

  // 가로 사다리 경로 설정
  function drawBridge() {
    const canvas = canvasRef.current;
    const width = canvas.width;
    const height = canvas.height;
    if (!ctx) return;

    for (let i = 0; i < users; i++) {
      let startPosX = (i / users) * width + ((1 / users) * width) / 2;
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = DEFAUTL_LINE_WIDTH;
      ctx.strokeStyle = DEFAUTL_LINE_COLOR;
      ctx.moveTo(startPosX, 0);
      ctx.lineTo(startPosX, height);
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    }
    if (bridgeData.length < 1) return;
    bridgeData.forEach((item) => {
      let { startBridge, endBridge } = item;
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = DEFAUTL_LINE_WIDTH;
      ctx.strokeStyle = DEFAUTL_LINE_COLOR;
      ctx.moveTo(startBridge.x, startBridge.y);
      ctx.lineTo(endBridge.x, endBridge.y);
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    });
  }

  useEffect(() => {
    if (bridgeData.length < 1) return;
    init();
    drawBridge();

    const temp = clonedeep(data);

    bridgeData.forEach(({ startBridge, endBridge }) => {
      temp[startBridge.targetIndex].push(startBridge);
      temp[endBridge.targetIndex].push(endBridge);
    });

    setSortedData(sort(temp, "y"));
  }, [bridgeData]);

  //기존 requestAnimationFrame을 setTimeout로 뽑음. 느려는 지는데, 프레임이 끊힘
  const tracePathAnimated = (idx) => {
    console.log(idx);
    if (sortedData.length < 1) return;
    init();
    drawBridge();
    const path = [];
    let currentLine = idx;
    let nodeIdx = 0;
    const usedBridge = new Set();

    // 이미 실행 중인 애니메이션이 있다면 중지
    if (animationId) {
      clearTimeout(animationId);
    }

    let animationProgress = 0;
    const animationDuration = 10000; // 애니메이션 전체 기간
    const interval = 180; // 180ms마다 한 번씩 업데이트

    const animate = () => {
      // 경로 추적
      if (sortedData[currentLine].length === nodeIdx) {
        setCompletedData([...completedData, { ...path[path.length - 1], i: idx }]);
        return;
      }
      const node = sortedData[currentLine][nodeIdx];
      path.push({ i: currentLine, x: node.x, y: node.y });

      if (!node.linkId) {
        nodeIdx++;
      } else if (usedBridge.has(node.linkId)) {
        const start = path[path.length - 2].i;
        const end = currentLine;

        if (start !== end) nodeIdx++;
        else {
          currentLine = node.linkIndex;
          nodeIdx = sortedData[currentLine].findIndex((el) => node.linkId === el.linkId);
        }
      } else {
        currentLine = node.linkIndex;
        nodeIdx = sortedData[currentLine].findIndex((el) => node.linkId === el.linkId);
        usedBridge.add(node.linkId);
      }
      // 애니메이션 진행 상태 갱신
      animationProgress += interval / animationDuration;

      // 캔버스에 경로를 그리는 부분
      drawPathOnCanvas(path, idx);

      if (animationProgress < 1) {
        setAnimationId(setTimeout(animate, interval));
      }
    };
    // 애니메이션 시작
    animate();
  };

  // 실제 캔버스에서 그려지는 경로에 대한 함수
  const drawPathOnCanvas = (path, idx) => {
    // const canvas = canvasRef.current;
    // const ctx = canvas.getContext("2d");

    const pathCanvas = pathCanvasRef.current;
    const ctx = pathCanvas.getContext("2d");

    const imgCanvas = imgCanvasRef.current;
    const imgCtx = imgCanvas.getContext("2d");

    const image = new Image();

    image.src = GhostLegImg[idx];

    ctx.clearRect(0, 0, pathCanvas.width, pathCanvas.height);
    // imgCtx.clearRect(0, 0, image.width, image.height);

    // imgCtx.clearRect(0, 0, pathCanvas.width, pathCanvas.height);

    // 경로 및 이미지 지우기
    for (let i = 0; i < path.length - 1; i++) {
      // ctx.clearRect(0, 0, pathCanvas.width, pathCanvas.height);
      imgCtx.clearRect(
        path[i].x - image.width / 2,
        path[i].y - image.height / 2,
        image.width,
        image.height
      );
    }

    let scaledWidth, scaledHeight;

    if (isMobile) {
      scaledWidth = 25; // 모바일일 때 조절된 너비
      scaledHeight = (scaledWidth / image.width) * image.height; // 비율에 따라 높이 조절
    } else {
      scaledWidth = 80; // 모바일이 아닐 때 이미지의 너비
      scaledHeight = 80; // 모바일이 아닐 때 이미지의 높이
    }

    for (let i = 0; i < path.length - 1; i++) {
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = DEFAUTL_LINE_WIDTH;

      ctx.strokeStyle = "#ffff00";
      ctx.shadowColor = "rgba(99, 99, 99, 0.6)";
      ctx.shadowBlur = 5; // 그림자 흐림 정도
      ctx.shadowOffsetX = 2; // 그림자의 가로 이동 거리
      ctx.shadowOffsetY = 2; // 그림자의 세로 이동 거리
      ctx.lineCap = "round";
      ctx.lineJoin = "round";
      ctx.lineWidth = 7;

      ctx.moveTo(path[i].x, path[i].y);
      ctx.lineTo(path[i + 1].x, path[i + 1].y);
      ctx.stroke();
      // if (i === path.length - 2) {
      //   ctx.drawImage(
      //     image,
      //     path[i + 1].x - scaledWidth / 2,
      //     path[i + 1].y - scaledWidth / 2,
      //     scaledWidth,
      //     scaledHeight
      //   );
      // }
      ctx.closePath();
      ctx.restore();
    }

    for (let i = 0; i < path.length - 1; i++) {
      imgCtx.save();
      imgCtx.beginPath();
      imgCtx.lineWidth = DEFAUTL_LINE_WIDTH;

      imgCtx.strokeStyle = "red";
      imgCtx.shadowColor = "rgba(99, 99, 99, 0.6)";
      imgCtx.shadowBlur = 5; // 그림자 흐림 정도
      imgCtx.shadowOffsetX = 2; // 그림자의 가로 이동 거리
      imgCtx.shadowOffsetY = 2; // 그림자의 세로 이동 거리
      imgCtx.lineCap = "round";
      imgCtx.lineJoin = "round";
      imgCtx.lineWidth = 7;

      imgCtx.moveTo(path[i].x, path[i].y);
      imgCtx.lineTo(path[i + 1].x, path[i + 1].y);
      // imgCtx.stroke();
      if (i === path.length - 2) {
        imgCtx.drawImage(
          image,
          path[i + 1].x - scaledWidth / 2,
          path[i + 1].y - scaledWidth / 2,
          scaledWidth,
          scaledHeight
        );
      }
      imgCtx.closePath();
      imgCtx.restore();
    }
    console.log(path);
  };

  // 랜덤으로 생성되는 가로 사다리의 위치와 시작, 끝 지점의 index값 설정
  const drawRandomBridge2 = (startIndex, endIndex, numBridges, canvasHeight) => {
    if (data.length < 1) return;

    const canvas = canvasRef.current;
    const width = canvas.width;
    const height = canvas.height;
    const usedAreas = Array(7).fill(false); // 사용된 등분을 추적하기 위한 배열
    const usedIndexes = new Set(); // 현재까지 그려진 다리들의 인덱스 배열

    let startY;

    if (isMobile) {
      startY = 30 + canvasHeight;
    } else {
      startY = 50 + canvasHeight;
    }

    // const startY = 40 + canvasHeight;

    for (let i = 0; i < numBridges; i++) {
      // 사용된 기둥 인덱스를 추적
      usedIndexes.add(startIndex);
      usedIndexes.add(endIndex);

      const startX = (startIndex / users) * width + ((1 / users) * width) / 2;
      const endX = (endIndex / users) * width + ((1 / users) * width) / 2;

      let area; // 변수 추가: 선택된 등분
      let maxTries = 10; // 몇 번 시도할 것인지를 정의
      let tries = 0; // 시도 횟수 초기화

      // 사용되지 않은 등분을 선택할 때까지 반복 (최대 시도 횟수 10)
      do {
        // 랜덤하게 등분 선택
        area = Math.floor(Math.random() * 7);
        tries++;
      } while (usedAreas[area] && tries < maxTries);

      // 시도 횟수가 최대 시도 횟수보다 작은 경우에만 해당 등분을 사용됨으로 표시
      if (tries < maxTries) {
        usedAreas[area] = true;

        const Y1 = startY + (i / numBridges) * height;

        const linkId = new Date().getTime() * Math.random();

        const newBridge = {
          startBridge: {
            targetIndex: startIndex,
            x: startX,
            y: Y1,
            linkId,
            linkIndex: endIndex,
          },
          endBridge: {
            targetIndex: endIndex,
            x: endX,
            y: Y1,
            linkId,
            linkIndex: startIndex,
          },
        };
        setBridgeData((prev) => [...prev, newBridge]);
      }
    }
  };
  /*--------------사다리타기 코드 종료-----------------*/

  return (
    <>
      {isPc && (
        <Box
          sx={{
            bgcolor: "#f5f5f5",
            height: "calc(100vh - 80px)",
            pt: "80px",
          }}
        >
          <Box
            className="paper"
            sx={{
              height: "calc(100% - 20px)",
              mt: "10px",
              mx: "10px",
            }}
          >
            {/* <Title {...props} /> */}

            {/* <Box className="manager paper" children={children.content} /> */}

            <Box
              sx={{
                // height: "35vw",
                height: "calc(100% - 20px)",
                py: "20px",
                overflow: "hidden",
                display: "flex",
                position: "relative",
                width: "100%",
                background: `url(${SimpleGameBack})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              {/*QR코드 부분 시작*/}
              <Box
                sx={{
                  marginLeft: "1px",
                  borderRight: "1px solid #cdcdcd",
                  position: "relative",
                  transition: "1s",
                  minWidth: isAccessCodeOpened ? "75%" : "100%",
                  // background: `url(${SimpleGameBack})`,
                  backgroundSize: "95% 100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  // height: "700px",
                }}
              >
                {/*이 부분 수정 */}
                {/* {children.ContentBox} */}
                <div>
                  <div className={classes.contentBox}>
                    <div className={classes.inputBox}>
                      <div className={classes.boxStyle2} />
                      <h1 className={classes.h1Font}>사다리 타기</h1>
                    </div>
                    {/* {desktop ? (
                      ""
                    ) : (
                      <div className={classes.inputBox}>
                        <div className={classes.boxStyle2} />
                        <h1 className={classes.h1Font}>사다리 타기</h1>
                      </div>
                    )} */}

                    <div className={classes.containerBox}>
                      <div className={classes.gameBox}>
                        <div
                          style={{
                            width: 900,
                            // border: "2px solid blue",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              // marginLeft: ((1 / users) * 730) / 2,
                              // marginRight: ((1 / users) * 730) / 2,
                              margin: "0 auto",
                              width: `${usersWidth}`,
                              // border: "1px solid black",
                            }}
                          >
                            {Array.from({ length: users }, (_, i) => i + 1).map((u) => (
                              <img
                                key={new Date().getTime() * u}
                                style={{ width: 70, height: 70 }}
                                onClick={() => {
                                  tracePathAnimated(u - 1);
                                  // setSelectedVehicleIndex(u - 1);
                                }}
                                src={GhostLegImg[u - 1]}
                              />
                            ))}
                          </div>
                        </div>
                        <canvas
                          style={{ display: "block", zIndex: 1, position: "absolute" }}
                          ref={canvasRef}
                        ></canvas>
                        <canvas
                          style={{ display: "block", zIndex: 2, position: "absolute" }}
                          ref={pathCanvasRef}
                        ></canvas>
                        <canvas
                          style={{
                            display: "block",
                            zIndex: 3,
                            position: "absolute",
                            // border: "1px solid black",
                          }}
                          ref={imgCanvasRef}
                        ></canvas>
                        <div
                          style={{
                            width: 900,
                            // border: "1px solid blue",
                            height: "450px",
                            alignItems: "flex-end",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              // marginLeft: ((1 / users) * 700) / 2,
                              // marginRight: ((1 / users) * 700) / 2,
                              margin: "0 auto",
                              width: `${usersWidth}`,
                            }}
                          >
                            {Array.from({ length: users }, (_, i) => i + 1).map((u) => (
                              // <input
                              //   key={new Date().getTime() * u}
                              //   className={classes.resultInput}
                              //   style={{ width: 50, height: 30, textAlign: "center" }}
                              //   defaultValue={userInputs[u - 1]}
                              // />
                              <Typography
                                sx={{
                                  fontFamily: "Pretendard",
                                  fontSize: "25px",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  width: 50,
                                  height: 30,
                                }}
                              >
                                {u}.
                              </Typography>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Box
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: "50%",
                    transform: "translate(50%, -50%)",
                  }}
                >
                  <IconButton
                    sx={{
                      bgcolor: "#dddddd",
                      color: "white",
                    }}
                    onClick={handleAccessCodeContainer}
                  >
                    {isAccessCodeOpened ? (
                      <KeyboardArrowRightIcon />
                    ) : (
                      <KeyboardArrowLeftIcon
                        sx={{
                          margin: "2px 0px",
                          marginRight: "16px",
                        }}
                      />
                    )}
                  </IconButton>
                </Box>
              </Box>
              {/*QR코드 부분 끝*/}

              <Box
                sx={{
                  margin: "auto",
                  display: isAccessCodeVisible ? "flex" : "none",
                  justifyContent: "center",
                  flexDirection: "column",
                  transitionDelay: "1s",
                  minWidth: isAccessCodeOpened ? "25%" : "0%",
                }}
              >
                {/*이 부분 수정 */}
                {/*input value 넣는 곳 */}

                <div className={classes.resultBox}>
                  {/* {isButtonAble === true ? (
                      <h1></h1>
                    ) : (
                      <h2 style={{ height: "10px", lineHeight: "10px" }}>{result}</h2>
                    )} */}
                </div>
                <div className={classes.optionInputBox}>
                  <div className={classes.boxStyle} />
                  <h3 style={{ width: "300px", textAlign: "center", fontWeight: "bold" }}>
                    옵션 내용을 설정해주세요!
                  </h3>
                  {Array.from({ length: users }, (_, index) => (
                    <div key={index} style={{ textAlign: "center", padding: "3px" }}>
                      <label className={classes.labelFont}>{`${index + 1}`}: &nbsp; </label>
                      <input
                        className={classes.inputCss}
                        type="text"
                        placeholder={`옵션 ${index + 1}`}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                        value={userInputs[index]}
                      />
                    </div>
                  ))}
                </div>
                <div style={{ marginBottom: "70px" }}>
                  <p style={{ textAlign: "center", fontSize: "10px", color: "#B2B2B2" }}>
                    &#40;2~8까지 가능&#41;
                  </p>
                  <div className={classes.buttonBox}>
                    <img className={classes.button} src={minus} onClick={handleDecrease} />
                    <h2 className={classes.numberOfOptionsH2}>&nbsp;&nbsp;{users}&nbsp;&nbsp;</h2>
                    <img className={classes.button} src={plus} onClick={handleIncrease} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "180px",
                      margin: "0 auto",
                    }}
                  >
                    <button className={classes.startOrReset} onClick={craeteBaseLine}>
                      생성
                    </button>
                    <button className={classes.startOrReset} onClick={resetButton}>
                      초기화
                    </button>
                  </div>
                </div>
              </Box>
            </Box>

            <Box sx={{ position: "absolute", top: 20, right: 20 }}>
              <IconButton
                size="large"
                sx={{
                  bgcolor: "#c33c3c",
                  "&:hover": { bgcolor: "#a31c1c" },
                  boxShadow: "2px 2px 6px #797979",
                  color: "white",
                }}
                onClick={handleFullscreen}
              >
                {isFullscreen ? <CloseFullscreenIcon /> : <FullscreenIcon />}
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
      {isMobile && (
        <>
          <Box className={classes.mobileBody}>
            <Container
              maxWidth="xs"
              disableGutters
              sx={{
                bgcolor: "red",
                height: "calc(100% - 10px)",
                p: "20px",
                background: `url(${SimpleGameBack})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              <div className={classes.mobileContainerBox}>
                <div className={classes.mobileGameBox}>
                  <div className={classes.boxStyle} />
                  <h3
                    className={classes.h1Font}
                    style={{ textAlign: "center", marginBottom: "10px" }}
                  >
                    사다리 타기
                  </h3>

                  <div style={{ width: 300, height: 400 }}>
                    <div
                      style={{
                        width: 300,
                        // border: "2px solid gray",
                      }}
                    >
                      {/*자동차 그림 코드 */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          // marginLeft: ((1 / users) * 730) / 2,
                          // marginRight: ((1 / users) * 730) / 2,
                          margin: "0 auto",
                          width: `${mobileUsersWidth}`,

                          // width: "255px",
                          // border: "1px solid black",
                        }}
                      >
                        {Array.from({ length: users }, (_, i) => i + 1).map((u) => (
                          <div>
                            <img
                              key={new Date().getTime() * u}
                              style={{ width: 38, height: 40 }}
                              onClick={() => {
                                tracePathAnimated(u - 1);
                                // setSelectedVehicleIndex(u - 1);
                              }}
                              src={GhostLegImg[u - 1]}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ position: "relative", margin: "0 auto", height: "180px" }}>
                      <canvas
                        style={{
                          display: "block",
                          zIndex: 1,
                          position: "absolute",
                          // border: "1px solid yellow",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                        ref={canvasRef}
                      ></canvas>
                      <canvas
                        style={{
                          display: "block",
                          zIndex: 2,
                          position: "absolute",
                          // border: "1px solid blue",
                          left: "50%",
                          top: "57%",
                          transform: "translate(-50%, -50%)",
                        }}
                        ref={pathCanvasRef}
                      ></canvas>
                      <canvas
                        style={{
                          display: "block",
                          zIndex: 3,
                          position: "absolute",
                          // border: "1px solid black",
                        }}
                        ref={imgCanvasRef}
                      ></canvas>
                      <div
                        style={{
                          width: "300px",
                          // border: "1px solid blue",
                          height: "200px",
                          alignItems: "flex-end",
                          margin: "0 auto",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            // marginLeft: ((1 / users) * 700) / 2,
                            // marginRight: ((1 / users) * 700) / 2,
                            margin: "0 auto",
                            width: `${mobileUsersWidth}`,
                            // border: "1px solid blue",
                            // width: "300px",
                          }}
                        >
                          {Array.from({ length: users }, (v, i) => i + 1).map((u) => (
                            <Typography
                              key={u}
                              sx={{
                                fontFamily: "Pretendard",
                                fontSize: "18px",
                                fontWeight: "bold",
                                textAlign: "center",
                                width: 30,
                                height: 20,
                              }}
                            >
                              {u}.
                            </Typography>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.MobileOptionInputBox}>
                    {Array.from({ length: users }, (_, index) => (
                      <div
                        key={index}
                        style={{
                          textAlign: "center",
                          padding: "3px",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        <label className={classes.labelFont}>{`${index + 1}`}: &nbsp; </label>
                        <input
                          className={classes.MobileInputCss}
                          type="text"
                          placeholder={`옵션 ${index + 1}`}
                          onChange={(e) => handleInputChange(index, e.target.value)}
                          value={userInputs[index]}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div style={{ marginTop: "30px" }}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "10px",
                      color: "#B2B2B2",
                      marginBottom: "0",
                    }}
                  >
                    &#40;2~8까지 가능 &#41;
                  </p>
                  <div className={classes.mobileButtonBox}>
                    <img className={classes.mobileButton} src={minus} onClick={handleDecrease} />
                    <h2 className={classes.numberOfOptionsH2}>&nbsp;&nbsp;{users}&nbsp;&nbsp;</h2>
                    <img className={classes.mobileButton} src={plus} onClick={handleIncrease} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "150px",
                      margin: "0 auto",
                    }}
                  >
                    <button className={classes.mobileStartOrReset} onClick={craeteBaseLine}>
                      생성
                    </button>
                    <button className={classes.mobileStartOrReset} onClick={resetButton}>
                      초기화
                    </button>
                  </div>
                </div>
              </div>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};
