const styles = {
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  content_box: {
    backgroundColor: "white",
    borderRadius: "10px",
    px: "30px",
    py: "25px",
    outline: 0,
  },

  title: {
    font: "bold 24px NotoSansKR-Medium",
    mb: "8px",
  },

  explain: { fontFamily: "NotoSansKR-Medium" },

  button_box: {
    display: "flex",
    justifyContent: "flex-end",
    mt: "16px",
  },

  button: {
    mx: "5px",
  },

  cancel: {
    backgroundColor: "#909090",
    "&:hover": {
      backgroundColor: "#707070",
    },
  },
};

export default styles;
