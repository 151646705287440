import Axios from "axios";

const axios = Axios.create({
  baseURL: "https://tap.withplus.live",
});

// 0806 라이브 탭 생성하는 API
export const POST_ADD_TAP = (auth, title, gameMode, teamCount) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/add", { auth, title, gameMode, teamCount })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 0807 라이브 탭 리스트 불러오는 API
export const GET_LIST_TAP = (auth) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/list", { params: { auth } })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 0807 QRcode 생성해내는 API
export const GET_API_CODE = (tapId) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/code", { params: { tapId } })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 0808 슬롯 삭제하는 API
export const DELETE_TAP = (liveTaps, callback) => {
  axios.post("/delete", null, { params: { liveTaps: liveTaps } }).then((res) => {
    callback(res);
  });
};

// 0810 라이브 탭 수정 API
export const UPDATE_TAP = (auth, tapId, title, gameMode, teamCount) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/update/${tapId}`, { auth, title, gameMode, teamCount })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 0813 특정 LiveTap 데이터를 가져오는 API
export const GET_LIVETAP_BY_ID = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 0819 대기하고 있는 유저를 삭제하는 API
export const DELETE_USER = (uid, tapId) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/delete-user", { uid, tapId })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const POST_TAP = (tapId, uid) => {
  return new Promise((resolve, reject) => {
    axios
      .post("/tap", { tapId, uid })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 0820 게임 시작 시 isGameStart를 True로 바꾸는 API
export const START_GAME = (tapId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/start/${tapId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 게임을 리셋하는 API
export const RESET_GAME = (tapId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/reset/${tapId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
