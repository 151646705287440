// 초성(19개)
const CHO_HANGUL = [
  "ㄱ",
  "ㄲ",
  "ㄴ",
  "ㄷ",
  "ㄸ",
  "ㄹ",
  "ㅁ",
  "ㅂ",
  "ㅃ",
  "ㅅ",
  "ㅆ",
  "ㅇ",
  "ㅈ",
  "ㅉ",
  "ㅊ",
  "ㅋ",
  "ㅌ",
  "ㅍ",
  "ㅎ",
];
// 중성(21개)
const JUNG_HANGUL = [
  "ㅏ",
  "ㅐ",
  "ㅑ",
  "ㅒ",
  "ㅓ",
  "ㅔ",
  "ㅕ",
  "ㅖ",
  "ㅗ",
  "ㅘ",
  "ㅙ",
  "ㅚ",
  "ㅛ",
  "ㅜ",
  "ㅝ",
  "ㅞ",
  "ㅟ",
  "ㅠ",
  "ㅡ",
  "ㅢ",
  "ㅣ",
];
// 종성(28개)
const JONG_HANGUL = [
  "",
  "ㄱ",
  "ㄲ",
  "ㄳ",
  "ㄴ",
  "ㄵ",
  "ㄶ",
  "ㄷ",
  "ㄹ",
  "ㄺ",
  "ㄻ",
  "ㄼ",
  "ㄽ",
  "ㄾ",
  "ㄿ",
  "ㅀ",
  "ㅁ",
  "ㅂ",
  "ㅄ",
  "ㅅ",
  "ㅆ",
  "ㅇ",
  "ㅈ",
  "ㅊ",
  "ㅋ",
  "ㅌ",
  "ㅍ",
  "ㅎ",
];
const CHO_PERIOD = Math.floor("까".charCodeAt(0) - "가".charCodeAt(0)); // 588 ( 28 * 21 )
const JUNG_PERIOD = Math.floor("개".charCodeAt(0) - "가".charCodeAt(0)); // 28

const HANGUL_START_CHARCODE = "가".charCodeAt(0);
const HANGUL_END_CHARCODE = "힣".charCodeAt(0);

// 조합 된 글자인지 체크 (가 ~ 힣 사이)
export function isHangul(charCode) {
  return HANGUL_START_CHARCODE <= charCode && charCode <= HANGUL_END_CHARCODE;
}

export const isHangulLetter = (sentence) => {
  // Unicode ranges for Hangul syllables and Hangul Jamo (consonants)
  // Reference: https://unicode.org/charts/PDF/UAC00.pdf
  const koreanRangeStart = 0xac00;
  const koreanRangeEnd = 0xd7a3;
  const hangulJamoRangeStart = 0x1100;
  const hangulJamoRangeEnd = 0x11ff;

  let containsKorean = false;

  // Iterate over each character in the sentence
  for (let i = 0; i < sentence.length; i++) {
    const codePoint = sentence.charCodeAt(i);
    // Check if the code point falls within the Korean range
    if (codePoint >= koreanRangeStart && codePoint <= koreanRangeEnd) {
      containsKorean = true;
    }
    // Check if the code point falls within the Hangul Jamo range
    if (codePoint >= hangulJamoRangeStart && codePoint <= hangulJamoRangeEnd) {
      containsKorean = true;
    }
  }

  // If we found Korean characters or Hangul Jamo, return true
  if (containsKorean) {
    return true;
  }

  return false;
};

export function divideHangul(letter) {
  const letterCode = letter.charCodeAt(0);

  if (!isHangul(letterCode)) {
    return letter;
  }

  const charCode = letterCode - HANGUL_START_CHARCODE;

  const choIndex = Math.floor(charCode / CHO_PERIOD);
  // const jungIndex = Math.floor((charCode % CHO_PERIOD) / JUNG_PERIOD);
  // const jongIndex = charCode % JUNG_PERIOD;

  return CHO_HANGUL[choIndex];
}
