import React, { useContext, useEffect, useRef, useState } from "react";

import { Box, Button, Typography, IconButton } from "@mui/material";

import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

import Title from "components/Title";
import { ManagerContext } from "../contexts/ManagerContext";
import { AudioContext } from "contexts/Audio";

import winnerAssets from "assets/images/LiveTap/winnerManager";
import background from "assets/images/LiveTap/backgroundMobile.png";

const getTeamCharacter = (idx) => {
  //   return "elephant";
  //   return "hippo";
  //   return "cow";
  //   return "tiger";

  switch (idx) {
    case 0:
      return "hippo";
    case 1:
      return "cow";
    case 2:
      return "tiger";
    case 3:
      return "elephant";
  }
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default () => {
  const boxRef = useRef(null);
  const { socket, tap } = useContext(ManagerContext);
  const { bgmVolumn, setBgmVolumn, playSFX, stopBGM, handleBGM } = useContext(AudioContext);

  const [boxStyles, setBoxStyles] = useState({});
  const [winner, setWinner] = useState(null);

  const goToRanking = () => {
    handleBGM();
    playSFX("finalRank");
    sleep(3000).then(() => {
      handleBGM();
    });
    socket.emit("goToRankingPage", { gameId: tap._id });
  };

  const handleMuteSound = () => {
    if (bgmVolumn === 0) {
      setBgmVolumn(0.25);
    } else {
      setBgmVolumn(0);
    }
  };

  const handleBGMVolume = (e) => {
    setBgmVolumn((e.target.value * 1) / 200);
  };

  useEffect(() => {
    if (!tap._id) return;

    if (tap.gameMode === "team") {
      let newTap = tap;

      for (let i = 0; i < newTap.teamData.length; i++) {
        newTap.teamData[i].totalTapCount =
          newTap.teamData[i].teamMembers.reduce((acc, cur) => acc + cur.tapCount, 0) /
          (newTap.teamData[i].teamMembers.length * 100);
      }

      let winner = newTap.teamData.reduce((acc, cur) => {
        return acc.totalTapCount > cur.totalTapCount ? acc : cur;
      });
      let winnerIdx = newTap.teamData.findIndex((team) => team === winner);

      setWinner({ ...winner, character: getTeamCharacter(winnerIdx) });
    }
  }, [tap]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const boxWidth = entries[0].contentRect.width;
      const boxHeight = entries[0].contentRect.height;

      if (boxWidth / boxHeight > 16 / 9) {
        setBoxStyles({
          height: "100%",
        });
      } else {
        setBoxStyles({
          width: "100%",
        });
      }
    });

    if (boxRef.current) observer.observe(boxRef.current);

    return () => {
      boxRef.current && observer.unobserve(boxRef.current);
    };
  }, []);

  return (
    <Box
      sx={{
        pt: "80px",
        height: "100vh",
        boxSizing: "border-box",
        bgcolor: "#f5f5f5",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ px: "20px", position: "relative" }}>
        <Title children={{ name: "Live Tap" }} />
      </Box>

      <Box sx={{ flex: 1 }}>
        <Box
          ref={boxRef}
          sx={{
            bgcolor: "#fff",
            width: "calc(100% - 20px)",
            borderRadius: "10px",
            boxSizing: "border-box",
            height: "calc(100% - 10px)",
            boxShadow: "0 3px 6px #00000038",
            m: "0 auto",
            overflow: "hidden",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              right: "5px",
              top: "5px",
              display: "flex",
              borderRadius: "50px",
              border: "1px solid #eee",
              bgcolor: "#fff",
              px: "5px",
              boxShadow: "0px 3px 6px #00000020",
              zIndex: 100,
            }}
          >
            <input
              type="range"
              min="0"
              max="100"
              value={bgmVolumn * 200}
              onChange={handleBGMVolume}
              style={{
                accentColor: "#c26974",
              }}
            />
            <IconButton size="small" onClick={handleMuteSound}>
              {bgmVolumn === 0 ? (
                <VolumeOffIcon sx={{ fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)" }} />
              ) : (
                <VolumeUpIcon sx={{ fontSize: "clamp(16.3125px, 1.359375vw, 26.1px)" }} />
              )}
            </IconButton>
          </Box>

          <Box
            sx={{
              position: "relative",
              ...boxStyles,
              aspectRatio: "16 / 9",
              backgroundImage: `url(${winnerAssets[winner?.character]?.background})`,
              backgroundSize: "cover",
              boxShadow: "0px 3px 6px #00000038",
            }}
          >
            {/* 별 이미지 */}
            <Box
              sx={{
                position: "absolute",
                aspectRatio: winnerAssets[winner?.character]?.star.aspectRatio,
                width: `${(winnerAssets[winner?.character]?.star.width * 100) / 3840}%`,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <img src={winnerAssets[winner?.character]?.star.src} />
            </Box>

            {/* 축하 이미지 */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
                width: winnerAssets[winner?.character]?.congratulation.width || "100%",
              }}
            >
              <img src={winnerAssets[winner?.character]?.congratulation.src} />
            </Box>

            {/* 크레용 이미지 */}
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "30%",
              }}
            >
              <img src={winnerAssets[winner?.character]?.crayon} />

              <Box
                sx={{
                  //   bgcolor: "#ff000050",
                  position: "absolute",
                  width: winnerAssets[winner?.character]?.tape.width || "100%",
                  height: "100%",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <img src={winnerAssets[winner?.character]?.tape.src} />
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "90%",
                }}
              >
                <img src={winnerAssets[winner?.character]?.win} />
              </Box>
            </Box>

            <Box
              sx={{
                position: "absolute",
                bottom: "3%",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Typography
                sx={{
                  font: "900 60px Pretendard",
                  color: "#fff",
                  WebkitTextStroke: "3px rgb(15, 25, 174)",
                  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                }}
              >
                {winner?.teamName} 승리
              </Typography>
            </Box>

            <Box
              sx={{
                position: "absolute",
                bottom: "1%",
                right: "1%",
              }}
            >
              <Button
                onClick={goToRanking}
                sx={{
                  bgcolor: "rgb(15, 25, 174)",
                  color: "#fff",
                  font: "bold 28px Pretendard",
                  "&:hover": {
                    bgcolor: "rgb(15, 25, 174)",
                  },
                }}
              >
                {">"} 최종 순위 보기
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
