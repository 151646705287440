export const QuestionListMobile = [
  {
    id: 1,
    q: `나는 갈등 상황에서 스트레스를\n받으면 강하게 감정적 반응을\n할 때가 있다.`,
  },
  {
    id: 2,
    q: `나는 다른 사람이 하는 일이\n믿음직스럽지 못하다는 생각을\n하는 경우가 많다. 그래서 내가\n살펴볼 필요가 있다고 생각한다.`,
  },
  {
    id: 3,
    q: `사람들은 나를 보고 말이 없다고\n한다.`,
  },
  {
    id: 4,
    q: `나는 한번 일을 시작하면 그 일을 제대로 마치기 위해서 다른동료\n보다 더 늦게까지 남아서 일한다.`,
  },
  {
    id: 5,
    q: `나는 성공할 가능성이 적어 실익이 없다고 판단되면 그 즉시 마무리\n한다.`,
  },
  {
    id: 6,
    q: `나는 일을 할때 무엇보다도\n사람과의 관계를 매우 주요하게\n생각한다.`,
  },
  {
    id: 7,
    q: `나는 유쾌한 인간관계와 즐거움을 추구하는 경향이 있다.`,
  },
  {
    id: 8,
    q: `나는 옳다고 생각하는 것은 어떠한 상황에서도 분명하게 주장해야\n한다고 생각한다.`,
  },
  {
    id: 9,
    q: `주변에서 나를 보고 무슨 생각을\n하는지 알기 힘들다고 한다.`,
  },
  {
    id: 10,
    q: `나는 한 가지 일을 끝마치면 휴식 없이 곧바로 다음 일에 착수하곤\n한다.`,
  },
  {
    id: 11,
    q: `내 주위 사람들은 나를 아주\n재치있고 영리하다고 생각한다.`,
  },
  {
    id: 12,
    q: `나는 사교적이며, 다른 사람과\n어울리는 것을 몹시 좋아한다.`,
  },
  {
    id: 13,
    q: `다른 사람들은 나의 자유로운\n행동과 쾌활함을 종종 부러워 한다.`,
  },
  {
    id: 14,
    q: `나는 일을 할 때 무책임하게\n행동하는 모습을 보면 매우 화가\n난다.`,
  },
  {
    id: 15,
    q: `나는 다른 사람들과 함께 있을 때는 내 맘대로 하고 싶은 일이 있어도 참는 편이다.`,
  },
  {
    id: 16,
    q: `나는 동시에 여러 가지 많은 일을 떠맡기도 해서 일 중독이라는\n소리를 듣기도 한다.`,
  },
  {
    id: 17,
    q: `나는 큰 이익이 생길 수 있다면 큰 위험부담이 있는 일도 기꺼이 한다.`,
  },
  {
    id: 18,
    q: `나는 주변 사람들로부터 따듯하고\n동정심이 많다는 소리를 듣는다.`,
  },
  {
    id: 19,
    q: `나는 큰 이익이 발생하는 일이라도 감정적으로 싫으면 하지 않는\n편이다.`,
  },
  {
    id: 20,
    q: `내가 일할 때 가장 중요하게\n생각하는 것은 신중하게 생각하고 실행하는 것이다.`,
  },
  {
    id: 21,
    q: `나는 다른 사람들과 어울려 있을 때보다 혼자 있을 때가 더 편안하다.`,
  },
  {
    id: 22,
    q: `나는 일을 빠르게 하는 편이라 다른 사람도 신속하게 행동하기를\n바란다.`,
  },
  {
    id: 23,
    q: `나는 상황이 변한다면 어떤 협의\n사항도 바꿀 수 있다고 생각한다.`,
  },
  {
    id: 24,
    q: `나는 업무가 예상대로 흘러가지\n않으면 감정적으로 민감하게 과잉반응 할 때가 있다.`,
  },
  {
    id: 25,
    q: `나는 주변으로부터 열정적으로\n행동한다는 소리를 들을 때가 자주 있다.`,
  },
  {
    id: 26,
    q: `나는 다른 어떤 사람보다도 뛰어난\n사고능력을 가지고 있다고\n생각한다.`,
  },
  {
    id: 27,
    q: `나는 다른 사람들과 너무 오래\n있으면 혼자 있고 싶어진다.`,
  },
  {
    id: 28,
    q: `나는 다름 사람들과 같이\n있으면서도 해야 할 일들이 계속\n생각나서 그 순간에 온전히\n몰입하기가 어렵다.`,
  },
  {
    id: 29,
    q: `나는 자극적이고 흥분되는 일을\n할 때에 기운이 난다.`,
  },
  {
    id: 30,
    q: `나는 다른 사람들이 나에게 관심을\n기울일 때 인정받는다고 느낀다.`,
  },
  {
    id: 31,
    q: `나는 남과 다른 기발한 생각을\n하기를 좋아한다.`,
  },
  {
    id: 32,
    q: `나는 일할 때 직접 확인한 것만\n믿는 경향이 있다.`,
  },
  {
    id: 33,
    q: `나는 집단 속에 있을 때에는 말을 하지 않는 경향이 있다.`,
  },
  {
    id: 34,
    q: `나는 일을 할 때 목표와 일정을\n명확히 알지 못하면 스트레스를\n받는다.`,
  },
  {
    id: 35,
    q: `나는 곤란한 일을 재치 있게\n처리하는 재주가 있다.`,
  },
  {
    id: 36,
    q: `나는 내 주변 사람들을 기분 좋게 해야한다는 책임감을 느낀다.`,
  },
  {
    id: 37,
    q: `나는 재치 있고 활발하나 때로\n반항적이라는 소리도 듣는다.`,
  },
  {
    id: 38,
    q: `나는 계획된 일이 다른 사람에 의해\n변경되면 불편하다.`,
  },
  {
    id: 39,
    q: `나는 일할 때 화가 나거나 상처\n받으면 아무도 알아차리지 못하게 하려고 애쓴다.`,
  },
  {
    id: 40,
    q: `나는 다른 사람들로부터\n완벽주의자라는 말을 종종 듣는다.`,
  },
  {
    id: 41,
    q: `나는 내 일을 누구보다 잘 해내고 난처한 상황을 잘 해결한다.`,
  },
  {
    id: 42,
    q: `나는 일할 때 나보다는 상대방이\n좋아할 것 같은 것을 먼저 한다.`,
  },
  {
    id: 43,
    q: `나는 내가 요청하기 전에 상대방이 미리 알아서 내가 원하는 것을\n해주기를 바란다.`,
  },
  {
    id: 44,
    q: `나는 다른 사람들이 내 기대만큼\n일을 하지 못하면 화가 난다.`,
  },
  {
    id: 45,
    q: `나는 고독을 즐기며, 사람들의\n관심을 받으면 불편하다.`,
  },
  {
    id: 46,
    q: `나는 하고 있는 일이 마무리 될 때까지 노는 것을 뒤로 미룬다.`,
  },
  {
    id: 47,
    q: `나는 지루하거나 지체되는 것을\n참지 못하는 편이다.`,
  },
  {
    id: 48,
    q: `나는 남들이 나에게 관심을 줄 때 기분이 좋고 편안하다.`,
  },
  {
    id: 49,
    q: `나는 일이 잘 풀리지 않으면 그 일에서 흥미를 잃어버리는 경향이\n있다.`,
  },
  {
    id: 50,
    q: `나는 지는 것을 싫어하고 웬만하면\n내 의견을 굽히지 않는 편이다.`,
  },
  {
    id: 51,
    q: `나는 흥분을 자제하고 참는 편이다.`,
  },
  {
    id: 52,
    q: `나는 일을 할 때 먼저 업무계획을 수립하고 우선순위에 따라\n처리한다.`,
  },
  {
    id: 53,
    q: `나는 도전적인 활동을 즐기는\n경향이 있다.`,
  },
  {
    id: 54,
    q: `다른 사람들은 나에게 감성이\n풍부하고 공감을 잘 한다고 말한다.`,
  },
  {
    id: 55,
    q: `나는 다른 사람이 꺼리는 일을 남과 다른 방식으로 수행하는 것을\n즐긴다.`,
  },
  {
    id: 56,
    q: `나는 시작한 일은 끝까지 마무리\n하는 것이 중요하다고 생각한다.`,
  },
  {
    id: 57,
    q: `나는 업무 중에도 스트레스가\n심하면 관계를 회피하고 혼자\n있으려 한다.`,
  },
  {
    id: 58,
    q: `나는 다른 사람과 일을 할 때\n답답해서 내가 직접 처리하는\n경우가 많다.`,
  },
  {
    id: 59,
    q: `새로운 프로젝트나 일에 관심이\n많고 능동적으로 행동한다.`,
  },
  {
    id: 60,
    q: `나는 다른 사람들이 나로 인해\n즐거움을 느낄 때 행복한다.`,
  },
];
