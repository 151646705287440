import { Typography, Avatar, Button, Input } from "@mui/material";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import { RecentProgram } from "components/Program";
import { socket } from "utils/socket";

const Profile = ({ user, setUser }) => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const now = new Date();

  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [modifyData, setModifyData] = useState(false);
  const [recentProgramList, setRecentProgramList] = useState([]);
  const [plan, setPlan] = useState("");

  const updateUser = () => {
    setModifyData(!modifyData);
    if (window.confirm("수정하시겠습니까?")) {
      socket.emit("userUpdate", { auth: cookies.auth, userInfo: user });
    }
  };

  useEffect(() => {
    if (!user) return;

    setName(user.name);

    if (user.status === "Group") {
      setStatus("단체 계정 (관리자)");
    } else {
      setStatus("개인 계정");
    }

    switch (user.plan) {
      case "basic_monthly":
        if (user.group?.isAccepted) {
          setPlan("[단체] 베이식 구독 (월간 결제)");
        } else {
          setPlan("베이식 구독 (월간 결제)");
        }
        break;

      case "basic_yearly":
        if (user.group?.isAccepted) {
          setPlan("[단체] 베이식 구독 (연간 결제)");
        } else {
          setPlan("베이식 구독 (연간 결제)");
        }
        break;

      case "basic":
        if (user.planType === "period") {
          setPlan("베이직 (기간 선택)");
        } else {
          if (user.planType === "monthly") {
            setPlan("베이직 구독 (월간 결제)");
          } else {
            setPlan("베이직 구독 (연간 결제)");
          }
        }
        break;

      case "free":
        if (new Date(user.ExpiredAt) > now) setPlan("무료 평가판");
        else setPlan("Free");
        break;
      default:
        break;
    }
  }, [user]);

  useEffect(() => {
    socket.on("getRecent", (data) => {
      setRecentProgramList(data.res);
    });

    return () => {
      socket.off("getRecent");
    };
  });

  useEffect(() => {
    socket.emit("getRecent", { auth: cookies.auth });
  }, []);

  return (
    <div className="DashBoard">
      <div className="profile-box" style={{ height: "25%", justifyContent: "left" }}>
        <div className="profile" style={{ paddingLeft: "5%" }}>
          <Avatar sx={{ width: "20vmin", height: "20vmin", float: "left" }} />
          <div style={{ float: "left", padding: "50px", textAlign: "left" }}>
            <Typography
              sx={{ color: "#707070" }}
              fontSize="1.5rem"
              fontFamily="Noto Sans"
              fontWeight="700"
            >
              {name}
            </Typography>
            <Typography sx={{ color: "#878787", fontWeight: 500, fontFamily: "Pretendard" }}>
              {status}
            </Typography>
          </div>
          {/* <Button variant="contained">Edit</Button> */}
          {/* <Button
              variant="contained"
              sx={{ font: "16px NotoSansKR-Medium", ml: "5px" }}
              onClick={withdrawal}
            >
              회원탈퇴
            </Button> */}
        </div>
      </div>

      {/* 유저 프로필 아래부분 그리드 */}
      <div className="profile-grid">
        {/* 유저 information */}
        <div className="cell1">
          <div className="item">
            <div
              style={{
                height: "15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Typography sx={{ ml: "10%", font: "bold 18px Pretendard" }}>프로필 정보</Typography>

              {modifyData ? (
                <Button
                  variant="contained"
                  sx={{ font: "500 16px Pretendard", ml: "10px" }}
                  onClick={() => updateUser()}
                >
                  프로필 변경하기
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ font: "500 16px Pretendard", ml: "10px" }}
                  onClick={() => setModifyData(!modifyData)}
                >
                  수정하기
                </Button>
              )}
            </div>
            <div className="info-grid">
              {/* 유저이름 */}
              <div className="container">
                <div className="box">
                  <div
                    style={{
                      width: "80%",
                      height: "auto",
                      textAlign: "left",
                      margin: "auto",
                    }}
                  >
                    <Typography fontFamily="Noto Sans" color="#797979" fontSize="0.9rem">
                      name
                    </Typography>
                    {modifyData ? (
                      <Input
                        defaultValue={user.name}
                        size="small"
                        onChange={(e) => {
                          setUser((prevState) => ({
                            ...prevState,
                            name: e.target.value,
                          }));
                        }}
                      />
                    ) : (
                      <div>{user.name}</div>
                    )}
                  </div>
                </div>
              </div>
              {/* 유저 이메일 */}
              <div className="container">
                <div className="box">
                  <div
                    style={{
                      width: "80%",
                      height: "auto",
                      textAlign: "left",
                      margin: "auto",
                    }}
                  >
                    <Typography fontFamily="Noto Sans" color="#797979" fontSize="0.9rem">
                      email
                    </Typography>
                    {modifyData ? (
                      <Input
                        defaultValue={user.email}
                        size="small"
                        onChange={(e) => {
                          setUser((prevState) => ({
                            ...prevState,
                            email: e.target.value,
                          }));
                        }}
                      />
                    ) : (
                      <div>{user.email}</div>
                    )}
                  </div>
                </div>
              </div>
              {/* 유저 회사 */}
              <div className="container">
                <div className="box">
                  <div
                    style={{
                      width: "80%",
                      height: "auto",
                      textAlign: "left",
                      margin: "auto",
                    }}
                  >
                    <Typography fontFamily="Noto Sans" color="#797979" fontSize="0.9rem">
                      company
                    </Typography>
                    {modifyData ? (
                      <Input
                        defaultValue={user.company}
                        size="small"
                        onChange={(e) => {
                          setUser((prevState) => ({
                            ...prevState,
                            company: e.target.value,
                          }));
                        }}
                      />
                    ) : (
                      <div>{user.company}</div>
                    )}
                  </div>
                </div>
              </div>
              {/* 유저.. 머 넣을지 생각 */}
              <div className="container">
                <div className="box">
                  <div
                    style={{
                      width: "80%",
                      height: "auto",
                      textAlign: "left",
                      margin: "auto",
                    }}
                  >
                    <Typography fontFamily="Noto Sans" color="#797979" fontSize="0.9rem">
                      subscribe
                    </Typography>
                    <div>{plan}</div>
                    {/* {modifyData ? (
                      user.group && user.group?.isAccepted ? (
                        <div>{plan}</div>
                      ) : (
                        <Button variant="contained" onClick={() => navigate("/plan")}>
                          변경하기
                        </Button>
                      )
                    ) : (
                      <div>{plan}</div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="cell3">
          <div className="item">
            <div
              style={{
                height: "15%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ height: "15%" }}>Recent</Typography>
            </div>
            <div className="recent-container">
              {recentProgramList.map((program, index) => {
                return (
                  <RecentProgram
                    key={index}
                    name={program.name}
                    date={program.date}
                    users={program.users}
                  />
                );
              })}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Profile;
