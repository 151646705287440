import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { Header } from "@withplusdream/withplus-live";

import { MobileHeader } from "./Header";
import "./Layout.css";

function Layout(props) {
  const location = useLocation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // 반응형 웹
  const isPc = useMediaQuery({
    query: "(min-width:1000px)",
  });

  // 반응형 모바일
  const isMobile = useMediaQuery({
    query: "(max-width:1000px)",
  });

  if (
    location.pathname === "/code" ||
    location.pathname.includes("/app") ||
    location.pathname.includes("/oauth") ||
    location.pathname.includes("/logout") ||
    location.pathname.includes("/user") ||
    location.pathname.includes("/board/manager") ||
    location.pathname.includes("/quiz/edit") ||
    location.pathname.includes("password") ||
    location.pathname.includes("checkout") ||
    location.pathname.includes("diagnosis-plan/payment") ||
    location.pathname.includes("redirect")
  ) {
    return <main>{props.children}</main>;
  }

  return (
    <>
      {isPc && (
        <div className="Layout">
          <Header />
          <main className="main">{props.children}</main>
          {/* {!location.pathname.includes("/dashboard") && <Footer />} */}
        </div>
      )}
      {isMobile && (
        <div>
          <MobileHeader />
          <main>{props.children}</main>
          {/* <Footer /> */}
        </div>
      )}
    </>
  );
}

export default Layout;
