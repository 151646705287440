// * npm modules
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// * material UI components
import { Box, InputAdornment, Typography } from "@mui/material";

// * local components
import { UserContext } from "../contexts/UserContext";
import { Textfield } from "components/Textfield";
import { SnackBarContext } from "components/SnackBar/ContextAPI";

// * styles
import * as S from "./styles";

// * WordCloud component
export default () => {
  const { gameId, slideId } = useParams(); // * 게임 아이디, 슬라이드 아이디
  const { socket, playingData, currentSlideIndex, setPlayingData } = useContext(UserContext); // * 소켓, 플레이 데이터, 현재 슬라이드 인덱스
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext); // * SnackBar Context

  const [submittedCount, setSubmittedCount] = useState(0); // * 제출 횟수
  const [words, setWords] = useState(""); // * 답변
  const [loading, setLoading] = useState(false); // * 로딩

  // * 답변 핸들러
  const handleWords = (e) => {
    const inputLimit = playingData[currentSlideIndex]?.inputLimit; // * 입력 제한 길이

    // * 입력 제한 길이 초과 시 입력 제한
    if (e.target.value.length > inputLimit) {
      setWords(e.target.value.slice(0, inputLimit)); // * 입력 제한 길이만큼 자름
    } else {
      setWords(e.target.value); // * 입력 제한 길이 이내일 때 입력
    }
  };

  // * 답변 제출 핸들러
  const submitWords = (e) => {
    if (e) e.preventDefault(); // * 이벤트 기본 동작 막기

    if (!words) return; // * 답변이 없을 때

    // * 제출 가능한 횟수를 초과했을 때
    if (
      playingData[currentSlideIndex]?.submissionCount !== 0 &&
      submittedCount >= playingData[currentSlideIndex]?.submissionCount
    ) {
      setText("제출 가능한 횟수를 초과하였습니다."); // * 텍스트 설정
      setSeverity("error"); // * 에러로 설정
      setOpen(true); // * SnackBar 열기
      return;
    }

    // * 답변을 단어로 나누고 빈 단어를 제거
    const wordList = words
      .trim()
      .split(" ")
      .filter((word) => word !== "");

    if (!wordList.length) return; // * 답변이 없을 때 리턴

    const uuid = localStorage.getItem("uuid"); // * 유저 아이디

    setLoading(true); // * 로딩 시작

    // * 답변 제출 (게임 아이디, 슬라이드 아이디, 유저 아이디, 답변)
    socket.emit("submitWordCloud", { gameId, slideId, userId: uuid, wordList }, (res) => {
      // * 답변이 제출되었을 때
      if (res.success) {
        setWords(""); // * 답변 초기화
        setText("답변이 제출되었습니다."); // * 텍스트 설정
        setSeverity("success"); // * 성공으로 설정
        setOpen(true); // * SnackBar 열기

        setPlayingData(res.poll.playingData); // * 플레이 데이터 설정 (제출 횟수 업데이트를 위해)
      } else {
        setText(res.message); // * 텍스트 설정
        setSeverity("error"); // * 에러로 설정
        setOpen(true); // * SnackBar 열기
      }

      setLoading(false); // * 로딩 종료
    });
  };

  // * 현재 슬라이드 설정
  useEffect(() => {
    if (!playingData) return; // * 플레이 데이터가 없으면 리턴

    const userData = playingData[currentSlideIndex].userData; // * 현재 슬라이드의 유저 데이터
    if (!userData) return; // * 유저 데이터가 없으면 리턴
    const myData = userData.filter((data) => data.userId === localStorage.getItem("uuid")); // * 내가 제출한 데이터

    setSubmittedCount(myData.length); // * 제출 횟수 설정
  }, [playingData]);

  return (
    <S.WordCloud>
      {/* 타이포 */}
      <Typography sx={{ font: "700 20px Pretendard", mb: "20px" }}>워드 클라우드</Typography>

      {
        // * 제출 제한이 걸려있을 때
        playingData[currentSlideIndex].restricted && (
          <S.WordCloudRestrictedBox>
            <Typography sx={{ font: "600 18px Pretendard", mb: "10px" }}>
              투표가 종료되었습니다.
            </Typography>

            <Typography sx={{ font: "500 14px Pretendard", color: "#888", wordBreak: "keep-all" }}>
              진행자가 다음 슬라이드로 넘어가기 전까지 대기하거나, 진행자에게 투표 제한 해제를
              요청해주세요.
            </Typography>
          </S.WordCloudRestrictedBox>
        )
      }

      {/* 질문 박스*/}
      <Box sx={{ border: "1px solid #eee", borderRadius: "10px", p: "10px", mb: "20px" }}>
        <Typography sx={{ font: "600 18px Pretendard", mb: "10px" }}>질문.</Typography>

        {/* 질문 */}
        <Typography sx={{ font: "700 24px Pretendard" }}>
          {playingData[currentSlideIndex]?.question}
        </Typography>
      </Box>

      {/* 답변 박스*/}
      <Box onSubmit={submitWords} component="form">
        {/* 답변 input 박스 */}
        <Textfield
          fullWidth
          placeholder={`질문에 대한 답변을 입력해주세요. `}
          // * 답변 입력 제한 길이
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {playingData[currentSlideIndex]?.inputLimit - words.length}
              </InputAdornment>
            ),
          }}
          sx={{ mb: "20px" }} // * style
          value={words} // * 답변
          onChange={handleWords} // * 답변 핸들러
          disabled={playingData[currentSlideIndex].restricted} // * 제출 제한이 걸려있을 때
        />
      </Box>

      {/* 제출 가능한 횟수 */}
      <Typography sx={{ font: "600 18px Pretendard", mb: "10px", textAlign: "center", mb: "10px" }}>
        제출 가능한 횟수 :{" "}
        {playingData[currentSlideIndex]?.submissionCount === 0
          ? "무제한"
          : playingData[currentSlideIndex]?.submissionCount}
      </Typography>

      {/* 제출한 횟수 */}
      <Typography sx={{ font: "600 18px Pretendard", mb: "10px", textAlign: "center", mb: "20px" }}>
        제출한 횟수 : {submittedCount}
      </Typography>

      {/* 제출 버튼 박스*/}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <S.WordCloudSubmitButton
          fullWidth // * 전체 너비
          variant="contained" // * 컨테인드 버튼
          color="primary" // * 컬러
          loading={loading} // * 로딩
          onClick={submitWords} // * 제출 핸들러
          disabled={playingData[currentSlideIndex].restricted} // * 제출 제한이 걸려있을 때
        >
          제출하기
        </S.WordCloudSubmitButton>
      </Box>
    </S.WordCloud>
  );
};
