import React, { useContext, useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";

import { CircularProgress, Divider, Modal, Box, Button } from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

import * as S from "./style";
import Coupon from "../Coupon";
import { Context } from "views/PaymentPage/context";

import logo from "assets/images/wliveLogo.png";
import { useNavigate } from "react-router-dom";

export const CouponModal = () => {
  const { V, F } = useContext(Context);

  return (
    <Modal open={V.isCouponModalOpened} onClose={F.handleCouponModal}>
      <S.Paper>
        <S.Header>
          <div />
          <S.Title>쿠폰 등록</S.Title>
          <S.CloseButton onClick={F.handleCouponModal} />
        </S.Header>

        <S.CodeInputContainer>
          쿠폰 등록하기
          <div>
            <S.CodeInput
              value={V.couponCode}
              onChange={F.handleCouponCode}
              onKeyPress={(e) => {
                if (e.key === "Enter") F.checkCouponCode();
              }}
            />
            <S.EnrollButton onClick={F.checkCouponCode}>등록</S.EnrollButton>
          </div>
        </S.CodeInputContainer>

        <S.CouponList>
          보유중인 쿠폰
          <Divider />
          {V.coupons.map((coupon) => (
            <Coupon key={coupon._id} coupon={coupon} />
          ))}
        </S.CouponList>
      </S.Paper>
    </Modal>
  );
};

export const LoadingModal = (props) => {
  const { success, error, params } = props;

  return (
    <Modal open={true}>
      <S.Container>
        <S.LoadingBar />

        {success && <SuccessModal params={params} />}
        {error.isError && <FailModal error={error} />}
      </S.Container>
    </Modal>
  );
};

const SuccessModal = ({ params }) => {
  const navigate = useNavigate();
  console.log("hi");

  const [className, setClassName] = useState("");
  const [isExploding, setIsExploding] = useState(false);
  const [nextPaymentDay, setNextPaymentDay] = useState(null);

  const goToDashBoard = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    const expiredAt = new Date(params.expiredAt);

    // expiredAt.setDate(expiredAt.getDate() + 1);

    setTimeout(() => {
      setClassName("show");
    }, 1000);

    setTimeout(() => {
      setIsExploding(true);
    }, 1500);

    setNextPaymentDay(expiredAt);
  }, []);

  return (
    <S.ResultModal className={className}>
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        {isExploding && (
          <ConfettiExplosion width={1500} particleCount={200} duration={3000} force={0.8} />
        )}
      </div>

      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        <img src={logo} style={{ width: "180px" }} />
      </div>

      <div style={{ textAlign: "center" }}>
        <CheckCircleIcon sx={{ fontSize: 100, color: "green" }} />
        <h1>등록이 완료되었습니다.</h1>
      </div>

      <div style={{ textAlign: "center", wordBreak: "keep-all" }}>
        위드플러스 라이브를 이용해주셔서 감사합니다.
        <br />
        등록 정보 및 결제정보는 마이페이지에서 확인하실 수 있습니다.
        <br />
        <br />
      </div>

      <div style={{ backgroundColor: "#f5f5f5", borderRadius: "8px", padding: "10px" }}>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
          <span>구독 플랜 : </span>
          <span>{params.plan === "monthly" ? "베이식 (월간 결제)" : "베이식 (연간 결제)"}</span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
          <span>다음 결제 예정일 : </span>
          <span>
            {nextPaymentDay &&
              `${nextPaymentDay.getFullYear()}월 ${
                nextPaymentDay.getMonth() + 1
              }월 ${nextPaymentDay.getDate()}일`}
          </span>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>다음 결제 금액 : </span>
          <span>{(params.paymentInfo.finalPrice * 1).toLocaleString("ko-KR")} 원</span>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
        <Button variant="contained" sx={{ width: "50%" }} onClick={goToDashBoard}>
          마이페이지로 이동하기
        </Button>
      </div>
    </S.ResultModal>
  );
};

const FailModal = ({ error }) => {
  const navigate = useNavigate();
  console.log(error);

  const [className, setClassName] = useState("");

  const goToPlanPage = () => {
    navigate("/plan");
  };

  useEffect(() => {
    setTimeout(() => {
      setClassName("show");
    }, 1000);
  }, []);

  return (
    <S.ResultModal className={className}>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
        <img src={logo} style={{ width: "70%" }} />
      </div>

      <div style={{ textAlign: "center" }}>
        <ErrorIcon sx={{ fontSize: 80, color: "#ee0000" }} />
        <h1>등록에 실패하였습니다.</h1>
      </div>

      <div style={{ textAlign: "center", marginBottom: "10px" }}>
        <div>에러 내용 : {error?.message}</div>
        <div>다시 시도해주세요.</div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          sx={{ width: "50%", minWidth: "max-content" }}
          onClick={goToPlanPage}
        >
          플랜 페이지로 돌아가기
        </Button>
      </div>
    </S.ResultModal>
  );
};
