import React, { createContext, useState, useEffect } from "react";

import tada from "assets/audio/tada.mp3";
import beep from "assets/audio/beep.aac";
import shot from "assets/audio/shot.aac";
import bgm from "assets/audio/liveTap.mp3";
import final from "assets/audio/FinalRank.aac";

const AudioContext = createContext({
  soundVolume: 0.25,
  BGM: null,
  tadaSFX: null,
  shotSFX: null,
  beepSFX: null,
  finalSFX: null,
  prepareSounds: () => {},
  handleBGM: () => {},
  setSoundVolume: () => {},
});

const AudioProvider = ({ children }) => {
  const [BGM] = useState(new Audio(bgm));
  const [beepSFX] = useState(new Audio(beep));
  const [shotSFX] = useState(new Audio(shot));
  const [tadaSFX] = useState(new Audio(tada));
  const [finalSFX] = useState(new Audio(final));

  const [soundVolume, setSoundVolume] = useState(0.25);

  const prepareSounds = () => {
    beepSFX.play();
    shotSFX.play();
    tadaSFX.play();
    finalSFX.play();

    beepSFX.pause();
    shotSFX.pause();
    tadaSFX.pause();
    finalSFX.pause();
  };

  const handleBGM = () => {
    if (BGM.paused) {
      BGM.play();
      BGM.loop = true;
    } else {
      BGM.pause();
    }
  };

  useEffect(() => {
    BGM.volume = soundVolume;
    beepSFX.volume = soundVolume + 0.4;
    shotSFX.volume = soundVolume + 0.4;
    tadaSFX.volume = soundVolume + 0.25;
    finalSFX.volume = soundVolume + 0.25;
  }, [soundVolume]);

  const value = {
    soundVolume,
    BGM,
    tadaSFX,
    shotSFX,
    beepSFX,
    finalSFX,
    prepareSounds,
    handleBGM,
    setSoundVolume,
  };

  return <AudioContext.Provider value={value}>{children}</AudioContext.Provider>;
};

export { AudioContext, AudioProvider };
