import React, { useContext, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCheck } from "@fortawesome/free-solid-svg-icons";

import * as S from "./styles";
import Feature from "../Feature";
import { Context } from "views/PlanPage/context";
import { TermOfUseModal } from "components/Modal";
import checkIcon from "assets/images/PlanPage/Icon/checkCircleIcon.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Subscribes = (props) => {
  const F = useContext(Context);
  const [isModalOpened, setIsModalOpened] = useState(false);

  const handleModal = () => {
    setIsModalOpened((prev) => !prev);
  };

  // if (props.mobile) {
  //   return (
  //     <>
  //       {/* <S.Container>
  //         <S.GridItem xs={12}>
  //           <S.Header>
  //             <FontAwesomeIcon icon={faCircleCheck} color="#808080" style={{ fontSize: "28px" }} />

  //             <S.HeaderTitle>무료 상품</S.HeaderTitle>
  //           </S.Header>

  //           <S.PlanBox>
  //             <S.PlanName>무료</S.PlanName>
  //             <S.PriceBox>
  //               <S.Price>0원</S.Price>
  //             </S.PriceBox>
  //           </S.PlanBox>

  //           <S.ExplainBox>
  //             <S.ExplainContent>
  //               <S.ExplainContentBox>
  //                 <S.UserLimitBox>
  //                   <FontAwesomeIcon icon={faCheck} color="#808080" />

  //                   <S.ExplainTitle>동시 참여</S.ExplainTitle>
  //                 </S.UserLimitBox>

  //                 <S.ExplainContentText>10명</S.ExplainContentText>
  //               </S.ExplainContentBox>

  //               <S.ExplainContentBox>
  //                 <S.TeamLimitBox>
  //                   <FontAwesomeIcon icon={faCheck} color="#808080" />

  //                   <S.ExplainTitle>
  //                     팀 수 제한 <br />
  //                     <span>{"(보드형 게임)"}</span>
  //                   </S.ExplainTitle>
  //                 </S.TeamLimitBox>

  //                 <S.ExplainContentText>3팀</S.ExplainContentText>
  //               </S.ExplainContentBox>
  //             </S.ExplainContent>
  //           </S.ExplainBox>

  //           <S.ButtonBox>
  //             <S.ButtonBoxExplain>
  //               위드플러스 라이브에서 강의지원 도구를 <br />
  //               체험해보세요
  //             </S.ButtonBoxExplain>

  //             <S.PlanButton disabled>무료평가판 사용중</S.PlanButton>
  //           </S.ButtonBox>
  //         </S.GridItem>
  //       </S.Container> */}
  //       {/* <Box
  //         sx={{
  //           bgcolor: "#fff",
  //           borderRadius: "8px",
  //           boxShadow: "0px 2px 2px #ababab",
  //           p: "20px",
  //           width: "80%",
  //           my: "20px",
  //         }}
  //       >
  //         <S.CardTitle>무료 평가판</S.CardTitle>

  //         <S.Explain>
  //           <S.RedText>위드플러스 라이브</S.RedText>에 가입한 모든 고객에게 제공하는 무료 30일을
  //           제공하는 서비스 입니다.
  //         </S.Explain>

  //         <S.Price>0 원</S.Price>

  //         <S.Features>
  //           <Feature text="모든 서비스 이용가능" />
  //           <Feature text="이용가능한 서비스 목록" />
  //           <Feature text="Live Polling" $service />
  //           <Feature text="Live Quiz" $service />
  //           <Feature text="Live Deal" $service />
  //           <Feature text="Live Pick" $service />
  //           <Feature text="Egogram" $service />
  //           <Feature text="30일 경과 후 무료 평가판 서비스는 종료되고, Free 모델로 변경됩니다." />
  //         </S.Features>

  //         <S.Button data-id="free" onClick={F.goToLogin} disabled={F.isLoggedIn}>
  //           {F.isLoggedIn ? "무료 평가판 사용 완료" : "시작하기"}
  //         </S.Button>
  //       </Box>

  //       <Box
  //         sx={{
  //           bgcolor: "#fff",
  //           borderRadius: "8px",
  //           boxShadow: "0px 2px 2px #ababab",
  //           p: "20px",
  //           width: "80%",
  //           my: "20px",
  //         }}
  //       >
  //         <S.CardTitle>베이식 (월간 결제)</S.CardTitle>

  //         <S.Explain>
  //           <S.RedText>위드플러스 라이브</S.RedText>에서 제공하는 모든 강의 지원 도구를 이용할 수
  //           있습니다.
  //         </S.Explain>

  //         <S.Price>9,900 원 / 월</S.Price>
  //         <S.VAT>(VAT 포함)</S.VAT>

  //         <S.Features>
  //           <Feature text="모든 서비스 이용가능" />
  //           <Feature text="이용가능한 서비스 목록" />
  //           <Feature text="Live Polling" $service />
  //           <Feature text="Live Quiz" $service />
  //           <Feature text="Live Deal" $service />
  //           <Feature text="Live Pick" $service />
  //           <Feature text="Egogram" $service />
  //           <Feature hidden />
  //         </S.Features>

  //         <S.Button
  //           data-id="basic_month"
  //           onClick={F.goToPayment}
  //           disabled={F.user?.paymentInfo && F.user?.paymentInfo.plan === "monthly"}
  //         >
  //           {F.user?.paymentInfo && F.user?.paymentInfo.plan === "monthly"
  //             ? "현재 이용중인 모델"
  //             : "시작하기"}
  //         </S.Button>
  //       </Box>

  //       <Box
  //         sx={{
  //           bgcolor: "#fff",
  //           borderRadius: "8px",
  //           boxShadow: "0px 2px 2px #ababab",
  //           p: "20px",
  //           width: "80%",
  //           my: "20px",
  //         }}
  //       >
  //         <S.CardTitle>베이식 (연간 결제)</S.CardTitle>

  //         <S.Explain>
  //           월간 결제의 <S.RedText $emphasis>16% </S.RedText>할인된 가격으로
  //           <S.RedText> 위드플러스 라이브</S.RedText>를 이용해 보세요!
  //         </S.Explain>

  //         <S.Price>99,000 원 / 년</S.Price>
  //         <S.VAT>(VAT 포함)</S.VAT>

  //         <S.Features>
  //           <Feature text="모든 서비스 이용가능" />
  //           <Feature text="이용가능한 서비스 목록" />
  //           <Feature text="Live Polling" $service />
  //           <Feature text="Live Quiz" $service />
  //           <Feature text="Live Deal" $service />
  //           <Feature text="Live Pick" $service />
  //           <Feature text="Egogram" $service />
  //           <Feature text="월간 결제 12개월 대비 할인된 가격입니다." />
  //         </S.Features>

  //         <S.Button
  //           data-id="basic_year"
  //           onClick={F.goToPayment}
  //           disabled={F.user?.paymentInfo && F.user?.paymentInfo.plan === "yearly"}
  //         >
  //           {F.user?.paymentInfo && F.user?.paymentInfo.plan === "yearly"
  //             ? "현재 이용중인 모델"
  //             : "시작하기"}
  //         </S.Button>
  //       </Box>

  //       <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
  //         <Button sx={{ font: "16px NotoSansKR-Medium" }} onClick={handleModal}>
  //           {">"} 환불규정 안내
  //         </Button>
  //         <TermOfUseModal open={isModalOpened} handleModal={handleModal} refund={true} />
  //       </Box> */}
  //     </>
  //   );
  // }

  return (
    <>
      <S.Container rowGap={3}>
        <S.GridItem xs={props.mobile ? 12 : 3}>
          <S.Header>
            <FontAwesomeIcon icon={faCircleCheck} color="#808080" style={{ fontSize: "28px" }} />

            <S.HeaderTitle>무료 상품</S.HeaderTitle>
          </S.Header>

          <S.PlanBox>
            <S.PlanName>무료</S.PlanName>
            <S.PriceBox>
              <S.Price>0원</S.Price>
            </S.PriceBox>
          </S.PlanBox>

          <S.ExplainBox>
            <S.ExplainContent>
              <S.ExplainContentBox>
                <S.UserLimitBox>
                  <FontAwesomeIcon icon={faCheck} color="#808080" />

                  <S.ExplainTitle>동시 참여</S.ExplainTitle>
                </S.UserLimitBox>

                <S.ExplainContentText>10명</S.ExplainContentText>
              </S.ExplainContentBox>

              <S.ExplainContentBox>
                <S.TeamLimitBox>
                  <FontAwesomeIcon icon={faCheck} color="#808080" />

                  <S.ExplainTitle>
                    팀 수 제한 <br />
                    <span>{"(보드형 게임)"}</span>
                  </S.ExplainTitle>
                </S.TeamLimitBox>

                <S.ExplainContentText>3팀</S.ExplainContentText>
              </S.ExplainContentBox>
            </S.ExplainContent>
          </S.ExplainBox>

          <S.ButtonBox>
            <S.ButtonBoxExplain>
              위드플러스 라이브에서 강의지원 도구를 <br />
              체험해보세요
            </S.ButtonBoxExplain>

            <S.PlanButton data-plan="free" onClick={F.goToLogin} disabled={F.isLoggedIn}>
              {"로그인해서 시작하기"}
            </S.PlanButton>
          </S.ButtonBox>
        </S.GridItem>

        <S.GridItem xs={props.mobile ? 12 : 3}>
          <S.Header sx={{ bgcolor: "#c33c3c" }}>
            <FontAwesomeIcon icon={faCircleCheck} color="#fff" style={{ fontSize: "28px" }} />

            <S.HeaderTitle sx={{ color: "#fff" }}>추천 상품</S.HeaderTitle>
          </S.Header>

          <S.PlanBox>
            <S.PlanName>베이직 (기간 선택 결제)</S.PlanName>

            <S.PriceBox>
              <S.Price>9,900원 / 월</S.Price>

              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  borderTop: "1px solid #ddd",
                  pt: "5px",
                }}
              >
                <Typography sx={{ font: "500 16px Pretendard" }}>12개월 선택 시,</Typography>
                <S.BeforeDisCountPrice>118,800원</S.BeforeDisCountPrice>
                <Typography sx={{ font: "600 24px Pretendard", color: "#c33c3c" }}>
                  약 16% 할인!
                </Typography>
              </Box>

              <S.DiscountPrice>99,000원</S.DiscountPrice>
            </S.PriceBox>
          </S.PlanBox>

          <S.ExplainBox>
            <S.ExplainContent>
              <S.ExplainContentBox>
                <S.UserLimitBox>
                  <FontAwesomeIcon icon={faCheck} color="#808080" />

                  <S.ExplainTitle>동시 참여</S.ExplainTitle>
                </S.UserLimitBox>

                <S.ExplainContentText>200명</S.ExplainContentText>
              </S.ExplainContentBox>

              <S.ExplainContentBox>
                <S.TeamLimitBox>
                  <FontAwesomeIcon icon={faCheck} color="#808080" />

                  <S.ExplainTitle>
                    팀 수 제한 <br />
                    <span>{"(보드형 게임)"}</span>
                  </S.ExplainTitle>
                </S.TeamLimitBox>

                <S.ExplainContentText>최대</S.ExplainContentText>
              </S.ExplainContentBox>
            </S.ExplainContent>
          </S.ExplainBox>

          <S.ButtonBox>
            <S.ButtonBoxExplain>
              위드플러스 라이브에서 강의지원 도구를 <br />
              체험해보세요
            </S.ButtonBoxExplain>

            <S.PlanButton
              data-plan="period"
              onClick={F.goToPayment}
              disabled={F.user && F.user.plan.includes("basic")}
            >
              {F.user && F.user.plan.includes("basic") ? "현재 이용중인 모델" : "구매하기"}
            </S.PlanButton>
          </S.ButtonBox>
        </S.GridItem>

        <S.GridItem xs={props.mobile ? 12 : 3}>
          <S.Header sx={{ bgcolor: "#c33c3c" }}>
            <FontAwesomeIcon icon={faCircleCheck} color="#fff" style={{ fontSize: "28px" }} />

            <S.HeaderTitle sx={{ color: "#fff" }}>추천 상품</S.HeaderTitle>
          </S.Header>

          <S.PlanBox>
            <S.PlanName>베이직 (구독 정기 결제)</S.PlanName>
            <S.PriceBox>
              <S.Price>
                9,900원/월 <span>{"(월간 구독 시)"}</span>
              </S.Price>

              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  borderTop: "1px solid #ddd",
                  pt: "5px",
                }}
              >
                <Typography sx={{ font: "500 16px Pretendard" }}>연간 구독 선택 시,</Typography>
                <S.BeforeDisCountPrice>118,800원/년</S.BeforeDisCountPrice>
                <Typography sx={{ font: "600 24px Pretendard", color: "#c33c3c" }}>
                  약 16% 할인!
                </Typography>
              </Box>

              <S.DiscountPrice>99,000원/년</S.DiscountPrice>
            </S.PriceBox>
          </S.PlanBox>

          <S.ExplainBox>
            <S.ExplainContent>
              <S.ExplainContentBox>
                <S.UserLimitBox>
                  <FontAwesomeIcon icon={faCheck} color="#808080" />

                  <S.ExplainTitle>동시 참여</S.ExplainTitle>
                </S.UserLimitBox>

                <S.ExplainContentText>200명</S.ExplainContentText>
              </S.ExplainContentBox>

              <S.ExplainContentBox>
                <S.TeamLimitBox>
                  <FontAwesomeIcon icon={faCheck} color="#808080" />

                  <S.ExplainTitle>
                    팀 수 제한 <br />
                    <span>{"(보드형 게임)"}</span>
                  </S.ExplainTitle>
                </S.TeamLimitBox>

                <S.ExplainContentText>최대</S.ExplainContentText>
              </S.ExplainContentBox>
            </S.ExplainContent>
          </S.ExplainBox>

          <S.ButtonBox>
            <S.ButtonBoxExplain>
              위드플러스 라이브에서 강의지원 도구를 <br />
              무제한으로 이용하세요
            </S.ButtonBoxExplain>

            <S.PlanButton
              data-plan="subscription"
              onClick={F.goToPayment}
              disabled={F.user && F.user.plan.includes("basic")}
            >
              {F.user && F.user.plan.includes("basic") ? "현재 이용중인 모델" : "구매하기"}
            </S.PlanButton>
          </S.ButtonBox>
        </S.GridItem>
      </S.Container>

      {!props.mobile && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <Button sx={{ font: "600 17px Pretendard" }} onClick={handleModal}>
            {">"} 환불규정 안내
          </Button>
          <TermOfUseModal open={isModalOpened} handleModal={handleModal} refund={true} />
        </div>
      )}
    </>
  );
};

export default Subscribes;
