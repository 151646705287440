import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { LoadingModal } from "components/Modal";
import { SnackBarContext } from "components/SnackBar/ContextAPI";

const UserContext = createContext({
  socket: null,
  playingData: [],
  currentSlideIndex: 0,
  likes: false,
  isQnACreated: false,
  submittedCount: 0,

  setLikes: () => {},
  setSubmittedCount: () => {},
  setPlayingData: () => {},
});

const UserProvider = ({ children }) => {
  const { gameId } = useParams(); // * 게임 ID
  const navigate = useNavigate(); // * 라우터 네비게이션
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext); // * 스낵바 컨텍스트

  const [socket, setSocket] = useState(null); // * 소켓
  const [isSocketDisconnected, setSocketDisconnected] = useState(true); // * 소켓 연결 해제 여부

  const [playingData, setPlayingData] = useState([]); // * 플레이 중인 데이터
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // * 현재 슬라이드 인덱스
  const [likes, setLikes] = useState(false); // * 좋아요 여부

  // wordcloud
  const [submittedCount, setSubmittedCount] = useState(0); // * 제출된 투표 수

  // * 유저 UUID 생성
  useEffect(() => {
    const uuid = localStorage.getItem("uuid");

    // * UUID가 없으면 생성
    if (!uuid) {
      localStorage.setItem("uuid", uuidv4());
    }
  }, []);

  // * 소켓 연결 및 페이지 로드 시 폴링 데이터 요청
  useEffect(() => {
    // * 소켓이 없으면 생성
    if (!socket) {
      setSocket(io.connect("https://poll.withplus.live"));
      return;
    }

    // * 폴링 데이터 요청
    socket.emit("getPolling", { gameId }, (poll) => {
      const likes = poll.likes;

      setPlayingData(poll.playingData); // * 플레이 중인 데이터 설정
      setCurrentSlideIndex(poll.currentSlideIndex); // * 현재 슬라이드 인덱스 설정
      setLikes(likes.includes(localStorage.getItem("uuid"))); // * 좋아요 여부 설정
    });
  }, [socket]);

  useEffect(() => {
    if (!socket) return; // * 소켓이 없으면 리턴

    // * 소켓 연결 이벤트 및 소켓 룸 입장
    socket.on("connect", () => {
      console.log("connected");
      setSocketDisconnected(false);
      socket.emit("joinRoom", { gameId, user: true }, (res) => {
        if (!res.success) {
          alert("참여인원이 초과되었습니다. 진행자에게 문의해주세요.");
          window.location.href = "/";
        }
      });
    });

    // * 소켓 연결 해제 이벤트
    socket.on("disconnect", () => {
      console.log("disconnected");
      setSocketDisconnected(true); // * 소켓 연결 해제 여부 true
    });

    // * 폴링 수정 이벤트
    socket.on("leavePolling", () => {
      alert("폴링이 수정되었습니다. 페이지를 새로고침합니다.");
      window.location.reload(); // * 페이지 새로고침
    });

    // * 현재 슬라이드 인덱스 수신 이벤트
    socket.on("setCurrentSlideIndex", (data) => {
      setCurrentSlideIndex(data.currentSlideIndex); // * 현재 슬라이드 인덱스 설정
    });

    // * 폴링 제한 이벤트
    socket.on("restrictPolling", (data) => {
      if (data.aiMode) {
        setOpen(true);
        setSeverity("info");
        setText("AI 그룹핑 모드가 시작되어 폴링이 제한되었습니다.");
      }

      setPlayingData(data.poll.playingData); // * 플레이 중인 데이터 설정
    });

    // * 폴링 제한 해제 이벤트
    socket.on("unrestrictPolling", (data) => {
      if (data.aiMode) {
        setOpen(true);
        setSeverity("info");
        setText("AI 그룹핑 모드가 종료되어 폴링이 제한 해제되었습니다.");
      }

      setPlayingData(data.poll.playingData); // * 플레이 중인 데이터 설정
    });

    // * 폴링 리셋 이벤트
    socket.on("resetPoll", (data) => {
      // * 스낵바 오픈
      setOpen(true);
      setSeverity("info");
      setText("폴링이 리셋되었습니다.");

      setPlayingData(data.poll.playingData); // * 플레이 중인 데이터 설정
      setCurrentSlideIndex(data.poll.currentSlideIndex); // * 현재 슬라이드 인덱스 설정
      setLikes(data.poll.likes.includes(localStorage.getItem("uuid"))); // * 좋아요 여부 설정
    });

    // * 소켓 이벤트 해제
    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("setCurrentSlideIndex");
      socket.off("restrictPolling");
      socket.off("unrestrictPolling");
      socket.off("resetPoll");
      socket.off("leavePolling");
    };
  });

  // * 현재 슬라이드 인덱스 변경 시 라우터 네비게이션
  useEffect(() => {
    // * 플레이 중인 데이터가 없으면 리턴
    if (!playingData.length) return;

    const currentSlide = playingData[currentSlideIndex]; // * 현재 슬라이드

    navigate(`${currentSlide.id}`); // * 라우터 네비게이션
  }, [playingData, currentSlideIndex]);

  // * 컨텍스트 값
  const value = {
    socket,
    playingData,
    currentSlideIndex,
    likes,
    submittedCount,

    setLikes,
    setSubmittedCount,
    setPlayingData,
  };

  return (
    <UserContext.Provider value={value}>
      {children}
      <LoadingModal open={isSocketDisconnected} /> {/* * 소켓 연결 해제 시 로딩 모달 */}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
