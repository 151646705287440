import React, { useContext, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudIcon from "@mui/icons-material/Cloud";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PieChartIcon from "@mui/icons-material/PieChart";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";

import Title from "components/Title";
import { Textfield } from "components/Textfield";
import { EditContext, EditProvider } from "../contexts/EditContext";
import { SnackBarContext } from "components/SnackBar/ContextAPI";
import { LoadingModal } from "components/Modal";

import * as API from "utils/api/Poll";

import { getIntroData, IntroEditor, IntroPreview } from "./Intro";
import { getWordCloudData, WordCloudEditor, WordCloudPreview } from "./WordCloud";
import { getChoiceData, ChoiceEditor, ChoicePreview } from "./Choice";
import { getOpenEndedData, OpenEndedEditor, OpenEndedPreview } from "./OpenEnded";

import * as S from "./styles";

function deepClone(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  const result = Array.isArray(obj) ? [] : {};

  for (let key of Object.keys(obj)) {
    result[key] = deepClone(obj[key]);
  }

  return result;
}

export default (props) => {
  return (
    <EditProvider>
      <S.Body>
        <S.Content>
          <Title children={{ name: "Live Polling" }} />

          <S.EditContainer>
            <TitleArea />

            <SlideArea />

            <PreviewArea />

            <EditorArea />
          </S.EditContainer>
        </S.Content>

        <SaveButton />
      </S.Body>
    </EditProvider>
  );
};

const TitleArea = () => {
  const { title, setTitle, titleWarning, setTitleWarning } = useContext(EditContext);

  const handleTitle = (e) => {
    if (titleWarning) setTitleWarning(false);

    setTitle(e.target.value);
  };

  return (
    <S.TitleArea>
      <Textfield
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              children={
                <span>
                  <span style={{ color: "#c33c3c" }}>*</span> 제목 :{" "}
                </span>
              }
            />
          ),
          style: { border: titleWarning ? "2px solid red" : null },
        }}
        size="small"
        fullWidth
        placeholder="폴링 제목을 입력해주세요."
        value={title}
        onChange={handleTitle}
      />
    </S.TitleArea>
  );
};

const SlideArea = () => {
  const { user, slideList, setSlideList, currentSlide, setCurrentSlide } = useContext(EditContext);
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);

  const onDragEnd = (res) => {
    const sourceIndex = res.source.index;
    const destinationIndex = res.destination.index;

    let source = slideList.filter((_, index) => index === sourceIndex);
    let remains = slideList.filter((_, index) => index !== sourceIndex);

    let newSlideList = [
      ...remains.slice(0, destinationIndex),
      ...source,
      ...remains.slice(destinationIndex),
    ];

    setSlideList(newSlideList);
  };

  const addSlide = () => {
    if (user.plan === "free" && slideList.length >= 3) {
      setText("무료 사용자는 최대 3개의 슬라이드까지만 추가할 수 있습니다.");
      setSeverity("error");
      setOpen(true);

      return;
    }

    let newSlide = { id: new Date().getTime() + "", type: "default" };

    setCurrentSlide(newSlide);
    setSlideList([...slideList, newSlide]);
  };

  const duplicateSlide = (e) => {
    e.stopPropagation();
    if (user.plan === "free" && slideList.length >= 3) {
      setText("무료 사용자는 최대 3개의 슬라이드까지만 추가할 수 있습니다.");
      setSeverity("error");
      setOpen(true);

      return;
    }

    let newSlideList = [];

    slideList.map((slide) => {
      newSlideList.push(slide);

      if (slide.id === e.currentTarget.id) {
        const clone = { ...deepClone(slide), id: new Date().getTime() + "" };

        newSlideList.push(clone);
        setCurrentSlide(clone);
      }
    });

    setSlideList(newSlideList);
  };

  const deleteSlide = (e) => {
    e.stopPropagation();

    if (slideList.length === 1) {
      setText("최소 1개 이상의 슬라이드가 있어야 합니다.");
      setSeverity("warning");
      setOpen(true);
      return;
    }

    if (currentSlide.id === e.currentTarget.id) {
      const newSlideList = slideList.filter((item) => item.id !== e.currentTarget.id);
      let index = slideList.findIndex((item) => item.id === e.currentTarget.id);

      if (index >= newSlideList.length) {
        index = newSlideList.length - 1;
      }

      setSlideList(newSlideList);
      setCurrentSlide(newSlideList[index]);
    } else {
      setSlideList(slideList.filter((item) => item.id !== e.currentTarget.id));
    }
  };

  const handleCurrentSlide = (e) => {
    let newCurrentSlide = slideList.find((item) => item.id === e.currentTarget.id);

    setCurrentSlide(newCurrentSlide);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <S.SlideGrid>
        <Typography sx={{ font: "600 18px Pretendard", textAlign: "center" }}>
          총 {slideList.length}개의 슬라이드
        </Typography>

        <Droppable droppableId="slideList">
          {(provided) => (
            <S.SlideArea>
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {slideList.map((slide, index) => {
                  return (
                    <Draggable key={slide.id} draggableId={slide.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <S.SlideItem>
                            <Typography sx={{ font: "600 18px Pretendard" }}>
                              {index + 1}
                            </Typography>

                            <S.SlidePaper
                              id={slide.id}
                              current-slide-id={currentSlide.id}
                              onClick={handleCurrentSlide}
                            >
                              <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <Icon type={slide.type} slide={slide} />
                                <Typography
                                  sx={{
                                    font: "600 18px Pretendard",
                                    fontSize: "clamp(14px, 0.9375vw, 18px)",
                                  }}
                                >
                                  {(() => {
                                    switch (slide.type) {
                                      case "Intro":
                                        return "인트로";
                                      case "WordCloud":
                                        return "워드클라우드";
                                      case "Choice":
                                        return "선택형";
                                      case "OpenEnded":
                                        return "설문형";
                                      default:
                                        return "타입 선택";
                                    }
                                  })()}
                                </Typography>
                              </Box>

                              <S.SlideTitle>{slide.question}</S.SlideTitle>
                              <S.SlideTitle>{slide.title}</S.SlideTitle>

                              <S.SlideButtonArea>
                                <Tooltip title="복제" arrow>
                                  <Box>
                                    <S.SlideIconButton id={slide.id} onClick={duplicateSlide}>
                                      <FileCopyIcon />
                                    </S.SlideIconButton>
                                  </Box>
                                </Tooltip>

                                <Tooltip title="삭제" arrow>
                                  <Box>
                                    <S.SlideIconButton id={slide.id} onClick={deleteSlide}>
                                      <DeleteIcon />
                                    </S.SlideIconButton>
                                  </Box>
                                </Tooltip>
                              </S.SlideButtonArea>

                              <S.IsCompletedArea>
                                {slide.completed ? (
                                  <CheckCircleIcon sx={{ color: "green" }} />
                                ) : (
                                  <ErrorIcon sx={{ color: "red" }} />
                                )}
                              </S.IsCompletedArea>
                            </S.SlidePaper>
                          </S.SlideItem>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            </S.SlideArea>
          )}
        </Droppable>

        <S.AddSlideButton onClick={addSlide}>슬라이드 추가</S.AddSlideButton>
      </S.SlideGrid>
    </DragDropContext>
  );
};

const PreviewArea = () => {
  const { currentSlide } = useContext(EditContext);

  return (
    <S.PreviewArea>
      <S.PreviewTitle>미리보기 (예시 화면)</S.PreviewTitle>

      <S.PreviewOverlay>
        <S.Preview>
          {(() => {
            switch (currentSlide.type) {
              case "Intro":
                return <IntroPreview />;
              case "WordCloud":
                return <WordCloudPreview />;
              case "Choice":
                return <ChoicePreview />;
              case "OpenEnded":
                return <OpenEndedPreview />;
              default:
                return (
                  <S.DefaultPreview>
                    <Typography sx={{ font: "700 40px Pretendard" }}>
                      슬라이드 타입을 선택해주세요.
                    </Typography>
                  </S.DefaultPreview>
                );
            }
          })()}
        </S.Preview>
      </S.PreviewOverlay>
    </S.PreviewArea>
  );
};

const EditorArea = () => {
  const { currentSlide } = useContext(EditContext);

  return (
    <S.EditorArea>
      <SlideType />

      {(() => {
        switch (currentSlide.type) {
          case "Intro":
            return <IntroEditor />;
          case "WordCloud":
            return <WordCloudEditor />;
          case "Choice":
            return <ChoiceEditor />;
          case "OpenEnded":
            return <OpenEndedEditor />;
          default:
            return <></>;
        }
      })()}
    </S.EditorArea>
  );
};

const SaveButton = () => {
  const { gameId } = useParams();
  const navigate = useNavigate();

  const { slideList, title, setTitleWarning } = useContext(EditContext);
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);

  const [loading, setLoading] = useState(false);

  const savePoll = () => {
    const completed = slideList.every((slide) => slide.completed);

    if (completed) {
      if (title === "") {
        setText("폴링 제목을 입력해주세요.");
        setSeverity("warning");
        setOpen(true);
        setTitleWarning(true);
        return;
      }

      setLoading(true);

      API.editPoll(gameId, title, slideList, (res) => {
        const { success } = res.data;

        setTimeout(() => {
          if (success) {
            setText("저장되었습니다.");
            setSeverity("success");
            setOpen(true);
            navigate("/polling/list");
          } else {
            setText("저장에 실패했습니다.");
            setSeverity("error");
            setOpen(true);
            window.location.reload();
          }
        }, 800);
      });
    } else {
      setText(
        "모든 슬라이드의 필수 입력 항목을 입력해주세요. 각 슬라이드의 완료 여부는 슬라이드 우측 상단에 표시되어 있습니다."
      );
      setSeverity("warning");
      setOpen(true);
    }
  };

  return (
    <>
      <S.SaveButton onClick={savePoll}>
        <SaveIcon fontSize="large" />
        <Typography sx={{ font: "700 20px Pretendard" }}>저장하기</Typography>
      </S.SaveButton>

      <LoadingModal open={loading} />
    </>
  );
};

const Icon = ({ type, slide }) => {
  const iconStyle = {
    fontSize: "clamp(20px, 1.8vw, 35px) !important",
  };

  switch (type) {
    case "Intro":
      return <QrCode2Icon sx={iconStyle} />;
    case "WordCloud":
      return <CloudIcon sx={iconStyle} />;
    case "Choice":
      switch (slide.resultLayout) {
        case "bar":
          return <EqualizerIcon sx={iconStyle} />;
        case "circle":
          return <PieChartIcon sx={iconStyle} />;
        case "doughnut":
          return <DonutLargeIcon sx={iconStyle} />;
        default:
          return <></>;
      }
    case "OpenEnded":
      return <ChatBubbleIcon sx={iconStyle} />;
    default:
      return <></>;
  }
};

const SlideType = () => {
  const { currentSlide, changeSlideType } = useContext(EditContext);

  const boxStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  };
  const iconStyle = {
    fontSize: "clamp(20px, 1.8vw, 35px) !important",
  };

  const handleType = (e) => {
    switch (e.target.value) {
      case "Intro":
        changeSlideType(getIntroData());
        break;
      case "WordCloud":
        changeSlideType(getWordCloudData());
        break;
      case "Choice":
        changeSlideType(getChoiceData());
        break;
      case "OpenEnded":
        changeSlideType(getOpenEndedData());
        break;
      default:
        changeSlideType({ type: "default", completed: false });
        break;
    }
  };

  return (
    <S.SlideTypeBox>
      <Typography sx={{ font: "600 18px Pretendard", mb: "10px" }}>슬라이드 타입</Typography>

      <Select
        fullWidth
        value={currentSlide.type}
        onChange={handleType}
        size="small"
        sx={{
          bgcolor: "#f5f5f5",
          " .MuiTypography-root": {
            font: "600 18px Pretendard",
          },
        }}
      >
        <S.SelectItem value="default" disabled>
          슬라이드 타입을 선택해주세요.
        </S.SelectItem>

        <S.SelectItem value="Intro">
          <Box sx={boxStyle}>
            <QrCode2Icon sx={iconStyle} />

            <Typography>인트로</Typography>
          </Box>
        </S.SelectItem>

        <S.SelectItem value="WordCloud">
          <Box sx={boxStyle}>
            <CloudIcon sx={iconStyle} />

            <Typography>워드클라우드</Typography>
          </Box>
        </S.SelectItem>

        <S.SelectItem value="Choice">
          <Box sx={boxStyle}>
            <EqualizerIcon sx={iconStyle} />

            <Typography>선택형</Typography>
          </Box>
        </S.SelectItem>

        <S.SelectItem value="OpenEnded">
          <Box sx={boxStyle}>
            <ChatBubbleIcon sx={iconStyle} />

            <Typography>설문형</Typography>
          </Box>
        </S.SelectItem>
      </Select>
    </S.SlideTypeBox>
  );
};
