import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

import { Tooltip } from "@mui/material";

import naverIcon from "../../assets/images/btnG_아이콘원형.png";
import NAVER from "../../config/naver";
import { oAuthLogin } from "utils/api";

export const NaverButton = () => {
  const { naver } = window;
  // const navigate = useNavigate();
  const naverRef = useRef();
  // console.log(naver);
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  const NaverLogin = () => {
    let naverLogin = new naver.LoginWithNaverId({
      clientId: NAVER.CLIENT_ID,
      callbackUrl: NAVER.CALLBACK_URL,
      isPopup: !isMobile,
      loginButton: { color: "green", type: 1, height: 40, width: 40 },
    });

    naverLogin.init();
  };

  const loginWithNaver = () => {
    naverRef.current.children[0].click();
  };

  useEffect(() => {
    NaverLogin();
  }, []);

  return (
    <div style={{ marginRight: "20px" }}>
      <div className="grid-naver" id="naverIdLogin" style={{ display: "none" }} ref={naverRef} />
      <Tooltip title="네이버 로그인" arrow>
        <div onClick={loginWithNaver} style={{ cursor: "pointer" }}>
          <img
            src={naverIcon}
            style={{ width: "45px", borderRadius: "50%", height: "100%" }}
            className="kakao-button"
          />
        </div>
      </Tooltip>
    </div>
  );
};

const NaverOauth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = location.hash.split("=")[1].split("&")[0];
  const [, setCookie] = useCookies();
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  const getNaverUserInfo = () => {
    oAuthLogin(
      "naver",
      { token },
      (res) => {
        let { auth } = res.data;
        let expires = new Date();

        expires.setTime(expires.getTime() + 1000 * 60 * 60 * 5);
        setCookie("auth", auth, { path: "/", expires: expires });
        setCookie("OAUTH", "naver", { path: "/", expires: expires });
        !isMobile ? window.opener.parentCallback(`/dashboard`) : navigate(`/dashboard`);
        !isMobile && window.close();
      },
      () => {
        !isMobile ? window.opener.parentCallback(`/login`) : navigate(`/login`);
        !isMobile && window.close();
      }
    );
  };

  useEffect(() => {
    getNaverUserInfo();
  }, []);
  return <div></div>;
};

export default NaverOauth;
