import hyundaiLogo from "assets/images/cardLogo/hyundai.svg";
import kbLogo from "assets/images/cardLogo/kb.svg";
import shinhanLogo from "assets/images/cardLogo/shinhan.svg";
import samsungLogo from "assets/images/cardLogo/samsung.svg";
import lotteLogo from "assets/images/cardLogo/lotte.svg";
import hanaLogo from "assets/images/cardLogo/hana.svg";
import nonghyupLogo from "assets/images/cardLogo/nonghyup.svg";
import bcLogo from "assets/images/cardLogo/bc.svg";
import wooriLogo from "assets/images/cardLogo/woori.svg";

export default {
  현대: { logo: hyundaiLogo, color: "#000000" },
  국민: { logo: kbLogo, color: "rgb(255, 188, 0)" },
  신한: { logo: shinhanLogo, color: "#0046ff" },
  삼성: { logo: samsungLogo, color: "#1428a0" },
  롯데: { logo: lotteLogo, color: "#e60012" },
  하나: { logo: hanaLogo, color: "#009b8f" },
  농협: { logo: nonghyupLogo, color: "rgb(38, 183, 188)" },
  BC: { logo: bcLogo, color: "#e83e44" },
  우리: { logo: wooriLogo, color: "rgb(32, 196, 244)" },
};
