import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";

import { Box, Typography, Tooltip, IconButton, Menu, MenuItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ReplayIcon from "@mui/icons-material/Replay";

import { ListPageUserInfo as UserInfo } from "components/UserInfo";
import { CustomButton as Button } from "components/Button";
import { SnackBar } from "components/SnackBar";
import Title from "components/Title";
import { DeletePollModal, ResetPollModal } from "components/Modal";

import * as S from "./styles";
import * as API from "utils/api/Poll";

const PollingListPage = (props) => {
  const navigate = useNavigate();
  const [cookies] = useCookies();

  const columns = [
    {
      field: "name",
      headerName: "프로젝트 이름",
      flex: 2,
      renderCell: (params) => {
        if (!params.value)
          return (
            <Link
              to={`/polling/edit/${params.id}`}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <Typography color="error" sx={{ fontFamily: "Pretendard", fontWeight: "600" }}>
                수정이 필요한 폴링입니다. 여기를 클릭하여 수정을 완료해주세요.
              </Typography>
            </Link>
          );
        return (
          <Tooltip title="진행 페이지 이동" arrow>
            <Link
              to={`/polling/manager/${params.id}`}
              style={{ textDecoration: "none", width: "100%" }}
            >
              <Box id={params.id} sx={{ cursor: "pointer", width: "100%", display: "flex" }}>
                <PlayCircleFilledWhiteIcon
                  sx={{ mr: "10px", color: "#9a9a9a", "&:hover": { color: "#ababab" } }}
                />
                <Typography sx={{ fontFamily: "Pretendard", fontWeight: "600", color: "#222" }}>
                  {params.value}
                </Typography>
              </Box>
            </Link>
          </Tooltip>
        );
      },
    },
    { field: "type", headerName: "타입", flex: 1 },
    { field: "created", headerName: "날짜", flex: 1 },
    {
      field: "action",
      headerName: "",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <>
            <IconButton id={params.id} onClick={handleMenu}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id={params.id}
              anchorEl={anchorEl}
              open={anchorEl === null ? false : params.id === anchorEl.id}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  width: "200px",
                  boxShadow: "1px 1px 2px #00000020",
                  padding: "0 10px",
                  borderRadius: "10px",
                },
              }}
            >
              <MenuItem onClick={goToApp} sx={{ mt: "5px", mb: "5px" }} id={params.id}>
                <PlayCircleOutlineOutlinedIcon sx={{ mr: "10px" }} />
                <Typography sx={{ font: "600 1rem Pretendard" }}>슬라이드로 이동</Typography>
              </MenuItem>
              <MenuItem onClick={editPoll} sx={{ mt: "5px", mb: "5px" }} id={params.id}>
                <EditOutlinedIcon sx={{ mr: "10px" }} />
                <Typography sx={{ font: "600 1rem Pretendard" }}>수정</Typography>
              </MenuItem>
              <MenuItem onClick={handleDeletePollModal} sx={{ mb: "5px" }} id={params.id}>
                <DeleteForeverOutlinedIcon sx={{ mr: "10px" }} />
                <Typography sx={{ font: "600 1rem Pretendard" }}>삭제</Typography>
              </MenuItem>
              <MenuItem onClick={copyPoll} sx={{ mb: "5px" }} id={params.id}>
                <ContentCopyOutlinedIcon sx={{ mr: "10px" }} />
                <Typography sx={{ font: "600 1rem Pretendard" }}>복제</Typography>
              </MenuItem>
              <MenuItem onClick={handleResetPollModal} sx={{ mb: "5px" }} id={params.id}>
                <ReplayIcon sx={{ mr: "10px" }} />
                <Typography sx={{ font: "600 1rem Pretendard" }}>리셋</Typography>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [polls, setPolls] = useState([]);
  const [checkedList, setCheckedList] = useState([]);

  const [modalId, setModalId] = useState("");
  const [deletePollModalOpened, setDeletePollModalOpened] = useState(false);
  const [resetPollModalOpened, setResetPollModalOpened] = useState(false);

  const [snackBarOpened, setSnackBarOpened] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState("success");
  const [snackBarText, setSnackBarText] = useState("");

  const handleCheckList = (list) => {
    setCheckedList([...list]);
  };

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const goToApp = (e) => {
    let poll = polls.filter((poll) => poll.id === e.currentTarget.id)[0];

    if (poll.name === "") {
      setSnackBarOpened(true);
      setSnackBarSeverity("error");
      setSnackBarText("해당 폴링에 접근할 수 없습니다. 수정 완료 후 접속해주세요.");
    } else {
      navigate(`/polling/manager/${e.currentTarget.id}`);
    }
  };

  const addPoll = () => {
    API.addPoll(cookies.auth, (res) => {
      const { poll, message, success } = res.data;

      if (success) {
        navigate(`/polling/edit/${poll._id}`);
      } else {
        switch (message) {
          case "Login Expired":
            alert("로그인이 만료되었습니다.");
            navigate("/login", { replace: true });
            break;
          case "Free Maximum":
            setSnackBarOpened(true);
            setSnackBarSeverity("error");
            setSnackBarText("무료 사용자는 최대 2개의 폴링까지 생성할 수 있습니다.");
            break;
          default:
            alert("알 수 없는 오류가 발생했습니다.");
            break;
        }
      }
    });
  };

  const getPollList = () => {
    API.getPollList(cookies.auth, (res) => {
      const { success, polls, message } = res.data;
      if (success) {
        let newPolls = [];

        for (let poll of polls) {
          newPolls.push({
            id: poll._id,
            name: poll.name,
            type: "Live Polling",
            created: poll.created.slice(0, 10),
          });
        }

        setPolls(newPolls);
      } else {
        switch (message) {
          case "Login Expired":
            // alert("로그인이 만료되었습니다.");
            // navigate("/login", { replace: true });
            break;
          default:
            alert("알 수 없는 오류가 발생했습니다.");
            break;
        }
      }
    });
  };

  const editPoll = (e) => {
    let poll = polls.filter((poll) => poll.id === e.currentTarget.id)[0];

    if (
      poll.name !== "" &&
      window.confirm("해당 폴링을 수정할 시 투표된 데이터들은 손실됩니다. 그래도 수정하시겠습니까?")
    ) {
      navigate(`/polling/edit/${e.currentTarget.id}`);
    } else if (poll.name === "") {
      navigate(`/polling/edit/${e.currentTarget.id}`);
    }
  };

  const deletePoll = (id) => {
    API.removePoll(id ? [id] : checkedList, (res) => {
      let data = res.data;

      setTimeout(() => {
        setSnackBarOpened(true);
        if (data.success) {
          setSnackBarSeverity("success");
          setSnackBarText("삭제가 완료되었습니다.");
          getPollList();
        } else {
          setSnackBarSeverity("error");
          setSnackBarText("삭제에 실패하였습니다. 다시 시도해주세요.");
        }
        setDeletePollModalOpened(false);
      }, 500);
    });
  };

  const handleDeletePollModal = (e) => {
    setModalId(e.currentTarget.id);
    setDeletePollModalOpened(!deletePollModalOpened);
  };

  const copyPoll = (e) => {
    let poll = polls.filter((poll) => poll.id === e.currentTarget.id)[0];

    if (poll.name === "") {
      setSnackBarOpened(true);
      setSnackBarSeverity("error");
      setSnackBarText("해당 폴링은 복제할 수 없습니다. 수정 완료 후 다시 시도해주세요.");
    } else {
      API.duplicatePoll(e.currentTarget.id, (res) => {
        let data = res.data;

        setTimeout(() => {
          setSnackBarOpened(true);
          if (data.success) {
            setSnackBarSeverity("success");
            setSnackBarText("복제가 완료되었습니다.");
          } else {
            if (data.message === "Free Maximum") {
              setSnackBarSeverity("error");
              setSnackBarText("무료 사용자는 최대 2개의 폴링까지 생성할 수 있습니다.");
            } else {
              setSnackBarSeverity("error");
              setSnackBarText("복제에 실패하였습니다. 다시 시도해주세요.");
            }
          }
        }, 500);
        setTimeout(() => {
          getPollList();
        }, 800);
      });
    }

    setAnchorEl(null);
  };

  const handleResetPollModal = (e) => {
    if (resetPollModalOpened) {
      setResetPollModalOpened(false);
    } else {
      let poll = polls.filter((poll) => poll.id === e.currentTarget.id)[0];

      if (poll.name === "") {
        setSnackBarOpened(true);
        setSnackBarSeverity("error");
        setSnackBarText("해당 폴링은 리셋할 수 없습니다. 수정 완료 후 다시 시도해주세요.");
      } else {
        setResetPollModalOpened(true);
        setModalId(e.currentTarget.id);
      }

      setAnchorEl(null);
    }
  };

  const resetPoll = (id) => {
    API.resetPoll(id, (res) => {
      let data = res.data;

      setTimeout(() => {
        setSnackBarOpened(true);
        if (data.success) {
          setSnackBarSeverity("success");
          setSnackBarText("리셋되었습니다.");
        } else {
          setSnackBarSeverity("error");
          setSnackBarText(data.text);
        }
        setResetPollModalOpened(false);
      }, 500);

      setTimeout(() => {
        getPollList();
      }, 800);
    });
  };

  const handleSnackBar = () => {
    setSnackBarOpened(false);
  };

  useEffect(() => {
    getPollList();
  }, []);

  return (
    <S.Body>
      <S.Content>
        <Title children={{ name: "Live Polling" }} />

        <UserInfo />

        <S.MyListBox>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ font: "bold 25px Pretendard" }}>My List</Typography>

            <Button
              sx={{ font: "600 18px Pretendard", py: "10px" }}
              onClick={checkedList.length > 0 ? handleDeletePollModal : addPoll}
            >
              {checkedList.length > 0 ? "삭제" : "추가"}
            </Button>
          </Box>

          <S.MyList>
            <DataGrid
              columns={columns}
              rows={polls}
              rowsPerPageOptions={[100]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={handleCheckList}
              selectionModel={checkedList}
              disableColumnMenu
              sx={{
                font: "600 16px Pretendard",
                bgcolor: "#fff",
                borderRadius: "15px",
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "created", sort: "desc" }],
                },
              }}
            />
          </S.MyList>
        </S.MyListBox>
      </S.Content>

      <SnackBar
        open={snackBarOpened}
        onClose={handleSnackBar}
        severity={snackBarSeverity}
        text={snackBarText}
      />

      {deletePollModalOpened && (
        <DeletePollModal
          open={deletePollModalOpened}
          handleModal={handleDeletePollModal}
          deletePoll={deletePoll}
          count={checkedList.length}
          id={modalId}
        />
      )}

      {resetPollModalOpened && (
        <ResetPollModal
          open={resetPollModalOpened}
          handleModal={handleResetPollModal}
          resetPoll={resetPoll}
          id={modalId}
        />
      )}
    </S.Body>
  );
};

export default PollingListPage;
