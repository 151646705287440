import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import * as S from "./styles";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// import logo from "assets/images/Logo.svg";
// import mac from "assets/images/Mac.png";
// import phone from "assets/images/Phone.png";
// import polling_mobile from "assets/images/screenshot/polling_mobile.png";
// import quiz_mobile from "assets/images/screenshot/quiz_mobile.jpeg";
// import pick_mobile from "assets/images/screenshot/pick_mobile.jpeg";
// import deal_mobile from "assets/images/screenshot/deal_mobile.jpeg";
// import polling_pc from "assets/images/screenshot/polling_pc.png";
// import quiz_pc from "assets/images/screenshot/quiz_pc.png";
// import pick_pc from "assets/images/screenshot/pick_pc.png";
// import deal_pc from "assets/images/screenshot/deal_pc.png";
import background from "assets/images/PlanPage/background.png";
import logo from "assets/images/PlanPage/logo.png";
import mobLogo from "assets/images/PlanPage/mobLogo.png";
import blurImg from "assets/images/PlanPage/blurImg.png";
import backgroundItem from "assets/images/PlanPage/backgroundItem.png";

import Subscribes from "../components/Subscribes/Subscribes";
import { PaymentTable } from "../components/Table";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Footer } from "components/Footer";
import { Context } from "views/PlanPage/context";
import { useNavigate } from "react-router-dom";

import styles from "../styles/styles.module.css";

const Layout = (props) => {
  const navigate = useNavigate();
  const F = useContext(Context);

  const over1300 = useMediaQuery({
    query: "(min-width:1300px)",
  });

  // 반응형 모바일
  const isMobile = useMediaQuery({
    query: "(max-width:1000px)",
  });

  const goToDiagnosisPlanPage = () => {
    setTimeout(() => {
      navigate("/diagnosis-plan");
    }, 500);
  };

  if (isMobile) {
    return (
      <S.Body>
        <Box
          sx={{
            background:
              "transparent linear-gradient(180deg, #050721 0%, #771B1B 100%) 0% 0% no-repeat padding-box;",
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 0,
          }}
        ></Box>

        <Box
          sx={{
            position: "absolute",
            width: "100%",
            background:
              "transparent linear-gradient(180deg, #050721 0%, #771B1B 100%) 0% 0% no-repeat padding-box;",
            position: "absolute",
            zIndex: 0,
          }}
        >
          <Container disableGutters maxWidth="xs" sx={{ p: "20px 10px" }}>
            <S.Content xs={12}>
              <Box className={styles.routing_button} onClick={goToDiagnosisPlanPage}>
                <ArrowForwardIcon />

                <Typography>진단지 상품 페이지로 이동하기</Typography>
              </Box>

              <S.Logo src={mobLogo} />

              <Typography sx={{ font: "300 16px Pretendard", color: "#fff", mt: "10px" }}>
                Withplus Live
              </Typography>

              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  mt: "16px",
                  mb: "30px",
                  height: "36px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={blurImg}
                  style={{ width: "100%", height: "100%", position: "absolute" }}
                />

                <Typography
                  sx={{
                    color: "white",
                    textAlign: "center",
                    margin: "auto",
                    font: "600 18px Pretendard",
                    fontSize: "clamp(12px , 3.9vw , 18px)",
                    "> span": {
                      font: "900 18px Pretendard",
                      fontSize: "clamp(12px , 3.9vw , 18px)",
                    },
                  }}
                >
                  강의에 필요한 모든 강의 지원 도구를 <span>위드플러스 라이브</span>에서
                </Typography>
              </Box>

              <Subscribes mobile />
            </S.Content>
            <S.GroupAccountContainer>
              <S.GroupAccountTitle>단체 계정 : 연간 결제 할인</S.GroupAccountTitle>

              <S.GroupAccountSubTitle>
                단체 계정 시, 연간 결제로 인한 할인 혜택을 누리세요
              </S.GroupAccountSubTitle>

              <Box className="plan-slide-box" sx={{ width: "100%", height: "100%" }}>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={0}
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  pagination={{ clickable: true }}
                  navigation
                >
                  <SwiperSlide>
                    <S.GroupBox>
                      <S.GroupTitle>10계정 이상</S.GroupTitle>

                      <S.GroupContent>
                        <S.GroupDiscount>10% 할인</S.GroupDiscount>

                        <Box>
                          <S.GroupBeforeDiscount>99,000원</S.GroupBeforeDiscount>

                          <S.GroupPrice>
                            89,100원/년 <span>{"(계정당)"}</span>
                          </S.GroupPrice>
                        </Box>
                      </S.GroupContent>
                    </S.GroupBox>
                  </SwiperSlide>

                  <SwiperSlide>
                    <S.GroupBox>
                      <S.GroupTitle>20계정 이상</S.GroupTitle>

                      <S.GroupContent>
                        <S.GroupDiscount>20% 할인</S.GroupDiscount>

                        <Box>
                          <S.GroupBeforeDiscount>99,000원</S.GroupBeforeDiscount>

                          <S.GroupPrice>
                            79,200원/년 <span>{"(계정당)"}</span>
                          </S.GroupPrice>
                        </Box>
                      </S.GroupContent>
                    </S.GroupBox>
                  </SwiperSlide>

                  <SwiperSlide>
                    <S.GroupBox>
                      <S.GroupTitle>30계정 이상</S.GroupTitle>

                      <S.GroupContent>
                        <S.GroupDiscount>30% 할인</S.GroupDiscount>

                        <Box>
                          <S.GroupBeforeDiscount>99,000원</S.GroupBeforeDiscount>

                          <S.GroupPrice>
                            69,300원/년 <span>{"(계정당)"}</span>
                          </S.GroupPrice>
                        </Box>
                      </S.GroupContent>
                    </S.GroupBox>
                  </SwiperSlide>

                  <SwiperSlide>
                    <S.GroupBox>
                      <S.GroupTitle>50계정</S.GroupTitle>

                      <S.GroupContent>
                        <S.GroupDiscount>50% 할인</S.GroupDiscount>

                        <Box>
                          <S.GroupBeforeDiscount>99,000원</S.GroupBeforeDiscount>

                          <S.GroupPrice>
                            49,500원/년 <span>{"(계정당)"}</span>
                          </S.GroupPrice>
                        </Box>
                      </S.GroupContent>
                    </S.GroupBox>
                  </SwiperSlide>
                </Swiper>
              </Box>
            </S.GroupAccountContainer>
          </Container>
          <Footer />
        </Box>
      </S.Body>
    );
  }

  return (
    <S.Body>
      {over1300 ? (
        <img
          src={background}
          style={{ position: "absolute", top: "-14%", width: "100%", zIndex: 0 }}
        />
      ) : (
        <Box
          sx={{
            background:
              "transparent linear-gradient(180deg, #050721 0%, #771B1B 100%) 0% 0% no-repeat padding-box;",
            width: "100%",
            height: "700px",
            position: "absolute",
          }}
        >
          <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
            <img src={backgroundItem} />
          </Box>
        </Box>
      )}

      <Box sx={{ position: "absolute", width: "100%" }}>
        <S.Container>
          <S.Content xs={12}>
            <S.Logo src={logo} />

            <Box className={styles.routing_button} onClick={goToDiagnosisPlanPage}>
              <ArrowForwardIcon />

              <Typography>진단지 상품 페이지로 이동하기</Typography>
            </Box>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                mt: "16px",
                mb: "30px",
                height: "36px",
              }}
            >
              <img src={blurImg} style={{ width: "100%", height: "100%", position: "absolute" }} />

              <Typography
                sx={{
                  color: "white",
                  textAlign: "center",
                  margin: "auto",
                  font: "600 30px Pretendard",
                  "> span": {
                    font: "900 30px Pretendard",
                  },
                }}
              >
                강의에 필요한 모든 강의 지원 도구를 <span>위드플러스 라이브</span>에서
              </Typography>

              <Typography
                sx={{
                  textAlign: "center",
                  color: "#fff",
                  textDecoration: "underline",
                  font: "800 33px Pretendard",
                  mt: "10px",
                }}
              >
                콘텐츠 상품 플랜
              </Typography>
            </Box>

            <Container disableGutters maxWidth="xl" sx={{ marginTop: "50px" }}>
              <Subscribes />
            </Container>
          </S.Content>
        </S.Container>

        <S.GroupAccountContainer>
          <S.GroupAccountTitle>단체 계정 : 연간 결제 할인</S.GroupAccountTitle>
          <S.GroupAccountSubTitle>
            단체 계정 시, 연간 결제로 인한 할인 혜택을 누리세요
          </S.GroupAccountSubTitle>

          <S.GroupAccountContent>
            <S.GroupAccountGrid>
              <S.GroupAccountItem>
                <S.GroupBox>
                  <S.GroupTitle>10계정 이상</S.GroupTitle>

                  <S.GroupContent>
                    <S.GroupDiscount>10% 할인</S.GroupDiscount>

                    <Box>
                      <S.GroupBeforeDiscount>99,000원</S.GroupBeforeDiscount>

                      <S.GroupPrice>
                        89,100원/년 <span>{"(계정당)"}</span>
                      </S.GroupPrice>
                    </Box>
                  </S.GroupContent>
                </S.GroupBox>
              </S.GroupAccountItem>

              <S.GroupAccountItem>
                <S.GroupBox>
                  <S.GroupTitle>20계정 이상</S.GroupTitle>

                  <S.GroupContent>
                    <S.GroupDiscount>20% 할인</S.GroupDiscount>

                    <Box>
                      <S.GroupBeforeDiscount>99,000원</S.GroupBeforeDiscount>

                      <S.GroupPrice>
                        79,200원/년 <span>{"(계정당)"}</span>
                      </S.GroupPrice>
                    </Box>
                  </S.GroupContent>
                </S.GroupBox>
              </S.GroupAccountItem>

              <S.GroupAccountItem>
                <S.GroupBox>
                  <S.GroupTitle>30계정 이상</S.GroupTitle>

                  <S.GroupContent>
                    <S.GroupDiscount>30% 할인</S.GroupDiscount>

                    <Box>
                      <S.GroupBeforeDiscount>99,000원</S.GroupBeforeDiscount>

                      <S.GroupPrice>
                        69,300원/년 <span>{"(계정당)"}</span>
                      </S.GroupPrice>
                    </Box>
                  </S.GroupContent>
                </S.GroupBox>
              </S.GroupAccountItem>

              <S.GroupAccountItem>
                <S.GroupBox>
                  <S.GroupTitle>50계정</S.GroupTitle>

                  <S.GroupContent>
                    <S.GroupDiscount>50% 할인</S.GroupDiscount>

                    <Box>
                      <S.GroupBeforeDiscount>99,000원</S.GroupBeforeDiscount>

                      <S.GroupPrice>
                        49,500원/년 <span>{"(계정당)"}</span>
                      </S.GroupPrice>
                    </Box>
                  </S.GroupContent>
                </S.GroupBox>
              </S.GroupAccountItem>
            </S.GroupAccountGrid>
          </S.GroupAccountContent>

          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                width: "250px",
                font: "700 20px Pretendard",
                padding: "10px 0",
                borderRadius: "12px",
                border: "2px solid #c33c3c",
                color: "#000",
                backgroundColor: "#fff",
                "&:hover": {
                  backgroundColor: "#c33c3c",
                  color: "#fff",
                },
                "&:active": {
                  boxShadow: "none",
                  transform: "translateY(4px)",
                },
                "&:disabled": {
                  color: "white",
                  backgroundColor: "#bebebe",
                  border: "2px solid #bebebe",
                },
                // opacity: F.user && F.user.status === "Personal" ? 0 : 1,
              }}
              // onClick={F.goToPayment}
              onClick={() => {
                window.open("https://forms.gle/a1X9Qsdf8cRKUWXp6", "_blank");
              }}
              // disabled={F.user && F.user.status === "Personal"}
              data-id="basic"
            >
              단체 계정 구매하기
              {/* {F.user && F.user.status === "Personal" && "개인 계정은 구매 불가"}
              {F.user && F.user.status !== "Personal" && "단체 계정 구매하기"}
              {F.user === null && "단체 계정 구매하기"} */}
            </Button>
          </Box>
        </S.GroupAccountContainer>

        <PaymentTable />

        <Footer />
      </Box>
    </S.Body>
  );
};

export default Layout;
