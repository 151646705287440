import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSocket } from "contexts/Socket";

import { AudioContext } from "./AudioContext";

import tada from "assets/audio/tada.mp3";
import beep from "assets/audio/beep.aac";
import shot from "assets/audio/shot.aac";
import bgm from "assets/audio/liveTap.mp3";
import final from "assets/audio/FinalRank.aac";

const ManagerContext = createContext({
  socket: null,
  tap: {},
  timer: 3,
  soundVolume: 0.25,
  // sounds: {},
  BGM: null,

  setTap: () => {},
  prepareSounds: () => {},
  handleBGM: () => {},
  setSoundVolume: () => {},
});

const ManagerProvider = ({ children }) => {
  const { tadaSFX, shotSFX, beepSFX } = useContext(AudioContext);

  const { gameId } = useParams();
  const navigate = useNavigate();
  const socket = useSocket("https://tap.withplus.live");

  // const sounds = {
  //   bgm: new Audio(bgm),
  //   tada: new Audio(tada),
  //   beep: new Audio(beep),
  //   shot: new Audio(shot),
  // };

  const [tap, setTap] = useState({});
  const [timer, setTimer] = useState(3);

  // const [BGM] = useState(new Audio(bgm));
  // const [beepSFX] = useState(new Audio(beep));
  // const [shotSFX] = useState(new Audio(shot));
  // const [tadaSFX] = useState(new Audio(tada));
  // const [finalSFX] = useState(new Audio(final));

  // const [soundVolume, setSoundVolume] = useState(0.25);

  // const [soundVolume, setSoundVolume] = useState(0);

  // const prepareSounds = () => {
  //   // sounds.beep.play();
  //   beepSFX.play();
  //   shotSFX.play();
  //   tadaSFX.play();
  //   finalSFX.play();
  //   // sounds.tada.play();
  //   // sounds.shot.play();

  //   beepSFX.pause();
  //   shotSFX.pause();
  //   tadaSFX.pause();
  //   finalSFX.pause();
  //   // sounds.beep.pause();
  //   // sounds.tada.pause();
  //   // sounds.shot.pause();
  // };

  // const handleBGM = () => {
  //   if (BGM.paused) {
  //     BGM.play();
  //     BGM.loop = true;
  //   } else {
  //     BGM.pause();
  //   }
  // };

  // useEffect(() => {
  //   BGM.volume = soundVolume;
  //   beepSFX.volume = soundVolume + 0.25;
  //   shotSFX.volume = soundVolume + 0.25;
  //   tadaSFX.volume = soundVolume;
  //   finalSFX.volume = soundVolume;
  // }, [soundVolume]);

  useEffect(() => {
    if (!socket) return;

    socket.emit("getTapData", { gameId }, (res) => {
      const { success, tap } = res;
      if (success) {
        setTap(tap);
      }
    });
  }, [socket, gameId]);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", () => {
      console.log("connect");
      socket.emit("joinRoom", { gameId, manager: true });
    });

    socket.on("updatedTap", (tap) => {
      setTap(tap);
      // console.log("updatedTap", tap);
    });

    socket.on("gamePaused", (data) => {
      setTap((prev) => ({ ...prev, isGamePaused: data.isGamePaused }));
    });

    socket.on("gameEnded", (data) => {
      // console.log("gameEnded", data);
      // sounds.tada.volume = soundVolume;
      // sounds.tada.play();
      tadaSFX.play();
      setTap((prev) => ({ ...prev, isGameEnded: data.isGameEnded }));
    });

    socket.on("setTimer", (data) => {
      setTimer(data.timer);

      if (data.timer === 0) {
        setTap((prev) => ({ ...prev, isGameStart: true }));
        shotSFX.play();
      } else {
        beepSFX.play();
      }
    });

    socket.on("resetGame", () => {
      setTimer(3);
    });

    socket.on("goToRankingPage", () => {
      // finalSFX.play();
      navigate(`/tap/manager/${gameId}/result`);
    });

    return () => {
      socket.off("connect");
      socket.off("updatedTap");
      socket.off("gamePaused");
      socket.off("gameEnded");
      socket.off("setTimer");
      socket.off("resetGame");
      socket.off("goToRankingPage");
    };
  });

  const value = {
    socket,
    tap,
    timer,
    setTap,
  };

  return <ManagerContext.Provider value={value}>{children}</ManagerContext.Provider>;
};

export { ManagerContext, ManagerProvider };
