import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Wheel } from "react-custom-roulette";
import RouletteImg1 from "./RouletteImg/RouletteImg1.png";
// import { Header } from "@withplusdream/withplus-live";
import { useMediaQuery } from "react-responsive";
import plus from "./RouletteImg/buttonPlus.png";
import minus from "./RouletteImg/buttonMinus.png";
import { Avatar, Box, Container, Grid, IconButton, Tooltip, Typography } from "@mui/material";

import SimpleGameBack from "../../../assets/images/simpleGameBack.png";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import classes from "./Roulette.module.css";
import { Textfield } from "components/Textfield";

import wheelAudio from "assets/audio/wheelAudio.aac";
import tada from "assets/audio/tada.mp3";

//SimpleGameLayout 레이아웃
export const SimpleGameRoultte = (props) => {
  const { children, isLobby, type } = props;
  const handle = useFullScreenHandle();
  const { state } = useLocation();
  const ref = useRef(null);
  const tadaRef = useRef(null);

  const [isFullscreen, setFullscreen] = useState(false);
  const [isAccessCodeOpened, setAccessCodeOpened] = useState(true);
  const [isAccessCodeVisible, setAccessCodeVisible] = useState(true);

  const [isSpinning, setIsSpinning] = useState(false);
  const [numberOfOptions, setNumberOfOptions] = useState(2);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [result, setResult] = useState(null);
  const [userInputs, setUserInputs] = useState(
    Array(numberOfOptions).fill({ text: "", weight: 1 })
  );
  const [isButtonAble, setIsButtonAble] = useState(false);
  const [sumOfWeight, setSumOfWeight] = useState(2);

  const laptop = useMediaQuery({
    query: "(min-width:1400px)",
  });

  const desktop = useMediaQuery({
    query: "(max-width:1700px)",
  });

  const isPc = useMediaQuery({
    query: "(min-width:1001px)",
  });

  const isMobile = useMediaQuery({
    query: "(max-width:1000px)",
  });
  /*----------룰렛 코드 시작----------*/

  // 돌림판 색깔 지정
  const fixedColors = Array.from({ length: numberOfOptions }, (_, index) => {
    const colorOptions = {
      2: ["#FFFFFF", "#60A7FF"],
      3: ["#FFFFFF", "#BBDAFF", "#60A7FF"],
      4: ["#FFFFFF", "#EFF6FF", "#BBDAFF", "#60A7FF"],
      5: ["#FFFFFF", "#EFF6FF", "#BBDAFF", "#9BC9FF", "#60A7FF"],
      6: ["#FFFFFF", "#EFF6FF", "#9BC9FF", "#BBDAFF", "#9BC9FF", "#60A7FF"],
      7: ["#FFFFFF", "#e2F7F0", "#EFF6FF", "#9BC9FF", "#BBDAFF", "#9BC9FF", "#60A7FF"],
      8: ["#FFFFFF", "#e2F7F0", "#EFF6FF", "#9BC9FF", "#BBDAFF", "#9BC9FF", "#80B9FF", "#60A7FF"],
      9: [
        "#FFFFFF",
        "#e2F7F0",
        "#EFF6FF",
        "#9BC9FF",
        "#BBDAFF",
        "#9BC9FF",
        "#80B9FF",
        "#60A7FF",
        "#5aA2FF",
      ],
      10: [
        "#FFFFFF",
        "#e2F7F0",
        "#EFF6FF",
        "#9BC9FF",
        "#BBDAFF",
        "#9BC9FF",
        "#80B9FF",
        "#60A7FF",
        "#5aA2FF",
        "#50b7FF",
      ],
    };

    const selectedColors = colorOptions[numberOfOptions];

    return selectedColors[index];
  });

  //텍스트 색깔
  const textColors = Array.from({ length: numberOfOptions }, (_, index) => {
    const colorOptions = {
      2: ["#60A7FF", "#FFFFFF"],
      3: ["#60A7FF", "#60A7FF", "#FFFFFF"],
      4: ["#60A7FF", "#60A7FF", "#60A7FF", "#FFFFFF"],
      5: ["#60A7FF", "#60A7FF", "#60A7FF", "#FFFFFF", "#FFFFFF"],
      6: ["#60A7FF", "#60A7FF", "#60A7FF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
      7: ["#60A7FF", "#60A7FF", "#60A7FF", "#60A7FF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
      8: ["#60A7FF", "#60A7FF", "#60A7FF", "#60A7FF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
      9: [
        "#60A7FF",
        "#60A7FF",
        "#60A7FF",
        "#60A7FF",
        "#60A7FF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
      ],
      10: [
        "#60A7FF",
        "#60A7FF",
        "#60A7FF",
        "#60A7FF",
        "#60A7FF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
        "#FFFFFF",
      ],
    };

    const selectedColors = colorOptions[numberOfOptions];

    return selectedColors[index];
  });

  // input 박스에 값이 없으면 true 반환 시작 버튼 비활성화

  //룰렛을 돌리고 당첨 번호 랜덤으로 지정
  const spinRoulette = () => {
    ref.current.play();

    if (!isSpinning) {
      setIsSpinning(true);
      setResult(null);

      // setTimeout(() => {
      const randomPrizeNumber = Math.floor(Math.random() * numberOfOptions);
      setPrizeNumber(randomPrizeNumber);
      // }, 3500);

      // console.log(randomPrizeNumber);
    }
  };

  // 룰렛 option 감소 버튼
  const handleDecrease = () => {
    if (numberOfOptions <= 2) return;

    setNumberOfOptions(Math.max(2, numberOfOptions - 1));

    setUserInputs((prevInputs) => {
      const newSize = Math.max(2, prevInputs.length - 1);
      return prevInputs.slice(0, newSize);
    });
    setResult(null);
  };

  // 룰렛 option 증가 버튼
  const handleIncrease = () => {
    if (numberOfOptions >= 10) return;

    setNumberOfOptions(numberOfOptions + 1);

    setUserInputs((prevInputs) => {
      return [...prevInputs, { text: "", weight: 1 }];
    });
    setResult(null);
  };

  // react-custom-roulette 라이브러리의 data를 넣기 위한 함수 _ option값에 룰렛에 들어갈 단어 기입
  const generateData = () => {
    const newData = Array.from({ length: numberOfOptions }, (_, index) => ({
      option: userInputs[index].text
        ? userInputs[index].text.length > 7
          ? userInputs[index].text.slice(0, 5) + "..."
          : userInputs[index].text
        : `옵션${index + 1}`,
      style: { backgroundColor: fixedColors[index % fixedColors.length], width: 10 },
      optionSize: userInputs[index].weight,
    }));
    return newData || [];
  };

  // 룰렛 회전 종료 후 결과값 저장
  const handleStopSpinning = () => {
    // ref.current.stop();
    tadaRef.current.volume = 0.3;
    tadaRef.current.play();
    setIsSpinning(false);
    setResult(`결과: ${generateData()[prizeNumber].option}`);
  };

  // input박스 onChange 핸들러
  const handleInputChange = (index, value) => {
    setUserInputs((prev) => {
      const newInputs = [...prev];
      newInputs[index] = { ...newInputs[index], text: value };
      return newInputs;
    });
  };

  const handleWeight = (e) => {
    if (isNaN(e.target.value * 1)) return;
    let value = e.target.value * 1;

    if (value > 10000) return;

    setUserInputs((prev) => {
      const newInputs = [...prev];
      newInputs[e.target.id] = { ...newInputs[e.target.id], weight: value };
      return newInputs;
    });
  };

  const handleWeightAtBlur = (e) => {
    if (e.target.value === "" || e.target.value === "0") {
      setUserInputs((prev) => {
        const newInputs = [...prev];
        newInputs[e.target.id * 1] = { ...newInputs[e.target.id], weight: 1 };
        return newInputs;
      });
    }
  };

  const handleIncreaseWeight = (e) => {
    const index = e.target.id * 1;

    if (e.shiftKey) {
      setUserInputs((prev) => {
        const newInputs = [...prev];
        newInputs[index] = {
          ...newInputs[index],
          weight: newInputs[index].weight + 100 >= 10000 ? 10000 : newInputs[index].weight + 100,
        };
        return newInputs;
      });
    } else if (e.altKey) {
      setUserInputs((prev) => {
        const newInputs = [...prev];
        newInputs[index] = {
          ...newInputs[index],
          weight: newInputs[index].weight + 10 >= 10000 ? 10000 : newInputs[index].weight + 10,
        };
        return newInputs;
      });
    } else {
      setUserInputs((prev) => {
        const newInputs = [...prev];
        newInputs[index] = {
          ...newInputs[index],
          weight: newInputs[index].weight + 1 >= 10000 ? 10000 : newInputs[index].weight + 1,
        };
        return newInputs;
      });
    }
  };

  const handleDecreaseWeight = (e) => {
    const index = e.target.id * 1;

    if (e.shiftKey) {
      setUserInputs((prev) => {
        const newInputs = [...prev];
        newInputs[index] = {
          ...newInputs[index],
          weight: newInputs[index].weight - 100 <= 0 ? 1 : newInputs[index].weight - 100,
        };
        return newInputs;
      });
    } else if (e.altKey) {
      setUserInputs((prev) => {
        const newInputs = [...prev];
        newInputs[index] = {
          ...newInputs[index],
          weight: newInputs[index].weight - 10 <= 0 ? 1 : newInputs[index].weight - 10,
        };
        return newInputs;
      });
    } else {
      setUserInputs((prev) => {
        const newInputs = [...prev];
        newInputs[index] = {
          ...newInputs[index],
          weight: newInputs[index].weight - 1 <= 0 ? 1 : newInputs[index].weight - 1,
        };
        return newInputs;
      });
    }
  };

  // 초기화 버튼
  const handleResetRoulette = () => {
    let newInputs = [
      { text: "", weight: 1 },
      { text: "", weight: 1 },
    ];

    setUserInputs(newInputs);
    setNumberOfOptions(2);
  };

  /*룰렛 침_위치만 조정하고 숨김처리*/
  const CustompointerProps = {
    src: { RouletteImg1 },
    style: {
      width: "10%",
      right: "40px",
      top: "25px",
      transform: "rotate(45deg)",
      opacity: "0.0",
    },
  };

  /*----------룰렛 코드 종료----------*/

  // console.log(userInputs);
  // console.log(Math.max(2, numberOfOptions - 1));

  const handleFullscreen = () => {
    setFullscreen(!isFullscreen);

    if (isFullscreen) handle.exit();
    else handle.enter();
  };

  const handleAccessCodeContainer = () => {
    setAccessCodeOpened(!isAccessCodeOpened);
  };

  useEffect(() => {
    if (isAccessCodeOpened) {
      setAccessCodeVisible(true);
    } else {
      setTimeout(() => {
        setAccessCodeVisible(false);
      }, 1000);
    }
  }, [isAccessCodeOpened]);

  useEffect(() => {
    const isButtonAble = userInputs.some((input) => input.text.trim() === "");
    const sum = userInputs.reduce((acc, cur) => acc + cur.weight, 0);

    setIsButtonAble(isButtonAble);
    setSumOfWeight(sum);
  }, [userInputs]);

  // useEffect(() => {
  //   console.log(isSpinning);
  //   if (isSpinning) {
  //     setTimeout(() => {
  //       const randomPrizeNumber = Math.floor(Math.random() * numberOfOptions);
  //       setPrizeNumber(randomPrizeNumber);
  //     }, 3500);
  //   }
  // }, [isSpinning]);

  // useEffect(() => {
  //   if (handle.active) {
  //     setFullscreen(true);
  //   } else {
  //     setFullscreen(false);
  //   }
  // }, [handle.active]);

  // useEffect(() => {
  //   if (isLobby) {
  //     setTimeout(() => {
  //       setAccessCodeOpened(true);
  //     }, 2000);
  //   } else {
  //     if (state?.isGameStarted) {
  //       setTimeout(() => {
  //         setAccessCodeOpened(false);
  //       }, 2000);
  //     } else {
  //       setAccessCodeOpened(false);
  //     }
  //   }
  // }, [isLobby]);

  return (
    <>
      {isPc && (
        <Box className="body" sx={{ minWidth: "1200px", pb: 0 }}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Grid item xs={11.5} sx={{ height: "100%" }}>
              {/* <Title {...props} /> */}

              {/* <Box className="manager paper" children={children.content} /> */}
              {/* <FullScreen handle={handle}> */}
              <Box
                // className="paper"
                sx={{
                  width: "100%",
                  height: "calc(100vh - 100px)",
                  boxSizing: "border-box",
                  mt: "90px",
                  p: "10px",
                  background: `url(${SimpleGameBack})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <Box
                  sx={{
                    height: "100%",
                    py: "20px",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    display: "flex",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  {/*QR코드 부분 시작*/}
                  <Box
                    sx={{
                      marginLeft: "1px",
                      borderRight: "1px solid #cdcdcd",
                      position: "relative",
                      transition: "1s",
                      minWidth: isAccessCodeOpened ? "75%" : "100%",
                      // background: `url(${SimpleGameBack})`,
                      backgroundSize: "95% 100%",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // height: "700px",
                    }}
                  >
                    {/*이 부분 수정 */}
                    {/* {children.ContentBox} */}
                    <div className={classes.content}>
                      <div className={classes.justifyContent}>
                        <h1 className={classes.h1Font}>원판 룰렛</h1>
                      </div>

                      <div className={classes.justifyContent}>
                        <h1 className={classes.h1Font}>{result}</h1>
                      </div>

                      {/* 가져와서 쓸 코드 */}
                      <div className={classes.rouletteBox}>
                        <img className={classes.roulettePointer} src={RouletteImg1} />
                        <Wheel
                          mustStartSpinning={isSpinning}
                          // markerImage={RouletteImg1}
                          //룰렛 텍스트 가로
                          // perpendicularText={[true]}
                          pointerProps={CustompointerProps}
                          prizeNumber={prizeNumber}
                          data={generateData()}
                          backgroundColors={["#3e3e3e", "#df3428"]}
                          textColors={textColors}
                          outerBorderColor={["none"]}
                          // outerBorderWidth={[9]}
                          innerBorderColor={["#f2f2f2"]}
                          radiusLineColor={["tranparent"]}
                          radiusLineWidth={[1]}
                          textDistance={65}
                          fontSize={[20]}
                          // spinDuration={[0.4]}
                          spinDuration={[0.5768]}
                          // disableInitialAnimation={true}
                          onStopSpinning={handleStopSpinning}
                        />
                        <button
                          className={classes.rouletteButton}
                          onClick={spinRoulette}
                          disabled={isButtonAble}
                        >
                          시작하기
                        </button>
                        {/*버튼 뒤의 배경*/}
                        <div className={classes.buttonBackGround}></div>
                        {/*룰렛 테두리 배경*/}
                        <div className={classes.rouletteBackGround}></div>
                      </div>
                    </div>
                    <Box
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: "50%",
                        transform: "translate(50%, -50%)",
                      }}
                    >
                      <IconButton
                        sx={{
                          bgcolor: "#dddddd",
                          color: "white",
                        }}
                        onClick={handleAccessCodeContainer}
                      >
                        {isAccessCodeOpened ? (
                          <KeyboardArrowRightIcon />
                        ) : (
                          <KeyboardArrowLeftIcon
                            sx={{
                              margin: "2px 0px",
                              marginRight: "16px",
                            }}
                          />
                        )}
                      </IconButton>
                    </Box>
                  </Box>
                  {/*QR코드 부분 끝*/}

                  <Box
                    sx={{
                      // margin: "auto",
                      display: isAccessCodeVisible ? "flex" : "none",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      transitionDelay: "1s",
                      minWidth: isAccessCodeOpened ? "25%" : "0%",
                    }}
                  >
                    {/*이 부분 수정 */}
                    {/*input value 넣는 곳 */}
                    {/* <div className={classes.resultBox}>
                      {isButtonAble === true ? (
                        <h1></h1>
                      ) : (
                        <h2 style={{ height: "10px", lineHeight: "10px" }}>{result}</h2>
                      )}
                    </div> */}
                    <div className={classes.optionInputBox}>
                      <div className={classes.boxStyle} />
                      <h3 style={{ width: "100%", textAlign: "center", fontWeight: "bold" }}>
                        옵션 내용과 확률을 설정해주세요!
                      </h3>
                      <div
                        style={{
                          backgroundColor: "#f2f2f2",
                          display: "flex",
                          gap: "10px",
                          padding: "10px 10px 5px 10px",
                          borderRadius: "10px 10px 0 0",
                        }}
                      >
                        <span
                          style={{
                            font: "600 18px Pretendard",
                            textAlign: "center",
                            width: "50%",
                            border: "1px solid #ffffff00",
                            padding: "1px 2px",
                          }}
                        >
                          내용
                        </span>
                        <span
                          style={{
                            font: "600 18px Pretendard",
                            textAlign: "center",
                            width: "25%",
                            border: "1px solid #ffffff00",
                            padding: "1px 2px",
                          }}
                        >
                          가중치
                        </span>
                        <span
                          style={{
                            font: "600 18px Pretendard",
                            textAlign: "center",
                            width: "25%",
                            border: "1px solid #ffffff00",
                            padding: "1px 2px",
                          }}
                        >
                          확률
                        </span>
                      </div>
                      {Array.from({ length: numberOfOptions }, (_, index) => (
                        <div
                          key={index}
                          style={{
                            textAlign: "center",
                            padding: "3px",
                            display: "flex",
                            gap: "10px",
                            backgroundColor: "#f2f2f2",
                            padding: "3px 10px",
                            borderRadius: index === numberOfOptions - 1 ? "0px 0px 10px 10px" : "0",
                            paddingBottom: numberOfOptions - 1 === index ? "10px" : "3px",
                            boxSizing: "border-box",
                          }}
                        >
                          <input
                            className={classes.inputCss}
                            type="text"
                            placeholder={`옵션 ${index + 1}`}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            value={userInputs[index].text}
                          />

                          {/* <input
                            className={classes.inputCss}
                            style={{ width: "25%" }}
                            type="text"
                            placeholder={`옵션 ${index + 1}`}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            value={userInputs[index]}
                          /> */}
                          <Textfield
                            sx={{
                              width: "35%",
                              height: "50px",
                              p: "2px",
                              m: 0,
                              display: "block",
                              position: "relative",
                              flexShrink: 0,
                              boxSizing: "border-box",
                            }}
                            InputProps={{
                              style: {
                                position: "absolute",
                                top: 0,
                                left: 0,
                                height: "100%",
                                width: "100%",
                                backgroundColor: "#fff",
                                borderRadius: "5px",
                                border: "1px solid rgb(222, 219, 219)",
                                padding: "5px",
                                boxSizing: "border-box",
                              },
                              startAdornment: (
                                <div
                                  style={{
                                    font: "500 14px Pretendard",
                                    color: "#666",
                                    opacity: "0.75",
                                    paddingRight: "5px",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  X
                                </div>
                              ),
                              endAdornment: (
                                <Tooltip
                                  title={
                                    <Typography>
                                      Shift + 클릭 : ±100
                                      <br />
                                      Alt + 클릭 : ±10
                                      <br />
                                      클릭 : ±1
                                    </Typography>
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <div
                                    style={{
                                      paddingLeft: "5px",
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "5px",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    <button
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        font: "600 16px Pretendard",
                                        border: "1px solid #666",
                                      }}
                                      id={index + ""}
                                      onClick={handleIncreaseWeight}
                                    >
                                      +
                                    </button>
                                    <button
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        font: "600 16px Pretendard",
                                        border: "1px solid #666",
                                      }}
                                      id={index + ""}
                                      onClick={handleDecreaseWeight}
                                    >
                                      -
                                    </button>
                                  </div>
                                </Tooltip>
                              ),
                            }}
                            value={userInputs[index].weight}
                            onChange={handleWeight}
                            onBlur={handleWeightAtBlur}
                            id={index + ""}
                          />

                          <div
                            style={{
                              width: "15%",
                              height: "50px",
                              font: "600 16px Pretendard",
                              padding: "2px 1px",
                              boxSizing: "border-box",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexShrink: 0,
                            }}
                          >
                            {((userInputs[index].weight / sumOfWeight) * 100).toFixed(2) + "%"}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <p style={{ textAlign: "center", fontSize: "10px", color: "#B2B2B2" }}>
                        &#40;2~10개까지 가능 &#41;
                      </p>
                      <div className={classes.buttonBox}>
                        <img className={classes.button} src={minus} onClick={handleDecrease} />
                        <h2 className={classes.numberOfOptionsH2}>
                          &nbsp;&nbsp;{numberOfOptions}&nbsp;&nbsp;
                        </h2>

                        <img className={classes.button} src={plus} onClick={handleIncrease} />
                      </div>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <button className={classes.startOrReset} onClick={handleResetRoulette}>
                          초기화
                        </button>
                      </div>
                    </div>
                  </Box>
                </Box>

                <Box sx={{ position: "absolute", top: 20, right: 20 }}>
                  <IconButton
                    size="large"
                    sx={{
                      bgcolor: "#c33c3c",
                      "&:hover": { bgcolor: "#a31c1c" },
                      boxShadow: "2px 2px 6px #797979",
                      color: "white",
                    }}
                    onClick={handleFullscreen}
                  >
                    {isFullscreen ? <CloseFullscreenIcon /> : <FullscreenIcon />}
                  </IconButton>
                </Box>
              </Box>
              {/* </FullScreen> */}
            </Grid>
          </Grid>
        </Box>
      )}
      {isMobile && (
        <>
          {/*룰렛 모바일버전*/}
          <Box className="body" sx={{ position: "relative", pt: "80px", overflow: "hidden" }}>
            <Container
              maxWidth="xs"
              disableGutters
              sx={{
                // minHeight: "calc(var(--vh, 1vh) * 100 - 80px)",
                // height: "calc(var(--vh, 1vh) * 100 - 80px)",
                // position: "absolute",
                // top: "50%",
                // left: "50%",
                // transform: "translate(-50%, 0%)",
                display: "flex",
                justifyContent: "center",
                pt: "10px",
                // pb: "20px",
                boxSizing: "border-box",
              }}
            >
              {/* <Grid container sx={{ display: "flex", justifyContent: "center" }}> */}
              {/* <Grid item xs={11}> */}
              <Box className="paper" sx={{ width: "100%" }}>
                <Box
                  sx={{
                    // minHeight: "100vh",
                    height: "100%",
                    pb: "10px",

                    display: "flex",
                    position: "relative",
                    width: "100%",
                    // marginTop: "7vw",
                    background: `url(${SimpleGameBack})`,
                    backgroundSize: "110% 110%",
                    borderRadius: "15px",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // overflow: "auto",
                  }}
                >
                  {/*classses.content -> 룰렛의 전체 div */}
                  <div className={classes.content}>
                    {/*classes.boxStyle -> 파란색 박스 디자인 */}
                    <div className={classes.boxStyle} style={{ marginTop: "30px" }} />
                    <h3 className={classes.h1Font} style={{ textAlign: "center" }}>
                      원판 룰렛
                    </h3>
                    <h3 style={{ textAlign: "center" }}>{result}</h3>

                    <div
                      style={{
                        display: "flex",
                        gap: "6px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span className={classes.mobAlert}>(2~10개 까지 설정가능)</span>

                      <div className={classes.buttonBox}>
                        <img className={classes.button} src={minus} onClick={handleDecrease} />
                        <h2 className={classes.numberOfOptionsH2}>
                          &nbsp;&nbsp;{numberOfOptions}&nbsp;&nbsp;
                        </h2>

                        <img className={classes.button} src={plus} onClick={handleIncrease} />
                      </div>
                    </div>

                    {/*classes.mobileRouletteBox 룰렛를 감사는 사우이 div */}

                    <div style={{ display: "flex", justifyContent: "center", margin: "40px" }}>
                      {/* <div className={classes.mobileRouletteBox}> */}
                      <div className={classes.mobRouletteBox}>
                        {/*classes.roulettePointer 룰렛의 침img  */}
                        <img className={classes.roulettePointer} src={RouletteImg1} />
                        <Wheel
                          mustStartSpinning={isSpinning}
                          // markerImage={RouletteImg1}
                          //룰렛 텍스트 가로
                          // perpendicularText={[true]}
                          pointerProps={CustompointerProps}
                          prizeNumber={prizeNumber}
                          data={generateData()}
                          backgroundColors={["#3e3e3e", "#df3428"]}
                          textColors={textColors}
                          outerBorderColor={["none"]}
                          // outerBorderWidth={[9]}s
                          innerBorderColor={["#f2f2f2"]}
                          radiusLineColor={["tranparent"]}
                          radiusLineWidth={[12]}
                          textDistance={65}
                          fontSize={[20]}
                          spinDuration={[0.5768]}
                          onStopSpinning={handleStopSpinning}
                        />
                        <button
                          className={classes.rouletteButton}
                          onClick={spinRoulette}
                          disabled={isButtonAble}
                        >
                          시작하기
                        </button>
                        {/*버튼 뒤의 이미지배경*/}
                        <div className={classes.buttonBackGround}></div>
                        {/*룰렛 테두리 이미지배경*/}
                        <div className={classes.mobileRouletteBackGround}></div>
                      </div>
                    </div>

                    <div
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          width: "100%",
                          // backgroundColor: "yellow",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Pretendard",
                            fontWeight: "bold",
                            width: "50%",
                            textAlign: "center",
                            // backgroundColor: "blue",
                          }}
                        >
                          옵션을 입력해주세요.
                        </div>
                        <div
                          style={{
                            fontFamily: "Pretendard",
                            fontWeight: "bold",
                            width: "35%",
                            // backgroundColor: "red",
                            textAlign: "center",
                          }}
                        >
                          가중치를 설정해주세요.
                        </div>
                      </div>

                      {Array.from({ length: numberOfOptions }, (_, index) => (
                        <div
                          key={index}
                          style={{
                            textAlign: "center",
                            padding: "3px",
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                          }}
                        >
                          {/* <label>{`Option ${index + 1}`}: </label> */}
                          <input
                            className={classes.inputCss}
                            type="text"
                            placeholder={`옵션 ${index + 1}`}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            value={userInputs[index].text}
                          />
                          <Textfield
                            sx={{
                              width: "35%",
                              height: "50px",
                              p: "2px",
                              m: 0,
                              display: "block",
                              position: "relative",
                              flexShrink: 0,
                              boxSizing: "border-box",
                            }}
                            InputProps={{
                              style: {
                                position: "absolute",
                                top: 0,
                                left: 0,
                                height: "100%",
                                width: "100%",
                                backgroundColor: "#fff",
                                borderRadius: "5px",
                                border: "1px solid rgb(222, 219, 219)",
                                padding: "5px",
                                boxSizing: "border-box",
                              },
                              startAdornment: (
                                <div
                                  style={{
                                    font: "500 14px Pretendard",
                                    color: "#666",
                                    opacity: "0.75",
                                    paddingRight: "5px",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  X
                                </div>
                              ),
                              endAdornment: (
                                <Tooltip
                                  title={
                                    <Typography>
                                      Shift + 클릭 : ±100
                                      <br />
                                      Alt + 클릭 : ±10
                                      <br />
                                      클릭 : ±1
                                    </Typography>
                                  }
                                  arrow
                                  placement="top"
                                >
                                  <div
                                    style={{
                                      paddingLeft: "5px",
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "5px",
                                      boxSizing: "border-box",
                                    }}
                                  >
                                    <button
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        font: "600 16px Pretendard",
                                        border: "1px solid #666",
                                      }}
                                      id={index + ""}
                                      onClick={handleIncreaseWeight}
                                    >
                                      +
                                    </button>
                                    <button
                                      style={{
                                        padding: 0,
                                        margin: 0,
                                        borderRadius: "50%",
                                        width: "20px",
                                        height: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        font: "600 16px Pretendard",
                                        border: "1px solid #666",
                                      }}
                                      id={index + ""}
                                      onClick={handleDecreaseWeight}
                                    >
                                      -
                                    </button>
                                  </div>
                                </Tooltip>
                              ),
                            }}
                            value={userInputs[index].weight}
                            onChange={handleWeight}
                            onBlur={handleWeightAtBlur}
                            id={index + ""}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </Box>
              </Box>
              {/* </Grid> */}
              {/* </Grid> */}
            </Container>
          </Box>
        </>
      )}

      <audio ref={ref} src={wheelAudio} style={{ display: "none" }} />
      <audio ref={tadaRef} src={tada} style={{ display: "none" }} />
    </>
  );
};
