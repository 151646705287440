import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import { DataGrid } from "@mui/x-data-grid";
import Layout from "Layout/ListPageLayout";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";

import { CustomButton as Button } from "../../components/Button";
import {
  DELETE_Quiz,
  GET_QuizList,
  POST_duplicateQuiz,
  POST_newQuiz,
  RESET_Quiz,
} from "utils/api/Quiz";
import { IconButton, Menu, MenuItem, Tooltip, Typography, Box } from "@mui/material";
import { DeleteQuizModal } from "../../components/Modal/Modal";
import { SnackBarContext, SnackBarProvider } from "./contexts/SnackBar";

import styles from "./styles/LiveQuizStyles";

const QuizListPage = () => {
  return (
    <SnackBarProvider>
      <QuizList />
    </SnackBarProvider>
  );
};

const QuizList = (props) => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const { setOpen, setSeverity, setText } = useContext(SnackBarContext);

  const [quizList, setQuizList] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [isModalOpened, setModalOpened] = useState(false);
  const [isButtonLoading, setButtonLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const columns = [
    {
      field: "name",
      headerName: "퀴즈",
      flex: 4,
      renderCell: (params) => {
        if (!params.value)
          return (
            <Typography color="error" noWrap sx={{ font: "600 18px Pretendard" }}>
              수정이 필요한 목록입니다. Edit 버튼을 눌러 수정을 완료해주세요.
            </Typography>
          );
        return (
          <Tooltip title="퀴즈 진행 페이지로 이동" arrow>
            <Box id={params.id} onClick={goToApp} sx={styles.data_grid.quiz_name}>
              <PlayCircleFilledWhiteIcon sx={styles.data_grid.icon} />
              <Typography sx={{ font: "600 18px Pretendard" }}>{params.value}</Typography>
            </Box>
          </Tooltip>
        );
      },
    },
    { field: "type", headerName: "타입", flex: 3 },
    { field: "created", headerName: "생성 날짜", flex: 3 },
    {
      field: "action",
      headerName: "더보기",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton id={params.id} onClick={handleMenu}>
              <MoreVertIcon />
            </IconButton>

            <Menu
              id={params.id}
              open={anchorEl === null ? false : params.id === anchorEl.id}
              anchorEl={anchorEl}
              PaperProps={{
                style: styles.menu,
              }}
              onClose={handleMenu}
            >
              <MenuItem onClick={goToApp} sx={styles.menuitem.box} id={params.id}>
                <PlayCircleOutlineOutlinedIcon sx={styles.menuitem.icon} />
                <Typography sx={styles.menuitem.font}>슬라이드로 이동</Typography>
              </MenuItem>

              <MenuItem onClick={editQuiz} sx={styles.menuitem.box} id={params.id}>
                <EditOutlinedIcon sx={styles.menuitem.icon} />
                <Typography sx={styles.menuitem.font}>수정</Typography>
              </MenuItem>

              <MenuItem onClick={handleDeleteQuiz} sx={styles.menuitem.box} id={params.id}>
                <DeleteForeverOutlinedIcon sx={styles.menuitem.icon} />
                <Typography sx={styles.menuitem.font}>삭제</Typography>
              </MenuItem>

              <MenuItem onClick={duplicateQuiz} sx={styles.menuitem.box} id={params.id}>
                <ContentCopyOutlinedIcon sx={styles.menuitem.icon} />
                <Typography sx={styles.menuitem.font}>복제</Typography>
              </MenuItem>

              <MenuItem onClick={resetQuiz} sx={styles.menuitem.box} id={params.id}>
                <ReplayIcon sx={styles.menuitem.icon} />
                <Typography sx={styles.menuitem.font}>리셋</Typography>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  const addQuiz = () => {
    POST_newQuiz(cookies.auth, (res) => {
      let { success, quiz, message } = res.data;

      if (success) {
        navigate(`/features/quiz/edit/${quiz._id}`);
      } else {
        setSeverity("error");
        setText(message);
        setOpen(true);
      }
    });
  };

  const deleteQuiz = () => {
    setButtonLoading(true);

    DELETE_Quiz(checkedList, (res) => {
      let { success } = res.data;

      if (success) {
        setTimeout(() => {
          setModalOpened(false);

          setText("삭제가 완료되었습니다.");
          setSeverity("success");
          setOpen(true);
        }, 500);

        setTimeout(() => {
          getQuizList();
          setButtonLoading(false);
          setCheckedList([]);
        }, 700);
      } else {
        window.location.reload();
      }
    });
  };

  const handleDeleteQuizModal = () => {
    setModalOpened(!isModalOpened);
  };

  const handleCheckList = (list) => {
    setCheckedList([...list]);
  };

  const handleMenu = (e) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const getQuizList = () => {
    GET_QuizList(cookies.auth, (res) => {
      let { success, quizList } = res.data;

      if (success) {
        let newQuizList = [];

        for (var i = 0; i < quizList.length; i++) {
          newQuizList.push({
            id: quizList[i]._id,
            name: quizList[i].name,
            type: "기본형",
            created: quizList[i].created?.slice(0, 10),
          });
        }

        newQuizList.sort((a, b) => {
          return new Date(b.created) - new Date(a.created);
        });

        setQuizList(newQuizList);
      }
    });
  };

  // * Menu Item Event Function
  const goToApp = (e) => {
    const quiz = quizList.filter((quiz) => quiz.id === e.currentTarget.id)[0];

    if (quiz.name === "") {
      setText("해당 퀴즈를 진행할 수 없습니다. 수정 완료 후 접속해주세요.");
      setSeverity("error");
      setOpen(true);
    } else {
      // window.open(`/features/quiz/app/${e.currentTarget.id}`, "_blank");
      navigate(`/features/quiz/app/${e.currentTarget.id}`);
      // console.log("슬라이드로 이동하기 :)");
    }
  };

  const editQuiz = (e) => {
    const quiz = quizList.filter((quiz) => quiz.id === e.currentTarget.id)[0];
    setAnchorEl(null);

    if (
      quiz.name !== "" &&
      window.confirm("해당 퀴즈를 수정할 시 진행된 데이터들은 손실됩니다. 그래도 수정하시겠습니까?")
    ) {
      navigate(`/features/quiz/edit/${e.currentTarget.id}`);
      // window.open(`/features/quiz/edit/${e.currentTarget.id}`, "_blank");
    } else if (quiz.name === "") {
      // window.open(`/features/quiz/edit/${e.currentTarget.id}`, "_blank");

      navigate(`/features/quiz/edit/${e.currentTarget.id}`);
    }
  };

  const handleDeleteQuiz = (e) => {
    setCheckedList([e.currentTarget.id]);
    handleDeleteQuizModal();
  };

  const duplicateQuiz = (e) => {
    handleMenu();

    POST_duplicateQuiz(e.currentTarget.id, (res) => {
      let data = res.data;

      if (data.success) {
        setText("복제가 완료되었습니다.");
        setSeverity("success");
      } else {
        setText(data.message);
        setSeverity("warning");
      }

      setOpen(true);
      setTimeout(() => {
        getQuizList();
      }, 300);
    });
  };

  const resetQuiz = (e) => {
    // console.log("퀴즈 리셋 하기 :)");
    // console.log(e.currentTarget.id);
    handleMenu();

    RESET_Quiz(e.currentTarget.id, (res) => {
      let data = res.data;

      if (data.success) {
        setText("리셋이 완료되었습니다.");
        setSeverity("success");
      } else {
        setText(data.message);
        setSeverity("warning");
      }

      setOpen(true);
      setQuizList([]);

      setTimeout(() => {
        getQuizList();
      }, 300);
    });
  };

  useEffect(() => {
    getQuizList();
  }, []);

  return (
    <>
      <Layout>
        {{
          name: "Live Quiz",

          button: (
            <Button
              sx={{ fontSize: "18px" }}
              onClick={checkedList.length === 0 ? addQuiz : handleDeleteQuizModal}
              children={checkedList.length === 0 ? "추가" : "삭제"}
            />
          ),

          data: (
            <DataGrid
              columns={columns}
              rows={quizList}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={handleCheckList}
              selectionModel={checkedList}
              disableColumnMenu
              sx={{ font: "600 16px Pretendard" }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "created", sort: "desc" }],
                },
              }}
            />
          ),
        }}
      </Layout>

      <DeleteQuizModal
        open={isModalOpened}
        checkedList={checkedList}
        onClose={handleDeleteQuizModal}
        action={deleteQuiz}
        loading={isButtonLoading}
      />
    </>
  );
};

export default QuizListPage;
