export const QuizTemplate = (type) => {
  let id = new Date().getTime() + "";

  switch (type) {
    case "초성 퀴즈":
      return {
        id: id,
        type: type,
        question: "",
        answer: "",
        timer: 10,
        isCompleted: false,
        mediaType: "",
        score: 300,
      };

    case "OX 퀴즈":
      return {
        id: id,
        type: type,
        question: "",
        answer: "",
        timer: 10,
        isCompleted: false,
        mediaType: "",
        score: 300,
      };

    default:
      return {
        id: id,
        type: "객관식",
        question: "",
        answers: [
          { id: id + 1, text: "" },
          { id: id + 2, text: "" },
          { id: id + 3, text: "" },
          { id: id + 4, text: "" },
        ],
        correctAnswerIDs: [],
        timer: 10,
        isCompleted: false,
        isMultiple: false,
        mediaType: "",
        score: 300,
      };
  }
};
