import { styled } from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";
import { Box, Container, IconButton, Paper } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export const Body = MuiStyled((props) => (
  <Box
    sx={{
      height: "calc(var(--vh, 1vh) * 100)",
      bgcolor: "#f5f5f5",
      p: "5px",
      boxSizing: "border-box",
    }}
    {...props}
  />
))({});

export const container = (props) => {
  return (
    <Container maxWidth="xs" disableGutters sx={{ height: "100%" }}>
      <Paper
        sx={{
          minHeight: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "visible",
        }}
      >
        {props.children}
      </Paper>
    </Container>
  );
};

export const Header = MuiStyled(Box)({
  display: "flex",
  justifyContent: "center",
  paddingTop: "10px",
  marginBottom: "20px",
  position: "relative",
});

export const Logo = styled.img`
  width: ${(props) => (props.width ? props.width : "50%")};
  margin: auto;
  filter: ${(props) => (props.$filter ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))" : "none")};
`;

export const QnaButton = MuiStyled(IconButton)({
  position: "absolute",
  top: "50%",
  right: 7,
  transform: "translateY(-50%)",
  fontSize: "16px",
  fontWeight: "600",
  backgroundColor: "#eee",
  width: "50px",
  height: "50px",
  boxShadow: "1px 4px 4px rgba(0, 0, 0, 0.25)",
  "&:hover": { backgroundColor: "#eee" },
  "&:active": { transform: "translateY(-48%)", boxShadow: "none" },
});

export const Footer = MuiStyled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minHeight: "100px",
  flex: 1,
  flexDirection: "column",
  paddingBottom: "5px",
});

export const LikeButton = MuiStyled(IconButton)({
  border: "2px solid #c33c3c",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  "&:active": { transform: "translateY(4px)", boxShadow: "none" },
});

// ? Intro
export const Intro = MuiStyled((props) => <Box sx={{ p: "10px" }} {...props} />)({});

export const QuestionBox = MuiStyled((props) => (
  <Box
    sx={{
      p: "10px",
    }}
    {...props}
  />
))({});

// ? WordCloud
export const WordCloud = MuiStyled(Box)({
  height: "100%",
  padding: "10px",
  boxSizing: "border-box",
});

export const WordCloudRestrictedBox = MuiStyled(Box)({
  backgroundColor: "#f5f5f5",
  border: "2px solid #eee",
  padding: "15px",
  marginBottom: "10px",
  borderRadius: "10px",
});

export const WordCloudSubmitButton = MuiStyled(LoadingButton)({
  font: "600 18px Pretendard",
  padding: "15px 0",
  width: "50%",
  borderRadius: "10px",
  "&:active": { boxShadow: "none", transform: "translateY(4px)" },
});

// ? Choice

export const ChoiceBody = MuiStyled(Box)({
  height: "100%",
  padding: "10px",
  boxSizing: "border-box",
});

export const ChoiceRestrictedBox = MuiStyled(Box)({
  backgroundColor: "#f5f5f5",
  border: "2px solid #eee",
  padding: "15px",
  marginBottom: "10px",
  borderRadius: "10px",
});

export const OptionBox = MuiStyled(Box)({
  padding: "20px 10px",
  display: "flex",
  gap: "10px",
  alignItems: "center",
  border: "2px solid #eee",
  borderRadius: "10px",
  cursor: "pointer",
});

export const EtcOpinionBox = MuiStyled(Box)({
  padding: "0 10px",
  overflow: "hidden",
  transition: "0.5s",
});

export const ChoiceSubmitButton = MuiStyled(LoadingButton)({
  font: "600 18px Pretendard",
  padding: "15px 0",
  width: "50%",
  borderRadius: "10px",
  "&:active": { boxShadow: "none", transform: "translateY(4px)" },
});

// ? OpenEnded

export const OpenEndedBody = MuiStyled(Box)({
  height: "100%",
  padding: "10px",
  boxSizing: "border-box",
});
