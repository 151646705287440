import React, { useEffect, useRef, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";
import S from "../style.module.css";

const EighthPage = ({ resultObjectArray, userData, secondTypeArray }) => {
  const chartRef = useRef(null);
  const [userType, setUserType] = useState("");
  const [userTypeSplit, setUserTypeSplit] = useState("");
  useEffect(() => {
    if (resultObjectArray[4]?.name) {
      const name = resultObjectArray[4]?.name.split("(")[0];
      setUserType(resultObjectArray[4]?.name);
      setUserTypeSplit(name);
    }
  }, [resultObjectArray]);

  useEffect(() => {
    const ctx = document.getElementById("radarChartFirst").getContext("2d");
    if (chartRef.current) {
      chartRef.current.destroy();
    }
    const labels = resultObjectArray.map((item) => item.name);
    const data = resultObjectArray.map((item) => item.num);

    chartRef.current = new Chart(ctx, {
      type: "radar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "검사 결과",
            data: data,
            backgroundColor: "rgba(8,31,92,0.5)",
            borderColor: "rgba(8,31,92,1)",
            borderWidth: 1,
            pointBackgroundColor: "rgba(8,31,92,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(8,31,92,1)",
          },
        ],
      },
      options: {
        scales: {
          r: {
            min: 0,
            max: 77,
            ticks: {
              display: false,
            },
          },
        },
        plugins: {
          datalabels: {
            display: true,
            color: "#081f5c",
            align: 'end',
            offset: 5,
            anchor: 'end',
            font: {
              size: 18,
            },
            formatter: function (value) {
              return value;
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });

    // 컴포넌트 언마운트 시 정리합니다
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [resultObjectArray]);

  return (
    <div className={S.Page}>
      <div className={S.PageHeader}>HEXA GRAM Personality Profile</div>
      <div className={S.PageTitle}>
        <div className={S.TitlePart} style={{ backgroundColor: "#B02418" }}>
          Part IⅤ
        </div>
        <p className={S.TitlePartName}>{userData.name} 님의 2차 타입</p>
      </div>
      <div className={S.UserTypeInfo}>
        <div>
          <canvas id="radarChartFirst"></canvas>
        </div>
        <div className={S.UserTypeName}>
          <div>
            2차 타입 <br />: {userType}
          </div>
        </div>
      </div>
      <div className={S.ProsAndConsTitle}>
        <div>
          {userData.name} 님의 장·단점_<span style={{ color: "#B02418" }}>{userTypeSplit}</span>
        </div>
      </div>
      <div className={S.ProsAndConsContent}>
        <table>
          <thead>
            <tr>
              <th>장점</th>
              <th>단점</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{secondTypeArray[2]?.pros}</td>
              <td>{secondTypeArray[2]?.cons}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={S.PageFooter}>
      <p>All right reserved by Withplus Inc. copyright</p>
      </div>
    </div>
  );
};

export default EighthPage;
