import React, { useContext, useEffect, useState, useRef } from "react";

import { useParams } from "react-router-dom";
import { DndContext, useSensors, useSensor, PointerSensor, DragOverlay } from "@dnd-kit/core";
import { arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { Box, Button, Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";

import * as S from "../styles";

import { ManagerContext } from "views/LiveBoard/contexts/Manager";
import { Sortable } from "components/DragDrop/Sortable";
import { SortableBox, SortableMasonry } from "components/DragDrop/Droppable";

import GroupHeader from "../components/GroupHeader";
import Card from "../components/Card";
import { Masonry } from "@mui/lab";

export default () => {
  const { gameId } = useParams();
  const ref = useRef(null);
  const { list, layout, sections, socket, setList, handlePostModal } = useContext(ManagerContext);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 5 },
    })
  );

  const [gridSize, setGridSize] = useState(6);
  const [cardSize, setCardSize] = useState("16%");
  const [isOverflow, setIsOverflow] = useState(false);
  const [draggingItem, setDraggingItem] = useState(null);

  const [postList, setPostList] = useState([]);

  const addGroup = () => {
    socket.emit("addGroup", { gameId });
  };

  const onDragStart = (e) => {
    const { id } = e.active;

    const item = list.find((item) => item.id === id);

    setDraggingItem(item);
  };

  const onDragOver = (e, type = undefined) => {
    const { active, over } = e;

    if (!over) return;

    if (type === "section") {
      const { containerId: id } = active.data.current.sortable;
      const overId = over.data.current?.sortable.containerId || over.id;

      const activeGroup = postList.find((section) => section.id === id);
      const overGroup = postList.find((section) => section.id === overId);

      if (!activeGroup || !overGroup) return;

      const activeItem = activeGroup.list.find((item) => item.id === active.id);

      if (activeGroup !== overGroup) {
        setPostList((prev) => {
          return prev.map((group) => {
            if (group.id === activeGroup.id) {
              return { ...group, list: group.list.filter((item) => item.id !== active.id) };
            }

            if (group.id === overGroup.id) {
              return { ...group, list: [...group.list, { ...activeItem, section: overId }] };
            }

            return group;
          });
        });
      }

      if (over.data.current) {
        setDraggingItem({
          ...draggingItem,
          index: over.data.current ? over.data.current.sortable.index : 0,
        });
      }
    } else {
      const overItem = list.find((item) => item.id === over.id);

      if (!overItem) return;

      setDraggingItem({ ...draggingItem, index: over.data.current.sortable.index });
    }
  };

  const onDragEnd = (e, type = undefined) => {
    setDraggingItem(null);

    const { active, over } = e;

    if (!over) return;

    if (type === "section") {
      const { id } = active;
      const { id: overId } = over;
      const { containerId } = active.data.current.sortable;
      const activeGroup = postList.find((section) => section.id === containerId);

      if (!activeGroup) return;

      const activeIndex = activeGroup.list.findIndex((item) => item.id === id);
      const overIndex = activeGroup.list.findIndex((item) => item.id === overId);

      const result = arrayMove(activeGroup.list, activeIndex, overIndex);

      let newPostList = postList.map((section) => {
        if (section.id === containerId) {
          return { ...section, list: result };
        }

        return section;
      });

      setPostList(newPostList);

      let newList = newPostList.reduce((acc, cur) => {
        return acc.concat(cur.list);
      }, []);

      setList(newList);

      socket.emit("sortPosts", { gameId, list: newList });

      return;
    }

    const activeIndex = list.findIndex((item) => item.id === active.id);
    const overIndex = list.findIndex((item) => item.id === over.id);

    const result = arrayMove(list, activeIndex, overIndex);

    setList(result);

    socket.emit("sortPosts", { gameId, list: result });
  };

  useEffect(() => {
    if (!layout.type) return;

    if (layout.postSize === "big") {
      setGridSize(4);
      setCardSize("25%");
    } else {
      setGridSize(6);
      setCardSize("16%");
    }
  }, [layout]);

  useEffect(() => {
    if (sections.length === 0 || !layout.type) return;

    if (layout.section) {
      let newPostList = [];

      for (let section of sections) {
        let sectionList = list.filter((item) => item.section === section.id);

        newPostList.push({ ...section, list: sectionList });
      }

      setPostList(newPostList);
    }
  }, [layout, sections, list]);

  useEffect(() => {
    const element = ref.current;

    if (element) {
      const isOverflow = element.scrollWidth > element.clientWidth;
      setIsOverflow(isOverflow);
    }
  });

  if (layout.section) {
    if (layout.sort === "likes") {
      return (
        <Box sx={{ position: "relative", height: "100%" }}>
          <Box
            ref={ref}
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: isOverflow ? "flex-start" : "center",
              gap: "20px",
              overflow: "auto",
              "::-webkit-scrollbar": { height: "12.5px" },
            }}
          >
            {postList.map((section) => (
              <Box
                key={section.id}
                sx={{
                  height: "100%",
                  width: `calc(${cardSize} + 20px)`,
                  display: "flex",
                  flexDirection: "column",
                  gap: "7.5px",
                  boxSizing: "border-box",
                  flexShrink: 0,
                }}
              >
                <GroupHeader section={section} />

                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#888",
                    font: "700 16px Pretendard",
                    "&:hover": { bgcolor: "#777" },
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={handlePostModal}
                  id={section.id}
                >
                  <AddIcon />
                  게시물 등록하기
                </Button>

                <Box
                  sx={{
                    flex: 1,
                    overflow: "auto",
                    overflowX: "hidden",
                    p: "10px",
                    height: "100%",
                    boxShadow: "inset 0 0 10px #00000033",
                    bgcolor: "#eee",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {section.list
                    .sort((a, b) => (b.likes?.length || 0) - (a.likes?.length || 0))
                    .map((item) => (
                      <Card key={item.id} item={item} />
                    ))}
                </Box>
              </Box>
            ))}

            <Button
              variant="contained"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                bgcolor: "#aaaaaa88",
                "&:hover": { bgcolor: "#88888888" },
              }}
              onClick={addGroup}
            >
              <AddIcon sx={{ fontSize: "42px", color: "#000" }} />

              <Typography sx={{ font: "700 16px Pretendard", color: "#000" }}>
                그룹 <br />
                추가하기
              </Typography>
            </Button>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ height: "100%", position: "relative" }}>
        <Box
          ref={ref}
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: isOverflow ? "flex-start" : "center",
            gap: "20px",
            overflow: "auto",
            "::-webkit-scrollbar": { height: "12.5px" },
          }}
        >
          <DndContext
            onDragStart={onDragStart}
            onDragOver={(e) => onDragOver(e, "section")}
            onDragEnd={(e) => onDragEnd(e, "section")}
            sensors={sensors}
          >
            {postList.map((section) => (
              <Box
                key={section.id}
                sx={{
                  height: "100%",
                  width: `calc(${cardSize} + 20px)`,
                  display: "flex",
                  flexDirection: "column",
                  gap: "7.5px",
                  boxSizing: "border-box",
                  flexShrink: 0,
                }}
              >
                <GroupHeader section={section} />

                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#888",
                    font: "700 16px Pretendard",
                    "&:hover": { bgcolor: "#777" },
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={handlePostModal}
                  id={section.id}
                >
                  <AddIcon />
                  게시물 등록하기
                </Button>

                <SortableBox
                  id={section.id}
                  sx={{
                    flex: 1,
                    overflow: "auto",
                    overflowX: "hidden",
                    p: "10px",
                    height: "100%",
                    boxShadow: "inset 0 0 10px #00000033",
                    bgcolor: "#eee",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                  items={section.list}
                  strategy={verticalListSortingStrategy}
                >
                  {section.list.map((item, index) => (
                    <Sortable
                      key={item.id}
                      id={item.id}
                      style={{
                        position: "relative",
                        p: "10px",
                        maxHeight: draggingItem ? "150px" : undefined,
                        overflow: draggingItem ? "hidden" : undefined,
                        flexShrink: 0,
                      }}
                      dragging-style={{
                        opacity: 0.5,
                      }}
                      is-dragging={draggingItem ? "true" : null}
                    >
                      <Card
                        key={item.id}
                        item={item}
                        index={index}
                        sort-mode={draggingItem ? "true" : null}
                      />
                    </Sortable>
                  ))}
                </SortableBox>
              </Box>
            ))}

            {draggingItem && (
              <DragOverlay>
                <Box
                  sx={{
                    maxHeight: "150px",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <Card item={draggingItem} sort-mode="true" />

                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bgcolor: "#c33c3c",
                      color: "#fff",
                      p: "5px",
                      fontWeight: "bold",
                      borderTopRightRadius: "8px",
                      borderBottomLeftRadius: "8px",
                    }}
                  >
                    {draggingItem.index}
                  </Box>
                </Box>
              </DragOverlay>
            )}
          </DndContext>

          <Button
            variant="contained"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              bgcolor: "#aaaaaa88",
              "&:hover": { bgcolor: "#88888888" },
            }}
            onClick={addGroup}
          >
            <AddIcon sx={{ fontSize: "42px", color: "#000" }} />

            <Typography sx={{ font: "700 16px Pretendard", color: "#000" }}>
              그룹 <br />
              추가하기
            </Typography>
          </Button>
        </Box>
      </Box>
    );
  }

  if (layout.sort === "likes") {
    return (
      <Box sx={{ position: "relative", height: "100%" }}>
        <Masonry columns={gridSize} spacing={0}>
          {list
            .sort((a, b) => (b.likes?.length || 0) - (a.likes?.length || 0))
            .map((item) => (
              <Box key={item.id} sx={{ px: "10px" }}>
                <Card item={item} />
              </Box>
            ))}
        </Masonry>
      </Box>
    );
  }

  return (
    <Box sx={{ position: "relative", height: "100%" }}>
      <DndContext
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragEnd={onDragEnd}
        sensors={sensors}
      >
        <SortableMasonry columns={gridSize} spacing={0} items={list} id="masonry">
          {list.map((item) => (
            <Sortable
              key={item.id}
              id={item.id}
              style={{
                p: "10px",
                position: "relative",
                height: draggingItem ? "300px" : undefined,
                overflow: draggingItem ? "hidden" : undefined,
              }}
              dragging-style={{
                opacity: 0.5,
              }}
              is-dragging={draggingItem ? "true" : null}
            >
              <Card item={item} sort-mode={draggingItem ? "true" : null} />
            </Sortable>
          ))}
        </SortableMasonry>

        {draggingItem && (
          <DragOverlay>
            <Box sx={{ maxHeight: "300px", overflow: "hidden", position: "relative" }}>
              <Card item={draggingItem} sort-mode="true" />

              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bgcolor: "#c33c3c",
                  color: "#fff",
                  p: "5px",
                  fontWeight: "bold",
                  borderTopRightRadius: "8px",
                  borderBottomLeftRadius: "8px",
                }}
              >
                {draggingItem.index}
              </Box>
            </Box>
          </DragOverlay>
        )}
      </DndContext>
    </Box>
  );
};
