import { styled as MuiStyled } from "@mui/material/styles";
import { Box, Menu, MenuItem } from "@mui/material";

// * 보드 리스트 페이지 스타일
export const Body = MuiStyled(Box)({
  paddingTop: "80px",
  minHeight: "100vh",
  height: "0px",
  backgroundColor: "#f5f5f5",
  boxSizing: "border-box",
  overflow: "auto",
});

// * 보드 리스트 페이지 메인 컨테이너
export const Main = MuiStyled(Box)({
  height: "100%",
  width: "87.5%",
  margin: "auto",
  padding: "2vh 0",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
});

// * 보드 리스트 페이지 보드 리스트 컨테이너
export const MyListBox = MuiStyled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "0 10px",

  // * 보드 리스트 헤더
  "> #header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    // * 보드 리스트 헤더 타이틀
    "> p": {
      font: "bold 25px Pretendard",
    },

    // * 보드 리스트 헤더 버튼
    "> button": {
      font: "600 18px Pretendard",
      padding: "10px 0",
    },
  },

  // * 보드 리스트
  "> #board-list": {
    flex: 1,
    marginTop: "8px",
  },
});

export const BoardNameBox = MuiStyled(Box)({
  cursor: "pointer",
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "10px",

  "> svg": {
    color: "#9a9a9a",
    "&:hover": { color: "#ababab" },
  },

  "> p": {
    font: "600 16px Pretendard",
    color: "#222",
  },
});

export const BoardActionMenu = MuiStyled((props) => (
  <Menu
    PaperProps={{
      style: {
        width: "200px",
        boxShadow: "1px 1px 2px #00000020",
        padding: "0 10px",
        borderRadius: "10px",
        boxSizing: "border-box",
      },
    }}
    {...props}
  />
))({});

export const Item = MuiStyled(MenuItem)({
  marginBottom: "5px",
  display: "flex",
  gap: "10px",

  "> p": {
    font: "600 16px Pretendard",
  },
});
