import React, { useContext, useEffect, useRef, useState } from "react";
import { FullScreen } from "react-full-screen";
import { QRCode } from "react-qr-svg";
import { useParams } from "react-router-dom";

import { Box, Button, Drawer, Modal, Typography } from "@mui/material";

import QrCode2Icon from "@mui/icons-material/QrCode2";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "@mui/icons-material/Help";
import AddIcon from "@mui/icons-material/Add";

import * as S from "./styles";

import { FullScreenContext, FullScreenProvider } from "contexts/FullScreen";
import { ManagerContext, ManagerProvider } from "views/LiveBoard/contexts/Manager";
import { BoardSectionSortModal, LoadingModal, QRCodeWideModal } from "components/Modal";

import { SnackBarContext } from "components/SnackBar/ContextAPI";
import Settings from "./components/Settings";

import Wall from "./layout/Wall";
import GridLayout from "./layout/Grid";
import TimeLine from "./layout/TimeLine";

import logo from "assets/images/wLiveLogo(Red).png";
import { CardModal, PostModal } from "./components/Modal";

export default () => {
  return (
    <ManagerProvider>
      <FullScreenProvider>
        <Main />
      </FullScreenProvider>
    </ManagerProvider>
  );
};

const Main = () => {
  const { gameId } = useParams();
  const { handle, handleFullScreen } = useContext(FullScreenContext); // * 전체화면 컨텍스트
  const {
    code,
    isSocketDisconnected,
    name,
    explain,
    userURL,
    layout,
    sections,
    isSortSectionModalOpened,
    isCardModalOpened,
    handleSortSectionModal,
    handleCardModal,
    socket,
    postModalOpened,
    handlePostModal,
  } = useContext(ManagerContext); // * 매니저 컨텍스트
  const SnackBar = useContext(SnackBarContext);

  const [open, setOpen] = useState(false);
  const [isQrCodeOpened, setIsQrCodeOpened] = useState(false);
  const [QRModalOpened, setQRModalOpened] = useState(false); // * QR코드 모달 오픈 여부

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleQrCode = () => {
    setIsQrCodeOpened(!isQrCodeOpened);
  };

  // * QR코드 모달 핸들러
  const handleQRcodeModal = () => {
    setQRModalOpened(!QRModalOpened); // * QR코드 모달 오픈 여부 토글
  };

  const sortSections = (sortedSections) => {
    if (sortedSections === sections) return;

    socket.emit("sortSections", { gameId, sections: sortedSections }, (success) => {
      if (success) {
        handleSortSectionModal();

        SnackBar.setOpen(true);
        SnackBar.setText("그룹 정렬이 완료되었습니다.");
        SnackBar.setSeverity("success");
      }
    });
  };

  const openHelp = () => {
    if (handle.active) {
      handle.exit();

      setTimeout(() => {
        window.open(
          "https://withplus-live.notion.site/Live-Board-97890159fce64d6db18f7abb7e616a71",
          "_blank",
          "width=600, height=800"
        );
      }, 700);
    } else {
      window.open(
        "https://withplus-live.notion.site/Live-Board-97890159fce64d6db18f7abb7e616a71",
        "_blank",
        "width=600, height=800"
      );
    }
  };

  useEffect(() => {
    document.title = `Live Board | ${name}`;
  }, [name]);

  return (
    <S.Body>
      <S.Main>
        <FullScreen handle={handle}>
          <S.Board>
            <S.Logo>
              <img src={logo} alt="logo" />

              <Typography>
                {"Withplus Live - "}

                <span>라이브 보드</span>
              </Typography>
            </S.Logo>

            <S.MainContainer>
              <Box className="main-header">
                <Typography className="title">{name}</Typography>
                <Typography className="sub-title">{explain}</Typography>

                {!layout?.section && (
                  <Box sx={{ position: "absolute", bottom: 5, right: 5 }}>
                    <Button
                      variant="contained"
                      sx={{
                        font: "600 18px Pretendard",
                        bgcolor: "#888",
                        "&:hover": { bgcolor: "#777" },
                      }}
                      onClick={handlePostModal}
                    >
                      <AddIcon />
                      게시물 등록하기
                    </Button>
                  </Box>
                )}
              </Box>

              {(() => {
                switch (layout.type) {
                  case "wall":
                    return <Wall />;
                  case "grid":
                    return <GridLayout />;
                  case "timeline":
                    return <TimeLine />;
                  default:
                    return <></>;
                }
              })()}
            </S.MainContainer>

            <S.QRCodeContainer
              sx={{ transform: isQrCodeOpened ? "translate(0%, 0%)" : "translate(100%, 0%)" }}
            >
              <Box className="qrcode" onClick={handleQRcodeModal}>
                <QRCode value={userURL} />
              </Box>

              <Box className="access-code">
                <Typography sx={{ fontSize: "28px", color: "#797979" }}>접속코드</Typography>

                <Typography sx={{ fontSize: "40px", color: "#B43F3F" }}>
                  {code.slice(0, 3)} {code.slice(3, 6)}
                </Typography>
              </Box>
            </S.QRCodeContainer>

            <S.MenuContainer>
              <S.ToolTip title="전체화면">
                <S.MenuButton onClick={handleFullScreen}>
                  <FullscreenIcon />
                </S.MenuButton>
              </S.ToolTip>

              <S.ToolTip title="QR코드">
                <S.MenuButton onClick={handleQrCode}>
                  <QrCode2Icon />
                </S.MenuButton>
              </S.ToolTip>

              <S.ToolTip title="설정">
                <S.MenuButton onClick={toggleDrawer}>
                  <SettingsIcon />
                </S.MenuButton>
              </S.ToolTip>

              <S.ToolTip title="도움말">
                <S.MenuButton onClick={openHelp}>
                  <HelpIcon />
                </S.MenuButton>
              </S.ToolTip>
            </S.MenuContainer>
          </S.Board>

          <Drawer
            anchor="right"
            open={open}
            onClose={toggleDrawer}
            ModalProps={{ disablePortal: true }}
            PaperProps={{ sx: { bgcolor: "#00000000" } }}
          >
            <Settings onClose={toggleDrawer} />
          </Drawer>

          <LoadingModal open={isSocketDisconnected} />

          {postModalOpened && <PostModal open={true} onClose={handlePostModal} />}

          {/* QR코드 모달 */}
          <QRCodeWideModal
            open={QRModalOpened}
            handleModal={handleQRcodeModal}
            ModalQRCode={userURL}
          />

          {isSortSectionModalOpened && (
            <BoardSectionSortModal
              open={isSortSectionModalOpened}
              sections={sections}
              onClose={handleSortSectionModal}
              action={sortSections}
            />
          )}

          {isCardModalOpened && <CardModal open={isCardModalOpened} onClose={handleCardModal} />}
        </FullScreen>
      </S.Main>
    </S.Body>
  );
};
