import { styled } from "styled-components";

export const Features = styled.div`
  margin-top: 20px;
`;

export const Feature = styled.div`
  display: flex;
  ${(props) => props.hidden && "display: none;"}
  align-items: center;
  gap: 10px;
  ${(props) => !props.$service && "margin-bottom : 5px;"}
  font: 14px NotoSansKR-Regular;
  color: ${(props) => (props.$service ? "#909090" : "#000")};
  word-break: keep-all;
`;

export const Circle = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #909090;
`;
