import { styled } from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";
import { Box, Typography, Container, Button } from "@mui/material";

export const Body = MuiStyled((props) => (
  <Box
    sx={{
      pt: "75px",
      height: "100vh",
      boxSizing: "border-box",

      "@media (max-width: 1000px)": {
        bgcolor: "#f5f5f5",
        height: "auto",
      },
    }}
    {...props}
  />
))({});

export const container = MuiStyled((props) => (
  <Container
    sx={{
      bgcolor: "white",
      minWidth: "315px",
    }}
    {...props}
  />
))({});

export const Intro = MuiStyled((props) => (
  <Box
    sx={{
      backgroundSize: "cover",
      width: "100%",
      maxWidth: "1920px",
      aspectRatio: "1920/750",
      position: "relative",
      margin: "0 auto",
      imageRendering: "-webkit-optimize-contrast",
      backfaceVisibility: "hidden",
      transform: "translateZ(0)",
      boxShadow: "0px 3px 6px #00000029",

      "@media (max-width: 1000px)": {
        backgroundSize: "100% 100%",
        aspectRatio: "444/500",
      },
    }}
    {...props}
  />
))({});

export const IntroTitle = MuiStyled((props) => (
  <Typography
    sx={{
      position: "absolute",
      top: "26.8%",
      left: "12.5%",
      font: "bold 100px Pretendard",
      fontSize: "clamp(52px, 5.208vw, 100px)",
      color: "#202020",

      "@media (max-width: 1000px)": {
        top: "22%",
        fontSize: "clamp(35px, 11.26vw, 50px)",
      },
    }}
    {...props}
  />
))({});

export const IntroExplain1 = MuiStyled((props) => (
  <Typography
    sx={{
      position: "absolute",
      top: "54.6667%",
      left: "12.5%",
      font: "500 1.14vw Pretendard",
      fontSize: "clamp(11px, 1.14vw, 22px)",

      "@media (max-width: 1000px)": {
        top: "37.5%",
        fontSize: "clamp(10px, 3.153vw, 14px)",
      },
    }}
    {...props}
  />
))({});

export const IntroExplain2 = MuiStyled((props) => (
  <Typography
    sx={{
      position: "absolute",
      top: "63.7333%",
      left: "12.5%",
      font: "500 1.14vw Pretendard",
      fontSize: "clamp(11px, 1.14vw, 22px)",

      "@media (max-width: 1000px)": {
        top: "47.5%",
        fontSize: "clamp(10px, 3.153vw, 14px)",
      },
    }}
    {...props}
  />
))({});

export const IntroButton = MuiStyled((props) => (
  <Button
    variant="contained"
    sx={{
      position: "absolute",
      top: "79.3333%",
      left: "12.5%",
      background: "linear-gradient(97.7deg, #c60f0f 1.23%, #630808 134.48%)",
      font: "600 1.35vw Pretendard",
      fontSize: "clamp(13px, 1.35vw, 26px)",
      borderRadius: "13px",
      transition: "transform 0.3s",
      width: "9.89583%",
      height: "9.0667%",

      "&:hover": {
        transform: "scale(1.05)",
      },
      "&:active": {
        transform: "scale(1.05) translateY(4px)",
      },

      "@media (max-width: 1000px)": {
        width: "auto",
        top: "65%",
        fontSize: "clamp(14px, 4.5vw, 20px)",
      },
    }}
    {...props}
  />
))({});

export const IntroCharacter = styled.img`
  position: absolute;
  top: 22.8%;
  left: 60.364%;
  width: 27.2916%;
  height: 63.2%;

  @media (max-width: 1000px) {
    top: 57.5%;
    left: 47.5%;
    width: 50%;
    height: auto;
  }
`;

export const LiveBoardBox = MuiStyled((props) => (
  <Box
    sx={{
      textAlign: "center",
      bgcolor: "#f0cbc6",
      font: "16px Pretendard-Regular",
      color: "#c91f5f",
      padding: "3px clamp(10px, 1.042vw, 20px)",
      borderRadius: "14px",
      width: "max-content",
      fontSize: "clamp(8.3px, 0.83vw, 16px)",

      "@media (max-width: 1000px)": {
        fontSize: "clamp(11px, 3.6vw, 16px)",
        padding: "3px clamp(14.189px, 4.505vw, 18px)",
      },
    }}
    {...props}
  />
))({});

export const EffectContainer = MuiStyled((props) => (
  <Box
    sx={{
      mt: "95px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
    {...props}
  />
))({});

export const Title = MuiStyled((props) => (
  <Typography
    sx={{
      mt: "10px",
      font: "700 36px Pretendard",
      color: "#202020",
      fontSize: "clamp(18.75px, 1.875vw, 36px)",

      "@media (max-width: 1000px)": {
        fontSize: "clamp(25px, 8.1vw, 36px)",
      },
    }}
    {...props}
  />
))({});

export const Explain = MuiStyled((props) => (
  <Typography
    sx={{
      mt: "15px",
      font: "500 20px Pretendard",
      color: "#343434",
      textAlign: "center",
      fontSize: "clamp(10px, 1.041vw, 20px)",

      "@media (max-width: 1000px)": {
        fontSize: "clamp(11px, 3.6vw, 16px)",
      },
    }}
    {...props}
  />
))({});

export const EffectBox = MuiStyled((props) => (
  <Box
    sx={{
      mt: "25px",
      width: "100%",
      display: "flex",
      gap: "4.0625%",
      flexWrap: "wrap",
      justifyContent: "center",
      padding: "25px 0",

      "> .swiper": {
        padding: "10px 0",
        paddingBottom: "50px",
      },

      "> .swiper .swiper-wrapper .swiper-slide": {
        display: "flex",
        justifyContent: "center",
      },

      "> .swiper .swiper-button-prev, > .swiper .swiper-button-next": {
        width: "clamp(27px , 9vw, 40px)",
        height: "clamp(27px , 9vw, 40px)",
      },

      "> .swiper .swiper-button-prev:after, > .swiper .swiper-button-next:after": {
        width: "clamp(27px , 9vw, 40px)",
        height: "clamp(27px , 9vw, 40px)",

        backgroundColor: "#2f3542",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: "clamp(14px ,4.5vw,20px)",
        fontWeight: "bold",
        cursor: "pointer",
      },
    }}
    {...props}
  />
))({});

export const EffectItem = MuiStyled((props) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "clamp(20px, 2.08vw, 40px)",
      width: "clamp(130px, 13.02vw, 250px)",
      height: "clamp(130px, 13.02vw, 250px)",
      borderRadius: "30px",
      boxShadow: "0px 3px 6px #0000004d",

      "@media (max-width: 1000px)": {
        width: "clamp(177px, 56.306vw, 250px)",
        height: "clamp(177px, 56.306vw, 250px)",
        gap: "clamp(28px, 9vw, 40px)",
      },
    }}
    {...props}
  />
))({});

export const EffectItemIcon = styled.img`
  width: clamp(33.9px, 3.385vw, 65px);

  @media (max-width: 1000px) {
    width: clamp(46.2px, 14.64vw, 65px);
  }
`;

export const EffectItemDescription = MuiStyled((props) => (
  <Typography
    sx={{
      font: "600 18px Pretendard",
      color: "#343434",
      textAlign: "center",
      fontSize: "clamp(9.375px, 0.9375vw, 18px)",

      "@media (max-width: 1000px)": {
        fontSize: "clamp(13px, 4.05vw, 18px)",
      },
    }}
    {...props}
  />
))({});

export const ConnectionScreenContainer = MuiStyled((props) => (
  <Box
    sx={{
      m: "80px auto",
      bgcolor: "#ebebeb",
      width: "100%",
      maxWidth: "1920px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "100px 0",
    }}
    {...props}
  />
))({});

export const ConnectionScreenBox = MuiStyled((props) => (
  <Box
    sx={{
      mt: props.id === "user" ? "150px" : null,
      p: "25px 0",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      width: "100%",
    }}
    {...props}
  />
))({});

export const ConnectionScreenTitle = MuiStyled((props) => (
  <Typography
    sx={{
      mt: "10px",
      font: "700 36px Pretendard",
      color: "#202020",
      fontSize: "clamp(18.75px, 1.875vw, 36px)",

      "@media (max-width: 1000px)": {
        fontSize: "clamp(25px, 8.1vw, 36px)",
      },
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplain = MuiStyled((props) => (
  <Typography
    sx={{
      mt: "30px",
      font: "500 20px Pretendard",
      color: "#343434",
      textAlign: "center",
      wordBreak: "keep-all",
      fontSize: "clamp(10.42px, 1.042vw, 20px)",

      "@media (max-width: 1000px)": {
        fontSize: "clamp(11px, 3.6vw, 16px)",
      },
    }}
    {...props}
  />
))({});

export const ConnectionScreenImgBox = MuiStyled((props) => (
  <Box
    sx={{
      mt: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "clamp(215.1px, 21.51vw, 413px)",
      height: "clamp(252.6px, 25.26vw, 485px)",
      backgroundColor: "#fff",
      borderRadius: "30px",
      boxShadow: "0px 3px 6px #00000029",

      "> img": {
        width:
          props.id === "user"
            ? "clamp(170.83px, 17.083vw, 328px)"
            : "clamp(159.375px, 15.9375vw, 306px)",
        height:
          props.id === "user"
            ? "clamp(180.73px, 18.073vw, 347px)"
            : "clamp(144.27px, 14.427vw, 277px)",
      },

      "@media (max-width: 1000px)": {
        width: "60%",
        height: "auto",
        aspectRatio: "413/485",

        "> img": {
          width: "80%",
          height: "auto",
        },
      },
    }}
    {...props}
  />
))({});

export const ConnectionScreenButton = MuiStyled((props) => (
  <Button
    variant="contained"
    sx={{
      mt: props.target === "true" ? "20px" : "50px",
      background: "linear-gradient(97.7deg, #c60f0f 1.23%, #630808 134.48%)",
      font: "700 26px Pretendard",
      padding: "clamp(10.4px, 1.04vw, 20px) clamp(15.625px, 1.5625vw, 30px)",
      borderRadius: "13px",
      transition: "transform 0.3s",
      fontSize: "clamp(13px, 1.35vw, 26px)",

      "&:hover": {
        transform: "scale(1.05)",
      },
      "&:active": {
        transform: "scale(1.05) translateY(4px)",
      },

      "@media (max-width: 1000px)": {
        padding: "clamp(14.189px, 4.505vw, 18px) clamp(21.283px, 6.76vw, 30px)",
        fontSize: "clamp(14px, 4.5vw, 20px)",
      },
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplainsContainer = MuiStyled((props) => (
  <Box
    sx={{
      position: props.target === "true" ? "static" : "absolute",
      width: props.target === "true" ? "auto" : "clamp(596.35px, 59.635vw, 1145px)",
      height: props.target === "true" ? "auto" : "clamp(178.65px, 17.865vw, 343px)",
      top: "35%",
      left: "21.1458%",
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplainsBox = MuiStyled((props) => (
  <Box
    sx={{
      position: "relative",
      width: "100%",
      height: "100%",
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplainItem = MuiStyled((props) => (
  <Box
    sx={{
      position: props.target === "true" ? "static" : "absolute",
      top: props.id === "1" || props.id === "3" ? 0 : null,
      bottom: props.id === "2" || props.id === "4" ? 0 : null,
      left: props.id === "1" || props.id === "2" ? 0 : "75.052%",
      my: props.target === "true" ? "20px" : null,
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplainItemTitleBox = MuiStyled((props) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "10px",
    }}
    {...props}
  />
))({});

export const RedCircle = MuiStyled((props) => (
  <Box
    sx={{
      bgcolor: "#c60f0f",
      width: "clamp(14.58px, 1.458vw, 28px)",
      height: "clamp(14.58px, 1.458vw, 28px)",
      borderRadius: "50%",
      color: "#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      font: "600 20px Pretendard",
      fontSize: "clamp(10px, 1.041vw, 20px)",

      "@media (max-width: 1000px)": {
        width: "clamp(20px, 6.4vw, 28px)",
        height: "clamp(20px, 6.4vw, 28px)",
        fontSize: "clamp(14.18px, 4.5vw, 20px)",
      },
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplainItemTitle = MuiStyled((props) => (
  <Typography
    sx={{
      font: "700 20px Pretendard",
      color: "#343434",
      fontSize: "clamp(10.41px, 1.041vw, 20px)",

      "@media (max-width: 1000px)": {
        fontSize: "clamp(14.18px, 4.5vw, 20px)",
      },
    }}
    {...props}
  />
))({});

export const ConnectionScreenExplainItemDescription = MuiStyled((props) => (
  <Typography
    sx={{
      mt: "10px",
      ml: "10px",
      font: "500 20px Pretendard",
      fontSize: "clamp(10.41px, 1.041vw, 20px)",
      color: "#343434",

      "@media (max-width: 1000px)": {
        fontSize: "clamp(12.77px, 4.05vw, 18px)",
      },
    }}
    {...props}
  />
))({});
