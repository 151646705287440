import managerImg from "assets/images/ContentPage/LiveQuiz/managerImg.png";
import userImg from "assets/images/ContentPage/LiveQuiz/userImg.png";
import icon1 from "assets/images/ContentPage/LiveQuiz/icon1.png";
import icon2 from "assets/images/ContentPage/LiveQuiz/icon2.png";
import icon3 from "assets/images/ContentPage/LiveQuiz/icon3.png";
import icon4 from "assets/images/ContentPage/LiveQuiz/icon4.png";
// import wordCloudIcon from "assets/images/ContentPage/LivePolling/wordCloudIcon.png";
// import choiceIcon from "assets/images/ContentPage/LivePolling/choiceIcon.png";
// import barIcon from "assets/images/ContentPage/LivePolling/barIcon.png";
// import circleIcon from "assets/images/ContentPage/LivePolling/circleIcon.png";
// import donutIcon from "assets/images/ContentPage/LivePolling/donutIcon.png";

export const benefitData = [
  {
    id: 1,
    description: ["다양한 주제로", "활용 가능"],
    img: icon1,
  },
  {
    id: 2,
    description: ["학습자의", "이해도 확인"],
    img: icon2,
  },
  {
    id: 3,
    description: ["퀴즈형식으로", "참여도 향상"],
    img: icon3,
  },
  {
    id: 4,
    description: ["경쟁심을 통한", "집중 유도"],
    img: icon4,
  },
];

export const connectionScreenData = [
  {
    id: "manager",
    title: "진행자용 접속화면",
    explain: [
      "생성한 질문은 Live Quiz의 My List에서 확인 가능",
      "객관식 타입과 OX퀴즈 타입 중 선택",
    ],
    img: managerImg,
    button: "메뉴얼 다운받기",
    explains: [
      {
        id: 1,
        title: "Withplus.Live 접속",
        description: ["위드플러스 라이브에 접속 후", "로그인 - [마이페이지] - [Live Quiz]"],
      },
      {
        id: 2,
        title: "문제 생성",
        description: [
          "출제하고자 하는 문제와 답안 입력",
          "문제 유형과 옵션을 설정하여",
          "한 세트의 퀴즈를 생성",
        ],
      },
      {
        id: 3,
        title: "QR코드 배포",
        description: ["QR코드와 접속코드 배포, 스캔 요청 후", "참여자의 라이브 퀴즈 접속현황 확인"],
      },
      {
        id: 4,
        title: "퀴즈 진행 및 결과 공개",
        description: [
          "퀴즈 진행 중, 중간 순위 및 점수 확인",
          "한 세트 종료 후 최종 순위 및 점수 공개",
        ],
      },
    ],
  },
  {
    id: "user",
    title: "참여자용 접속화면",
    explain: [
      "모바일 접속 -> QR코드로 접속 or 코드 넘버 입력",
      "PC, 노트북 접속 -> Withplus.Live 페이지 -> 우측 상단의 접속코드 클릭 -> 코드 넘버 입력",
    ],
    img: userImg,
    button: "메뉴얼 다운받기",
    explains: [
      {
        id: 1,
        title: "QR 접속",
        description: ["진행자가 배포한 QR코드 스캔"],
      },
      {
        id: 2,
        title: "접속 코드 입력",
        description: ["접속 코드 입력 후", "라이브 퀴즈에 접속"],
      },
      {
        id: 3,
        title: "퀴즈 참여",
        description: ["제한 시간 안에 퀴즈 답변 제출"],
      },
      {
        id: 4,
        title: "진행자 화면에서 결과 확인",
        description: ["최종 결과 화면에서", "순위와 점수 확인"],
      },
    ],
  },
];

// export const typeDescriptionData = [
//   {
//     id: 1,
//     title: "Word Cloud",
//     description: [
//       "- 참여자들의 주관식 응답을 실시간으로 수집",
//       "- 질문에 대한 짧은 응답이 시각적으로 구성",
//       "- 참여자들의 자유로운 의견 표출 용도로 적합",
//     ],
//     img: wordCloudIcon,
//   },
//   {
//     id: 2,
//     title: "Choice",
//     description: [
//       "- 주어진 보기 중 응답을 선택하는 객관식 질문",
//       "- 3가지 유형의 결과 레이아웃 제공",
//       "- 분석이 수월한 결과 데이터 제공",
//     ],
//     img: choiceIcon,
//   },
// ];

// export const choiceData = [
//   {
//     id: 1,
//     title: "막대 그래프",
//     description: "항목별 응답 수를 막대 모양으로 나타냄",
//     img: barIcon,
//   },
//   {
//     id: 2,
//     title: "원형 그래프",
//     description: "전체 응답에 대한 각 항목의 비율을 원 모양으로 나타냄",
//     img: circleIcon,
//   },
//   {
//     id: 3,
//     title: "도넛 그래프",
//     description: "전체 응답에 대한 각 항목의 비율을 도넛 모양으로 나타냄",
//     img: donutIcon,
//   },
// ];
