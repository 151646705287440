import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Checkbox, Container, Divider, IconButton, Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import * as S from "./style";
import { Context } from "../context";
import { CouponModal } from "../components/Modal";
import { TermOfUseModal } from "components/Modal";

const Layout = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { V, F } = useContext(Context);
  const [modal, setModal] = useState(false);
  // const [card, setCard] = useState("신용카드");

  useEffect(() => {
    if (!state) {
      alert("잘못된 접근입니다.");
      navigate("/");
      return;
    }
  }, []);

  const handleModal = () => {
    setModal(!modal);
  };

  // const handleCard = (e) => {
  //   setCard(e.target.value);
  // };

  // return (
  //   <Box
  //     sx={{
  //       pt: "100px",
  //       bgcolor: "#f5f5f5",
  //       // height: "100%",
  //       height: "100vh",
  //       boxSizing: "border-box",
  //       pb: "20px",
  //       overflow: "auto",
  //     }}
  //   >
  //     <Container
  //       maxWidth="sm"
  //       disableGutters
  //       sx={{
  //         // height: "100%",
  //         textAlign: "left",
  //         bgcolor: "#fff",
  //         borderRadius: "10px",
  //         boxShadow: "0px 0px 10px 0px #0000001a",
  //         display: "flex",
  //         flexDirection: "column",
  //         gap: "1rem",
  //         p: "1rem",
  //         overflow: "auto",
  //       }}
  //     >
  //       <Button
  //         sx={{
  //           width: "fit-content",
  //           "> span": {
  //             fontFamily: "NotoSansKR-Medium",
  //           },
  //           "&:hover": { transform: "scale(1.1)" },
  //         }}
  //         onClick={F.goBackPage}
  //       >
  //         <ArrowBackIcon />
  //         <span>뒤로가기</span>
  //       </Button>

  //       <Typography sx={{ font: "bold 22px NotoSansKR-Regular" }}>
  //         위드플러스 라이브 구독결제
  //       </Typography>

  //       <Typography
  //         sx={{
  //           font: "16px NotoSansKR-Medium",
  //           color: "#c33c3c",
  //           wordBreak: "keep-all",
  //           textAlign: "center",
  //         }}
  //       >
  //         구독 결제 후 7일 이내에 환불이 가능하며, 서비스를 이용하신 경우 환불이 불가능 합니다.
  //       </Typography>

  //       <Box
  //         sx={{
  //           position: "relative",
  //           width: "90%",
  //           margin: "0 auto",
  //           border: "1px solid #eee",
  //           bgcolor: "#fafafa",
  //           p: "15px 20px",
  //           borderRadius: "10px",
  //           display: "flex",
  //           flexDirection: "column",
  //           gap: "10px",
  //         }}
  //       >
  //         <Typography sx={{ font: "bold 18px NotoSansKR-Regular", textAlign: "center" }}>
  //           구독 정보
  //         </Typography>

  //         <Typography sx={{ font: "bold 24px NotoSansKR-Extrabold" }}>
  //           {V.plan === "monthly" ? "베이식 (월간 결제)" : "베이식 (연간 결제)"}
  //         </Typography>

  //         <Typography sx={{ textAlign: "right" }}>
  //           {V.price && V.price.toLocaleString("ko-KR")} 원 / {V.plan === "monthly" ? "월 " : "년 "}
  //           (부가세 포함)
  //         </Typography>

  //         <Box
  //           sx={{
  //             position: "absolute",
  //             display: V.plan === "yearly" ? "block" : "none",
  //             top: "-10px",
  //             right: "-10px",
  //             zIndex: 1,
  //             bgcolor: "#c33c3c",
  //             color: "white",
  //             p: "0 10px",
  //             font: "bold 14px NotoSansKR-Regular",
  //             borderRadius: "6px",
  //           }}
  //         >
  //           약 16% 할인 *
  //         </Box>
  //       </Box>

  //       <Box
  //         sx={{
  //           border: "1px solid #eee",
  //           borderRadius: "10px",
  //           bgcolor: "#fafafa",
  //           p: "10px 20px",
  //         }}
  //       >
  //         <Typography
  //           sx={{
  //             font: "bold 18px NotoSansKR-Regular",
  //             color: "#000",
  //             textAlign: "center",
  //             "> span": { font: "14px NotoSansKR-Regular", color: "#c33c3c" },
  //           }}
  //         >
  //           개인정보 입력 <span>(* 필수항목)</span>
  //         </Typography>

  //         <S.InputContainer>
  //           <S.InputGrid>
  //             <div className="title">
  //               이름 <p>*</p>
  //             </div>

  //             <S.Input placeholder="이름을 입력해주세요." value={V.name} onChange={F.handleName} />
  //           </S.InputGrid>

  //           <S.InputGrid>
  //             <div className="title">
  //               연락처 <p>*</p>
  //             </div>

  //             <S.Input target="phone" value={V.phone} onChange={F.handlePhone} />
  //           </S.InputGrid>

  //           <S.InputGrid>
  //             <div className="title">소속</div>

  //             <S.Input value={V.company} onChange={F.handleCompany} />
  //           </S.InputGrid>

  //           <S.CheckBoxContainer>
  //             <Checkbox checked={V.isChecked} onChange={F.handleChecked} />
  //             본인의 개인 정보를 결제 서비스 업체 및 위드플러스 라이브에 제공하는데 동의합니다.
  //           </S.CheckBoxContainer>
  //         </S.InputContainer>
  //       </Box>

  //       <Box
  //         sx={{
  //           border: "1px solid #eee",
  //           borderRadius: "10px",
  //           bgcolor: "#fafafa",
  //           p: "10px 20px",
  //         }}
  //       >
  //         <Typography
  //           sx={{
  //             font: "bold 18px NotoSansKR-Regular",
  //             color: "#000",
  //             textAlign: "center",
  //           }}
  //         >
  //           결제 방법
  //         </Typography>

  //         <S.SelectBox>
  //           <S.SelectItem value="신용카드">신용카드 (국내)</S.SelectItem>
  //           <S.SelectItem value="1">국민</S.SelectItem>
  //         </S.SelectBox>
  //       </Box>
  //       <Box
  //         sx={{
  //           border: "1px solid #eee",
  //           borderRadius: "10px",
  //           bgcolor: "#fafafa",
  //           p: "10px 20px",
  //         }}
  //       >
  //         <Typography
  //           sx={{
  //             font: "bold 18px NotoSansKR-Regular",
  //             color: "#000",
  //             textAlign: "center",
  //           }}
  //         >
  //           결제 방법
  //         </Typography>

  //         <S.SelectBox>
  //           <S.SelectItem value="신용카드">신용카드 (국내)</S.SelectItem>
  //           <S.SelectItem value="1">국민</S.SelectItem>
  //         </S.SelectBox>
  //       </Box>
  //     </Container>
  //   </Box>
  // );

  return (
    <S.Body>
      <S.Header>
        <S.BackButton onClick={F.goBackPage}>
          <S.GoBackIcon /> 뒤로가기
        </S.BackButton>
        <S.Title>위드플러스 라이브 상품결제</S.Title>
        <S.Blank />
      </S.Header>

      <S.SubTitle>
        상품 결제 후 7일 이내에 환불이 가능하며, 서비스를 이용하신 경우 환불이 불가능 합니다.
      </S.SubTitle>

      <S.Content>
        <S.UserInfoGrid>
          <S.Paper data-id="userInfo">
            <S.PaperTitle>
              정보입력
              <p>(* 필수항목)</p>
            </S.PaperTitle>

            <S.InputContainer>
              <S.InputGrid>
                <div className="title">
                  이름 <p>*</p>
                </div>

                <S.Input
                  placeholder="이름을 입력해주세요."
                  value={V.name}
                  onChange={F.handleName}
                />
              </S.InputGrid>

              <S.InputGrid>
                <div className="title">
                  연락처 <p>*</p>
                </div>

                <S.Input target="phone" value={V.phone} onChange={F.handlePhone} />
              </S.InputGrid>

              <S.InputGrid>
                <div className="title">소속</div>

                <S.Input value={V.company} onChange={F.handleCompany} />
              </S.InputGrid>
            </S.InputContainer>

            <Box>
              <S.CheckBoxContainer>
                <Checkbox checked={V.isChecked[0]} onChange={F.handleChecked} id="0" />
                결제 서비스 업체 및 위드플러스 라이브에 본인 개인 정보 제공 동의
              </S.CheckBoxContainer>
              <S.CheckBoxContainer>
                <Checkbox checked={V.isChecked[1]} onChange={F.handleChecked} id="1" />
                구매조건(결제조건) 확인 및 결제진행 동의, 이용약관 동의
                <Box sx={{ flex: 1, textAlign: "right" }}>
                  <span
                    style={{
                      font: "14px Pretendard-Regular",
                      color: "#888",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={handleModal}
                  >
                    보기
                  </span>
                </Box>
              </S.CheckBoxContainer>
              <TermOfUseModal open={modal} handleModal={handleModal} />
            </Box>
          </S.Paper>
        </S.UserInfoGrid>

        <S.PaymentGrid>
          <S.Paper>
            <S.PlanTitle>상품 정보</S.PlanTitle>

            <S.PlanContent>
              <div className="title">상품 플랜</div>

              <Box>
                <div className="plan">
                  {/* {V.user && V.user.status === "Personal" && "베이직"} */}
                  {/* {V.user && V.user.status === "Group" && "[그룹] 베이직 (연간 결제)"} */}
                  {V.user &&
                    (() => {
                      if (V.user.status === "Personal") {
                        if (state.plan === "period") return "베이직 (기간 선택 결제)";
                        return "베이직 (구독 정기 결제)";
                      }
                      if (V.user.status === "Group") return "[그룹] 베이직 (연간 결제)";
                      // switch ()
                    })()}
                </div>

                {V.user && V.user.status === "Personal" && state.plan === "subscription" && (
                  <S.SelectBox
                    value={V.paymentFrequency}
                    sx={{ fontSize: "14px", float: "right" }}
                    onChange={F.handlePaymentFrequency}
                    disabled={V.usedCoupon !== null}
                  >
                    <S.SelectItem
                      value="default"
                      disabled
                      sx={{ overflow: "hidden", maxHeight: 0, p: 0 }}
                    >
                      결제 방법을 선택해주세요
                    </S.SelectItem>

                    <S.SelectItem value="monthly">월간 결제</S.SelectItem>

                    <S.SelectItem value="yearly">연간 결제</S.SelectItem>
                  </S.SelectBox>
                )}
              </Box>
            </S.PlanContent>

            {V.user && V.user.status === "Group" && (
              <S.PlanContent>
                <div className="title">그룹 계정 수</div>

                <S.SelectBox
                  size="small"
                  value={V.groupUserCount}
                  onChange={F.handleGroupUserCount}
                  sx={{ fontSize: "15px", fontWeight: "bold" }}
                  MenuProps={{
                    style: {
                      maxHeight: "300px",
                    },
                  }}
                >
                  <S.SelectItem
                    value="default"
                    disabled
                    sx={{ overflow: "hidden", maxHeight: 0, p: 0 }}
                  >
                    그룹 계정 수를 선택해주세요
                  </S.SelectItem>

                  {Array.from({ length: 46 }, (_, i) => (
                    <S.SelectItem key={i} value={i + 5}>
                      {i + 5}
                    </S.SelectItem>
                  ))}
                </S.SelectBox>
              </S.PlanContent>
            )}

            {/* <div style={{ textAlign: "right", wordBreak: "keep-all" }}>
              참고: 정기 결제가 윤년이 아닌 해의 2월 29일, 30일 또는 31일에 갱신되도록 설정되어
              있으면 정기 결제 갱신일은 2월 28일로 옮겨지고 이후 정기 결제 기간 동안 계속해서 매월
              28일에 갱신됩니다. 마찬가지로 사용자가 3월 31일에 정기 결제를 시작하면 정기 결제는 4월
              30일에 갱신되고 계속해서 매월 30일에 갱신됩니다.
            </div> */}

            {state.plan === "subscription" && (
              <S.PlanContent>
                <div className="title">
                  결제수단 <span style={{ color: "#c33c3c", fontWeight: "bold" }}>*</span>
                </div>

                <S.SelectBox value={V.card} onChange={F.handleCard}>
                  <S.SelectItem value="신용카드" disabled>
                    카드를 선택해주세요
                  </S.SelectItem>
                  <S.SelectItem value="신한카드">신한카드</S.SelectItem>
                  <S.SelectItem value="현대카드">현대카드</S.SelectItem>
                  <S.SelectItem value="삼성카드">삼성카드</S.SelectItem>
                  <S.SelectItem value="국민카드">국민카드</S.SelectItem>
                  <S.SelectItem value="롯데카드">롯데카드</S.SelectItem>
                  <S.SelectItem value="하나카드">하나카드</S.SelectItem>
                  <S.SelectItem value="농협카드">농협카드</S.SelectItem>
                  <S.SelectItem value="BC카드">BC카드</S.SelectItem>
                  <S.SelectItem value="우리카드">우리카드</S.SelectItem>
                </S.SelectBox>
              </S.PlanContent>
            )}

            {state.plan === "period" && (
              <S.PlanContent>
                <div className="title">
                  기간 선택 <span style={{ color: "#c33c3c", fontWeight: "bold" }}>*</span>
                </div>

                <S.SelectBox value={V.period} onChange={F.handlePeriod}>
                  <S.SelectItem value="0" disabled>
                    기간을 선택해주세요
                  </S.SelectItem>
                  {/* <S.SelectItem value="1">1개월</S.SelectItem> */}
                  <S.SelectItem value="2">2개월</S.SelectItem>
                  <S.SelectItem value="3">3개월</S.SelectItem>
                  <S.SelectItem value="4">4개월</S.SelectItem>
                  <S.SelectItem value="5">5개월</S.SelectItem>
                  <S.SelectItem value="6">6개월</S.SelectItem>
                  <S.SelectItem value="7">7개월</S.SelectItem>
                  <S.SelectItem value="8">8개월</S.SelectItem>
                  <S.SelectItem value="9">9개월</S.SelectItem>
                  <S.SelectItem value="10">10개월</S.SelectItem>
                  <S.SelectItem value="11">11개월</S.SelectItem>
                  <S.SelectItem value="12">12개월</S.SelectItem>
                </S.SelectBox>
              </S.PlanContent>
            )}
          </S.Paper>

          <S.Paper data-id="coupon" onClick={F.handleCouponModal}>
            <S.CouponBox>
              <AddIcon />
              나의 쿠폰함
            </S.CouponBox>
          </S.Paper>

          <S.Paper>
            <S.PlanTitle>최종 결제 정보</S.PlanTitle>

            {state.plan === "subscription" && (
              <>
                <S.PlanContent>
                  <div className="title">결제상품금액</div>

                  <div className="price">
                    {V.plan === "yearly" && V.groupUserCount !== "default" && (
                      <p
                        style={{
                          color: "#00ee00",
                          textDecoration: "line-through",
                          p: 0,
                          m: 0,
                          lineHeight: "0",
                        }}
                      >
                        {"99,000 "}원
                      </p>
                    )}
                    {V.price && V.price.toLocaleString("ko-KR")} 원 /
                    {V.plan === "monthly" ? " 월 " : " 년 "}
                    {V.plan === "yearly" && V.groupUserCount !== "default" && (
                      <span style={{ color: "#c33c3c", fontWeight: "bold" }}>
                        X {V.groupUserCount}명
                      </span>
                    )}
                    {V.plan === "yearly" && V.groupUserCount !== "default" && (
                      <p style={{ color: "#c33c3c", textAlign: "right", fontSize: "14px" }}>
                        = {(V.price * V.groupUserCount).toLocaleString("ko-KR")}원
                      </p>
                    )}
                  </div>
                </S.PlanContent>
                {/* {V.user && V.user.status === "Personal" && ( */}
                <S.PlanContent>
                  <div className="title">할인</div>

                  <div className="price">
                    - {V.discount.toLocaleString("ko-KR")} 원{" "}
                    {V.plan === "yearly" && V.groupUserCount !== "default" && (
                      <span style={{ color: "#c33c3c", fontWeight: "bold" }}>
                        X {V.groupUserCount}명
                      </span>
                    )}
                    {V.plan === "yearly" && V.groupUserCount !== "default" && (
                      <p style={{ color: "#c33c3c", textAlign: "right", fontSize: "14px" }}>
                        = {(V.discount * V.groupUserCount).toLocaleString("ko-KR")}원
                      </p>
                    )}
                  </div>
                </S.PlanContent>
                {/* // )} */}
                <S.PlanContent>
                  <div className="title">최종결제금액</div>

                  <div className="price">
                    {V.finalPrice && V.finalPrice.toLocaleString("ko-KR")} 원 /{" "}
                    {V.plan === "monthly" ? "월" : "년"}
                  </div>
                </S.PlanContent>
              </>
            )}

            {state.plan === "period" && (
              <>
                <S.PlanContent>
                  <div className="title">결제상품금액</div>

                  <div className="price">
                    {V.plan === "yearly" && V.groupUserCount !== "default" && (
                      <p
                        style={{
                          color: "#00ee00",
                          textDecoration: "line-through",
                          p: 0,
                          m: 0,
                          lineHeight: "0",
                        }}
                      >
                        {"99,000 "}원
                      </p>
                    )}
                    {V.price && V.price.toLocaleString("ko-KR")} 원
                    {/* {V.plan === "monthly" ? " 월 " : " 년 "} */}
                    {/* {V.plan === "yearly" && V.groupUserCount !== "default" && (
                      <span style={{ color: "#c33c3c", fontWeight: "bold" }}>
                        X {V.groupUserCount}명
                      </span>
                    )} */}
                    {V.plan === "yearly" && V.groupUserCount !== "default" && (
                      <p style={{ color: "#c33c3c", textAlign: "right", fontSize: "14px" }}>
                        = {(V.price * V.groupUserCount).toLocaleString("ko-KR")}원
                      </p>
                    )}
                  </div>
                </S.PlanContent>

                <S.PlanContent>
                  <div className="title">할인</div>

                  <div className="price">
                    - {V.discount.toLocaleString("ko-KR")} 원{" "}
                    {V.plan === "yearly" && V.groupUserCount !== "default" && (
                      <span style={{ color: "#c33c3c", fontWeight: "bold" }}>
                        X {V.groupUserCount}명
                      </span>
                    )}
                    {V.plan === "yearly" && V.groupUserCount !== "default" && (
                      <p style={{ color: "#c33c3c", textAlign: "right", fontSize: "14px" }}>
                        = {(V.discount * V.groupUserCount).toLocaleString("ko-KR")}원
                      </p>
                    )}
                  </div>
                </S.PlanContent>
                {/* // )} */}
                <S.PlanContent>
                  <div className="title">최종결제금액</div>

                  <div className="price">
                    {V.finalPrice && V.finalPrice.toLocaleString("ko-KR")} 원
                  </div>
                </S.PlanContent>
              </>
            )}

            <Divider />

            <S.PaymentButton disabled={V.buttonDisabled} onClick={F.startBilling}>
              결제하기
            </S.PaymentButton>
          </S.Paper>
        </S.PaymentGrid>
      </S.Content>

      <CouponModal />
    </S.Body>
  );
};
export default Layout;
