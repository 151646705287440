import React from "react";
import { useMediaQuery } from "react-responsive";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import background from "assets/images/ContentPage/LivePolling/background.png";
import characterImg from "assets/images/ContentPage/LivePolling/characterImg.png";

import * as S from "./styles";
import { benefitData, connectionScreenData, typeDescriptionData, choiceData } from "./data";
import { Footer } from "components/Footer";

const manual = "https://withplus-live.notion.site/Live-Polling-69e60ce10c6c497fb6413a043604b259";

const LivePollingContentPage = () => {
  // 반응형 모바일
  const isMobile = useMediaQuery({
    query: "(max-width:1000px)",
  });

  if (isMobile) {
    return (
      <S.Body>
        <S.container disableGutters maxWidth="xs">
          <Intro isMobile />

          <Benefit isMobile />

          <TypeDescription isMobile />

          <ChoiceDescription isMobile />

          <ConnectionScreen isMobile />
        </S.container>

        <Footer />
      </S.Body>
    );
  }

  return (
    <S.Body>
      <Intro />

      <Benefit />

      <TypeDescription />

      <ChoiceDescription />

      <ConnectionScreen />

      <Footer />
    </S.Body>
  );
};

const Intro = ({ isMobile }) => {
  const navigate = useNavigate();

  const goToDashBoard = () => {
    if (/Android|iPhone/i.test(navigator.userAgent)) {
      alert("진행자 기능 사용은 PC(노트북)에서 진행해 주세요!");
    } else {
      setTimeout(() => {
        navigate("/dashboard");
      }, 300);
    }
  };

  return (
    <S.Intro sx={{ backgroundImage: `url(${background})` }}>
      <S.IntroTitle>Live Polling</S.IntroTitle>

      <S.IntroExplain1>
        강사의 화면 집계용 보드로 활용하여 <br />
        참여자의 모바일 기기를 통해 실시간 투표기능을 제공합니다
      </S.IntroExplain1>

      <S.IntroExplain2>
        참여자의 의견을 실시간으로 청취하고 <br /> 공유하며 참여도와 집중도를 높일 수 있습니다
      </S.IntroExplain2>

      <S.IntroButton onClick={goToDashBoard}>체험하기</S.IntroButton>

      {<S.IntroCharacter src={characterImg} alt="character" />}
    </S.Intro>
  );
};

const Benefit = ({ isMobile }) => {
  return (
    <S.EffectContainer>
      <S.LivePollingBox>Live Polling</S.LivePollingBox>

      <S.Title>기대 효과</S.Title>

      <S.Explain>참여자 모두의 생각을 실시간으로 공유할 수 있는 라이브 폴링</S.Explain>

      <S.EffectBox>
        {isMobile ? (
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            pagination={{ clickable: true }}
            navigation
          >
            {benefitData.map((data, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <S.EffectItem>
                    <S.EffectItemIcon src={data.img} alt="icon1" />

                    <S.EffectItemDescription>
                      {data.description[0]} <br /> {data.description[1]} &nbsp;
                    </S.EffectItemDescription>
                  </S.EffectItem>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          benefitData.map((data, idx) => {
            return (
              <S.EffectItem key={idx}>
                <S.EffectItemIcon src={data.img} alt="icon1" />

                <S.EffectItemDescription>
                  {data.description[0]} <br /> {data.description[1]} &nbsp;
                </S.EffectItemDescription>
              </S.EffectItem>
            );
          })
        )}
      </S.EffectBox>
    </S.EffectContainer>
  );
};

const TypeDescription = ({ isMobile }) => {
  return (
    <S.TypeDescriptionContainer>
      <S.LivePollingBox>Live Polling</S.LivePollingBox>

      <S.Title>타입 소개</S.Title>

      <S.Explain>
        Word Cloud / Choice 두 가지 타입 중 {isMobile && <br />}
        사용 의도에 적합한 타입 선택
      </S.Explain>

      <S.TypeDescriptionBox>
        {isMobile ? (
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            pagination={{ clickable: true }}
            navigation
          >
            {typeDescriptionData.map((data, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <S.TypeDescriptionItem>
                    <S.TypeDescriptionIconBox>
                      <img src={data.img} alt="icon1" />
                    </S.TypeDescriptionIconBox>

                    <S.TypeItemTitle>{data.title}</S.TypeItemTitle>

                    <S.TypeItemDescription>
                      {data.description[0]} <br /> {data.description[1]} <br />
                      {data.description[2]}
                    </S.TypeItemDescription>
                  </S.TypeDescriptionItem>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          typeDescriptionData.map((data, idx) => {
            return (
              <S.TypeDescriptionItem key={idx}>
                <S.TypeDescriptionIconBox>
                  <img src={data.img} alt="icon1" />
                </S.TypeDescriptionIconBox>

                <S.TypeItemTitle>{data.title}</S.TypeItemTitle>

                <S.TypeItemDescription>
                  {data.description[0]} <br /> {data.description[1]} <br />
                  {data.description[2]}
                </S.TypeItemDescription>
              </S.TypeDescriptionItem>
            );
          })
        )}
      </S.TypeDescriptionBox>
    </S.TypeDescriptionContainer>
  );
};

const ChoiceDescription = ({ isMobile }) => {
  return (
    <S.ChoiceDescriptionContainer>
      <S.LivePollingBox>Live Polling</S.LivePollingBox>

      <S.Title>Choice 결과 레이아웃 유형</S.Title>

      <S.Explain>
        Choice 타입 선택 시 응답 결과를 총 3가지의 레이아웃으로 <br />
        시각화할 수 있습니다
      </S.Explain>

      <S.ChoiceDescriptionBox>
        {isMobile ? (
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            pagination={{ clickable: true }}
            navigation
          >
            {choiceData.map((data, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <S.ChoiceDescriptionItem>
                    <S.ChoiceItemIcon src={data.img} alt="icon1" />

                    <S.ChoiceItemTitle>{data.title}</S.ChoiceItemTitle>

                    <S.ChoiceItemDescription>{data.description}</S.ChoiceItemDescription>
                  </S.ChoiceDescriptionItem>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          choiceData.map((data, idx) => {
            return (
              <S.ChoiceDescriptionItem key={idx}>
                <S.ChoiceItemIcon src={data.img} alt="icon1" />

                <S.ChoiceItemTitle>{data.title}</S.ChoiceItemTitle>

                <S.ChoiceItemDescription>{data.description}</S.ChoiceItemDescription>
              </S.ChoiceDescriptionItem>
            );
          })
        )}
      </S.ChoiceDescriptionBox>
    </S.ChoiceDescriptionContainer>
  );
};

const ConnectionScreen = ({ isMobile }) => {
  return (
    <S.ConnectionScreenContainer>
      {connectionScreenData.map((data, idx) => {
        return (
          <S.ConnectionScreenBox key={idx} id={data.id}>
            <S.LivePollingBox>Live Polling</S.LivePollingBox>

            <S.ConnectionScreenTitle>{data.title}</S.ConnectionScreenTitle>

            <S.ConnectionScreenExplain>
              {data.explain[0]} <br /> {isMobile && <br />} {data.explain[1]}
            </S.ConnectionScreenExplain>

            <S.ConnectionScreenImgBox id={data.id}>
              <img src={data.img} alt={data.id} />
            </S.ConnectionScreenImgBox>

            <S.ConnectionScreenExplainsContainer target={(isMobile || false).toString()}>
              <S.ConnectionScreenExplainsBox>
                {data.explains.map((explain, idx) => {
                  return (
                    <S.ConnectionScreenExplainItem
                      key={idx}
                      id={explain.id + ""}
                      target={(isMobile || false).toString()}
                    >
                      <S.ConnectionScreenExplainItemTitleBox>
                        <S.RedCircle>{explain.id}</S.RedCircle>

                        <S.ConnectionScreenExplainItemTitle>
                          {explain.title}
                        </S.ConnectionScreenExplainItemTitle>
                      </S.ConnectionScreenExplainItemTitleBox>

                      <S.ConnectionScreenExplainItemDescription>
                        {explain.description[0]} <br /> {explain.description[1]}
                      </S.ConnectionScreenExplainItemDescription>
                    </S.ConnectionScreenExplainItem>
                  );
                })}
              </S.ConnectionScreenExplainsBox>
            </S.ConnectionScreenExplainsContainer>

            <a href={manual} target="_blank" rel="noreferrner">
              <S.ConnectionScreenButton target={(isMobile || false).toString()}>
                메뉴얼 보러가기
              </S.ConnectionScreenButton>
            </a>
          </S.ConnectionScreenBox>
        );
      })}
    </S.ConnectionScreenContainer>
  );
};

export default LivePollingContentPage;
