import S from "../style.module.css";
import HexagonImg from "../../../../../src/assets/images/HexagramHexagonImg.png";

const SecondPage = () => {
  return (
    <div className={S.Page}>
      <div className={S.PageHeader}>HEXA GRAM Personality Profile</div>
      <div className={S.PageTitle}>
        <div className={S.TitlePart}>Part I</div>
        <p className={S.TitlePartName}>개인 프로파일을 읽기 전에...</p>
      </div>
      <div className={S.SecondContentsFirst}>
        <p>
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>핵사그램(HEXAGRAM)</span>은 사람의
          성향을 6가지 타입모델(Hexagram Personality Types Model))을 기본으로 자신을 이해하고,
          스트레스 관리 등 자기관리 측면 뿐만 아니라 관계측면에서 서로를 이해하는데 넓은 시각과
          관점을 가질 수 있도록 하는 성격
          <br />
          타입 안내서입니다.
        </p>
      </div>
      <div className={S.SecondContentsSecond}>
        <p>
          1) 핵사그램은 자신의 특징을 이해하고 강, 약점 등 자세한 정보제공을 통해 ‘진짜 나’를
          섬세하게 탐색할 수 있도록 도와줍니다. <br />
          2) 스트레스에 반응 하는 자신을 앎으로써 자기관리 뿐만 아니라 자신에게 맞는 삶의 방식을
          선택하여 행복한 삶을 영위 할 수 있도록 도와줍니다.
          <br />
          3) 6가지 타입별로 일 처리 방식의 차이를 확인하고, 효과적으로 시너지를 발휘 할 수 있는
          방법을 제공합니다. <br />
          4) 상호 교류 시 선호하는 관계방식과 커뮤니케이션 스타일을 이해하고, 유연하게 관계관리를 할
          수 있도록 방법을 제시합니다. <br />
          5) 심리적 욕구를 통해 갈등 요인을 확인하고 갈등해결 방법을 구체적으로 제시하여 현장에서
          적용하고 관리할 수 있도록
          <br />
          도와줍니다.
        </p>
      </div>
      <div className={S.SecondContentsThird}>
        <div className={S.SecondThirdContent}>
          핵사그램은 사람의 타입을 누구나 갖고 태어난 <span>선천적 특성인 기질</span>과 저마다
          독특한 유아기 환경속에서 상호작용하면서 형성된 <span>성격</span>, 그리고 현재 처한 환경과
          포지션에서 자주 발휘되는 개인의 독특한 행동 특성인 <span>행동유형</span>을 모두 포함하여{" "}
          <span>6가지 범주</span>로 구분하고 있습니다. 생존적 적응방식 중 자신의 내부로 에너지를
          향하는 상상형과 신념형으로 구분하며, 행동형은 외부로 에너지가 향하지만 상황에 따라 자신
          내부로 에너지가 향하는 경우도 있습니다. 실행적 적응 중 감성형과 유희형은 타인이나
          외부세계로 에너지가 향하고, 분석형은 자기 내부로 에너지가 향하는 타입으로 구분합니다.
          이러한 내용을 아래 다이어그램에 간단하게 표기 하였습니다.
        </div>
      </div>
      <div className={S.SecondPageImage}>
        <img src={HexagonImg} alt="HexagonImg" />
      </div>
      <div className={S.SecondContentsFourthTitle}>[HPT의 이론적 근거와 배경]</div>
      <div className={S.SecondContentsFourthContent}>
        <p>
          벤 조인스(Vann Joines)와 이언 스튜어트(Ian Stewart)에 의해 연구된 성격적응론을 근거로
          개발된 성격타입 검사입니다.
          <br />
          핵사그램은 인간이 가진 에너지의 수준과 방향이 다르고 바라보는 관점이 다르다고 보았고,
          성격타입의 이해를 위한 실제적인 안내서입니다. 여섯가지 타입을 서술하고 자신을 어떻게
          관리하고, 사람 사이에 어떻게 커뮤니케이션 하며 관계관리를 하는 것이
          <br />
          효과적인지를 자세히 설명하고 있습니다. 사람의 타입에 따라 생존적 적응와 실행적 적응을 통해
          6가지 범주로 구분 모델을 제시하고 있습니다. 이를 통해 6가지 타입의 특징에 대해 명확히
          제시하고 있습니다. 성격적응론을 통해 다양한 영역에서 인간를 이해하기
          <br />
          위한 새로운 안내서이며, 6가지 타입별로의 특징을 자세히 이해하고 성장·발전 할 수 있는
          방향을 제시합니다. 더욱이 이를 바탕으로
          <br />
          성숙된 인간으로 성장할 수 있도록 하는 안내서입니다.
        </p>
      </div>
      <div className={S.PageFooter}>
        <p>All right reserved by Withplus Inc. copyright</p>
      </div>
    </div>
  );
};
export default SecondPage;
