export const subTitles = [
  "ㄱ",
  "가",
  "강",
  "강ㅇ",
  "강으",
  "강의",
  "강읭",
  "강의에",
  "강의에 ",
  "강의에 ㅈ",
  "강의에 즈",
  "강의에 즐",
  "강의에 즑",
  "강의에 즐거",
  "강의에 즐겅",
  "강의에 즐거우",
  "강의에 즐거움",
  "강의에 즐거움ㅇ",
  "강의에 즐거움으",
  "강의에 즐거움을",
  "강의에 즐거움을 ",
  "강의에 즐거움을 ㄷ",
  "강의에 즐거움을 더",
  "강의에 즐거움을 덯",
  "강의에 즐거움을 더해",
  "강의에 즐거움을 더햊",
  "강의에 즐거움을 더해주",
  "강의에 즐거움을 더해준",
  "강의에 즐거움을 더해주느",
  "강의에 즐거움을 더해주는",
  "강의에 즐거움을 더해주는 ",
  "강의에 즐거움을 더해주는 ㄱ",
  "강의에 즐거움을 더해주는 가",
  "강의에 즐거움을 더해주는 갖",
  "강의에 즐거움을 더해주는 가자",
  "강의에 즐거움을 더해주는 가장",
  "강의에 즐거움을 더해주는 가장 ",
  "강의에 즐거움을 더해주는 가장 ㅅ",
  "강의에 즐거움을 더해주는 가장 수",
  "강의에 즐거움을 더해주는 가장 쉬",
  "강의에 즐거움을 더해주는 가장 슁",
  "강의에 즐거움을 더해주는 가장 쉬우",
  "강의에 즐거움을 더해주는 가장 쉬운",
  "강의에 즐거움을 더해주는 가장 쉬운 ",
  "강의에 즐거움을 더해주는 가장 쉬운 ㄷ",
  "강의에 즐거움을 더해주는 가장 쉬운 도",
  "강의에 즐거움을 더해주는 가장 쉬운 독",
  "강의에 즐거움을 더해주는 가장 쉬운 도구",
  "강의에 즐거움을 더해주는 가장 쉬운 도구 ",
  "강의에 즐거움을 더해주는 가장 쉬운 도구 ㅍ",
  "강의에 즐거움을 더해주는 가장 쉬운 도구 프",
  "강의에 즐거움을 더해주는 가장 쉬운 도구 플",
  "강의에 즐거움을 더해주는 가장 쉬운 도구 플ㄹ",
  "강의에 즐거움을 더해주는 가장 쉬운 도구 플래",
  "강의에 즐거움을 더해주는 가장 쉬운 도구 플랫",
  "강의에 즐거움을 더해주는 가장 쉬운 도구 플랫ㅍ",
  "강의에 즐거움을 더해주는 가장 쉬운 도구 플랫포",
  "강의에 즐거움을 더해주는 가장 쉬운 도구 플랫폼",
];

export const outro = [
  "ㄱ",
  "가",
  "갖",
  "가자",
  "가장",
  "가장 ",
  "가장 ㅅ",
  "가장 수",
  "가장 쉬",
  "가장 쉽",
  "가장 쉽ㄱ",
  "가장 쉽고",
  "가장 쉽고 ",
  "가장 쉽고 ㅍ",
  "가장 쉽고 펴",
  "가장 쉽고 편",
  "가장 쉽고 펺",
  "가장 쉽고 편하",
  "가장 쉽고 편한",
  "가장 쉽고 편한 ",
  "가장 쉽고 편한 ㄱ",
  "가장 쉽고 편한 가",
  "가장 쉽고 편한 강",
  "가장 쉽고 편한 강ㅇ",
  "가장 쉽고 편한 강으",
  "가장 쉽고 편한 강의",
  "가장 쉽고 편한 강의 ",
  "가장 쉽고 편한 강의 ㄷ",
  "가장 쉽고 편한 강의 도",
  "가장 쉽고 편한 강의 독",
  "가장 쉽고 편한 강의 도구",
  "가장 쉽고 편한 강의 도구 ",
  "가장 쉽고 편한 강의 도구 ㅍ",
  "가장 쉽고 편한 강의 도구 프",
  "가장 쉽고 편한 강의 도구 플",
  "가장 쉽고 편한 강의 도구 플ㄹ",
  "가장 쉽고 편한 강의 도구 플래",
  "가장 쉽고 편한 강의 도구 플랫",
  "가장 쉽고 편한 강의 도구 플랫ㅍ",
  "가장 쉽고 편한 강의 도구 플랫포",
  "가장 쉽고 편한 강의 도구 플랫폼",
];
