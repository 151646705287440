import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { useNavigate, useParams } from "react-router-dom";

const ManagerContext = createContext({
  socket: null,
});

const ManagerProvider = ({ children }) => {
  const { gameId } = useParams();

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (!socket) return;
    socket.emit("joinRoom", { gameId });
  }, [socket]);

  useEffect(() => {
    if (!socket) {
      setSocket(io.connect("hexagram.withplus.live"));
      return;
    }

    socket.on("connect", () => {
      console.log("connected");
      socket.emit("joinRoom", { gameId });
    });

    return () => {
      socket.off("connect");
      // socket.disconnect();
    };
  });

  const value = {
    socket,
  };

  return <ManagerContext.Provider value={value}>{children}</ManagerContext.Provider>;
};

export { ManagerContext, ManagerProvider };
