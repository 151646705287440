// * npm modules
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// * material UI components
import { Box, InputAdornment, Typography } from "@mui/material";

// * local components
import { UserContext } from "../contexts/UserContext";
import { SnackBarContext } from "components/SnackBar/ContextAPI";
import { Textfield } from "components/Textfield";

// * styles
import * as S from "./styles";

// * OpenEnded User
export default () => {
  const { gameId, slideId } = useParams(); // * gameId, slideId from URL
  const { socket, playingData, currentSlideIndex } = useContext(UserContext); // * 소켓, 플레이 데이터, 현재 슬라이드 인덱스
  const { setOpen, setText, setSeverity } = useContext(SnackBarContext); // * SnackBar Context

  const [currentSlide, setCurrentSlide] = useState(null); // * 현재 슬라이드
  const [answer, setAnswer] = useState(""); // * 답변

  // * 답변 제출 핸들러
  const submitAnswer = () => {
    // * 답변이 입력되지 않았을 때
    if (answer.trim() === "") {
      setText("답변을 입력해주세요."); // * SnackBar 텍스트
      setSeverity("warning"); // * SnackBar 세버리티
      setOpen(true); // * SnackBar 오픈
      setAnswer(""); // * 답변 초기화

      return; // * 리턴
    }

    const uuid = localStorage.getItem("uuid"); // * 유저 아이디

    // * 소켓으로 답변 제출 (게임 아이디, 슬라이드 아이디, 유저 아이디, 답변)
    socket.emit("submitOpenEnded", { gameId, slideId, userId: uuid, answer }, (res) => {
      // * 답변 제출 성공
      if (res.success) {
        setText("답변이 성공적으로 등록되었습니다."); // * SnackBar 텍스트
        setSeverity("success"); // * SnackBar 세버리티
        setOpen(true); // * SnackBar 오픈
        setAnswer(""); // * 답변 초기화
      }
      // * 답변 제출 실패
      else {
        setText("이미 답변을 제출하셨습니다."); // * SnackBar 텍스트
        setSeverity("warning"); // * SnackBar 세버리티
        setOpen(true); // * SnackBar 오픈
      }
    });
  };

  // * 플레이 데이터가 있을 때 현재 슬라이드 업데이트
  useEffect(() => {
    if (!playingData) return; // * 플레이 데이터가 없으면 리턴

    setCurrentSlide(playingData[currentSlideIndex]); // * 현재 슬라이드 업데이트
  }, [playingData, currentSlideIndex]);

  return (
    <S.OpenEndedBody>
      <Typography sx={{ font: "700 20px Pretendard", mb: "20px" }}>설문형</Typography>

      {
        // * 투표 제한 되어있을 때 제한 박스 생성
        currentSlide?.restricted && (
          <S.ChoiceRestrictedBox>
            <Typography sx={{ font: "600 18px Pretendard", mb: "10px" }}>
              투표 제출이 제한되었습니다.
            </Typography>

            <Typography sx={{ font: "500 14px Pretendard", color: "#888", wordBreak: "keep-all" }}>
              진행자가 다음 슬라이드로 넘어가기 전까지 대기하거나, 진행자에게 투표 제한 해제를
              요청해주세요.
            </Typography>
          </S.ChoiceRestrictedBox>
        )
      }

      {/* 질문 박스 */}
      <Box sx={{ border: "1px solid #eee", borderRadius: "10px", p: "10px", mb: "20px" }}>
        <Typography sx={{ font: "600 18px Pretendard", mb: "10px" }}>
          {"질문. "}

          {/* 중복 제출 가능할 때 컴포넌트 생성 */}
          {currentSlide?.duplicateSubmission && (
            <span style={{ color: "blue", fontSize: "16px" }}>{"(중복 제출 가능)"}</span>
          )}
        </Typography>

        {/* 질문 텍스트 */}
        <Typography sx={{ font: "700 24px Pretendard" }}>{currentSlide?.question}</Typography>
      </Box>

      {/* 답변 입력 */}
      <Box sx={{ mb: "20px" }}>
        <Textfield
          multiline
          fullWidth
          placeholder="질문에 대한 답을 입력해주세요."
          maxRows={4}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography sx={{ color: "#252b36" }}>{75 - answer.length}</Typography>
              </InputAdornment>
            ),
          }}
          value={answer}
          onChange={(e) => {
            setAnswer(e.target.value.slice(0, 75)); // * 답변 75자 제한
          }}
        />

        {/* 중복 제출 가능할 때 표시 텍스트 */}
        {currentSlide?.duplicateSubmission && (
          <Typography sx={{ pl: "5px", fontSize: "14px", mt: "10px", fontWeight: "600" }}>
            질문에 대한 답을 여러번 제출 가능합니다.
          </Typography>
        )}
      </Box>

      {/* 제출 버튼 */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: "20px" }}>
        <S.ChoiceSubmitButton
          variant="contained"
          onClick={submitAnswer}
          disabled={currentSlide?.restricted}
        >
          제출하기
        </S.ChoiceSubmitButton>
      </Box>
    </S.OpenEndedBody>
  );
};
