import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import * as API from "utils/api/Poll";

import { getIntroData } from "../EditPage/Intro";
import { WordCloudData } from "../EditPage/WordCloud";
import { getChoiceData } from "../EditPage/Choice";
import { getOpenEndedData } from "../EditPage/OpenEnded";
import { LoadingModal } from "components/Modal";

const EditContext = createContext({
  user: null,
  title: "",
  slideList: [],
  currentSlide: {},
  titleWarning: false,

  setTitle: () => {},
  setSlideList: () => {},
  setCurrentSlide: () => {},
  updateCurrentSlide: () => {},
  changeSlideType: () => {},
  setTitleWarning: () => {},
});

const EditProvider = ({ children }) => {
  const { gameId } = useParams();

  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState(null);
  const [title, setTitle] = useState("");
  const [slideList, setSlideList] = useState([
    { ...getIntroData(), id: new Date().getTime() + "" },
  ]);
  // const [slideList, setSlideList] = useState([
  //   { ...getOpenEndedData(), id: new Date().getTime() + "" },
  // ]);
  const [currentSlide, setCurrentSlide] = useState(slideList[0] || {});
  const [titleWarning, setTitleWarning] = useState(false);

  const updateCurrentSlide = (object) => {
    let newCurrentSlide = { ...currentSlide, ...object };

    checkComplete(newCurrentSlide);
    setCurrentSlide(newCurrentSlide);

    setSlideList(
      slideList.map((item) => {
        return item.id === currentSlide.id ? newCurrentSlide : item;
      })
    );
  };

  const changeSlideType = (object) => {
    let newSlide;

    if (object.type === "Intro") {
      newSlide = { ...getIntroData(), id: new Date().getTime() + "" };
    } else {
      newSlide = { ...object, id: currentSlide.id, question: currentSlide.question || "" };
    }

    checkComplete(newSlide);
    setCurrentSlide(newSlide);

    setSlideList(
      slideList.map((item) => {
        return item.id === currentSlide.id ? newSlide : item;
      })
    );
  };

  const checkComplete = (currentSlide) => {
    switch (currentSlide.type) {
      case "Intro":
        currentSlide.completed = true;
        break;
      case "WordCloud":
        if (currentSlide.question !== "") {
          currentSlide.completed = true;
        } else {
          currentSlide.completed = false;
        }
        break;
      case "Choice":
        const isOptionCompleted = currentSlide.options.every((option) => option.value !== "");

        if (currentSlide.question !== "" && isOptionCompleted) {
          currentSlide.completed = true;
        } else {
          currentSlide.completed = false;
        }
        break;
      case "OpenEnded":
        if (currentSlide.question !== "") {
          currentSlide.completed = true;
        } else {
          currentSlide.completed = false;
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    API.getPollData(gameId, (res) => {
      const { success, poll, user } = res.data;

      if (success) {
        setUser(user);

        if (!poll.name) {
          setLoading(false);
          return;
        }

        setTitle(poll.name);
        setSlideList(poll.slideList);
        setCurrentSlide(poll.slideList[0]);
      } else {
        alert("데이터를 불러오는데 실패했습니다.");
        window.location.href = "/polling/list";
      }

      setLoading(false);
    });
  }, []);

  const value = {
    user,
    title,
    slideList,
    currentSlide,
    titleWarning,

    setTitle,
    setSlideList,
    setCurrentSlide,
    updateCurrentSlide,
    changeSlideType,
    setTitleWarning,
  };

  return (
    <EditContext.Provider value={value}>
      {children}
      <LoadingModal open={loading} />
    </EditContext.Provider>
  );
};

export { EditContext, EditProvider };
