import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Divider, Typography, IconButton, Menu, MenuItem, Skeleton } from "@mui/material";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import * as S from "../styles";

import { UserContext } from "views/LiveBoard/contexts/User";
import { SnackBarContext } from "components/SnackBar/ContextAPI";
import { DeleteModal } from "../components/Modal";

const baseURL = process.env.REACT_APP_LIVE_BOARD_SERVER_URL;
// const baseURL = "http://192.168.0.87:3004/live-board";
// const baseURL = "http://172.30.1.52:3004/live-board";

const Card = React.memo(({ item }) => {
  const { gameId } = useParams();
  const { socket, handleCardModal, handleCardModify } = useContext(UserContext);
  const SnackBar = useContext(SnackBarContext);
  const uuid = localStorage.getItem("uuid");

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  // const [src, setSrc] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [imageError, setImageError] = useState(false);

  const [file, setFile] = useState(null);

  const handleLikes = (e) => {
    e.stopPropagation();

    socket.emit("handleLike", { gameId, postId: item.id, userId: uuid });
  };

  const modifyCard = () => {
    const password = window.prompt("비밀번호를 입력해주세요.");

    if (!password) return;

    if (password === item.password) {
      handleCardModify(item);
    } else {
      SnackBar.setOpen(true);
      SnackBar.setText("비밀번호가 틀렸습니다.");
      SnackBar.setSeverity("error");
    }
  };

  const deleteCard = () => {
    const password = window.prompt("비밀번호를 입력해주세요.");

    if (!password) return;

    if (password === item.password) {
      // handleCardModify(item);
      // handleDeleteModal(item.id);
      handleDeleteModal();
    } else {
      SnackBar.setOpen(true);
      SnackBar.setText("비밀번호가 틀렸습니다.");
      SnackBar.setSeverity("error");
    }
  };

  const handleDeleteModal = (e) => {
    e?.stopPropagation();

    setIsDeleteModalOpened((prev) => !prev);
  };

  useEffect(() => {
    if (!item || !item.file) {
      setThumbnail(undefined);
      return;
    }

    setFile(item.file);

    fetch(`${baseURL}/thumbnail?id=${item.id}&gameId=${gameId}`).then((res) => {
      res.blob().then((blob) => {
        const url = URL.createObjectURL(blob);
        const image = new Image();
        image.src = url;

        image.onload = () => {
          setThumbnail(image);
          setImageError(true);
        };

        image.onerror = () => {
          setImageError(true);
        };
      });
    });
  }, [item]);

  useEffect(() => {
    if (imageError) {
      setTimeout(() => {
        fetch(`${baseURL}/thumbnail?id=${item.id}&gameId=${gameId}`).then((res) => {
          res.blob().then((blob) => {
            const url = URL.createObjectURL(blob);
            const image = new Image();
            image.src = url;

            image.onload = () => {
              setThumbnail(image);
              setImageError(false);
            };

            image.onerror = () => {
              setImageError(true);
            };
          });
        });
      }, 1500);
    }
  }, [imageError]);

  return (
    <S.Card className={"prevent-select"} onClick={handleCardModal} item-id={item.id}>
      <Typography className="card-title" sx={{ wordBreak: "break-all" }}>
        {item.title}
      </Typography>

      <IconButton
        sx={{ position: "absolute", top: 0, right: 0, p: 0 }}
        onClick={(e) => {
          e.stopPropagation();

          setAnchorEl(e.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>

      {Boolean(anchorEl) && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(null);
          }}
        >
          <MenuItem onClick={modifyCard} sx={{ px: "20px" }}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <EditIcon />

              <Typography sx={{ fontWeight: "600" }}>수정하기</Typography>
            </Box>
          </MenuItem>

          <MenuItem onClick={deleteCard} sx={{ px: "20px", color: "red" }}>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <DeleteIcon />

              <Typography sx={{ fontWeight: "600" }}>삭제하기</Typography>
            </Box>
          </MenuItem>
        </Menu>
      )}

      {item.file && (
        <>
          <Box className="card-image">
            {thumbnail !== null && thumbnail !== undefined ? (
              <>
                <img src={thumbnail.src} />

                <Box
                  sx={{
                    position: "absolute",
                    bottom: "4px",
                    left: 0,
                    bgcolor: "#333",
                    color: "#fff",
                    p: "2px 5px",
                    borderRadius: "0 5px 0 0",
                    font: "700 14px Pretendard",
                  }}
                >
                  {file.type?.toUpperCase()}
                </Box>
              </>
            ) : (
              <Skeleton variant="rectangular" sx={{ width: "100%", height: "200px" }} />
            )}
          </Box>

          <Box
            sx={{
              bgcolor: "#ddd",
              p: "2px",
              mt: "-15px",
              "> p": { font: "600 14px Pretendard" },
            }}
          >
            {item.link && <Typography noWrap>{item.link}</Typography>}
            {!item.link && <Typography noWrap>{item.file.name}</Typography>}
          </Box>
        </>
      )}

      <Typography className="card-content">{item.explain}</Typography>

      <Divider />

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box className="card-footer">
          <IconButton size="small" onClick={handleLikes}>
            {item.likes?.includes(uuid) ? (
              <FavoriteIcon sx={{ color: "#c33c3c" }} />
            ) : (
              <FavoriteBorderIcon sx={{ color: "#555" }} />
            )}
          </IconButton>

          <Typography>{item.likes?.length || 0}</Typography>
        </Box>

        <Box sx={{ display: "flex", gap: "5px", alignItems: "center", pr: "5px" }}>
          <ChatBubbleOutlineIcon fontSize="large" sx={{ scale: "0.6", color: "#555" }} />
          <Typography>{item.comments?.length || 0}</Typography>
        </Box>
      </Box>

      {isDeleteModalOpened && (
        <DeleteModal open={isDeleteModalOpened} onClose={handleDeleteModal} targetId={item.id} />
      )}
    </S.Card>
  );
});

export default Card;
