import { styled } from "styled-components";
import { styled as MuiStyled } from "@mui/material/styles";
import { Box, Typography, Grid, IconButton, Tooltip, Badge } from "@mui/material";

// * Body
export const Body = MuiStyled((props) => (
  <Box
    sx={{
      height: "100vh",
      // width: "90%",
      bgcolor: "#f5f5f5",
      pt: "80px",
      boxSizing: "border-box",
      overflow: "auto",
    }}
    {...props}
  />
))({});

// * 내부 Content
export const Content = MuiStyled((props) => (
  <Box
    sx={{
      height: "100%",
      width: "calc(100% - 30px)",
      minHeight: "600px",
      maxHeight: "1080px",
      margin: "auto",
      pb: "20px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
    }}
    {...props}
  />
))({});

// * 슬라이드
export const Slide = MuiStyled((props) => (
  <Box
    sx={{
      position: "relative",
      p: "5px 10px",
      flex: 1,
      flexShrink: 0,
      height: "100%",
      minHeight: "550px",
      boxSizing: "border-box",
      bgcolor: "#fff",
      borderRadius: "10px",
      boxShadow: "0px 3px 6px #00000028",
      overflow: "auto",
      // zIndex: 1,
    }}
    {...props}
  />
))({});

// * 슬라이드 그리드 (메인 슬라이드, QR코드)
export const SlideGrid = MuiStyled((props) => (
  <Box
    sx={{
      height: "100%",
      display: "flex",
      overflow: "hidden",
      flexShrink: 0,
    }}
    {...props}
  />
))({});

// * 메인 슬라이드
export const MainBox = MuiStyled((props) => (
  <Box
    sx={{
      transition: "1s",
      width: props["is-qr-grid-opened"] === "true" ? "75%" : "100%",
      flexShrink: 0,
      height: "100%",
      overflow: "hidden",
    }}
    {...props}
  />
))({});

// * QR코드 영역
export const QrArea = MuiStyled((props) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderLeft: "1px solid #eee",
      flexShrink: 0,
      width: "25%",
      flexDirection: "column",
      gap: "20px",
    }}
    {...props}
  />
))({});

// * QR코드 박스
export const QrBox = MuiStyled(Box)({
  width: "50%",
  cursor: "pointer",
  "&:hover": { transform: "scale(1.05)" },
});

// * "접속코드"
export const AccessCodeTitle = MuiStyled((props) => (
  <Typography
    sx={{
      display: "flex",
      justifyContent: "center",
      font: "bold 28px Pretendard",
      color: "#797979",
    }}
    {...props}
  />
))({});

// * 실제 접속코드
export const AccessCode = MuiStyled((props) => (
  <Typography
    sx={{
      display: "flex",
      justifyContent: "center",
      font: "bold 40px Pretendard",
      color: "#B43F3F",
    }}
    {...props}
  />
))({});

// * 액션 버튼 박스
export const ActionBox = MuiStyled((props) => (
  <Box
    sx={{
      position: "absolute",
      bottom: "20px",
      left: "20px",
      display: "flex",
      gap: "10px",
    }}
    {...props}
  />
))({});

// * 네비게이션 박스
export const NavigationBox = MuiStyled((props) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "5px",
      p: "5px",

      bgcolor: "#f5f5f5",
      borderRadius: "20px",
    }}
    {...props}
  />
))({});

// * 네비게이션 버튼
export const SlideButtonBox = MuiStyled((props) => (
  <Box
    sx={{
      display: "flex",
      gap: "10px",
      p: "5px",
      // pt: "100px",
      bgcolor: "#f5f5f5",
      borderRadius: "20px",
      opacity: props["data-opacity"],
      // opacity: 1,
      transition: "opacity 0.5s",
    }}
    {...props}
  />
))({});

// * 리액션 박스 (좋아요 , QnA 버튼)
export const ReactionBox = MuiStyled((props) => (
  <Box
    sx={{
      position: "absolute",
      bottom: "20px",
      right: "20px",
      display: "flex",
      gap: "10px",
    }}
    {...props}
  />
))({});

// * 리액션 버튼 (좋아요, QnA)
export const ReactionButton = MuiStyled(Box)({
  backgroundColor: "#f5f5f5",
  padding: "5px",
  borderRadius: "50%",
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    bgcolor: "#ddd",
  },
});

// * 리액션 카운트
export const ReactionBadge = MuiStyled((props) => (
  <Badge
    sx={{
      "> span": {
        position: "absolute",
        top: "-8px",
        right: "50%",
        fontWeight: 600,
      },
    }}
    {...props}
  />
))({});

// * 타이머 박스
export const TimerBox = MuiStyled((props) => (
  <Box
    sx={{
      position: "absolute",
      bottom: "80px",
      right: "40px",
      bgcolor: "#f5f5f5",
      borderRadius: "20px",
      p: "20px",
      border: "3px solid #aaa",
      boxShadow: "0px 3px 6px #00000028",
    }}
    {...props}
  />
))({});

// * 로고
export const SlideLogo = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
`;

// * 툴팁
export const ToolTip = (props) => {
  return (
    <Tooltip
      title={props.title}
      placement={props.placement}
      arrow
      PopperProps={{ disablePortal: true }}
    >
      {props.children}
    </Tooltip>
  );
};

// ? Intro
export const Intro = MuiStyled((props) => (
  <Box
    sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      p: "20px",
      boxSizing: "border-box",
    }}
    {...props}
  />
))({});

export const IntroTitle = MuiStyled((props) => (
  <Typography
    sx={{
      textAlign: "center",
      font: "700 60px Pretendard",
    }}
    {...props}
  />
))({});

export const AccessGuide = MuiStyled((props) => (
  <Grid
    item
    xs={6}
    sx={{
      borderRight: "1px solid #eee",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
    {...props}
  />
))({});

export const QrGrid = MuiStyled((props) => (
  <Grid
    item
    xs={6}
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",

      "> div": {
        width: "50%",
        cursor: "pointer",
        "&:hover": { transform: "scale(1.05)" },
      },
    }}
    {...props}
  />
))({});

// ? 워드클라우드
export const WordCloud = MuiStyled((props) => (
  <Box
    sx={{
      height: "92%",
      display: "flex",
      flexDirection: "column",
      p: "10px",
      boxSizing: "border-box",
      width: "100%",
      overflow: "hidden",
    }}
    {...props}
  />
))({});

export const WordCloudLeftButton = MuiStyled(IconButton)({
  position: "absolute",
  top: "50%",
  left: 0,
  transform: "translateY(-50%)",
  bgcolor: "#eee",
});

export const WordCloudRightButton = MuiStyled(IconButton)({
  position: "absolute",
  top: "50%",
  right: 0,
  transform: "translateY(-50%)",
  bgcolor: "#eee",
});

export const WordCloudContent = MuiStyled((props) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      flexShrink: 0,
      transform: props.translate,
      transition: ".75s ease-out",
    }}
    {...props}
  />
))({});

export const WordCloudArea = MuiStyled(Box)({
  height: "100%",
  width: `80%`,
  position: "relative",
});

export const WordTooltip = MuiStyled((props) => (
  <Box
    sx={{
      position: "absolute",
      p: "5px",
      top: props.y - 30,
      left: props.x,
      transform: "translateX(-50%)",

      bgcolor: "#555",
      borderRadius: "5px",
      fontWeight: "600",
      color: "#fff",
      ":after": {
        content: "''",
        position: "absolute",
        top: "100%",
        left: "50%",
        border: "solid transparent",
        borderWidth: "10px",
        borderColor: "rgba(136, 183, 213, 0)",
        borderTopColor: "#555",
        marginLeft: "-10px",
      },
    }}
    {...props}
  />
))({});

export const Graph = MuiStyled((props) => (
  <Box
    sx={{
      width: "100%",
      height: "100%",
      flexShrink: 0,
      transform: props.translate,
      transition: ".75s ease-out",
      display: "flex",
      justifyContent: "center",
    }}
    {...props}
  />
))({});

export const GraphArea = MuiStyled((props) => (
  <Box
    sx={{
      position: "absolute",
      flexShrink: 0,
      height: "100%",
      width: "90%",
      p: "20px 10px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      overflow: "auto",
    }}
    {...props}
  />
))({});

export const GraphBox = MuiStyled((props) => (
  <Box
    sx={{
      width: "60%",
      display: "flex",
      justifyContent: "flex-end",
      height: "16px",
      alignItems: "center",
      pr: "10%",
    }}
    {...props}
  />
))({});

export const TotalGraph = MuiStyled((props) => (
  <Box
    sx={{
      width: "90%",
      backgroundColor: "#efefef",
      mr: "5px",
      position: "relative",
      height: "16px",
    }}
    {...props}
  />
))({});

export const GraphBar = MuiStyled((props) => (
  <Box
    sx={{
      position: "absolute",
      backgroundColor: "#B43F3FEE",
      width: `${props.width}%`,
      height: "16px",
      transition: ".3s",
    }}
    {...props}
  />
))({});

// ? Choice

// * ChoiceBody
export const ChoiceBody = MuiStyled(Box)({
  height: "calc(100% - 70px)",
  display: "flex",
  flexDirection: "column",
  padding: "10px",
  boxSizing: "border-box",
});

// * Choice 질문 텍스트
export const ChoiceQuestion = MuiStyled(Typography)({
  font: "700 44px Pretendard",
  borderBottom: "1px solid #eee",
});

// * Choice 콘텐츠 박스
export const ChoiceContent = MuiStyled(Box)({
  flex: 1,
  position: "relative",
  display: "flex",
  overflow: "hidden",
});

// * Choice 설명 박스
export const ExplainBox = MuiStyled(Box)({
  position: "absolute",
  top: 10,
  left: 10,
});

// * Choice 타입 텍스트
export const TypeText = MuiStyled(Typography)({
  font: "700 28px Pretendard",
  marginBottom: "10px",
});

// * Choice 투표 수
export const PollingCount = MuiStyled(Typography)({
  fontWeight: "600",
  color: "#888",
});

// * Choice 메인 박스
export const ChoiceMain = MuiStyled(Box)({
  height: "100%",
  width: "100%",
  overflow: "hidden",
  position: "relative",
});

// * Choice Area
export const ChoiceArea = MuiStyled(Box)({
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  transition: "0.5s",
});

// * Choice 차트 박스
export const ChartBox = MuiStyled(Box)({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "calc(100% - 85px)",
});

// * Choice 투표가 없을 때 대기 텍스트
export const WaitingText = MuiStyled(Typography)({
  font: "600 30px Pretendard",
  color: "#888",
});

// * Choice 기타의견 박스
export const EtcOpinionBox = MuiStyled(Box)({
  position: "absolute",
  width: "100%",
  height: "calc(100% - 85px)",
  marginTop: "85px",
  transition: "0.5s",
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "20px",
});

// * Choice 기타의견 없을 때 대기 텍스트
export const EtcOpinionNoData = MuiStyled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

// * Choice 기타의견 타이틀
export const EtcOpinionTitle = MuiStyled(Typography)({
  position: "absolute",
  top: -80,
  left: "50%",
  transform: "translateX(-50%)",
  font: "700 30px Pretendard",
});

// * Choice 기타의견 아이템
export const EtcOpinion = MuiStyled(Box)({
  backgroundColor: "#f5f5f5",
  border: "2px solid #eee",
  padding: "15px",
  boxSizing: "border-box",
  marginBottom: "10px",
  borderRadius: "10px",
  width: "100%",
  height: "max-content",
});

// * Choice 네이게이션 버튼 (좌)
export const ChoiceLeftNav = MuiStyled(IconButton)({
  position: "absolute",
  top: "50%",
  right: 10,
  transform: "translateY(-50%)",
  backgroundColor: "rgba(0,0,0,0.04)",
});

// * Choice 네이게이션 버튼 (우)
export const ChoiceRightNav = MuiStyled(IconButton)({
  position: "absolute",
  top: "50%",
  left: 10,
  transform: "translateY(-50%)",
  backgroundColor: "rgba(0,0,0,0.04)",
});

// ? OpenEnded

export const OpenEndedBody = MuiStyled(Box)({
  height: "calc(100% - 70px)",
  display: "flex",
  flexDirection: "column",
  padding: "10px",
  boxSizing: "border-box",
});

export const OpenEndedQuestion = MuiStyled(Typography)({
  font: "700 44px Pretendard",
  borderBottom: "1px solid #eee",
});

export const OpenEndedContent = MuiStyled(Box)({
  flex: 1,
  display: "flex",
  overflow: "hidden",
  flexDirection: "column",
});

export const OpenEndedArea = MuiStyled(Box)({
  flex: 1,
  flexShrink: 0,
  height: "100%",
  overflow: "hidden",
  position: "relative",
});

export const OpenEndedGrid = MuiStyled(Grid)({
  position: "absolute",
  height: "100%",
  overflow: "auto",
  padding: "10px",
});

export const OpenEndedItem = MuiStyled(Grid)({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  padding: "10px",
});

export const OpenEndedBox = MuiStyled(Box)({
  padding: "30px",
  backgroundColor: "#f5f5f5",
  font: "500 24px Pretendard",
  lineHeight: "1.5",
});

export const OpenEndedWaitingBox = MuiStyled(Box)({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const OutroBody = MuiStyled(Box)({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const OutroContent = MuiStyled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "50px",
  transition: "1s ease-in-out",

  "& img": {
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  },
});

export const OutroText = MuiStyled(Typography)({
  font: "800 36px Pretendard",
  textAlign: "center",
  lineHeight: "1.5",
});

export const styles = {
  intro: {
    participant_box: {
      py: "20px",
      width: "100%",
      borderRadius: "10px 10px 0 0",
      // backgroundColor: "#f5f5f5",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      icon: { fontSize: "clamp(20px, 1.354vw, 40px)", mr: "10px" },

      text: {
        fontSize: "32px",
        fontFamily: "Pretendard",
        fontWeight: 400,
        color: "#333",
      },
    },
  },
};
