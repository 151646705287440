import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingModal } from "components/Modal";
import { useSocket } from "contexts/Socket";
import { SnackBarContext } from "components/SnackBar/ContextAPI";
import { v4 as uuidv4 } from "uuid";

// const baseURL = process.env.REACT_APP_LIVE_BOARD_SERVER_URL;

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const { gameId } = useParams();
  const SnackBar = useContext(SnackBarContext);
  // const socket = useSocket("http://localhost:3004");
  // const socket = useSocket("http://192.168.0.154:3004");
  // const socket = useSocket("http://172.30.1.54:3004");
  const socket = useSocket("https://board.withplus.live");

  const [isSocketDisconnected, setSocketDisconnected] = useState(false);
  const [isCardModalOpened, setIsCardModalOpened] = useState(false);

  const [board, setBoard] = useState(null);

  const [title, setTitle] = useState("");
  const [explain, setExplain] = useState("");
  const [layout, setLayout] = useState(null);
  const [sections, setSections] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [modifyTarget, setModifyTarget] = useState(null);

  const handleCardModal = (e) => {
    let itemId = e?.currentTarget.getAttribute("item-id");

    if (!itemId) {
      setSelectedCard(null);
    } else {
      let item = board.list.find((item) => item.id === itemId);
      setSelectedCard(item);
    }

    setIsCardModalOpened((prev) => !prev);
  };

  const handleCardModify = (item) => {
    setModifyTarget(item);
  };

  useEffect(() => {
    socket.emit("getBoard", { gameId }, (data) => {
      if (!data.success) {
        alert("보드가 존재하지 않습니다.");

        window.location.href = "/";
        return;
      }
      setBoard(data.board);
    });

    if (localStorage.getItem("uuid") === null) {
      localStorage.setItem("uuid", uuidv4());
    }
  }, []);

  useEffect(() => {
    if (!board) return;

    setTitle(board.name);
    setExplain(board.explain);
    setLayout(board.layout);
    setSections(board.sections);

    if (selectedCard) {
      setSelectedCard(board.list.find((item) => item.id === selectedCard.id));
    }
  }, [board]);

  useEffect(() => {
    socket.on("disconnect", () => {
      console.log("disconnect");
      setSocketDisconnected(true);
    });

    socket.on("connect", () => {
      console.log("connect");
      setSocketDisconnected(false);
      let uid = localStorage.getItem("uuid");

      if (!uid) {
        uid = uuidv4();
        localStorage.setItem("uuid", uid);
      }

      socket.emit("joinRoom", { gameId, user: true, uid }, (res) => {
        if (!res.success) {
          alert(" 해당 보드에 접속한 최대 인원이 초과되었습니다. \n 진행자에게 문의하세요.");
          window.location.href = "/";
        }
      });
      socket.emit("getBoard", { gameId }, (data) => {
        if (!data.success) {
          alert("보드가 존재하지 않습니다.");

          window.location.href = "/";
          return;
        }
        setBoard(data.board);
      });
    });

    socket.on("changeTitle", (data) => {
      setTitle(data.name);
      setBoard((prev) => ({ ...prev, name: data.name }));
    });

    socket.on("changeExplain", (data) => {
      setExplain(data.explain);
      setBoard((prev) => ({ ...prev, explain: data.explain }));
    });

    socket.on("addGroup", (data) => {
      setSections(data.sections);
      setBoard((prev) => ({ ...prev, sections: data.sections }));
    });

    socket.on("changeLayout", (data) => {
      setLayout(data.layout);
      setBoard((prev) => ({ ...prev, layout: data.layout }));
    });

    socket.on("changeSectionName", (data) => {
      setSections(data.sections);
      setBoard((prev) => ({ ...prev, sections: data.sections }));
    });

    socket.on("sortSections", (data) => {
      setSections(data.sections);
      setBoard((prev) => ({ ...prev, sections: data.sections }));
    });

    socket.on("deleteSection", (data) => {
      setSections(data.sections);
      setBoard((prev) => ({ ...prev, sections: data.sections }));
    });

    socket.on("sortPosts", (data) => {
      setBoard((prev) => ({ ...prev, list: data.list }));
    });

    socket.on("postBoard", (data) => {
      const { list } = data;
      const newBoard = { ...board, list };

      setBoard(newBoard);
    });

    socket.on("handleLike", (data) => {
      setBoard(data.board);

      if (selectedCard && selectedCard.id === data.postId) {
        setSelectedCard(data.board.list.find((item) => item.id === selectedCard.id));
      }
    });

    socket.on("addComment", (data) => {
      setBoard(data.board);

      if (selectedCard && selectedCard.id === data.post.id) {
        setSelectedCard(data.board.list.find((item) => item.id === selectedCard.id));
      }
    });

    socket.on("deletePost", (data) => {
      setBoard(data.board);

      if (selectedCard && selectedCard.id === data.postId) {
        setSelectedCard(null);
        setIsCardModalOpened(false);

        SnackBar.setOpen(true);
        SnackBar.setText("게시물이 삭제되었습니다.");
        SnackBar.setSeverity("info");
      }
    });

    socket.on("modifyBoard", (data) => {
      const { list, target } = data;

      const idx = list.findIndex((item) => item.id === target.id);

      list[idx].file = null;

      setBoard((prev) => ({ ...prev, list }));

      setTimeout(() => {
        list[idx].file = target.file;

        setBoard((prev) => ({ ...prev, list }));
      }, 300);
    });

    return () => {
      socket.off("disconnect");
      socket.off("connect");
      socket.off("changeLayout");
      socket.off("changeTitle");
      socket.off("changeExplain");
      socket.off("addGroup");
      socket.off("changeSectionName");
      socket.off("sortSections");
      socket.off("deleteSection");
      socket.off("sortPosts");
      socket.off("postBoard");
      socket.off("handleLike");
      socket.off("addComment");
      socket.off("deletePost");
      socket.off("modifyBoard");
    };
  });

  const value = {
    socket,
    board,
    layout,
    title,
    explain,
    sections,
    isCardModalOpened,
    selectedCard,
    modifyTarget,

    handleCardModal,
    setSelectedCard,
    handleCardModify,
    setModifyTarget,
  };

  return (
    <UserContext.Provider value={value}>
      {children}

      <LoadingModal open={isSocketDisconnected} />
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
