import React from "react";

/** 이용약관 */
const TermOfUse = ({ refund }) => {
  if (refund) {
    return <Refund />;
  }

  return <Content />;
};

const Refund = () => {
  return (
    <>
      <p>제24조(취소&nbsp;및&nbsp;환불)</p>
      <p>
        ①&nbsp;회원은&nbsp;다음&nbsp;각호의&nbsp;사항에&nbsp;따라&nbsp;사이트에서&nbsp;결제한&nbsp;서비스의&nbsp;환불&nbsp;또는&nbsp;변경을&nbsp;요청할&nbsp;수&nbsp;있습니다.
      </p>
      <p>
        ②&nbsp;결제한&nbsp;유료&nbsp;서비스를&nbsp;이용하지&nbsp;않은&nbsp;경우&nbsp;전액&nbsp;환불이&nbsp;가능합니다.&nbsp;단,&nbsp;유료&nbsp;서비스의&nbsp;종류에&nbsp;따라&nbsp;다음&nbsp;각호의&nbsp;사항에&nbsp;따른&nbsp;환불&nbsp;규정을&nbsp;적용합니다.
      </p>
      <p>
        1.
        &nbsp;모든&nbsp;유료&nbsp;서비스는&nbsp;이용내역이&nbsp;없는&nbsp;경우&nbsp;청약(구매&nbsp;및&nbsp;결제)일로부터&nbsp;7일&nbsp;이내&nbsp;요청하시면&nbsp;전액&nbsp;환불이&nbsp;가능합니다.
      </p>
      <p>
        2.
        &nbsp;기간제(정기&nbsp;구독)&nbsp;서비스&nbsp;이용권이라&nbsp;함은&nbsp;매월&nbsp;같은&nbsp;날,&nbsp;같은&nbsp;금액으로&nbsp;정기&nbsp;결제되는&nbsp;1개월&nbsp;유료&nbsp;서비스&nbsp;이용권을&nbsp;의미합니다.
      </p>
      <p>
        3. &nbsp;12개월 유료 이용 서비스(1년 선결제 상품)를 신청하여 서비스 이용내역이 있는 경우,
        취소 수수료(총 결제 금액의 10%) + (이용 개월 수 * 1개월 기간제(정기 구독) 서비스 금액)을
        차감한 나머지 결제 금액을 환불합니다. 12개월 유료 이용 서비스 결제시 제공되는 할인 혜택은
        장기적으로 계속 이용하는 회원에 대한 혜택으로, 12개월 유료 이용 서비스 중도 해지시 월 기간제
        서비스 금액은 1개월 기간제 서비스 금액을 기준으로 환불 금액을 산정합니다. 단, 차감하는
        금액이 환불 금액을 초과할 시에는 환불이 불가합니다.&nbsp;
      </p>
      <p>
        ③&nbsp;다음&nbsp;각호의&nbsp;경우에는&nbsp;이용자가&nbsp;환불을&nbsp;요청할&nbsp;수&nbsp;없습니다.
      </p>
      <p>
        1.
        &nbsp;기간제(정기&nbsp;구독)&nbsp;서비스의&nbsp;환불&nbsp;해당&nbsp;월&nbsp;이용내역이&nbsp;있는&nbsp;경우
      </p>
      <p>
        2.
        &nbsp;서비스&nbsp;업데이트를&nbsp;통한&nbsp;문제&nbsp;해결이&nbsp;가능함에도&nbsp;회원의&nbsp;의사로&nbsp;이를&nbsp;거부하여&nbsp;서비스를&nbsp;이용하지&nbsp;못하는&nbsp;경우
      </p>
      <p>3. &nbsp;회원의&nbsp;실수로&nbsp;해당&nbsp;서비스를&nbsp;이용하지&nbsp;못하는&nbsp;경우</p>
      <p>
        ④&nbsp;기타&nbsp;본&nbsp;약관&nbsp;및&nbsp;사이트의&nbsp;이용안내에&nbsp;규정되지&nbsp;않은&nbsp;취소&nbsp;및&nbsp;환불에&nbsp;대한&nbsp;사항에&nbsp;대해서는&nbsp;소비자&nbsp;피해보상규정에서&nbsp;정한&nbsp;바에&nbsp;따릅니다.
      </p>
      <p>
        <br />
      </p>
    </>
  );
};

const Content = () => {
  return (
    <>
      {" "}
      <p>
        제1조(목적) 이 약관은 주식회사 위드플러스(이하 &ldquo;회사&rdquo;라 한다.)이 운영하는 웹
        서비스 위드플러스 라이브(이하 &ldquo;서비스&rdquo; 라 한다.)을 이용함에 있어 회사와 이용자의
        권리와 의무 및 책임사항을 규정함을 목적으로 합니다.
      </p>
      <p>&nbsp;</p>
      <p>제2조(정의)</p>
      <p>
        ① &ldquo;위드플러스 라이브&rdquo;이란 게임 기반 학습플랫폼을 회사가 웹사이트, 회원의
        이동전화, 휴대용 단말기 등 각종 유무선 기기를 통해 제공하며, 연령과 주제에 상관없이 게임러닝
        콘텐츠를 저작하고 풀이할 수 있도록 서비스함을 의미합니다. 서비스는 무료와 유료서비스로
        구분됩니다.
      </p>
      <p>
        ② &ldquo;이용자&rdquo;란 웹사이트 및 각종 유무선 기기를 통해 서비스에 접속하여 이용 약관에
        따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
      </p>
      <p>
        ③ &ldquo;회원&rdquo;이라 함은 웹사이트 접속하여 본 약관에 동의한 후 회원가입을 신청하여,
        회사의 승낙을 통해 회원 가입 절차를 완료하고, 서비스 이용 자격(이하 &lsquo;계정&rsquo;)을
        부여 받은 자를 의미합니다. 회원은{" "}
        {/*서비스 이용 목적에 따라 &ldquo;학생회원&rdquo;과
        &ldquo;선생님회원&rdquo;, &ldquo;기업회원&rdquo;, &ldquo;기타회원&rdquo;으로 나뉘며,*/}
        지속적으로 회사가 제공하는 정보와 서비스를 이용할 수 있는 자를 말합니다.
      </p>
      <p>
        ④ 회원의 &ldquo;게시물&rdquo;이란 위드플러스 라이브 서비스를 이용해 회원이 올린 {/*퀴즈*/}
        콘텐츠{/*,&nbsp;회원이 작성한 글,&nbsp;이미지,&nbsp;각종 파일 및 링크,&nbsp;각종 댓글 등*/}
        의 정보를 말합니다.
      </p>
      <p>
        ⑤&nbsp;&ldquo;무료 서비스&rdquo;란 위드플러스 라이브의 이용을 위해 제공되는 제한된 회사의
        서비스를 의미합니다.
      </p>
      <p>
        <br />
      </p>
      <p>
        ⑥&nbsp;&ldquo;유료 서비스&rdquo;란 회원이 회사에 일정 금액을 지불해야만 이용할 수 있는
        회사의 서비스 또는 이용권(상품)을 의미합니다.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>제3조 (약관 등의 명시와 설명 및 개정)</p>
      <p>
        ① 회사는 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수
        있는 곳의 주소를 포함), 전화번호, 팩스번호, 전자우편주소, 사업자등록번호, 통신판매업
        신고번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 홈페이지의 초기
        서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록
        할 수 있습니다.
      </p>
      <p>
        ② 회사는 이용자가 약관에 동의하기에 앞서 약관에 정해져 있는 내용 중 청약철회, 배송책임,
        환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면
        등을 위드플러스 라이브 서비스 홈페이지에 제공하여 이용자의 확인을 구하여야 합니다.
      </p>
      <p>
        ③ 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」,
        「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자 서명법」, 「정보통신망 이용촉진
        및 정보보호 등에 관한 법률」, 「소비자 기본법」 등 관련 법을 위배하지 않는 범위에서 이
        약관을 개정할 수 있습니다.
      </p>
      <p>
        ④ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 홈페이지의
        초기 서비스화면(전면)에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만,
        이용자에게 불리하게 약관내용을 변경하는 경우에는 최소 한 30일 이상의 사전 유예기간을 두고
        공지합니다. &nbsp;이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가
        알기 쉽도록 표시합니다.
      </p>
      <p>
        ⑤ 회사가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고
        그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미
        계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의
        공지기간 내에 사이트에 게재하여 사이트의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
      </p>
      <p>&nbsp;</p>
      <p>제4조 (관련법령과의 관계)</p>
      <p>
        ① 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의
        소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래
        등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.
      </p>
      <p>&nbsp;</p>
      <p>제5조(서비스의 제공 및 변경)</p>
      <p>① 회사는 다음과 같은 서비스를 제공합니다.</p>
      <p>
        1. &nbsp;게임 기반 학습 플랫폼을 제공하여 지식 콘텐츠인 퀴즈게임을 생성할 수 있는 전반
        기능과 PIN번호 또는 고유URL을 통해 {/*퀴즈를 공유하고 풀이할 수 있도록 하는 기능들을*/}{" "}
        게임러닝 콘텐츠 및 서비스를 무료와 유료 서비스로 제공합니다.
      </p>
      <p>2. &nbsp;기타 회사가 정하는 업무</p>
      <p>
        ② 회사는 기술적 사양의 변경 등의 경우 장차 제공되는 서비스 내용을 변경할 수 있습니다. 변경된
        서비스 내용 및 제공일자는 홈페이지의 초기 서비스화면(전면)에 명시하여 즉시 공지합니다.
      </p>
      <p>&nbsp;</p>
      <p>제6조(서비스의 중단) &nbsp;</p>
      <p>
        ① 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절, 국가 비상사태, 정전,
        사용자의 접속 폭주 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단하거나 정지할
        수 있습니다. &nbsp;&nbsp;
      </p>
      <p>
        ② 회사가 서비스를 일시적으로 중단할 경우 그 사유를 홈페이지의 초기 서비스화면(전면)에
        게재합니다.
      </p>
      <p>&nbsp;</p>
      <p>제7조(회원가입)</p>
      <p>
        ① 이용자는 웹사이트 또는 단말기를 통하여 회사가 정한 가입 양식에 따라 회원 정보를 기입한 후
        이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.
      </p>
      <p>
        ② 회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한
        회원으로 등록합니다.
      </p>
      <p>
        1. &nbsp;가입신청자가 이 약관 제8조 2항에 의거하여 이전에 회원자격을 상실한 적이 있는 경우,
        다만 제8조 2항에 의한 회원자격 상실 후 30일이 경과한 자로서 회사의 회원 재가입 승낙을 얻은
        경우에는 예외로 합니다. &nbsp;&nbsp;
      </p>
      <p>2. &nbsp;등록 내용에 허위, 기재 누락, 오기가 있는 경우 &nbsp;&nbsp;</p>
      <p>3. &nbsp;타인의 명의를 도용한 경우 &nbsp;&nbsp;</p>
      <p>4. &nbsp;실명인증에 실패한 경우</p>
      <p>5. &nbsp;서비스 관련 설비의 용량이 부족한 경우</p>
      <p>6. &nbsp;기타 회사가 필요하다고 인정되는 경우</p>
      <p>7. &nbsp;이미 등록된 아이디를 이용하여 가입 신청한 경우 &nbsp;&nbsp;</p>
      <p>8. &nbsp;부당한 이윤을 목적으로 한 가입 신청이 확인된 경우</p>
      <p>
        ③ 회원가입신청에 대한 승낙은 회원가입이 완료되었다는 화면이 연결될 때와 서비스상에서
        로그인(log-in)이 가능해진 시점으로 합니다.
      </p>
      <p>
        ④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 회사에 대하여
        회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.
      </p>
      <p>&nbsp;</p>
      <p>제8조(회원 탈퇴 및 자격 상실 등)</p>
      <p>
        ① 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 서비스 규정에 의거하여 회원탈퇴를
        처리합니다.
      </p>
      <p>② 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 정지시킬 수 있습니다.</p>
      <p>1. &nbsp;가입 신청 시에 허위 내용을 등록한 경우 &nbsp;&nbsp;</p>
      <p>
        2. &nbsp;서비스를 이용하여 구입한 재화 등의 대금, 기타 유료 서비스 이용에 관련하여 회원이
        부담하는 채무를 기일에 지급하지 않는 경우 &nbsp;&nbsp;
      </p>
      <p>
        3. &nbsp;다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를
        위협하는 경우 &nbsp;&nbsp;
      </p>
      <p>
        4. &nbsp;서비스를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
      </p>
      <p>
        5. &nbsp;부정한 이용을 사유로 탈퇴하거나, 회사에 의해 이미 회원자격을 정지당한 경우
        &nbsp;&nbsp;
      </p>
      <p>6. &nbsp;부당한 이윤을 목적으로 한 이용이 확인된 경우</p>
      <p>
        ③ 회사가 회원자격을 상실시키는 경우에는 회원 등록을 말소합니다. 이 경우 회원에게 이를
        통지하고, 해당 회원에게는 회원 등록 말소에 대하여 소명할 기회를 부여합니다.&nbsp;
      </p>
      <p>
        ④ 회사는 회원자격을 상실한 회원을 식별하기 위하여 회원가입 당시의 DI(Duplication
        information)값을 30일간 보존하며, 이를 근거로 30일 이내 재가입 신청 시 승낙을 불허합니다.
      </p>
      <p>&nbsp;</p>
      <p>제9조(회원에 대한 통지)</p>
      <p>
        ① 회사가 회원에 대한 통지를 하는 경우, 회원이 가입시에 등록한 전자우편 주소로 할 수
        있습니다.
      </p>
      <p>
        ② 회사는 불특정다수 회원에 대한 통지의 경우 1주일 이상 사이트 게시판에 게시함으로써 개별
        통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에
        대하여는 개별통지를 합니다.
      </p>
      <p>&nbsp;</p>
      <p>제10조 (이용 계약의 성립)</p>
      <p>① 이용계약은 본 이용약관 내용에 대한 회원의 이용신청과 회사의 승낙에 의하여 성립합니다.</p>
      <p>② 단 다음 각 호의 사유에 해당하는 경우, 이용계약 성립이 되지 않거나 제한될 수 있습니다.</p>
      <p>1. &nbsp;신청 내용에 허위, 기재누락, 오기가 있는 경우 &nbsp;&nbsp;</p>
      <p>
        2. &nbsp;기존에 서비스 이용 중 약관에 위배되어 회원자격을 상실한 적이 있는 경우 &nbsp;&nbsp;
      </p>
      <p>3. &nbsp;부당한 이윤을 목적으로 한 이용이 확인된 경우 &nbsp;</p>
      <p>4. &nbsp;이미 등록된 전자우편주소와 중복될 경우 &nbsp;</p>
      <p>5. &nbsp;사이트의 기술적인 문제로 승인이 불가한 경우 &nbsp;</p>
      <p>6. &nbsp;승낙하는 것이 회사의 기술상 현저히 지장이 있다고 판단하는 경우&nbsp;</p>
      <p>
        <br />
      </p>
      <p>제11조 (정보의 제공 및 광고의 게재)</p>
      <p>
        ① 회사는 회원에게 서비스 이용에 필요가 있다고 인정되는 각종 정보에 대해서, 서비스내의
        화면이나 전자우편 또는 서신 우편 등의 방법으로 회원에게 제공할 수 있습니다.
      </p>
      <p>
        ② 회사의 서비스를 이용하는 회원은 서비스 이용 시 노출되는 광고 게재에 대해 동의하는 것으로
        간주됩니다.
      </p>
      <p>
        <br />
      </p>
      <p>&nbsp;제12조(서비스 이용시간) &nbsp;&nbsp;</p>
      <p>
        ① 서비스 이용시간은 회사의 업무상 또는 기술상 불가능한 경우를 제외하고는 1일
        24시간(00:00~24:00)으로 함을 원칙으로 합니다. 다만, 서비스 설비의 정기점검 등의 사유로
        회사가 서비스를 특정범위로 분할하여 별도로 날짜와 시간을 정할 수 있습니다.
      </p>
      <p>&nbsp;</p>
      <p>제13조(개인정보의 이용 또는 제공)</p>
      <p>
        ① 회사는 이용자의 개인정보 수집 시 서비스 제공을 위하여 필요한 범위에서 최소한의 개인정보를
        수집합니다. &nbsp;
      </p>
      <p>
        ② 회사는 이용자의 개인정보를 수집&middot;이용하는 때에는 당해 이용자에게 그 목적을 고지하고
        동의를 받습니다. &nbsp;
      </p>
      <p>
        ③ 회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용 목적이 발생한 경우
        또는 제3자에게 제공하는 경우에는 이용&middot;제공단계에서 당해 이용자에게 그 목적을 고지하고
        동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
      </p>
      <p>&nbsp;</p>
      <p>제14조(개인정보의 변경)</p>
      <p>① 회원은 개인정보관리에서 본인의 개인정보를 확인하고 변경할 수 있습니다. &nbsp;&nbsp;</p>
      <p>② 정보변경을 하지 않아 발생하는 불이익에 대해서는 회사는 책임지지 않습니다.</p>
      <p>
        ③ 회원이 닉네임 또는 이와 유사한 식별자를 선정할 경우, 회사가 변경이 필요하다고 판단되는
        다음 각호의 사유에 해당될 때 이를 변경할 수 있습니다.
      </p>
      <p>1. &nbsp;타인의 지적경〮제적 재산권을 침해하는 경우</p>
      <p>2. &nbsp;다른 사용자를 사칭하는 경우</p>
      <p>3. &nbsp;타인에게 혐오감을 주는 경우</p>
      <p>4. &nbsp;회원의 전화번호 등으로 등록되어 사생활 침해가 우려되는 경우</p>
      <p>5. &nbsp;기타 회사가 인정하는 경우</p>
      <p>
        <br />
      </p>
      <p>제15조(개인정보주체의 권리)&nbsp;</p>
      <p>
        ① 회사는 제17조 2항에 의해 회원의 동의를 받아야 하는 경우에는 개인정보관리 책임자의
        신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한
        정보제공 관련사항(제공 받은 자, 제공 목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및
        정보보호 등에 관한 법률」 제19조 제2항이 규정한 사항을 미리 명시하거나 고지해야 하며
        이용자는 언제든지 이 동의를 철회할 수 있습니다.&nbsp;
      </p>
      <p>
        ② 회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을
        달성한 때에는 당해 개인정보를 지체 없이 파기합니다.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>제16조(회원 정보의 관리 또는 삭제요청)</p>
      <p>① 회사는 데이터 액세스, 수정, 복사 및 삭제 기능을 제공합니다.</p>
      <p>
        ② 회사는 더 이상 서비스를 제공할 필요가 없는 시점, &nbsp;회원의 계정이 삭제되는 시점 중 먼저
        도달하는 시점까지만 데이터를 저장합니다. 이는 데이터의 성격, 수집 및 처리된 이유, 관련 법적
        또는 운영상의 보존 필요성과 같은 요인에 따라 개별적으로 결정됩니다.&nbsp;
      </p>
      <p>
        ③ 회원 및 계정정보를 삭제하면 회원의 개인정보 및 회원이 작성한 사항이 삭제되며 나중에 해당
        정보를 복원할 수 없습니다.&nbsp;
      </p>
      <p>
        ④ 다른 회원이 회원에게 대해 공유한 정보는 회원의 계정정보에 포함되지 않으며 삭제되지
        않습니다.&nbsp;
      </p>
      <p>
        ⑤ 계정을 삭제하지 않고 제품 이용을 일시적으로 중단하려면 계정을 비활성화할 수
        있습니다.&nbsp;
      </p>
      <p>
        ⑥ 회원님의 계정정보(작성정보 포함)의 삭제는 아래 회사 개인정보 보호책임자에게 연락하여
        언제든 요청할 수 있습니다. &nbsp;
      </p>
      <p>
        (개인정보의 파기절차 및 방법은 &quot;개인정보처리방침&quot;의 4항을 참고해주시기 바랍니다.)
      </p>
      <p>&nbsp;</p>
      <p>제17조(회사의 의무)</p>
      <p>
        ① 회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는
        바에 따라 지속적이고, 안정적으로 유료 및 무료 서비스를 제공하는데 최선을 다하여야 합니다.
      </p>
      <p>
        ② 회사는 이용자가 안전하게 위드플러스 라이브 웹사이트 및 서비스를 이용할 수 있도록 이용자의
        개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
      </p>
      <p>&nbsp;</p>
      <p>제18조(회원의 ID 및 비밀번호에 대한 의무)</p>
      <p>① ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.</p>
      <p>② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.</p>
      <p>
        ③ 회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로
        회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.
      </p>
      <p>&nbsp;</p>
      <p>제19조(이용자의 의무) &nbsp;</p>
      <p>① 이용자는 다음 행위를 하여서는 안 됩니다.</p>
      <p>1. &nbsp;서비스신청 또는 변경 시 허위 내용의 등록 &nbsp;&nbsp;</p>
      <p>2. &nbsp;타인의 정보 도용 &nbsp;&nbsp;</p>
      <p>3. &nbsp;사이트에 게시된 정보 이외의 변경 &nbsp;&nbsp;</p>
      <p>
        4. &nbsp;회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시 &nbsp;&nbsp;
      </p>
      <p>5. &nbsp;회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해 &nbsp;&nbsp;</p>
      <p>6. &nbsp;회사와 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 &nbsp;&nbsp;</p>
      <p>
        7. &nbsp;외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스
        사이트에 공개 또는 게시하는 행위 &nbsp;&nbsp;
      </p>
      <p>8. &nbsp;불법적인 목적으로 서비스를 이용하여 영업하는 행위 &nbsp;</p>
      <p>9. &nbsp;기타 회사의 규정에 반하는 행위</p>
      <p>&nbsp;&nbsp;</p>
      <p>제20조(회원 게시물의 귀속 및 이용제한) &nbsp;</p>
      <p>
        ①&nbsp;회원이 서비스를 이용하면서 등록한 게시물의 저작권 및 지적 재산권은 회원에게 있으며
        회사는 서비스상의 게재권 만을 갖습니다.
      </p>
      <p>
        <br />
      </p>
      <p>
        ②&nbsp;회원은 게시물의 공개와 비공개 여부를 설정할 수 있으며 공개 게시물의 경우 다른
        회원에게 제공이 가능하고 비공개 게시물의 경우 다른 회원에게 제공되지 않습니다.
      </p>
      <p>
        ③&nbsp;회원이 공개한 게시물(댓글 제외)은 다른 회원에게 제공되어 사용,&nbsp;재생산.&nbsp;다른
        컨텐츠 또는 자료와의 결합이 가능합니다.
      </p>
      <p>
        ④&nbsp;회원이 서비스를 이용하는 과정에서 작성한 게시물에 대한 저작권을 포함한 일체에 관한
        권리는 별도의 의사표시가 없는 한 해당 이용고객에게 귀속됩니다.&nbsp;단 다음 각 호에 해당하는
        경우 회사는 사전 통지 없이 게시물을 삭제하며,&nbsp;이에 법적 책임을 지지 않습니다.
      </p>
      <p>
        <br />
      </p>
      <p>
        ⑤&nbsp;회사는 이용자의 게시물을 모니터링,&nbsp;검토,&nbsp;편집 또는 삭제할 수 있지만 이는
        의무사항이 아니며,&nbsp;게시물 편집 또는 삭제 시 이용자에게 전자우편으로 통지 후 조치합니다.
      </p>
      <p>
        1. &nbsp;공공질서 또는 미풍양속에 위배되는 내용의 정보, 문장, 도형, 음성 등을 포함하고 있는
        경우 &nbsp;&nbsp;
      </p>
      <p>
        2. &nbsp;컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로
        고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를
        게시할 경우 &nbsp;&nbsp;
      </p>
      <p>3. &nbsp;회사와 기타 제3자의 명예를 훼손하는 내용을 포함하고 있는 경우</p>
      <p>4. &nbsp;회사와 기타 제3자의 저작권 등 지적재산권에 대해 침해하는 경우</p>
      <p>
        <br />
      </p>
      <p>제21조(저작권의 귀속 및 이용제한)</p>
      <p>① 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.</p>
      <p>
        ② 이용자는 사이트를 이용함으로써 얻은 정보 중 회사에 지적재산권이 귀속된 정보를 회사의 사전
        승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게
        이용하게 하여 회사의 손해가 발생한 경우 이에 대한 민,형사상의 책임을 갖게 됩니다.
      </p>
      <p>
        ③ 회사는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 전자우편으로
        사전 통보하여야 합니다.
      </p>
      <p>
        ④ 회사는 회원이 작성한 정보, 문장, 폰트, 도형, 이미지, 음성 등 저작물에서 발생하는 저작권
        분쟁에 대해서는 책임을 부담하지 않습니다.
      </p>
      <p>&nbsp;</p>
      <p>제22조(결제)</p>
      <p>
        ①&nbsp;회원은&nbsp;회사가&nbsp;제공하는&nbsp;다음&nbsp;각호&nbsp;또는&nbsp;이와&nbsp;유사한&nbsp;절차에&nbsp;의하여&nbsp;유료&nbsp;서비스&nbsp;신청을&nbsp;합니다.
      </p>
      <p>1. &nbsp;유료&nbsp;서비스&nbsp;구매&nbsp;대상&nbsp;선택</p>
      <p>2. &nbsp;유료&nbsp;서비스의&nbsp;상세정보&nbsp;확인</p>
      <p>
        3.
        &nbsp;회원의&nbsp;성명,&nbsp;주소,&nbsp;전화번호(또는&nbsp;휴대전화번호),&nbsp;소속명&nbsp;등의&nbsp;입력&nbsp;및&nbsp;결제하기&nbsp;클릭
      </p>
      <p>4. &nbsp;결제금액&nbsp;확인,&nbsp;결제수단&nbsp;선택&nbsp;및&nbsp;결제하기</p>
      <p>5. &nbsp;결제금액&nbsp;재확인&nbsp;및&nbsp;결제</p>
      <p>
        ②&nbsp;회원은 회사에서 정하는 방법으로 유료서비스의 결제가 가능합니다. 단, 미성년 회원의
        결제는 원칙적으로 보호자(법정대리인)의 명의 또는 동의하에 이루어져야 하고, &nbsp;보호자(법정
        대리인)는 본인 동의없이 체결된 자녀(미성년자)의 계약을 취소할 수 있습니다.
      </p>
      <p>
        ③ 정기 결제가 윤년이 아닌 해의 2월 29일, 30일 또는 31일에 갱신되도록 설정되어 있으면 정기
        결제 갱신일은 2월 28일로 옮겨지고 이후 정기 결제 기간 동안 계속해서 매월 28일에 갱신됩니다.
        마찬가지로 사용자가 3월 31일에 정기 결제를 시작하면 정기 결제는 4월 30일에 갱신되고 계속해서
        매월 30일에 갱신됩니다.
      </p>
      <p>&nbsp;</p>
      <p>제23조(계약의&nbsp;성립&nbsp;및&nbsp;통지)</p>
      <p>
        ①&nbsp;회사는&nbsp;다음&nbsp;각호에&nbsp;해당하지&nbsp;않는&nbsp;한&nbsp;유료&nbsp;서비스&nbsp;신청을&nbsp;승낙합니다.
      </p>
      <p>1. &nbsp;신청&nbsp;내용에&nbsp;허위,&nbsp;누락,&nbsp;오기가&nbsp;있는&nbsp;경우</p>
      <p>
        2.
        &nbsp;미성년자가&nbsp;청소년&nbsp;보호법에서&nbsp;금지하는&nbsp;서비스&nbsp;또는&nbsp;콘텐츠를&nbsp;결제하는&nbsp;경우
      </p>
      <p>
        3.
        &nbsp;유료&nbsp;서비스&nbsp;신청금액&nbsp;총액과&nbsp;입금총액이&nbsp;일치하지&nbsp;않는&nbsp;경우
      </p>
      <p>
        4.
        &nbsp;결제&nbsp;신청을&nbsp;승낙하는&nbsp;것이&nbsp;회사의&nbsp;기술상&nbsp;현저히&nbsp;지장이&nbsp;있다고&nbsp;판단하는&nbsp;경우
      </p>
      <p>
        5.
        &nbsp;기타&nbsp;합리적인&nbsp;이유가&nbsp;있는&nbsp;경우로서&nbsp;회사가&nbsp;필요하다고&nbsp;인정되는&nbsp;경우
      </p>
      <p>
        ②&nbsp;회사는&nbsp;회원이&nbsp;본&nbsp;약관&nbsp;제22조의&nbsp;절차에&nbsp;따라&nbsp;유료&nbsp;서비스&nbsp;이용을&nbsp;신청할&nbsp;경우,&nbsp;승낙의&nbsp;의사표시로써&nbsp;본&nbsp;약관&nbsp;제9조의&nbsp;방법을&nbsp;통하여&nbsp;회원에게&nbsp;통지하고,&nbsp;승낙의&nbsp;통지가&nbsp;회원에게&nbsp;도달한&nbsp;시점에&nbsp;계약이&nbsp;성립한&nbsp;것으로&nbsp;봅니다.
      </p>
      <p>
        ③
        회사의&nbsp;승낙&nbsp;의사표시에는&nbsp;회원의&nbsp;이용신청에&nbsp;대한&nbsp;확인&nbsp;및&nbsp;서비스제공&nbsp;가능여부,&nbsp;이용신청의&nbsp;정정,&nbsp;취소&nbsp;등에&nbsp;관한&nbsp;정보&nbsp;등을&nbsp;포함합니다.
      </p>
      <p>&nbsp;</p>
      <p>제24조(취소&nbsp;및&nbsp;환불)</p>
      <p>
        ①&nbsp;회원은&nbsp;다음&nbsp;각호의&nbsp;사항에&nbsp;따라&nbsp;사이트에서&nbsp;결제한&nbsp;서비스의&nbsp;환불&nbsp;또는&nbsp;변경을&nbsp;요청할&nbsp;수&nbsp;있습니다.
      </p>
      <p>
        ②&nbsp;결제한&nbsp;유료&nbsp;서비스를&nbsp;이용하지&nbsp;않은&nbsp;경우&nbsp;전액&nbsp;환불이&nbsp;가능합니다.&nbsp;단,&nbsp;유료&nbsp;서비스의&nbsp;종류에&nbsp;따라&nbsp;다음&nbsp;각호의&nbsp;사항에&nbsp;따른&nbsp;환불&nbsp;규정을&nbsp;적용합니다.
      </p>
      <p>
        1.
        &nbsp;모든&nbsp;유료&nbsp;서비스는&nbsp;이용내역이&nbsp;없는&nbsp;경우&nbsp;청약(구매&nbsp;및&nbsp;결제)일로부터&nbsp;7일&nbsp;이내&nbsp;요청하시면&nbsp;전액&nbsp;환불이&nbsp;가능합니다.
      </p>
      <p>
        2.
        &nbsp;기간제(정기&nbsp;구독)&nbsp;서비스&nbsp;이용권이라&nbsp;함은&nbsp;매월&nbsp;같은&nbsp;날,&nbsp;같은&nbsp;금액으로&nbsp;정기&nbsp;결제되는&nbsp;1개월&nbsp;유료&nbsp;서비스&nbsp;이용권을&nbsp;의미합니다.
      </p>
      <p>
        3. &nbsp;12개월 유료 이용 서비스(1년 선결제 상품)를 신청하여 서비스 이용내역이 있는 경우,
        취소 수수료(총 결제 금액의 10%) + (이용 개월 수 * 1개월 기간제(정기 구독) 서비스 금액)을
        차감한 나머지 결제 금액을 환불합니다. 12개월 유료 이용 서비스 결제시 제공되는 할인 혜택은
        장기적으로 계속 이용하는 회원에 대한 혜택으로, 12개월 유료 이용 서비스 중도 해지시 월 기간제
        서비스 금액은 1개월 기간제 서비스 금액을 기준으로 환불 금액을 산정합니다. 단, 차감하는
        금액이 환불 금액을 초과할 시에는 환불이 불가합니다.&nbsp;
      </p>
      <p>
        ③&nbsp;다음&nbsp;각호의&nbsp;경우에는&nbsp;이용자가&nbsp;환불을&nbsp;요청할&nbsp;수&nbsp;없습니다.
      </p>
      <p>
        1.
        &nbsp;기간제(정기&nbsp;구독)&nbsp;서비스의&nbsp;환불&nbsp;해당&nbsp;월&nbsp;이용내역이&nbsp;있는&nbsp;경우
      </p>
      <p>
        2.
        &nbsp;서비스&nbsp;업데이트를&nbsp;통한&nbsp;문제&nbsp;해결이&nbsp;가능함에도&nbsp;회원의&nbsp;의사로&nbsp;이를&nbsp;거부하여&nbsp;서비스를&nbsp;이용하지&nbsp;못하는&nbsp;경우
      </p>
      <p>3. &nbsp;회원의&nbsp;실수로&nbsp;해당&nbsp;서비스를&nbsp;이용하지&nbsp;못하는&nbsp;경우</p>
      <p>
        ④&nbsp;기타&nbsp;본&nbsp;약관&nbsp;및&nbsp;사이트의&nbsp;이용안내에&nbsp;규정되지&nbsp;않은&nbsp;취소&nbsp;및&nbsp;환불에&nbsp;대한&nbsp;사항에&nbsp;대해서는&nbsp;소비자&nbsp;피해보상규정에서&nbsp;정한&nbsp;바에&nbsp;따릅니다.
      </p>
      <p>
        <br />
      </p>
      <p>제25조(면책조항)</p>
      <p>
        ①&nbsp;회사는 다음 사유로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에
        대해서는 책임을 부담하지 않습니다.
      </p>
      <p>1. &nbsp;천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</p>
      <p>
        2. &nbsp;서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가
        있는 경우
      </p>
      <p>3. &nbsp;회원의 귀책사유로 서비스 이용에 장애가 있는 경우</p>
      <p>4. &nbsp;기타 회사의 고의∙과실이 없는 사유로 인한 경우</p>
      <p>
        ② 회사는 이용자가 유.무료서비스를 이용하여 얻은 정보, 자료, 사실의 신뢰도, 정확성 등에
        대해서는 보증을 하지 않으며 이로 인해 발생한 이용자의 손해에 대하여는 책임을 부담하지
        않습니다.
      </p>
      <p>
        ③ 회사를 통해 접수를 하지 않아 전산에서 조회가 되지 않는 건에 대해 회사는 책임을 지지
        않습니다.
      </p>
      <p>
        ④ 회사는 이용자가 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에 대하여 책임을
        지지 않으며, 이용자 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없고, 이로 인한
        손해를 배상할 책임도 부담하지 않습니다.
      </p>
      <p>&nbsp;</p>
      <p>제26조(분쟁해결)</p>
      <p>
        ① 회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여
        피해보상처리기구를 설치․운영합니다.
      </p>
      <p>
        ② 회사는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만,
        신속한 처리가 곤란한 경우에는 이용자에게 사유와 처리일정을 통보해 드립니다.
      </p>
      <p>
        ③ 회사와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제 신청이 있는
        경우에는 공정거래위원회 또는 시&middot;도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수
        있습니다.
      </p>
      <p>&nbsp;</p>
      <p>제27조(재판권 및 준거법)</p>
      <p>
        ① 회사와 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 회사의 주소에
        의합니다.
      </p>
      <p>② 회사와 이용자 간에 제기된 전자상거래 소송에는 대한민국의 법률을 적용합니다.</p>
      <p>&nbsp;</p>
      <p>제28조(기타)</p>
      <p>
        ① 약관에 명시하지 않은 사항은 &lsquo;전자상거래 등에서의 소비자 보호에 관한 법률&rsquo; 등
        관련 법령의 규정과 일반 상관례에 의합니다.
      </p>
      <p>&nbsp;</p>
      <p>부칙</p>
      <p>제1조. (시행일)본 약관은 2019년 12월 1일부터 적용됩니다.</p>
      <p>제2조. 회사의 정보관리책임자는 다음과 같습니다.</p>
      <p>- 이름: 이광표</p>
      <p>- 연락처: 031-423-0201</p>
      <p>- 이메일: withplus@gamedu.co.kr</p>{" "}
    </>
  );
};

export default TermOfUse;
