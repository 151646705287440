import React from "react";
import { Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export const SnackBar = (props) => {
  const { open, onClose, severity, text } = props;
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <MuiAlert elevation={8} variant="filled" severity={severity}>
        <Typography sx={{ fontFamily: "NotoSansKR-Medium" }}>{text}</Typography>
      </MuiAlert>
    </Snackbar>
  );
};
