import {
  DndContext,
  useDroppable,
  useDraggable,
  closestCorners,
  useSensors,
  useSensor,
  PointerSensor,
  DragOverlay,
} from "@dnd-kit/core";
import { SortableContext, rectSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";

export const SortableItem = (props) => {
  const { id, children, xs, style } = props;

  const { attributes, listeners, setNodeRef, transform, transition, isDragging, newIndex } =
    useSortable({
      id,
    });

  const [sx, setSx] = useState({});

  useEffect(() => {
    let newSx = {};
    newSx = { ...style };

    if (props["dragging-style"]) {
      Object.keys(props["dragging-style"]).forEach((key) => {
        if (isDragging) {
          newSx = {
            ...newSx,
            [key]: props["dragging-style"][key],
          };
        } else {
          newSx = {
            ...newSx,
            [key]: style[key],
          };
        }
      });
    }

    setSx(newSx);
  }, [props["dragging-style"], style, isDragging]);

  return (
    <Grid
      item
      xs={xs}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      sx={{
        ...sx,
        transform: CSS.Translate.toString(transform),
        transition,
      }}
    >
      {children}

      {props["is-dragging"] === "true" && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            bgcolor: "#c33c3c",
            color: "#fff",
            p: "5px",
            fontWeight: "bold",
            borderTopRightRadius: "8px",
            borderBottomLeftRadius: "8px",
          }}
        >
          {newIndex}
        </Box>
      )}
    </Grid>
  );
};

export const Sortable = (props) => {
  const { id, children, style } = props;
  const { attributes, listeners, setNodeRef, transform, transition, isDragging, newIndex } =
    useSortable({
      id,
    });

  const [sx, setSx] = useState({});

  useEffect(() => {
    let newSx = {};
    newSx = { ...style };

    if (props["dragging-style"]) {
      Object.keys(props["dragging-style"]).forEach((key) => {
        if (isDragging) {
          newSx = {
            ...newSx,
            [key]: props["dragging-style"][key],
          };
        } else {
          newSx = {
            ...newSx,
            [key]: style[key],
          };
        }
      });
    }

    setSx(newSx);
  }, [props["dragging-style"], style, isDragging]);

  return (
    <Box
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      sx={{ ...sx, position: "relative", transform: CSS.Translate.toString(transform), transition }}
    >
      {children}

      {props["is-dragging"] === "true" && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            bgcolor: "#c33c3c",
            color: "#fff",
            p: "5px",
            fontWeight: "bold",
            borderTopRightRadius: "8px",
            borderBottomLeftRadius: "8px",
          }}
        >
          {newIndex}
        </Box>
      )}
    </Box>
  );
};
