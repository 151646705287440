import React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCheck } from "@fortawesome/free-solid-svg-icons";

import { Box, Button, Grid, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import background from "assets/images/PlanPage/background.png";
import logo from "assets/images/PlanPage/logo.png";
import blurImg from "assets/images/PlanPage/blurImg.png";
import backgroundItem from "assets/images/PlanPage/backgroundItem.png";

import styles from "../styles/styles.module.css";

export default () => {
  const navigate = useNavigate();

  // 반응형 모바일
  const isMobile = useMediaQuery({
    query: "(max-width:1000px)",
  });

  const goToPlanPage = () => {
    setTimeout(() => {
      navigate("/plan");
    }, 500);
  };

  const goToPayPage = (e) => {
    const id = e.currentTarget.id;
    setTimeout(() => {
      navigate(`pay?product=${id}`);
    }, 500);
  };

  return (
    <Box className={[styles.body, isMobile && styles.mobile_background].join(" ")}>
      <Box className={[styles.background, isMobile && styles.hidden].join(" ")}>
        <img src={backgroundItem} className={styles.background_image} />
      </Box>

      <Box className={styles.content_area}>
        <Box className={styles.routing_button} onClick={goToPlanPage}>
          <ArrowForwardIcon />

          <Typography>콘텐츠 상품 페이지로 이동하기</Typography>
        </Box>

        <Grid container rowGap="50px">
          <Grid item xs={12} className={styles.title_grid}>
            <img src={logo} />

            <Box className={styles["title_box"]}>
              <img src={blurImg} />

              <Typography>
                강의에 필요한 모든 강의 지원 도구를 <span>위드플러스 라이브</span>에서
              </Typography>
            </Box>
            <span className={styles["plan_type"]}>진단지 상품 플랜</span>
          </Grid>

          <Grid item xs={12} className={styles["product_grid"]}>
            <Box className={styles["product"]}>
              <Box className={styles["header"]}>
                <FontAwesomeIcon icon={faCircleCheck} color="#fff" style={{ fontSize: "28px" }} />

                <p>추천 상품</p>
              </Box>

              <div className={styles["product_body"]}>
                <p className={styles["product_name"]}>
                  라이브 핵사그램
                  <br />
                  <span>리더십 유형 파악 진단지</span>
                </p>

                <p className={styles["product_price"]}>10,000원 / 명</p>

                <div className={styles["explain"]}>
                  <p>핵사그램 리더의 6가지 타입별 특징과 성향분석</p>
                  <p>타입별 ‘심리욕구 및 스트레스’를 이해</p>
                  <p>타입별 ‘관계관리 & 동기부여’에 대해 이해</p>
                </div>
              </div>

              <div className={styles["footer"]}>
                <p>
                  위드플러스 라이브에서 강의지원 도구를 <br />
                  체험해보세요
                </p>

                {!isMobile && (
                  <Button variant="contained" onClick={goToPayPage} id="hexagram">
                    구매하기
                  </Button>
                )}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
