import React, { useEffect } from "react";
import { useCookies } from "react-cookie";

import { CircularProgress, Backdrop, Box } from "@mui/material";

const LogoutPage = (props) => {
  const [, , removeCookie] = useCookies();

  useEffect(() => {
    removeCookie("OAUTH", { path: "/" });
    window.location.href = "/";
  }, []);

  return (
    <Backdrop
      sx={{ bgcolor: "#ababab50", color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LogoutPage;
